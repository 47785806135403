<template>
  <div class="filter-btns">
    <div v-for="(entry, index) in options"
      :key="index"
      @click="select(entry.value)"
      :class="{'filter-btn--active': selected === entry.value}"
      class="filter-btn">
      {{ entry.label }}
    </div>
  </div>
</template>

<script>

export default {
  name: 'FilterButtons',
  components: {
  },
  props: {
    options: Array,
    selected: String,
  },
  data() {
    return {
    };
  },
  computed: {
  },
  mounted() {
  },
  methods: {
    select(val) {
      this.$emit('select', val);
    },
  },
};
</script>

<style lang="scss" scoped>
  .filter {
    &-btn {
      font-weight: 800;
      color: #a7a7a7;
      padding: .1rem .5rem;
      min-width: 80px;
      font-size: .8rem;
      margin-right: .5rem;
      text-align: center;
      cursor: pointer;
      &--sort {
        margin-left: auto;
        color: #fff;
        border-bottom: 2px dotted;
        cursor: pointer;
      }
      &:last-child {
        margin-right: 0;
      }
      &--active {
        color: #65a0ff;
        font-weight: 800;
        // font-weight: 600;
        border-bottom: 4px solid #65a0ff;
      }
    }
    &-btns {
      display: flex;
      width: 100%;
      justify-content: flex-end;
      align-items: center;
      margin: .25rem;
      flex-direction: row;
      flex-wrap: wrap;
    }
  }
  @media only screen and (max-width: 1250px) {
    .filter-btns {
      margin-top: 60px;
    }
  }
  @media only screen and (max-width: 490px) {
    .filter-btn {
      min-width: 40px;
    }
  }

</style>
