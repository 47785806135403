<template>
  <i
    @click="play"
    :class="{inactive: !loaded, active: loaded}"
    class="fas fa-volume-up icon-voiceover heartbeat-animation"></i>
</template>

<script>

import {mapState} from 'vuex';
import {soundMixin} from '../../../mixins/sound';

// plays a sound depending on current book and scene id
// should play the challenge text version if challengetextversion exists
// and difficulty is 1

export default {
  name: 'ReadAloudAudio',
  components: {
  },
  mixins: [soundMixin],
  props: {
    challengeTextExists: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // boolean to see if audio is loaded
      loaded: false,
      sounds: {},
    };
  },
  computed: {
    ...mapState('dynamicBook', ['currentBookId', 'currentSceneId',
      'difficulty']),
    // we want to watch all three of these properties
    combined() {
      return this.currentBookId + '_' +
        this.currentSceneId + '_' + this.difficulty;
    },
    audioUrl() {
      const baseUrl = 'https://smartschool-static.s3-ap-southeast-1.amazonaws.com/media/dynamicbooks/2019_dec_holiday_camp/sounds/read-aloud/story-';

      const showChallengeTextVersion =
        this.challengeTextExists && this.difficulty === 1;

      // use different url depending on if its challenge text or now
      return baseUrl + this.currentBookId + '/' +
        this.currentSceneId +
        (showChallengeTextVersion ? '-A.m4a' : '.m4a');
    },
  },
  // whenever sceneId changes, we should modify the audioUrl
  watch: {
    combined: {
      immediate: true,
      handler(to, from) {
        this.loaded = false;
        // if there are any readaloud sounds, stop playing
        if (typeof this.sounds['readAloud'] !== 'undefined') {
          this.sounds['readAloud'].stop();
        };

        const manifest = [{
          id: 'readAloud',
          src: this.audioUrl,
        }];

        this.preload(manifest, (data) => {
          this.sounds = data;
          this.loaded = true;
        }, (error) => {
          console.log(error);
        });
      },
    },
  },
  mounted: function() {
  },
  methods: {
    play() {
      // stops any current playing readAloud track
      this.sounds['readAloud'].stop();
      this.sounds['readAloud'].play();
    },
  },
};
</script>

<style lang="scss" scoped>
  /*
  .read-aloud-audio__component {
    position: absolute;
    top: 60px;
    right: 50px;
    font-size: 2rem;
    cursor: pointer;
    i {
      color: #fff;
    }
  }*/

  .inactive {
    color: #fff;
    opacity: 0.1;
  }

  .active {
    cursor: pointer;
    color: #69ffbb !important;
    opacity: 1;
  }
  i {
    position: absolute;
    bottom: 30px;
    right: 30px;
    font-size: 2rem;
  }
  i:focus {
    outline: none;
    border: none;
  }

</style>
