<template>
  <div class="settings--body">
    <div class="settings--body-levels">
      <h3 class="settings--body-levels--header">
        SELECT LEVEL
      </h3>
      <ul class="settings--body-levels--list">
        <li v-for="(level, index) in levels" :key="index"
            @click="selectedLevel = level"
            :class="{active: selectedLevel === level}"
            class="settings--body-levels--list-item">
            {{  level | shorternLevel  }}
        </li>
      </ul>
    </div>
    <div class="settings--body-chapters">
      <div class="settings--body-chapters--header">
        <h3 class="settings--body-chapters--header-text">
          SELECT CHAPTER(S)
        </h3>
        <div class="settings--body-chapters--header-btns">
          <div class="settings--body-chapters--header-btn"
          @click="all">
            ALL
          </div>
          <div class="settings--body-chapters--header-btn"
          @click="clear">
            CLEAR
          </div>
        </div>
      </div>
      <ul class="settings--body-chapters--list"
        v-if="chapters.length > 0">
        <li  @click="add(chapter.id)"
          v-for="(chapter, index) in chapters" :key="index"
          class="settings--body-chapters--list-item animated fadeIn faster"
          :class="{active: chapter.id in selectedChapterIds}">
          {{ chapter.chapter_number + 1 }}. {{ chapter.title }}
        </li>
      </ul>
    </div>
  </div>
</template>

<script>

import Vue from 'vue';

// https://stackoverflow.com/questions/72797681/click-button-in-parent-get-data-from-child-to-parent-and-use-it-in-a-method-vu
export default {
  name: 'LevelChapterSelector',
  components: {
  },
  props: {
    listLevelChapters: Array,
    defaultLevel: String,
  },
  data() {
    return {
      selectedChapterIds: {},
      selectedLevel: '',
    };
  },
  computed: {
    levels() {
      return this.listLevelChapters.map((x) => x.level);
      // .concat('others');
    },
    chapters() {
      if (this.selectedLevel === '') return [];
      const data = this.listLevelChapters.find((d) => d.level === this.selectedLevel);
      if (!data) return [];
      return data['chapters'];
    },
  },
  mounted() {
    // adds default chapters
    this.selectedLevel = this.defaultLevel;
    for (const chapter of this.chapters) {
      if (chapter.show_by_default) {
        this.add(chapter.id);
      }
    }
  },
  methods: {
    // adds all chapters from selected level to selectedChapterIds
    all() {
      for (const chapter of this.chapters) {
        Vue.set(this.selectedChapterIds, chapter.id, true);
        this.$emit('updateSelectedChapterIds', this.selectedChapterIds);
      };
    },
    clear() {
      this.selectedChapterIds = {};
      this.$emit('updateSelectedChapterIds', this.selectedChapterIds);
    },
    add(chapterId) {
      if (chapterId in this.selectedChapterIds) {
        Vue.delete(this.selectedChapterIds, chapterId);
      } else {
        // need to use set to avoid reactivity issues
        Vue.set(this.selectedChapterIds, chapterId, true);
      };
      this.$emit('updateSelectedChapterIds', this.selectedChapterIds);
    },
  },
};
</script>


<style lang="scss" scoped>
  .settings {
    &--body {
      flex: 1;
      display: flex;
      flex-direction: column;
      padding-left: 400px;
      width: 100%;
      justify-content: space-around;
      align-items: center;
      &-levels {
        display: flex;
        flex-direction: column;
        width: 100%;
        padding: 0 1rem;
        margin-bottom: 1rem;
        &--header {
          align-self: left;
        }
        &--list {
          display: flex;
          gap: .5rem;
          cursor: pointer;
          flex: 1;
          &-item {
            font-size: 1rem;
            font-weight: 800;
            width: 100px;
            padding: .5rem;
            flex: 1;
            text-align: center;
            border-radius: .25rem;
            color: #ffffff63;
            background: #5b6b86b3;
            border: 4px solid #5122402e;
            &:hover {
              color: #FF6500;
              background: #fff;
              border: 4px solid #FF6500 ;
            }
          }
          .active {
              color: #fff;
              background: #FF6500;
              border: 4px solid #FF6500 ;
              cursor: auto;
          }
        }
      }
      &-chapters {
        flex: 1;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: flex-end;
        &--header {
          display: flex;
          justify-content: space-between;
          width: 100%;
          &-btns {
            display: flex;
            align-items: center;
            justify-content: center;
          }
          &-btn {
            width: 75px;
            background: #fff;
            border: 2px solid #8a8a8a;
            text-align: center;
            border-radius: 2rem;
            font-size: .75rem;
            font-weight: 600;
            color: #8a8a8a;
            cursor: pointer;
            &:hover {
              background: #bb6bff;
              color: #fff;
              border: 2px solid #bb6bff;
            }
            &:first-child {
              margin-right: .5rem;
            }
          }
        }
        &--list {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr;
          gap: .5rem;
          flex: 1;
          margin-top: .5rem;
          &-item {
            width: 200px;
            display: flex;
            flex: 1;
            align-items: center;
            padding: 0 .75rem;
            font-size: .75rem;
            background: #bbc6d0c4;;
            border: 3px solid #bbc6d0;
            border-radius: .25rem;
            color: #5d5d5d99;
            cursor: pointer;
            &:hover {
              background: #5390f9;
              color: #fff;
              border: 3px solid #00aeff;
            }
          }
          .active {
            background: #5390f9;
            color: #fff;
            border: 3px solid #00aeff;
            &:hover {
              background: #fff;
              color: #5390f9;
              border: 3px solid #5390f9;
            }
          }
        }
      }
    }
  }
</style>
