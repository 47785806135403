<template>
  <div class="start-scene__component">
    <router-link
      class="game-flashcard-memory__component--close"
      :to="{name: 'profile-main'}">
      <i class="fas fa-times
        game-flashcard-memory__component--close-icon"></i>
    </router-link>
    <div class="start-scene__component--content">
      <div class="start-scene__component--logo--container">
        <div class="start-scene__component--logo"></div>
        <div class="start-scene__component--logo"></div>
        <div class="start-scene__component--logo"></div>
        <div class="start-scene__component--logo"></div>
      </div>
      <h1 class="start-scene__title--main">
        Memory Game
      </h1>
      <h3 class="start-scene__title--sub">
        with Flashcards
      </h3>
      <div class="start-scene__btns">
        <button
          @click="startGame"
          class="start-scene__btn start-scene__btn--start">
          Start Game
        </button>
<!--           <button class="start-scene__btn start-scene__btn--howto">
          How To Play?
        </button>
-->        </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'GameFlashcardMemoryStart',
  components: {
  },
  props: {
    startGame: Function,
  },
  data() {
    return {
    };
  },
  computed: {
  },
  mounted: function() {
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
  .game-flashcard-memory {
    &__component {
      height: 100vh;
      width: 100%;
      background: #fff;
      z-index: 30;
      position: absolute;
      top: 0;
      left: 0;
      &--close {
        position: absolute;
        top: 30px;
        right: 30px;
        &-icon {
          color: #cccccc;
          font-size: 2rem;
        }
      }
    }
  }
  /* Start Scene */
  .start-scene {
    &__component {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      &--content {
        margin-bottom: 3rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      &--logo {
        height: 75px;
        width: 75px;
        margin: 5px;
        &:nth-child(1) {
          border-top-left-radius: 50%;
          background: rgb(255, 192, 98);
        }
        &:nth-child(2) {
          border-top-right-radius: 50%;
          background: rgb(255, 125, 138);
        }
        &:nth-child(3) {
          border-bottom-left-radius: 50%;

          background: rgb(164, 138, 255);
        }
        &:nth-child(4) {
          border-bottom-right-radius: 50%;

          background: rgb(80, 241, 144);
        }
        &--container{
          display: flex;
          justify-content: center;
          width: 225px;
          flex-wrap: wrap;
          margin-bottom: 20px;
        }
      }
    }
    &__btn--start {
      margin-top: 10px;
      background: #00b3ec;
      padding: 0.75rem 1.5rem;
      border-radius: 15px;
      color: #fff;
      font-size: 1rem;
      font-weight: bold;
    }
  }
.start-scene__btns {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.start-scene__title {
  &--main {
    margin-bottom: 0.3rem;
  }
  &--sub {
    margin-bottom: 1rem;
    font-weight: 300;
  }
}


/* GameScene */
.game-scene__component {
  height: 100vh;
  &--close-icon {
    color: #cccccc;
    position: absolute;
    left: 30px;
    top: 30px;
    font-size: 2rem;
  }
}
.game-scene__component--content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.game-scene__btns {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin: 0 10px;
}

.game-scene__btn {
  flex: 1;
  padding: 60px 50px;
  font-size: 200%;
  border-radius: 5px;
  cursor: pointer;
  opacity: 0.5;
  margin: 10px;
  white-space: nowrap;
  transition: all 0.2s;
  &:hover {
    opacity: 1;
  }
  &:nth-child(1) {
    background: rgb(255, 192, 98);
  }
  &:nth-child(2) {
    background: rgb(255, 125, 138);
  }
  &:nth-child(3) {
    background: rgb(164, 138, 255);
  }
  &:nth-child(4) {
    background: rgb(80, 241, 144);
  }
}

.game-scene__component--title {
  margin-bottom: 1rem;
}

.game-scene__component--game-instructions {
  margin-bottom: 0.5rem;
  text-align: center;
  border-top: 2px solid #eee;
  padding: 1rem;
}
.game-scene__component--user-seq {
  margin-top: 1rem;
  margin-bottom: 2rem;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  span {
    border-radius: 5px;
    background: #f8f8f8;
    padding: 5px 10px;
    border: 2px solid #ccc;
    margin: 10px;
  }
}
.game-scene__btn--result {
  position: absolute;
  bottom: 50px;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  font-weight: bold;
  min-width: 200px;
  border-radius: 15px;
  color: #fff;
  cursor: pointer;
}
/* General */

.disabled {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.15;
}

a, button, .btn {
  cursor: pointer;
}
button:focus {
  outline: none;
}

.bg-correct {
  background: #00b3ec;
}
.bg-wrong {
  background: #db6372;
}
@media only screen and (max-width: 768px) {
  .game-scene__btn {
    padding: 50px 30px;
  }
}
@media only screen and (max-width: 660px) {
  .game-scene__btn {
    padding: 20px 30px;
    font-size: 150%;
    margin: 5px;
  }
}
@media only screen and (max-width: 500px) {
  .game-scene__btn--result {
    font-size: 1rem;
    padding: 0.75rem 1.5rem;
  }
  .game-scene__component--user-seq {
    margin-bottom: 6rem;
    span {
      font-size: 0.7rem;
      margin: 5px;
    }
  }
}
@media only screen and (max-width: 350px) {
  .game-scene__btn {
    padding: 15px 25px;
    font-size: 120%;
  }
}
@media only screen and (max-width: 400px) {
  .game-scene__component {
    &--title {
      font-size: 1.3rem;
      margin-bottom: 0.75rem;
    }
    &--game-instructions {
      font-size: 0.8rem;
      margin-bottom: 0.2rem;
      padding: 0.65rem;
    }
    &--user-seq {
      margin-top: 0.2rem;
    }
  }
  .game-scene__btn--result {
    font-size: 0.75rem;
    padding: 0.5rem 1.25rem;
  }
}
</style>
