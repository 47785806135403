<template>
  <div v-show="streakData.length > 0"
      class='streak-container'>
    <i v-for='(isCorrect, index) in streakData'
    :key='index'
    v-tooltip="attemptedAnswers ? attemptedAnswers[index] : ''"
    :class='{correct: isCorrect, wrong: !isCorrect, "fa-times": !isCorrect, "fa-check": isCorrect}'
    class='fas block'>
  </i>
  </div>
</template>

<script>

export default {
  name: 'CorrectStreak',
  props: {
    streakData: Array,
    attemptedAnswers: Array,
  },
  data() {
    return {
    };
  },
  mounted() {
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
  .streak-container {
    display: inline-block;
  }

  .wrong {
    color: red !important;
  }

  .correct {
    color: green !important;
  }

  .block {
    margin-left: 3px;
  }
</style>
