<template>
  <div class="dailystreak">
    <div class="dailystreak-container">
      <h2 class="dailystreak-header">
        Daily Streak
      </h2>
      <div class="dailystreak-content">
        <div class="dailystreak-content-counter">
          <i class="fas fa-fire-alt"></i>
          <div class="dailystreak-content-counter-text">
            <h3 class="dailystreak-content-counter-text-main">
              {{ loginStreak.current_streak }}
            </h3>
            <p class="dailystreak-content-counter-text-sub">
              Day Streak
            </p>
          </div>
        </div>
        <div class="dailystreak-content-calendar">
          <ul>
            <daily-streak-calendar-day
              v-for="(d, index) in daysData"
              :key="index"
              :day="d.day"
              :is-earned="d.isEarned"></daily-streak-calendar-day>
          </ul>
        </div>
        <p class="dailystreak-content-footer">
          Login daily to maintain your streak!
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex';

// since sunday is 0 and monday is 1, we start from sunday
const DAYS = ['S', 'M', 'T', 'W', 'T', 'F', 'S'];

import DailyStreakCalendarDayComponent
  from './components/DailyStreakCalendarDayComponent.vue';

export default {
  name: 'DailyStreak',
  components: {
    DailyStreakCalendarDay: DailyStreakCalendarDayComponent,
  },
  props: {
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapState(['loginStreak']),
    // if streak started on Monday, then the sequence will be M, T, W, ...
    // if streak started on Tuesday, then T, W , T, ...
    daysData() {
      // if login streak has exceeded 7 days, then we want to have 6 green days and 1 unfilled
      const SHOW_HOW_MANY_DAYS_IF_STREAK_IS_MAX = 6;
      let modifiedStreakLength = this.loginStreak['current_streak'];
      let startDayIndex = new Date(this.loginStreak['current_streak_start_date']).getDay();

      // if user's current streak has exceeded 7, then we need to account for rollover
      if (this.loginStreak['current_streak'] > 7) {
        modifiedStreakLength = SHOW_HOW_MANY_DAYS_IF_STREAK_IS_MAX;
        startDayIndex = new Date().getDay() - SHOW_HOW_MANY_DAYS_IF_STREAK_IS_MAX;
        if (startDayIndex < 0) {
          startDayIndex += 7;
        }
      }

      const toReturn = [];
      let i = 0;
      while (i < 7) {
        const index = (i + startDayIndex) % (DAYS.length);
        toReturn.push({
          day: DAYS[index],
          isEarned: modifiedStreakLength > i,
        });
        i++;
      }
      return toReturn;
    },
  },
  mounted() {
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
  .dailystreak {
    background: #132647;
    color: #fff;
    margin: .75rem 1rem .75rem 0;
    padding: .25rem;
    border-radius: 0.5rem;
    &-container {
      padding: .25rem .5rem;
      border: 5px solid #1D335C;
      border-radius: 0.5rem;
    }
    &-header {
      font-size: 1rem;
    }
    &-content {
      &-calendar {
        ul {
          margin: .75rem 0;
          display: flex;
          justify-content: space-around;
        }
      }
      &-counter {
        margin: .5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        i {
          padding: .75rem;
          font-size: 3rem;
          color: #ffce00;
          background: #ff8437;
          border-radius: 50%;
          width: 80px;
          height: 80px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        &-text {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          margin-left: 1.5rem;
          flex: 1;
          background: #ffffff12;
          padding-top: 0.5rem;
          border-radius: 0.5rem;
          padding-bottom: 0.25rem;
          &-main {
            font-size: 3rem;
            line-height: 1rem;
            margin: .75rem;
          }
          &-sub {
            font-size: .8rem;
          }
        }
      }
      &-footer {
        font-size: .75rem;
        line-height: 1rem;
        opacity: .6;
        text-align: center;
        border-top: 3px solid #ffffff36;
        padding: 0.5rem;
      }
    }
  }
  @media only screen and (max-width: 1366px),
              screen and (max-height: 870px) {
    .dailystreak {
      margin: .5rem .5rem 0.5rem 0;
      &-header {
        font-size: 0.9rem;
      }
      &-content {
        &-calendar {
          ul {
            margin: .5rem 0;
          }
        }
        &-counter {
          i {
            padding: 0.5rem;
            font-size: 2.75rem;
          }
          &-text {
            margin-left: 1rem;
            &-main {
              font-size: 2.25rem;
              line-height: .75rem;
              margin: 0.5rem;
            }
          }
        }
        &-footer {
          font-size: .65rem;
          padding: .25rem .25rem 0.1rem .25rem;
        }
      }
    }
  }
  @media only screen and (max-width: 1150px),
            screen and (max-height: 720px) {
    .dailystreak {
      margin: .5rem .25rem 0.25rem 0;
      &-header {
        font-size: 0.75rem;
      }
      &-content {
        &-calendar {
          ul {
            margin: .25rem 0;
          }
        }
        &-counter {
          margin: 0.25rem;
          margin-bottom: .5rem;
          i {
            font-size: 2.25rem;
          }
          &-text {
            margin-left: .5rem;
            &-main {
              font-size: 2rem;
              line-height: .5rem;
              margin: 0.5rem;
            }
            &-sub {
              font-size: 0.7rem;
            }
          }
        }
        &-footer {
          font-size: .55rem;
        }
      }
    }
  }

</style>
