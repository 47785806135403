<template>
  <div class="multiplayer__room--component">
    <div v-if="roomState === 0 || roomState === 2 || roomState === 3"
      class="multiplayer__room--header">
      {{ teacher.display_name }}的课室
      <i v-on:click="exitPopup = true"
        v-if="roomState === 0 || roomState === 3"
        class="fas fa-times multiplayer__room--exit"></i>
      <!-- Current Qns / Total Num of Qns for result page-->
<!--  <div v-if="roomState === 2">
        {{ questionData.question_index + 1 }} / {{ questionData.length }}
      </div> -->
        <div v-if="exitPopup"
        class="multiplayer__room--exit-popup animated fadeIn faster">
        <div class="multiplayer__room--exit-popup--container">
          <h3>
            Are you sure you want to exit?
          </h3>
          <div class="multiplayer__room--exit-popup--btns">
            <router-link
              :to="{name:'profile-main'}">
              Yes
            </router-link>
            <a v-on:click="exitPopup = false">No</a>
          </div>
        </div>
      </div>
    </div>
    <div v-if="roomState === 1"
      class="multiplayer__room--header multiplayer__room--header-question">
        <!-- TODO: Replace with total question num -->
        题目：{{ questionData.question_index + 1 }}
    </div>
    <room-waiting
      v-if="roomState === 0"
      :teacher="teacher"></room-waiting>
    <room-question
      v-if="roomState === 1"
      :question-data="questionData"
      :answer-question="answerQuestion"
      :timer-duration="timerDuration">
    </room-question>
    <room-result
      v-if="roomState === 2"
      :correct-answer="questionData['correct_answer']"
      :student-answer="studentAnswer"
      :question-score="questionScore"
      :streak-bonus="streakBonus"
      :total-score="totalScore">
    </room-result>
    <room-end
      v-if="roomState === 3"
      :group-session="true"
      :ending-analytics="endingAnalytics"
      :session-id="sessionId">
    </room-end>
    <div class="multiplayer__room--footer">
      <div class="multiplayer__room--footer__student--name">
        <p>{{ studentName }}</p>
        <span>|</span>
        <p>{{ level | splitCapitalise }}</p>
      </div>
      <div class="multiplayer__room--footer__student--score">
        <i class="fas fa-trophy"></i>
        <span>{{ totalScore }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import Pusher from 'pusher-js';
import axios from 'axios';
import {mapState} from 'vuex';
import Vue from 'vue';
import VueNoty from 'vuejs-noty';

// https://github.com/renoguyon/vuejs-noty?ref=madewithvuejs.com
Vue.use(VueNoty);

import RoomWaitingComponent
  from './room/WaitingComponent.vue';

import RoomQuestionComponent
  from './room/QuestionComponent.vue';

import RoomResultComponent
  from './room/ResultComponent.vue';

import RoomEndComponent
  from './room/EndComponent.vue';

export default {
  name: 'MultiplayerRoom',
  components: {
    RoomWaiting: RoomWaitingComponent,
    RoomQuestion: RoomQuestionComponent,
    RoomResult: RoomResultComponent,
    RoomEnd: RoomEndComponent,
  },
  data() {
    return {
      // pusher
      pusher: null,
      channelName: '',
      socketId: null,
      sessionId: -1,
      // room states
      // 0 for waiting for teacher to start game
      // 1 for when question is sent out
      // 2 for when results are shown
      roomState: 0,
      teacher: {},
      questionData: {},
      studentAnswer: '',
      exitPopup: false,

      // in seconds
      timerDuration: 3,
      endingAnalytics: null,
      questionScore: 0,
      streakBonus: 0,
      totalScore: 0,

      roomId: '',
    };
  },
  computed: {
    ...mapState(['studentId', 'studentName', 'level']),
    ...mapState('multiplayer', ['info']),
  },
  mounted: function() {
    if (typeof this.info['session_id'] === 'undefined') {
      this.$router.push({name: 'multiplayer-room-selection'});
      return;
    }

    const app = this;
    Pusher.logToConsole = true;

    axios
        .post('/multiplayer/api/room/enter_room/', {
          sessionId: this.info['session_id'],
        })
        .then((response) => {
          this.roomId = response.data['pk'];
          this.teacher = response.data.teacher;
          this.channelName = response.data.channel_name;
          const pusherKey = response.data.pusher_key;

          this.pusher = new Pusher(pusherKey, {
            cluster: 'ap1',
            authEndpoint: '/api/v1/core/pusher/auth/',
            auth: {
              headers: {
                'Authorization': 'Token ' + localStorage.getItem('user-token'),
              },
            },
          });

          this.pusher.connection.bind('connected', () => {
            console.log('connected');
            app.socketId = app.pusher.connection.socket_id;
          });

          // when receiving teacher:question message
          this.channel = app.pusher.subscribe(this.channelName);

          // important for when teacher creates a new session/quiz and child
          // is already in channel
          // we want student to be able to seamlessly go to new quiz
          // technically we don't need to update sessionId since it's
          // only used in initial axios post
          // but we update any way to avoid potential bugs in future
          // note: isn't called initially  where teacher first creates the room
          // and student joins for the first time
          // this.channel.bind('teacher:initial', (data) => {
          //   console.log(data);
          //   this.sessionId = response.data['session_id'];
          //   const isAttempted = false;
          //   this.receiveQuestion(data['status'],
          //       data['question'], isAttempted);
          // });

          this.channel.bind('teacher:question', (data) => {
            // KIV -> messy with how we deal with isattempted
            console.log('receiving teacher:question');
            const isAttempted = data['attempted'][this.studentId];
            this.receiveQuestion(data['status'],
                data['question'], isAttempted);
          });

          // // this is when other students have answered
          // // sends a notification
          // this.channel.bind('student:answer', function(data) {
          //   const msg = data['sender_display_name'] + ' has answered ' +
          //       (data['is_correct'] ? 'correctly' : 'wrongly');
          //   app.$noty.info(msg);
          // }, this);

          // this is when teacher has started a new session, and we want to
          // end any old sessions to prevent weird bugs
          this.channel.bind('teacher:end_old_sessions', (data) => {
            this.endOldSession(data);
          });

          // this is when teachers send an end quiz notification
          this.channel.bind('teacher:end', (data) => {
            this.roomState = 3;
            console.log(data);
            this.endingAnalytics = data.analytics[app.studentId];
            // const msg = data['sender_display_name'] + ' has answered ' +
            //     (data['is_correct'] ? 'correctly' : 'wrongly');
            // app.$noty.info(msg);
          });

          // end pusher bindings

          // to be run only if session is already started
          if (response.data['session_started']) {
            // we want to track session_id
            // if session_id is -1 in the future, student hasn't joined yet
            // and we can make student join
            this.sessionId = response.data['session_id'];
            if (response.data['question'].attempted_answer) {
              this.studentAnswer =
                  response.data['question'].attempted_answer;
            }

            // on initial load
            this.receiveQuestion(response.data['status'],
                response.data['question']);
          };
        });
  },
  methods: {
    // we update sessionId, and repull and update questions
    // don't need to rebind pusher stuff
    endOldSession(data) {
      this.roomState = 0;
      console.log(data);
      console.log('ending old session');
      console.log(data['session_id']);
      axios
          .post('/multiplayer/api/room/enter_room/', {
            sessionId: data['session_id'],
          })
          .then((response) => {
            this.receiveQuestion(response.data['status'],
                response.data['question']);
          });
    },
    // if question is attempted, show results page
    toggleRoomState(sessionStatus, isAttempted) {
      if (isAttempted) {
        this.roomState = 2;
      } else if (sessionStatus === 'ongoing') {
        // if haven't done qn, but room session is ongoing, then show qn
        this.roomState = 1;
      };
    },
    // to be called a) initial load, b) when receive question event
    receiveQuestion(sessionStatus, questionData, isAttempted) {
      this.questionData = questionData;
      if (typeof questionData['question_score'] !== 'undefined') {
        this.questionScore = questionData['question_score'];
        this.totalScore = questionData['total_score'];
      };

      // if isAttempted is not passed, try to take from questionData
      if (typeof isAttempted === 'undefined') {
        isAttempted = questionData['is_attempted'];
      }
      this.toggleRoomState(sessionStatus, isAttempted);
    },
    answerQuestion(choice) {
      this.studentAnswer = choice;
      // pass socketId, in case we want to exclude sending to ownself
      axios
          .post('/multiplayer/api/room/' + this.roomId + '/student_answer/', {
            attemptedAnswer: choice,
            socketId: this.socketId,
            timerDuration: this.timerDuration,
          })
          .then((response) => {
            this.questionScore = response.data['question_score'];
            this.totalScore = response.data['total_score'];
            this.streakBonus = response.data['streak_bonus'];
            this.roomState = 2;
          });
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '~vuejs-noty/dist/vuejs-noty.css';

  .multiplayer__room {
    &--component {
      font-family: 'baloo da 2';
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      z-index: 20;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      background: #e8e8e8;
    }
    &--exit {
      color: #a0a0a040;
      font-size: 1.8rem;
      cursor: pointer;
      &:hover {
        color: #a0a0a080;
      }
      &-popup {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #00000090;
        height: 100%;
        width: 100%;
        text-align: center;
        top: 0;
        left: 0;
        z-index: 30;
        &--container {
          padding: 2rem;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          background: #fff;
          border-radius: 5px;
          z-index: 20;
        }
        &--btns {
          margin-top: 20px;
          a {
            margin: 0 5px;
            display: inline-block;
            font-weight: 900;
            color: #fff;
            background: #e2e2e2;
            text-decoration: none;
            outline: none;
            border: 4px solid #c3c3c3;
            cursor: pointer;
            transition: all 0.3s;
            padding: 2.5px 10px;
            border-radius: 5px;
            width: 100px;
            &:hover {
              background: #969696;
              border: 4px solid #5d5d5d;
            }
          }
        }
      }
    }
    &--header,
    &--footer {
      position: absolute;
      background: #fff;
      width: 100%;
      padding: 10px 20px;
      font-weight: 900;
      font-size: 1.1rem;
    }
    &--header {
      border-bottom: 4px solid #555;
      top: 0;
      display: flex;
      justify-content: space-between;
      z-index: 20;
      height: 60px;
      &-question {
        justify-content: center;
        border-bottom: 4px solid #222;
        color: #fff;
        background: #5e2c77;
        width: 100%;
      }
    }
    &--footer {
      border-top: 4px solid #555;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      &__student {
        &--name {
          display: flex;
          span {
            margin: 0 10px;
          }
        }
        &--score {
          i {
            font-size: .85rem;
            color: #b7b7b7;
          }
          padding: 0px 15px;
          border-radius: 5px;
          background: #5f5f5f;
          color: #fff;
        }
      }
    }
  }
</style>
