<template>
  <div
    v-if="isShowSuptopicWordList"
    class="word-list__component">
    <h1 class="word-list__header">Revision</h1>
    <pinyin-list
      :show-pinyin-list="false"
      :words-to-recognise="wordsToRecognise"
      :manifest="manifest">
    </pinyin-list>
    <button
      @click="hideWordList"
      class="back-btn">Back to quiz</button>
  </div>
</template>

<script>
// import axios from 'axios';
import {mapState, mapMutations} from 'vuex';

import PinyinListComponent from '../common/PinyinListComponent.vue';

export default {
  name: 'SubtopicWordListPopup',
  components: {
    PinyinList: PinyinListComponent,
  },
  props: {
    showWordList: {
      type: Boolean,
      default: false,
    },
    wordsToRecognise: Array,
    manifest: Array,
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapState('learningTrack', ['isShowSuptopicWordList']),
  },
  methods: {
    ...mapMutations('learningTrack', ['setIsShowSuptopicWordList']),
    hideWordList(event) {
      this.setIsShowSuptopicWordList(false);
    },
  },
};
</script>

<style lang="scss" scoped>
  .word-list {
    &__component {
      display: flex;
      flex-direction: column;
    }
    &__header {
      color: #fff;
      margin-bottom: 20px;
    }
  }
  .back-btn  {
    background-color: #c33e5d;
    text-transform: capitalize;
    color: #fff;
    font-size: 18px;
    margin-bottom: 50px;
    padding: 10px 40px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    &:focus {
      outline: none;
      border: none;
    }
  }

  @media only screen and (max-width: 490px) {
    .back-btn {
      padding: 10px 30px;
      font-size: 16px;
      margin-bottom: 40px;
    }
  }
  @media only screen and (max-width: 450px) {
    .back-btn {
      padding: 10px 20px;
      font-size: 14px;
    }
  }
</style>
