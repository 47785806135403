<template>
  <div class='icon-scroll' v-if="!scrolledToBottom"></div>
</template>

<script>

export default {
  name: 'ScrollReminder',
  components: {
  },
  props: {
    document: Object,
  },
  data() {
    return {
      scrolledToBottom: false,
    };
  },
  methods: {
    handleScroll() {
      const bottomOfWindow = Math.max(window.pageYOffset, document.documentElement.scrollTop, document.body.scrollTop) + window.innerHeight === document.documentElement.offsetHeight;

      if (bottomOfWindow) {
        this.scrolledToBottom = true;
      }
    },
  },
  created() {
    document.addEventListener('scroll', this.handleScroll, true);
  },
  destroyed() {
    document.removeEventListener('scroll', this.handleScroll);
  },
};
</script>

<style lang="scss" scoped>
  .icon-scroll,
  .icon-scroll:before {
    position: fixed;
    left: 50%;
  }

  .icon-scroll {
    width: 40px;
    height: 70px;
    margin-left: -20px;
    top: 90%;
    margin-top: -35px;
    box-shadow: inset 0 0 0 1px #403a3a;
    border-radius: 25px;
  }

  .icon-scroll:before {
    content: '';
    width: 8px;
    height: 8px;
    background: #403a3a;
    margin-left: -4px;
    top: calc(90% - 20px);
    border-radius: 4px;
    animation-duration: 1.5s;
    animation-iteration-count: infinite;
    animation-name: scroll;
  }

  @keyframes scroll {
    0% {opacity: 1;}
    100% {
      opacity: 0;
      transform: translateY(46px);
    }
  }
</style>
