<template>
  <div class="fixed-component">
    <div class="change-password__component row">
      <h2 class="change-password__header">Change Password</h2>
      <div class="change-password__content">
        <div class="avatar-container">
          <div class="avatar-img">
            <img v-if="avatar !== ''"
              :src='"../assets/avatars/" + avatar + ".png"' />
          </div>
          <h4>{{ studentName }}</h4>
        </div>
        <form method="post" class="change-password__content--form">
          <span
            v-if="errorMessage !== ''"
            class="error-message">
            {{ errorMessage }}
          </span>
          <h4 class="change-password__content--form-title">Current Password</h4>
          <input
            autocomplete="off"
            v-model="input.oldPassword"
            class="change-password__content--form-input"
            type="password"
            placeholder="Old Password..."/>
          <h4 class="change-password__content--form-title">New Password</h4>
          <input
            autocomplete="off"
            v-model="input.newPassword"
            class="change-password__content--form-input"
            type="password"
            placeholder="New Password..."/>
          <h4 class="change-password__content--form-title">
            Confirm New Password
          </h4>
          <input
            autocomplete="off"
            v-model="input.newDuplicatePassword"
            class="change-password__content--form-input"
            placeholder="Type New Password again..."
            type="password"/>
          <button
            class="btn-submit"
            type="submit"
            @click.prevent="changePassword">
            Submit
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex';
import axios from 'axios';

export default {
  name: 'ChangePassword',
  components: {
  },
  props: {
  },
  data() {
    return {
      input: {
        oldPassword: '',
        newPassword: '',
        newDuplicatePassword: '',
      },
      errorMessage: '',
    };
  },
  computed: {
    ...mapState(['studentName', 'avatar', 'level']),
  },
  mounted: function() {
  },
  methods: {
    changePassword() {
      this.errorMessage = '';

      if (this.input.oldPassword != ''
        && this.input.newPassword != ''
        && this.input.newDuplicatePassword != '') {
        if (this.input.newPassword !== this.input.newDuplicatePassword) {
          this.errorMessage = 'New passwords must match';
          return;
        };
        axios
            .put('/api/v1/core/change_password/', {
              old_password: this.input.oldPassword,
              new_password: this.input.newPassword,
            })
            .then((response) => {
              alert('Successfully changed password.');
              this.$router.push({name: 'profile-main'});
            })
            .catch((error) => {
              this.errorMessage = 'Error changing password';
            });
      } else {
        this.errorMessage = 'Please fill in all the fields.';
      };
    },
  },
};

</script>

<style lang="scss" scoped>
  .change-password {
    &__component {
      margin: 20px auto;
      text-align: center;
      background: #fff;
      border-radius: 3px;
    }
    &__header {
      background: #5a4179;
      color: #fff;
      padding: 10px;
      border-radius: 3px;
    }
    &__content {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 40px 40px 80px 40px;
      margin: 0 auto;
      &--form {
        position: relative;
        &-title{
          text-align: left;
          margin-bottom: 6px;
        }
        &-input{
          background-color: #eee;
          padding: 15px;
          width: 550px;
          display: block;
          border-radius: 10px;
          outline: none;
          border: 2px solid #ddd;
          margin-bottom: 12px;
          &:focus {
            border: 2px solid #ac2fb1;
          }
        }
      }
    }
  }

  .error-message {
    color: red;
    position: absolute;
    top: 0;
    right: 0;
  }
  .avatar-container {
    margin-top: 25px;
    align-self: flex-start;
    border: 3px solid #000;
    text-align: center;
    border-radius: 10px;
    margin-right: 50px;
  }

  .avatar-img {
    padding: 10px 10px 5px 10px;
  }

  .avatar-img + h4 {
    margin-bottom: 10px;
  }

  .btn-submit {
    position: absolute;
    right: 0;
    bottom: -55px;
    font-size: 15px;
    color: #fff;
    text-transform: uppercase;
    display: block;
    width: 200px;
    height: 50px;
    border-radius: 25px;
    background: #8654c7;
    padding: 0 25px;
    cursor: pointer;
    transition: all 0.2s;
    &:focus {
      outline: none;
    }
    &:hover {
      transform: scale(1.04);
      background-color: #a570ea;
    }
    &:active {
      transform: scale(1.02);
      background: #9359de;
    }
  }

  @media only screen and (max-width: 840px) {
    .change-password__content {
      flex-direction: column;
      padding: 40px;
      margin-bottom: 64px;
    }
    .avatar-container {
      margin: 0 auto 20px auto;
    }
    .btn-submit {
      margin: 10px auto;
      position: relative;
      bottom: 0;
    }
  }

  @media only screen and (max-width: 640px) {
    .change-password__content--form-input {
      width: 400px;
    }
    .avatar-container { display: none; }
  }
  @media only screen and (max-width: 490px) {
    .change-password__content--form-input {
      width: 300px;
    }
    .error-message {
      top: -30px;
      right: 0;
      left: 0;
    }
  }
  @media only screen and (max-width: 350px) {
    .change-password__content--form-input {
      width: 270px;
    }
  }
  </style>
