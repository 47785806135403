// import Vue from 'vue';

const getDefaultState = () => {
  return {
    data: [
      {
        // book id
        id: 0,
        title: '冬天来了',
        sceneData: [
          {
            id: 0,
            name: 'title-scene',
            options: {
              text: '冬天来了',
              subtitle: '',
            },
            transition: 'fadeIn',
            nextSceneId: 1,
          },
          {
            id: 1,
            name: 'narration-scene',
            options: {
              narrator: '熊猫博士',
              narratorState: 'wave',
              narratorAnimation: 'single-heartbeat-animation',
              text: '⼩朋友们，你们好！我是熊猫博士。',
              background: 'black',
            },
            transition: 'fadeIn',
            nextSceneId: 2,
          },
          {
            id: 2,
            name: 'narration-scene',
            options: {
              narrator: '熊猫博士',
              narratorState: 'happyhandsonhips',
              narratorAnimation: 'single-heartbeat-animation',
              text: '欢迎你们来到故事国。',
              background: 'black',
            },
            transition: 'fadeIn',
            nextSceneId: 3,
          },
          {
            id: 3,
            name: 'frame-clickable-popup-scene',
            options: {
              portrait: 'panda-boshi',
              text: '故事国里有很多⽣动有趣的故事，你们想听吗？',
              choices: [{
                text: '想',
                nextSceneId: 4,
                imageName: 'food-2',
              },
              {
                text: '不想',
                nextSceneId: 5,
                imageName: 'food-2',
              }],
            },
            transition: 'fadeIn',
          },
          {
            id: 4,
            name: 'narration-scene',
            options: {
              narrator: '熊猫博士',
              narratorState: 'eureka',
              narratorAnimation: 'single-heartbeat-animation',
              narratorAnimation: 'single-shake-animation',
              text: '太好了！这⼀次，我们来听⼀听熊猫过节的故事吧！',
              background: 'black',
            },
            transition: 'fadeIn',
            nextSceneId: 8,
          },
          {
            id: 5,
            name: 'narration-scene',
            options: {
              narrator: '熊猫博士',
              narratorState: 'sad',
              narratorAnimation: 'single-heartbeat-animation',
              text: '啊？不要？真的不要吗？',
              background: 'black',
            },
            transition: 'fadeIn',
            nextSceneId: 6,
          },
          {
            id: 6,
            name: 'narration-scene',
            options: {
              narrator: '熊猫博士',
              narratorState: 'reluctantsmile',
              narratorAnimation: 'single-heartbeat-animation',
              text: '这个故事很好听哦，我相信你会喜欢的。',
              background: 'black',
            },
            transition: 'fadeIn',
            nextSceneId: 7,
          },
          {
            id: 7,
            name: 'narration-scene',
            options: {
              narrator: '熊猫博士',
              narratorState: 'eureka',
              narratorAnimation: 'single-heartbeat-animation',
              text: '来吧，我们一起听⼀听！',
              background: 'black',
            },
            transition: 'fadeIn',
            nextSceneId: 8,
          },
          {
            id: 8,
            name: 'frame-scene',
            options: {
              portrait: '',
              text: '在故事国的⽵林⾥，住着熊猫⼀家。',
              frameIndex: 0,
            },
            transition: 'fadeIn',
            nextSceneId: 9,
          },
          // kiv -> custom frame scene (replace text with images for choices)
          {
            id: 9,
            name: 'frame-clickable-popup-scene',
            options: {
              text: '熊猫家里有熊猫爸爸、熊猫妈妈、熊猫姐姐和熊猫弟弟。',
              challengeText: '熊猫家里有高大的熊猫爸爸、温柔的熊猫妈妈、可爱的熊猫姐姐和胖胖的熊猫弟弟。',
              portrait: '',
              // replace with image in the future
              choices: [{
                text: '弟弟',
                nextSceneId: 18,
                imageName: 'panda-bro',
              },
              {
                text: '妈妈',
                nextSceneId: 13,
                imageName: 'panda-mum',
              },
              {
                text: '爸爸',
                nextSceneId: 10,
                imageName: 'panda-dad',
              },
              {
                text: '姐姐',
                nextSceneId: 16,
                imageName: 'panda-sis',
              }],
            },
            transition: 'fadeIn',
            nextSceneId: 21,
          },
          {
            id: 10,
            name: 'narration-scene',
            options: {
              narrator: '熊猫爸爸',
              narratorState: 'wave',
              narratorAnimation: 'single-heartbeat-animation',
              text: '我是熊猫爸爸。',
              background: 'bamboo-green',
            },
            // animation to execute when first loading scene
            transition: 'fadeIn',
            nextSceneId: 11,
          },
          {
            id: 11,
            name: 'narration-scene',
            options: {
              narrator: '熊猫爸爸',
              narratorState: 'happyhandsonhips',
              narratorAnimation: 'single-heartbeat-animation',
              text: '我是家⾥长得最高大的。',
              background: 'bamboo-green',
            },
            transition: 'fadeIn',
            nextSceneId: 12,
          },
          // kiv 互动
          {
            id: 12,
            name: 'narration-scene',
            options: {
              narrator: '熊猫爸爸',
              narratorState: 'wand',
              narratorAnimation: 'single-heartbeat-animation',
              text: '我是⼀位魔术师，我会变魔术。',
              background: 'bamboo-green',
            },
            transition: 'fadeIn',
            nextSceneId: 9,
          },
          {
            id: 13,
            name: 'narration-scene',
            options: {
              narrator: '熊猫妈妈',
              narratorState: 'wave',
              narratorAnimation: 'single-heartbeat-animation',
              text: '我是熊猫妈妈。',
              background: 'bamboo-green',
            },
            transition: 'fadeIn',
            nextSceneId: 14,
          },
          {
            id: 14,
            name: 'narration-scene',
            options: {
              narrator: '熊猫妈妈',
              narratorState: 'happyarmsfolded',
              narratorAnimation: 'single-heartbeat-animation',
              text: '我是家⾥厨艺最好的。',
              background: 'bamboo-green',
            },
            transition: 'fadeIn',
            nextSceneId: 15,
          },
          // kiv 互动
          {
            id: 15,
            name: 'narration-scene',
            options: {
              narrator: '熊猫妈妈',
              narratorState: 'cook',
              narratorAnimation: 'single-heartbeat-animation',
              text: '我是⼀名厨师。',
              background: 'bamboo-green',
            },
            transition: 'fadeIn',
            nextSceneId: 9,
          },
          {
            id: 16,
            name: 'narration-scene',
            options: {
              narrator: '熊猫姐姐',
              narratorState: 'wave',
              narratorAnimation: 'single-heartbeat-animation',
              text: '我是熊猫姐姐。',
              background: 'bamboo-green',
            },
            transition: 'fadeIn',
            nextSceneId: 17,
          },
          // kiv 互动
          {
            id: 17,
            name: 'narration-scene',
            options: {
              narrator: '熊猫姐姐',
              narratorState: 'cheerful',
              narratorAnimation: 'single-heartbeat-animation',
              text: '我喜欢跟妈妈学煮东⻄。',
              challengeText: '我非常喜欢煮东西，经常跟妈妈学厨艺。',
              background: 'bamboo-green',
            },
            transition: 'fadeIn',
            nextSceneId: 9,
          },
          {
            id: 18,
            name: 'narration-scene',
            options: {
              narrator: '熊猫弟弟',
              narratorState: 'wave',
              narratorAnimation: 'single-heartbeat-animation',
              text: '我是熊猫弟弟。',
              background: 'bamboo-green',
            },
            transition: 'fadeIn',
            nextSceneId: 19,
          },
          {
            id: 19,
            name: 'narration-scene',
            options: {
              narrator: '熊猫弟弟',
              narratorState: 'cheerful',
              narratorAnimation: 'single-heartbeat-animation',
              text: '我喜欢看妈妈和姐姐煮东⻄，有时候也会帮忙。',
              background: 'bamboo-green',
            },
            transition: 'fadeIn',
            nextSceneId: 20,
          },
          // kiv 互动
          {
            id: 20,
            name: 'narration-scene',
            options: {
              narrator: '熊猫弟弟',
              narratorState: 'thumbsup',
              narratorAnimation: 'single-heartbeat-animation',
              text: '我最喜欢的就是吃。',
              challengeText: '可是我最爱的就是吃。',
              background: 'bamboo-green',
            },
            transition: 'fadeIn',
            nextSceneId: 9,
          },
          {
            id: 21,
            name: 'frame-scene',
            options: {
              text: '你们看，熊猫⼀家⼈正在⽵林⾥做什么呢?',
              frameIndex: 1,
              portrait: '',
            },
            transition: 'fadeIn',
            nextSceneId: 22,
          },
          {
            id: 22,
            name: 'frame-scene',
            options: {
              text: '原来，⼤家都在看熊猫爸爸表演魔术。',
              challengeText: '原来，⼤家都在全神贯注地看熊猫爸爸表演魔术。',
              frameIndex: 1,
              portrait: '',
            },
            transition: 'fadeIn',
            nextSceneId: 23,
          },
          {
            id: 23,
            name: 'narration-scene',
            options: {
              narrator: '熊猫爸爸',
              narratorState: 'wand',
              narratorAnimation: 'single-heartbeat-animation',
              text: '我变，我变，我变、变、变！',
              background: 'bamboo-green',
            },
            transition: 'fadeIn',
            nextSceneId: 24,
          },
          {
            id: 24,
            name: 'frame-scene',
            options: {
              text: '熊猫爸爸变出了⼀只⼩白鸽。',
              frameIndex: 2,
              portrait: '',

            },
            transition: 'fadeIn',
            nextSceneId: 25,
          },
          {
            id: 25,
            name: 'narration-scene',
            options: {
              narrator: '熊猫姐姐和弟弟',
              narratorState: 'cheerful',
              narratorAnimation: 'single-heartbeat-animation',
              text: '爸爸，你好厉害啊！',
              background: 'bamboo-green',
            },
            transition: 'fadeIn',
            nextSceneId: 26,
          },
          {
            id: 26,
            name: 'narration-scene',
            options: {
              narrator: '熊猫爸爸',
              narratorState: 'happyhandsonhips',
              narratorAnimation: 'single-heartbeat-animation',
              text: '这只是⼀个简单的小魔术。',
              challengeText: '这只是最简单的⼩小魔术而已，对我来说轻而易举。',
              background: 'bamboo-green',
            },
            transition: 'fadeIn',
            nextSceneId: 27,
          },
          {
            id: 27,
            name: 'narration-scene',
            options: {
              narrator: '熊猫爸爸',
              narratorState: 'think',
              narratorAnimation: 'single-heartbeat-animation',
              text: '我接下来要变什么魔术呢?',
              background: 'bamboo-green',
            },
            transition: 'fadeIn',
            nextSceneId: 28,
          },
          {
            id: 28,
            name: 'frame-scene',
            options: {
              text: '熊猫爸爸在用心地想要变什么魔术。',
              challengeText: '熊猫爸爸正在认真地想要表演的魔术节目。',
              frameIndex: 3,
              portrait: '',
            },
            transition: 'fadeIn',
            nextSceneId: 29,
          },
          {
            id: 29,
            name: 'frame-scene',
            options: {
              text: '⼩朋友们，你们看！',
              frameIndex: 4,
              portrait: '',

            },
            transition: 'fadeIn',
            nextSceneId: 30,
          },
          {
            id: 30,
            name: 'frame-scene',
            options: {
              text: '下⼤雪了！绿色的竹林变成了白⾊的⽵林。',
              challengeText: '天上突然开始下大雪了，绿色的⽵林慢慢地变成了白色的⽵林。',
              frameIndex: 5,
              portrait: '',

            },
            transition: 'fadeIn',
            nextSceneId: 32,
          },
          {
            id: 32,
            name: 'narration-scene',
            options: {
              narrator: '熊猫爸爸',
              narratorState: 'happyhandsonhips',
              narratorAnimation: 'single-heartbeat-animation',
              text: '下雪了！',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 33,
          },
          {
            id: 33,
            name: 'narration-scene',
            options: {
              narrator: '熊猫妈妈',
              narratorState: 'happyhandsonhips',
              narratorAnimation: 'single-heartbeat-animation',
              text: '⽩雪把⽵林变得好漂亮啊！',
              challengeText: '竹林被白雪装扮，变得好漂亮啊！',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 34,
          },
          {
            id: 34,
            name: 'narration-scene',
            options: {
              narrator: '熊猫姐姐和弟弟',
              narratorState: 'cheerful',
              narratorAnimation: 'single-heartbeat-animation',
              text: '冬天来了！圣诞节也要到了！太好了！',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 35,
          },
          {
            id: 35,
            name: 'narration-scene',
            options: {
              narrator: '熊猫姐姐',
              narratorState: 'cheerful',
              narratorAnimation: 'single-heartbeat-animation',
              text: '爸爸、爸爸！你能变出⼀棵⼤圣诞树吗?',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 36,
          },
          {
            id: 36,
            name: 'narration-scene',
            options: {
              narrator: '熊猫弟弟',
              narratorState: 'cheerful',
              narratorAnimation: 'single-heartbeat-animation',
              text: '是呀，爸爸，圣诞树底下要放很多的礼物！',
              challengeText: '是呀，爸爸，圣诞树底下要放的礼物哦！礼物越多越好。',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 37,
          },
          {
            id: 37,
            name: 'narration-scene',
            options: {
              narrator: '熊猫爸爸',
              narratorState: 'happyarmsfolded',
              narratorAnimation: 'single-heartbeat-animation',
              text: '当然可以！我现在就变⼀棵圣诞树。',
              challengeText: '当然可以！我现在就变一棵圣诞树，让你们如愿以偿。',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 38,
          },
          {
            id: 38,
            name: 'narration-scene',
            options: {
              narrator: '熊猫爸爸',
              narratorState: 'wand',
              narratorAnimation: 'single-heartbeat-animation',
              text: '我变，我变，我变、变、变！',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 39,
          },
          {
            id: 39,
            name: 'frame-scene',
            options: {
              text: '熊猫爸爸变出了⼀棵⼤圣诞树，树底下还有很多的礼物。',
              challengeText: '熊猫爸爸不仅变出了一棵⼤圣诞树，树底下还放着⾮常多的礼物。',
              frameIndex: 6,
              portrait: '',

            },
            transition: 'fadeIn',
            nextSceneId: 40,
          },
          {
            id: 40,
            name: 'narration-scene',
            options: {
              narrator: '熊猫姐姐和弟弟',
              narratorState: 'cheerful',
              narratorAnimation: 'single-heartbeat-animation',
              text: '好多礼物！我们一起来拆礼物吧！',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 41,
          },
          {
            id: 41,
            name: 'narration-scene',
            options: {
              narrator: '熊猫妈妈',
              narratorState: 'stop',
              narratorAnimation: 'single-heartbeat-animation',
              text: '等⼀等！你们要等到圣诞节时才能拆礼物。',
              challengeText: '等一等！你们应该等到圣诞节的时候再拆开礼物。',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 42,
          },
          {
            id: 42,
            name: 'narration-scene',
            options: {
              narrator: '熊猫弟弟',
              narratorState: 'sad',
              narratorAnimation: 'single-heartbeat-animation',
              text: '圣诞节时？还要等好久。',
              challengeText: '圣诞节的时候？那还要等好长一段时间。',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 43,
          },
          {
            id: 43,
            name: 'narration-scene',
            options: {
              narrator: '熊猫姐姐',
              narratorState: 'sad',
              narratorAnimation: 'single-heartbeat-animation',
              text: '那我们现在该做什么呢？',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 44,
          },
          {
            id: 44,
            name: 'frame-scene',
            options: {
              text: '熊猫爸爸和妈妈想了想。',
              frameIndex: 7,
              portrait: '',

            },
            transition: 'fadeIn',
            nextSceneId: 45,
          },
          {
            id: 45,
            name: 'narration-scene',
            options: {
              narrator: '熊猫妈妈',
              narratorState: 'happyhandsonhips',
              narratorAnimation: 'single-heartbeat-animation',
              text: '我知道了！我们来准备庆祝冬至吧。',
              challengeText: '我知道了！不如我们来准备庆祝冬至吧。',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 46,
          },
          {
            id: 46,
            name: 'narration-scene',
            options: {
              narrator: '熊猫爸爸',
              narratorState: 'happyhandsonhips',
              narratorAnimation: 'single-heartbeat-animation',
              text: '庆祝冬至？这是⼀个好主意。',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 47,
          },
          {
            id: 47,
            name: 'narration-scene',
            options: {
              narrator: '熊猫姐姐',
              narratorState: 'puzzled',
              narratorAnimation: 'single-heartbeat-animation',
              text: '冬至？冬至是什么？',
              challengeText: '冬⾄？冬至是什么节日？',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 48,
          },
          {
            id: 48,
            name: 'narration-scene',
            options: {
              narrator: '熊猫妈妈',
              narratorState: 'educatetwo',
              narratorAnimation: 'single-heartbeat-animation',
              text: '冬⾄是华⼈的传统节⽇。冬⾄这天是⼀年中⽩天最短的⽇子。',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 49,
          },
          {
            id: 49,
            name: 'narration-scene',
            options: {
              narrator: '熊猫弟弟',
              narratorState: 'cheerful',
              narratorAnimation: 'single-heartbeat-animation',
              text: '冬⾄和圣诞节⼀样吗？是不是也可以收到很多礼物？',
              challengeText: '冬⾄是不是像圣诞节⼀样，也可以收到非常多的礼物？',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 50,
          },
          {
            id: 50,
            name: 'narration-scene',
            options: {
              narrator: '熊猫爸爸',
              narratorState: 'laughtwo',
              narratorAnimation: 'single-heartbeat-animation',
              text: '你还⼀直想着礼物啊？',
              challengeText: '你脑⼦里还⼀直想着礼物啊？',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 51,
          },
          {
            id: 51,
            name: 'narration-scene',
            options: {
              narrator: '熊猫爸爸',
              narratorState: 'eureka',
              narratorAnimation: 'single-heartbeat-animation',
              text: '冬⾄是没有礼物的。',
              challengeText: '冬至不像圣诞节。华人庆祝冬至是没有礼物的。',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 52,
          },
          {
            id: 52,
            name: 'narration-scene',
            options: {
              narrator: '熊猫弟弟',
              narratorState: 'sad',
              narratorAnimation: 'single-heartbeat-animation',
              text: '没有礼物？不好玩。',
              challengeText: '没有礼物？真没意思！',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 53,
          },
          {
            id: 53,
            name: 'narration-scene',
            options: {
              narrator: '熊猫爸爸',
              narratorState: 'eureka',
              narratorAnimation: 'single-heartbeat-animation',
              text: '但是冬至可以吃很多美⾷。',
              challengeText: '冬⾄虽然不能收到礼物，但是可以吃很多美食。',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 54,
          },
          {
            id: 54,
            name: 'narration-scene',
            options: {
              narrator: '熊猫姐姐和弟弟',
              narratorState: 'happy',
              narratorAnimation: 'single-heartbeat-animation',
              text: '美⾷？',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 55,
          },
          {
            id: 55,
            name: 'narration-scene',
            options: {
              narrator: '熊猫姐姐和弟弟',
              narratorState: 'cheerful',
              narratorAnimation: 'single-heartbeat-animation',
              text: '太好了！',
              challengeText: '太好了！我们喜欢吃美食。',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 56,
          },
          {
            id: 56,
            name: 'narration-scene',
            options: {
              narrator: '熊猫⼀家⼈',
              narratorState: 'happy',
              narratorAnimation: 'single-heartbeat-animation',
              text: '我们⼀起庆祝冬⾄吧！',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 57,
          },
          {
            id: 57,
            name: 'narration-scene',
            options: {
              narrator: '熊猫博士',
              narratorState: 'happyhandsdown',
              narratorAnimation: 'single-heartbeat-animation',
              text: '⼩朋友们，熊猫⼀家要庆祝冬至了。',
              challengeText: '⼩朋友们，熊猫一家即将庆祝冬至。',
              background: 'black',
            },
            transition: 'fadeIn',
            nextSceneId: 58,
          },
          {
            id: 58,
            name: 'narration-scene',
            options: {
              narrator: '熊猫博士',
              narratorState: 'confused',
              narratorAnimation: 'single-heartbeat-animation',
              text: '你们知道在冬⾄会吃到什么吗？',
              challengeText: '你们知道在冬至会吃到什么美食吗？',
              background: 'black',
            },
            transition: 'fadeIn',
            nextSceneId: 59,
          },
          {
            id: 59,
            name: 'narration-scene',
            options: {
              narrator: '熊猫博士',
              narratorState: 'eureka',
              narratorAnimation: 'single-heartbeat-animation',
              text: '我和你们⼀起来猜猜看吧！',
              challengeText: '我给你们一个提示，快来猜猜看吧！',
              background: 'black',
            },
            transition: 'fadeIn',
            nextSceneId: 60,
          },
          {
            id: 60,
            name: 'frame-scene',
            options: {
              portrait: 'panda-boshi',
              text: '小朋友们， 你们能猜得到这是什么食物吗？',
              frameIndex: 8,
            },
            transition: 'fadeIn',
            nextSceneId: 0,
          },

        ],
      },
      {
        id: 1,
        title: '冬⾄魔幻精灵',
        sceneData: [
          {
            id: 0,
            name: 'title-scene',
            options: {
              text: '冬⾄魔幻精灵',
            },
            transition: 'fadeIn',
            nextSceneId: 1,
          },
          {
            id: 1,
            name: 'narration-scene',
            options: {
              narrator: '熊猫博士',
              narratorState: 'wave',
              narratorAnimation: 'single-heartbeat-animation',
              text: '⼩朋友们，欢迎回到故事国！',
              background: 'black',
            },
            transition: 'fadeIn',
            nextSceneId: 2,
          },
          {
            id: 2,
            name: 'narration-scene',
            options: {
              narrator: '熊猫博士',
              narratorState: 'eureka',
              narratorAnimation: 'single-heartbeat-animation',
              text: '让我们继续讲熊猫⼀家庆祝冬⾄的故事吧！',
              challengeText: '让我们继续讲故事，来听一听熊猫⼀家怎么庆祝冬至吧！',
              background: 'black',
            },
            transition: 'fadeIn',
            nextSceneId: 3,
          },
          {
            id: 3,
            name: 'frame-scene',
            options: {
              portrait: '',
              text: '在⽩⾊的⽵林⾥，熊猫⼀家正在准备庆祝冬⾄。',
              challengeText: '在⽩色的⽵林里，熊猫一家正在兴高采烈地准备庆祝冬至。',
              frameIndex: 0,
            },
            transition: 'fadeIn',
            nextSceneId: 4,
          },
          {
            id: 4,
            name: 'narration-scene',
            options: {
              narrator: '熊猫爸爸和妈妈',
              narratorState: 'instruct',
              narratorAnimation: 'single-heartbeat-animation',
              text: '我们⼀起来庆祝冬⾄吧！',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 5,
          },
          {
            id: 5,
            name: 'narration-scene',
            options: {
              narrator: '熊猫姐姐',
              narratorState: 'puzzled',
              narratorAnimation: 'single-heartbeat-animation',
              text: '爸爸、妈妈，冬⾄是什么？',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 6,
          },
          {
            id: 6,
            name: 'narration-scene',
            options: {
              narrator: '熊猫爸爸',
              narratorState: 'eureka',
              narratorAnimation: 'single-heartbeat-animation',
              text: '冬⾄是华⼈很重要的传统节⽇。',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 7,
          },
          {
            id: 7,
            name: 'frame-scene',
            options: {
              portrait: 'panda-dad-happy',
              text: '在冬⾄那天，世界上很多国家的华⼈都会和亲戚朋友聚集在⼀起庆祝。',
              frameIndex: 2,
            },
            transition: 'fadeIn',
            nextSceneId: 8,
          },
          {
            id: 8,
            name: 'frame-scene',
            options: {
              portrait: 'panda-dad-happy',
              text: '⼤家聚在⼀起庆祝代表着团团圆圆。',
              challengeText: '像这样，⼤家聚在⼀起庆祝代表着团团圆圆，因此冬至有团圆的含义。',
              frameIndex: 3,
            },
            transition: 'fadeIn',
            nextSceneId: 9,
          },
          {
            id: 9,
            name: 'narration-scene',
            options: {
              narrator: '熊猫妈妈',
              narratorState: 'happyhandsonhips',
              narratorAnimation: 'single-heartbeat-animation',
              text: '冬⾄平时是在12⽉21⽇到23⽇之间庆祝的。',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 10,
          },
          {
            id: 10,
            name: 'narration-scene',
            options: {
              narrator: '熊猫妈妈',
              narratorState: 'educate',
              narratorAnimation: 'single-heartbeat-animation',
              text: '熊猫姐姐、熊猫弟弟，你们猜⼀猜，今年冬⾄是在⼏号庆祝呢？',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 11,
          },
          {
            id: 11,
            name: 'frame-clickable-popup-scene',
            options: {
              portrait: 'panda-siblings-puzzled',
              text: '今年的冬⾄是在哪⼀天庆祝呢？⼩朋友们，你们能帮帮我们吗？请你猜一猜。',
              choices: [{
                text: '21号',
                imageName: 'calendar',
                nextSceneId: 12,
              },
              {
                text: '22号',
                imageName: 'calendar',
                nextSceneId: 13,
              },
              {
                text: '23号',
                imageName: 'calendar',
                nextSceneId: 12,
              }],

            },
            transition: 'fadeIn',
            nextSceneId: 11,
          },
          {
            id: 12,
            name: 'narration-scene',
            options: {
              narrator: '熊猫爸爸',
              narratorState: 'think',
              narratorAnimation: 'single-heartbeat-animation',
              text: '再猜猜看！',
              challengeText: '猜错了。再猜猜看。',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 11,
          },
          {
            id: 13,
            name: 'narration-scene',
            options: {
              narrator: '熊猫爸爸',
              narratorState: 'thumbsup',
              narratorAnimation: 'single-heartbeat-animation',
              text: '好厉害！你们猜对了！',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 14,
          },
          {
            id: 14,
            name: 'narration-scene',
            options: {
              narrator: '熊猫爸爸',
              narratorState: 'eureka',
              narratorAnimation: 'single-heartbeat-animation',
              text: '今年的冬至是在12月22日！',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 15,
          },
          {
            id: 15,
            name: 'narration-scene',
            options: {
              narrator: '熊猫弟弟',
              narratorState: 'puzzled',
              narratorAnimation: 'single-heartbeat-animation',
              text: '爸爸、妈妈，冬⾄时会吃什么食物呢？',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 16,
          },

          {
            id: 16,
            name: 'narration-scene',
            options: {
              narrator: '熊猫爸爸',
              narratorState: 'laughone',
              narratorAnimation: 'single-heartbeat-animation',
              text: '在冬⾄的时候，我们可以吃不同的⾷物。',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 17,
          },
          {
            id: 17,
            name: 'narration-scene',
            options: {
              narrator: '熊猫爸爸',
              narratorState: 'eureka',
              narratorAnimation: 'single-heartbeat-animation',
              text: '让妈妈给你们介绍一下。',
              challengeText: '先让妈妈给你们介绍一下。',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 18,
          },
          {
            id: 18,
            name: 'frame-scene',
            options: {
              portrait: 'panda-mum-happy',
              text: '在中国，北⽅人在冬⾄时会吃饺子。',
              challengeText: '在中国，北方人过冬至时都会吃饺子。',
              frameIndex: 4,
            },
            transition: 'fadeIn',
            nextSceneId: 19,
          },
          {
            id: 19,
            name: 'frame-scene',
            options: {
              portrait: 'panda-mum-happy',
              text: '南⽅人在冬至时会吃汤圆。',
              challengeText: '⽽在南方的华人都会吃汤圆！',
              frameIndex: 5,
            },
            transition: 'fadeIn',
            nextSceneId: 20,
          },

          {
            id: 20,
            name: 'narration-scene',
            options: {
              narrator: '熊猫姐姐',
              narratorState: 'cheerful',
              narratorAnimation: 'single-heartbeat-animation',
              text: '饺⼦？我最喜欢吃饺⼦。',
              challengeText: '饺子？我特别喜欢吃饺子。',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 21,
          },
          {
            id: 21,
            name: 'narration-scene',
            options: {
              narrator: '熊猫弟弟',
              narratorState: 'cheerful',
              narratorAnimation: 'single-heartbeat-animation',
              text: '我喜欢吃汤圆，也喜欢吃饺⼦。',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 22,
          },
          {
            id: 22,
            name: 'narration-scene',
            options: {
              narrator: '熊猫弟弟',
              narratorState: 'thumbsup',
              narratorAnimation: 'single-heartbeat-animation',
              text: '饺⼦和汤圆看起来都很好吃。',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 23,
          },
          {
            id: 23,
            name: 'narration-scene',
            options: {
              narrator: '熊猫姐姐和弟弟',
              narratorState: 'cheerful',
              narratorAnimation: 'single-heartbeat-animation',
              text: '妈妈，快做吧！我们现在就想吃。',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 24,
          },
          {
            id: 24,
            name: 'narration-scene',
            options: {
              narrator: '熊猫妈妈',
              narratorState: 'educatetwo',
              narratorAnimation: 'single-heartbeat-animation',
              text: '你们要⾃⼰学习做才能吃。',
              challengeText: '如果你们想吃，就要⾃己学习做。',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 25,
          },
          {
            id: 25,
            name: 'narration-scene',
            options: {
              narrator: '熊猫弟弟',
              narratorState: 'puzzled',
              narratorAnimation: 'single-heartbeat-animation',
              text: '我们要⾃己做了才能吃？',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 26,
          },
          {
            id: 26,
            name: 'narration-scene',
            options: {
              narrator: '熊猫姐姐',
              narratorState: 'sad',
              narratorAnimation: 'single-heartbeat-animation',
              text: '可是，我们从来没包过饺子和汤圆。',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 27,
          },
          {
            id: 27,
            name: 'narration-scene',
            options: {
              narrator: '熊猫爸爸',
              narratorState: 'happyarmsfolded',
              narratorAnimation: 'single-heartbeat-animation',
              text: '不⽤担心！让我请魔幻饺子和魔幻汤圆来教我们。',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 28,
          },
          {
            id: 28,
            name: 'narration-scene',
            options: {
              narrator: '熊猫爸爸',
              narratorState: 'wand',
              narratorAnimation: 'single-heartbeat-animation',
              text: '我变，我变，我变，变，变！',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 30,
          },
          {
            id: 30,
            name: 'frame-scene',
            options: {
              portrait: '',
              text: '熊猫爸爸变魔术，把魔幻饺子和魔幻汤圆都请了出来。',
              frameIndex: 6,
            },
            transition: 'fadeIn',
            nextSceneId: 31,
          },
          {
            id: 31,
            name: 'narration-scene',
            options: {
              narrator: '魔幻饺子',
              narratorState: 'wave',
              narratorAnimation: 'single-heartbeat-animation',
              text: '大家好！我是魔幻饺子。',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 32,
          },
          {
            id: 32,
            name: 'narration-scene',
            options: {
              narrator: '魔幻汤圆',
              narratorState: 'wave',
              narratorAnimation: 'single-heartbeat-animation',
              text: '大家好！我是魔幻汤圆。',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 33,
          },
          {
            id: 33,
            name: 'narration-scene',
            options: {
              narrator: '魔幻饺子和汤圆',
              narratorState: 'wave',
              narratorAnimation: 'single-heartbeat-animation',
              text: '我们是冬至魔幻精灵！',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 34,
          },
          {
            id: 34,
            name: 'narration-scene',
            options: {
              narrator: '熊猫姐姐和弟弟',
              narratorState: 'puzzled',
              narratorAnimation: 'single-heartbeat-animation',
              text: '魔幻饺子、魔幻汤圆，你们能教我们包饺⼦和汤圆吗？',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 35,
          },
          {
            id: 35,
            name: 'narration-scene',
            options: {
              narrator: '魔幻饺子和汤圆',
              narratorState: 'cheerful',
              narratorAnimation: 'single-heartbeat-animation',
              text: '好呀！',
              challengeText: '当然可以！我们现在就教你们！',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 36,
          },
          {
            id: 36,
            name: 'narration-scene',
            options: {
              narrator: '熊猫姐姐和弟弟',
              narratorState: 'cheerful',
              narratorAnimation: 'single-heartbeat-animation',
              text: '太好了！',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 37,
          },
          {
            id: 37,
            name: 'narration-scene',
            options: {
              narrator: '魔幻饺子和汤圆',
              narratorState: 'wave',
              narratorAnimation: 'single-heartbeat-animation',
              text: '⼩朋友们，你们也一起学习包饺子和汤圆吧！',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 38,
          },
          {
            id: 38,
            name: 'narration-scene',
            options: {
              narrator: '魔幻饺子',
              narratorState: 'eureka',
              narratorAnimation: 'single-heartbeat-animation',
              text: '请问你们想先学包饺⼦？',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 39,
          },
          {
            id: 39,
            name: 'narration-scene',
            options: {
              narrator: '魔幻汤圆',
              narratorState: 'eureka',
              narratorAnimation: 'single-heartbeat-animation',
              text: '还是想先学包汤圆呢？',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 40,
          },
          {
            id: 40,
            name: 'frame-clickable-popup-scene',
            options: {
              narrator: '',
              text: '学包饺子还是学包汤圆呢？',
              choices: [{
                text: '汤圆',
                nextSceneId: 41,
                imageName: 'food-2',
              },
              {
                text: '饺子',
                nextSceneId: 66,
                imageName: 'food-3',
              }],
            },
            transition: 'fadeIn',
          },
          // TangYuan First
          {
            id: 41,
            name: 'narration-scene',
            options: {
              narrator: '魔幻汤圆',
              narratorState: 'cheerful',
              narratorAnimation: 'single-heartbeat-animation',
              text: '太好了！我们先学包汤圆吧！',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 42,
          },
          // TY Sequence
          {
            id: 42,
            name: 'frame-scene',
            options: {
              portrait: 'tangyuan-point',
              text: '我们要先准备一些糯⽶粉和温水。',
              challengeText: '⾸先，我们需要准备的材料是糯米粉和温水。',
              frameIndex: 7,
            },
            transition: 'fadeIn',
            nextSceneId: 43,
          },
          {
            id: 43,
            name: 'frame-scene',
            options: {
              portrait: 'tangyuan-thumbsup',
              text: '准备好之后，我们把它们混合在一起，变出一个⼤大的面团。',
              challengeText: '准备好材料之后，我们就把它们混合在一起，变出一个⼤大的⾯团。',
              frameIndex: 8,
            },
            transition: 'fadeIn',
            nextSceneId: 44,
          },
          {
            id: 44,
            name: 'frame-scene',
            options: {
              portrait: 'tangyuan-point',
              text: '我们再⽤这个大⾯团搓出一粒粒⼩小的、圆圆的汤圆。',
              frameIndex: 9,
            },
            transition: 'fadeIn',
            nextSceneId: 45,
          },
          {
            id: 45,
            name: 'frame-scene',
            options: {
              portrait: 'tangyuan-point',
              text: '在冬至的时候，我们一般会包白色和粉色的汤圆。',
              challengeText: '在冬至的时候，我们通常都会包⽩色和粉色的汤圆。',
              frameIndex: 10,
            },
            transition: 'fadeIn',
            nextSceneId: 46,
          },
          {
            id: 46,
            name: 'frame-scene',
            options: {
              portrait: 'tangyuan-point',
              text: '在汤圆里也可以放些黑芝麻或花生。',
              frameIndex: 11,
            },
            transition: 'fadeIn',
            nextSceneId: 47,
          },
          {
            id: 47,
            name: 'frame-scene',
            options: {
              portrait: 'tangyuan-cheerful',
              text: '搓好汤圆后，我们先烧开一锅水，再把汤圆放进热水里煮。',
              challengeText: '搓好汤圆后，我们先烧开一锅水，再把汤圆放进热水里煮。⼩小的汤圆在热水里会慢慢变大呢。',
              frameIndex: 12,
            },
            transition: 'fadeIn',
            nextSceneId: 48,
          },
          {
            id: 48,
            name: 'narration-scene',
            options: {
              narrator: '魔幻汤圆',
              narratorState: 'cheerfultwo',
              narratorAnimation: 'single-heartbeat-animation',
              text: '你们看，包汤圆和煮汤圆是不是很简单呢？',
              challengeText: '你们觉得包汤圆和煮汤圆是不是很简单呢？',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 49,
          },
          // Dumpling Next
          {
            id: 49,
            name: 'narration-scene',
            options: {
              narrator: '魔幻饺子',
              narratorState: 'wave',
              narratorAnimation: 'single-heartbeat-animation',
              text: '我来了！',
              challengeText: '轮到我出场了！',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 50,
          },
          {
            id: 50,
            name: 'narration-scene',
            options: {
              narrator: '魔幻饺子',
              narratorState: 'cheerfulone',
              narratorAnimation: 'single-heartbeat-animation',
              text: '我们接下来学包饺⼦吧！',
              challengeText: '我们接下来学怎样包饺子吧！',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 51,
          },
          // Dumpling Sequence
          {
            id: 51,
            name: 'narration-scene',
            options: {
              narrator: '魔幻饺子',
              narratorState: 'eureka',
              narratorAnimation: 'single-heartbeat-animation',
              text: '我们要先准备饺⼦皮和饺子馅。',
              challengeText: '⾸先，我们需要准备饺子皮和饺子馅的食材。',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 52,
          },
          {
            id: 52,
            name: 'narration-scene',
            options: {
              narrator: '魔幻饺子',
              narratorState: 'confused',
              narratorAnimation: 'single-heartbeat-animation',
              text: '我们要用什么做饺子皮呢？',
              challengeText: '我们需要用什么做饺子皮呢？',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 53,
          },
          {
            id: 53,
            name: 'frame-scene',
            options: {
              portrait: 'dumpling-point',
              text: '饺⼦皮是用面粉和水做成的面团变出来的。',
              challengeText: '饺⼦皮是⽤面粉和⽔混合做成的面团变出来的。',
              frameIndex: 13,
            },
            transition: 'fadeIn',
            nextSceneId: 54,
          },
          {
            id: 54,
            name: 'frame-scene',
            options: {
              portrait: 'dumpling-thumbsup',
              text: '只要把面粉和水混合在一起，就能变出一个⼤大的⾯团。',
              frameIndex: 8,
            },
            transition: 'fadeIn',
            nextSceneId: 55,
          },
          {
            id: 55,
            name: 'frame-scene',
            options: {
              portrait: 'dumpling-point',
              text: '接下来，我们把⾯团分成很多⼩块的面团，再擀成⼀片⼀片的饺子皮。',
              challengeText: '接下来，我们把⾯团分成很多⼩小块的⾯团，⽤小擀⾯杖擀成一⽚一⽚的饺子皮',
              frameIndex: 15,
            },
            transition: 'fadeIn',
            nextSceneId: 56,
          },
          {
            id: 56,
            name: 'frame-scene',
            options: {
              portrait: 'dumpling-cheerful',
              text: '这样，饺子⽪就准备好了。',
              frameIndex: 16,
            },
            transition: 'fadeIn',
            nextSceneId: 57,
          },
          {
            id: 57,
            name: 'narration-scene',
            options: {
              narrator: '魔幻饺子',
              narratorState: 'confused',
              narratorAnimation: 'single-heartbeat-animation',
              text: '那么，我们⽤什么来做饺⼦馅呢？',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 58,
          },
          {
            id: 58,
            name: 'frame-scene',
            options: {
              portrait: 'dumpling-point',
              text: '做饺⼦馅要⽤猪⾁碎、包菜、洋葱和不同的调味料。',
              challengeText: '做饺子馅需要的食材有猪⾁碎、包菜、洋葱和不同的调味料。',
              frameIndex: 17,
            },
            transition: 'fadeIn',
            nextSceneId: 59,
          },
          {
            id: 59,
            name: 'frame-scene',
            options: {
              portrait: 'dumpling-point',
              text: '其实，你也可以选择自己喜欢的食材做不同的饺子馅。',
              challengeText: '其实，你也可以选择自己喜欢的食材做各种各样不同的饺⼦馅。',
              frameIndex: 17,
            },
            transition: 'fadeIn',
            nextSceneId: 60,
          },
          {
            id: 60,
            name: 'frame-scene',
            options: {
              portrait: 'dumpling-thumbsup',
              text: '我们把这些⾷材搅拌在一起后，饺⼦馅就做好了。',
              challengeText: '我们再把这些食材搅拌在一起后，饺子馅就准备好了。',
              frameIndex: 18,
            },
            transition: 'fadeIn',
            nextSceneId: 61,
          },
          {
            id: 61,
            name: 'frame-scene',
            options: {
              portrait: 'dumpling-point',
              text: '我们把饺子馅包在饺子⽪⾥，就能包出⼀个个可爱的饺子。',
              challengeText: '我们把准备好的饺子馅包在饺⼦皮里，就能包出一个像元宝⼀样的饺子。',
              frameIndex: 19,
            },
            transition: 'fadeIn',
            nextSceneId: 62,
          },
          {
            id: 62,
            name: 'frame-scene',
            options: {
              portrait: 'dumpling-thumbsup',
              text: '看！饺⼦包好了！',
              frameIndex: 19,
            },
            transition: 'fadeIn',
            nextSceneId: 63,
          },
          {
            id: 63,
            name: 'frame-scene',
            options: {
              portrait: 'dumpling-cheerful',
              text: '最后，我们先烧开一锅水，再把饺子放进热水里煮。',
              frameIndex: 20,
            },
            transition: 'fadeIn',
            nextSceneId: 64,
          },
          {
            id: 64,
            name: 'frame-scene',
            options: {
              portrait: 'dumpling-cheerful',
              text: '当饺⼦都浮起来了，就煮好了',
              frameIndex: 20,
            },
            transition: 'fadeIn',
            nextSceneId: 65,
          },
          {
            id: 65,
            name: 'narration-scene',
            options: {
              narrator: '魔幻饺子',
              narratorState: 'cheerfultwo',
              narratorAnimation: 'single-heartbeat-animation',
              text: '你们看，这些饺⼦子看起来好吃吗？',
              challengeText: '你们看，煮好的饺子是不是看起来很好吃呢？',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 91,
          },
          // CHOICE 2: Dumpling First
          {
            id: 66,
            name: 'narration-scene',
            options: {
              narrator: '魔幻饺子',
              narratorState: 'cheerful',
              narratorAnimation: 'single-heartbeat-animation',
              text: '太好了！我们先学包饺⼦吧！',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 67,
          },
          // Dumpling Sequence
          {
            id: 67,
            name: 'narration-scene',
            options: {
              narrator: '魔幻饺子',
              narratorState: 'eureka',
              narratorAnimation: 'single-heartbeat-animation',
              text: '我们要先准备饺⼦皮和饺子馅。',
              challengeText: '⾸先，我们需要准备饺子皮和饺子馅的食材。',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 68,
          },
          {
            id: 68,
            name: 'narration-scene',
            options: {
              narrator: '魔幻饺子',
              narratorState: 'confused',
              narratorAnimation: 'single-heartbeat-animation',
              text: '我们要用什么做饺子皮呢？',
              challengeText: '我们需要用什么做饺子皮呢？',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 69,
          },
          {
            id: 69,
            name: 'frame-scene',
            options: {
              portrait: 'dumpling-point',
              text: '饺⼦皮是用面粉和水做成的面团变出来的。',
              challengeText: '饺⼦皮是⽤面粉和⽔混合做成的面团变出来的。',
              frameIndex: 13,
            },
            transition: 'fadeIn',
            nextSceneId: 70,
          },
          {
            id: 70,
            name: 'frame-scene',
            options: {
              portrait: 'dumpling-thumbsup',
              text: '只要把面粉和水混合在一起，就能变出一个⼤大的⾯团。',
              frameIndex: 8,
            },
            transition: 'fadeIn',
            nextSceneId: 71,
          },
          {
            id: 71,
            name: 'frame-scene',
            options: {
              portrait: 'dumpling-point',
              text: '接下来，我们把⾯团分成很多⼩块的面团，再擀成⼀片⼀片的饺子皮。',
              challengeText: '接下来，我们把⾯团分成很多⼩小块的⾯团，⽤小擀⾯杖擀成一⽚一⽚的饺子皮。',
              frameIndex: 15,
            },
            transition: 'fadeIn',
            nextSceneId: 72,
          },
          {
            id: 72,
            name: 'frame-scene',
            options: {
              portrait: 'dumpling-cheerful',
              text: '这样，饺子⽪就准备好了。',
              frameIndex: 16,
            },
            transition: 'fadeIn',
            nextSceneId: 73,
          },
          {
            id: 73,
            name: 'narration-scene',
            options: {
              narrator: '魔幻饺子',
              narratorState: 'confused',
              narratorAnimation: 'single-heartbeat-animation',
              text: '那么，我们⽤什么来做饺⼦馅呢？',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 74,
          },
          {
            id: 74,
            name: 'frame-scene',
            options: {
              portrait: 'dumpling-point',
              text: '做饺⼦馅要⽤猪⾁碎、包菜、洋葱和不同的调味料。',
              challengeText: '做饺子馅需要的食材有猪⾁碎、包菜、洋葱和不同的调味料。',
              frameIndex: 17,
            },
            transition: 'fadeIn',
            nextSceneId: 75,
          },
          {
            id: 75,
            name: 'frame-scene',
            options: {
              portrait: 'dumpling-point',
              text: '其实，你也可以选择自己喜欢的食材做不同的饺子馅。',
              challengeText: '其实，你也可以选择自己喜欢的食材做各种各样不同的饺⼦馅。',
              frameIndex: 17,
            },
            transition: 'fadeIn',
            nextSceneId: 76,
          },
          {
            id: 76,
            name: 'frame-scene',
            options: {
              portrait: 'dumpling-thumbsup',
              text: '我们把这些⾷材搅拌在一起后，饺⼦馅就做好了。',
              challengeText: '我们再把这些食材搅拌在一起后，饺子馅就准备好了。',
              frameIndex: 18,
            },
            transition: 'fadeIn',
            nextSceneId: 77,
          },
          {
            id: 77,
            name: 'frame-scene',
            options: {
              portrait: 'dumpling-point',
              text: '我们把饺子馅包在饺子⽪⾥，就能包出⼀个个可爱的饺子。',
              challengeText: '我们把准备好的饺子馅包在饺⼦皮里，就能包出一个像元宝⼀样的饺子。',
              frameIndex: 19,
            },
            transition: 'fadeIn',
            nextSceneId: 78,
          },
          {
            id: 78,
            name: 'frame-scene',
            options: {
              portrait: 'dumpling-thumbsup',
              text: '看！饺⼦包好了！',
              frameIndex: 19,
            },
            transition: 'fadeIn',
            nextSceneId: 79,
          },
          {
            id: 79,
            name: 'frame-scene',
            options: {
              portrait: 'dumpling-cheerful',
              text: '最后，我们先烧开一锅水，再把饺子放进热水里煮。',
              frameIndex: 20,
            },
            transition: 'fadeIn',
            nextSceneId: 80,
          },
          {
            id: 80,
            name: 'frame-scene',
            options: {
              portrait: 'dumpling-cheerful',
              text: '当饺⼦都浮起来了，就煮好了',
              frameIndex: 20,
            },
            transition: 'fadeIn',
            nextSceneId: 81,
          },
          {
            id: 81,
            name: 'narration-scene',
            options: {
              narrator: '魔幻饺子',
              narratorState: 'cheerfultwo',
              narratorAnimation: 'single-heartbeat-animation',
              text: '你们看，这些饺⼦子看起来好吃吗？',
              challengeText: '你们看，煮好的饺子是不是看起来很好吃呢？',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 82,
          },
          {
            id: 82,
            name: 'narration-scene',
            options: {
              narrator: '魔幻汤圆',
              narratorState: 'wave',
              narratorAnimation: 'single-heartbeat-animation',
              text: '我来了！',
              challengeText: '轮到我出场了！',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 83,
          },
          {
            id: 83,
            name: 'narration-scene',
            options: {
              narrator: '魔幻汤圆',
              narratorState: 'cheerfulone',
              narratorAnimation: 'single-heartbeat-animation',
              text: '我们接下来学包饺⼦吧！',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 84,
          },
          {
            id: 84,
            name: 'frame-scene',
            options: {
              portrait: 'tangyuan-point',
              text: '我们要先准备一些糯⽶粉和温水。',
              challengeText: '⾸先，我们需要准备的材料是糯米粉和温水。',
              frameIndex: 7,
            },
            transition: 'fadeIn',
            nextSceneId: 85,
          },
          {
            id: 85,
            name: 'frame-scene',
            options: {
              portrait: 'tangyuan-thumbsup',
              text: '准备好之后，我们把它们混合在一起，变出一个⼤大的面团。',
              challengeText: '准备好材料之后，我们就把它们混合在一起，变出一个⼤大的⾯团。',
              frameIndex: 8,
            },
            transition: 'fadeIn',
            nextSceneId: 86,
          },
          {
            id: 86,
            name: 'frame-scene',
            options: {
              portrait: 'tangyuan-point',
              text: '我们再⽤这个大⾯团搓出一粒粒⼩小的、圆圆的汤圆。',
              frameIndex: 9,
            },
            transition: 'fadeIn',
            nextSceneId: 87,
          },
          {
            id: 87,
            name: 'frame-scene',
            options: {
              portrait: 'tangyuan-point',
              text: '在冬至的时候，我们一般会包白色和粉色的汤圆。',
              challengeText: '在冬至的时候，我们通常都会包⽩色和粉色的汤圆。',
              frameIndex: 10,
            },
            transition: 'fadeIn',
            nextSceneId: 88,
          },
          {
            id: 88,
            name: 'frame-scene',
            options: {
              portrait: 'tangyuan-point',
              text: '在汤圆里也可以放些黑芝麻或花生。',
              frameIndex: 11,
            },
            transition: 'fadeIn',
            nextSceneId: 89,
          },
          {
            id: 89,
            name: 'frame-scene',
            options: {
              portrait: 'tangyuan-cheerful',
              text: '搓好汤圆后，我们先烧开一锅水，再把汤圆放进热水里煮。',
              challengeText: '搓好汤圆后，我们先烧开一锅水，再把汤圆放进热水里煮。⼩小的汤圆在热水里会慢慢变大呢。',
              frameIndex: 12,
            },
            transition: 'fadeIn',
            nextSceneId: 90,
          },
          {
            id: 90,
            name: 'narration-scene',
            options: {
              narrator: '魔幻汤圆',
              narratorState: 'cheerfultwo',
              narratorAnimation: 'single-heartbeat-animation',
              text: '你们看，包汤圆和煮汤圆是不是很简单呢？',
              challengeText: '你们觉得包汤圆和煮汤圆是不是很简单呢？',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 91,
          },
          {
            id: 91,
            name: 'narration-scene',
            options: {
              narrator: '熊猫姐姐和弟弟',
              narratorState: 'thumbsup',
              narratorAnimation: 'single-heartbeat-animation',
              text: '我们学会包汤圆和饺⼦了！',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 92,
          },
          {
            id: 92,
            name: 'narration-scene',
            options: {
              narrator: '熊猫姐姐和弟弟',
              narratorState: 'cheerful',
              narratorAnimation: 'single-heartbeat-animation',
              text: '魔幻饺⼦、魔幻汤圆，谢谢你们！',
              challengeText: '魔幻饺子、魔幻汤圆，谢谢你们教我们包饺子和汤圆。',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 93,
          },
          {
            id: 93,
            name: 'narration-scene',
            options: {
              narrator: '魔幻饺子和汤圆',
              narratorState: 'cheerful',
              narratorAnimation: 'single-heartbeat-animation',
              text: '不客⽓！',
              challengeText: '不用客⽓！',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 94,
          },
          {
            id: 94,
            name: 'narration-scene',
            options: {
              narrator: '熊猫博士',
              narratorState: 'happyhandsonhips',
              narratorAnimation: 'single-heartbeat-animation',
              text: '太棒了！熊猫姐弟俩学会了包饺子和汤圆。',
              background: 'black',
            },
            transition: 'fadeIn',
            nextSceneId: 95,
          },
          {
            id: 95,
            name: 'narration-scene',
            options: {
              narrator: '熊猫博士',
              narratorState: 'wave',
              narratorAnimation: 'single-heartbeat-animation',
              text: '⼩朋友们，你们也学会了吗？',
              background: 'black',
            },
            transition: 'fadeIn',
            nextSceneId: 96,
          },
          {
            id: 96,
            name: 'frame-clickable-popup-scene',
            options: {
              narrator: '熊猫博士',
              narratorState: 'wave',
              narratorAnimation: 'single-heartbeat-animation',
              text: '你要再看看怎么包饺子或汤圆吗？',
              background: 'bamboo-white',
              choices: [{
                text: '再学饺子',
                nextSceneId: 97,
                imageName: 'food-3',
              },
              {
                text: '再学汤圆',
                nextSceneId: 112,
                imageName: 'food-2',
              },
              {
                text: '我学会了！',
                imageName: 'yes',
                nextSceneId: 0,
              }],

            },
            transition: 'fadeIn',
          },
          // Dumpling Sequence
          {
            id: 97,
            name: 'narration-scene',
            options: {
              narrator: '魔幻饺子',
              narratorState: 'eureka',
              narratorAnimation: 'single-heartbeat-animation',
              text: '我们要先准备饺⼦皮和饺子馅。',
              challengeText: '⾸先，我们需要准备饺子皮和饺子馅的食材。',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 98,
          },
          {
            id: 98,
            name: 'narration-scene',
            options: {
              narrator: '魔幻饺子',
              narratorState: 'confused',
              narratorAnimation: 'single-heartbeat-animation',
              text: '我们要用什么做饺子皮呢？',
              challengeText: '我们需要用什么做饺子皮呢？',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 99,
          },
          {
            id: 99,
            name: 'frame-scene',
            options: {
              portrait: 'dumpling-point',
              text: '饺⼦皮是用面粉和水做成的面团变出来的。',
              challengeText: '饺⼦皮是⽤面粉和⽔混合做成的面团变出来的。',
              frameIndex: 13,
            },
            transition: 'fadeIn',
            nextSceneId: 100,
          },
          {
            id: 100,
            name: 'frame-scene',
            options: {
              portrait: 'dumpling-thumbsup',
              text: '只要把面粉和水混合在一起，就能变出一个⼤大的⾯团。',
              frameIndex: 8,
            },
            transition: 'fadeIn',
            nextSceneId: 101,
          },
          {
            id: 101,
            name: 'frame-scene',
            options: {
              portrait: 'dumpling-point',
              text: '接下来，我们把⾯团分成很多⼩块的面团，再擀成⼀片⼀片的饺子皮。',
              challengeText: '接下来，我们把⾯团分成很多⼩小块的⾯团，⽤小擀⾯杖擀成一⽚一⽚的饺子皮。',
              frameIndex: 15,
            },
            transition: 'fadeIn',
            nextSceneId: 102,
          },
          {
            id: 102,
            name: 'frame-scene',
            options: {
              portrait: 'dumpling-cheerful',
              text: '这样，饺子⽪就准备好了。',
              frameIndex: 16,
            },
            transition: 'fadeIn',
            nextSceneId: 103,
          },
          {
            id: 103,
            name: 'narration-scene',
            options: {
              narrator: '魔幻饺子',
              narratorState: 'confused',
              narratorAnimation: 'single-heartbeat-animation',
              text: '那么，我们⽤什么来做饺⼦馅呢？',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 104,
          },
          {
            id: 104,
            name: 'frame-scene',
            options: {
              portrait: 'dumpling-point',
              text: '做饺⼦馅要⽤猪⾁碎、包菜、洋葱和不同的调味料。',
              challengeText: '做饺子馅需要的食材有猪⾁碎、包菜、洋葱和不同的调味料。',
              frameIndex: 17,
            },
            transition: 'fadeIn',
            nextSceneId: 105,
          },
          {
            id: 105,
            name: 'frame-scene',
            options: {
              portrait: 'dumpling-point',
              text: '其实，你也可以选择自己喜欢的食材做不同的饺子馅。',
              challengeText: '其实，你也可以选择自己喜欢的食材做各种各样不同的饺⼦馅。',
              frameIndex: 17,
            },
            transition: 'fadeIn',
            nextSceneId: 106,
          },
          {
            id: 106,
            name: 'frame-scene',
            options: {
              portrait: 'dumpling-thumbsup',
              text: '我们把这些⾷材搅拌在一起后，饺⼦馅就做好了。',
              challengeText: '我们再把这些食材搅拌在一起后，饺子馅就准备好了。',
              frameIndex: 18,
            },
            transition: 'fadeIn',
            nextSceneId: 107,
          },
          {
            id: 107,
            name: 'frame-scene',
            options: {
              portrait: 'dumpling-point',
              text: '我们把饺子馅包在饺子⽪⾥，就能包出⼀个个可爱的饺子。',
              challengeText: '我们把准备好的饺子馅包在饺⼦皮里，就能包出一个像元宝⼀样的饺子。',
              frameIndex: 19,
            },
            transition: 'fadeIn',
            nextSceneId: 108,
          },
          {
            id: 108,
            name: 'frame-scene',
            options: {
              portrait: 'dumpling-thumbsup',
              text: '看！饺⼦包好了！',
              frameIndex: 19,
            },
            transition: 'fadeIn',
            nextSceneId: 109,
          },
          {
            id: 109,
            name: 'frame-scene',
            options: {
              portrait: 'dumpling-cheerful',
              text: '最后，我们先烧开一锅水，再把饺子放进热水里煮。',
              frameIndex: 20,
            },
            transition: 'fadeIn',
            nextSceneId: 110,
          },
          {
            id: 110,
            name: 'frame-scene',
            options: {
              portrait: 'dumpling-cheerful',
              text: '当饺⼦都浮起来了，就煮好了',
              frameIndex: 20,
            },
            transition: 'fadeIn',
            nextSceneId: 111,
          },
          {
            id: 111,
            name: 'narration-scene',
            options: {
              narrator: '魔幻饺子',
              narratorState: 'cheerfultwo',
              narratorAnimation: 'single-heartbeat-animation',
              text: '你们看，这些饺⼦子看起来好吃吗？',
              challengeText: '你们看，煮好的饺子是不是看起来很好吃呢？',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 96,
          },
          // TY Sequence
          {
            id: 112,
            name: 'frame-scene',
            options: {
              portrait: 'tangyuan-point',
              text: '我们要先准备一些糯⽶粉和温水。',
              challengeText: '⾸先，我们需要准备的材料是糯米粉和温水。',
              frameIndex: 7,
            },
            transition: 'fadeIn',
            nextSceneId: 113,
          },
          {
            id: 113,
            name: 'frame-scene',
            options: {
              portrait: 'tangyuan-thumbsup',
              text: '准备好之后，我们把它们混合在一起，变出一个⼤大的面团。',
              challengeText: '准备好材料之后，我们就把它们混合在一起，变出一个⼤大的⾯团。',
              frameIndex: 8,
            },
            transition: 'fadeIn',
            nextSceneId: 114,
          },
          {
            id: 114,
            name: 'frame-scene',
            options: {
              portrait: 'tangyuan-point',
              text: '我们再⽤这个大⾯团搓出一粒粒⼩小的、圆圆的汤圆。',
              frameIndex: 9,
            },
            transition: 'fadeIn',
            nextSceneId: 115,
          },
          {
            id: 115,
            name: 'frame-scene',
            options: {
              portrait: 'tangyuan-point',
              text: '在冬至的时候，我们一般会包白色和粉色的汤圆。',
              challengeText: '在冬至的时候，我们通常都会包⽩色和粉色的汤圆。',
              frameIndex: 10,
            },
            transition: 'fadeIn',
            nextSceneId: 116,
          },
          {
            id: 116,
            name: 'frame-scene',
            options: {
              portrait: 'tangyuan-point',
              text: '在汤圆里也可以放些黑芝麻或花生。',
              frameIndex: 11,
            },
            transition: 'fadeIn',
            nextSceneId: 117,
          },
          {
            id: 117,
            name: 'frame-scene',
            options: {
              portrait: 'tangyuan-cheerful',
              text: '搓好汤圆后，我们先烧开一锅水，再把汤圆放进热水里煮。',
              challengeText: '搓好汤圆后，我们先烧开一锅水，再把汤圆放进热水里煮。⼩小的汤圆在热水里会慢慢变大呢。',
              frameIndex: 12,
            },
            transition: 'fadeIn',
            nextSceneId: 118,
          },
          {
            id: 118,
            name: 'narration-scene',
            options: {
              narrator: '魔幻汤圆',
              narratorState: 'cheerfultwo',
              narratorAnimation: 'single-heartbeat-animation',
              text: '你们看，包汤圆和煮汤圆是不是很简单呢？',
              challengeText: '你们觉得包汤圆和煮汤圆是不是很简单呢？',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 96,
          },

        ],
      },
      {
        // book id
        id: 2,
        title: '包汤圆和饺⼦',
        sceneData: [
          {
            id: 0,
            name: 'title-scene',
            options: {
              text: '包汤圆和饺⼦',
            },
            transition: 'fadeIn',
            nextSceneId: 1,
          },
          {
            id: 1,
            name: 'narration-scene',
            options: {
              narrator: '熊猫博士',
              narratorState: 'wave',
              narratorAnimation: 'single-heartbeat-animation',
              text: '⼩朋友们，你们⼜来到了故事国。',
              background: 'black',
            },
            transition: 'fadeIn',
            nextSceneId: 2,
          },
          {
            id: 2,
            name: 'narration-scene',
            options: {
              narrator: '熊猫博士',
              narratorState: 'eureka',
              narratorAnimation: 'single-heartbeat-animation',
              text: '我们再来听⼀听，看⼀看熊猫⼀家接下来会做什么吧！',
              challengeText: '我们来继续听故事，看一看熊猫一家接下来会做什么吧！',
              background: 'black',
            },
            transition: 'fadeIn',
            nextSceneId: 3,
          },
          {
            id: 3,
            name: 'frame-scene',
            options: {
              portrait: '',
              text: '在冬天的⽵林⾥，熊猫姐弟俩跟冬至魔幻精灵们学会了包汤圆和饺子。',
              challengeText: '在⽩雪覆盖的竹林里，熊猫姐弟俩跟冬至魔幻精灵们学会了包汤圆和饺子。',
              frameIndex: 0,
            },
            transition: 'fadeIn',
            nextSceneId: 4,
          },
          {
            id: 4,
            name: 'narration-scene',
            options: {
              narrator: '魔幻饺子和汤圆',
              narratorState: 'cheerful',
              narratorAnimation: 'single-heartbeat-animation',
              text: '太好了！你们学会包汤圆和饺⼦了。',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 5,
          },
          {
            id: 5,
            name: 'narration-scene',
            options: {
              narrator: '熊猫姐姐和弟弟',
              narratorState: 'cheerful',
              narratorAnimation: 'single-heartbeat-animation',
              text: '魔幻饺⼦、魔幻汤圆，谢谢你们教我们。',
              challengeText: '魔幻饺子、魔幻汤圆，谢谢你们把知识教给我们。',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 6,
          },
          {
            id: 6,
            name: 'narration-scene',
            options: {
              narrator: '魔幻饺子和汤圆',
              narratorState: 'cheerful',
              narratorAnimation: 'single-heartbeat-animation',
              text: '不客⽓！祝你们度过⼀个开⼼的冬至！',
              challengeText: '不用客气！祝你们度过一个开心的冬⾄！',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 888,
          },
          {
            id: 888,
            name: 'narration-scene',
            options: {
              narrator: '魔幻饺子和汤圆',
              narratorState: 'wave',
              narratorAnimation: 'single-heartbeat-animation',
              text: '我们先回去了，再⻅！',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 7,
          },
          {
            id: 7,
            name: 'frame-scene',
            options: {
              portrait: '',
              text: '魔幻饺⼦和魔幻汤圆不见了。',
              challengeText: '魔幻饺子和魔幻汤圆消失不见了。',
              frameIndex: 0,
            },
            transition: 'fadeIn',
            nextSceneId: 8,
          },
          {
            id: 8,
            name: 'narration-scene',
            options: {
              narrator: '熊猫爸爸和妈妈',
              narratorState: 'happy',
              narratorAnimation: 'single-heartbeat-animation',
              text: '熊猫姐姐、熊猫弟弟，你们都学会包饺子和汤圆了吗？',
              challengeText: '熊猫姐姐、熊猫弟弟，你们都学会怎么包饺子和汤圆了吗？',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 9,
          },
          {
            id: 9,
            name: 'narration-scene',
            options: {
              narrator: '熊猫姐姐和弟弟',
              narratorState: 'thumbsup',
              narratorAnimation: 'single-heartbeat-animation',
              text: '是的，我们都学会了。',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 10,
          },
          {
            id: 10,
            name: 'narration-scene',
            options: {
              narrator: '熊猫爸爸和妈妈',
              narratorState: 'instruct',
              narratorAnimation: 'single-heartbeat-animation',
              text: '好！我们现在就⼀起包饺子和汤圆吧！',
              challengeText: '既然学会了，我们现在就⼀起包饺⼦和汤圆吧！',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 11,
          },
          {
            id: 11,
            name: 'narration-scene',
            options: {
              narrator: '熊猫姐姐和弟弟',
              narratorState: 'cheerful',
              narratorAnimation: 'single-heartbeat-animation',
              text: '好的！',
              challengeText: '好的，我们一起来包饺子和汤圆吧！',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 12,
          },
          {
            id: 12,
            name: 'narration-scene',
            options: {
              narrator: '熊猫姐姐',
              narratorState: 'puzzled',
              narratorAnimation: 'single-heartbeat-animation',
              text: '我们要先包饺子，还是包汤圆呢？',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 13,
          },
          {
            id: 13,
            name: 'narration-scene',
            options: {
              narrator: '熊猫弟弟',
              narratorState: 'puzzled',
              narratorAnimation: 'single-heartbeat-animation',
              text: '⼩朋友们，你们说，我们先包饺子，还是先包汤圆好呢？',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 14,
          },
          {
            id: 14,
            name: 'frame-clickable-popup-scene',
            options: {
              narrator: '',
              text: '包饺子还是包汤圆呢？',
              choices: [{
                text: '汤圆',
                nextSceneId: 57,
                imageName: 'food-2',
              },
              {
                text: '饺子',
                nextSceneId: 15,
                imageName: 'food-3',
              }],
            },
            transition: 'fadeIn',
          },
          // WRAP DUMPLING FIRST
          {
            id: 15,
            name: 'narration-scene',
            options: {
              narrator: '熊猫姐姐和弟弟',
              narratorState: 'cheerful',
              narratorAnimation: 'single-heartbeat-animation',
              text: '好！我们先包饺⼦吧。',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 16,
          },
          // DUMPLING SEQUENCE
          {
            id: 16,
            name: 'narration-scene',
            options: {
              narrator: '熊猫姐姐和弟弟',
              narratorState: 'makeapoint',
              narratorAnimation: 'single-heartbeat-animation',
              text: '包饺子要⽤饺⼦皮和饺子馅。',
              challengeText: '包饺⼦需要饺子⽪和饺子馅。',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 999,
          },
          {
            id: 999,
            name: 'narration-scene',
            options: {
              narrator: '熊猫弟弟',
              narratorState: 'eureka',
              narratorAnimation: 'single-heartbeat-animation',
              text: '我们先来做饺子⽪吧！',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 17,
          },
          {
            id: 17,
            name: 'narration-scene',
            options: {
              narrator: '熊猫弟弟',
              narratorState: 'think',
              narratorAnimation: 'single-heartbeat-animation',
              text: '我不记得怎样做饺⼦皮了！',
              challengeText: '我忘记怎样做饺子皮了！',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 18,
          },
          {
            id: 18,
            name: 'narration-scene',
            options: {
              narrator: '熊猫弟弟',
              narratorState: 'puzzled',
              narratorAnimation: 'single-heartbeat-animation',
              text: '小朋友们，你们能帮帮我吗？',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 19,
          },
          {
            id: 19,
            name: 'narration-scene',
            options: {
              narrator: '熊猫弟弟',
              narratorState: 'puzzled',
              narratorAnimation: 'single-heartbeat-animation',
              text: '饺⼦皮要⽤什么呢？',
              challengeText: '饺子皮需要什么材料呢？',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 20,
          },
          {
            id: 20,
            name: 'frame-clickable-popup-scene',
            options: {
              narrator: '',
              text: '选择当中选出正确的答案',
              choices: [{
                text: '1',
                nextSceneId: 22,
                imageName: 'ingredient-4',
              },
              {
                text: '2',
                nextSceneId: 21,
                imageName: 'ingredient-5',
              }],
            },
            transition: 'fadeIn',
          },
          {
            id: 21,
            name: 'narration-scene',
            options: {
              narrator: '熊猫弟弟',
              narratorState: 'sad',
              narratorAnimation: 'single-heartbeat-animation',
              text: '再想⼀想！',
              challengeText: '好像不对，再想一想！',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 20,
          },
          {
            id: 22,
            name: 'narration-scene',
            options: {
              narrator: '熊猫弟弟',
              narratorState: 'thumbsup',
              narratorAnimation: 'single-heartbeat-animation',
              text: '答对了！',
              challengeText: '太棒了！答对了。',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 23,
          },
          {
            id: 23,
            name: 'frame-scene',
            options: {
              portrait: 'panda-bro-correct',
              text: '饺⼦皮要⽤面粉和水。',
              challengeText: '饺子⽪需要的材料是面粉和水。',
              frameIndex: 2,
            },
            transition: 'fadeIn',
            nextSceneId: 24,
          },
          {
            id: 24,
            name: 'frame-scene',
            options: {
              portrait: 'panda-bro-cheerful',
              text: '你们看！饺⼦皮做好了！',
              frameIndex: 3,
            },
            transition: 'fadeIn',
            nextSceneId: 25,
          },
          {
            id: 25,
            name: 'narration-scene',
            options: {
              narrator: '熊猫姐姐',
              narratorState: 'eureka',
              narratorAnimation: 'single-heartbeat-animation',
              text: '我们接下来做饺子馅吧！',
              challengeText: '我们接下来准备饺子馅吧！',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 26,
          },
          {
            id: 26,
            name: 'narration-scene',
            options: {
              narrator: '熊猫姐姐',
              narratorState: 'puzzled',
              narratorAnimation: 'single-heartbeat-animation',
              text: '小朋友们，饺⼦馅⼜要用什么呢？',
              challengeText: '⼩朋友们，饺⼦馅又需要什么材料，你们知道吗？',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 27,
          },
          {
            id: 27,
            name: 'frame-clickable-popup-scene',
            options: {
              narrator: '',
              text: '选择当中选出正确的答案',
              choices: [{
                text: '1',
                nextSceneId: 28,
                imageName: 'ingredient-5',
              },
              {
                text: '2',
                nextSceneId: 29,
                imageName: 'ingredient-6',
              }],
            },
            transition: 'fadeIn',
          },
          {
            id: 28,
            name: 'narration-scene',
            options: {
              narrator: '熊猫姐姐',
              narratorState: 'sad',
              narratorAnimation: 'single-heartbeat-animation',
              text: '再想⼀想！',
              challengeText: '好像不对... 再想一想！',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 27,
          },
          {
            id: 29,
            name: 'narration-scene',
            options: {
              narrator: '熊猫姐姐',
              narratorState: 'thumbsup',
              narratorAnimation: 'single-heartbeat-animation',
              text: '答对了！',
              challengeText: '太棒了！答对了。',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 30,
          },
          {
            id: 30,
            name: 'frame-scene',
            options: {
              portrait: 'panda-sis-correct',
              text: '饺⼦馅要⽤猪⾁碎、包菜、洋葱和调味料。',
              challengeText: '饺⼦馅需要的材料是猪⾁碎，包菜，洋葱和调味料。',
              frameIndex: 4,
            },
            transition: 'fadeIn',
            nextSceneId: 31,
          },
          {
            id: 31,
            name: 'frame-scene',
            options: {
              portrait: 'panda-sis-cheerful',
              text: '饺子馅做好了！',
              frameIndex: 18,
            },
            transition: 'fadeIn',
            nextSceneId: 32,
          },
          {
            id: 32,
            name: 'narration-scene',
            options: {
              narrator: '熊猫弟弟',
              narratorState: 'eureka',
              narratorAnimation: 'single-heartbeat-animation',
              text: '有了饺子皮。',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 33,
          },
          {
            id: 33,
            name: 'narration-scene',
            options: {
              narrator: '熊猫姐姐',
              narratorState: 'eureka',
              narratorAnimation: 'single-heartbeat-animation',
              text: '还有饺子馅。',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 34,
          },
          {
            id: 34,
            name: 'narration-scene',
            options: {
              narrator: '熊猫姐姐和弟弟',
              narratorState: 'cheerful',
              narratorAnimation: 'single-heartbeat-animation',
              text: '就能开始包饺⼦了',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 35,
          },
          {
            id: 35,
            name: 'frame-scene',
            options: {
              portrait: '',
              text: '熊猫姐姐和弟弟包了很多饺子，每个饺子看起来都可爱极了！',
              challengeText: '熊猫姐姐和弟弟包了很多饺子，每个饺子看起来都像⼀个⼩元宝，可爱极了！',
              frameIndex: 5,
            },
            transition: 'fadeIn',
            nextSceneId: 36,
          },
          {
            id: 36,
            name: 'narration-scene',
            options: {
              narrator: '熊猫爸爸',
              narratorState: 'thumbsup',
              narratorAnimation: 'single-heartbeat-animation',
              text: '你们包的饺子好可爱呀！',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 37,
          },
          {
            id: 37,
            name: 'narration-scene',
            options: {
              narrator: '熊猫妈妈',
              narratorState: 'happyarmsfolded',
              narratorAnimation: 'single-heartbeat-animation',
              text: '让我来帮你们煮饺子吧！',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 38,
          },
          {
            id: 38,
            name: 'narration-scene',
            options: {
              narrator: '熊猫妈妈',
              narratorState: 'cook',
              narratorAnimation: 'single-heartbeat-animation',
              text: '煮，煮，煮！',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 39,
          },
          {
            id: 39,
            name: 'frame-scene',
            options: {
              portrait: '',
              text: '熊猫妈妈把饺子放进一锅烧开的水里煮。',
              frameIndex: 6,
            },
            transition: 'fadeIn',
            nextSceneId: 40,
          },
          {
            id: 40,
            name: 'frame-scene',
            options: {
              portrait: '',
              text: '你们看！饺子煮好了！',
              frameIndex: 7,
            },
            transition: 'fadeIn',
            nextSceneId: 41,
          },
          // DUMPLING SEQUENCE END
          // WRAP TANG YUAN NEXT
          {
            id: 41,
            name: 'narration-scene',
            options: {
              narrator: '熊猫姐姐和弟弟',
              narratorState: 'cheerful',
              narratorAnimation: 'single-heartbeat-animation',
              text: '接下来，我们包汤圆吧。',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 42,
          },
          // TANG YUAN SEQUENCE
          {
            id: 42,
            name: 'narration-scene',
            options: {
              narrator: '熊猫弟弟',
              narratorState: 'eureka',
              narratorAnimation: 'single-heartbeat-animation',
              text: '包汤圆先要做面团。',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 43,
          },
          {
            id: 43,
            name: 'narration-scene',
            options: {
              narrator: '熊猫弟弟',
              narratorState: 'think',
              narratorAnimation: 'single-heartbeat-animation',
              text: '让我想一想， 做汤圆的⾯团要用什么呢？',
              challengeText: '让我想⼀想， 做汤圆面团的材料是什么呢？',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 44,
          },
          {
            id: 44,
            name: 'frame-clickable-popup-scene',
            options: {
              narrator: '',
              text: '选择当中选出正确的答案',
              choices: [{
                text: '1',
                nextSceneId: 45,
                imageName: 'ingredient-4',
              },
              {
                text: '2',
                nextSceneId: 46,
                imageName: 'ingredient-5',
              }],
            },
            transition: 'fadeIn',
          },
          {
            id: 45,
            name: 'narration-scene',
            options: {
              narrator: '熊猫弟弟',
              narratorState: 'sad',
              narratorAnimation: 'single-heartbeat-animation',
              text: '再想⼀想！',
              challengeText: '好像不对... 再想一想！',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 44,
          },
          {
            id: 46,
            name: 'narration-scene',
            options: {
              narrator: '熊猫弟弟',
              narratorState: 'thumbsup',
              narratorAnimation: 'single-heartbeat-animation',
              text: '答对了！',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 47,
          },
          {
            id: 47,
            name: 'frame-scene',
            options: {
              portrait: '',
              text: '我们⽤糯米粉和水做汤圆的⾯团。',
              frameIndex: 8,
            },
            transition: 'fadeIn',
            nextSceneId: 48,
          },
          {
            id: 48,
            name: 'frame-scene',
            options: {
              portrait: '',
              text: '你们看！汤圆的面团做好了！',
              frameIndex: 9,
            },
            transition: 'fadeIn',
            nextSceneId: 49,
          },
          {
            id: 49,
            name: 'narration-scene',
            options: {
              narrator: '熊猫姐姐',
              narratorState: 'cheerful',
              narratorAnimation: 'single-heartbeat-animation',
              text: '让我来搓⼩小的汤圆吧！',
              challengeText: '让我用面团搓出⼩小的汤圆吧！',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 50,
          },
          {
            id: 50,
            name: 'tangyuan-clicker-scene',
            options: {
              narrator: '',
              narratorState: '',
              narratorAnimation: 'single-heartbeat-animation',
              text: '小朋友们，帮熊猫姐姐搓汤圆吧！',
              background: 'black',
              scoreTarget: 10,
            },
            transition: 'fadeIn',
            nextSceneId: 51,
          },
          {
            id: 51,
            name: 'narration-scene',
            options: {
              narrator: '熊猫姐姐',
              narratorState: 'cheerful',
              narratorAnimation: 'single-heartbeat-animation',
              text: '汤圆搓好了！',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 52,
          },
          {
            id: 52,
            name: 'narration-scene',
            options: {
              narrator: '熊猫爸爸',
              narratorState: 'thumbsup',
              narratorAnimation: 'single-heartbeat-animation',
              text: '你们搓的汤圆⼜圆又好！',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 53,
          },
          {
            id: 53,
            name: 'narration-scene',
            options: {
              narrator: '熊猫妈妈',
              narratorState: 'happyarmsfolded',
              narratorAnimation: 'single-heartbeat-animation',
              text: '让我来帮你们煮汤圆吧！',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 54,
          },
          {
            id: 54,
            name: 'narration-scene',
            options: {
              narrator: '熊猫妈妈',
              narratorState: 'cook',
              narratorAnimation: 'single-heartbeat-animation',
              text: '煮，煮，煮！',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 55,
          },
          {
            id: 55,
            name: 'frame-scene',
            options: {
              portrait: '',
              text: '熊猫妈妈把汤圆放进一锅烧开的水⾥煮。',
              frameIndex: 11,
            },
            transition: 'fadeIn',
            nextSceneId: 56,
          },
          {
            id: 56,
            name: 'frame-scene',
            options: {
              portrait: '',
              text: '你们看！汤圆煮好了！',
              frameIndex: 12,
            },
            transition: 'fadeIn',
            nextSceneId: 101,
          },
          // TANGYUAN SEQUENCE END
          // WRAP TANGYUAN FIRST
          {
            id: 57,
            name: 'narration-scene',
            options: {
              narrator: '熊猫姐姐和弟弟',
              narratorState: 'cheerful',
              narratorAnimation: 'single-heartbeat-animation',
              text: '好！我们先包汤圆吧。',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 58,
          },
          // TANG YUAN SEQUENCE
          {
            id: 58,
            name: 'narration-scene',
            options: {
              narrator: '熊猫弟弟',
              narratorState: 'eureka',
              narratorAnimation: 'single-heartbeat-animation',
              text: '包汤圆先要做面团。',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 60,
          },
          {
            id: 60,
            name: 'narration-scene',
            options: {
              narrator: '熊猫弟弟',
              narratorState: 'think',
              narratorAnimation: 'single-heartbeat-animation',
              text: '让我想一想， 做汤圆的⾯团要用什么呢？',
              challengeText: '让我想⼀想， 做汤圆面团的材料是什么呢？',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 61,
          },
          {
            id: 61,
            name: 'frame-clickable-popup-scene',
            options: {
              narrator: '',
              text: '选择当中选出正确的答案',
              choices: [{
                text: '1',
                nextSceneId: 62,
                imageName: 'ingredient-4',
              },
              {
                text: '2',
                nextSceneId: 63,
                imageName: 'ingredient-5',
              }],
            },
            transition: 'fadeIn',
          },
          {
            id: 62,
            name: 'narration-scene',
            options: {
              narrator: '熊猫弟弟',
              narratorState: 'sad',
              narratorAnimation: 'single-heartbeat-animation',
              text: '再想⼀想！',
              challengeText: '好像不对... 再想一想！',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 61,
          },
          {
            id: 63,
            name: 'narration-scene',
            options: {
              narrator: '熊猫弟弟',
              narratorState: 'thumbsup',
              narratorAnimation: 'single-heartbeat-animation',
              text: '答对了！',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 64,
          },
          {
            id: 64,
            name: 'frame-scene',
            options: {
              portrait: '',
              text: '我们⽤糯米粉和水做汤圆的⾯团。',
              frameIndex: 8,
            },
            transition: 'fadeIn',
            nextSceneId: 65,
          },
          {
            id: 65,
            name: 'frame-scene',
            options: {
              portrait: '',
              text: '你们看！汤圆的面团做好了！',
              frameIndex: 9,
            },
            transition: 'fadeIn',
            nextSceneId: 66,
          },
          {
            id: 66,
            name: 'narration-scene',
            options: {
              narrator: '熊猫姐姐',
              narratorState: 'cheerful',
              narratorAnimation: 'single-heartbeat-animation',
              text: '让我来搓⼩小的汤圆吧！',
              challengeText: '让我用面团搓出⼩小的汤圆吧！',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 67,
          },
          {
            id: 67,
            name: 'tangyuan-clicker-scene',
            options: {
              narrator: '',
              narratorState: '',
              narratorAnimation: 'single-heartbeat-animation',
              text: '小朋友们，帮熊猫姐姐搓汤圆吧！',
              background: 'black',
              scoreTarget: 10,
              choices: [{
                text: '搓汤圆',
                nextSceneId: 68,
              }],
            },
            transition: 'fadeIn',
          },
          {
            id: 68,
            name: 'narration-scene',
            options: {
              narrator: '熊猫姐姐',
              narratorState: 'cheerful',
              narratorAnimation: 'single-heartbeat-animation',
              text: '汤圆搓好了！',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 69,
          },
          {
            id: 69,
            name: 'narration-scene',
            options: {
              narrator: '熊猫爸爸',
              narratorState: 'thumbsup',
              narratorAnimation: 'single-heartbeat-animation',
              text: '你们搓的汤圆⼜圆又好！',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 70,
          },
          {
            id: 70,
            name: 'narration-scene',
            options: {
              narrator: '熊猫妈妈',
              narratorState: 'happyarmsfolded',
              narratorAnimation: 'single-heartbeat-animation',
              text: '让我来帮你们煮汤圆吧！',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 71,
          },
          {
            id: 71,
            name: 'narration-scene',
            options: {
              narrator: '熊猫妈妈',
              narratorState: 'cook',
              narratorAnimation: 'single-heartbeat-animation',
              text: '煮，煮，煮！',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 72,
          },
          {
            id: 72,
            name: 'frame-scene',
            options: {
              portrait: '',
              text: '熊猫妈妈把汤圆放进一锅烧开的水⾥煮。',
              frameIndex: 11,
            },
            transition: 'fadeIn',
            nextSceneId: 73,
          },
          {
            id: 73,
            name: 'frame-scene',
            options: {
              portrait: '',
              text: '你们看！汤圆煮好了！',
              frameIndex: 12,
            },
            transition: 'fadeIn',
            nextSceneId: 74,
          },
          // TANG YUAN SEQUENCE END
          {
            id: 74,
            name: 'narration-scene',
            options: {
              narrator: '熊猫姐姐和弟弟',
              narratorState: 'cheerful',
              narratorAnimation: 'single-heartbeat-animation',
              text: '接下来，我们包饺子吧。',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 75,
          },
          // DUMPLING SEQUENCE
          {
            id: 75,
            name: 'narration-scene',
            options: {
              narrator: '熊猫姐姐和弟弟',
              narratorState: 'makeapoint',
              narratorAnimation: 'single-heartbeat-animation',
              text: '包饺子要⽤饺⼦皮和饺子馅。',
              challengeText: '包饺⼦需要饺子⽪和饺子馅。',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 76,
          },
          {
            id: 76,
            name: 'narration-scene',
            options: {
              narrator: '熊猫弟弟',
              narratorState: 'eureka',
              narratorAnimation: 'single-heartbeat-animation',
              text: '我们先来做饺子⽪吧！',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 77,
          },
          {
            id: 77,
            name: 'narration-scene',
            options: {
              narrator: '熊猫弟弟',
              narratorState: 'think',
              narratorAnimation: 'single-heartbeat-animation',
              text: '我不记得怎样做饺⼦皮了！',
              challengeText: '我忘记怎样做饺子皮了！',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 78,
          },
          {
            id: 78,
            name: 'narration-scene',
            options: {
              narrator: '熊猫弟弟',
              narratorState: 'puzzled',
              narratorAnimation: 'single-heartbeat-animation',
              text: '小朋友们，你们能帮帮我吗？',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 79,
          },
          {
            id: 79,
            name: 'narration-scene',
            options: {
              narrator: '熊猫弟弟',
              narratorState: 'puzzled',
              narratorAnimation: 'single-heartbeat-animation',
              text: '饺⼦皮要⽤什么呢？',
              challengeText: '饺子皮需要什么材料呢？',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 80,
          },
          {
            id: 80,
            name: 'frame-clickable-popup-scene',
            options: {
              narrator: '',
              text: '选择当中选出正确的答案',
              choices: [{
                text: '1',
                nextSceneId: 82,
                imageName: 'ingredient-4',
              },
              {
                text: '2',
                nextSceneId: 81,
                imageName: 'ingredient-5',
              }],
            },
            transition: 'fadeIn',
          },
          {
            id: 81,
            name: 'narration-scene',
            options: {
              narrator: '熊猫弟弟',
              narratorState: 'sad',
              narratorAnimation: 'single-heartbeat-animation',
              text: '再想⼀想！',
              challengeText: '好像不对，再想一想！',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 80,
          },
          {
            id: 82,
            name: 'narration-scene',
            options: {
              narrator: '熊猫弟弟',
              narratorState: 'thumbsup',
              narratorAnimation: 'single-heartbeat-animation',
              text: '答对了！',
              challengeText: '太棒了！答对了。',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 83,
          },
          {
            id: 83,
            name: 'frame-scene',
            options: {
              portrait: 'panda-bro-correct',
              text: '饺⼦皮要⽤面粉和水。',
              challengeText: '饺子⽪需要的材料是面粉和水。',
              frameIndex: 2,
            },
            transition: 'fadeIn',
            nextSceneId: 84,
          },
          {
            id: 84,
            name: 'frame-scene',
            options: {
              portrait: 'panda-bro-cheerful',
              text: '你们看！饺⼦皮做好了！',
              frameIndex: 3,
            },
            transition: 'fadeIn',
            nextSceneId: 85,
          },
          {
            id: 85,
            name: 'narration-scene',
            options: {
              narrator: '熊猫姐姐',
              narratorState: 'eureka',
              narratorAnimation: 'single-heartbeat-animation',
              text: '我们接下来做饺子馅吧！',
              challengeText: '我们接下来准备饺子馅吧！',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 86,
          },
          {
            id: 86,
            name: 'narration-scene',
            options: {
              narrator: '熊猫姐姐',
              narratorState: 'puzzled',
              narratorAnimation: 'single-heartbeat-animation',
              text: '小朋友们，饺⼦馅⼜要用什么呢？',
              challengeText: '⼩朋友们，饺⼦馅又需要什么材料，你们知道吗？',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 87,
          },
          {
            id: 87,
            name: 'frame-clickable-popup-scene',
            options: {
              narrator: '',
              text: '选择当中选出正确的答案',
              choices: [{
                text: '4',
                nextSceneId: 88,
                imageName: 'ingredient-5',
              },
              {
                text: '5',
                nextSceneId: 89,
                imageName: 'ingredient-6',
              }],
            },
            transition: 'fadeIn',
          },
          {
            id: 88,
            name: 'narration-scene',
            options: {
              narrator: '熊猫姐姐',
              narratorState: 'sad',
              narratorAnimation: 'single-heartbeat-animation',
              text: '再想⼀想！',
              challengeText: '好像不对... 再想一想！',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 87,
          },
          {
            id: 89,
            name: 'narration-scene',
            options: {
              narrator: '熊猫姐姐',
              narratorState: 'thumbsup',
              narratorAnimation: 'single-heartbeat-animation',
              text: '答对了！',
              challengeText: '太棒了！答对了。',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 90,
          },
          {
            id: 90,
            name: 'frame-scene',
            options: {
              portrait: 'panda-sis-correct',
              text: '饺⼦馅要⽤猪⾁碎、包菜、洋葱和调味料。',
              challengeText: '饺⼦馅需要的材料是猪⾁碎，包菜，洋葱和调味料。',
              frameIndex: 4,
            },
            transition: 'fadeIn',
            nextSceneId: 91,
          },
          {
            id: 91,
            name: 'frame-scene',
            options: {
              portrait: 'panda-sis-cheerful',
              text: '饺子馅做好了！',
              frameIndex: 18,
            },
            transition: 'fadeIn',
            nextSceneId: 92,
          },
          {
            id: 92,
            name: 'narration-scene',
            options: {
              narrator: '熊猫弟弟',
              narratorState: 'eureka',
              narratorAnimation: 'single-heartbeat-animation',
              text: '有了饺子皮。',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 93,
          },
          {
            id: 93,
            name: 'narration-scene',
            options: {
              narrator: '熊猫姐姐',
              narratorState: 'eureka',
              narratorAnimation: 'single-heartbeat-animation',
              text: '还有饺子馅。',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 94,
          },
          {
            id: 94,
            name: 'narration-scene',
            options: {
              narrator: '熊猫姐姐和弟弟',
              narratorState: 'cheerful',
              narratorAnimation: 'single-heartbeat-animation',
              text: '就能开始包饺⼦了',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 95,
          },
          {
            id: 95,
            name: 'frame-scene',
            options: {
              portrait: '',
              text: '熊猫姐姐和弟弟包了很多饺子，每个饺子看起来都可爱极了！',
              challengeText: '熊猫姐姐和弟弟包了很多饺子，每个饺子看起来都像⼀个⼩元宝，可爱极了！',
              frameIndex: 5,
            },
            transition: 'fadeIn',
            nextSceneId: 96,
          },
          {
            id: 96,
            name: 'narration-scene',
            options: {
              narrator: '熊猫爸爸',
              narratorState: 'thumbsup',
              narratorAnimation: 'single-heartbeat-animation',
              text: '你们包的饺子好可爱呀！',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 97,
          },
          {
            id: 97,
            name: 'narration-scene',
            options: {
              narrator: '熊猫妈妈',
              narratorState: 'happyarmsfolded',
              narratorAnimation: 'single-heartbeat-animation',
              text: '让我来帮你们煮饺子吧！',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 98,
          },
          {
            id: 98,
            name: 'narration-scene',
            options: {
              narrator: '熊猫妈妈',
              narratorState: 'cook',
              narratorAnimation: 'single-heartbeat-animation',
              text: '煮，煮，煮！',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 99,
          },
          {
            id: 99,
            name: 'frame-scene',
            options: {
              portrait: '',
              text: '熊猫妈妈把饺子放进一锅烧开的水里煮。',
              frameIndex: 6,
            },
            transition: 'fadeIn',
            nextSceneId: 100,
          },
          {
            id: 100,
            name: 'frame-scene',
            options: {
              portrait: '',
              text: '你们看！饺子煮好了！',
              frameIndex: 7,
            },
            transition: 'fadeIn',
            nextSceneId: 101,
          },
          // DUMPLING SEQUENCE END
          // END SEQUENCE
          {
            id: 101,
            name: 'narration-scene',
            options: {
              narrator: '熊猫爸爸和妈妈',
              narratorState: 'happy',
              narratorAnimation: 'single-heartbeat-animation',
              text: '汤圆和饺子看起来真好吃。',
              challengeText: '汤圆和饺子看起来都很美味可口。',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 102,
          },
          {
            id: 102,
            name: 'narration-scene',
            options: {
              narrator: '熊猫姐姐和弟弟',
              narratorState: 'cheerful',
              narratorAnimation: 'single-heartbeat-animation',
              text: '我们一起来吃汤圆和饺子吧！',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 103,
          },
          {
            id: 103,
            name: 'narration-scene',
            options: {
              narrator: '熊猫博士',
              narratorState: 'happyhandsonhips',
              narratorAnimation: 'single-heartbeat-animation',
              text: '⼩朋友们，熊猫一家煮的饺子和汤圆看起来好吃吗？',
              challengeText: '⼩朋友们，熊猫一家煮好的饺子和汤圆看起来怎么样？',
              background: 'black',
            },
            transition: 'fadeIn',
            nextSceneId: 104,
          },
          {
            id: 104,
            name: 'narration-scene',
            options: {
              narrator: '熊猫博士',
              narratorState: 'eureka',
              narratorAnimation: 'single-heartbeat-animation',
              text: '你们喜欢吃哪一个呢？',
              background: 'black',
            },
            transition: 'fadeIn',
            nextSceneId: 105,
          },
          {
            id: 105,
            name: 'frame-clickable-popup-scene',
            options: {
              narrator: '',
              text: '包饺子还是包汤圆呢？',
              choices: [{
                text: '汤圆',
                nextSceneId: 106,
                imageName: 'food-2',
              },
              {
                text: '两样都喜欢',
                nextSceneId: 107,
                imageName: 'food-1',
              },
              {
                text: '饺⼦',
                nextSceneId: 108,
                imageName: 'food-3',
              }],
            },
            transition: 'fadeIn',
          },
          {
            id: 106,
            name: 'narration-scene',
            options: {
              narrator: '熊猫博士',
              narratorState: 'happyhandsonhips',
              narratorAnimation: 'single-heartbeat-animation',
              text: '是吗？我也喜欢吃汤圆。',
              background: 'black',
            },
            transition: 'fadeIn',
            nextSceneId: 109,
          },
          {
            id: 107,
            name: 'narration-scene',
            options: {
              narrator: '熊猫博士',
              narratorState: 'happyhandsonhips',
              narratorAnimation: 'single-heartbeat-animation',
              text: '我也是，我都喜欢吃。',
              background: 'black',
            },
            transition: 'fadeIn',
            nextSceneId: 109,
          },
          {
            id: 108,
            name: 'narration-scene',
            options: {
              narrator: '熊猫博士',
              narratorState: 'happyhandsonhips',
              narratorAnimation: 'single-heartbeat-animation',
              text: '是吗？我也喜欢吃饺子。',
              background: 'black',
            },
            transition: 'fadeIn',
            nextSceneId: 109,
          },
          {
            id: 109,
            name: 'narration-scene',
            options: {
              narrator: '熊猫博士',
              narratorState: 'happyhandsonhips',
              narratorAnimation: 'single-heartbeat-animation',
              text: '我们下次再和熊猫一家⼀起吃饺子和汤圆吧！下回再见！',
              challengeText: '我们下次再和熊猫一家一起吃美味可口的饺子和汤圆吧！下回再见！',
              background: 'black',
            },
            transition: 'fadeIn',
            nextSceneId: 110,
          },
          {
            id: 110,
            name: 'frame-scene',
            options: {
              portrait: '',
              text: '⼩朋友们，你们猜一猜！他们又是谁呢？',
              challengeText: '⼩朋友们，你们来猜猜看！他们又是谁呢？',
              frameIndex: 13,
            },
            transition: 'fadeIn',
            nextSceneId: 0,
          },

        ],
      },
      {
        // book id
        id: 3,
        title: '⿊熊父子',
        sceneData: [
          {
            id: 0,
            name: 'title-scene',
            options: {
              text: '⿊熊父子',
              subtitle: '',
            },
            transition: 'fadeIn',
            nextSceneId: 1,
          },
          {
            id: 1,
            name: 'narration-scene',
            options: {
              narrator: '熊猫博士',
              narratorState: 'wave',
              narratorAnimation: 'single-heartbeat-animation',
              text: '小朋友们，欢迎来到故事国。',
              challengeText: '⼩朋友们，欢迎你们来到故事国。',
              background: 'black',
            },
            transition: 'fadeIn',
            nextSceneId: 2,
          },
          {
            id: 2,
            name: 'narration-scene',
            options: {
              narrator: '熊猫博士',
              narratorState: 'eureka',
              narratorAnimation: 'single-heartbeat-animation',
              text: '我们来听⼀听，看⼀看熊猫一家接下来还会做什么吧！',
              challengeText: '我们继续听故事，看一看熊猫一家接下来会发生什么事。',
              background: 'black',
            },
            transition: 'fadeIn',
            nextSceneId: 3,
          },
          {
            id: 3,
            name: 'frame-scene',
            options: {
              portrait: '',
              text: '在竹林里，熊猫一家煮好了汤圆和饺⼦。',
              challengeText: '在⽩雪皑皑的⽵林里，熊猫⼀家煮好了汤圆和饺子。',
              frameIndex: 1,
            },
            transition: 'fadeIn',
            nextSceneId: 4,
          },
          {
            id: 4,
            name: 'narration-scene',
            options: {
              narrator: '熊猫爸爸和妈妈',
              narratorState: 'happy',
              narratorAnimation: 'single-heartbeat-animation',
              text: '汤圆和饺⼦看起来都很好吃！',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 5,
          },
          {
            id: 5,
            name: 'narration-scene',
            options: {
              narrator: '熊猫姐姐和弟弟',
              narratorState: 'cheerful',
              narratorAnimation: 'single-heartbeat-animation',
              text: '我们一起来吃汤圆和饺子吧！',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 6,
          },
          {
            id: 6,
            name: 'frame-scene',
            options: {
              portrait: '',
              text: '⼩朋友们，你们看！',
              frameIndex: 10,
            },
            transition: 'fadeIn',
            nextSceneId: 7,
          },
          {
            id: 7,
            name: 'frame-scene',
            options: {
              portrait: '',
              text: '饺⼦和汤圆都不见了。',
              frameIndex: 10,
            },
            transition: 'fadeIn',
            nextSceneId: 8,
          },
          {
            id: 8,
            name: 'narration-scene',
            options: {
              narrator: '熊猫⼀家⼈',
              narratorState: 'sad',
              narratorAnimation: 'single-heartbeat-animation',
              text: '⾷物不见了？发生了什么事？',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 9,
          },
          {
            id: 9,
            name: 'frame-clickable-popup-scene',
            options: {
              narrator: '',
              text: '为什么食物不见了？',
              choices: [{
                text: '姐姐偷吃了！',
                nextSceneId: 10,
                imageName: 'suspect-1',
              },
              {
                text: '弟弟偷吃了！',
                nextSceneId: 11,
                imageName: 'suspect-2',
              },
              {
                text: '有人偷走了食物？',
                nextSceneId: 13,
                imageName: 'suspect-3',
              }],
            },
            transition: 'fadeIn',
          },
          {
            id: 10,
            name: 'narration-scene',
            options: {
              narrator: '熊猫姐姐',
              narratorState: 'sad',
              narratorAnimation: 'single-heartbeat-animation',
              text: '我没有偷吃！我⼀直在等着和大家一起吃！',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 9,
          },
          {
            id: 11,
            name: 'narration-scene',
            options: {
              narrator: '熊猫弟弟',
              narratorState: 'sad',
              narratorAnimation: 'single-heartbeat-animation',
              text: '我...我... 我只偷吃了⼀小口！',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 12,
          },
          {
            id: 12,
            name: 'narration-scene',
            options: {
              narrator: '熊猫弟弟',
              narratorState: 'sad',
              narratorAnimation: 'single-heartbeat-animation',
              text: '我没有全吃了呀！',
              challengeText: '我没有把⾷物全都吃了呀！',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 9,
          },
          {
            id: 13,
            name: 'narration-scene',
            options: {
              narrator: '熊猫爸爸',
              narratorState: 'think',
              narratorAnimation: 'single-heartbeat-animation',
              text: '会不会是被人偷走了？',
              challengeText: '会不会是让别人偷走了？',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 14,
          },
          {
            id: 14,
            name: 'narration-scene',
            options: {
              narrator: '熊猫妈妈',
              narratorState: 'puzzled',
              narratorAnimation: 'single-heartbeat-animation',
              text: '偷⾛了？是谁？',
              challengeText: '让别人偷⾛了？是谁？',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 15,
          },
          {
            id: 15,
            name: 'frame-scene',
            options: {
              portrait: '',
              text: '⼩朋友们，你们知道是谁偷走食物吗？',
              frameIndex: 2,
            },
            transition: 'fadeIn',
            nextSceneId: 16,
          },
          {
            id: 16,
            name: 'narration-scene',
            options: {
              narrator: '熊猫姐姐和弟弟',
              narratorState: 'pointdown',
              narratorAnimation: 'single-heartbeat-animation',
              text: '爸爸妈妈，你们看地上！',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 17,
          },
          {
            id: 17,
            name: 'frame-scene',
            options: {
              portrait: '',
              text: '⼩朋友们，你们看！地上有什么？',
              frameIndex: 3,
            },
            transition: 'fadeIn',
            nextSceneId: 18,
          },
          {
            id: 18,
            name: 'frame-scene',
            options: {
              portrait: '',
              text: '地上有汤圆和饺子的线索。',
              challengeText: '⼩偷不⼩心在地上留下了汤圆和饺⼦的线索！',
              frameIndex: 3,
            },
            transition: 'fadeIn',
            nextSceneId: 19,
          },
          {
            id: 19,
            name: 'narration-scene',
            options: {
              narrator: '熊猫爸爸',
              narratorState: 'shock',
              narratorAnimation: 'single-heartbeat-animation',
              text: '那是我们的汤圆和饺子。',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 20,
          },
          {
            id: 20,
            name: 'narration-scene',
            options: {
              narrator: '熊猫妈妈',
              narratorState: 'stop',
              narratorAnimation: 'single-heartbeat-animation',
              text: '一定是⼩偷不⼩⼼留下来的。',
              challengeText: '⼀定是⼩偷不小心留下来的线索。',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 21,
          },
          {
            id: 21,
            name: 'narration-scene',
            options: {
              narrator: '熊猫姐姐和弟弟',
              narratorState: 'angry',
              narratorAnimation: 'single-heartbeat-animation',
              text: '我们快去找出小偷吧！',
              challengeText: '我们快跟着线索找出小偷吧！',
              background: 'bamboo-white',
            },
            transition: 'fadeIn',
            nextSceneId: 22,
          },
          {
            id: 22,
            name: 'frame-scene',
            options: {
              portrait: '',
              text: '熊猫⼀家⼀起去找小偷。',
              challengeText: '熊猫⼀家跟着⼩偷留下来的线索去找小偷。',
              frameIndex: 4,
            },
            transition: 'fadeIn',
            nextSceneId: 23,
          },
          {
            id: 23,
            name: 'frame-scene',
            options: {
              portrait: '',
              text: '他们来到了竹林外的一个山洞。',
              frameIndex: 5,
            },
            transition: 'fadeIn',
            nextSceneId: 24,
          },
          {
            id: 24,
            name: 'narration-scene',
            options: {
              narrator: '熊猫妈妈',
              narratorState: 'puzzled',
              narratorAnimation: 'single-heartbeat-animation',
              text: '这是哪⾥？',
              background: 'cave',
            },
            transition: 'fadeIn',
            nextSceneId: 25,
          },
          {
            id: 25,
            name: 'narration-scene',
            options: {
              narrator: '熊猫爸爸',
              narratorState: 'think',
              narratorAnimation: 'single-heartbeat-animation',
              text: '这⾥好像是⼀个山洞。',
              background: 'cave',
            },
            transition: 'fadeIn',
            nextSceneId: 26,
          },
          {
            id: 26,
            name: 'narration-scene',
            options: {
              narrator: '熊猫姐姐',
              narratorState: 'pointup',
              narratorAnimation: 'single-heartbeat-animation',
              text: '爸爸、妈妈，你们看！',
              challengeText: '爸爸妈妈，你们看！那里有人！',
              background: 'cave',
            },
            transition: 'fadeIn',
            nextSceneId: 27,
          },
          {
            id: 27,
            name: 'narration-scene',
            options: {
              narrator: '熊猫弟弟',
              narratorState: 'pointup',
              narratorAnimation: 'single-heartbeat-animation',
              text: '它们... 它们手里拿着我们家的碗。',
              challengeText: '它们... 它们手里拿着我们装汤圆和饺⼦的碗！',
              background: 'cave',
            },
            transition: 'fadeIn',
            nextSceneId: 28,
          },
          {
            id: 28,
            name: 'frame-scene',
            options: {
              portrait: '',
              text: '它们是黑熊爸爸和⿊熊宝宝。',
              frameIndex: 6,
            },
            transition: 'fadeIn',
            nextSceneId: 29,
          },
          {
            id: 29,
            name: 'frame-scene',
            options: {
              portrait: '',
              text: '它们为什么要站在山洞外呢？',
              challengeText: '为什么它们迷迷糊糊地站在⼭洞外呢？',
              frameIndex: 6,
            },
            transition: 'fadeIn',
            nextSceneId: 30,
          },
          {
            id: 30,
            name: 'narration-scene',
            options: {
              narrator: '⿊熊爸爸',
              narratorState: 'checkbowl',
              narratorAnimation: 'single-heartbeat-animation',
              text: '为什么碗⾥的⾷物都不⻅了？',
              background: 'cave',
            },
            transition: 'fadeIn',
            nextSceneId: 31,
          },
          {
            id: 31,
            name: 'narration-scene',
            options: {
              narrator: '黑熊宝宝',
              narratorState: 'pointdown',
              narratorAnimation: 'single-heartbeat-animation',
              text: '好像是掉在地上了。',
              background: 'cave',
            },
            transition: 'fadeIn',
            nextSceneId: 32,
          },
          {
            id: 32,
            name: 'frame-scene',
            options: {
              portrait: '',
              text: '熊猫⼀家⽣气地看着⿊熊爸爸和宝宝。',
              challengeText: '熊猫一家怒气冲冲地看着⿊熊爸爸和宝宝。',
              frameIndex: 7,
            },
            transition: 'fadeIn',
            nextSceneId: 33,
          },
          {
            id: 33,
            name: 'narration-scene',
            options: {
              narrator: '熊猫爸爸和妈妈',
              narratorState: 'angry',
              narratorAnimation: 'single-heartbeat-animation',
              text: '你们这些⼩偷，快站住！',
              background: 'cave',
            },
            transition: 'fadeIn',
            nextSceneId: 34,
          },
          {
            id: 34,
            name: 'narration-scene',
            options: {
              narrator: '黑熊爸爸和宝宝',
              narratorState: 'shock',
              narratorAnimation: 'single-heartbeat-animation',
              text: '是熊猫⼀家！被你们发现了。',
              background: 'cave',
            },
            transition: 'fadeIn',
            nextSceneId: 35,
          },
          {
            id: 35,
            name: 'narration-scene',
            options: {
              narrator: '熊猫姐姐',
              narratorState: 'angryarmsonhips',
              narratorAnimation: 'single-heartbeat-animation',
              text: '快把饺⼦和汤圆还给我们！',
              background: 'cave',
            },
            transition: 'fadeIn',
            nextSceneId: 36,
          },
          {
            id: 36,
            name: 'narration-scene',
            options: {
              narrator: '熊猫弟弟',
              narratorState: 'angryarmsfolded',
              narratorAnimation: 'single-heartbeat-animation',
              text: '你们知道吗？偷东⻄是不对的！',
              challengeText: '你们知道吗？偷别人的东西是不对的。',
              background: 'cave',
            },
            transition: 'fadeIn',
            nextSceneId: 37,
          },
          {
            id: 37,
            name: 'narration-scene',
            options: {
              narrator: '⿊熊爸爸',
              narratorState: 'crythree',
              narratorAnimation: 'single-heartbeat-animation',
              text: '我知道。对不起！',
              challengeText: '我知道，我也不想这样做。对不起！',
              background: 'cave',
            },
            transition: 'fadeIn',
            nextSceneId: 38,
          },
          {
            id: 38,
            name: 'narration-scene',
            options: {
              narrator: '黑熊宝宝',
              narratorState: 'crythree',
              narratorAnimation: 'single-heartbeat-animation',
              text: '对不起！对不起！',
              background: 'cave',
            },
            transition: 'fadeIn',
            nextSceneId: 39,
          },
          {
            id: 39,
            name: 'narration-scene',
            options: {
              narrator: '熊猫姐姐',
              narratorState: 'puzzled',
              narratorAnimation: 'single-heartbeat-animation',
              text: '⿊熊爸爸、⿊熊宝宝，你们怎么哭了？',
              background: 'cave',
            },
            transition: 'fadeIn',
            nextSceneId: 40,
          },
          {
            id: 40,
            name: 'narration-scene',
            options: {
              narrator: '熊猫弟弟',
              narratorState: 'puzzled',
              narratorAnimation: 'single-heartbeat-animation',
              text: '发⽣了什么事？',
              challengeText: '到底发生了什么事？',
              background: 'cave',
            },
            transition: 'fadeIn',
            nextSceneId: 41,
          },
          {
            id: 41,
            name: 'narration-scene',
            options: {
              narrator: '⿊熊爸爸',
              narratorState: 'cryone',
              narratorAnimation: 'single-heartbeat-animation',
              text: '我们不是故意要偷你们的食物。',
              background: 'cave',
            },
            transition: 'fadeIn',
            nextSceneId: 42,
          },
          {
            id: 42,
            name: 'narration-scene',
            options: {
              narrator: '黑熊宝宝',
              narratorState: 'cryone',
              narratorAnimation: 'single-heartbeat-animation',
              text: '我的妈妈每年冬至都会包饺子和汤圆，她也会和我们一起吃。',
              challengeText: '我的妈妈每年冬至都会包很多美味可口的饺子和汤圆，她也会和我们⼀起吃。',
              background: 'cave',
            },
            transition: 'fadeIn',
            nextSceneId: 43,
          },
          {
            id: 43,
            name: 'narration-scene',
            options: {
              narrator: '黑熊宝宝',
              narratorState: 'crytwo',
              narratorAnimation: 'single-heartbeat-animation',
              text: '可是，今年妈妈⽣病了，不能包饺子和汤圆。',
              challengeText: '可是，今年妈妈生了重病，没有办法包饺子和汤圆。',
              background: 'cave',
            },
            transition: 'fadeIn',
            nextSceneId: 44,
          },
          {
            id: 44,
            name: 'narration-scene',
            options: {
              narrator: '⿊熊爸爸',
              narratorState: 'crythree',
              narratorAnimation: 'single-heartbeat-animation',
              text: '我们俩也不会包。一闻到⾹香味，就忍不住偷了你们的⾷物。',
              challengeText: '我们父子俩也不会包，因此闻到你们煮的饺子和汤圆的⾹味，就一时冲动，偷了你们的⾷物。',
              background: 'cave',
            },
            transition: 'fadeIn',
            nextSceneId: 45,
          },
          {
            id: 45,
            name: 'narration-scene',
            options: {
              narrator: '黑熊宝宝',
              narratorState: 'crythree',
              narratorAnimation: 'single-heartbeat-animation',
              text: '我们只是想让⽣病的妈妈开心，早点好起来。',
              challengeText: '我们只是想让生病的妈妈开心⼀点，让她早日康复。',
              background: 'cave',
            },
            transition: 'fadeIn',
            nextSceneId: 46,
          },
          {
            id: 46,
            name: 'narration-scene',
            options: {
              narrator: '⿊熊爸爸',
              narratorState: 'crytwo',
              narratorAnimation: 'single-heartbeat-animation',
              text: '都是我不好！我没⽤，连饺子和汤圆都不会包。',
              challengeText: '都是我不好！我没用，连个饺子和汤圆都不会包。',
              background: 'cave',
            },
            transition: 'fadeIn',
            nextSceneId: 47,
          },
          {
            id: 47,
            name: 'narration-scene',
            options: {
              narrator: '熊猫姐姐',
              narratorState: 'think',
              narratorAnimation: 'single-heartbeat-animation',
              text: '原来是这样。',
              background: 'cave',
            },
            transition: 'fadeIn',
            nextSceneId: 48,
          },
          {
            id: 48,
            name: 'narration-scene',
            options: {
              narrator: '熊猫弟弟',
              narratorState: 'think',
              narratorAnimation: 'single-heartbeat-animation',
              text: '⿊熊爸爸和黑熊宝宝只想和黑熊妈妈一起庆祝冬至。',
              background: 'cave',
            },
            transition: 'fadeIn',
            nextSceneId: 49,
          },
          {
            id: 49,
            name: 'narration-scene',
            options: {
              narrator: '熊猫妈妈',
              narratorState: 'stop',
              narratorAnimation: 'single-heartbeat-animation',
              text: '可是，偷东西还是不对的。',
              background: 'cave',
            },
            transition: 'fadeIn',
            nextSceneId: 50,
          },
          {
            id: 50,
            name: 'narration-scene',
            options: {
              narrator: '熊猫爸爸',
              narratorState: 'lecture',
              narratorAnimation: 'single-heartbeat-animation',
              text: '你们下次可以先问，不要偷。',
              background: 'cave',
            },
            transition: 'fadeIn',
            nextSceneId: 51,
          },
          {
            id: 51,
            name: 'narration-scene',
            options: {
              narrator: '黑熊爸爸和宝宝',
              narratorState: 'guilty',
              narratorAnimation: 'single-heartbeat-animation',
              text: '是的，我们知道错了。',
              background: 'cave',
            },
            transition: 'fadeIn',
            nextSceneId: 52,
          },
          {
            id: 52,
            name: 'narration-scene',
            options: {
              narrator: '黑熊爸爸和宝宝',
              narratorState: 'apologetic',
              narratorAnimation: 'single-heartbeat-animation',
              text: '真的很对不起！',
              background: 'cave',
            },
            transition: 'fadeIn',
            nextSceneId: 53,
          },
          {
            id: 53,
            name: 'narration-scene',
            options: {
              narrator: '黑熊爸爸和宝宝',
              narratorState: 'holdbowl',
              narratorAnimation: 'single-heartbeat-animation',
              text: '我们偷了你们的汤圆和饺子，还不⼩心把⾷物都掉在地上。',
              challengeText: '我们不但把你们的汤圆和饺子偷走，还不⼩心把⾷物都掉在地上。',
              background: 'cave',
            },
            transition: 'fadeIn',
            nextSceneId: 54,
          },
          {
            id: 54,
            name: 'narration-scene',
            options: {
              narrator: '黑熊爸爸和宝宝',
              narratorState: 'guilty',
              narratorAnimation: 'single-heartbeat-animation',
              text: '现在⼤家都没有汤圆和饺子吃了。',
              challengeText: '现在弄得⼤家都没有汤圆和饺⼦吃了。',
              background: 'cave',
            },
            transition: 'fadeIn',
            nextSceneId: 55,
          },
          {
            id: 55,
            name: 'narration-scene',
            options: {
              narrator: '熊猫爸爸',
              narratorState: 'laughtwo',
              narratorAnimation: 'single-heartbeat-animation',
              text: '不⽤担⼼！这是小事。',
              background: 'cave',
            },
            transition: 'fadeIn',
            nextSceneId: 56,
          },
          {
            id: 56,
            name: 'narration-scene',
            options: {
              narrator: '熊猫妈妈',
              narratorState: 'happyhandsonhips',
              narratorAnimation: 'single-heartbeat-animation',
              text: '我们可以再包一些。',
              challengeText: '饺子和汤圆没了，我们还可以再包！',
              background: 'cave',
            },
            transition: 'fadeIn',
            nextSceneId: 57,
          },
          {
            id: 57,
            name: 'narration-scene',
            options: {
              narrator: '熊猫姐姐和弟弟',
              narratorState: 'cheerful',
              narratorAnimation: 'single-heartbeat-animation',
              text: '我们也可以教你们包饺⼦和汤圆。',
              background: 'cave',
            },
            transition: 'fadeIn',
            nextSceneId: 58,
          },
          {
            id: 58,
            name: 'narration-scene',
            options: {
              narrator: '黑熊爸爸和宝宝',
              narratorState: 'cheerful',
              narratorAnimation: 'single-heartbeat-animation',
              text: '真的吗？',
              background: 'cave',
            },
            transition: 'fadeIn',
            nextSceneId: 59,
          },
          {
            id: 59,
            name: 'narration-scene',
            options: {
              narrator: '熊猫姐姐和弟弟',
              narratorState: 'thumbsup',
              narratorAnimation: 'single-heartbeat-animation',
              text: '真的！',
              background: 'cave',
            },
            transition: 'fadeIn',
            nextSceneId: 60,
          },
          {
            id: 60,
            name: 'frame-scene',
            options: {
              portrait: '',
              text: '熊猫⼀家教⿊熊爸爸和⿊熊宝宝包饺子和汤圆。',
              challengeText: '熊猫一家把包饺子和汤圆的方法教给了黑熊爸爸和黑熊宝宝。',
              frameIndex: 8,
            },
            transition: 'fadeIn',
            nextSceneId: 61,
          },
          {
            id: 61,
            name: 'frame-scene',
            options: {
              portrait: '',
              text: '它们⼀起包了非常多的饺子和汤圆。',
              frameIndex: 9,
            },
            transition: 'fadeIn',
            nextSceneId: 62,
          },
          {
            id: 62,
            name: 'narration-scene',
            options: {
              narrator: '黑熊爸爸和宝宝',
              narratorState: 'cheerful',
              narratorAnimation: 'single-heartbeat-animation',
              text: '谢谢你们！',
              challengeText: '谢谢你们教我们包饺子和汤圆！',
              background: 'cave',
            },
            transition: 'fadeIn',
            nextSceneId: 63,
          },
          {
            id: 63,
            name: 'narration-scene',
            options: {
              narrator: '熊猫⼀家⼈',
              narratorState: 'happy',
              narratorAnimation: 'single-heartbeat-animation',
              text: '不客气！',
              challengeText: '不⽤客气！',
              background: 'cave',
            },
            transition: 'fadeIn',
            nextSceneId: 64,
          },
          {
            id: 64,
            name: 'narration-scene',
            options: {
              narrator: '黑熊爸爸和宝宝',
              narratorState: 'wave',
              narratorAnimation: 'single-heartbeat-animation',
              text: '我们现在就回家和黑熊妈妈一起庆祝冬至！再见！',
              background: 'cave',
            },
            transition: 'fadeIn',
            nextSceneId: 65,
          },
          {
            id: 65,
            name: 'narration-scene',
            options: {
              narrator: '熊猫⼀家⼈',
              narratorState: 'wave',
              narratorAnimation: 'single-heartbeat-animation',
              text: '再见！',
              background: 'cave',
            },
            transition: 'fadeIn',
            nextSceneId: 66,
          },
          {
            id: 66,
            name: 'narration-scene',
            options: {
              narrator: '熊猫爸爸和妈妈',
              narratorState: 'educate',
              narratorAnimation: 'single-heartbeat-animation',
              text: '我们也回家吧。',
              background: 'cave',
            },
            transition: 'fadeIn',
            nextSceneId: 67,
          },
          {
            id: 67,
            name: 'narration-scene',
            options: {
              narrator: '熊猫姐姐和弟弟',
              narratorState: 'cheerful',
              narratorAnimation: 'single-heartbeat-animation',
              text: '好的！',
              background: 'cave',
            },
            transition: 'fadeIn',
            nextSceneId: 68,
          },
          {
            id: 68,
            name: 'frame-scene',
            options: {
              portrait: '',
              text: '熊猫⼀家把包好的饺子和汤圆⼀起带回⽵林。',
              frameIndex: 11,
            },
            transition: 'fadeIn',
            nextSceneId: 69,
          },
          {
            id: 69,
            name: 'frame-scene',
            options: {
              portrait: '',
              text: '在⽵林⾥，它们一边开心地吃饺子和汤圆，一边有说有笑地庆祝冬至。',
              challengeText: '在竹林里，它们⼀边兴高采烈地吃饺⼦和汤圆，一边有说有笑地庆祝冬至。',
              frameIndex: 12,
            },
            transition: 'fadeIn',
            nextSceneId: 70,
          },
          {
            id: 70,
            name: 'narration-scene',
            options: {
              narrator: '熊猫博士',
              narratorState: 'reluctantsmile',
              narratorAnimation: 'single-heartbeat-animation',
              text: '⼩朋友们，熊猫⼀家庆祝冬至的故事讲完了。',
              challengeText: '⼩朋友们，熊猫一家过冬至的故事就到此结束了。',
              background: 'black',
            },
            transition: 'fadeIn',
            nextSceneId: 71,
          },
          {
            id: 71,
            name: 'narration-scene',
            options: {
              narrator: '熊猫博士',
              narratorState: 'eureka',
              narratorAnimation: 'single-heartbeat-animation',
              text: '你们喜欢这个故事吗？想不想听别的故事呢？',
              background: 'black',
            },
            transition: 'fadeIn',
            nextSceneId: 72,
          },
          {
            id: 72,
            name: 'narration-scene',
            options: {
              narrator: '熊猫博士',
              narratorState: 'happyhandsonhips',
              narratorAnimation: 'single-heartbeat-animation',
              text: '欢迎你们再来故事国听故事！',
              challengeText: '我们下次在故事国见，给你们讲新故事吧！',
              background: 'black',
            },
            transition: 'fadeIn',
            nextSceneId: 73,
          },
          {
            id: 73,
            name: 'narration-scene',
            options: {
              narrator: '熊猫博士',
              narratorState: 'wave',
              narratorAnimation: 'single-heartbeat-animation',
              text: '祝你们过一个温暖团圆的冬至！再见！',
              challengeText: '祝你们过一个温馨团圆的冬至！再见！',
              background: 'black',
            },
            transition: 'fadeIn',
            nextSceneId: 0,
          },

        ],
      },
    ],
    currentBookId: 0,
    currentSceneId: 0,
    // used to track history of scenes visited for back button
    scenesVisitedHeap: [],
    // 0 - normal difficulty, 1 - advanced difficulty
    // used to determine what sort of words are shown
    difficulty: 1,
  };
};

const state = getDefaultState();

export const dynamicBookModule = {
  namespaced: true,
  state,
  mutations: {
    resetStateMutation(state) {
      Object.assign(state, getDefaultState());
    },
    setCurrentBookId(state, value) {
      state.currentBookId = value;
    },
    setCurrentSceneId(state, value) {
      state.currentSceneId = value;
    },
    pushScenesVisitedHeap(state, value) {
      state.scenesVisitedHeap.push(value);
    },
    toggleDifficulty(state) {
      state.difficulty = state.difficulty === 0 ?
        1 : 0;
    },
  },
  actions: {
    // add error validation if no such scene
    goToScene({commit, state, getters}, nextSceneId) {
      if (getters.sceneIds.indexOf(nextSceneId) !== -1) {
        commit('pushScenesVisitedHeap', state.currentSceneId);
        commit('setCurrentSceneId', nextSceneId);
        return;
      };
      alert('Error going to scene: ' + nextSceneId);
    },
    nextScene({commit, state, dispatch, getters}) {
      const currentScene = getters.currentScene;
      if (getters.sceneIds.indexOf(currentScene.nextSceneId) !== -1) {
        dispatch('goToScene', currentScene.nextSceneId);
      };
    },
    previousScene({commit, state, dispatch, getters}) {
      // if there are no previous scenes, then do nothing
      if (state.scenesVisitedHeap.length === 0) return;

      const previousSceneId = state.scenesVisitedHeap.pop();
      // NOTE -> we use setCurrentSceneId mutation rather
      // than goToScene action as the latter will store the current
      // sceneId in scenesVisitedHeap (resulting in circular loop)
      commit('setCurrentSceneId', previousSceneId);
    },
  },
  getters: {
    bookTitles(state) {
      if (state.data.length === 0) return null;

      const results = [];
      for (let i = 0; i < state.data.length; i++) {
        const book = state.data[i];
        results.push({
          title: book.title,
          id: book.id,
        });
      };

      return results;
    },
    currentBook(state) {
      if (state.data.length === 0) return null;

      return state.data.find((book) => {
        return book.id === state.currentBookId;
      });
    },
    currentScene(state, getters) {
      if (!getters.currentBook) return null;
      if (getters.currentBook.sceneData.length === 0) return null;

      return getters.currentBook.sceneData.find((scene) => {
        return scene.id === state.currentSceneId;
      });
    },
    // used to determine if we have data about a particular scene
    sceneIds(state, getters) {
      if (!getters.currentBook) return null;

      const ids = [];
      const sceneData = getters.currentBook.sceneData;
      for (let i = 0; i < sceneData.length; i++) {
        ids.push(sceneData[i].id);
      };

      return ids;
    },
  },
};
