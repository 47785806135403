<template>
  <!-- MAIN PAGE -->
  <div id="pandabox-reward-menu">
    <div @click="rewardSequence"
      class="pandabox-reward__loots">
      abc
      {{ currentRewardCategory }}
      <!-- COINS -->
      <div v-if='currentRewardCategory === "coins"'
        class="pandabox-reward__loots--container pandabox-reward__coins">
        <div class="pandabox-reward__loots--subheader">
          <p>BONUS!</p>
        </div>
        <div class="pandabox-reward__loots--header">
          <h2>COINS</h2>
        </div>
        <div class="pandabox-reward__loots--img">
          <div class="coin-circle">
            <i class="fas fa-star"></i>
          </div>
        </div>
        <div class="pandabox-reward__loots--count">
          <h2>x{{ coinsToAdd }}</h2>
        </div>
        <img class="sunburst rotating-anim" src="../../assets/sunburst.png">
      </div>
      <!-- DIAMONDS -->
      <div v-if='currentRewardCategory === "diamonds"'
        class="pandabox-reward__loots--container pandabox-reward__diamonds">
        <div class="pandabox-reward__loots--subheader">
          <p>BONUS!</p>
        </div>
        <div class="pandabox-reward__loots--header">
          <h2>DIAMONDS</h2>
        </div>
        <div class="pandabox-reward__loots--img">
          <div class="diamond-circle">
            <i class="fas fa-gem"></i>
          </div>
        </div>
        <div class="pandabox-reward__loots--count">
          <h2>x{{ diamondsToAdd }}</h2>
        </div>
        <img class="sunburst rotating-anim" src="../../assets/sunburst.png">
      </div>
    </div>
  </div>
</template>

<script>
import {mapState, mapMutations} from 'vuex';

// for giving basic rewards like coins and diamonds only (no pets)
export default {
  name: 'BasicReward',
  components: {
  },
  props: {
    closeRewardsCallback: Function,
    coinsToAdd: Number,
    diamondsToAdd: Number,
  },
  data() {
    return {
      rewardSequenceCategories: ['coins', 'diamonds'],
      // controls which sequence to show after claiming
      sequenceCounter: -1,
      hasClaimedRewards: false,
    };
  },
  computed: {
    ...mapState(['studentName', 'avatar']),
    currentRewardCategory() {
      if (this.sequenceCounter === -1 || this.sequenceCounter >= this.rewardSequenceCategories.length) return;
      return this.rewardSequenceCategories[this.sequenceCounter];
    },
  },
  mounted() {
    // hackish, but widgets keep appearing. thus we hide when we show narrator popup, and then show it later
    this.setIsWidgetsShown(false);
    this.rewardSequence();
  },
  methods: {
    ...mapMutations(['setIsWidgetsShown']),
    end() {
      this.sequenceCounter = -1;
      this.setIsWidgetsShown(true);
      this.closeRewardsCallback();
    },
    rewardSequence() {
      this.sequenceCounter++;
      if (this.sequenceCounter >= this.rewardSequenceCategories.length) {
        this.end();
      } else {
        // skips to next category if missing
        if (this.currentRewardCategory === 'coins') {
          if (typeof this.coinsToAdd === 'undefined' || this.coinsToAdd === 0) {
            this.rewardSequence();
          }
        } else if (this.currentRewardCategory === 'diamonds') {
          if (typeof this.diamondsToAdd === 'undefined' || this.diamondsToAdd === 0) {
            this.rewardSequence();
          };
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
// STYLES FOR PANDABOX REWARD MENU PAGE
  #pandabox-reward-menu {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: fixed;
    background: #e9e9e9;
    top: 0;
    height: 100%;
    width: 100%;
  }
  .pandabox-reward {
    &__container {
      padding: 30px;
      background: #fff;
      border-radius: 10px;
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      align-items: center;
      margin: 10px;
      text-align: center;
    }
    &__title {
      text-transform: uppercase;
      margin: 2px auto 2px auto;
    }
    &__text {
      width: 100%;
      text-align: center;
      padding-bottom: 10px;
      border-bottom: 2px solid #eee;
      margin-bottom: 12px;
    }
    &__img {
      font-size: 8rem;
      color: #6e81de;
    }
    &__btn {
      margin-top: 10px;
      padding: 10px 0;
      font-size: 110%;
      border-radius: 4px;
      border: 1px solid transparent;
      cursor: pointer;
      background: #7b57a9;
      color: white;
      text-align: center;
      font-weight: 400;
      transition: transform 0.2s;
      text-decoration: none;
      width: 100%;
    }
  }

// STYLES FOR LOOTS
.pandabox-reward {
  &__loots {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 30;
    color: #fff;
    overflow: hidden;
    &--container {
      padding-bottom: 75px;
      position: absolute;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
    &--img {
      margin: 20px 0;
    }
    &--subheader {
      font-size: 0.8rem;
    }
  }
  &__coins {
    background: #59e0c1;
    .pandabox-reward__loots--subheader {
      visibility: hidden;
    }
  }
  &__diamonds {
    background: #1989fd;
  }
  &__pets {
    background: #000;
    &--card {
      border: 6px solid #b2e6ff;
      background: linear-gradient(#83baff, #e614ff);
      width: 245.25px;
      height: 273.75px;
      border-radius: 10px;
      color: #fff;
      margin-top: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      font-family: arial;
      font-weight: bold;
    }
  }
  &__summary {
    background: #000;
  }
}
// STYLES FOR COINS
.coin-circle {
  background: #ffbe43;
  border: 6px solid #000;
  border-radius: 50%;
  height: 120px;
  width: 120px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  i {
    font-size: 4rem;
    color: #fff;
  }
}

// STYLES FOR DIAMONDS
.diamond-circle {
  border: 6px solid #000;
  background: #fff;
  border-radius: 50%;
  height: 120px;
  width: 120px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  i {
    margin-top: 10px;
    font-size: 4rem;
    color: #4f7dff;
  }
}

// SUNBURST ANIMATION AND STYLINGS
.sunburst {
  z-index: 10;
  position: absolute;
}
.pandabox-reward__loots--subheader,
.pandabox-reward__loots--header,
.pandabox-reward__loots--img,
.pandabox-reward__loots--count {
  z-index: 20;
}

@-webkit-keyframes rotating-anim /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating-anim {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.rotating-anim {
  -webkit-animation: rotating-anim 20s linear infinite;
  -moz-animation: rotating-anim 20s linear infinite;
  -ms-animation: rotating-anim 20s linear infinite;
  -o-animation: rotating-anim 20s linear infinite;
  animation: rotating-anim 20s linear infinite;
}

// OLD COMPLETED MENU COMPONENT
/*
  .coin-circle.hidden {
    display: none;
  }
  #completed-menu {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 64px);
  }
  .hidden {
    display: none !important;
  }
  .completed-container {
    padding: 20px;
    background: #fff;
    border-radius: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 10px;
    text-align: center;
  }
  .coin-circle {
    background-color: #ffbe43;
    border-radius: 50%;
    font-size: 140%;
    margin: 5px 5px;
    width: 45px;
    height: 45px;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .avatar-container {
    border: 3px solid #000;
    text-align: center;
    border-radius: 10px;
  }

  .avatar-img {
    padding: 10px 10px 5px 10px;
  }

  .avatar-img + h4 {
    margin-bottom: 10px;
  }

  .completed-menu-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 20px;
  }

  .completed-menu-content h2 {
    text-transform: uppercase;
    margin: 2px auto 2px auto;
  }

  .completed-menu-content p {
    width: 100%;
    text-align: center;
    padding-bottom: 10px;
    border-bottom: 2px solid #eee;
    margin-bottom: 12px;
  }
  .coin-container {
    display: flex;
  }

  .btn-end-quiz {
    padding: 10px 120px;
    font-size: 130%;
    border-radius: 4px;
    border: 1px solid transparent;
    cursor: pointer;
    background: #7b57a9;
    color: white;
    text-align: center;
    font-weight: 400;
    transition: transform 0.2s;
    text-decoration: none;
    width: 350px;
    &--pet {
      padding: 5px 0;
      background: #ff8100;
      margin-top: 10px;
      font-size: 90%;
      text-transform: capitalize;
    }
  }

  .btn-end-quiz:hover {
    transform: scale(1.03);
    background: #8f69bf;
  }

  @media only screen and (max-width: 1023px) {
    #completed-menu {
      height: calc(100vh - 114px);
    }
  }
  @media only screen and (max-width: 680px) {
    .completed-container {
      flex-direction: column;
      padding: 0;
      justify-content: center;
      width: 100vw;
      height: 90vh;
      border-radius: 0;
      margin: 0;
    }
    .completed-menu-content {
      margin: 10px 0 0 0;
    }
  }

  @media only screen and (max-width: 400px) {
    .completed-menu-content {
      font-size: 90%;
    }
    .coin-circle {
      height: 40px;
      width: 40px;
    }
    .btn-end-quiz {
      width: 90vw;
      padding: 10px 0;
    }
  }

  @media only screen and (max-width: 812px) and (max-height: 550px) {
    .completed-container {
      flex-direction: row;
      padding: 10px;
      border-radius: 10px;
      height: auto;
      width: auto;
    }
    .completed-menu-content {
      margin-left: 20px;
      margin-top: 0;
    }
  }
  @media only screen and (max-width: 400px) {
    .btn-end-quiz {
      font-size: 100%;
      padding: 5px 0;
      &--pet {
        font-size: 80%;
        margin-top: 5px;
      }
    }
  }
  */
</style>
