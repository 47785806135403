<template>
  <div id="start-menu">
    <div class="start-menu-container">
      <div class="avatar-container">
        <div class="avatar-img">
          <img v-if="avatar !== ''"
            :src='"../../assets/avatars/" + avatar + ".png"' />
        </div>
        <h4>{{ studentName }}</h4>
      </div>
      <div class="start-menu-content">
        <h2>Are you ready to revise?</h2>
        <a href="#" class="btn-start"
          @click="startGame">Start
        </a>
      </div>
    </div>
  </div>
</template>

<script>

import {mapState} from 'vuex';

export default {
  name: 'LearningTrackRevisionStart',
  components: {
  },
  props: {
    startGame: Function,
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapState(['studentName', 'avatar']),
    ...mapState('quiz', ['gameRunning']),
  },
  mounted() {
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
  #start-menu {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    background: #e7e7e7;
    width: 100vw;
    position: fixed;
    top: 0;
    left: 0;
  }

  .start-menu-container {
    padding: 20px;
    background: #fff;
    border-radius: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 10px;
    text-align: center;
  }

  .avatar-container {
    border: 3px solid #000;
    text-align: center;
    border-radius: 10px;
  }

  .avatar-img {
    padding: 10px 10px 5px 10px;
  }

  .avatar-img + h4 {
    margin-bottom: 10px;
  }

  .start-menu-content {
    margin-left: 40px;
    margin-right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .start-menu-content > h2 {
    padding: 20px 0;
    border-bottom: 2px solid #eee;
  }

  .btn-start:link,
  .btn-start:visited {
    margin: 20px auto;
    padding: 10px 120px;
    font-size: 150%;
    border-radius: 4px;
    border: 1px solid transparent;
    cursor: pointer;
    background: #7b57a9;
    color: white;
    text-align: center;
    font-weight: 400;
    transition: transform 0.2s;
    text-decoration: none;
  }

  .btn-start:hover,
  .btn-start:active {
    transform: scale(1.03);
    background: #8f69bf;
  }

  .btn-start + a {
    text-decoration: none;
    font-size: 80%;
    color: #4776c1;
    margin-top: 10px;
  }

  @media only screen and (max-width: 1023px) {
  }
  @media only screen and (max-width: 820px) {
    .start-menu-content h2 span {
      display: block;
    }
  }

  @media only screen and (max-width: 600px) {
    .avatar-img {
      img {
        width: 120px;
      }
    }
    .start-menu-container {
      flex-direction: column;
      border-radius: 0;
      margin: 0;
      height: 100%;
      width: 100vw;
      justify-content: center;
      padding: 0;
    }
    .start-menu-content {
      margin: 0;
      font-size: 80%;
    }
    .btn-start:link,
    .btn-start:visited {
      margin-bottom: 0;
    }
  }
  @media only screen and (max-width: 350px) {
    .start-menu-content {
      font-size: 90%;
    }
    .btn-start:link,
    .btn-start:visited {
      padding: 10px 100px;
    }
  }
  @media only screen and (max-height: 520px) {
    .start-menu-container {
      flex-direction: row;
      height: auto;
      width: auto;
      padding: 10px;
      border-radius: 10px;
    }
    .start-menu-content {
      margin-left: 40px;
      margin-right: 20px;
    }
    .btn-start:link,
    .btn-start:visited {
      margin-bottom: 20px;
    }
  }
  @media only screen and (max-height: 365px) {
    .avatar-img {
      padding: 5px 0;
    }
    .avatar-img + h4 {
      margin-bottom: 5px;
      font-size: 90%;
    }
    .avatar-img img {
      width: 75%;
    }
    .start-menu-content {
      font-size: 85%;
    }
    .start-menu-content h2 {
      padding: 10px 0;
    }
    .btn-start:link,
    .btn-start:visited {
      margin: 10px auto;
    }
  }

</style>
