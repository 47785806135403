import {Howl, Howler} from 'howler';

export const soundMixin = {
  methods: {
    isAudioPlaying() {
      const sounds = Howler._howls;
      for (let i = 0; i < sounds.length; i++) {
        if (sounds[i].playing()) {
          return true;
        }
      }
      return false;
    },
    preload(manifest, successCallback, errorCallback) {
      console.log('preloading sounds abc');
      const results = {};
      const promiseArray = [];
      for (let i = 0; i < manifest.length; i++) {
        const item = manifest[i];
        promiseArray[i] = new Promise((resolve, reject) => {
          results[item.id] = new Howl({
            src: [item.src],
            onload: () => {
              resolve('ok');
            },
            onloaderror: () => {
              const errorMessage = 'error preloading ' +
                item.id + '; ' + item.src;

              const error = new Error(errorMessage);
              console.log(errorMessage);
              reject(error);
            },
          });
        });
      };

      // after we have finished loading all the sounds
      // call callback function
      Promise.all(promiseArray).then(() => {
        console.log('finished loading all sounds');
        successCallback(results);
      })
          .catch((error) => {
            throw error;
          });
    },
  },
};
