<template>
  <div class="level-library" v-if="isShow">
    <h2 class="term-header">
      <i class="fas fa-book-open"></i>{{ levelText }}
    </h2>
    <div class="term-arcs">
      <figure
        v-for="storyarc in storyarcsInLevel"
        :key="storyarc.level + '_' + storyarc.index"
        class="story-arc-container">
        <router-link
          :to="{name: 'library-storyarc-main',
          params: {storyarcId: storyarc.pk}}">

          <div class="story-arc-thumbnail-img">
            <div class="mobile-vignette"></div>
            <img
              :src="storyarc.thumbnail"
            />
            <div class="read-icon">
              <i class="fas fa-book"></i>
            </div>
            <h4 class="story-arc-title">
              {{ storyarc.title }}
            </h4>
          </div>
        </router-link>
      </figure>
    </div>
  </div>
</template>

<script>

import {mapState} from 'vuex';
import _filter from 'lodash.filter';

export default {
  name: 'LibraryOverviewClipIndex',
  components: {
  },
  props: {
    level: String,
    overrideTitle: String,
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapState('library', ['storyarcs']),
    storyarcsInLevel() {
      return _filter(this.storyarcs, (o) => {
        return o.level === this.level;
      });
    },
    // only shows component if there is at least one storyarc for the level
    isShow() {
      return this.storyarcsInLevel.length > 0;
    },
    levelText() {
      if (typeof this.overrideTitle !== 'undefined' && this.overrideTitle.length > 0) {
        return this.overrideTitle;
      }
      const map = {
        nursery_two: 'Nursery Two',
        kindergarten_one: 'Kindergarten One',
        kindergarten_two: 'Kindergarten Two',
        holiday_camp: 'Holiday Camp',
      };

      return map[this.level];
    },
  },
  mounted() {
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
  .level-library {
    font-size: 140%;
    margin: 2px;
  }

  .term-header {
    font-size: 110%;
    text-transform: uppercase;
  }
  .term-header i {
    margin-right: 5px;
  }
  .term-arcs {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
  .story-arc-container {
    position: relative;
    padding: 2px 2px;
  }

  .story-arc-container > a {
    text-decoration: none;
  }

  .story-arc-thumbnail-img {
    overflow: hidden;
    background: #000;
    position: relative;
    // display: flex;
    // justify-content: center;
    // align-items: center;
  }

  .read-icon {
    display: flex;
    justify-content: flex-end;
    align-items:flex-end;
    height: 100%;
    width: 100%;
    position:absolute;
    top: 0;
    left: 0;
    opacity: 0;
    transition: opacity 0.2s;
    z-index: 3;
    color: #fff;
    pointer-events:none;
    font-size: 150%;
  }

  .read-icon > i {
    padding-right: 10px;
    padding-bottom: 10px;
  }

  .story-arc-thumbnail-img > img {
    width: 100%;
    transform: scale(1);
    // opacity: 0;
    transition: transform 0.2s, opacity 0.2s;
    opacity: 0.4;
  }

  .story-arc-thumbnail-img > img:hover {
    transform: scale(1.1);
    opacity: 1;
  }

  .story-arc-thumbnail-img > img:hover ~ .read-icon {
    opacity: 1;
  }

  .story-arc-thumbnail-img > img:hover ~ .story-arc-title {
    color: #fff;
  }

  .story-arc-thumbnail-img::after {
    background: linear-gradient(to top, rgba(0,0,0,0.8), rgba(0,0,0,0));
    width: 100%;
    height: 80px;
    position: relative;
    display: block;
    margin-top: -80px;
    content: '';
    pointer-events:none;
  }

  .story-arc-container:first-child {
    padding-left: 0;
  }
  .story-arc-container:last-child {
    padding-right: 0;
  }

  .story-arc-title {
    text-transform: uppercase;
    color: #aaa;
    margin-top: -30px;
    margin-left: 10px;
    margin-bottom: 0;
    padding-bottom: 5px;
    position: relative;
    z-index: 3;
    font-size: 90%;
    pointer-events: none;
  }

  @media only screen and (max-width: 1140px) {
    .story-arc-title {
      font-size: 80%;
    }
    .read-icon {
      font-size: 130%;
    }
  }

  @media only screen and (max-width: 1024px) {
    .story-arc-thumbnail-img > img {
      opacity: 1;
    }
    .mobile-vignette {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      box-shadow: 0 0 80px rgba(50,50,50,0.4) inset;
      z-index: 2;
    }
    .story-arc-title {
      color: #fff;
    }
  }
  @media only screen and (max-width: 800px) {
    .story-arc-title {
      font-size: 75%;
    }
    .read-icon {
      font-size: 120%;
    }
  }

  @media only screen and (max-width: 700px) {
    .story-arc-title {
      font-size: 70%;
    }
    .read-icon {
      font-size: 110%;
    }
  }
  @media only screen and (max-width: 680px) {
    .term-arcs {
      grid-template-columns: 1fr 1fr;
    }
    .story-arc-title {
      font-size: 90%;
    }
    .read-icon {
      font-size: 140%;
    }
    .term-header {
      font-size: 120%;
    }
    .story-arc-container:nth-child(odd) {
      padding-left:0;
    }
  }

  @media only screen and (max-width: 535px) {
    .story-arc-title {
      font-size: 80%;
    }
    .read-icon {
      font-size: 130%;
    }
  }

</style>
