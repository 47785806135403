<template>
  <li class="mission">
    <div class="mission-row-1">
      <div class="mission-row-1--text">
        <h3>{{ title }}</h3>
        <!-- <p>{{ info.current_times_done }} / {{ info.target_times_done }}</p> -->
      </div>
      <div @click="onClickNext" class="mission-row-1--btn">
        <i v-if="info.status <= 1" class="go-icon">Go</i>
        <!-- completed but not yet collected gift -->
        <i v-if="info.status === 2" class="fa-solid fa-gift"></i>
        <!-- collected already -->
        <i v-if="info.status === 3" class="fa fa-check"></i>
      </div>
    </div>
    <div class="mission-row-2--counter">
      <div
        :style="{width: progressBarWidth}"
        class="mission-row-2--counter-fill">
      </div>
    </div>
  </li>
</template>

<script>
// NOT_STARTED: int = 0
// ONGOING: int = 1
// COMPLETED_UNCLAIMED: int = 2
// COMPLETED_CLAIMED: int = 3

import {mapState} from 'vuex';

export default {
  name: 'MissionRow',
  components: {
  },
  props: {
    info: Object,
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapState(['studentId', 'cocos']),
    title() {
      return this.$options.filters.splitCapitalise(this.info.name);
    },
    progressBarWidth() {
      if (!this.info) return 0;
      const percentage = Math.ceil(this.info.current_times_done / this.info.target_times_done * 100);
      return `${percentage}%`;
    },
    cocosUrl() {
      if (!this.cocos.quick_token || this.cocos.quick_token === '') {
        return 'https://petbattle.s3-ap-southeast-1.amazonaws.com/index.html';
      } else {
        return 'https://petbattle.s3-ap-southeast-1.amazonaws.com/index.html?quick=' + this.cocos.quick_token;
      }
    },
    missionTypeFontAwesomeClass() {
      if (this.info.name === 'pet_battle') {
        return 'fa-paw';
      } else if (this.info.name === 'fishing_game') {
        return 'fa-comments';
      } else {
        return 'fa-graduation-cap';
      }
    },
  },
  mounted() {
  },
  methods: {
    onClickNext() {
      console.log('on click next');
      console.log(this.info.status);
      // claimed already, do nothing
      if (this.info.status === 3) return;
      if (this.info.status === 2) {
        this.$router.push({name: 'new-missions-overview'});
        return;
      }
      // if haven't completed, navigate
      if (this.info.name === 'daily_challenge') {
        this.$router.push({name: 'daily-challenge-overview'});
      } else if (this.info.name === 'pet_battle') {
        window.open(this.cocosUrl);
      } else if (this.info.name === 'fishing_game') {
        this.$router.push({name: 'new-play-fishing-game'});
      }
      return;
    },
  },
};
</script>

<style lang="scss" scoped>
  .mission {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
    flex-direction: column;
    &-row-1 {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 0.25rem;
      margin-bottom: .5rem;
      &--text {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex: 1;
        h3 {
          font-size: .8rem;
          line-height: 1.2rem;
        }
        p {
          font-size: 0.7rem;
        }
      }
      &--btn {
        margin: 0 0.25rem;
        i {
          height: 2rem;
          width: 2rem;
          text-align: center;
          font-size: 1.5rem;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .fa-gift {
          border-radius: .5rem;
          color: #f89f2b;
          background: #505bc08c;
        }
        .go-icon {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 2rem;
          height: 2rem;
          border-radius: .5rem;
          color: #fff;
          background: #50c09f;
          cursor: pointer;
          font-size: 1.1rem;
        }
      }
    }
    &-row-2 {
      &--counter {
        height: .75rem;
        width: 100%;
        background: #4b517a52;
        border-radius: 2rem;
        &-fill {
          background: #f89f2b;
          height: 100%;
          border-radius: 2rem;
        }
      }
    }
  }
  @media only screen and (max-width: 1366px),
              screen and (max-height: 800px)  {
    .mission {
      margin-bottom: 0.75rem;
      &-row-1 {
        &--text {
          h3 {
            font-size: 0.7rem;
          }
          p {
            font-size: 0.6rem;
          }
        }
        &--btn {
          margin: 0 0 0 .25rem;
          i {
            height: 1.5rem;
            width: 1.5rem;
            font-size: 1rem;
          }
          .go-icon {
            width: 1.5rem;
            height: 1.5rem;
            font-size: 0.8rem;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 1150px),
            screen and (max-height: 720px) {
    .mission {
      &-row-1 {
        &--text {
          h3 {
            font-size: 0.6rem;
          }
          p {
            font-size: 0.5rem;
          }
        }
        &--btn {
          i {
            height: 1.25rem;
            width: 1.25rem;
            font-size: .8rem;
          }
          .go-icon {
            width: 1.25rem;
            height: 1.25rem;
            font-size: 0.7rem;
          }
        }
      }
    }
  }

</style>
