<template>
  <div class="teaching-screen-container">
    <div v-if="showToggleOverlay"
      class="wordlist-toggle-overlay"
      :class="{'border-green': inWordlist, 'border-red': !inWordlist}">
      <h2 class="animated fadeInDown">
        <span v-if="inWordlist">Added to Wordlist!</span>
        <span v-if="!inWordlist">Removed from Wordlist!</span>
      </h2>
      <p class="flipInY animated
        wordlist-toggle-overlay--word chinese-character" :class="wordClass">{{ word }}</p>
      <div class="wordlist-toggle-overlay--text fadeInUp animated">
        <p><b>Definition: </b>{{ definition }} </p>
      </div>
    </div>
    <div id="revise-word-container">
      <div v-if="isTrackable"
        @click="toggleWordList"
        class="wordlist-bookmark--container"
        :class="{'wordlist-bookmark-filled': inWordlist}">
        <div class="wordlist-bookmark--toggle">
          <i class="fas" :class="{'fa-minus': inWordlist, 'fa-plus': !inWordlist}"></i>
        </div>
        <i class="wordlist-bookmark fas fa-bookmark">
          <span class="wordlist-bookmark--text"></span>
        </i>
      </div>
      <div class="revise-word--header">
        <div class="revise-word--header-main">
          <p v-if="showChapter">
            <b>{{ chapter.level }}</b>
          </p>
          <p v-if="showStory">
            <b>故事</b>
          </p>
          <p v-if="showHighFrequency">
            <b>{{ subtopic.level }}</b>
          </p>
          <p v-if="!isTrackable || (!showChapter && !showStory && !showHighFrequency)">
            <b>扩展词语</b>
          </p>
        </div>
        <div class="revise-word--header-sub">
          <p v-if="showStory">
           《{{ story.title }}》
          </p>
          <p v-if="showHighFrequency">
            《{{ subtopic.title }}》
          </p>
          <p v-if="showChapter">
            {{ chapter.number }}《{{ chapter.title }}》
          </p>
        </div>
      </div>
      <!-- words taken from cedict -->
      <div class="revise-word--main"
        @click.prevent="playSound"
        :class="{'cursor-pointer': src !== '' }">
        <p class="chinese-character revise-word">{{ word }}</p>
        <p class="hypy">
          [{{ pinyin }}]
          <i v-if="src !== ''" class="fas fa-volume-up revise-icon"></i>
        </p>
        <!-- sentence -->
        <p v-if="modifiedExamplesentence.length > 0"
          class="chinese-character example-sentence">
          <span v-html="modifiedExamplesentence"></span>
        </p>
      </div>
      <div class="revise-word--footer">
        <p><b>Definition: </b>{{ definition }} </p>
      </div>
    </div>
  </div>
</template>

<script>

import axios from 'axios';
import {mapState, mapMutations} from 'vuex';

export default {
  name: 'GuidedReaderTeachingScreen',
  components: {
  },
  props: {
    word: String,
    pk: Number,
    exampleSentence: String,
    definition: String,
    pinyin: String,
    src: String,
    chapter: Object,
    story: Object,
    subtopic: Object,
    isTrackable: Boolean,
  },
  data() {
    return {
      showToggleOverlay: false,
    };
  },
  computed: {
    ...mapState(['studentId']),
    ...mapState('guidedReading', ['comprehensionId']),
    ...mapState('analytics', ['wordlistIds']),
    inWordlist() {
      if (!this.isTrackable) return false;
      console.log(this.wordlistIds, this.pk, this.pk in this.wordlistIds);
      return this.pk in this.wordlistIds;
    },
    wordClass() {
      if (this.word === '') return '';
      return this.word.length < 4 ? 'word-short' : 'word-medium';
    },
    modifiedExamplesentence() {
      const entries = this.exampleSentence.split(this.word);
      const separator = `<span class="red">${this.word}</span>`;
      const text = entries.join(separator);
      return text;
    },
    // very poor code: should refactor
    showChapter() {
      return this.chapter && Object.keys(this.chapter).length > 0;
    },
    showStory() {
      if (this.showChapter || !this.story || Object.keys(this.story).length === 0) return false;
      return true;
    },
    showHighFrequency() {
      if (this.showChapter || this.showStory || !this.subtopic || Object.keys(this.subtopic).length === 0) return false;
      return true;
    },
  },
  mounted() {
  },
  methods: {
    ...mapMutations('guidedReading', ['toggleInWordlist']),
    ...mapMutations('analytics', ['addToWordlistIds', 'removeFromWordlistIds']),
    playSound() {
      const p = new Promise((resolve, reject) => {
        const sound = new Howl({
          src: [this.src],
          onload: () => {
            resolve(sound);
          },
        });
      });

      p.then((sound) => {
        sound.play();
      });
    },
    toggleWordList() {
      if (!this.isTrackable) return;
      // we show the frontend effect and assume it will be completed on the backend
      if (this.inWordlist) {
        this.removeFromWordlistIds(this.pk);
      } else {
        this.addToWordlistIds(this.pk);
      }
      this.showToggleOverlay = !this.showToggleOverlay;
      setTimeout(() => {
        this.showToggleOverlay = !this.showToggleOverlay;
      }, 2000);

      // actual backend call
      axios
          .post(`/api/v1/core/student/${this.studentId}/wordlist/`, {
            wordId: this.pk,
            inWordlist: this.inWordlist,
            comprehensionId: this.comprehensionId,
          });
    },
  },
};
</script>

<style lang="scss" scoped>
  .wordlist-toggle-overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: #fff;
    border-radius: 1rem;
    z-index: 99;
    font-family: 'Baloo Da 2', Avenir, Helvetica, Arial, sans-serif;
    font-weight: 600;
    transition: 0.2s all linear;
    h2 {
      border-bottom: 3px solid #f7f4f4;
      padding-bottom: 1rem;
      text-align: center;
      width: 450px;

    }
    &--word {
      font-size: 7.5rem;
      padding: 1.5rem;
      margin-top: 0.5rem;
      margin-bottom: 1rem;
    }
    &--text {
      text-align: center;
      width: 450px;
      border-top: 3px solid #f7f4f4;
      padding-top: 1rem;
      font-size: 1.2rem;
      margin-bottom: 2rem;
    }
  }
  .cursor-pointer {
    cursor: pointer;
  }
  .example-sentence >>> .red {
    color: red !important;
  }
  .teaching-screen-container {
    height: 100%;
    width: 100%;
    position: relative;
    border: 2px solid #ccc;
    border-radius: 1rem;
  }

  #revise-word-container {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
  }

  .wordlist-bookmark {
    display: flex;
    justify-content: center;
    align-items: center;
    &--toggle {
      font-size: 1rem;
      margin-right: 0.5rem;
      margin-bottom: 0.25rem;
      opacity: 0;
      transition: 0.2s linear opacity;
      transition: 0.2s linear all;
      transform: translateX(2rem);
    }
    &--container {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      right: 2rem;
      top: 2rem;
      font-size: 1.5rem;
      color: #e5e5e5;
      transition: 0.2s linear all;
      cursor: pointer;
      &:hover {
        color: #ff9a00;
        .wordlist-bookmark--toggle {
          opacity: 1;
          transform: translateX(0);
        }
      }
    }
    &--text {
      font-family: 'Baloo Da 2', Avenir, Helvetica, Arial, sans-serif;
      font-weight: 600;
      font-size: 1.1rem;
      margin-left: 0.25rem;
    }
  }
  .wordlist-bookmark-filled {
    color: #ff9a00;
    &:hover {
      color: #de0101;
    }
  }

  .revise-word {
    font-size: 3rem;
    margin-bottom: 1.5rem;
    margin-top: 1.5rem;
    &--header {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      height: 15%;
      &-main {
        font-size: 1.2rem;
        margin-bottom: 0.1rem;
      }
      &-sub {
      }
    }
    &--main {
      border-bottom: 2px solid #e9e9e9;
      border-top: 2px solid #e9e9e9;
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 90%;
      &:hover {
        .revise-icon {
          color: #c200e7;
          transition: 0.1s all linear;
          transform: scale(1.2);
        }
      }
    }
    &--footer {
      height: 15%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      p {
        margin-bottom: 0.5rem;
      }
    }
  }

  #revise-word-translate {
    font-size: 80%;
    text-align: center;
    margin: 1rem 30px;
  }
  .example-sentence {
    margin-top: 2rem;
    text-align: center;
    font-size: 1.2rem;
    border-radius: 20px;
    background: #f0f0f0;
    padding: 0.25rem 1rem;
    margin-bottom: 1rem;
  }
  .border-red {
    border: 8px solid #de0101;
  }
  .border-green {
    border: 8px solid #1dffca;
  }
</style>
