<template>
  <div class="daily-challenge-btn__component"
    :class="{'daily-challenge-btn__disabled': !unlocked}"
    @click.prevent='startQuiz'
  >
    <div
      class="daily-challenge-btn__icon"
      :style="buttonStyle"
    >
      <p v-if='unlocked'>
        {{ index + 1}}
      </p>
      <i v-if='!unlocked' class="fas fa-lock"></i>
    </div>
    <div class="daily-challenge-btn__content">
      <div class="daily-challenge-btn__content--counter">
        <!-- Countdown Timer Template -->
        <countdown
          v-if='!unlocked && timeToUnlocking > 0'
          :time="timeToUnlocking">
          <template slot-scope="props">
            <!-- Always show day and hours if > 0 -->
            <!-- only show mins if day === 0 -->
            <!-- only show seconds if everything else === 0 -->
            <span>new event in:</span>
            <!-- show days timer only if day.timer !== 0 -->
            <span v-if="props.days !== 0">{{ props.days }}d</span>
            <!-- show hours timer only if hours.timer !== 0 -->
            <span v-if="props.hours !== 0">{{ props.hours }}h</span>
            <!-- show minutes timer only if day.timer === 0 -->
            <span v-if="
              props.days === 0 &&
              props.minutes !== 0">
              {{ props.minutes }}m</span>
            <!-- show seconds timer only if all other timers === 0 -->
            <span v-if="
              props.days === 0 &&
              props.hours === 0 &&
              props.minutes === 0 &&
              props.seconds !== 0">
              {{ props.seconds }}s
            </span>
           </template>
        </countdown>
      </div>
      <div class="daily-challenge-btn__content--body">
        <div class="daily-challenge-btn__content--title">
          <p v-if='unlocked'>{{ unlocked_date |  convertToDay}}</p>
          <p class="daily-challenge-btn__content--title-locked"
            v-if='!unlocked'>locked!</p>
        </div>
          <daily-challenge-key
            v-if='unlocked'
            :color='color'
            :completed='completed'
          >
          </daily-challenge-key>
        <rating
          v-if='unlocked'
          class='rating-star-container'
          image='star'
          :current-score='stars'
          :max-score=3
          :color='color'>
        </rating>
      </div>
    </div>
  </div>
</template>

<script>
import {mapMutations} from 'vuex';

import DailyChallengeKeyComponent
  from './DailyChallengeKeyComponent.vue';
import RatingComponent from '../common/RatingComponent.vue';

export default {
  name: 'DailyChallengeDayButton',
  components: {
    rating: RatingComponent,
    DailyChallengeKey: DailyChallengeKeyComponent,
  },
  mixins: [],
  props: {
    unlocked_date: String,
    stars: Number,
    completed: Boolean,
    colors: Array,
  },
  data() {
    return {
      currentTime: new Date(),
    };
  },
  computed: {
    // note that day entry for monday (first entry) is 1
    // so we subtract by 1
    index() {
      const day = new Date(this.unlocked_date).getDay();
      return day !== 0 ? day - 1 : 6;
    },
    color() {
      return this.colors[this.index];
    },
    buttonStyle() {
      return {background: this.color};
    },
    unlocked() {
      return this.stars > -1;
    },
    timeToUnlocking() {
      if (this.unlocked) return 0;

      // need to set the hours to get the timer to work properly
      const targetDate = new Date(this.unlocked_date);
      targetDate.setHours(0);

      return Math.max(targetDate - this.currentTime, 0);
    },
  },
  mounted() {
  },
  methods: {
    ...mapMutations('dailyChallenge',
        ['setSelectedDailyChallengeUnlockedDate']),
    startQuiz() {
      if (this.timeToUnlocking > 0) {
        this.setSelectedDailyChallengeUnlockedDate(null);
        return;
      }
      this.setSelectedDailyChallengeUnlockedDate(this.unlocked_date);
      this.$router.push({name: 'daily-challenge-quiz'});
    },
  },
};
</script>

<style lang="scss" scoped>
  .daily-challenge-btn {
    &__component{
      cursor: pointer;
      display: flex;
      background: #fff;
      // transform: skewX(-12deg);
    }
    &__icon {
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: bold;
      padding: 10px;
      width: 70px;
      height: 100px;
      font-size: 50px;
      background:  #a48aff;
      color: #fff;
    }
    &__content {
      flex: 1;
      // transform: skewX(12deg);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
      &--counter {
        position: absolute;
        top: 4px;
        right: 4px;
        font-size: 16px;
        font-weight: bold;
        text-transform: capitalize;
      }
      &--body{
        width: 200px;
        i {
          position: absolute;
          bottom: 0px;
          right: 0px;
          color: #eee;
        }
      }
      &--title {
        font-size: 24px;
        font-weight: bold;
        text-transform: uppercase;
        &-locked {
          font-size: 30px;
          color: #989898;
        }
      }
    }
    &__disabled {
      cursor: disabled;
      pointer-events: none;
      .daily-challenge-btn__icon {
        background: #dadada !important;
      }
    }
  }
  .rating-star-container {
    width: 80%;
    padding: 3px;
    margin: 5px auto 0 auto;
    border-top: 1px solid #eee;
  }

  @media only screen and (max-width: 380px) {
    .daily-challenge-btn {
      &__icon {
        width: 60px;
        height: 90px;
      }
      &__content {
        &--title {
          font-size: 22px;
        }
      }
    }
  }
</style>
