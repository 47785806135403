<template>
  <div>
    <div class='rating-container'>
      <!-- note n in 3 is 1, 2, 3 for vue not 0, 1, 2 (in python)-->
      <span v-for='n in maxScore'
      :key='n'
      :style='colorStyle'
      :class='{translucent: n > currentScore}'
      >★
      </span>
    </div>
  </div>
</template>

<script>

export default {
  name: 'Rating',
  props: {
    image: String,
    // the max number of items to display
    maxScore: Number,
    currentScore: Number,
    color: {
      type: String,
      default: '#ffbe43',
    },
  },
  data() {
    return {
    };
  },
  computed: {
    colorStyle() {
      return {color: this.color};
    },
  },
  mounted() {
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
  .rating-container {
    text-align: center;
  }

  .translucent {
    opacity: 0.3;
    color: #aaa !important;
  }
</style>
