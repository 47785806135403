<template>
    <section class="leaderboard__component">
      <div class="leaderboard__content">
        <!-- header start-->
        <div class="leaderboard__content--header">
            <img class="leaderboard__content--header-img--desktop"
              src="../../assets/leaderboards/header.png" alt="">
            <img class="leaderboard__content--header-img--mobile"
              src="../../assets/leaderboards/header-mobile.png" alt="">
            <router-link
              class="btn-back"
              :to="{name:'profile-main'}">
              <img src="../../assets/leaderboards/back.png" alt="" class="img-fluid">
            </router-link>
        </div>
        <!-- header end -->
        <div class="leaderboard__content--body">
          <div class="leaderboard__content--body-left">
            <leaderboard-row v-for="(row, index) in leftStudentRows"
              :key="index" :student-info="row">
            </leaderboard-row>
          </div>
          <div class="leaderboard__content--body-right">
            <leaderboard-row v-for="(row, index) in rightStudentRows"
              :key="index" :student-info="row">
            </leaderboard-row>
          </div>
          <div class="leaderboard__content--body-panda">
            <img src="../../assets/leaderboards/panda.png" alt="" class="img-fluid">
          </div>
        </div>
      </div>
      <!-- footer start-->
      <div class="leaderboard__content--footer">
        <div
          @click.prevent="previousPageIndex"
          class="leaderboard__content--footer-btn-prev"
          :class="{'zero-opacity': currentPageIndex === 0}">
          <a href="#">
            <img src="../../assets/leaderboards/previously.png" alt="">
          </a>
        </div>
        <div class="leaderboard__content--footer-text">
          <h2>The Leaderboard reset monthly</h2>
        </div>
        <div
          class="leaderboard__content--footer-btn-next"
          @click.prevent="nextPageIndex"
          :class="{'zero-opacity': currentPageIndex >= maxPageIndex}">
          <a href="#">
            <img src="../../assets/leaderboards/next.png" alt="">
          </a>
        </div>
      </div>
      <!-- footer end-->
    </section>
</template>

<script>
import axios from 'axios';
import {mapState, mapMutations} from 'vuex';

import LeaderboardRowComponent
  from './LeaderboardRowComponent.vue';

export default {
  name: 'MainLeaderboardCoin',
  components: {
    LeaderboardRow: LeaderboardRowComponent,
  },
  props: {
  },
  data() {
    return {
      studentData: [],
      currentPageIndex: 0,
      maxPageIndex: 0,
      currentRows: [],
      // leaderboardActive: 'level',
      // leaderboardClass: [],
    };
  },
  computed: {
    ...mapState(['studentId']),
    leftStudentRows() {
      if (this.studentData.length === 0) return;
      // ten rows per page - 5 on left, 5 on right
      const startRowIndex = this.currentPageIndex * 10;
      return this.studentData['rows'].slice(startRowIndex, startRowIndex + 5);
    },
    rightStudentRows() {
      if (this.studentData.length === 0) return;
      // ten rows per page - 5 on left, 5 on right
      const startRowIndex = this.currentPageIndex * 10;
      return this.studentData['rows'].slice(startRowIndex + 5, startRowIndex + 10);
    },
  },
  mounted: function() {
    this.setShowGlobalLoadingScreen(true);
    console.log('im here');
    const url = '/api/v1/core/student/' + this.studentId +
      '/coin_leaderboards/';
    return axios
        .get(url)
        .then((response) => {
          console.log(response.data);
          // this.studentMap = response.data['student_map'];
          this.studentData = response.data;
          this.currentPageIndex = Math.floor(this.studentData['student_index'] / 10);
          this.maxPageIndex = Math.floor(this.studentData['rows'].length / 10);
          if (this.studentData['rows'].length % 10 === 0) {
            this.maxPageIndex = this.maxPageIndex > 0 ? this.maxPageIndex - 1 : 0;
          }
          this.setShowGlobalLoadingScreen(false);
        });
  },
  methods: {
    ...mapMutations(['setShowGlobalLoadingScreen']),
    nextPageIndex() {
      if (this.currentPageIndex >= this.maxPageIndex) return;
      this.currentPageIndex++;
    },
    previousPageIndex() {
      if (this.currentPageIndex === 0) return;
      this.currentPageIndex--;
    },
  },
};
</script>

<style lang="scss" scoped>
* {
  font-family: 'Baloo Da 2', Avenir, Helvetica, Arial, sans-serif;
}
.leaderboard__component {
  margin: 0;
  background-image: url(../../assets/leaderboards/bg.png);
  height: 100vh;
  background-size: cover;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  z-index: 100;
}
.leaderboard__content {
  height: 90%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  &--header {
    position: relative;
    z-index: 10;
    width: 850px;
    &-img {
      &--desktop {}
      &--mobile {
        display: none;
      }
    }
    img {
      width: 100%;
    }
    .btn-back {
      position: fixed;
      left: 0;
      top: 2%;
      width: 180px;
    }
  }
  &--body {
    margin-top: -2rem;
    width: 67.5%;
    height: 75%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    background-color: rgba(255,255,255,0.3);
    border-radius: 50px;
    padding: 2.5rem 1.5rem;
    &-left,
    &-right {
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      height: 100%;
      margin: 0 0.25rem;
      position: relative;
    }
    &-panda {
      position: absolute;
      bottom: 0;
      left: -130px;
      img {
        width: 80%;
      }
    }
  }
  &--footer {
    height: 10%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    &-btn-prev, &-btn-next {
      width: 160px;
      transition: all 0.2s linear;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      a {
        width: 100%;
        img {
          width: 100%;
        }
      }
    }
    &-text {
      color: #503327;
      font-weight: 800;
      font-size: 0.9rem;
      background-size: cover;
      background-image: url(../../assets/leaderboards/cloud-text.png);
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

@import url('https://fonts.googleapis.com/css2?family=Baloo+Da+2:wght@400;500;600;700;800&display=swap');

.zero-opacity {
  opacity: 0;
}

@media screen and (max-width:1850px) {
  .leaderboard__content--body {
    width: 85%;
  }
}
@media screen and (max-width:1500px) {
  .leaderboard__content--body {
    width: 90%;
  }
  .leaderboard__content--body-panda {
    display: none;
  }
}
@media screen and (max-width:1230px) {
  .leaderboard__content--header {
    width: 800px;
  }
  .leaderboard__content--body {
    margin-top: -2.5rem;
  }
  .leaderboard__content--header .btn-back {
    width: 160px;
  }
  .leaderboard__content--footer-btn-prev,
  .leaderboard__content--footer-btn-next {
    width: 140px;
  }
  .leaderboard__content--footer-text {
    font-size: 0.85rem;
    padding: 0rem 2rem;
  }
}
@media screen and (max-width:1150px) {
  .leaderboard__content--header .btn-back {
    left: -2rem;
    width: 140px;
  }
  .leaderboard__content--body {
    height: 75%;
  }
  .leaderboard__content--header {
    width: 700px;
  }
  .leaderboard__content--footer-btn-prev,
  .leaderboard__content--footer-btn-next {
    width: 120px;
  }
  .leaderboard__content--footer-text {
    font-size: 0.8rem;
  }
}
@media screen and (max-width: 915px) and (min-height: 567px) {
  .leaderboard__content--body {
    flex-direction: column;
    margin-top: 0;
    margin-bottom: -2rem;
    &-left,
    &-right {
      width: 100%;
    }
  }
  .leaderboard__content--body {
    margin-bottom: -1rem;
    height: 87.5%;
    padding: 2rem 0.5rem 1rem 0.5rem;
    border-radius: 25px;
  }
  .leaderboard__content--header .btn-back {
    width: 120px;
  }
  .leaderboard__content--header {
    width: 600px;
    margin-bottom: -2rem;
  }
  .leaderboard__content--footer {
    flex-wrap: wrap;
    margin-top: 1rem;
    justify-content: space-between;
    &-btn-prev {
      margin-left: 2rem;
    }
    &-btn-next {
      margin-right: 2rem;
    }
  }
  .leaderboard__content--footer-text {
    order: 3;
    flex: 1 0 100%;
    height: 50%;
  }
  .leaderboard__content--footer-btn-prev,
  .leaderboard__content--footer-btn-next {
    width: 110px;
    height: 50%;
  }
}
@media screen and (max-width: 700px) and (min-height: 667px) {
  .leaderboard__content--footer {
    &-btn-prev {
      margin-left: 1.5rem;
      width: 100px;
    }
    &-btn-next {
      margin-right: 1.5rem;
    }
  }
}
@media screen and (max-width: 550px) and (min-height: 667px) {
  .leaderboard__content--footer {
    &-btn-prev {
      margin-left: 1rem;
      width: 100px;
    }
    &-btn-next {
      margin-right: 1rem;
      width: 100px;
    }
  }
}
@media screen and (max-width: 390px) and (min-height: 667px) {
  .leaderboard__content--footer {
    &-btn-prev {
      width: 90px;
    }
    &-btn-next {
      width: 90px;
    }
  }
}
@media screen and (max-width: 700px) {
  .leaderboard__content--header {
    width: 500px;
  }
  .leaderboard__content--header .btn-back {
    width: 110px;
  }
  .leaderboard__content--header {
    margin-bottom: -1.5rem;
  }
  .leaderboard__content--footer-text {
    font-size: 0.75rem;
  }
}
@media screen and (max-width: 600px) {
  .leaderboard__content--header {
    width: 400px;
  }
  .leaderboard__content--body {
    height: 90%;
    width: 90%;
  }
  .leaderboard__content--header-img--desktop {
    display: none;
  }
  .leaderboard__content--header-img--mobile {
    display: block;
  }
}
@media screen and (max-width: 480px) {
  .leaderboard__content--header {
    width: 340px;
    margin-top: 2rem;
  }
  .leaderboard__content--body {
    height: 85%;
  }
  .leaderboard__content--footer-text {
    padding: 0;
    font-size: 0.7rem;
  }
}
@media screen and (max-width: 440px) {
  .leaderboard__content--footer-text {
    font-size: 0.6rem;
  }
}
@media screen and (max-width: 380px) {
  .leaderboard__content--header .btn-back {
    width: 100px;
  }
  .leaderboard__content--header {
    width: 310px;
  }
  .leaderboard__content--footer-text {
    font-size: 0.55rem;
  }
}

@media screen and (max-width: 915px) and (min-height: 1000px) {
  .leaderboard__content--footer-btn {
    &-next,
    &-prev {
      width: 130px;
    }
  }
  .leaderboard__content--header .btn-back {
    width: 130px;
  }
}
@media screen and (max-width: 915px) and (min-height: 1150px) {
  .leaderboard__content--footer-btn {
    &-next,
    &-prev {
      width: 140px;
    }
  }
  .leaderboard__content--header .btn-back {
    width: 140px;
  }
}
@media screen and (max-width:915px) and (max-height: 615px) {
  .leaderboard__content--body {
    padding: 2rem 0.75rem 1.25rem;
    border-radius: 25px;
  }
}
@media screen and (max-width:915px) and (max-height: 470px) {
  .leaderboard__content--body {
    padding: 1.5rem 0.5rem 0.5rem;
  }
  .leaderboard__content--header {
    width: 550px;
  }
  .leaderboard__content--header .btn-back {
    width: 120px;
  }
  .leaderboard__content--footer-btn-next,
  .leaderboard__content--footer-btn-prev {
    width: 110px;
  }
  .leaderboard__content--footer-text {
    font-size: 0.65rem;
  }
}
@media screen and (max-width:915px) and (max-height: 400px) {
  .leaderboard__content--header {
    width: 500px;
    margin-bottom: 0.5rem;
  }
}
@media screen and (max-width:667px) and (max-height: 400px) {
  .leaderboard__content--body {
    padding: 1.5rem 0.25rem 0.5rem;
  }
  .leaderboard__content--header {
    width: 400px;
    margin-bottom: 0.5rem;
  }
  .leaderboard__content--header .btn-back {
    width: 100px;
  }
  .leaderboard__content--footer-btn-next,
  .leaderboard__content--footer-btn-prev {
    width: 90px;
  }
  .leaderboard__content--footer-text {
    font-size: 0.55rem;
  }
}
</style>
