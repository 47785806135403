<template>
<div>
  <header>
    <nav v-if="isOldNavShown">
      <!-- MAIN NAV -->
      <div class="main-nav">
        <!-- LOGO -->
        <router-link :to="{name: 'profile-main'}">
          <img class="logo" src="../../assets/logo-text-white.png">
        </router-link>
        <!-- MAIN NAV ROUTES -->
        <!-- MAIN NAV COINS INFO -->
        <div class="main-nav-coins">
          <div class="coin-circle">
            <i class="fas fa-star"></i>
          </div>
          <p>{{ coins }}</p>
        </div>
        <!-- DIAMOND INFO -->
        <div class="main-nav-diamonds">
          <div class="diamond-circle">
            <i class="fas fa-gem"></i>
          </div>
          <!-- Placeholder -->
          <!-- <p>{{ diamonds }}</p> -->
          <p>{{ diamonds }}</p>
        </div>
        <!-- SETTINGS -->
        <div class="main-nav--hamburger"
          @click="toggleSidebar">
          <div class="main-nav--hamburger-1">
          </div>
          <div class="main-nav--hamburger-2">
          </div>
          <div class="main-nav--hamburger-3">
          </div>
        </div>
        <!-- <i @click="toggleSidebar"
          class="fas fa-bars"></i> -->
        <nav-bottom v-if="showNavBottom"></nav-bottom>
      </div>
    </nav>
  </header>
</div>
</template>

<script>
import {mapState} from 'vuex';
// import TheHeaderDropdownComponent from './TheHeaderDropdownComponent.vue';
import NavBottom from './NavBottom.vue';

export default {
  name: 'TheHeader',
  components: {
    NavBottom: NavBottom,
    // HeaderDropdown: TheHeaderDropdownComponent,
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapState(['coins', 'diamonds', 'avatar', 'studentName',
      'level', 'studentId']),
    ...mapState(['isWebApp']),
    ...mapState(['isSidebarShown', 'isShowNavBottom', 'isOldNavShown']),
    // don't show nav for analytics
    // we also want to hide bottom for certain places like quizzes
    showNavBottom() {
      return !this.$route.name.startsWith('analytics') && this.isShowNavBottom;
    },
  },
  // kiv code -> can be moved to header if necessary
  showCompoKing() {
    const levels = ['primary_three', 'primary_three', 'primary_four',
      'primary_five', 'primary_six'];

    return levels.indexOf(this.level);
  },
  mounted() {
  },
  methods: {
    toggleSidebar() {
      this.$store.state.isSidebarShown = !this.$store.state.isSidebarShown;
    },
  },
};
</script>

<style lang="scss" scoped>
  /* #5a4179 */
.logo {
  padding: 8px 16px 8px 0;
  margin-left: 16px;
}

header {
  height: 64px;
}
/* NAVIGATION */
nav {
  height: 64px;
  background-color: #5a4179;
  position: fixed;
  width: 100%;
  font-size: 85%;

  /* added by dan to ensure nav doesnt get covered in analytics page; fix if not suitable */
  z-index: 2;
}

.main-nav {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  height: 100%;
}

.main-nav-routes {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100%;
}

.main-nav-routes > a {
  margin: 0 4px;
  font-weight: 400;
  border-radius: 24px;
}

.main-nav-routes > a:link,
.main-nav-routes > a:visited {
  padding: 8px 16px;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 90%;
  transition: background-color 0.1s, color 0.1s;
}

.main-nav-routes > a:hover,
.main-nav-routes > a.router-link-active {
  color: #fff;
  background-color: #b52491;
}

.main-nav i {
  font-size: 150%;
  color: #fff;
}

.main-nav-coins {
  margin-left: auto;
}

.main-nav-profile {
  position: relative;
}

.main-nav-diamonds__link {
  height: 100%;
  text-decoration: none;
}
.main-nav-profile,
.main-nav-coins,
.main-nav-diamonds,
.main-nav-notification {
  margin-right: 16px;
}

.main-nav-profile,
.main-nav-coins,
.main-nav-diamonds,
.main-nav-notification,
.main-nav-menu, {
  display: flex;
  height: 100%;
  align-items: center;
}

.main-nav-profile p,
.main-nav-coins p,
.main-nav-diamonds p {
  color: #fff;
  white-space: nowrap;
}

.main-nav-profile > *,
.main-nav-coins > *,
.main-nav-diamonds > *,
.main-nav-notifications > * {
  margin-right: 8px;
}

.main-nav-profile img {
  height: 40px;
  width: 40px;
  background-color: white;
  border-radius: 50%;
  padding: 2% 2%;
}

.coin-circle {
  background: #ffbe43;
  border-radius: 50%;
  height: 36px;
  width: 36px;
  text-align: center;
}

.coin-circle > i {
  margin-top: 3.5px;
}

.diamond-circle {
  background: #fff;
  border-radius: 50%;
  height: 36px;
  width: 36px;
  text-align: center;
  i {
    margin-top: 7px;
    color: #2390ff;
  }
}

.main-nav-menu {
  margin-right: 16px;
  margin-left: 8px;
}

// Profile drop down Menu
.profile-drop-down-menu {
  width: 240px;
  position: absolute;
  top: 64px;
  right: 0;
  background: #fff;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  display: none;
  box-shadow: 0 0 20px rgba(0,0,0,.25);
}

.main-nav-profile:hover .profile-drop-down-menu {
  display: block;
}
/* MOBILE NAVIGATION */
.mobile-nav {
  display:none;
  left: 0;
  bottom: 0;
  height: 50px;
  background-color: #5a4179;
  position: fixed;
  width: 100%;
  z-index: 2;
}

.mobile-nav-routes {
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  text-align: center;
  position: relative;
}

.mobile-nav-routes > a {
  width: 100%;
  height: 100%;
  display: block;
}

.mobile-nav-routes > a > i {
  font-size: 110%
}
.mobile-nav-routes > a > p {
  font-size: 65%;
}

.mobile-nav-routes > a:link,
.mobile-nav-routes > a:visited {
  padding: 6px 0;
  text-decoration: none;
  border-top: 4px solid transparent;
  transition: border-top 0.2s, color 0.2s;
}

.mobile-nav-routes > a:hover,
.mobile-nav-routes > a.router-link-active {
  color: #fff;
  border-top: 4px solid #b52491;
}
.main-nav .fa-bars {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.75rem;
  margin-right: 1rem;
  cursor: pointer;
}

/*MEDIA QUERIES*/
/* Big Tablets up to 1200px */
@media only screen and (max-width: 1140px) {
  .profile-drop-down-menu {
    right: -24px;
  }
}

@media only screen and (max-width: 1070px) {
  .main-nav-notification {
    display: none;
  }

}
/* Small Tablet to big tablet: from 768px to 1023px.
   Targets tablets in landscape mode too. */
@media only screen and (max-width: 1115px) {
  .main-nav-routes {
    display: none;
  }
  .mobile-nav {
    display: block;
  }
}

/* Small phones to small tablets: from 481px to 767px; */
@media only screen and (max-width: 767px) {

}

/* Small phones: from 0 to 480px */
/* Example: iPhone 4/5*/
@media only screen and (max-width: 580px) {
  .logo {
    display: none;
  }
  .main-nav-coins,
  .main-nav-profile {
    display: inline-flex;
  }

  .main-nav-coins {
    margin-left: 16px;
  }

  .main-nav-profile {
    margin-left: auto;
  }
  .main-nav {
    justify-content: flex-end;
    &-diamonds {
      margin-right: auto;
    }
  }
}
@media only screen and (max-width: 400px) {
  .main-nav-profile p {
    display: none;
  }
}
// iphone XR
@media only screen
  and (min-device-width : 414px)
  and (max-device-height : 896px)
  and (-webkit-device-pixel-ratio : 2) {
  .mobile-nav.full-screen {
    height: 65px;
  }
}
// iphone X
@media only screen
  and (device-width : 375px)
  and (device-height : 812px)
  and (-webkit-device-pixel-ratio : 3) {
  .mobile-nav.full-screen {
      height: 65px;
    }
  }

/* iphone XS */
@media only screen
  and (min-device-width : 375px)
  and (max-device-height : 812px)
  and (-webkit-device-pixel-ratio : 3) {
  .mobile-nav.full-screen.full-screen{
    height: 65px;
  }
}

/* iphone XS Max */
@media only screen
  and (min-device-width : 414px)
  and (max-device-height : 896px)
  and (-webkit-device-pixel-ratio : 3) {
  .mobile-nav.full-screen {
    height: 65px;
  }
}

.main-nav--hamburger {
  cursor: pointer;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  height: 100%;
  padding: 0.75rem 1rem .75rem .25rem;
  &-1, &-2, &-3 {
    width: 35px;
    background: #fff;
    height: 5px;
    border-radius: 0.5rem;
  }
}
</style>
