f<template>
  <div class="dynamic-book__frame--component">
    <div class="dynamic-book__frame--border">
      <div class="dynamic-book__frame--dialogue row">
        <image-portrait
          v-if="portrait"
          :portrait="portrait"></image-portrait>
        <p class="dynamic-book__frame--text">
          {{ textToDisplay }}
        </p>
        <!-- <read-aloud-audio></read-aloud-audio> -->
      </div>
      <!-- replace with images in the future -->
      <div
        class="frame-story-img dynamic-book__frame--image"
        :class="framePictureClass"
        :style="framePictureStyle">
      </div>
    </div>
    <slot></slot>
  </div>
</template>

<script>

import {mapState} from 'vuex';

import ImagePortraitComponent
  from '../../common/ImagePortraitComponent.vue';

// import ReadAloudAudioComponent
//   from '../../common/ReadAloudAudioComponent.vue';

export default {
  name: 'DynamicBookFrameScene',
  components: {
    ImagePortrait: ImagePortraitComponent,
    // ReadAloudAudio: ReadAloudAudioComponent,
  },
  props: {
    textToDisplay: String,
    portrait: String,
    // bad code; please review in the future
    // index of which frame within the spirte
    // note -> bad naming, bad code
    // please refactor in the future
    frameIndex: Number,
  },
  data() {
    return {
      baseFrameUrl:
        'https://smartschool-static.s3-ap-southeast-1.amazonaws.com/media/dynamicbooks/2019_dec_holiday_camp/frame-scene/story-',
    };
  },
  computed: {
    ...mapState('dynamicBook', ['currentBookId']),
    framePictureClass() {
      if (isNaN(this.currentBookId) ||
        this.currentBookId < 0 ||
          typeof this.currentBookId === 'undefined') {
        return;
      }

      if (isNaN(this.frameIndex) ||
        this.frameIndex < 0 || typeof this.frameIndex === 'undefined') {
        return;
      }

      return 'frame-story-' + this.currentBookId + '-' + this.frameIndex;
    },
    framePictureStyle() {
      if (isNaN(this.currentBookId) ||
        this.currentBookId < 0 || typeof this.currentBookId === 'undefined') {
        return;
      }

      const frameUrl = this.baseFrameUrl + this.currentBookId + '.png';

      return {
        backgroundImage: 'url(' + frameUrl +')',
      };
    },
  },
  mounted: function() {
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
  .frame-story-img {
    height: 0;
    width: 75%;
    background-repeat: no-repeat;
    padding-top: 49%;
    background-size: 100%;
  }
  .dynamic-book__frame {
    &--border {
      border-radius: 10px;
      border: 5px solid #444;
      width: calc(100vw - 30px);
      height: 100vh;
      margin: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      position: relative;
    }
    &--component {
      height: 100vh;
      width: 100vw;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      &::after {
        content: '';
        background: url('https://smartschool-static.s3-ap-southeast-1.amazonaws.com/media/dynamicbooks/2019_dec_holiday_camp/others/bg-pattern.jpg');
        opacity: 0.3;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: absolute;
        z-index: -1;
      }
    }
    &--dialogue {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 35vh;
      margin-bottom: 55vh;
      padding: 0 2rem;
      z-index: 20;
    }
    &--text {
      color: #fff;
      font-size: 2.65rem;
      text-align: center;
      width: 75%;
      position: relative;
    }
    &--image {
      position: absolute;
      bottom: -150px;
      display: flex;
      justify-content: center;
    }
  }

  .frame-story-0-0 {
    background-position: 0 0%;
  }
  .frame-story-0-1 {
    background-position: 0 12.5%;
  }
  .frame-story-0-2 {
    background-position: 0 25%;
  }
  .frame-story-0-3 {
    background-position: 0 38%;
  }
  .frame-story-0-4 {
    background-position: 0 50%;
  }
  .frame-story-0-5 {
    background-position: 0 62.5%;
  }
  .frame-story-0-6 {
    background-position: 0 75%;
  }
  .frame-story-0-7 {
    background-position: 0 87.5%;
  }
  .frame-story-0-8 {
    background-position: 0 100%;
  }

  .frame-story-1-0 {
    background-position: 0 0%;
  }
  .frame-story-1-10 {
    background-position: 0 5.555556%;
  }
  .frame-story-1-11 {
    background-position: 0 11.111111%;
  }
  .frame-story-1-12 {
    background-position: 0 16.666667%;
  }
  .frame-story-1-13 {
    background-position: 0 22.222222%;
  }
  .frame-story-1-15 {
    background-position: 0 27.777778%;
  }
  .frame-story-1-16 {
    background-position: 0 33.333333%;
  }
  .frame-story-1-17 {
    background-position: 0 38.888889%;
  }
  .frame-story-1-18 {
    background-position: 0 44.444444%;
  }
  .frame-story-1-19 {
    background-position: 0 50%;
  }
  .frame-story-1-2 {
    background-position: 0 55.555556%;
  }
  .frame-story-1-20 {
    background-position: 0 61.111111%;
  }
  .frame-story-1-3 {
    background-position: 0 66.666667%;
  }
  .frame-story-1-4 {
    background-position: 0 72.222222%;
  }
  .frame-story-1-5 {
    background-position: 0 77.777778%;
  }
  .frame-story-1-6 {
    background-position: 0 83.333333%;
  }
  .frame-story-1-7 {
    background-position: 0 88.888889%;
  }
  .frame-story-1-8 {
    background-position: 0 94.444444%;
  }
  .frame-story-1-9 {
    background-position: 0 100%;
  }
  .frame-story-2-1 {
    background-position: 0 0%;
  }
  .frame-story-2-10 {
    background-position: 0 8.333333%;
  }
  .frame-story-2-11 {
    background-position: 0 16.666667%;
  }
  .frame-story-2-12 {
    background-position: 0 25%;
  }
  .frame-story-2-13 {
    background-position: 0 33.333333%;
  }
  .frame-story-2-2 {
    background-position: 0 41.666667%;
  }
  .frame-story-2-3 {
    background-position: 0 50%;
  }
  .frame-story-2-4 {
    background-position: 0 58.333333%;
  }
  .frame-story-2-5 {
    background-position: 0 66.666667%;
  }
  .frame-story-2-6 {
    background-position: 0 75%;
  }
  .frame-story-2-7 {
    background-position: 0 83.333333%;
  }
  .frame-story-2-8 {
    background-position: 0 91.666667%;
  }
  .frame-story-2-9 {
    background-position: 0 100%;
  }
.frame-story-3-1 {
    background-position: 0 0%;
  }
.frame-story-3-10 {
    background-position: 0 9.090909%;
  }
.frame-story-3-11 {
    background-position: 0 18.181818%;
  }
.frame-story-3-12 {
    background-position: 0 27.272727%;
  }
.frame-story-3-2 {
    background-position: 0 36.363636%;
  }
.frame-story-3-3 {
    background-position: 0 45.454545%;
  }
.frame-story-3-4 {
    background-position: 0 54.545455%;
  }
.frame-story-3-5 {
    background-position: 0 63.636364%;
  }
.frame-story-3-6 {
    background-position: 0 72.727273%;
  }
.frame-story-3-7 {
    background-position: 0 81.818182%;
  }
.frame-story-3-8 {
    background-position: 0 90.909091%;
  }
.frame-story-3-9 {
    background-position: 0 100%;
  }

  // @media only screen and (min-width: 1920px) and (min-height: 800px) {
  //   .frame-story-img {
  //     width: 75%;
  //     padding-top: 49%;
  //   }
  //   .dynamic-book__frame {
  //     &--image {
  //       bottom: -150px;
  //     }
  //   }
  // }
  /* Landscape */
  // ipad pro
  @media only screen
  and (min-device-width: 1024px)
  and (max-device-width: 1366px)
  and (-webkit-min-device-pixel-ratio: 2) {
    .frame-story-img {
      width: 110%;
      padding-top: 67%;
    }
    .dynamic-book__frame {
      &--image {
        bottom: -135px;
      }
      &--text {
        width: 80%;
      }
    }
  }
  // ipad
  @media only screen and (min-device-width : 768px)
  and (max-device-width : 1024px) and (orientation : landscape) {
    .frame-story-img {
      width: 100%;
      padding-top: 67%;
    }
    .dynamic-book__frame {
      &--text {
        color: #fff;
        font-size: 2.4rem;
        text-align: center;
        width: 75%;
      }
      &--image {
        bottom: -100px;
      }
    }
  }

</style>
