import { render, staticRenderFns } from "./PassageComponent.vue?vue&type=template&id=01f26d10&scoped=true&"
import script from "./PassageComponent.vue?vue&type=script&lang=js&"
export * from "./PassageComponent.vue?vue&type=script&lang=js&"
import style0 from "./PassageComponent.vue?vue&type=style&index=0&id=01f26d10&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "01f26d10",
  null
  
)

export default component.exports