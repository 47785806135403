<template>
  <div v-if="dialogueData" @click="nextTextWithinDialogue" class="narrator-container">
    <div class="dialogue-container">
      <h3 class="narrator-name">{{ narrator }}</h3>
      <div class="img-text-container">
        <div class="img-container">
          <img :src="portraitSrc" class="portrait">
        </div>
        <div class="text-container" ref="textContainer">
          {{ text }}
        </div>
      </div>
      <div v-if="!isTyping" class="proceed-arrow"><i class="fa-solid fa-arrow-right"></i></div>
    </div>
  </div>
</template>

<script>

/**
 * takes a list of dialogues objects. Each dialogu object has a narrator and a list of texts. E.g. below
 [
    {
      narrator: 'burnox',
      texts: ['Water. Earth. Fire. Air.', 'Long ago, the four nations lived together in harmony.'],
    },
    {
      narrator: 'mangsha',
      texts: ['Only the Avatar, master of all four elements, could stop them, but when the world needed him most, he vanished.'],
    }
 *
 */

import {mapMutations} from 'vuex';

export default {
  name: 'NarratorPopup',
  components: {
  },
  props: {
    dialogueData: Array,
    endCallback: Function,
  },
  data() {
    return {
      narrator: '',
      text: '',
      dialogueIndex: 0,
      textIndex: 0, // controls which text message is show
      // controls which char is shown
      charIndex: 0,
      typeWriterSpeed: 50,
      isTyping: false,
      timeoutId: -1,
    };
  },
  computed: {
    currentDialogue() {
      if (this.dialogueIndex >= this.dialogueData.length) return null;
      return this.dialogueData[this.dialogueIndex];
    },
    portraitSrc() {
      if (!this.currentDialogue) return;
      return require('../../assets/pet/narrator/' + this.currentDialogue['narrator'] + '.png');
    },
  },
  mounted() {
    this.initialize();
    // hackish, but widgets keep appearing. thus we hide when we show narrator popup, and then show it later
    this.setIsWidgetsShown(false);
  },
  methods: {
    ...mapMutations(['setIsWidgetsShown']),
    capitalizeWords(text) {
      const arr = text.split(' ');
      return arr.map((element) => {
        return element.charAt(0).toUpperCase() + element.substring(1).toLowerCase();
      }).join(' ');
    },
    initialize() {
      this.text = '';
      this.narrator = this.capitalizeWords(this.currentDialogue['narrator']);
      this.textIndex = 0;
      this.charIndex = 0;
      this.isTyping = true;
      this.typeWriter();
    },
    // in the future, refactor this to solve the problems of word going to next line
    // need to measure the width of each word (either thru canvas or by fixing the font)
    typeWriter() {
      if (!this.isTyping) return;
      const currentText = this.currentDialogue['texts'][this.textIndex];
      if (this.charIndex < currentText.length) {
        this.text += currentText.charAt(this.charIndex);
        this.charIndex++;
        this.timeoutId = setTimeout(this.typeWriter, this.typeWriterSpeed);
      } else {
        this.isTyping = false;
        clearTimeout(this.timeoutId);
      }
    },
    // if text message is still being shown, show all
    // if text message is already shown, and there is another message to be shown, go to next message
    nextTextWithinDialogue() {
      if (this.isTyping) {
        this.showText();
      } else if (this.textIndex + 1 < this.currentDialogue['texts'].length) {
        this.textIndex++;
        this.text = '';
        this.charIndex = 0;
        this.isTyping = true;
        this.typeWriter();
      } else {
        this.nextDialogue();
      }
    },
    // when we finish with all the texts in current dialogue, move to next dialogue if there is more
    // otherwise call endCallback and close
    nextDialogue() {
      if (this.dialogueIndex + 1 < this.dialogueData.length) {
        this.dialogueIndex++;
        this.initialize();
      } else {
        this.setIsWidgetsShown(true);
        this.endCallback();
      }
    },
    showText() {
      this.isTyping = false;
      clearTimeout(this.timeoutId);
      this.text = this.currentDialogue['texts'][this.textIndex];
    },
  },
};
</script>

<style lang="scss" scoped>
.narrator-container {
  display: flex;
  position: fixed;
  z-index: 2;
  background: #000000c9;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  color: #555;
  flex-direction: column;
}

.proceed-arrow {
  position: absolute;
  bottom: 0px;
  right: 10px;
  color: white;
  font-size: 1.5em;
}

.dialogue-container {
  position: fixed;
  bottom: 10px;
  left: 20%;
  width: 60%;
  height: 200px;
  background: linear-gradient(#e66465, #9198e5);
  padding: 10px;
}

.narrator-name {
  text-align: center;
  color: white;
}

.img-text-container {
  display: flex;
}

.text-container {
  color: white;
}

.portrait {
  max-width: 200px;
  margin-top: -45px;
  margin-left: -25px;
}

@media only screen and (max-width: 505px) {
  .dialogue-container {
    left: 0%;
    width: 100%;
  }

  .img-container {
    display: none;
  }
}


</style>
