<template>
  <block-container
    title="Comprehension"
    fa-icon="gamepad"
    >
    <div class="body-content">
      <router-link
        :to="{name: 'new-malay-comprehension'}"
        tag="p"
        class="body-content--item">
        <div class="body-content--item-icon">
        </div>
        <div class="body-content--item-text">
          Sekolah
        </div>
      </router-link>
    </div>
  </block-container>
</template>

<script>
import {mapState} from 'vuex';
// import {mapState, mapGetters, mapActions} from 'vuex';

import BlockContainerComponent
  from '../../common/BlockContainerComponent.vue';

export default {
  name: 'NewPlayOverview',
  components: {
    BlockContainer: BlockContainerComponent,
  },
  data() {
    return {
      fetching: false,
    };
  },
  computed: {
    ...mapState(['cocos', 'level']),
  },
  mounted() {
  },
  methods: {
  },
};
</script>


<style lang="scss" scoped>
  .body-content {
    height: 100%;
    width: 100%;
    padding: 2rem;
    overflow-y: auto;
    overflow-x: hidden;
    position: absolute;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    text-align: center;
    gap: 1rem;
    &--item {
      background: #fff;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: stretch;
      flex-direction: column;
      text-align: center;
      border: 8px solid #fff;
      border-radius: 20px;
      text-decoration: none;
      transition: all 0.2s;
      position: relative;
      height: 250px;
      &:hover {
        box-shadow: 0 0px 1px 7px #00ffd8;
      }
      &-icon {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        transition: all 0.2s;
        border-radius: 1rem;
        background-size: cover;
        background-position: center;
        i {
          transition: all 0.2s;
          color: #b9b6c1;
          font-size: 3.5rem;
          margin-bottom: 5px;
        }
      }
      &-text {
        background: #fff;
        border-bottom-right-radius: .5rem;
        border-bottom-left-radius: .5rem;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 600;
        font-size: 1rem;
        color: #6b6b6b;
        padding-top: .5rem;
      }
    }
  }

  @media only screen and (max-width: 1560px) {
    .body-content {
    }
  }
  @media only screen and (max-width: 1366px),
              screen and (max-height: 870px) {
    .body-content {
      grid-template-columns: 1fr 1fr;
      &--item {
        height: 100%;
      }
      &-text {
        font-size: .9rem;
      }
    }
  }

  @media only screen and (max-width: 1150px),
  screen and (max-height: 690px) {
  }
  @media only screen and (max-width: 960px),
  screen and (max-height: 620px) {
    .body-content {
      background: none;
      width: 100%;
      height: 100%;
    }
  }
  @media only screen and (max-width: 690px) {
    .body-content {
      padding: 1.5rem
    }
  }
  @media only screen and (max-width: 580px) {
    .body-content {
      grid-template-columns: 1fr;
    }
  }
  @media only screen and (max-width: 480px) {
    .body-content {
      padding: 1rem
    }
    .body-content--item-icon {
      padding: .5rem;
      i {
        font-size: 2.5rem;
      }
    }
    .body-content--item-text {
      font-size: .8rem;
    }
  }

</style>
