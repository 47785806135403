<template>
  <block-container
    title="Settings"
    fa-icon="cog"
    >
    <div class="body-content">
      <!-- only primary school students can change difficulty -->
      <router-link
        v-if="!isPreschool"
        :to="{name: 'new-settings-change-difficulty'}"
        tag="p"
        class="body-content--item">
        <div class="body-content--item-icon">
          <i class="fa-solid fa-gauge-high"></i>
        </div>
        <div class="body-content--item-text">
          Set Difficulty
        </div>
      </router-link>
      <!-- diabling billing for digital trial for now until we re add stripe -->
      <router-link
        v-if="studentType !== 'attending' && studentType !== 'digital_trial'"
        :to="{name: 'new-settings-billing-index'}"
        tag="p"
        class="body-content--item">
        <div class="body-content--item-icon">
          <i class="fa-solid fa-wallet"></i>
        </div>
        <div class="body-content--item-text">
          Billing
        </div>
      </router-link>
      <router-link
        :to="{name: 'new-settings-password'}"
        tag="p"
        class="body-content--item">
      <div class="body-content--item-icon">
        <i class="fa-solid fa-lock"></i>
      </div>
      <div class="body-content--item-text">
        Change Password
      </div>
      </router-link>
      <router-link
        v-if="siblings.length > 0"
        :to="{name: 'new-settings-switch-user'}"
        tag="p"
        class="body-content--item">
        <div class="body-content--item-icon">
          <i class="fa-solid fa-users"></i>
        </div>
        <div class="body-content--item-text">
          Switch User
        </div>
      </router-link>
      <div class="body-content--item">
        <a @click.prevent="logout">
          <div class="body-content--item-icon">
            <i class="fa-solid fa-solid fa-right-from-bracket"></i>
          </div>
          <div class="body-content--item-text">
            Logout
          </div>
        </a>
      </div>
    </div>
  </block-container>
</template>

<script>

import {mapState, mapGetters, mapActions} from 'vuex';

import BlockContainerComponent
  from '../common/BlockContainerComponent.vue';

export default {
  name: 'NewSettingsOverview',
  components: {
    BlockContainer: BlockContainerComponent,
  },
  data() {
    return {
      fetching: false,
    };
  },
  computed: {
    ...mapState(['siblings', 'studentType']),
    ...mapGetters(['isPreschool']),
  },
  mounted() {
  },
  methods: {
    ...mapActions(['resetState']),
    logout() {
      this.resetState();

      // redirects to login page
      this.$router.push({name: 'login-main'});
    },
  },
};
</script>


<style lang="scss" scoped>
  .body-content {
    height: 100%;
    width: 100%;
    padding: 2rem;
    overflow-y: auto;
    overflow-x: hidden;
    position: absolute;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    text-align: center;
    grid-gap: 1rem;
    &--item {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: stretch;
      flex-direction: column;
      text-align: center;
      background: #fff;
      border-radius: 7.5px;
      text-decoration: none;
      border: 6px solid transparent;
      transition: all 0.2s;
      position: relative;
      &:hover {
        background: #fff;
        .body-content--item-icon {
          background: #fff;
          border: 6px solid #2b92f8;
          i {
            color: #2b92f8;
          }
        }
      }
      &-icon {
        height: 75%;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 6px solid #e9e9e9;
        margin: .5rem .5rem;
        transition: all 0.2s;
        padding: 1rem;
        border-radius: 0.5rem;
        background: #fff;
        i {
          transition: all 0.2s;
          color: #b9b6c1;
          font-size: 3.5rem;
          margin-bottom: 5px;
        }
      }
      &-text {
        width: 100%;
        height: calc(25% - 1rem);
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 600;
        font-size: 1rem;
        color: #4e4e54;
      }
    }
  }
  @media only screen and (max-width: 1560px) {
    .body-content {
      grid-template-columns: 1fr 1fr;
    }
  }
  @media only screen and (max-width: 1366px),
              screen and (max-height: 870px) {
    .body-content {
        padding: 1.5rem;
        grid-gap: 1.25rem;
        &--item {
          padding: .25rem;
          &-icon {
            padding: .75rem;
            i {
              font-size: 3rem;
            }
          }
        }
        &-text {
          font-size: .9rem;
        }
      }
    }

  @media only screen and (max-width: 1150px),
  screen and (max-height: 690px) {
  }
  @media only screen and (max-width: 960px),
  screen and (max-height: 620px) {
    .body-content {
      background: none;
      width: 100%;
      height: 100%;
    }
  }
  @media only screen and (max-width: 690px) {
    .body-content {
      grid-template-columns: 1fr;
    }
  }
  @media only screen and (max-width: 580px) {
    .body-content {
      grid-gap: 1rem;
    }
  }
  @media only screen and (max-width: 480px) {
    .body-content--item-icon {
      padding: .5rem;
      i {
        font-size: 2.5rem;
      }
    }
    .body-content--item-text {
      font-size: .8rem;
    }
  }

</style>
