<template>
  <div>
    <div class="story-thumbnail row">
      <router-link v-if="unlocked" class="story-thumbnail-img"
        :to="{name: 'library-story', params: {storyId: story.pk}}">
        <img
          :src="story.thumbnail"
          class="img-thumbnail img-thumbnail-transition"
        />
      </router-link>
      <div v-if="!unlocked" class="story-thumbnail-img disabled-cursor">
        <img
          :src="story.thumbnail"
          class="img-thumbnail translucent"
        />
        <p v-if="!unlocked" class="lock">
          <i class="fas fa-lock"></i>
        </p>
      </div>
      <router-link v-if="unlocked"
      :to="{name: 'library-story', params: {storyId: story.pk}}"
      class="story-thumbnail-caption">
        <p v-if="!read" class="new">new</p>
        <div class="story-thumbnail-caption-title">
          <h2>{{ index + 1 }}.
            <span class="chinese-character"> {{ story.chineseTitle }}</span>
          </h2>
        </div>
        <div class="story-thumbnail-caption-desc">
          {{ story.description }}
        </div>
        <div class="story-thumbnail-caption-words">
          <div class="words-icon-container">
            <i class="fas fa-book"></i>
          </div>
          <p
            v-for="(word, _index) in story.wordsToRecognise"
            :key="_index"
            class="story-thumbnail-caption-word chinese-character">
            {{ word.text }}
          </p>
        </div>
      </router-link>
      <router-link
        v-if="unlocked" class="story-play-btn"
        :to="{name: 'library-story', params: {storyId: story.pk}}">
        <div class="play-icon-circle">
          <i class="fas fa-play"></i>
        </div>
      </router-link>
      <div v-if="!unlocked" class="story-play-btn-disabled">
        <div class="play-icon-circle-disabled">
          <i class="fas fa-play"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex';

export default {
  name: 'LibraryStoryarcThumbnail',
  props: {
    story: Object,
    index: Number,
    unlocked: Boolean,
    read: Boolean,
  },
  data() {
    return {
      storyArcs: [],
      stories: [],
      selected: null,
      info: {},
      doneInitialFetching: false,
    };
  },
  computed: {
    ...mapState('library', ['currentStoryarc']),
  },
  // https://stackoverflow.com/questions/52637936/vuejs-skip-watchers-first-change
  watch: {
  },
  mounted: function() {
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
//TODO: Change mobile play button to touch friendly
//TODO: Add play button over image in mobile to show where to click;
.disabled-cursor {
  cursor: not-allowed;
}

// thumbnail container
.story-thumbnail {
  margin: 20px auto;
  background: white;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  height: 100%;
  border-radius: 5px;
  overflow: hidden;
}

// thumbnail img styling
.story-thumbnail-img {
  background: #333;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 35%;
  overflow: hidden;
}

.story-thumbnail .disabled-cursor {
  background: #848484;
}

.img-thumbnail {
  width: 100%;
  display:block;
}

.img-thumbnail-transition {
  transform:scale(1.03);
  opacity: 0.95;
  transition: transform 0.3s, opacity 0.3s;
}

.img-thumbnail-transition:hover {
  transform: scale(1);
  opacity: 1;
}

.lock {
  color: #969696;
  text-shadow: -3px 0 #fff, 0 3px #fff, 3px 0 #fff, 0 -3px #fff;
  position: absolute;
  font-size: 5em;
  z-index: 2;
}

.new {
  position: absolute;
  top: 20px;
  right: 20px;
  background-color: #49d689;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  box-shadow: 0 5px 5px rgba(0,0,0,0.2);
  text-transform: uppercase;
  font-weight: 700;
}

.img-thumbnail.translucent {
  opacity: 0.4;
}

.story-thumbnail-caption {
  flex: 1;
  padding: 35px 30px;
  display: flex;
  flex-direction: column;
  position: relative;
  color: #555;
  text-decoration: none;
}

.story-thumbnail-caption-text {
  flex: 1;
}
.story-thumbnail-caption-title {
  margin-bottom: 20px;
}

// styling for important words for chapter
.story-thumbnail-caption-words {
  display: flex;
  margin-top: auto;
  border-top: 2px solid #eee;
  align-items: center;
}

.words-icon-container {
  margin-top: 15px;
  margin-right: 20px;
  background: #cecece ;
  color: #fff;
  height: 35px;
  width: 35px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.story-thumbnail-caption-word {
  margin-top: 15px;
  margin-right: 20px;
  border: 2px solid #eee;
  padding: 2px 20px;
  border-radius: 10px;
  transition: all 0.2s;
  // cursor: pointer;
}

// play play btn styling
.story-play-btn:link,
.story-play-btn:visited {
  width: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-basis: auto;
  background: #bbb;
  font-size: 2em;
  color: rgba(255, 255, 255, 0.5);
  text-decoration: none;
  transition: all 0.3s ease-out;
}

.story-play-btn:hover,
.story-play-btn:active {
  background: #ffbe43;
  width: 12.5%;
}

.story-play-btn:hover .play-icon-circle,
.story-play-btn:active .play-icon-circle {
  border: 5px solid rgba(255, 255, 255, 0.8);
  color: rgba(255, 255, 255, 0.8);
}

.play-icon-circle {
  height: 90px;
  width: 90px;
  border-radius: 50%;
  border: 5px solid rgba(255, 255, 255, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  padding-left: 5px;
  transition: all 0.2s ease-out;
}

.play-icon-circle:hover {
  background: rgba(255, 255, 255, 0.4);
  border: 5px solid white;
  color: #fff;
  height: 100px;
  width: 100px;
  font-size: 1.2em;
}

// play button disabled
.story-play-btn-disabled {
  width: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-basis: auto;
  background: #bbb;
  font-size: 2em;
  color: #cfcfcf;
  cursor: not-allowed;
}

.play-icon-circle-disabled {
  height: 90px;
  width: 90px;
  border-radius: 50%;
  border: 5px solid #cfcfcf;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;
  padding-left: 5px;
  position: relative;
}

.play-icon-circle-disabled::before {
  position: absolute;
  content: "";
  left: 0;
  top: 50%;
  right: 0;
  border-top: 5px solid #cfcfcf;
  transform:rotate(45deg);
}

/*MEDIA QUERIES*/
/* Big Tablets up to 1200px */
@media only screen and (max-width: 1200px) {
}
@media only screen and (max-width: 1040px) {
  .story-thumbnail-caption-word {
    padding: 4px 8px;
  }
}
/* Small Tablet to big tablet: from 768px to 1023px. */
@media only screen and (max-width: 1023px) {
  .story-thumbnail-caption {
    padding: 30px 30px;
  }
  .story-thumbnail-caption-title {
    margin-bottom: 15px;
    font-size: 90%;
  }
  .story-thumbnail-caption-desc {
    font-size: 90%;
    padding-bottom: 15px;
  }
  .story-play-btn:link,
  .story-play-btn:visited {
    font-size: 150%;
  }

  .story-play-btn:hover,
  .story-play-btn:active {
    background: #ffbe43;
    width: 11.5%;
  }

  .story-play-btn-disabled {
    font-size: 150%;
  }
  .play-icon-circle-disabled {
    height: 80px;
    width: 80px;
  }

  .play-icon-circle {
    height: 80px;
    width: 80px;
  }
  .play-icon-circle:hover {
    height: 90px;
    width: 90px;
    font-size: 120%;
  }
}

@media only screen and (max-width: 955px) {
  .story-play-btn:link,
  .story-play-btn:visited,
  .story-play-btn-disabled {
    display: none;
  }
  .img-thumbnail {
    width: 120%;
  }
}

@media only screen and (max-width: 800px) {
  .img-thumbnail {
    width: 170%;
  }
  .story-thumbnail-img {
    width: 25%;
  }
}
/* Small phones to small tablets: from 481px to 767px; */
@media only screen and (max-width: 767px) {
  .img-thumbnail {
    width: 160%;
  }
  .story-thumbnail-caption {
    font-size: 90%;
  }
  .story-thumbnail-caption-words {
    margin-top: auto;
  }
}

@media only screen and (max-width: 704px) {
  .img-thumbnail {
    width: 180%;
  }

  .words-icon-container,
  .story-thumbnail-caption-word {
    margin-right: 10px;
  }
  .lock {
    font-size: 450%;
  }

}

@media only screen and (max-width: 640px){
  .img-thumbnail {
    width: 190%;
  }
  .new {
    top: 10px;
    right: 10px;
    padding: 3px 6px;
    font-size: 90%
  }
  .lock {
    font-size: 400%;
  }
}

@media only screen and (max-width: 640px){
  .img-thumbnail {
    width: 210%;
  }
  .story-thumbnail-caption {
    padding: 25px 25px;
  }
}

@media only screen and (max-width: 560px) {
  .story-thumbnail-caption-words {
    display: none;
  }

  .story-thumbnail-caption {
    padding: 0;
    font-size: 80%;
  }

  .story-thumbnail-caption {
    padding: 16px 16px;
  }
  .lock {
    font-size: 300%;
  }

}
/* Small phones: from 0 to 480px */
@media only screen and (max-width: 480px) {
}

</style>
