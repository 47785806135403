<template>
  <div class="dynamic-book__title--component">
    <img
      class="dynamic-book__title--image"
      :src="titleUrl"
    >
    <h1 class="dynamic-book__title--text">
      《{{ textToDisplay }}》</h1>
    <slot></slot>
    <toggle-difficulty></toggle-difficulty>
  </div>
</template>

<script>

import {mapState} from 'vuex';
import ToggleDifficultyComponent
  from '../../common/ToggleDifficultyComponent.vue';

export default {
  name: 'DynamicBookTitleScene',
  components: {
    ToggleDifficulty: ToggleDifficultyComponent,
  },
  props: {
    textToDisplay: String,
    subtitle: String,
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapState('dynamicBook', ['currentBookId']),
    titleUrl() {
      const baseUrl = 'https://smartschool-static.s3-ap-southeast-1.amazonaws.com/media/dynamicbooks/2019_dec_holiday_camp/title-scene/title-';

      return baseUrl + this.currentBookId + '.png';
    },
  },
  mounted: function() {
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
  .dynamic-book__title {
    &--component {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      height: 100%;
    }
    &--text {
      position: relative;
      font-size: 5rem;
      color: #fff;
    }
    &--image {
      max-width: 900px;
    }
  }
  .small {
    max-width: 300px;
    max-height: 300px
  }

  .medium {
    max-width: 600px;
    max-width: 600px;
  }
</style>
