<!-- main learning track component -->
<template>
  <div class="fixed-component">
    <div class="scrollable-component">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import {mapState, mapActions, mapMutations} from 'vuex';

export default {
  name: 'LearningTrackMain',
  components: {
  },
  props: {
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapState('learningTrack', ['learningTracks']),
  },
  watch: {
    // pull information on learning tracks if we don't already have the data
    $route: {
      immediate: true,
      handler(to, from) {
        if (this.learningTracks.length > 0) return;
        // only fetch data if learningTracks has not been previously fetched
        // https://www.reddit.com/r/vuejs/comments/7w6okz/auth_token_stored_in_localstorage_not_working_on/
        console.log('setting gloabl laoding screen');
        this.setShowGlobalLoadingScreen(true);
        axios
            .get('/api/v1/brain/learningtrack/')
            .then((response) => {
              this.initialLoadLearningTracks(response.data);
              this.setShowGlobalLoadingScreen(false);
            })
            .catch((error) => {
              console.log('having issue pulling learning tracks');
            });
      },
    },
  },
  methods: {
    ...mapMutations(['setShowGlobalLoadingScreen']),
    ...mapActions('learningTrack', ['initialLoadLearningTracks']),
  },
  mounted() {
  },
};
</script>

<style lang="scss" scoped>
.fixed-component {
  z-index: 3;
}
</style>
