export const routeNameToWidgets = {
  'profile-main': ['leaderboard', 'daily-streak', 'missions'],
  'new-oral-overview': ['speech-analytics', 'daily-streak', 'missions'],
  'new-oral-category-index': ['speech-analytics', 'daily-streak', 'missions'],
  'new-learn-overview': ['leaderboard', 'daily-streak', 'missions'],
  'new-learn-vocab-index': ['leaderboard', 'daily-streak', 'missions'],
  'new-learn-worksheet-download': ['leaderboard', 'daily-streak', 'missions'],
  'new-settings-overview': ['leaderboard', 'daily-streak', 'missions'],
  'new-settings-billing-index': ['leaderboard', 'daily-streak', 'missions'],
  'new-settings-change-difficulty': ['leaderboard', 'daily-streak', 'missions'],
  'new-settings-switch-user': ['leaderboard', 'daily-streak', 'missions'],
  'new-settings-password': ['leaderboard', 'daily-streak', 'missions'],
  'new-missions-overview': ['leaderboard', 'daily-streak'],
  'new-play-overview': ['leaderboard', 'daily-streak', 'missions'],
  'new-play-avatar': ['leaderboard', 'daily-streak', 'missions'],
  'new-play-fishing-game': ['speech-analytics', 'daily-streak'],
};
