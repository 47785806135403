<template>
  <div v-if="currentChallenge" class="events__component">
    <div v-if="fetching" class="loading">
      <img src="../../../../assets/loading.gif">
    </div>
    <div class="events__component--btn-close"
      @click="isCloseButtonClicked()">
    </div>
    <div class="events__component--header">
      <div class="events__component--header-gradient-bg"></div>
      <div class="events__component--header-text">
        <div class="events__component--header-text--main">
          <h2>{{ currentChallenge.title }}</h2>
          <p>Time Left: {{ timeUntil.days }} days {{ timeUntil.hours }}  hrs {{ timeUntil.minutes }}  mins</p>
          <div class="events__component--header-text--main--leaf"></div>
        </div>
        <div class="events__component--header-text--sub">
          <p>- Complete missions to earn tickets</p>
          <p>- Earn enough tickets to unlock event-exclusive rewards!</p>
        </div>
      </div>
      <div class="events__component--header-text--label">
        Limited Time Event!
      </div>
      <div class="events__component--header-progress">
        <div class="events__component--header-progress-bar">
          <div :style="{width: ticketsProgretssBarWidth}"
            class="events__component--header-progress-bar--fill"></div>
        </div>
        <div class="events__component--header-progress--items">
          <div class="events__component--header-progress--item">
            <div class="events__component--header-progress--item-icon">
              <i class="fa-solid fa-ticket"></i>
            </div>
            <div class="events__component--header-progress--item-label">
              {{ currentChallenge.current_tickets }} / {{ currentChallenge.max_tickets }}
            </div>
          </div>
          <div class="events__component--header-progress--item">
            <div class="events__component--header-progress--item-icon">
              <!-- // default icon, low opacity, for when reward is unavailable or collected -->
              <img @click="claimReward(0)"
                :class="{'reward-available': currentChallenge.rewards[0].status === 2}"
                src="../../../../assets/profile/diamond.png">
              <!-- // when amount of golden tickets reached, award is clickable -->
              <!-- <img class="reward-available" src="../../../../assets/profile/diamond.png"> -->
              <!-- // icon for when reward has been collected -->
              <i v-if="currentChallenge.rewards[0].status === 3" class="fa-solid fa-check"></i>
            </div>
          </div>
          <div class="events__component--header-progress--item">
            <div class="events__component--header-progress--item-icon">
              <!-- // default icon, low opacity, for when reward is unavailable or collected -->
              <!-- <img src="../../../../assets/profile/diamond.png"> -->
              <!-- // when amount of golden tickets reached, award is clickable -->
              <img @click="claimReward(1)"
                :class="{'reward-available': currentChallenge.rewards[1].status === 2}"
                src="../../../../assets/profile/diamond.png">
              <i v-if="currentChallenge.rewards[1].status === 3" class="fa-solid fa-check"></i>
            </div>
          </div>
          <div class="events__component--header-progress--item">
            <div class="events__component--header-progress--item-icon">
              <img @click="claimReward(2)"
                :class="{'reward-available': currentChallenge.rewards[2].status === 2}"
                src="../../../../assets/profile/treasure-chest.png">
              <i v-if="currentChallenge.rewards[2].status === 3" class="fa-solid fa-check"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="events__component--body">
      <div class="events__component--body-mission-items">
        <task
          v-for="(info, index) in currentChallenge.tasks" :key="index"
          :info="info" @claim="claimTask"></task>
      </div>
    </div>
    <!-- <basic-reward v-if="showRewardsPage"
      :diamonds-to-add="diamondsToAdd"
      :close-rewards-callback="onCloseRewards">
    </basic-reward> -->
  </div>
</template>

<script>

import axios from 'axios';

import {mapState, mapMutations} from 'vuex';

// import BasicRewardComponent
//   from '../../../common/BasicRewardComponent.vue';
import TaskComponent from './components/TaskComponent.vue';

export default {
  name: 'EventsComponent',
  components: {
    Task: TaskComponent,
    // BasicReward: BasicRewardComponent,
  },
  data() {
    return {
      intervalId: -1,
      diamondsToAdd: 1,
      showRewardsPage: true,
      fetching: false,
      timeUntil: {days: 0, hours: 0, minutes: 0},
      bodyBackgroundColor: 'linear-gradient(to right bottom, rgba(29, 36, 77, 0.79), rgb(21 24 41 / 86%))',
    };
  },
  computed: {
    ...mapState(['studentId', 'challenges']),
    currentChallenge() {
      if (this.challenges.length === 0) return null;
      return this.challenges[0];
    },
    fakeInfo() {
      return [
        {
          description: 'Master 200 P1 words',
          target_times_done: 200,
          current_times_done: 200,
          reward: 200,
          status: 2,
        },
        {
          description: 'Practise 100 P1 sentences',
          target_times_done: 100,
          current_times_done: 100,
          reward: 200,
          status: 2,
        },
        {
          description: 'Master 250 P2 words',
          target_times_done: 250,
          current_times_done: 250,
          reward: 200,
          status: 2,
        },
        {
          description: 'Practise 100 P2 sentences',
          target_times_done: 100,
          current_times_done: 100,
          reward: 200,
          status: 2,
        },
        {
          description: 'Master 300 P3 words',
          target_times_done: 300,
          current_times_done: 300,
          reward: 200,
          status: 2,
        },
        {
          description: 'Practise 100 P3 sentences',
          target_times_done: 100,
          current_times_done: 100,
          reward: 200,
          status: 2,
        },
      ];
    },
    ticketsProgretssBarWidth() {
      if (!this.currentChallenge) return 0;
      const num = Math.ceil(this.currentChallenge.current_tickets / this.currentChallenge.max_tickets * 100);
      return `${num}%`;
    },
  },
  mounted() {
    // updates the time until; checks every 10s
    this.timeUntil = this.getTimeUntil();
    this.intervalId = setInterval(() => {
      this.timeUntil = this.getTimeUntil();
    }, 10000);

    // gets latest value of challenges
    axios.get(`/api/v1/core/student/${this.studentId}/challenges/`)
        .then((response) => {
          this.fetching = false;
          console.log(response.data);
          // slight delay so child can see the movement of the progress bar
          setTimeout(() => {
            this.setChallenges(response.data['challenges']);
          }, 200);
        })
        .catch((err) => {
          console.log('error');
          console.log(err);
          this.fetching = false;
        });
  },
  methods: {
    ...mapMutations(['setChallenges', 'setDiamonds']),
    claimTask(taskKey) {
      if (this.fetching) return;
      // don't need to reset fetching since we should only need to fetch once (can only claim once)
      this.fetching = true;
      axios.post(`/api/v1/core/student/${this.studentId}/claim_challenge_task/`, {
        taskKey: taskKey,
        challengeName: this.currentChallenge.name,
      })
          .then((response) => {
            this.fetching = false;
            // slight delay so child can see the movement of the progress bar
            setTimeout(() => {
              this.setChallenges(response.data['challenges']);
            }, 200);
          })
          .catch((err) => {
            console.log('error');
            console.log(err);
            this.fetching = false;
          });
    },
    claimReward(rewardIndex) {
      if (this.fetching) return;
      // don't need to reset fetching since we should only need to fetch once (can only claim once)
      this.fetching = true;
      axios.post(`/api/v1/core/student/${this.studentId}/claim_challenge_reward/`, {
        challengeName: this.currentChallenge.name,
        rewardIndex: rewardIndex,
      })
          .then((response) => {
            this.fetching = false;
            console.log(response.data);
            setTimeout(() => {
              this.setChallenges(response.data['challenges']);
            }, 200);

            const rewardType = response.data['info']['reward_type'];
            if (rewardType === 'diamond') {
              this.setDiamonds(response.data['info']['new_diamonds']);
              this.diamondsToAdd = response.data['info']['diamonds_added'];
              alert(`Added ${this.diamondsToAdd} diamonds!`);
              this.showRewardsPage = true;
            } else if (rewardType === 'custom') {
              alert(`You have completed the full mission. Check with your parent for ${response.data['info']['message']}`);
            }
          })
          .catch((err) => {
            console.log('error');
            console.log(err);
            this.fetching = false;
          });
      // this.currentTickets += args.numTicketsAdded;
    },
    isCloseButtonClicked() {
      this.$emit('isClosed');
      clearInterval(this.intervalId);
      console.log(this.intervalId);
      console.log('closing');
    },
    // hard coded for now
    getTimeUntil() {
      const now = new Date();
      const target = new Date('2024-06-30T00:00:00');
      if (now > target) return {days: 0, hours: 0, minutes: 0};

      const diffInMilliseconds = target - now;
      const diffInSeconds = Math.floor(diffInMilliseconds / 1000);
      const diffInMinutes = Math.floor(diffInSeconds / 60);
      const diffInHours = Math.floor(diffInMinutes / 60);
      const diffInDays = Math.floor(diffInHours / 24);

      const days = diffInDays;
      const hours = diffInHours % 24;
      const minutes = diffInMinutes % 60;

      return {
        days: days,
        hours: hours,
        minutes: minutes,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
  .loading {
      position: absolute;
      z-index: 99;
      left: 50%;
      top: 50%;
      width: 100px;
  }

  .loading img {
    width: 100%;
  }

  .fa-ticket {
    color: #fff355;
    transform: rotate(-45deg);
    text-shadow: 1px 1px 2px #ff7b00, 0 0 15px #ff98009c, 0 0 7px orange;
  }

  .events__component {
    &--btn-close {
      position: absolute;
      z-index: 3;
      right: .75rem;
      top: 2rem;
      width: 32px;
      height: 32px;
      opacity: 0.9;
      cursor: pointer;
      transition: 0.3s all linear;
      &:hover {
        opacity: 1;
      }
      &:before, &:after {
        position: absolute;
        left: 15px;
        content: ' ';
        height: 25px;
        width: 5px;
        background-color: #ffffff;
      }
      &:before {
        transform: rotate(45deg);
      }
      &:after {
        transform: rotate(-45deg);
      }
    }
    height: 90%;
    width: 820px;
    box-shadow: 0 0px 6px 6px #e13eff;
    border-radius: .25rem;
    position: relative;
    &--header {
      &-gradient-bg {
        height: 60%;
        position: absolute;
        bottom: 0;
        width: 100%;
        background: linear-gradient(to top, #fff, transparent);
      }
      border-radius: .25rem;
      height: 350px;
      width: 100%;
      object-fit: cover;
      object-position: 70% 30%;
      background-size: cover;
      background-image: url("../../../../assets/banner-events-sevenday.jpg");
      position: relative;
      &-text {
        color: #fff;
        width: 460px;
        &--main {
          margin-left: -1.5rem;
          padding-top: 1rem;
          position: relative;
          h2 {
            font-size: 2.85rem;
            line-height: 4rem;
            background: #FE035A;
            text-align: center;
          }
          p {
            text-align: right;
            background: #5800E8;
            font-weight: 500;
            padding-right: 1.25rem;
          }
          &--leaf {
            position: absolute;
            bottom: -30px;
            left: 0;
            background: #1C0072;
            height: 30px;
            width: 30px;
            clip-path: polygon(0 0, 100% 0, 100% 100%);
          }
        }
        &--sub {
          padding: 1rem 1.5rem;
          width: 390px;
          line-height: 1.25rem;
          font-weight: 500;
          text-shadow: 2px 3px rgb(36 35 31 / 49%);
        }
        &--label {
          position: absolute;
          top: -10px;
          right: -15px;
          background: #8324FE;
          border-radius: 20px;
          font-weight: 500;
          color: #fff;
          padding: 0 1.5rem;
          border-bottom: 5px solid #561CA6;
          text-transform: uppercase;
        }
      }
      &-progress {
        width: 600px;
        margin: 0rem auto;
        position: relative;
        &-bar {
          background: #555562c9;
          width: calc(100% - 105px);
          margin-left: 45px;
          height: 1rem;
          position: absolute;
          top: 1rem;
          box-shadow: 3px 0px 10px 6px #ffffff87;
          &--fill {
            transition: all 0.5s;
            background: linear-gradient(to bottom, #ffb350, #d86801);
            height: 100%;
          }
        }
        &--item {
          &:first-child {
            position: relative;
            .events__component--header-progress--item-icon {
              margin-bottom: .3rem;
              border-radius: 50%;
            }
            .events__component--header-progress--item-label {
              background: #6363638c;
              color: #fff;
              border-radius: 20px;
              line-height: 1rem;
              width: 90px;
              margin-left: -30%;
              position: absolute;
              bottom: -1rem;
              left: 0;
            }
          }
          &-label {
            text-align: center;
            font-size: 0.8rem;
            font-weight: 600;
          }
          &-icon {
            background: linear-gradient(to top right, #49396e, #342732);
            border: 2px solid transparent;
            border-radius: 5px;
            height: 2.75rem;
            width: 2.75rem;
            overflow: hidden;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 3px solid #fff;
            position: relative;
            img {
              opacity: .2;
            }
            .reward-available {
              opacity: 1;
              cursor: pointer;
            }
            .fa-check {
              position: absolute;
              color: #00ffc7;
            }
          }
          &:last-child {
            .events__component--header-progress--item-icon {
              height: 3.5rem;
              width: 3.5rem;
              margin-top: -1rem;
              box-shadow: 0 0px 6px 5px #47fff2;
              img {
                width: 3rem;
              }
            }
          }
          img {
            width: 2rem;
          }
          i {
            font-size: 1.5rem;
          }
          &s {
            width: 100%;
            display: flex;
            justify-content: space-between;
          }
        }
      }
    }
    &--body {
      height: calc(100% - 350px);
      width: 100%;
      overflow: hidden;
      position: relative;
      &-mission-item {
        margin-bottom: .5rem;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 100px;
        background: #3f3750;
        color: #fff;
        border-radius: .5rem;
        text-shadow: 0px 2px #18171445;
        border: 3px solid #b3b3b3;
        &:last-child {
          margin-bottom: 0rem;
        }
        &--col {
          &-1, &-2, &-3 {
            display: flex;
            justify-content: center;
            align-items: center;
          }
          &-1, &-3 {
            width: 100px;
            height: 100%;
          }
          &-1 {
            background: linear-gradient(to bottom, #291B1A, #59507E);
            color: #fff;
            font-size: 2rem;
            border-top-left-radius: .3rem;
            border-bottom-left-radius: .3rem;
            position: relative;
            &--num {
              position: absolute;
              bottom: 0px;
              right: 8px;
              font-size: 1rem;
            }
          }
          &-2 {
            flex-direction: column;
            flex: 1;
            margin-left: 1rem;
            &--text {
              width: 100%;
              display: flex;
              justify-content: space-between;
              align-items: center;
              font-weight: 600;
            }
            &--progress {
              display: flex;
              width: 100%;
              justify-content: center;
              align-items: center;
              &-text {
                width: 45px;
                display: flex;
                justify-content: flex-end;
                align-items: center;
              }
              &-bar {
                height: 1rem;
                border-radius: 2rem;
                background: #1a1a1a5c;
                flex: 1;
                &--fill {
                  // width for mission completion
                  width: 50%;
                  height: 100%;
                  background: #ffb350;
                  border-radius: 2rem;
                }
              }
            }
          }
          &-3 {
            font-size: 2rem;
            color: #fff;
            .fa-gift {
              color: #ffb350;
              cursor: pointer;
            }
            .go-icon {
              display: flex;
              justify-content: center;
              align-items: center;
              font-weight: 600;
              font-size: 1.5rem;
              padding: 0.15rem 0.5rem;
              border-radius: .5rem;
              color: #fff;
              background: #50c09f;
              cursor: pointer;
              &:hover {
                box-shadow: 0 0px 1px 3px #ff00dd;
              }
            }
            .fa-check {
              color: #d1d1d1;
            }
          }
        }
        &s {
          height: 100%;
          width: 100%;
          padding: .5rem;
          overflow-y: auto;
          overflow-x: hidden;
          background: linear-gradient(to bottom, #fff, #6d7bc5);
        }
      }
    }
  }

  @media only screen and (max-width: 1366px),
  screen and (max-height: 870px)  {
    .events__component {
      width: 720px;
      &--btn-close {
        top: 1.75rem;
      }
      &--header {
        height: 300px;
        background-position: top;
        &-gradient-bg {
        }
        &-text {
          width: 400px;
          &--main {
            padding-top: .75rem;
            h2 {
              font-size: 2.5rem;
              line-height: 3.5rem;
            }
            p {
              font-size: .9rem;
            }
          }
          &--sub {
            width: 330px;
            font-size: .9rem;
            padding: .75rem 1rem;
          }
          &--label {
            font-size: .9rem;
          }
        }
        &-progress {
          width: 540px;
          &--item {
            &-icon {
              height: 2.5rem;
              width: 2.5rem;
            }
            i {
              font-size: 1.4rem;
            }
            img {
              width: 1.75rem;
            }
            &:last-child {
              .events__component--header-progress--item-icon {
                height: 3rem;
                width: 3rem;
                margin-top: -.8rem;
              }
            }
          }
        }
      }
      &--body {
        height: calc(100% - 300px);
        &-mission-item {
          height: 90px;
          &--col-1 {
            font-size: 1.9rem;
          }
          &--col-1, &--col-3 {
            width: 90px;
          }
          &--col-2 {
            &--text {
              font-size: .95rem;
              line-height: .9rem;
              margin-top: .5rem;
            }
            &--progress {
              &-bar {
                height: .9rem;
              }
            }
          }
        }
      }
    }
  }
  @media only screen and (max-width: 1150px),
  screen and (max-height: 720px) {
    .events__component {
      &--header {
        height: 280px;
        &-gradient-bg {
        }
        &-text {
          &--main {
            h2 {
              font-size: 2.25rem;
              line-height: 3rem;
            }
            p {
              font-size: .8rem;
            }
          }
          &--sub {
            width: 280px;
            font-size: .8rem;
            padding: .5rem .75rem;
          }
          &--label {
            font-size: .8rem;
          }
        }
        &-progress {
          &--item {
            &-label {
              font-size: .7rem;
            }
            &:last-child {
              .events__component--header-progress--item-icon {
                margin-top: -.5rem;
              }
            }
          }
        }
      }
      &--body {
        height: calc(100% - 280px);
        &-mission-item {
          height: 80px;
          &--col-1 {
            font-size: 1.8rem;
          }
          &--col-1, &--col-3 {
            width: 80px;
          }
          &--col-2 {
            &--text {
              font-size: .9rem;
              line-height: .8rem;
              margin-top: .5rem;
            }
            &--progress {
              &-bar {
                height: .8rem;
              }
            }
          }
        }
      }
    }
  }
  @media only screen and (max-width: 960px), screen and (max-height: 620px) {
    .events__component {
      width: 620px;
      margin: 0 2rem;
      &--header {
        height: 240px;
        &-gradient-bg {
        }
        &-text {
          width: 325px;
          &--main {
            h2 {
              font-size: 2rem;
              line-height: 2.5rem;
            }
            p {
              font-size: .75rem;
            }
          }
          &--sub {
            width: 260px;
            font-size: .75rem;
            line-height: .9rem;
            padding: .5rem .75rem;
          }
          &--label {
            font-size: .75rem;
          }
        }
        &-progress {
          width: 450px;
          &--item {
            &-icon {
              height: 2.25rem;
              width: 2.25rem;
            }
            &-label {
              font-size: .65rem;
            }
            i {
              font-size: 1.2rem;
            }
            img {
              width: 1.5rem;
            }
            &:last-child {
              .events__component--header-progress--item-icon {
                margin-top: -.5rem;
                height: 2.5rem;
                width: 2.5rem;
                img {
                  width: 2rem;
                }
              }
            }
          }
          &-bar {
            height: .75rem;
            width: calc(100% - 90px);
            margin-left: 40px;
          }
        }
      }
      &--body {
        height: calc(100% - 240px);
        &-mission-item {
          height: 65px;
          &--col-1 {
            font-size: 1.5rem;
            &--num {
              right: 6px;
              font-size: 0.8rem;
            }
          }
          &--col-1, &--col-3 {
            width: 65px;
          }
          &--col-2 {
            &--text {
              font-size: .8rem;
              line-height: .7rem;
              margin-top: .5rem;
            }
            &--progress {
              &-text {
                font-size: .9rem;
              }
              &-bar {
                height: .65rem;
              }
            }
          }
          &--col-3 {
            font-size: 1.5rem;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 635px) {
    .events__component {
      &--header {
        &-text {
          width: 275px;
          &--main {
            h2 {
              font-size: 1.75rem;
              line-height: 2.25rem;
            }
            p {
              font-size: .7rem;
            }
          }
          &--label {
            font-size: .7rem;
          }
        }
        &-progress {
          width: 400px;
        }
      }
      &--body {
        &-mission-item {
          &--col-1 {
            font-size: 1.4rem;
          }
          &--col-1, &--col-3 {
          }
          &--col-2 {
            &--text {
              font-size: .75rem;
              line-height: .7rem;
              margin-top: .5rem;
            }
          }
        }
      }
    }
  }
  @media only screen and (max-width: 520px) {
    .events__component {
      margin: 0 1rem;
      &--header {
        height: 210px;
        &-text {
          width: 230px;
          &--main {
            h2 {
              font-size: 1.5rem;
              line-height: 2rem;
            }
            p {
              font-size: .65rem;
            }
          }
          &--sub {
            width: 230px;
            font-size: .65rem;
            line-height: .8rem;
            padding: .5rem .75rem;
            text-shadow: 2px 1px rgb(36 35 31);
          }
          &--label {
            font-size: .65rem;
          }
        }
        &-progress {
          width: 300px;
          &--item {
            &-icon {
              height: 2.15rem;
              width: 2.15rem;
            }
            &-label {
              font-size: .65rem;
            }
            i {
              font-size: 1.1rem;
            }
            img {
              width: 1.3rem;
            }
            &:last-child {
              .events__component--header-progress--item-icon {
                margin-top: -.5rem;
                height: 2.5rem;
                width: 2.5rem;
                img {
                  width: 2rem;
                }
              }
            }
          }
          &-bar {
            height: .65rem;
          }
        }
      }
      &--body {
        height: calc(100% - 210px);
        &-mission-item {
          &--col-1, &--col-3 {
            width: 55px;
          }
          &--col-2 {
            &--text {
              font-size: .65rem;
              margin-top: .25rem;
            }
            &--progress {
              &-text {
                font-size: .75rem;
              }
              &-bar {
                height: .65rem;
              }
            }
          }
          &--col-3 {
            font-size: 1.5rem;
          }
        }
      }
    }
  }

</style>
