// import Vue from 'vue';
// import axios from 'axios';


/*
Workflow:
1) Student enters room pin (given by teacher during zoom session)
2) System validates the room pin is valid and returns a meeting hash
3) This is then used to enter the room
*/

const getDefaultState = () => {
  // note: index resets for every subtrack
  const results = {
    info: {},
  };

  return results;
};

const state = getDefaultState();

export const multiplayer = {
  namespaced: true,
  state,
  mutations: {
    setInfo(state, value) {
      state.info = value;
    },
  },
  actions: {
  },
  getters: {

  },
};
