<template>
  <block-container
    :title="title"
    :subtitle="subtitle"
    :fa-icon="faicon"
    previous-page-name="new-oral-overview"
    >
    <div class="body-content">
      <!-- for oral text-book choosing level -->
      <div v-if="this.category === 'oralTextbookChapter'"
        class="oral-level-btns">
        <div v-for="(level, index) in validLevels" :key="index"
          @click="clickLevel(level)"
          :class="{'oral-level-btn--active': selectedLevel === level }"
          class="oral-level-btn"
        >
          {{ level | shorternLevel }}
        </div>
      </div>
      <!-- for oral AI practice choosing filters -->
      <div v-if="this.category === 'aiPractise'"
        class="oral-ai-practise-btns">
        <div @click="aiQuestionType = 'all'"
          :class="{'oral-ai-practise-btn--active': aiQuestionType === 'all' }"
          class="oral-ai-practise-btn">
          全部
        </div>
        <div @click="aiQuestionType = 'personal_experience'"
          :class="{'oral-ai-practise-btn--active': aiQuestionType === 'personal_experience' }"
          class="oral-ai-practise-btn">
          个人经验
        </div>
        <div @click="aiQuestionType = 'suggestion'"
          :class="{'oral-ai-practise-btn--active': aiQuestionType === 'suggestion' }"
          class="oral-ai-practise-btn">
          建议
        </div>
        <div @click="aiQuestionType = 'opinion'"
          :class="{'oral-ai-practise-btn--active': aiQuestionType === 'opinion' }"
          class="oral-ai-practise-btn">
          意见
        </div>
        <div @click="changeSortingMethod"
          class="oral-ai-practise-btn--sort">
          <i class="fa-solid fa-filter"></i>
          <span>{{ sortingMethod | capitalize }}</span>
        </div>
      </div>
      <!-- content for all categories except ai practise -->
      <div v-if="this.category !== 'aiPractise'"
        class="oral-index__container"
        :class="{ 'oral-index__container--padding': this.category === 'oralTextbookChapter'}">
        <router-link
          v-for="(entity, index) in entities"
          :key="index"
          :to="getRoute(entity)"
          class="oral-index__container--item">
          <div class="user-settings-content">
            <div class="oral-index__container--item-icon">
              <i :class="'fa-' + faicon" class="fas icon-main"></i>
            </div>
            <div class="oral-index__container--text">
              <span v-if="category === 'aiPractise'" class="oral-index__container--text-title">
                {{ entity.title }}
              </span>
              <span v-if="category !== 'oralTextbookChapter' && category !== 'aiPractise'" class="oral-index__container--text-title">
                《{{ entity.title }}》
              </span>
              <span v-if="category === 'oralTextbookChapter' && category !== 'aiPractise'" class="oral-index__container--text-title">
                {{ index + 1 }}. {{ entity.title }}
              </span>
              <br>
            </div>
          </div>
        </router-link>
      </div>
      <!-- content for ai practise -->
      <div v-if="this.category === 'aiPractise'"
        class="ai-oral-index__container oral-index__container--padding">
        <router-link
          v-for="(entity, index) in filteredEntities"
          :key="index"
          :to="getRoute(entity)"
          class="ai-oral-index__container--item">
          <i class="fa-solid fa-comment"></i>
          <span class="ai-oral-index__container--item-divider">|</span>
          <span class="ai-oral-index__container--item-title">
            {{ entity.title }}
          </span>
          <span v-if="entity.id !== 'custom' && entity.attempts === 0"
            class="ai-oral-index__container--item-tag ai-oral-index__container--item-tag--new">
            New
          </span>
          <span v-if="entity.id !== 'custom' && entity.attempts > 0"
            class="ai-oral-index__container--item-tag ai-oral-index__container--item-tag--attempts">
            Attempts: {{ entity.attempts }}
          </span>
        </router-link>
      </div>
    </div>
  </block-container>
</template>

<script>

import BlockContainerComponent
  from '../common/BlockContainerComponent.vue';

import {mapState, mapMutations, mapGetters} from 'vuex';
import axios from 'axios';

export default {
  name: 'NewOralCategoryIndex',
  components: {
    BlockContainer: BlockContainerComponent,
  },
  props: {
    // passage, free response, etc
    category: String,
  },
  data() {
    return {
      entities: [],
      aiQuestionType: 'all',
      sortingMethod: 'attempts',
    };
  },
  computed: {
    ...mapState(['level']),
    ...mapState('guidedReading', ['selectedLevel', 'passages', 'freeResponseTopics', 'textbookChapters', 'templatedPhrases', 'activeVocabTopics']),
    ...mapGetters(['chineseLevel', 'validLevels']),
    // for ai practisce
    filteredEntities() {
      let toReturn;
      if (this.aiQuestionType === 'all') {
        toReturn = this.entities;
      } else {
        toReturn = this.entities.filter((entity) => {
          return entity.id === 'custom' || entity.question_category === this.aiQuestionType;
        });
      }
      if (this.sortingMethod === 'topic') {
        return toReturn.sort((a, b) => {
          if (b.id === 'custom') return 1;
          if (a.id === 'custom') return -1;
          return a.id - b.id;
        });
      }
      return toReturn.sort((a, b) => {
        if (b.id === 'custom') return 1;
        if (a.id === 'custom') return -1;
        return a.attempts - b.attempts;
      });
    },
    title() {
      if (this.category === 'readaloud') {
        return '短文';
      } else if (this.category === 'freeResponse') {
        return '会话题目';
      } else if (this.category === 'templatedPhrases') {
        return '会话模版';
      } else if (this.category === 'activeVocab') {
        return '会话词汇';
      } else if (this.category === 'oralTextbookChapter') {
        return '朗读课本';
      } else if (this.category === 'aiPractise') {
        return 'AI会话练习';
      }
      return '';
    },
    subtitle() {
      if (this.category === 'readaloud') {
        return 'Readaloud';
      } else if (this.category === 'freeResponse') {
        return 'Free Response';
      } else if (this.category === 'templatedPhrases') {
        return 'Templates';
      } else if (this.category === 'activeVocab') {
        return 'Active Vocab';
      } else if (this.category === 'oralTextbookChapter') {
        return 'Textbook Revision';
      }
      return '';
    },
    faicon() {
      if (this.category === 'readaloud') {
        return 'newspaper';
      } else if (this.category === 'freeResponse') {
        return 'comments';
      } else if (this.category === 'templatedPhrases') {
        return 'thumbs-up';
      } else if (this.category === 'activeVocab') {
        return 'book';
      } else if (this.category === 'oralTextbookChapter') {
        return 'book-open';
      } else if (this.category === 'aiPractise') {
        return 'comments';
      }
      return '';
    },

  },
  mounted() {
    if (this.selectedLevel === '') {
      this.setSelectedLevel(this.level);
    }
    if (this.category === 'readaloud') {
      this.pullReadAloud();
    } else if (this.category === 'freeResponse') {
      this.pullFreeResponse();
    } else if (this.category === 'oralTextbookChapter') {
      this.pullTextbookChapter(this.selectedLevel);
    } else if (this.category === 'activeVocab') {
      this.pullActiveVocabTopics();
    } else if (this.category === 'templatedPhrases') {
      this.pullTemplatedPhrases();
    } else if (this.category === 'aiPractise') {
      this.pullAiPractise();
    };
  },
  methods: {
    ...mapMutations(['setShowGlobalLoadingScreen']),
    ...mapMutations('guidedReading', ['setSelectedLevel', 'setPassages', 'setFreeResponseTopics', 'setFreeResponseQuestions', 'setTextbookChapters', 'setTemplatedPhrasesTopics', 'setActiveVocabTopics']),
    // for readaloud, we want to go directly to passage
    // for free response, we want to go to a subtopic page
    getRoute(entity) {
      if (this.category === 'readaloud') {
        return {
          name: 'oral-new-passage',
          params: {
            type: this.category,
            entityId: entity.id,
          },
        };
      } else if (this.category === 'freeResponse') {
        return {
          name: 'oral-free-response-topic',
          params: {
            type: this.category,
            topicId: entity.id,
          },
        };
      } else if (this.category === 'oralTextbookChapter') {
        return {
          name: 'new-oral-textbook-chapter-topic',
          params: {
            type: this.category,
            topicId: entity.id,
          },
        };
      } else if (this.category === 'templatedPhrases') {
        return {
          name: 'oral-templated-phrases-topic',
          params: {
            type: this.category,
            topicId: entity.id,
          },
        };
      } else if (this.category === 'activeVocab') {
        return {
          name: 'new-oral-picture-vocab-quiz',
          params: {
            topicId: entity.id,
          },
        };
      } else if (this.category === 'aiPractise') {
        return {
          name: 'new-oral-ai-practice',
          params: {
            questionId: entity.id.toString(),
            subject: entity.subject,
          },
        };
      }
    },
    clickLevel(level) {
      if (level === this.selectedLevel) return;
      this.setSelectedLevel(level);
      this.pullTextbookChapter(level);
    },
    pullReadAloud() {
      // checks to see if we need to pull passages
      if (!this.passages || this.passages.length === 0) {
        this.setShowGlobalLoadingScreen(true);
        axios
            .post(`/vocab/oralpassage/level/`, {level: this.level})
            .then((response) => {
              this.setPassages(response.data);
              this.entities = response.data;
              this.setShowGlobalLoadingScreen(false);
            });
      } else {
        this.entities = this.passages;
      }
    },
    pullFreeResponse() {
      if (!this.freeResponseTopics || this.freeResponseTopics.length === 0) {
        this.setShowGlobalLoadingScreen(true);
        axios
            .get(`/vocab/oralfreeresponse/`)
            .then((response) => {
              this.setFreeResponseTopics(response.data);
              this.entities = response.data;
              this.setShowGlobalLoadingScreen(false);
            });
      } else {
        this.entities = this.freeResponseTopics;
      }
    },
    pullAiPractise() {
      if (!this.freeResponseTopics || this.freeResponseTopics.length === 0) {
        this.setShowGlobalLoadingScreen(true);
        axios
            .get('/vocab/oralfreeresponse/questions/')
            .then((response) => {
              const toAdd = response.data;
              toAdd.unshift({
                title: 'Type in your own Chinese question',
                id: 'custom',
                subject: 'chinese',
                // placeholder to ensure it is always at the top
                attempts: 9999999999999999,
              });
              this.setFreeResponseQuestions(toAdd);
              this.entities = toAdd;
              console.log(this.entities);
              this.setShowGlobalLoadingScreen(false);
            });
      } else {
        this.entities = this.freeResponseTopics;
      }
    },
    pullActiveVocabTopics() {
      if (!this.activeVocabTopics || this.activeVocabTopics.length === 0) {
        this.setShowGlobalLoadingScreen(true);
        axios
            .get(`/vocab/oralactivevocab/`)
            .then((response) => {
              console.log(response.data);
              this.setActiveVocabTopics(response.data);
              this.entities = response.data;
              this.setShowGlobalLoadingScreen(false);
            });
      } else {
        this.entities = this.activeVocabTopics;
      }
    },
    // kiv - determine if there's a textbok level change
    pullTextbookChapter(level) {
      if (!this.textbookChapters || this.textbookChapters.length === 0 || this.textbookChapters[0].level !== level) {
        this.setShowGlobalLoadingScreen(true);
        axios
            .post(`/vocab/oraltextbookchapter/level/`, {level: level})
            .then((response) => {
              this.setTextbookChapters(response.data);
              this.entities = response.data;
              this.setShowGlobalLoadingScreen(false);
            });
      } else {
        this.entities = this.textbookChapters;
      };
    },
    pullTemplatedPhrases() {
      if (!this.templatedPhrases || this.templatedPhrases.length === 0) {
        this.setShowGlobalLoadingScreen(true);
        axios
            .post(`/vocab/templatedphrases/`)
            .then((response) => {
              this.setTemplatedPhrasesTopics(response.data);
              this.entities = response.data;
              this.setShowGlobalLoadingScreen(false);
            });
      } else {
        this.entities = this.templatedPhrases;
      };
    },
    changeSortingMethod() {
      if (this.sortingMethod === 'topic') {
        this.sortingMethod = 'attempts';
      } else if (this.sortingMethod === 'attempts') {
        this.sortingMethod = 'topic';
      };
    },
  },
};

</script>

<style lang="scss" scoped>
  .body-content {
    height: 100%;
    width: 100%;
    overflow: hidden;
    position: relative;
  }
  .oral-level {
    &-btn {
      border-bottom: 4px solid #193295;
      border-right: 4px solid #193295;
      border-top: 4px solid #5879fe;
      border-left: 4px solid #5879fe;
      color: #fff;
      background: #2c57ff;
      padding: .1rem;
      width: 13%;
      border-radius: 0.5rem;
      font-size: .9rem;
      margin-right: .5rem;
      text-align: center;
      cursor: pointer;
      &:last-child {
        margin-right: 0;
      }
      &:hover {
        box-shadow: 0 0px 1px 3px #ff00dd;
      }
      &--active {
        color: #fff;
        background: #ffa025;
        font-weight: 600;
        border-top: 4px solid #bf6800;
        border-left: 4px solid #bf6800;
        border-bottom: 4px solid #ffe758;
        border-right: 4px solid #ffe758;
        box-shadow: 0 0px 1px 3px #00ffea;
        &:hover {
          box-shadow: 0 0px 1px 3px #00ffea;
        }
      }
    }
    &-btns {
      display: flex;
      margin-top: 1rem;
      margin-bottom: 1rem;
      justify-content: center;
      align-items: center;
    }
  }
  .oral-ai-practise {
    &-btn {
      font-weight: 800;
      border-bottom: 4px solid #193295;
      border-right: 4px solid #193295;
      border-top: 4px solid #5879fe;
      border-left: 4px solid #5879fe;
      color: #fff;
      background: #2c57ff;
      padding: .1rem .5rem;
      min-width: 80px;
      border-radius: 0.5rem;
      font-size: .8rem;
      margin-right: .5rem;
      text-align: center;
      cursor: pointer;
      &--sort {
        margin-left: auto;
        color: #fff;
        border-bottom: 2px dotted;
        cursor: pointer;
      }
      &:last-child {
        margin-right: 0;
      }
      &:hover {
        box-shadow: 0 0px 1px 3px #ff00dd;
      }
      &--active {
        color: #fff;
        background: #ffa025;
        // font-weight: 600;
        border-top: 4px solid #bf6800;
        border-left: 4px solid #bf6800;
        border-bottom: 4px solid #ffe758;
        border-right: 4px solid #ffe758;
        box-shadow: 0 0px 1px 3px #00ffea;
        &:hover {
          box-shadow: 0 0px 1px 3px #00ffea;
        }
      }
    }
    &-btns {
      display: flex;
      margin-top: 1rem;
      margin-bottom: 1rem;
      justify-content: flex-start;
      align-items: center;
      gap: .25rem;
      flex-wrap: wrap;
      margin-left: 2rem;
      margin-right: 2rem;
    }
  }
  .oral-index {
    &__container {
      height: 100%;
      padding: 2rem;
      width: 100%;
      overflow-y: auto;
      overflow-x: hidden;
      position: absolute;
      text-align: center;
      grid-gap: 1rem;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      justify-content: center;
      width: 100%;
      grid-gap: 1.5rem;
      &--padding {
        padding: 0rem 2rem 6rem 2rem;
      }
      &--item {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        background: #fff;
        border-radius: 7.5px;
        text-decoration: none;
        border: 6px solid transparent;
        transition: all 0.2s;
        position: relative;
        &:hover {
          background: #fff;
          .oral-index__container--item-icon {
            background: #fff;
            border: 6px solid #2b92f8;
            i {
              color: #2b92f8;
            }
          }
        }
        .user-settings-content {
          height: 100%;
          width: 100%;
        }
        a {
          text-decoration: none;
        }
        &-icon {
          height: 75%;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 6px solid #e9e9e9;
          margin: .5rem .5rem;
          transition: all 0.2s;
          padding: 1rem;
          border-radius: 0.5rem;
          background: #fff;
          i {
            transition: all 0.2s;
            color: #b9b6c1;
            font-size: 3.5rem;
            margin-bottom: 5px;
          }
        }
      }
      &--text {
        width: 100%;
        height: calc(25% - 1rem);
        display: flex;
        justify-content: center;
        align-items: center;
        &-title {
          font-weight: 600;
          font-size: 1rem;
          color: #4e4e54;
        }
      }
    }
  }
  .ai-oral-index {
    &__container {
      max-height: calc( 100% - 83px);
      overflow-y: auto;
      overflow-x: hidden;
      position: absolute;
      text-align: center;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      width: 100%;
      gap: 1rem;
      flex-wrap: wrap;
      padding: 0rem 2rem 1.5rem 2rem;
      &--item {
        text-align: left;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        background: #fff;
        border-radius: 7.5px;
        text-decoration: none;
        transition: all 0.2s;
        position: relative;
        width: 100%;
        // flex-basis: 48.5%;
        font-weight: 600;
        font-size: 1rem;
        color: #4e4e54;
        padding: .25rem .5rem;
        border: 4px solid transparent;
        &:first-of-type {
          .ai-oral-index__container--item-tag {
            display: none;
          }
        }
        &-divider {
          margin: 0 .5rem;
        }
        &-tag {
          margin-left: auto;
          font-size: .8rem;
          padding: .1rem 0.5rem;
          border-radius: .25rem;
          color: #fff;
          &--new {
            background: #36d4ac;
          }
          &--attempts {
            background: #d825ff;
          }
        }
        &:hover {
          color: #2b92f8;
          border: 4px solid #2b92f8;
        }
      }
    }
  }

  .num-words {
    font-size: 0.8em;
  }

  @media only screen and (max-width: 1560px) {
    .oral-index__container {
      grid-template-columns: 1fr 1fr;
    }
  }
  @media only screen and (max-width: 1366px),
              screen and (max-height: 870px) {
    .oral-index {
      &__container {
        padding: 1.5rem;
        grid-gap: 1.25rem;
        &--padding {
          padding: 0rem 1.5rem 1rem 1.5rem;
        }
        &--item {
          padding: .25rem;
          &-icon {
            padding: .75rem;
            i {
              font-size: 3rem;
            }
          }
        }
        &--text {
          &-title {
            font-size: .9rem;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 1150px),
  screen and (max-height: 690px) {
    .ai-oral-index__container {
      gap: .75rem;
    }
    .ai-oral-index__container--item {
      font-size: .85rem;
      &-tag {
        font-size: .7rem;
      }
    }
    .oral-ai-practise-btn {
      padding: .1rem .25rem;
      font-size: .7rem;
      min-width: 60px;
    }
  }
  @media only screen and (max-width: 960px),
  screen and (max-height: 620px) {
    .oral-index {
      &__container {
        background: none;
        width: 100%;
        height: 100%;
      }
    }
  }
  @media only screen and (max-width: 690px) {
    .oral-index__container {
      grid-template-columns: 1fr;
    }
  }
  @media only screen and (max-width: 580px) {
    .oral-index__container {
      grid-gap: 1rem;
    }
    .oral-index {
      &__container {
        &--padding {
          padding: 0 .75rem 2.5rem .75em;
        }
      }
    }
  }
  @media only screen and (max-width: 480px) {
    .oral-index__container--item-icon {
      padding: .5rem;
      i {
        font-size: 2.5rem;
      }
    }
    .oral-index__container--text-title {
      font-size: .8rem;
    }
  }
  @media only screen and (max-height: 720px) {
    .ai-oral-index__container--item {
      font-size: .85rem;
      &-tag {
        font-size: .7rem;
      }
    }
    .oral-ai-practise-btn {
      padding: .1rem .25rem;
      font-size: .7rem;
      min-width: 60px;
    }
  }
</style>
