<!-- note: this is a reusable leaderboard component
not a route page; this is different from mainleaderboard, which
is a route page
 -->
<template>
  <div class="leaderboard__component">
    <!-- <h1 class="leaderboard__title">{{ title }}</h1> -->
    <!-- <div class="leaderboard__heading">
      <p class="leaderboard__heading--1">Rank</p>
      <p class="leaderboard__heading--2">Name</p>
      <p class="leaderboard__heading--3">{{ typeOfLeaderboard }}</p>
    </div> -->
    <leaderboard-row
      v-for="student in leaderboardData"
      :key="student[0]"
      :current-student="studentId === student[1]"
      :rank="student[0]"
      :name="studentMap[student[1]]"
      :score="student[2]"
      :type-of-leaderboard="typeOfLeaderboard"
      class="leaderboard__row animated flipInX">
    </leaderboard-row>
  </div>
</template>

<script>
import {mapState} from 'vuex';

import LeaderboardRowComponent
  from './LeaderboardRowComponent.vue';

export default {
  name: 'LeaderboardMain',
  components: {
    LeaderboardRow: LeaderboardRowComponent,
  },
  props: {
    // array of tuples (array in javascript)
    // tuple has 3 elements - student_rank,student_id, score)
    leaderboardData: Array,
    studentMap: Object,
    title: String,
    // what is being tracked - e.g. Scores or Coins
    typeOfLeaderboard: String,
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapState(['studentId']),
  },
  mounted: function() {
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
  .leaderboard__component {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  // .leaderboard__heading {
  //   display: flex;
  //   text-align: center;
  //   font-size: 1.2rem;
  //   font-weight: bold;
  //   margin-bottom: 10px;
  //   &--1 {
  //     width: 75px;
  //     margin-right: 10px;
  //     border-right: 2px solid #e8e8e8;
  //   }
  //   &--2 {
  //     text-align: left;
  //     margin-left: 10px;
  //     width: 440px;
  //   }
  //   &--3 {
  //     border-left: 2px solid #e8e8e8;
  //     width: 100px;
  //   }
  // }
  // .leaderboard__title {
  //   margin-bottom: 10px;
  // }
  // @media only screen and (max-width: 800px) {
  //   .leaderboard__heading {
  //     font-size: 1rem;
  //     &--1 {
  //       width: 50px;
  //     }
  //     &--2 {
  //       width: 350px;
  //     }
  //     &--3 {
  //       width: 90px;
  //     }
  //   }
  //   .leaderboard__title {
  //     font-size: 1.75rem;
  //   }
  // }
  // @media only screen and (max-width: 660px) {
  //   .leaderboard__heading {
  //     &--2 {
  //       width: 250px;
  //     }
  //   }
  // }
  // @media only screen and (max-width: 550px) {
  //   .leaderboard__heading {
  //     &--2 {
  //       width: 175px;
  //     }
  //   }
  //   .leaderboard__title {
  //     font-size: 1.5rem;
  //   }
  // }
  // @media only screen and (max-width: 430px) {
  //   .leaderboard__heading {
  //     &--2 {
  //       width: 150px;
  //     }
  //   }
  // }
</style>
