import VueScrollTo from 'vue-scrollto';

export const utilities = {
  methods: {
    // https://forum.vuejs.org/t/scroll-position-seems-to-not-work-in-my-app/16147/5
    // on scrolling, note that setting scrollTo(0, 0) won't work
    // since it will just scroll to the top of the page (which is the header)
    // rather than making the app body scroll top
    scrollToTop(element) {
      const options = {
        // set this to #app by default
        container: '#app',
        easing: 'ease-in',
        offset: -100,
        force: true,
        cancelable: true,
        onStart: function(element) {
          // scrolling started
        },
        onDone: function(element) {
          // scrolling is done
        },
        onCancel: function() {
          // scrolling has been interrupted
        },
        x: false,
        y: true,
      };
      VueScrollTo.scrollTo(element, 15, options);
    },
    checkMobileBrowser() {
      const ua = window.navigator.userAgent;
      const iPhone = ua.indexOf('iPhone') !== -1
        || ua.indexOf('iPod') !== -1;
      const iPad = ua.indexOf('iPad') !== -1;
      const iOs = iPhone || iPad;
      const android = ua.indexOf('Android') !== -1;

      return {
        iOs: iOs,
        android: android,
      };
    },
    /* Randomize array element order in-place.
     * Using Fisher-Yates shuffle algorithm.
     */
    shuffleArray: function(arr) {
      for (let i = arr.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        const temp = arr[i];
        arr[i] = arr[j];
        arr[j] = temp;
      }
      return arr;
    },
  },
};


