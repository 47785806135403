<!--
  generic game result component
  should be usable for different games
 -->
<template>
  <div class="game-summary__component">
    <div class="game-summary__content">
      <div class="game-summary__header">
        <div class="game-summary__header--text">
          <h1 v-if="summaryActive === 'game-stats'">Your Final Score</h1>
          <h1 v-if="summaryActive === 'high-score'">High Scores</h1>
        </div>
        <div class="game-summary__btns">
          <button
            @click.prevent="summaryActive = 'game-stats'"
            :class="{ active: isActive('game-stats') }"
            class="game-summary__btn">
            My Score
          </button>
          <button
            @click.prevent="summaryActive = 'high-score'"
            :class="{ active: isActive('high-score') }"
            class="game-summary__btn">
            High Score
          </button>
        </div>
      </div>
      <!-- game-stats component -->
      <div v-if="summaryActive === 'game-stats'"
        class="game-summary__game-stats">
        <span class="game-summary__game-stats--level">
          LEVEL {{ currentLevel }}
        </span>
        <span class="game-summary__game-stats--title">
          Game Over!
        </span>
        <span class="game-summary__game-stats--score">
          <i class="fa fa-trophy"></i>
          {{ currentScore }}
        </span>
<!--         <span class="game-summary__game-stats--stars">
          <i class="fas fa-star"></i>
          <i class="fas fa-star"></i>
          <i class="fas fa-star"></i>
        </span> -->
      </div>
      <!-- leaderboard component -->
      <leaderboard-main
        v-if="summaryActive === 'high-score'"
        :leaderboard-data="leaderboardData"
        :student-map="studentMap"
        :title="'High Scores'"
        :type-of-leaderboard="'Scores'"
        class="game-summary__leaderboard">
      </leaderboard-main>
      <!-- Reward btn -->
      <div class="game-summary__btn--next">
        <a
          v-if="claimedRewardStatus === 0"
          @click="claimRewards">
          Claim my Rewards!
        </a>
        <router-link
          v-if="claimedRewardStatus === 2"
          class="game-flashcard-memory__component--close"
          :to="{name: 'profile-main'}">
          Main Menu
        </router-link>
      </div>
    </div>
    <game-completion-screen
      v-if="claimedRewardStatus === 1"
      :new-coins="newCoins"
      :coins-to-add="coinsToAdd"
      :new-diamonds="newDiamonds"
      :diamonds-to-add="diamondsToAdd"
      :loot="loot"
      :end-game-callback="endGameCallback">
    </game-completion-screen>
  </div>
</template>

<script>

import LeaderboardMainComponent
  from '../common/leaderboard/LeaderboardMainComponent.vue';

import GameCompletionScreenComponent from './GameCompletionScreenComponent.vue';

export default {
  name: 'GameSummary',
  components: {
    LeaderboardMain: LeaderboardMainComponent,
    GameCompletionScreen: GameCompletionScreenComponent,
  },
  props: {
    // array of tuples (array in javascript)
    // tuple has 3 elements - student_rank,student_id, score)
    leaderboardData: Array,
    studentMap: Object,
    currentLevel: Number,
    currentScore: Number,
    newCoins: Number,
    coinsToAdd: Number,
  },
  data() {
    return {
      // 0 === not claimed, 1 === claiming, 2 === claimed
      claimedRewardStatus: 0,
      showRewardsScene: false,
      hasClaimedRewards: false,
      summaryActive: 'game-stats',
    };
  },
  computed: {
  },
  mounted: function() {
  },
  methods: {
    claimRewards() {
      console.log('claiming rewards');
      // can only claim rewards once
      if (this.claimedRewardStatus > 0) return;

      // set to claiming rewards
      this.claimedRewardStatus = 1;
    },
    endGameCallback() {
      // rewards are claimed
      this.claimedRewardStatus = 2;
    },
    isActive(value) {
      return this.summaryActive === value;
    },
  },
};
</script>

<style lang="scss" scoped>
  .game-summary {
    &__component {
      background: radial-gradient(#40c0f1, #302792);
      min-height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
    &__header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
      max-width: 700px;
      &--text {
        margin-right: 60px;
        text-transform: uppercase;
        color: #fff;
        font-family: 'baloo da 2';
      }
    }
    &__btns {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &__btn {
      padding: 0.25rem 1rem;
      background: #3e3e3e;
      border: 4px solid #222;
      color: #fff;
      font-family: 'Baloo Da 2';
      font-size: 1rem;
      opacity: 50%;
      cursor: pointer;
      font-weight: 700;
      &:focus {
        outline: none;
      }
      &--next {
        cursor: pointer;
        text-align: center;
        margin-top: 30px;
        a {
          border-radius: 5px;
          padding: 0.25rem 2rem;
          background: #3d80ff;
          border: 4px solid #222;
          color: #fff;
          font-weight: 700;
          font-family: 'Baloo Da 2';
          text-decoration: none;
          display: inline-block;
        }
      }
    }
    &__game-stats {
      font-family: 'baloo da 2';
      color: #fff;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      padding: 2rem;
      margin: 0 6rem;
      border-radius: 5px;
      border: 3px solid #000;
      background:
        radial-gradient(circle, #737373 -20%, #353535 85%);
      &--level {
        font-size: 1.3rem;
        font-weight: 600;
        color: #8ca4ff;
      }
      &--title {
        color: #00feff;
        font-weight: 700;
        font-size: 3rem;
        margin-top: -20px;
      }
      &--score {
        width: 100%;
        text-align: center;
        // border-bottom: 3px solid #0003;
        border-top: 3px solid #0003;
        font-size: 6rem;
        font-weight: 500;
        i {
          font-size: 4.5rem;
          color: #ffbe43;
        }
      }
      &--stars {
        font-size: 4rem;
        color: rgba(255,255,255,0.2);
        i {
          color: #ffbe43;
        }
      }
    }
  }
  .active {
    background: #ff733d;
    border: 4px solid #000;
    opacity: 100%;
  }

  @media only screen and (max-width: 720px) {
    .game-summary {
      &__header {
        flex-direction: column;
        align-items: center;
        &--text {
          margin-right: 0;
        }
      }
      &__game-stats {
        margin: 0 1rem;
        &--title {
          font-size: 2.3rem;
        }
        &--score {
          font-size: 4.5rem;
          i {
            font-size: 3.5rem;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 375px) {
    .game-summary {
      &__header--text {
        font-size: 0.8rem;
      }
      &__btn {
        padding: 0 0.75rem;
        font-size: 0.85rem;
        &--next {
          margin-top: 15px;
        }
      }
      &__game-stats {
        margin: 0 0.5rem;
        padding: 1rem;
        &--title {
          font-size: 1.8rem;
        }
      }
    }
  }
</style>
