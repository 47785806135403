import Vue from 'vue';
import axios from 'axios';


const getDefaultState = () => {
  // note: index resets for every subtrack
  const results = {
    dailyQuizInfo: {},
    selectedDailyChallengeUnlockedDate: null,
  };

  return results;
};

const state = getDefaultState();

// changed name to dailyChallengeModule rather than dailyChallenge
// since it was easy to accidentally name a variable dailyChallenge
export const dailyChallengeModule = {
  namespaced: true,
  state,
  mutations: {
    resetStateMutation(state) {
      Object.assign(state, getDefaultState());
    },
    setDailyQuizInfo(state, value) {
      state.dailyQuizInfo = value;
    },
    setSelectedDailyChallengeUnlockedDate(state, value) {
      state.selectedDailyChallengeUnlockedDate = value;
    },
    markWeeklyRewardClaimed(state) {
      Vue.set(state.dailyQuizInfo, 'weeklyRewardClaimed', true);
    },
    // update the stars for a unlocked date daily quiz if it is higher
    updateDailyQuizStarsIfBetter(state, starsFromQuiz) {
      if (!state.selectedDailyChallengeUnlockedDate) return;

      if (starsFromQuiz <=
        state.dailyQuizInfo.stars[state.selectedDailyChallengeUnlockedDate]) {
        return;
      };

      Vue.set(state.dailyQuizInfo.stars,
          state.selectedDailyChallengeUnlockedDate, starsFromQuiz);
    },
    markDailyQuizCompleted(state) {
      const unlockedDate = state.selectedDailyChallengeUnlockedDate;
      Vue.set(state.dailyQuizInfo.weeklySummary[unlockedDate],
          'completed', true);
    },
  },
  actions: {
    collectWeeklyReward: ({commit, state, rootState}, payload) => {
      return new Promise((resolve, reject) => {
        const url = '/api/v1/core/student/' +
          rootState.studentId + '/weeklyreward/';

        // https://stackoverflow.com/questions/44618440/vuex-how-to-commit-a-global-mutation-in-a-module-action
        // how to call a global mutation from module

        axios
            .post(url)
            .then((response) => {
              commit('markWeeklyRewardClaimed');
              // coinTransactions (over last 7 days) and
              // coins need to be added separately
              commit('addCoinTransaction', {
                category: response.data['transactionCategory'],
                coinsToAdd: response.data['coinsToAdd'],
              }, {root: true});
              commit('setCoins', response.data['coins'], {root: true});
              resolve(response.data);
            })
            .catch((error) => {
              alert('Error claiming reward. Please try again later.');
              reject(error);
            });
      });
    },
  },
  getters: {

  },
};
