import Vue from 'vue';
import axios from 'axios';


// authentication related actions
// can take an optional argument (token), and if passed in, will use that token
// otherwise, will search in localStorage
export const loadToken = ({commit, state}, token) => {
  // for when user passes in token
  if (token && token !== '') {
    localStorage.setItem('user-token', token);
  };

  // get token from localStorage
  const userToken = localStorage.getItem('user-token') || '';
  return new Promise((resolve, reject) => {
    if (userToken === '') {
      reject(new Error('Not authenticated yet.'));
    } else {
      // if user-token exists
      axios.defaults.headers.common['Authorization'] = 'Token '
        + userToken;
      axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN';
      axios.defaults.xsrfCookieName = 'csrftoken';
      axios.defaults.withCredentials = true;
      resolve();
    };
  });
};

// updates notifications object
export const setNotificationAsSeenOrCreate = ({commit, state, getters},
    payload) => {
  const category = payload.category;
  const subCategory = payload.sub_category;

  let index = state.notifications.findIndex((notification) => {
    return notification.category === category &&
      notification.sub_category === subCategory;
  });

  // if no entries are found, we push instead
  index = index === -1 ? state.notifications.length : index;

  Vue.set(state.notifications, index, payload);
};

// https://tahazsh.com/vuebyte-reset-module-state
// https://github.com/vuejs/vuex/issues/1118#issuecomment-376973664
export const resetState = ({commit, state}) => {
  console.log('resetting state');
  commit('resetStateMutation');
  commit('library/resetStateMutation');
  commit('quiz/resetStateMutation');
  commit('pet/resetStateMutation');
  commit('learningTrack/resetStateMutation');
  commit('dailyChallenge/resetStateMutation');
  commit('setFetchingInitialData', false);
  // when we logout, remove token from localStorage
  // also clear axios
  localStorage.removeItem('user-token');
  axios.defaults.headers.common['Authorization'] = '';
};

export const pullData = ({commit, dispatch}) => {
  commit('setFetchingInitialData', true);
  return axios.get('/api/v1/core/student/load/')
      // initial load of information on student
      .then((response) => {
        console.log(response.data);
        commit('setFetchingInitialData', false);
        commit('setShowGlobalLoadingScreen', false);
        // resetting state
        commit('library/resetStateMutation');
        commit('quiz/resetStateMutation');
        commit('learningTrack/resetStateMutation');
        commit('dailyChallenge/resetStateMutation');
        commit('raid/resetStateMutation');
        commit('guidedReading/resetStateMutation');
        commit('setMissions', response.data['missions']);
        commit('setChallenges', response.data['challenges']);

        // to be removed
        commit('setNotifications', response.data['notifications']);
        commit('setUsedFeatures', response.data['used_features']);
        commit('setStudentId', response.data['id']);
        commit('setSubject', response.data['subject']);
        commit('setStudentName', response.data['display_name']);
        // commit('setRemainingDailyAttempts', response.data['remaining_daily_attempts']);
        commit('setParentEmail', response.data['parent_email']);
        commit('setStudentType', response.data['student_type']);
        commit('setIsStoreEnabled', response.data['is_store_enabled']);
        commit('setListLevelChapters', response.data['list_level_chapters']);
        // to remove in future
        // commit('setSeenFeatures', response.data['seen_features']);
        // commit('setFeatureDescriptions', response.data['feature_descriptions']);
        commit('setSiblings', response.data['siblings']);
        commit('setDiamonds', response.data['diamonds']);
        commit('setCurrentDifficulty', response.data['difficulty']);
        commit('setCoins', response.data['coins']);
        commit('setTotalKnownWords', response.data['total_known_words']);
        commit('setLoginStreak', response.data['login_streak']);
        // (nov 16 2023) this will be replaced by quizzes_completed_info in future
        commit('setChineseQuizStars', response.data['chinese_quiz_stars']);
        commit('setQuizzesCompletedInfo', response.data['quizzes_completed_info']);
        commit('setWatchedPrimarySchoolLessonVideos',
            response.data['watched_primary_school_lesson_videos']);
        commit('setWatchedPreschoolLessonVideos',
            response.data['watched_preschool_lesson_videos']);
        commit('setAvatar', response.data['avatar']);
        commit('setLevel', response.data['level']);
        commit('pet/setActivePet', response.data['active_pet'] || null);
        commit('pet/setGameValues', response.data['game_values']);
        commit('analytics/setWordlistIds', response.data['wordlist_ids']);
        commit('setCocos', response.data['cocos']);
        commit('setVocabSubscription', response.data['vocab_subscription']);
        commit('setQuests', response.data['quests']);
        commit('setCoinTransactions', response.data['coin_transactions']);
        commit('setEnglishSubtitlesForLibrary',
            response.data['english_subtitles_for_library']);

        commit('widgets/setSpeechAnalytics', response.data['speech_analytics']);
        commit('widgets/setCoinLeaderboard', response.data['coin_leaderboard']);
        // // sets library related data
        // // storyarcs contains limited info on the available storyarcs
        // // does not contain the words, etc
        commit('library/setStoryarcs', response.data['storyarcs']);
        commit('library/setLatestStory', response.data['latest_story']);
        commit('setAuthenticated', true);

        // set dailchallenge related data
        commit('dailyChallenge/setDailyQuizInfo',
            response.data['daily_quiz_info']);
      })
      .catch((error) => {
        commit('setFetchingInitialData', false);
        throw error;
      });
};

// other actions
export const addCoinTransaction = ({commit, state}, payload) => {
  if (payload.category === 'dailyChallenge') {
    commit('addCoinTransaction', {
      category: 'daily_challenge',
      coinsToAdd: payload.coinsToAdd,
    });
  } else if (payload.category === 'story') {
    commit('addCoinTransaction', {
      category: 'online_quiz',
      coinsToAdd: payload.coinsToAdd,
    });
  };
};

export const sendNotifyTokenToServer = ({commit, dispatch}, notifyToken) => {
  const currentToken = localStorage.getItem('firebase-notify-token') || '';
  if (currentToken && currentToken === notifyToken) {
    return;
  }
  return axios.post('/api/v1/core/devices/', {
    'registration_id': notifyToken,
    'type': 'web',
  }).then((response) => {
    localStorage.setItem('firebase-notify-token', notifyToken);
  }).catch((error) => {
    throw error;
  });
};
