<template>
  <div id="main">
    <div v-if="latestStory"
      class="row latest-story-container">
      <div class="latest-story-desc-container">
        <h4>this week's <span>new story</span></h4>
        <h2 class="latest-story-title chinese-character">
          {{ latestStory.chineseTitle }}
        </h2>
        <h6>{{ latestStoryarc.title }} <span>|</span>
          episode {{ latestStory.index + 1 }}</h6>
        <p class="latest-story-desc">{{ latestStory.description }}</p>
        <div class="latest-story-buttons">
          <router-link
            class="play-btn"
            :to="{name: 'library-story-latest'}">
            <i class="fas fa-play"></i>
            play
          </router-link>
          <router-link
            class="show-episode-btn"
            :to="{name: 'library-storyarc-main',
              params: {storyarcId: latestStoryarc.pk}}">
            <i class="fas fa-list-ul"></i>
            other episodes
          </router-link>
        </div>
      </div>

      <router-link
        class="latest-story-thumbnail-container"
        :to="{name: 'library-story-latest'}">
        <div class="thumbnail-vignette"></div>
        <img
          :src="latestStory.thumbnail"
          class="latest-story-thumbnail-img" />
        <div class="play-icon-container">
          <i class="fas fa-play"></i>
        </div>
      </router-link>

    </div>
    <div class="row library-container">
      <div class="library-header">
        <h2>
          <i class="fas fa-book"></i>library
        </h2>
      </div>
      <div
        v-if="studentType === 'digital_trial' || studentType === 'digital_subscription'"
        class="library-content">
        <library-overview-clip-index
          override-title="Elementary"
          :level="'nursery_two'"></library-overview-clip-index>
        <library-overview-clip-index
          override-title="Intermediate"
          :level="'kindergarten_one'"></library-overview-clip-index>
        <library-overview-clip-index
          override-title="Advanced"
          :level="'kindergarten_two'"></library-overview-clip-index>
      </div>
      <div
        v-else
        class="library-content">
        <library-overview-clip-index
          :level="'kindergarten_two'"></library-overview-clip-index>
        <library-overview-clip-index
          :level="'kindergarten_one'"></library-overview-clip-index>
        <library-overview-clip-index
          :level="'nursery_two'"></library-overview-clip-index>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import {mapState, mapMutations} from 'vuex';

import LibraryOverviewClipIndexComponent
  from './LibraryOverviewClipIndexComponent.vue';

import {utilities} from '../../mixins/utilities';

export default {
  name: 'LibraryOverview',
  components: {
    LibraryOverviewClipIndex: LibraryOverviewClipIndexComponent,
  },
  mixins: [utilities],
  data() {
    return {
    };
  },
  computed: {
    ...mapState(['studentType']),
    ...mapState('library', ['storyarcs', 'latestStory']),
    latestStoryarc() {
      return this.storyarcs.find((storyarc) => {
        // note -> latestStory's storyarc field saves only the pk
        return storyarc.pk === this.latestStory.storyarc;
      });
    },
  },
  watch: {
    $route(to, from) {
      if (to && to.name === 'library-overview') {
        this.scrollToTop('#main');
      };
    },
  },
  mounted: function() {
    // checks to see if storyarcs and latest story are present; if not, pull
    if (!this.storyarcs || !this.latestStory) {
      this.setShowGlobalLoadingScreen(true);
      axios
          .get('/library/api/storyarc/available/')
          .then((response) => {
            console.log('pulling');
            this.setStoryarcs(response.data['storyarcs']);
            this.setLatestStory(response.data['latest_story']);
            this.setShowGlobalLoadingScreen(false);
          })
          .catch((error) => {
            console.log(error);
            console.log('having issue pullng');
            this.setShowGlobalLoadingScreen(false);
          });
    }
  },
  methods: {
    ...mapMutations(['setShowGlobalLoadingScreen']),
    ...mapMutations('library', ['setStoryarcs', 'setLatestStory']),
  },
};
</script>

<style lang="scss" scoped>
  #main {
    margin-bottom: 164px;
  }
  .latest-story-container {
    background: #fff;
    margin: 20px auto;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    display: grid;
    grid-template-columns: 1fr 2fr;
  }

  .thumbnail-vignette {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    width: 30%;
    z-index: 3;
    background-image: linear-gradient(to right,#fff,transparent);
  }

  .latest-story-desc-container {
    position: relative;
    z-index: 2;
    color: #333;
    height: 100%;
    background: #fff;
    display: flex;
    flex-direction: column;
    padding: 20px;
  }

  .latest-story-desc-container > h4 {
    text-transform: uppercase;
    font-weight: 300;
    font-size: 80%;
  }

  .latest-story-desc-container > h6 {
    text-transform: uppercase;
    font-size: 70%;
    align-self: flex-end;
    margin-right: 10px;
  }

  .latest-story-desc-container > hr {
    width: 80%;
  }

  .latest-story-desc-container > h6 > span {
    padding: 0 10px;
  }

  .latest-story-desc-container > h4 > span{
    text-transform: uppercase;
    font-weight: 700;
    font-size: 140%;
  }

  .latest-story-title {
    font-size: 320%;
    margin: 5px 0 12px 0;
    text-align: center;
    white-space: nowrap;
  }

  .latest-story-desc {
    margin: 20px 0;
    font-size: 90%;
  }

  .latest-story-thumbnail-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #000;
  }

  .latest-story-thumbnail-container > img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 100% 15%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    transition: all 0.2s;
  }

  .latest-story-thumbnail-container:hover > img {
    opacity: 0.7;
    transform:scale(1.03);
  }

  .play-icon-container {
    position: absolute;
    z-index: 2;
    border: 4px solid white;
    background: rgba(255, 255, 255, 0.4);
    border-radius: 50%;
    padding: 20px;
    height: 100px;
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 200%;
    color: #fff;
    opacity: 0;
    transition: all 0.2s;
  }

  .play-icon-container > i {
    padding-left: 4px;
  }

  .play-icon-container:hover {
    color: #bd279b;
    transform: scale(1.05);
    background-color: rgba(255,255,255,0.6);
  }

  .latest-story-thumbnail-container:hover > .play-icon-container {
    opacity: 1;
  }

  .latest-story-buttons {
    margin: 5px 0;
  }

  .latest-story-buttons > a {
    color: black;
    margin-right: 10px;
    padding: 8px 24px;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 70%;
    font-weight: bold;
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }

  .latest-story-buttons > a > i {
    font-size: 120%;
    padding-right: 5px;
  }

  .latest-story-buttons > .play-btn {
    color: white;
    background: #bd279b;
    border: 1px solid #bd279b;
    transition: transform 0.2s;
  }

  .latest-story-buttons > .show-episode-btn {
    color: #777;
    background: #ececec;
    border: 1px solid #cacaca;
    transition: all 0.2s;
  }

  .latest-story-buttons > .play-btn:hover {
    transform: scale(1.03);
  }

  .latest-story-buttons > .show-episode-btn:hover {
    transform: scale(1.03);
    border: 1px solid #b9b9b9;
  }

  .library-container {
    background: #fff;
    margin: 2px auto;
    border-radius: 10px;
    font-size: 80%;
    overflow: hidden;
  }

  .library-header {
    text-transform: uppercase;
    color: #fff;
    background: #5a4179;
    padding: 8px 6px 8px 6px;
  }

  .library-header i {
    margin-right: 8px;
    margin-left: 2px;
    font-size: 85%;
  }

  .library-content {
    padding: 2px 6px 6px 6px;
    font-size: 80%;
  }

  @media only screen and (max-width: 1140px) {
    .latest-story-container {
      border-radius: 0;
      margin: 0;
    }
    .library-container {
      border-radius: 0;
    }
  }

  @media only screen and (max-width: 1024px) {
    .latest-story-thumbnail-container > img {
      width: 110%;
      left: -5%;
    }
    .library-container{
      margin-bottom: 58px;
    }
  }

  @media only screen and (max-width: 1000px) {
    .latest-story-desc-container {
      font-size: 17px
    }
    .latest-story-buttons > a {
      padding: 8px 20px;
    }
    .library-container {
      font-size: 70%;
    }
  }
  @media only screen and (max-width: 865px) {
    .latest-story-desc-container {
      font-size: 16.5px;
      padding: 16px;
    }
    .latest-story-title {
      margin: 5px 0 10px 0;
    }
    .latest-story-desc {
      margin: 16px 0;
    }
    .latest-story-buttons > a {
      padding: 8px 17px;
    }
    .library-container {
      font-size: 65%;
    }

  }
  @media only screen and (max-width: 768px) {
    .latest-story-desc-container {
      font-size: 14px;
      padding: 12px;
    }
    .latest-story-title {
      margin: 5px 0 8px 0;
    }
    .latest-story-desc {
      margin: 12px 0;
    }
    .latest-story-buttons > a {
      padding: 8px 14px;
    }
    .library-container {
      font-size: 60%;
    }

  }
  @media only screen and (max-width: 680px) {
    .latest-story-container {
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 1fr;
    }
    .latest-story-desc-container {
      order: 2;
    }
    .latest-story-thumbnail-container {
      order: 1;
    }
    .latest-story-thumbnail-container > img{
      width: 100%;
      left: 0;
      object-position: 100% 30%;
    }
    .thumbnail-vignette {
      background-image: linear-gradient(to top,#fff,transparent);
      width: 100%;
      height: 30%;
      align-self: flex-end;
      position: relative;
    }
    .latest-story-desc-container {
      font-size: 18px;
    }
    .library-container {
      font-size: 70%;
    }
  }

  @media only screen and (max-width: 535px) {
    .latest-story-desc-container {
      font-size: 16px;
    }
    .library-container {
      font-size: 65%;
    }
  }
  @media only screen and (max-width: 480px) {
    .latest-story-thumbnail-container > img{
      width: 120%;
      left: -10%;
    }
    .library-container {
      font-size: 60%;
    }
  }
  @media only screen and (max-width: 400px) {
    .latest-story-desc-container {
      font-size: 15px;
    }
  }
  @media only screen and (max-width: 320px) {
    .latest-story-desc-container {
      font-size: 13px;
    }
  }
</style>
