<template>
  <div id="daily-challenge-container">
    <quiz-main
      quiz-type="dailyChallenge"
      :daily-challenge-unlocked-date="selectedDailyChallengeUnlockedDate"
    ></quiz-main>
  </div>
</template>

<script>

import {mapState} from 'vuex';
import QuizMainComponent from '../common/quiz/QuizMainComponent.vue';

export default {
  name: 'DailyChallengeQuiz',
  components: {
    QuizMain: QuizMainComponent,
  },
  mixins: [],
  data() {
    return {
    };
  },
  computed: {
    ...mapState('dailyChallenge', ['selectedDailyChallengeUnlockedDate']),
  },
  watch: {
    $route: {
      immediate: true,
      handler(to, from) {
        // if we are directing to the quiz component
        // only follow through if we have selected a particular daily challenge
        if (to && to.name === 'daily-challenge-quiz' &&
            !this.selectedDailyChallengeUnlockedDate) {
          this.$router.push({name: 'daily-challenge-overview'});
        };
      },
    },
  },
  mounted() {
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
  #daily-challenge-container {
    margin: 0 auto;
    height: 100%;
  }
</style>
