<template>
  <div class="question__component">
    <div class="question">
      <div class="question--header">Soalan: {{ questionIndex + 1 }} / {{ questions.length }}</div>
      <span class="question--content">{{ info.text }}</span>
    </div>
    <ul class="options">
      <li class="option" @click="selectChoice(choice)"
          @mouseover="onHover(choice, true)"
          @mouseleave="onHover(choice, false)"
        v-for="(choice, index) in info.choices" :key="index" :class="getChoiceClass(choice)">
        {{ index + 1 }}. {{ choice }}
        <i v-if="getChoiceClass(choice) == 'correct'" class="fa-solid fa-check"></i>
        <i v-if="getChoiceClass(choice) == 'wrong'" class="fa-solid fa-xmark"></i>
      </li>
    </ul>
  </div>
</template>

<script>
import {mapState, mapMutations, mapGetters} from 'vuex';

export default {
  name: 'QuestionComponent',
  components: {
  },
  props: {
    info: Object,
  },
  data() {
    return {
      currentHoveredChoice: '',
    };
  },
  computed: {
    ...mapState('guidedReading', ['comprehensionState', 'selectedAnswer', 'questionIndex', 'questions']),
    ...mapGetters('guidedReading', ['currentQuestion']),
  },
  mounted() {
  },
  methods: {
    ...mapMutations('guidedReading', ['setSelectedAnswer']),
    // need to write out own hover function instead of css because we only want hover to activate during question selection
    onHover(choice, isMouseEnter) {
      if (!isMouseEnter) {
        this.currentHoveredChoice = '';
      } else {
        this.currentHoveredChoice = choice;
      }
    },
    getChoiceClass(choice) {
      if (this.comprehensionState === 1) {
        if (this.selectedAnswer === choice) return 'selected';
        if (this.currentHoveredChoice === choice) return 'hover';
        return '';
      }
      // highlight the correct answer
      if (this.comprehensionState === 2 && choice === this.currentQuestion.correct_answer) {
        return 'correct';
      }
      // show wrong answer if this is what we selected and is wrong
      if (this.comprehensionState === 2 && this.selectedAnswer === choice &&
          this.selectedAnswer !== this.currentQuestion.correct_answer) {
        return 'wrong';
      }

      return '';
    },
    selectChoice(choice) {
      // can only select choice during the answer phase
      if (this.comprehensionState !== 1) return;
      this.setSelectedAnswer(choice);
    },
  },
};
</script>

<style lang="scss" scoped>
  .question {
    height: 40%;
    background: #fff;
    text-align: center;
    border-radius: .25rem;
    overflow: hidden;
    &__component {
      height: 100%;
      position: relative;
    }
    &--header {
      font-weight: 600;
      color: #fff;
      background: #527abe;
      padding: .5rem;
    }
    &--content {
      display: flex;
      justify-content: center;
      align-items: center;
      height: calc(100% - 54px);
    }
  }
  .option {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 1rem 0 2rem;
    background: #fff;
    border-radius: .25rem;
    border: 4px solid #fff;
    cursor: pointer;
    position: relative;
    &s {
      display: grid;
      grid-template-rows: 1fr 1fr 1fr 1fr;
      height: calc(60% - 1rem);
      grid-gap: .5rem;
      margin: .5rem 0rem;
    }
    i {
      font-size: 1.75rem;
      color: #fff;
    }
  }

  .option.hover {
    border: 4px solid #527abe;
  }

  .option.selected {
    background: #527abe;
    border: 4px solid #527abe;
    color: #fff;
  }
  .option.correct {
    background: #38c6a5;
    border: 4px solid #38c6a5;
    color: #fff;
  }
  .option.wrong {
    background: #dc5a84;
    border: 4px solid #dc5a84;
    color: #fff;
  }
</style>
