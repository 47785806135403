// https://stackoverflow.com/questions/42603909/accessing-vuex-state-when-defining-vue-router-routes
import {store} from './store/store';

import TheLoginMainComponent from
  './components/TheLoginMainComponent.vue';

import TheWordleComponent from
  './components/TheWordleComponent.vue';

import RegistrationMainComponent from
  './components/registration/MainComponent.vue';

import RegistrationSuccessComponent from
  './components/registration/SuccessComponent.vue';

// import RegistrationParentComponent from
//   './components/registration/ParentComponent.vue';

import RegistrationParentNewComponent from
  './components/registration/ParentNewComponent.vue';

import RegistrationStudentComponent from
  './components/registration/StudentComponent.vue';

import TheChangePasswordComponent from
  './components/TheChangePasswordComponent.vue';

import BillingMainComponent from
  './components/billing/BillingMainComponent.vue';

import BillingIndexComponent from
  './components/billing/BillingIndexComponent.vue';

import BillingSuccessComponent from
  './components/billing/BillingSuccessComponent.vue';

import BillingCancelledComponent from
  './components/billing/BillingCancelledComponent.vue';

import TheWorksheetDownloadComponent from
  './components/TheWorksheetDownloadComponent.vue';

import LibraryMainComponent from
  './components/library/LibraryMainComponent.vue';

import LibraryOverviewComponent from
  './components/library/LibraryOverviewComponent.vue';

import LibraryStoryComponent from
  './components/library/LibraryStoryComponent.vue';

import LibraryStoryArcMainComponent from
  './components/library/LibraryStoryarcMainComponent.vue';

import PetMainComponent from
  './components/pet/PetMainComponent.vue';

import PetInfoComponent from
  './components/pet/PetInfoComponent.vue';

import PetOverviewComponent from
  './components/pet/PetOverviewComponent.vue';

import DailyChallengeMainComponent from
  './components/dailychallenge/DailyChallengeMainComponent.vue';

import DailyChallengeOverviewComponent from
  './components/dailychallenge/DailyChallengeOverviewComponent.vue';

import DailyChallengeQuizComponent from
  './components/dailychallenge/DailyChallengeQuizComponent.vue';

import DailyChallengeBossComponent from
  './components/dailychallenge/boss/MainComponent.vue';

import OralMainComponent from
  './components/oral/MainComponent.vue';

import OralReadAloudIndexComponent from
  './components/oral/ReadAloudIndexComponent.vue';

import OralTextbookChapterIndexComponent from
  './components/oral/TextbookChapterIndexComponent.vue';

import OralFreeResponseIndexComponent from
  './components/oral/FreeResponseIndexComponent.vue';

import OralFreeResponseTopicComponent from
  './components/oral/FreeResponseTopicComponent.vue';

import OralTemplatedPhrasesIndexComponent from
  './components/oral/TemplatedPhrasesIndexComponent.vue';

import OralTemplatedPhrasesTopicComponent from
  './components/oral/TemplatedPhrasesTopicComponent.vue';

import OralOverviewComponent from
  './components/oral/OverviewComponent.vue';

import OralPassageComponent from
  './components/oral/PassageComponent.vue';

import OralNewPassageComponent from
  './components/oral/NewPassageComponent.vue';

import LearningTrackMainComponent from
  './components/learningtrack/LearningTrackMainComponent.vue';

import LearningTrackOverviewComponent from
  './components/learningtrack/LearningTrackOverviewComponent.vue';

import LearningTrackEbookDashboardComponent from
  './components/learningtrack/ebook/LearningTrackEbookDashboardComponent.vue';

import LearningTrackEbookComponent from
  './components/learningtrack/ebook/LearningTrackEbookComponent.vue';

import LearningTrackVocabularyIndexComponent from
  './components/learningtrack/vocab/LearningTrackVocabularyIndexComponent.vue';

import LearningTrackIdiomIndexComponent from
  './components/learningtrack/idiom/LearningTrackIdiomIndexComponent.vue';

import LearningTrackHighFrequencyIndexComponent from
  './components/learningtrack/highfrequency/LearningTrackHighFrequencyIndexComponent.vue';

import LearningTrackAssessmentBookIndexComponent from
  './components/learningtrack/assessmentbook/IndexComponent.vue';

import LearningTrackAssessmentBookMainComponent from
  './components/learningtrack/assessmentbook/MainComponent.vue';

import LearningTrackPinyinIndexComponent from
  './components/learningtrack/pinyin/LearningTrackPinyinIndexComponent.vue';

import LearningTrackSubtrackIndexComponent from
  './components/learningtrack/LearningTrackSubtrackIndexComponent.vue';

import LearningTrackQuizComponent from
  './components/learningtrack/LearningTrackQuizComponent.vue';

import LearningTrackRevisionQuizComponent from
  './components/learningtrack/LearningTrackRevisionQuizComponent.vue';

import VideoLessonMainComponent from
  './components/videolesson/MainComponent.vue';

import VideoLessonPreschoolRevision from
  './components/videolesson/PreschoolRevisionComponent.vue';

import DynamicBookMainComponent from
  './components/dynamicbook/MainComponent.vue';

import DynamicBookIndexComponent from
  './components/dynamicbook/IndexComponent.vue';

import DynamicBookOverviewComponent from
  './components/dynamicbook/OverviewComponent.vue';

import GameMainComponent from
  './components/game/GameMainComponent.vue';

import GameOverviewComponent from
  './components/game/GameOverviewComponent.vue';

import GameFlashcardMemoryComponent from
  './components/game/GameFlashcardMemoryComponent.vue';

import MainLeaderBoardMainComponent from
  './components/mainleaderboard/MainComponent.vue';

import MainLeaderBoardCoinComponent from
  './components/mainleaderboard/CoinComponent.vue';

import MainLeaderBoardAssessmentBookComponent from
  './components/mainleaderboard/AssessmentBookComponent.vue';

import MainLeaderBoardRoomSessionComponent from
  './components/mainleaderboard/RoomSessionComponent.vue';

import AnalyticsMainComponent from
  './components/new/analytics/MainComponent.vue';

import AnalyticsOverviewComponent from
  './components/new/analytics/OverviewComponent.vue';

import AnalyticsChapterVocabBreakdownComponent from
  './components/new/analytics/ChapterVocabBreakdownComponent.vue';

import MultiplayerMainComponent from
  './components/multiplayer/MainComponent.vue';

import MultiplayerRoomSelectionComponent from
  './components/multiplayer/RoomSelectionComponent.vue';

import MultiplayerRoomComponent from
  './components/multiplayer/RoomComponent.vue';

import ShopMainComponent from
  './components/shop/MainComponent.vue';

import ShopIndexComponent from
  './components/shop/IndexComponent.vue';

import RaidMainComponent from
  './components/raid/MainComponent.vue';

import RaidIndexComponent from
  './components/raid/IndexComponent.vue';

import RaidGameMainComponent from
  './components/raid/GameMainComponent.vue';

// the new routes
import NewLearnMainComponent from
  './components/new/learn/MainComponent.vue';

import NewLearnOverviewComponent from
  './components/new/learn/OverviewComponent.vue';

import NewWorksheetDownloadComponent from
  './components/new/learn/WorksheetDownloadComponent.vue';

import NewVocabIndexComponent from
  './components/new/learn/vocab/VocabIndexComponent.vue';

import NewOralMainComponent from
  './components/new/oral/MainComponent.vue';

import NewOralOverviewComponent from
  './components/new/oral/OverviewComponent.vue';

import NewOralCategoryIndexComponent from
  './components/new/oral/CategoryIndexComponent.vue';

import NewOralTexbookChapterTopicComponent from
  './components/new/oral/topic/TextbookChapterComponent.vue';

import NewOralPictureVocabQuizComponent from
  './components/new/oral/PictureVocabQuizComponent.vue';

import NewOralAiPracticeComponent from
  './components/new/oral/AiPracticeComponent.vue';

import NewOralPreviousAiPracticeAttemptsComponent from
  './components/new/oral/PreviousAiPracticeAttemptsComponent.vue';

import NewProfileMainComponent from
  './components/new/profile/MainComponent.vue';

import NewMissionsMainComponent from
  './components/new/missions/MainComponent.vue';

import NewMissionsOverviewComponent from
  './components/new/missions/OverviewComponent.vue';

import NewPlayMainComponent from
  './components/new/play/MainComponent.vue';

import NewPlayOverviewComponent from
  './components/new/play/OverviewComponent.vue';

import NewAvatarComponent from
  './components/new/play/AvatarComponent.vue';

import NewFishingGameComponent from
  './components/new/play/FishingGameComponent.vue';

import NewTreeClimbComponent from
  './components/new/play/TreeClimbComponent.vue';

import NewIslandMainComponent from
  './components/new/play/island/MainComponent.vue';

import NewSettingsMainComponent from
  './components/new/settings/MainComponent.vue';

import NewSettingsOverviewComponent from
  './components/new/settings/OverviewComponent.vue';

import NewSettingsChangePasswordComponent from
  './components/new/settings/ChangePasswordComponent.vue';

import NewSettingsSwitchUserComponent from
  './components/new/settings/SwitchUserComponent.vue';

import NewSettingsChangeDifficultyComponent from
  './components/new/settings/ChangeDifficultyComponent.vue';

import NewSettingsBillingIndexComponent from
  './components/new/settings/billing/IndexComponent.vue';

import NewComprehensionMainComponent from
  './components/new/comprehension/MainComponent.vue';
import NewComprehensionOverviewComponent from
  './components/new/comprehension/OverviewComponent.vue';
import NewComprehensionPassageIndexComponent from
  './components/new/comprehension/PassageIndexComponent.vue';
import NewComprehensionPassageComponent from
  './components/new/comprehension/PassageComponent.vue';
import NewComprehensionMeaningSearchPracticeComponent from
  './components/new/comprehension/MeaningSearchPracticeComponent.vue';

import NewCompositionMainComponent from
  './components/new/composition/MainComponent.vue';
import NewCompositionOverviewComponent from
  './components/new/composition/OverviewComponent.vue';
import NewCompositionVideoLessonIndexComponent from
  './components/new/composition/LessonIndexComponent.vue';
import NewCompositionVideoLessonComponent from
  './components/new/composition/LessonComponent.vue';
import NewCompositionResourcesComponent from
  './components/new/composition/ResourcesComponent.vue';

import NewMalayFreeResponseIndexComponent from
  './components/new/malay/FreeResponseIndexComponent.vue';
import NewMalayComprehensionOverviewComponent from
  './components/new/malay/comprehension/OverviewComponent.vue';
import NewMalayComprehensionMainComponent from
  './components/new/malay/comprehension/MainComponent.vue';
import NewMalayComprehensionComponent from
  './components/new/malay/comprehension/ComprehensionComponent.vue';

import OnboardingMainComponent from
  './components/onboarding/MainComponent.vue';

// guide on authetication
// https://scotch.io/tutorials/vue-authentication-and-route-handling-using-vue-router

export const routes = [
  {
    path: '/test',
    component: OnboardingMainComponent,
    name: 'test',
  },
  {
    path: '/login',
    component: TheLoginMainComponent,
    name: 'login-main',
  },
  {
    path: '/wordle',
    component: TheWordleComponent,
    name: 'wordle',
  },
  {
    path: '/registration',
    component: RegistrationMainComponent,
    name: 'registration-main',
    children: [
      // {
      //   path: 'parent',
      //   component: RegistrationParentComponent,
      //   name: 'registration-parent',
      // },
      {
        path: 'parent',
        component: RegistrationParentNewComponent,
        name: 'registration-parent',
      },
      {
        path: ':parentSignupToken/student',
        component: RegistrationStudentComponent,
        name: 'registration-student',
        props: (route) => {
          return {
            parentSignupToken: route.params.parentSignupToken,
          };
        },
      },
      {
        path: 'success',
        component: RegistrationSuccessComponent,
        name: 'registration-success',
      },
    ],
  },
  {
    path: '/billing',
    component: BillingMainComponent,
    name: 'billing-main',
    children: [
      {
        path: 'success',
        component: BillingSuccessComponent,
        name: 'billing-success',
      },
      {
        path: 'cancelled',
        component: BillingCancelledComponent,
        name: 'billing-cancelled',
      },
      {
        path: '',
        component: BillingIndexComponent,
        name: 'billing-index',
      },
    ],
  },
  {
    path: '/worksheetdownload',
    component: TheWorksheetDownloadComponent,
    name: 'worksheet-download',
  },
  {
    path: '/change-password',
    component: TheChangePasswordComponent,
    name: 'change-password',
  },
  {
    path: '/videolesson/primaryschool/:week',
    component: VideoLessonMainComponent,
    name: 'video-lesson-main',
    props: (route) => {
      return {
        week: Number(route.params.week),
      };
    },
  },
  {
    path: '/videolesson/preschool/:week',
    component: VideoLessonPreschoolRevision,
    name: 'video-lesson-preschool-revision',
    props: (route) => {
      return {
        week: Number(route.params.week),
      };
    },
  },
  {
    path: '/videolesson/preschool/',
    component: VideoLessonPreschoolRevision,
    name: 'video-lesson-preschool-revision-latest',
  },
  {
    path: '/videolesson/primaryschool/',
    component: VideoLessonMainComponent,
    name: 'video-lesson-main-latest',
  },
  // https://stackoverflow.com/questions/46083220/how-to-vuejs-router-link-active-style?rq=1
  // vue router explanation on nested routes
  // to select story arcs
  {
    path: '/library',
    component: LibraryMainComponent,
    name: 'library-main',
    children: [
      // to show the stories from storyarcs
      {
        path: 'storyarc/:storyarcId',
        component: LibraryStoryArcMainComponent,
        name: 'library-storyarc-main',
        props: (route) => {
          return {
            storyarcId: Number(route.params.storyarcId),
          };
        },
      },
      {
        path: 'story/latest',
        component: LibraryStoryComponent,
        name: 'library-story-latest',
        props: (route) => {
          return {
            storyId: Number(store.state.library.latestStory.pk),
          };
        },
      },
      {
        path: 'story/:storyId',
        component: LibraryStoryComponent,
        name: 'library-story',
        props: (route) => {
          return {
            storyId: Number(route.params.storyId),
          };
        },
      },
      // to show the stories from storyarcs
      {
        path: '',
        component: LibraryOverviewComponent,
        name: 'library-overview',
      },
    ],
  },
  {
    path: '/pet',
    component: PetMainComponent,
    name: 'pet-main',
    children: [
      {
        path: '',
        component: PetOverviewComponent,
        name: 'pet-overview',
      },
      {
        path: ':petId',
        component: PetInfoComponent,
        name: 'pet-info',
        props: (route) => {
          return {
            petId: Number(route.params.petId),
          };
        },
      },
    ],
  },
  {
    path: '/dailychallenge',
    component: DailyChallengeMainComponent,
    name: 'daily-challenge-main',
    children: [
      {
        path: '',
        component: DailyChallengeOverviewComponent,
        name: 'daily-challenge-overview',
      },
      {
        path: 'quiz',
        component: DailyChallengeQuizComponent,
        name: 'daily-challenge-quiz',
      },
      {
        path: 'boss',
        component: DailyChallengeBossComponent,
        name: 'daily-challenge-boss',
      },
    ],
  },
  {
    path: '/learningtrack',
    component: LearningTrackMainComponent,
    name: 'learning-track-main',
    children: [
      {
        path: '',
        component: LearningTrackOverviewComponent,
        name: 'learning-track-overview',
      },
      {
        path: 'ebook/dashboard',
        component: LearningTrackEbookDashboardComponent,
        name: 'learning-track-ebook-dashboard',
      },
      {
        path: 'ebook/:ebookPk/:pageIndex',
        component: LearningTrackEbookComponent,
        name: 'learning-track-ebook',
        props: (route) => {
          return {
            ebookPk: Number(route.params.ebookPk),
            pageIndex: Number(route.params.pageIndex),
          };
        },
      },
      {
        path: 'idiom',
        component: LearningTrackIdiomIndexComponent,
        name: 'learning-track-idiom-index',
      },
      {
        path: 'highfrequency',
        component: LearningTrackHighFrequencyIndexComponent,
        name: 'learning-track-high-frequency-index',
      },
      {
        path: 'pinyin',
        component: LearningTrackPinyinIndexComponent,
        name: 'learning-track-pinyin-index',
      },
      {
        path: 'vocabulary',
        component: LearningTrackVocabularyIndexComponent,
        name: 'learning-track-vocabulary-index',
      },
      {
        path: 'assessmentbook/:quizId',
        component: LearningTrackAssessmentBookMainComponent,
        name: 'learning-track-assessment-book',
        props: (route) => {
          return {
            quizId: Number(route.params.quizId),
          };
        },
      },
      {
        path: 'assessmentbook',
        component: LearningTrackAssessmentBookIndexComponent,
        name: 'learning-track-assessment-book-index',
      },
      {
        path: 'quiz/story/:quizId',
        component: LearningTrackQuizComponent,
        name: 'learning-track-quiz',
        props: (route) => {
          return {
            quizId: Number(route.params.quizId),
          };
        },
        // saves this route so we can route back after quiz ends
        beforeEnter(to, from, next) {
          store.commit('learningTrack/setRoutePriorToStartingQuiz', from);
          next();
        },
      },
      {
        path: ':learningTrackType/revision',
        component: LearningTrackRevisionQuizComponent,
        name: 'learning-track-revision-quiz',
        props: (route) => {
          return {
            learningTrackType: route.params.learningTrackType,
          };
        },
      },
      // note this is a very general pattern matching
      // might cause errors
      {
        path: ':learningTrackType/:learningTrackPk/:subtrack',
        component: LearningTrackSubtrackIndexComponent,
        name: 'learning-track-subterm-index',
        props: (route) => {
          return {
            learningTrackType: route.params.learningTrackType,
            learningTrackPk: Number(route.params.learningTrackPk),
            subtrack: route.params.subtrack,
          };
        },
      },
    ],
  },
  {
    path: '/dynamicbook',
    component: DynamicBookMainComponent,
    name: 'dynamic-book-main',
    children: [
      {
        path: '',
        component: DynamicBookIndexComponent,
        name: 'dynamic-book-index',
      },
      {
        path: ':bookId',
        component: DynamicBookOverviewComponent,
        name: 'dynamic-book-overview',
        props: (route) => {
          return {
            bookId: Number(route.params.bookId),
          };
        },
      },
    ],
  },
  {
    path: '/game',
    component: GameMainComponent,
    name: 'game-main',
    children: [
      {
        path: '',
        component: GameOverviewComponent,
        name: 'game-overview',
      },
      {
        path: 'flashcards/memory/:quizId',
        component: GameFlashcardMemoryComponent,
        name: 'game-flashcard-memory',
        props: (route) => {
          return {
            quizId: Number(route.params.quizId),
          };
        },
      },
    ],
  },
  {
    path: '/raid',
    component: RaidMainComponent,
    name: 'raid-main',
    children: [
      {
        path: ':raidType',
        component: RaidIndexComponent,
        name: 'raid-index',
      },
      {
        path: ':raidId',
        component: RaidGameMainComponent,
        name: 'raid-game-main',
        props: (route) => {
          return {
            raidId: Number(route.params.raidId),
          };
        },
      },
    ],
  },
  {
    path: '/neworal/oral',
    component: NewOralMainComponent,
    name: 'new-oral-main',
    children: [
      {
        path: ':category/index',
        component: NewOralCategoryIndexComponent,
        name: 'new-oral-category-index',
        props: (route) => {
          return {
            category: route.params.category,
          };
        },
      },
      {
        path: 'ai/practice/:subject/:questionId',
        component: NewOralAiPracticeComponent,
        name: 'new-oral-ai-practice',
        props: (route) => {
          return {
            subject: route.params.subject,
            // can be a number (in str) or custom
            questionId: route.params.questionId,
          };
        },
      },
      {
        path: 'ai/practice/:subject/:questionId/previous_attempts/',
        component: NewOralPreviousAiPracticeAttemptsComponent,
        name: 'new-oral-ai-practice-previous-attempts',
        props: (route) => {
          return {
            subject: route.params.subject,
            // can be a number (in str) or custom
            questionId: route.params.questionId,
          };
        },
      },
      {
        path: 'textbookchapter/topic/:topicId',
        component: NewOralTexbookChapterTopicComponent,
        name: 'new-oral-textbook-chapter-topic',
        props: (route) => {
          return {
            topicId: route.params.topicId,
          };
        },
      },
      {
        path: 'activevocab/topic/:topicId',
        component: NewOralPictureVocabQuizComponent,
        name: 'new-oral-picture-vocab-quiz',
        props: (route) => {
          return {
            topicId: parseInt(route.params.topicId),
          };
        },
      },
      {
        path: '',
        component: NewOralOverviewComponent,
        name: 'new-oral-overview',
      },
    ],
  },
  {
    path: '/oral',
    component: OralMainComponent,
    name: 'oral-main',
    children: [
      {
        path: '',
        component: OralOverviewComponent,
        name: 'oral-overview',
      },
      {
        path: 'readaloud',
        component: OralReadAloudIndexComponent,
        name: 'oral-readaloud-index',
      },
      {
        path: 'textbookchapter',
        component: OralTextbookChapterIndexComponent,
        name: 'oral-textbook-chapter-index',
      },
      // this is for free response where we pass in a specific question id
      {
        path: 'passage/:type/:entityId/:questionIndex',
        component: OralPassageComponent,
        name: 'oral-passage-with-question-id',
        props: (route) => {
          return {
            type: route.params.type,
            entityId: Number(route.params.entityId),
            questionIndex: Number(route.params.questionIndex),
          };
        },
      },
      // this is for read aloud
      {
        path: 'practice/:type/:entityId',
        component: OralNewPassageComponent,
        name: 'oral-new-passage',
        props: (route) => {
          return {
            type: route.params.type,
            entityId: Number(route.params.entityId),
          };
        },
      },
      // free response
      {
        path: 'freeresponse',
        component: OralFreeResponseIndexComponent,
        name: 'oral-free-response-index',
      },
      {
        path: 'freeresponse/:topicId',
        component: OralFreeResponseTopicComponent,
        name: 'oral-free-response-topic',
        props: (route) => {
          return {
            topicId: Number(route.params.topicId),
          };
        },
      },
      // templated phrases
      {
        path: 'templatedphrases',
        component: OralTemplatedPhrasesIndexComponent,
        name: 'oral-templated-phrases-index',
      },
      {
        path: 'templatedphrases/:topicId',
        component: OralTemplatedPhrasesTopicComponent,
        name: 'oral-templated-phrases-topic',
        props: (route) => {
          return {
            topicId: Number(route.params.topicId),
          };
        },
      },
    ],
  },
  {
    path: '/leaderboard',
    component: MainLeaderBoardMainComponent,
    name: 'main-leaderboard-main',
    children: [
      {
        path: 'coins',
        component: MainLeaderBoardCoinComponent,
        name: 'main-leaderboard-coin',
      },
      {
        path: 'assessmentbook/:assessmentBookId',
        component: MainLeaderBoardAssessmentBookComponent,
        name: 'main-leaderboard-assessment-book',
        props: (route) => {
          return {
            assessmentBookId: Number(route.params.assessmentBookId),
          };
        },
      },
      {
        path: 'roomsession/:sessionId',
        component: MainLeaderBoardRoomSessionComponent,
        name: 'main-leaderboard-room-session',
        props: (route) => {
          return {
            sessionId: Number(route.params.sessionId),
          };
        },
      },
    ],
  },
  {
    path: '/new/analytics',
    component: AnalyticsMainComponent,
    name: 'analytics-main',
    children: [
      {
        path: '/chapter/:chapterId',
        component: AnalyticsChapterVocabBreakdownComponent,
        name: 'analytics-chapter-vocab-breakdown',
        props: (route) => {
          return {
            chapterId: Number(route.params.chapterId),
          };
        },
      },
      {
        path: '',
        component: AnalyticsOverviewComponent,
        name: 'analytics-overview',
      },
    ],
  },
  {
    path: '/multiplayer',
    component: MultiplayerMainComponent,
    name: 'multiplayer-main',
    children: [
      {
        path: '',
        component: MultiplayerRoomSelectionComponent,
        name: 'multiplayer-room-selection',
      },
      {
        path: 'room',
        component: MultiplayerRoomComponent,
        name: 'multiplayer-room',
        // props: (route) => {
        //   return {
        //     // note roomId is a string not number
        //     // uses a hash
        //     roomId: String(route.params.roomId),
        //   };
        // },
      },
    ],
  },
  {
    path: '/shop/',
    component: ShopMainComponent,
    name: 'shop-main',
    children: [
      {
        path: '',
        component: ShopIndexComponent,
        name: 'shop-index',
      },
    ],
  },
  {
    path: '/newplay/play',
    component: NewPlayMainComponent,
    name: 'new-play-main',
    children: [
      {
        path: 'avatar',
        component: NewAvatarComponent,
        name: 'new-play-avatar',
      },
      {
        path: 'fishing',
        component: NewFishingGameComponent,
        name: 'new-play-fishing-game',
      },
      {
        path: 'tree-climb',
        component: NewTreeClimbComponent,
        name: 'new-play-tree-climb',
      },
      {
        path: 'island',
        component: NewIslandMainComponent,
        name: 'new-play-island-main',
      },
      {
        path: '',
        component: NewPlayOverviewComponent,
        name: 'new-play-overview',
      },
    ],
  },
  {
    path: '/newmissions',
    component: NewMissionsMainComponent,
    name: 'new-missions-main',
    children: [
      {
        path: 'missions',
        component: NewMissionsOverviewComponent,
        name: 'new-missions-overview',
      },
    ],
  },
  {
    path: '/newsettings/settings',
    component: NewSettingsMainComponent,
    name: 'new-settings-main',
    children: [
      {
        path: 'password',
        component: NewSettingsChangePasswordComponent,
        name: 'new-settings-password',
      },
      {
        path: 'switch-user',
        component: NewSettingsSwitchUserComponent,
        name: 'new-settings-switch-user',
      },
      {
        path: 'change-difficulty',
        component: NewSettingsChangeDifficultyComponent,
        name: 'new-settings-change-difficulty',
      },
      {
        path: 'billing',
        component: NewSettingsBillingIndexComponent,
        name: 'new-settings-billing-index',
      },
      {
        path: '',
        component: NewSettingsOverviewComponent,
        name: 'new-settings-overview',
      },
    ],
  },
  {
    path: '/newmalay/freeresponseindex/',
    component: NewMalayFreeResponseIndexComponent,
    name: 'new-malay-free-response-index',
  },
  {
    path: '/newmalay/comprehension',
    component: NewMalayComprehensionMainComponent,
    name: 'new-malay-comprehension-main',
    children: [
      {
        path: '1',
        component: NewMalayComprehensionComponent,
        name: 'new-malay-comprehension',
      },
      {
        path: '',
        component: NewMalayComprehensionOverviewComponent,
        name: 'new-malay-comprehension-overview',
      },
    ],
  },
  {
    path: '/newlearn/learn',
    component: NewLearnMainComponent,
    name: 'new-learn-main',
    children: [
      {
        path: 'vocab',
        component: NewVocabIndexComponent,
        name: 'new-learn-vocab-index',
      },
      {
        path: 'worksheetdownload',
        component: NewWorksheetDownloadComponent,
        name: 'new-learn-worksheet-download',
      },
      {
        path: '',
        component: NewLearnOverviewComponent,
        name: 'new-learn-overview',
      },
    ],
  },
  {
    path: '/newcomprehension/comprehension',
    component: NewComprehensionMainComponent,
    name: 'new-comprehension-main',
    children: [
      {
        path: 'passage/:comprehensionId',
        component: NewComprehensionPassageComponent,
        name: 'new-comprehension-passage',
        props: (route) => {
          return {
            comprehensionId: Number(route.params.comprehensionId),
          };
        },
      },
      {
        path: 'passage',
        component: NewComprehensionPassageIndexComponent,
        name: 'new-comprehension-passage-index',
      },
      {
        path: 'meaning-search-practice',
        component: NewComprehensionMeaningSearchPracticeComponent,
        name: 'new-comprehension-meaning-search-practice',
      },
      {
        path: '',
        component: NewComprehensionOverviewComponent,
        name: 'new-comprehension-overview',
      },
    ],
  },
  {
    path: '/newcomposition/composition',
    component: NewCompositionMainComponent,
    name: 'new-composition-main',
    children: [
      {
        path: 'resources',
        component: NewCompositionResourcesComponent,
        name: 'new-composition-resources',
      },
      {
        path: 'lessons/:week',
        component: NewCompositionVideoLessonComponent,
        name: 'new-composition-lesson',
        props: (route) => {
          return {
            week: Number(route.params.week),
          };
        },
      },
      {
        path: 'lessons',
        component: NewCompositionVideoLessonIndexComponent,
        name: 'new-composition-lesson-index',
      },
      {
        path: '',
        component: NewCompositionOverviewComponent,
        name: 'new-composition-overview',
      },
    ],
  },
  {
    path: '',
    component: NewProfileMainComponent,
    name: 'profile-main',
  },
];
