import Vue from 'vue';
// import axios from 'axios';


const getDefaultState = () => {
  const results = {
    gameState: 'index',
    combatLog: [],
    // 0 === no winner yet, 1 === student won, 2 === enemy won
    winner: 0,
    gameValues: {},
    animationValues: {
      playStudentTurn: false,
      playEnemyTurn: false,
      playStudentDamaged: false,
      playEnemyDamaged: false,
      playStudentAtk: false,
      playEnemyAtk: false,
      playStudentProjectile: false,
      playEnemyProjectile: false,
      showSpellSelection: true,
    },
    // kiv -> should swap this something that reads off css sprite
    spellKey: '',
    damageTextValues: {
      student: {
        show: false,
        damage: 0,
        attackResult: 'miss',
      },
      enemy: {
        show: false,
        damage: 0,
        attackResult: 'miss',
      },
    },
    fetching: false,
  };

  return results;
};

const state = getDefaultState();

// changed name to dailyChallengeModule rather than dailyChallenge
// since it was easy to accidentally name a variable dailyChallenge
export const raid = {
  namespaced: true,
  state,
  mutations: {
    resetStateMutation(state) {
      Object.assign(state, getDefaultState());
    },
    goToGameState(state, value) {
      state.gameState = value;
    },
    setGameValues(state, value) {
      Vue.set(state, 'gameValues', value);
    },
    // appendToCombatLog(state, value) {
    //   state.combatLog.push(value);
    // },
    setPetHealthPercent(state, value) {
      Vue.set(state.gameValues, 'student_pet_health_percentage', value);
    },
    setEnemyHealthPercent(state, value) {
      Vue.set(state.gameValues, 'enemy_health_percentage', value);
    },
    setWinner(state, value) {
      Vue.set(state, 'winner', value);
    },
    setAnimationValue(state, payload) {
      Vue.set(state.animationValues, payload.key, payload.value);
    },
    setDamageTextValues(state, payload) {
      Vue.set(state.damageTextValues, payload.target, payload.value);
    },
    setSpellKey(state, value) {
      Vue.set(state, 'spellKey', value);
    },
    setFetching(state, value) {
      Vue.set(state, 'fetching', value);
    },
  },
  actions: {
    playBattleResultHelper({commit, state}, payload) {
      return new Promise((resolve, reject) => {
        const target = payload.isStudentTurn ? 'enemy' : 'student';
        commit('setSpellKey', payload.spell_key);

        // hide spell selection buttons for student
        if (payload.isStudentTurn) {
          commit('setAnimationValue', {
            key: 'showSpellSelection',
            value: false,
          });
        }

        commit('setAnimationValue', {
          key: (payload.isStudentTurn ? 'playStudentTurn' :
              'playEnemyTurn'),
          value: true,
        });

        commit('setAnimationValue', {
          key: (payload.isStudentTurn ? 'playStudentProjectile' :
              'playEnemyProjectile'),
          value: true,
        });

        // player turn text remove after 1.5s
        setTimeout(() => {
          commit('setAnimationValue', {
            key: (payload.isStudentTurn ? 'playStudentTurn' :
              'playEnemyTurn'),
            value: false,
          });
        }, 1500);
        // sprite attacks anim
        setTimeout(() => {
          commit('setAnimationValue', {
            key: (payload.isStudentTurn ? 'playStudentAtk' :
                'playEnemyAtk'),
            value: true,
          });
        }, 2500);
        setTimeout(() => {
          commit('setAnimationValue', {
            key: (payload.isStudentTurn ? 'playStudentAtk' :
                'playEnemyAtk'),
            value: false,
          });
        }, 2750);

        // target gets hit
        setTimeout(() => {
          commit('setAnimationValue', {
            key: (payload.isStudentTurn ? 'playEnemyDamaged' :
              'playStudentDamaged'),
            value: true,
          });
        }, 4000);
        setTimeout(() => {
          commit('setAnimationValue', {
            key: (payload.isStudentTurn ? 'playEnemyDamaged' :
              'playStudentDamaged'),
            value: false,
          });
        }, 6000);


        // projectile takes 2s, reduce health after that
        setTimeout(() => {
          commit('setDamageTextValues', {
            target: target,
            value: {
              show: true,
              damage: payload.damage,
              attackResult: payload.attack_result,
            },
          });

          // reset/hide damage text after 0.5s
          setTimeout(() => {
            commit('setDamageTextValues', {
              target: target,
              value: {
                show: false,
                damage: 0,
                attackResult: 'miss',
              },
            });
          }, 1500);

          // hide projectile
          commit('setAnimationValue', {
            key: (payload.isStudentTurn ? 'playStudentProjectile' :
              'playEnemyProjectile'),
            value: false,
          });

          if (payload.isStudentTurn) {
            commit('setEnemyHealthPercent', payload.enemy_health_percentage);
          } else {
            commit('setPetHealthPercent',
                payload.student_pet_health_percentage);
            // show the buttons again
            commit('setAnimationValue', {
              key: 'showSpellSelection',
              value: true,
            });
          };
          resolve(payload.game_result);
        }, 4500);
      });
    },
    playEnding({commit, dispatch, state}, payload) {
      if (payload.game_result === 'won') {
        state.winner = 1;
      } else {
        state.winner = 2;
        console.log('loss');
      }
      // reset button state {
      commit('setAnimationValue', {
        key: 'showSpellSelection',
        value: true,
      });
      commit('goToGameState', 'raidResult');
    },
    playBattleResults({commit, dispatch, state}, payload) {
      let toPass = payload.student;
      toPass['isStudentTurn'] = true;
      dispatch('playBattleResultHelper', toPass)
          .then((gameResult) => {
            // 1.5s delay between enemy's turn
            setTimeout(() => {
              // enemy's turn
              if (gameResult === 'not_complete') {
                toPass = payload.enemy;
                toPass['isStudentTurn'] = false;
                dispatch('playBattleResultHelper', toPass)
                    .then((enemyGameResults) => {
                      if (enemyGameResults === 'loss') {
                        toPass.diamonds = payload.diamonds;
                        return dispatch('playEnding', toPass);
                      };
                    });
              } else {
                toPass.diamonds = payload.diamonds;
                return dispatch('playEnding', toPass);
              };
            }, 1750);
          });
    },
  },
  getters: {
    projectileSrc(state) {
      if (state.spellKey === '') return '';
      if (state.spellKey === 'fireball') {
        return '../../../assets/raid/fireball.png';
      } else if (state.spellKey === 'waterball') {
        return '../../../assets/raid/waterball.png';
      } else if (state.spellKey === 'fireblast') {
        return '../../../assets/raid/fireblast.png';
      } else if (state.spellKey === 'waterblast') {
        return '../../../assets/raid/waterblast.png';
      };

      return '';
    },
    // lastActionLog(state) {
    //   if (state.combatLog.length === 0) return null;

    //   for (let i = state.combatLog.length - 1; i >= 0; i--) {
    //     if (state.combatLog[i].type === 'action') {
    //       return state.combatLog[i];
    //     };
    //   };

    //   return {};
    // },
  },
};
