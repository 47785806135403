<template>
  <li :class="dayClass">
    {{ day }}
  </li>
</template>

<script>

export default {
  name: 'DailyStreakCalendarDay',
  components: {
  },
  props: {
    // whether a user has login on this day
    isEarned: Boolean,
    day: String,
  },
  data() {
    return {
    };
  },
  computed: {
    // there is a 'missed' class that is not currently used
    // as we currently reset whenever a streak is missed
    dayClass() {
      if (this.isEarned) return 'earned';
      return 'default';
    },
  },
  mounted() {
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
  li {
    padding: 0.5rem;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 0.9rem;
    font-weight: 600;
  }

  .earned {
    color: #fff;
    background: #00d583;
  }

  .missed {
    color: #fff;
    background: #f9004e;
  }

  .default {
    color: #ffffff2b;
    background: #16161673;
  }

  @media only screen and (max-width: 1366px),
              screen and (max-height: 870px) {
    li {
      width: 1.25rem;
      height: 1.25rem;
      font-size: 0.75rem;
    }
  }
  @media only screen and (max-width: 1150px),
            screen and (max-height: 720px) {
    li {
      width: 1.1rem;
      height: 1.1rem;
      font-size: 0.65rem;
    }
  }

</style>
