<template>
  <div class="multiplayer__room--component">
    <div v-if="roomState === 0 || roomState === 2 || roomState === 4"
      class="multiplayer__room--header">
      <i v-on:click="exitPopup = true"
        v-if="roomState === 0 || roomState === 4"
        class="fas fa-times multiplayer__room--exit"></i>
      <div v-if="exitPopup"
        class="multiplayer__room--exit-popup animated fadeIn faster">
        <div class="multiplayer__room--exit-popup--container">
          <h3>
            Are you sure you want to exit?
          </h3>
          <div class="multiplayer__room--exit-popup--btns">
            <router-link
              :to="{name:'profile-main'}">
              Yes
            </router-link>
            <a v-on:click="exitPopup = false">No</a>
          </div>
        </div>
      </div>
    </div>
    <div v-if="roomState === 3"
      class="multiplayer__room--header multiplayer__room--header--revision">
     <h3>Revision</h3>
    </div>

    <!-- placeholder waiting -->
    <div v-if="roomState === 0">
      Loading Questions
    </div>
    <div v-if="roomState === 1"
      class="multiplayer__room--header multiplayer__room--header-question">
        题目：{{ questionData.question_index + 1 }} / {{ numQuestions }}
    </div>
    <room-question
      v-if="roomState === 1"
      :question-data="questionData"
      :answer-question="answerQuestion"
      :timer-duration="timerDuration">
    </room-question>
    <room-result
      v-if="roomState === 2"
      :correct-answer="correctAnswer"
      :student-answer="studentAnswer"
      :question-score="questionScore"
      :streak-bonus="streakBonus"
      :total-score="totalScore"
      :show-teaching-screen-callback="showTeachingScreen"
      :next-question-callback="nextQuestion">
    </room-result>
    <room-teaching
      v-if="roomState === 3"
      :question-data="questionData"
      :correct-answer="correctAnswer"
      :student-answer="studentAnswer"
      :next-question-callback="nextQuestion"></room-teaching>
    <room-end
      v-if="roomState === 4"
      :group-session="false"
      :ending-analytics="endingAnalytics"
      :assessment-book-id="quizId"
      :coins-to-add="coinsToAdd"
      :diamonds-to-add="diamondsToAdd"
      :diamonds-obtained-this-week="diamondsObtainedThisWeek"
    >
    </room-end>
    <div class="multiplayer__room--footer">
      <div class="multiplayer__room--footer__student--name">
        <p>{{ studentName }}</p>
<!--         <span>|</span>
        <p>{{ level | splitCapitalise }}</p> -->
      </div>
      <div class="multiplayer__room--footer__student--score">
        <i class="fas fa-trophy"></i>
        <span>{{ totalScore }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import {mapState, mapMutations} from 'vuex';

import {soundMixin} from '../../../mixins/sound';

import RoomEndComponent
  from '../../multiplayer/room/EndComponent.vue';

import RoomQuestionComponent
  from '../../multiplayer/room/QuestionComponent.vue';

import RoomResultComponent
  from '../../multiplayer/room/ResultComponent.vue';

import RoomTeachingComponent
  from '../../multiplayer/room/TeachingComponent.vue';

export default {
  name: 'LearningTrackAssessmentBookMain',
  components: {
    RoomQuestion: RoomQuestionComponent,
    RoomResult: RoomResultComponent,
    RoomTeaching: RoomTeachingComponent,
    RoomEnd: RoomEndComponent,
  },
  mixins: [soundMixin],
  props: {
    quizId: Number,
  },
  data() {
    return {
      // room states
      // 0 for waiting for system to load questions
      // 1 for when question is loaded
      // 2 for when results are shown
      // 3 for when student wants to see explanation
      // 4 for quiz end
      roomState: 0,
      questionData: {},
      sounds: {},
      // after answering a question, we will receive next question data
      nextQuestionData: {},
      numQuestions: -1,
      studentAnswer: '',
      correctAnswer: '',
      exitPopup: false,
      // note: quizId is different from quizAttemptId
      quizAttemptId: -1,
      questionAttemptId: -1,
      nextQuestionAttemptId: -1,
      quizEnded: false,
      winStreak: 0,

      // in seconds
      timerDuration: 3,
      endingAnalytics: null,
      questionScore: 0,
      totalScore: 0,

      startTimeResult: -1,
      timeSpentOnResult: 0,

      // after quiz has ended
      coinsToAdd: 0,
      diamondsToAdd: 0,
      diamondsObtainedThisWeek: 0,

      fetching: false,
    };
  },
  computed: {
    ...mapState(['studentId', 'studentName', 'level']),
  },
  mounted: function() {
    this.createNewQuiz();
  },
  watch: {
    $route(to, from) {
      if (to && to.name === 'learning-track-assessment-book') {
        this.createNewQuiz();
      }
    },
  },
  methods: {
    ...mapMutations(['setCoins', 'setDiamonds']),
    createNewQuiz() {
      console.log('creating new quiz');
      // resetting
      this.coinsToAdd = 0;
      this.diamondsToAdd = 0;
      this.roomState = 0;

      // creates quiz attempt and gets first question
      axios
          .post('/api/v1/brain/assessmentbookquiz/', {
            quizId: this.quizId,
            userAgent: window.navigator.userAgent,
          })
          .then((response) => {
            this.preload(JSON.parse(response.data['manifest']), (soundData) => {
              this.numQuestions = response.data['question']['num_questions'];
              this.sounds = soundData;
              this.questionData = response.data['question'];
              this.quizAttemptId = response.data['quiz_attempt_id'];
              this.questionAttemptId = response.data['question_attempt_id'];
              this.roomState = 1;
            });
          });
    },
    showTeachingScreen() {
      this.roomState = 3;
    },
    answerQuestion(choice) {
      if (this.fetching) return;
      this.fetching = true;

      this.studentAnswer = choice;
      const url = '/api/v1/brain/quizattempt/' +
        this.quizAttemptId + '/assessmentbookquestionattempt/';

      // the first time we answer, timeSpentOnResult is 0,
      // so adding it doesnt change anything
      axios
          .post(url, {
            questionAttemptId: this.questionAttemptId,
            attemptedAnswer: choice,
            timerDuration: this.timerDuration + this.timeSpentOnResult,
          })
          .then((response) => {
            this.fetching = false;
            console.log(response.data);
            this.questionScore = response.data['previous_question_score'];
            this.streakBonus = response.data['streak_bonus'];
            this.totalScore = response.data['total_score'];
            this.correctAnswer = response.data['previous_question_answer'];

            // for sounds
            // if correct, play correct sound and move on to next question
            if (response.data['previous_question_is_correct']) {
              this.winStreak++;

              // we want to add more variety to the sounds
              let soundToPlay;
              // play 'correct' sound if correct; 'perfect' only if we
              // got all questions correct (at the end of the quiz)
              if (this.winStreak % 10 === 0) {
                soundToPlay = 'perfect';
              } else if (this.winStreak % 3 === 0) {
                soundToPlay = 'correct_2';
              } else if (this.winstreak % 5 === 0) {
                soundToPlay = 'correct_3';
              } else {
                soundToPlay = 'correct';
              }
              this.sounds[soundToPlay].play();
            } else {
              // if wrong, reset winstreak and show teaching screen
              this.winStreak = 0;
              this.sounds['wrong'].play();
            }

            // note: we need to account for the time delay
            // when child is on teaching screen
            this.startTimeRoomResult = new Date();

            this.nextQuestionData = response.data['next_question'];
            this.nextQuestionAttemptId =
              response.data['next_question_attempt_id'];
            this.quizEnded = response.data['quiz_ended'];

            if (this.quizEnded) {
              console.log('quiz ended');
              this.coinsToAdd = response.data['coins_to_add'];
              this.setCoins(response.data['coins']);
            };

            if (typeof response.data['ending_analytics'] !== 'undefined') {
              this.endingAnalytics = response.data['ending_analytics'];
            };
            this.roomState = 2;
          })
          .catch((error) => {
            this.fetching = false;
            console.log(error);
          });
    },
    nextQuestion() {
      if (!this.quizEnded) {
        this.questionData = this.nextQuestionData;
        this.questionAttemptId = this.nextQuestionAttemptId;

        if (this.startTimeRoomResult !== -1) {
          this.timeSpentOnResult =
            (new Date() - this.startTimeRoomResult) / 1000;
        };

        this.roomState = 1;
      } else {
        this.roomState = 4;
      };
    },
  },
};
</script>

<style lang="scss" scoped>
  @import '~vuejs-noty/dist/vuejs-noty.css';

  .multiplayer__room {
    &--component {
      font-family: 'baloo da 2';
      position: fixed;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      z-index: 20;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      background: #e8e8e8;
    }
    &--exit {
      color: #a0a0a040;
      font-size: 1.8rem;
      cursor: pointer;
      &:hover {
        color: #a0a0a080;
      }
      &-popup {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #00000090;
        height: 100vh;
        width: 100vw;
        text-align: center;
        top: 0;
        left: 0;
        z-index: 30;
        &--container {
          padding: 2rem;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          background: #fff;
          border-radius: 5px;
          z-index: 20;
        }
        &--btns {
          margin-top: 20px;
          a {
            margin: 0 5px;
            display: inline-block;
            font-weight: 900;
            color: #fff;
            background: #e2e2e2;
            text-decoration: none;
            outline: none;
            border: 4px solid #c3c3c3;
            cursor: pointer;
            transition: all 0.3s;
            padding: 2.5px 10px;
            border-radius: 5px;
            width: 100px;
            &:hover {
              background: #969696;
              border: 4px solid #5d5d5d;
            }
          }
        }
      }
    }
    &--header,
    &--footer {
      position: absolute;
      background: #fff;
      width: 100%;
      padding: 10px 20px;
      font-weight: 900;
      font-size: 1.1rem;
    }
    &--header {
      border-bottom: 4px solid #555;
      top: 0;
      display: flex;
      justify-content: space-between;
      z-index: 20;
      height: 62px;
      &--revision {
        justify-content: center;
      }
      &-question {
        justify-content: center;
        border-bottom: 4px solid #222;
        color: #fff;
        background: #5e2c77;
        width: 100%;
      }
    }
    &--footer {
      border-top: 4px solid #555;
      bottom: 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      &__student {
        &--name {
          display: flex;
          span {
            margin: 0 10px;
          }
        }
        &--score {
          i {
            font-size: .85rem;
            color: #b7b7b7;
          }
          padding: 0px 15px;
          border-radius: 5px;
          background: #5f5f5f;
          color: #fff;
        }
      }
    }
    @media only screen and (max-width: 362px) {
      .multiplayer__room--footer {
        font-size: 0.6rem;
      }
    }
  }
</style>
