<template>
  <div class="pet-selection__component animated fadeIn faster">
    <h1 class="animated bounceIn">Select Your Pet:</h1>
    <div class="pet__list--container">
      <div v-for="(pet, index) in petsForSelection"
        :key="index"
        @click="selectPet(pet.pk)"
        class="pet__card--container">
        <div class="pet__card">
          <pet-image
            :pet-key="pet.pet_key">
          </pet-image>
        </div>
        <h3>{{ pet.family_name }}</h3>
      </div>
    </div>
<!--     <div>
      <div
        class="pet__card animated flipInX"
        @click="petSelected('male')">
        <img src="../../../assets/raid/panda-male.png">
      </div>
      <div
        class="pet__card animated flipInX delay-1"
        @click="petSelected('female')">
        <img src="../../../assets/raid/panda-female.png">
      </div>
    </div> -->
  </div>
</template>

<script>
// import {mapMutations} from 'vuex';

import PetImageComponent
  from '../../pet/PetImageComponent.vue';

export default {
  name: 'PetSelection',
  components: {
    PetImage: PetImageComponent,
  },
  props: {
    petsForSelection: Array,
  },
  data() {
    return {
    };
  },
  computed: {
  },
  mounted: function() {
    console.log(this.petsForSelection);
  },
  methods: {
    // ...mapMutations('raid', ['goToGameState']),
    selectPet(petId) {
      // this.selectPet(pet);
      this.$emit('startBattle', petId);
    },
  },
};
</script>

<style lang="scss" scoped>
.delay-1 {
  animation-delay: 0.1s
}

.pet-selection {
  &__component {
    font-family: 'baloo da 2';
    font-size: 1.5rem;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
    width: 100%;
    h2 {
      margin-bottom: 20px;
    }
  }
}
.pet {
  &__list--container {
    display: flex;
  }
  &__card {
    width: 300px;
    background: #bdc0c1;
    display: inline-flex;
    border-radius: 5px;
    border: 6px solid transparent;
    margin: 10px;
    cursor: pointer;
    transition: 0.2s all ease-in-out;
    opacity: 0.6;
    &:hover {
      border: 6px solid #000;
      opacity: 1;
    }
    &--container {
      text-align: center;
      h3 {
        opacity: 0;
        transform: translateY(-60%);
        transition: 0.2s all ease-in-out;
      }
      &:nth-child(1) {
        transform: scaleX(-1);
        h3 {
          transform: translateY(-60%) scaleX(-1);
        }
      }
    }
    i {
      font-size: 4.5rem;
      padding: 4rem 2rem;
    }
  }
}
.pet__card:hover + h3{
  opacity: 1;
  transform: translateY(0);
}

.pet__card--container:nth-child(1) .pet__card:hover + h3 {
  transform: translateY(0) scaleX(-1);
}

@media only screen and (max-height: 540px) {
  .pet__card {
    width: 250px;
  }
}
@media only screen and (max-height: 440px) {
  .pet-selection__component {
    h1 {
      font-size: 2.5rem;
    }
  }
  .pet__card {
    width: 200px;
  }
}
@media only screen and (max-width: 670px) {
  .pet__card {
    width: 250px;
  }
}
@media only screen and (max-width: 560px) {
  .pet-selection__component {
    h1 {
      font-size: 2.5rem;
    }
  }
  .pet__card {
    width: 200px;
  }
}
@media only screen and (max-width: 450px) {
  .pet__card {
    width: 150px;
    &--container {
      h3 {
        font-size: 1.25rem;
      }
    }
  }
}
@media only screen and (max-width: 370px) {
  .pet-selection__component {
    h1 {
      font-size: 2rem;
    }
  }
  .pet__card {
    width: 135px;
    &--container {
      h3 {
        font-size: 1.25rem;
      }
    }
  }
}
@media only screen and (max-height: 400px) {
  .pet-selection__component {
    h1 {
      font-size: 2.25rem;
    }
  }
  .pet__card {
    width: 150px;
    &--container {
      h3 {
        font-size: 1.25rem;
      }
    }
  }
}
</style>
