<!-- this is for revision -->
<template>
  <div class="quiz-component">
    <quiz-main
      quiz-type="learningTrackRevision"
      ></quiz-main>
  </div>
</template>

<script>

import {mapGetters} from 'vuex';
import QuizMainComponent from '../common/quiz/QuizMainComponent.vue';

export default {
  name: 'LearningTrackRevisionQuiz',
  components: {
    QuizMain: QuizMainComponent,
  },
  props: {
    quizId: Number,
    learningTrackType: String,
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters('learningTrack', ['getLearningTrackItemByQuizId']),
    learningTrackItem() {
      return this.getLearningTrackItemByQuizId(this.quizId);
    },
    quizType() {
      if (!this.learningTrackItem) return;

      return this.learningTrackItem.quiz_type ?
        this.learningTrackItem.quiz_type : 'story';
    },
  },
  destroyed() {
  },
  mounted: function() {
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
  .quiz-component {
    height: 100%;
  }
</style>
