<template>
  <div class="dynamic-book__overview">
    <div class="portrait-mode">
      <p>Please use Landscape Mode.</p>
    </div>
    <div class="landscape-mode">
      <div class="dynamic-book__overview--content">
        <div class="dynamic-book__overview--content-settings">
          <i
            class="fas fa-times"
            @click="exit"></i>
        </div>
        <div
          v-if="typeof scene.nextSceneId !== 'undefined'"
          class="dynamic-book__overview--content-nav">
          <button
            class="dynamic-book__btn dynamic-book__btn-previous"
            @click="_previous">
            <span class="dynamic-book__btn-text--left">上一页</span>
          </button>
          <button
            class="dynamic-book__btn dynamic-book__btn-next"
            @click="_next">
            <span class="dynamic-book__btn-text--right">下一页</span>
          </button>
        </div>
        <component
          :is="scene.name"
          v-bind="scene.options"
          :transition="scene.transition"
          :text-to-display="textToDisplay"
          class="chinese-character"
        >
          <read-aloud-audio
            :challenge-text-exists="challengeTextExists"
            ></read-aloud-audio>
        </component>
      </div>
    </div>
  </div>
</template>

<script>

import {mapState, mapMutations, mapGetters, mapActions} from 'vuex';

import ReadAloudAudioComponent
  from './common/ReadAloudAudioComponent.vue';

// need to figure out how to load dynamically? or need to load all
import TitleSceneComponent
  from './scenes/common/TitleSceneComponent.vue';

import NarrationSceneComponent
  from './scenes/common/NarrationSceneComponent.vue';

import ChoiceSceneComponent
  from './scenes/common/ChoiceSceneComponent.vue';

import FrameSceneComponent
  from './scenes/common/FrameSceneComponent.vue';

import FrameClickablePopupSceneComponent
  from './scenes/common/FrameClickablePopupSceneComponent.vue';

import TangyuanClickerSceneComponent
  from './scenes/common/TangyuanClickerSceneComponent.vue';


export default {
  name: 'DynamicBookOverview',
  components: {
    TitleScene: TitleSceneComponent,
    NarrationScene: NarrationSceneComponent,
    ChoiceScene: ChoiceSceneComponent,
    FrameScene: FrameSceneComponent,
    FrameClickablePopupScene: FrameClickablePopupSceneComponent,
    TangyuanClickerScene: TangyuanClickerSceneComponent,
    ReadAloudAudio: ReadAloudAudioComponent,
  },
  props: {
    bookId: Number,
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapState('dynamicBook', ['currentSceneId', 'difficulty']),
    ...mapGetters('dynamicBook', ['currentScene']),
    scene() {
      return this.currentScene;
    },
    challengeTextExists() {
      return this.scene.options.challengeText &&
        this.scene.options.challengeText.length > 0;
    },
    textToDisplay() {
      return this.challengeTextExists && this.difficulty === 1 ?
        this.scene.options.challengeText : this.scene.options.text;
    },
  },
  mounted: function() {
    this.setCurrentBookId(this.bookId);
    this.backgroundSound = new Howl({
      volume: 0.5,
      autoplay: true,
      loop: true,
      src: ['https://smartschool-static.s3-ap-southeast-1.amazonaws.com/media/dynamicbooks/2019_dec_holiday_camp/sounds/background_harmless_fun.m4a'],
    });
  },
  beforeDestroy() {
    this.backgroundSound.stop();
  },
  methods: {
    ...mapMutations('dynamicBook', ['setCurrentBookId']),
    ...mapActions('dynamicBook', ['nextScene', 'previousScene']),
    _next() {
      this.nextScene();
    },
    _previous() {
      this.previousScene();
    },
    exit() {
      this.$router.push({name: 'dynamic-book-index'});
    },
  },
};
</script>

<!-- not scoped -->
<style lang="scss">
  .single-heartbeat-animation {
    animation: 2s heartbeat;
  }

  .single-tada-animation {
    animation: 2s tada;
  }

  .single-shake-animation {
    animation: 2s shake;
  }

  .heartbeat-animation {
    animation: 3s infinite heartbeat;
  }

  @keyframes tada {
    from {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }

    10%,
    20% {
      -webkit-transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
      transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
    }

    30%,
    50%,
    70%,
    90% {
      -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
      transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
    }

    40%,
    60%,
    80% {
      -webkit-transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
      transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
    }

    to {
      -webkit-transform: scale3d(1, 1, 1);
      transform: scale3d(1, 1, 1);
    }
  }

  @keyframes shake {
    from,
    to {
      -webkit-transform: translate3d(0, 0, 0);
      transform: translate3d(0, 0, 0);
    }

    10%,
    30%,
    50%,
    70%,
    90% {
      -webkit-transform: translate3d(-10px, 0, 0);
      transform: translate3d(-10px, 0, 0);
    }

    20%,
    40%,
    60%,
    80% {
      -webkit-transform: translate3d(10px, 0, 0);
      transform: translate3d(10px, 0, 0);
    }
  }

  @keyframes heartbeat {
    0% {
      transform: scale(1);
    }
    10% {
      transform: scale(1.1);
    }
    25% {
      transform: scale(1);
    }
    40% {
      transform: scale(1.1);
    }
    60% {
      transform: scale(1);
    }
    100% {
      transform: scale(1);
    }
  }
</style>

<style lang="scss" scoped>
  .center {
    text-align: center !important;
  }

  /* common css classes */
  .frame {
    border: 5px solid black;
    padding: 10px;
    margin: 10px;
  }
  .dynamic-book {
    &__btn {
      border: none;
      width: 75px;
      height: 55px;
      background: #a7a7a7;
      color: #fff;
      position: relative;
      font-size: 0.7rem;
      cursor: pointer;
      &-text {
        &--left {
          margin-left: 10px;
        }
        &--right {
          margin-right: 10px;
        }
      }
      &-previous {
        -webkit-clip-path:
          polygon(40% 0%, 40% 20%, 100% 20%,
            100% 80%, 40% 80%, 40% 100%, 0% 50%);
        clip-path:
          polygon(40% 0%, 40% 20%, 100% 20%, 100% 80%,
            40% 80%, 40% 100%, 0% 50%);
      }
      &-next {
        margin-left: 10px;
        -webkit-clip-path:
          polygon(0% 20%, 60% 20%, 60% 0%,
            100% 50%, 60% 100%, 60% 80%, 0% 80%);
        clip-path:
          polygon(0% 20%, 60% 20%, 60% 0%,
            100% 50%, 60% 100%, 60% 80%, 0% 80%);

      }
    }
    &__overview {
      position: absolute;
      overflow: hidden;
      top: 0;
      bottom: 0;
      height: 100vh;
      width: 100vw;
      background: #424242;
      z-index: 30;
      &--content {
        height: 100%;
        position: relative;
        &-settings {
          position: absolute;
          top: 35px;
          left: 35px;
          z-index: 30;
          i {
            font-size: 2rem;
            color: #555;
          }
        }
        &-nav {
          position: absolute;
          top: 35px;
          right: 35px;
          z-index: 30;
        }
      }
    }
  }

  .portrait-mode {
    background: #4a4a4a;
    height: 100vh;
    width: 100vw;
    display: none;
    color: #fff;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 30;
    font-size: 2rem;
  }
  .landscape-mode {
    display: block;
    height: 100%;
  }

  @media only screen and
  (min-device-width : 768px) and
  (max-device-width : 1024px) and
  (orientation : portrait) {
    .portrait-mode {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .landscape-mode {
      display: none;
    }
  }

</style>
