<template>
  <div class="box">
    <div class="hero-container">
      <img src="../../assets/onboarding/1.png">
    </div>
    <narrator-popup
      :dialogue-data="dialogueData[dialogueIndex]"
      :callback="next"></narrator-popup>
  </div>
</template>

<script>

// import {mapState, mapGetters, mapActions} from 'vuex';

import NarratorPopupComponent
  from '../common/NarratorPopupComponent.vue';

export default {
  name: 'OnboardingMain',
  components: {
    NarratorPopup: NarratorPopupComponent,
  },
  props: {
  },
  watch: {
  },
  data() {
    return {
      dialogueIndex: 0,
      dialogueData: [
        {
          narrator: 'burnox',
          texts: ['Water. Earth. Fire. Air.', 'Long ago, the four nations lived together in harmony.'],
        },
        {
          narrator: 'mangsha',
          texts: ['Only the Avatar, master of all four elements, could stop them, but when the world needed him most, he vanished.'],
        },
        {
          narrator: 'rakoon',
          texts: ['A hundred years passed and my brother and I discovered the new Avatar, an airbender named Aang.'],
        },
        {
          narrator: 'flamebun',
          texts: ['And although his airbending skills are great, he has a lot to learn before he\'s ready to save anyone.'],
        },
        {
          narrator: 'burnox',
          texts: ['But I believe Aang can save the world.'],
        },
      ],
    };
  },
  computed: {
  },
  mounted() {
  },
  methods: {
    next() {
      if (this.dialogueIndex + 1 < this.dialogueData.length) {
        this.dialogueIndex++;
      } else {
        console.log('close');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  html, body {
    margin: 0px;
    height: 100%;
  }

  .hero-container {
    margin: auto;

    img {
      display: block;
      margin-left: auto;
      margin-right: auto;
      width: 50%;
    }
  }

  .box {
    z-index: 99;
    height:100vh;
    position: absolute;
    top: -65px;
    right: 0px;
    bottom: 0px;
    left: 0px;

    background: black;
  }
</style>
