<template>
  <div class="resources__component">
    <div class="resources__component--content">
      <div class="mobile-nav-icons">
        <i @click="isMobileSideNavShown = !isMobileSideNavShown" class="fa-solid fa-bars"></i>
        <span v-if="selectedCategory === 'openings'">开头</span>
        <span v-if="selectedCategory === 'proverbs'">俗语</span>
        <span v-if="selectedCategory === 'metaphors'">比喻句</span>
        <span v-if="selectedCategory === 'idioms'">成语</span>
        <i @click="goProfileMain" class="fa-solid fa-xmark"></i>
      </div>
      <filter-buttons
        :options="options[selectedCategory] || []"
        @select="selectSubcategory"
        :selected="selectedSubcategory"
      ></filter-buttons>
      <!-- opening/closing -->
      <div class="resources__component--content--item">
        <resources-paragraph
          v-for="(info, index) in filteredResults"
          :key="index"
          :info="info"
          :subcategory-index="getCategoryIndex(info.category)"
          :category="selectedCategory"
        ></resources-paragraph>
      </div>
    </div>
    <!-- desktop nav -->
    <div class="resources__component--nav-container">
      <div class="resources__component--nav animated slideInLeft">
        <div class="resources__component--nav--header">
          <i class="fa-solid fa-pen"></i>
          好词佳句
        </div>
        <ul class="resources__component--nav--routes">
          <li
            :class="{active: selectedCategory === 'openings'}"
            @click="selectCategory('openings')">
            开头
          </li>
          <li :class="{active: selectedCategory === 'proverbs'}"
            @click="selectCategory('proverbs')">
            俗语
          </li>
          <li :class="{active: selectedCategory === 'metaphors'}"
            @click="selectCategory('metaphors')">
            比喻句
          </li>
          <li :class="{active: selectedCategory === 'idioms'}"
            @click="selectCategory('idioms')">
            成语
          </li>
        </ul>
        <div @click="goCompoIndex" class="resources__component--nav--btn-home">
          <i class="fa-solid fa-house"></i>
          Return
        </div>
      </div>
    </div>
    <!-- mobile nav -->
    <div v-if="isMobileSideNavShown" class="resources__component--nav-container--mobile">
      <div class="resources__component--nav animated slideInLeft">
        <div class="resources__component--nav--header">
          <i class="fa-solid fa-pen"></i>
          好词佳句
        </div>
        <ul class="resources__component--nav--routes">
          <li
            :class="{active: selectedCategory === 'openings'}"
            @click="selectCategory('openings'); isMobileSideNavShown = !isMobileSideNavShown">
            开头
          </li>
          <li :class="{active: selectedCategory === 'proverbs'}"
            @click="selectCategory('proverbs'); isMobileSideNavShown = !isMobileSideNavShown">
            俗语
          </li>
          <li :class="{active: selectedCategory === 'metaphors'}"
            @click="selectCategory('metaphors'); isMobileSideNavShown = !isMobileSideNavShown">
            比喻句
          </li>
          <li :class="{active: selectedCategory === 'idioms'}"
            @click="selectCategory('idioms'); isMobileSideNavShown = !isMobileSideNavShown">
            成语
          </li>
        </ul>
        <div @click="isMobileSideNavShown = !isMobileSideNavShown"
          class="resources__component--nav--btn-close">
          <i class="fa-solid fa-xmark"></i>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
// import {mapState} from 'vuex';
// import {mapState, mapGetters, mapActions} from 'vuex';


import ResourcesParagraphComponent
  from './components/ResourcesParagraphComponent.vue';

import FilterButtonsComponent
  from './components/FilterButtonsComponent.vue';

export default {
  name: 'NewCompositionResources',
  components: {
    ResourcesParagraph: ResourcesParagraphComponent,
    FilterButtons: FilterButtonsComponent,
  },
  data() {
    return {
      // this controls the main category like opening, conclusion, etc
      selectedCategory: 'openings',
      // this controls filter button selections  - e.g. 'all', '回忆', '人物', '天气'
      selectedSubcategory: 'all',
      isMobileSideNavShown: false,
      results: {
        // manual sort fornow
        openings: [
          {
            title: '曾经发生的事',
            category: '回忆',
            text: '在我脑海中有许多的事，不少已被流水般的时光冲得模糊了，可有一件事却使我怎么也忘不掉。',
          },
          {
            title: '曾经发生的事',
            category: '回忆',
            text: '时间犹如过往云烟。一转眼，已经是几年过去了，可是这件事让我至今历历在目，记忆犹新。至于什么事，让我来给你说一说吧。',
          },
          {
            title: '曾经发生的事',
            category: '回忆',
            text: '光阴似箭，日月如梭，岁月像潮水一样把我的记忆冲淡了不少，但是我永远也忘不了那件往事。那日的情景至今仍历历在目……',
          },
          {
            title: '曾经发生的事',
            category: '回忆',
            text: '在我记忆的大海里，发生过的事儿不是被大浪卷走了，就是被大风刮走了，但是却有一件事使我至今难忘，记忆犹新。',
          },
          {
            title: '曾经发生的事',
            category: '回忆',
            text: '这件事我不记得发生在什么时候，我只记得这件事深深地刻在了我的心上，记在了我的脑海里，怎么抹也抹不去…',
          },
          {
            title: '感动的事',
            category: '回忆',
            text: '在我的脑海里，有许多令我感动的事情，它们都像海边的贝壳一串串的，但其中有一件事，令我最受感动，提起那件事，我至今记忆犹新。',
          },
          {
            title: '有意义的事',
            category: '回忆',
            text: '生活就像七彩的阳光，丰富多彩，有快乐的事、伤心的事，也少不了有意义的事。我就曾经做过一件有意义的事。',
          },
          {
            title: '难忘的事',
            category: '回忆',
            text: '六年的小学生活中，我经历过许许多多的事，随着时间的流逝，大都淡忘了，但唯有一件小事却给我留下了难忘的教训。',
          },
          {
            title: '美好的事',
            category: '回忆',
            text: '在我记忆的沙滩，往事就像海边那各种各样的贝壳，在海水的陪伴下闪闪发光，散发着五颜六色的光彩，数都数不完。今天，我就选择其中最美的一只呈现给你。',
          },
          {
            title: '犯错',
            category: '回忆',
            text: '古人云，“人非圣贤，孰能无过，知错能改，善莫大焉”。在这个世界上没有十全十美的人，每个人都会犯错，我也不例外。',
          },
          {
            title: '后悔',
            category: '回忆',
            text: '“世界上没有卖后悔药的”这句话我相信很多人都听说过。人犯了错误，往往都会后悔，我当然也不例外。',
          },
          {
            title: '后悔',
            category: '回忆',
            text: '世界上有许多珍贵的药，偏偏缺少了后悔药。哎，提起这件事就让我后悔莫及。我真想找个地洞钻进去，永远不要出来。',
          },
          {
            title: '后悔',
            category: '回忆',
            text: '今天，老师发下了华文考卷，我只有31分。看着考卷，我后悔极了，事情要从考试前三天开始说起……',
          },
          {
            title: '保护环境',
            category: '回忆',
            text: '“保护环境，人人有责”每当我看到这句话时，那天在熟食中心发生的事还历历在目。',
          },
          {
            title: '朋友',
            category: '回忆',
            text: '我的身边有很多朋友，但却没有一个能够和我同甘共苦的好朋友。直到有一天，发生了一件事，才让我找到了一个真正的朋友。',
          },
          {
            title: '邻居',
            category: '回忆',
            text: '俗话说“远亲不如近邻”，每当想起那件事我才真正地感悟到这个句子的含义。',
          },
          {
            title: '女生',
            category: '人物',
            text: '我的妈妈有一双炯炯有神的大眼睛，高高的鼻梁，一笑起来脸上有个小酒窝。她温柔体贴，是个心地善良的人，常常帮助身边有需要的人。',
          },
          {
            title: '女生',
            category: '人物',
            text: '苗苗是个勤奋好学，乐于助人。她机灵可爱，而且能歌善舞，连我都比不上她。但是值得我学习的地方是她拾金不昧的行为。',
          },
          {
            title: '男生',
            category: '人物',
            text: '文华个子不高，圆圆的脸上有一双有神的眼睛，笑起来眼睛眯一条缝。他是个爱笑的人，走到哪里都会把幽默和欢乐带给别人。',
          },
          {
            title: '男生',
            category: '人物',
            text: '我的表哥长得高大强壮，他爱到处打抱不平，是一个见义勇为的人。',
          },
          {
            title: '个性',
            category: '人物',
            text: '我长得聪明伶俐、活泼开朗，人见人爱。不过，我有一个让人都头痛的习惯，那就是贪玩。全家人想尽了各种办法，但都无济于事。',
          },
          {
            title: '个性',
            category: '人物',
            text: '小时候，我聪明伶俐，但调皮的我总爱做些“恶作剧”，弄得大家哭笑不得，大家都叫我“开心果。” 现在就让我打开记忆的大门，向大家诉说一个最有趣的“恶作剧”吧！',
          },
          {
            title: '晴天',
            category: '天气',
            text: '这天早上，阳光明媚，微风轻抚着大地，空气是那么清新。枝头的鸟儿叽叽喳喳地唱起了欢快的歌儿，小草慢慢地伸出了双手向我们打招呼。',
          },
          {
            title: '晴天',
            category: '天气',
            text: '一个晴空万里的星期天早上，万里无云，微风吹拂。天晴得像一张蓝纸，蔚蓝的天空偶尔漂浮着几朵淡淡的云彩。',
          },
          {
            title: '炎热',
            category: '天气',
            text: '今天可真热呀！太阳像个大火球似的高高挂在天上，发出万道光芒，烤得大地直冒烟。花儿热得低下了头，鸟儿也躲在树上休息。',
          },
          {
            title: '乌云密布',
            category: '天气',
            text: '午后，天边突然乌云密布，天空瞬间黑了下来，好像一张黑色的大网把大地罩了起来。天空中还不时有震耳欲聋的雷声和刺眼的闪电，眼看一场暴风雨就要来临了。',
          },
          {
            title: '下雨',
            category: '天气',
            text: '刚才晴空万里，突然雷声隆隆，狂风大作，哗啦啦地下起倾盆大雨。路上的行人急忙找地方避雨，来不及避雨的人都变成了“落汤鸡”。',
          },
          {
            title: '上午',
            category: '天气',
            text: '一个星期六的上午，太阳早早地挂在天空上，金色的阳光照在身上暖洋洋的，好舒服呀！',
          },
          {
            title: '下午',
            category: '天气',
            text: '记得那是一个星期天的下午，天晴得像一张蓝纸，几片薄薄的白云，像被阳光晒化了似的，随风缓缓浮游着。',
          },
          {
            title: '夕阳',
            category: '天气',
            text: '夕阳西下，微风轻轻地吹着，暖暖的阳光覆盖着大地。我和几个好朋友在组屋楼下像快活的小鸟一样溜滑板。',
          },
          {
            title: '通用',
            category: '声音',
            text: '“铃铃铃……” 闹钟不知响了几遍，猛然听到房间外妈妈的敲门声，把我从美梦中惊醒。我揉了揉惺忪的眼睛，顺手拿起床头的闹钟。糟啦！上学要迟到了！我飞身一跳，以迅雷不及掩耳之势，穿上校服直奔学校。',
          },
          {
            title: '通用',
            category: '声音',
            text: '“铃铃铃……” 美妙悦耳的下课铃声又飘扬在校园空中，同学们不约而同地走去操场和食堂。安静的校园顿时喧闹起来，同学们的欢声笑语声在校园上空回荡。',
          },
          {
            title: '助人为乐',
            category: '设问',
            text: '我想大家都知道幸福是什么意思吧？如果你们让我说，那我觉得幸福一定是助人快乐。如果你们觉得不是，那我就和大家说一说这件事，就知道了.......',
          },
          {
            title: '诚实',
            category: '总结',
            text: '做人，就要诚实，诚实的人是快乐的。',
          },
        ],
        proverbs: [
          {
            title: '⼀朝被蛇咬，⼗年怕井绳',
            category: '开头结尾',
            definition: '"once bitten by a snake, fearful of ropes for years," describing lasting fear after a bad experience',
            chineseDefinition: '比喻经历一次挫折以后就变得胆小怕事',
            text: '所谓“一朝被蛇咬，十年怕井绳”， 虽然这件惊险的经历已经过去很久，但至今还会浮现在她的脑海里，久久挥之不去。',
          },
          {
            title: '经一事，长一智',
            category: '开头结尾',
            definition: '""gain wisdom from experience," emphasizing learning and growing smarter through life\'s challenges',
            chineseDefinition: '亲身经历了某件事情，就能增长关于这方面的知识',
            text: '所谓“经一事，长一智”，妈妈以后再也不敢拿贵重的名牌包独自走偏僻的小巷了。',
          },
          {
            title: '人非圣贤，孰能无过，知错能改，善莫大焉',
            category: '开头结尾',
            definition: 'everyone can learn from their mistakes',
            chineseDefinition: '比喻每个人都会犯错，重要的是知错能改',
            text: '古人云，“人非圣贤，孰能无过，知错能改，善莫大焉”。在这个世界上没有十全十美的人，每个人都会犯错，我也不例外。',
          },
          {
            title: '知错能改，善莫大焉',
            category: '开头结尾',
            definition: 'everyone can learn from their mistakes',
            chineseDefinition: '比喻每个人都会犯错，重要的是知错能改',
            text: '古人有句话“知错能改，善莫大焉。”从那以后，每当我再要犯错误时，就会想起老师和父母的教导，再也不会重蹈覆辙。',
          },
          {
            title: '早知今日，何必当初',
            category: '开头结尾',
            definition: 'regretting past actions after realizing the consequences',
            chineseDefinition: '后悔当初没有听从劝告',
            text: '我后悔极了，“早知今日，何必当初”，经过了这次教训，我以后再也不敢恶作剧了。',
          },
          {
            title: '马路如虎口',
            category: '开头结尾',
            definition: 'roadways are as dangerous as tigers\' mouths, warning of traffic hazards',
            chineseDefinition: '比喻过马路时要小心，因为马路上有很多危险',
            text: '这起车祸让我终于明白了“马路如虎口”的意思。我们过马路时一定要遵守交通规则，注意来往的车辆，这样才能避免车祸的发生。',
          },
          {
            title: '助人为快乐之本',
            category: '开头结尾',
            definition: 'helping others brings happiness',
            chineseDefinition: '帮助别人会带来快乐',
            text: '从那以后，每当我帮助别人的时候，我的心里真的无比快乐，我终于领悟到了“助人为快乐之本” 这句话的意思。',
          },
          {
            title: '赠人玫瑰，手留余香',
            category: '开头结尾',
            definition: 'helping others benefits others and makes you happy as well',
            chineseDefinition: '帮助别人不仅对他人有益，自己也能收获快乐和美好',
            text: '经过这件事，我深深地体会到：帮助别人自己也能从中得到快乐。古人说：“赠人玫瑰，手留余香。”说的就是这个道理。',
          },
          {
            title: '世上无难事，只怕有心人',
            category: '开头结尾',
            definition: 'with determination, nothing is impossible',
            chineseDefinition: '只要下定决心去做，世界上根本就没有做不好的事',
            text: '“世上无难事，只怕有心人”，这件事让我明白了，做任何事情，只要下定决心去做，困难总是可以克服的，世界上根本就没有做不好的事。',
          },
          {
            title: '失败乃成功之母',
            category: '开头结尾',
            definition: 'failure is the mother of success',
            chineseDefinition: '失败能带来经验和教训，为今后的成功打下基础',
            text: '经过了这件事，我明白了“失败乃成功之母”的意义， 失败了没关系，但是我们不要失去信心，每失败一次，离成功就近一步。',
          },
          {
            title: '一寸光阴一寸金，寸金难买寸光阴',
            category: '开头结尾',
            definition: 'it\'s important to not waste time - time is as valuable as gold, and cannot be replaced by gold',
            chineseDefinition: '时间宝贵，不能浪费',
            text: '俗话说“一寸光阴一寸金，寸金难买寸光阴”，通过这件事，让我明白了一个深刻道理：我们要珍惜时间，在以后的日子里我要和时间赛跑，完成一件又一件事。',
          },
          {
            title: '远亲不如近邻',
            category: '开头结尾',
            definition: 'distant relatives are not as good as nearby neighbors',
            chineseDefinition: '远方的亲戚不如近处的邻居重要',
            text: '俗话说“远亲不如近邻”，这件事已经过去好几年了，那位邻居阿姨虽然搬走了，但是她对我那无微不至的关心，我永远不会忘记。',
          },
          {
            title: '天有不测风云',
            category: '描写',
            definition: 'life is full of unpredictable changes',
            chineseDefinition: '人生充满不可预料的变化，就像天气突变一样，祸福无常，无法预见',
            text: '一个假期的上午，我们一家人兴高采烈地外出旅行，可天有不测风云，走到半路时，忽然下起了一场倾盆大雨。',
          },
          {
            title: '天网恢恢，疏而不漏',
            category: '描写',
            definition: 'no matter how small or insignificant the crime, it will not escape punishment',
            chineseDefinition: '比喻无论多么狡猾的犯罪分子，都逃不过法律的制裁',
            text: '所谓“天网恢恢，疏而不漏”，小偷被警察带去警察局。警察叔叔竖起大拇指夸奖我是个见义勇为的小英雄，我感到非常自豪，心里甜滋滋的。',
          },
          {
            title: '不听老人言，吃亏在眼前',
            category: '描写',
            definition: 'ignoring elders\' advice will lead to immediate mistakes or negative consequences.',
            chineseDefinition: '老人们因为经历的多，对很多事情有着自己的丰富经验，他的劝告都有一定的道理，全盘不听，就会有吃亏的时候',
            text: '妈妈经常提醒我不要轻易相信陌生人，我不听， 结果被人骗了，真是不听老人言，吃亏在眼前啊。',
          },

          {
            title: '君子动口不动手',
            category: '描写',
            definition: 'resolve conflicts with dialogue and reason rather than resorting to physical violence',
            chineseDefinition: '讲道理，不打架',
            text: '冬冬走上前去，劝告他们说：“你们别打啦，君子动口不动手。”',
          },
          {
            title: '不管三七二十一',
            category: '描写',
            definition: ' acting impulsively without considering consequences or thinking through the situation',
            chineseDefinition: '不顾一切；表示对什么事都不管不顾',
            text: '这时我不管三七二十一冲出去，朝家的方向奔去。',
          },
          {
            title: '哭笑不得',
            category: '描写',
            definition: 'feeling awkward or unsure whether to laugh or cry in a difficult situation',
            chineseDefinition: '哭也不好，笑也不好。形容很尴尬',
            text: '小刚的行为令老师和同学们哭笑不得。',
          },
          {
            title: '空口说白话',
            category: '描写',
            definition: 'making empty promises or talking without taking any action to back up the words',
            chineseDefinition: '形容光说不做事',
            text: '如果一直空口说白话，时间长了是没有人相信你的。',
          },
          {
            title: '乱成一锅粥',
            category: '描写',
            definition: 'completely chaotic or disorganized',
            chineseDefinition: '意思是形容秩序非常混乱',
            text: '老师去开会了，这时教室里乱成了一锅粥。',
          },
        ],
        metaphors: [
          {
            'title': '太阳',
            'category': '拟人句',
            'text': '今天，太阳好害羞，躲在厚厚的云层后面，迟迟不肯露面。',
          },
          {
            'title': '太阳',
            'category': '拟人句',
            'text': '太阳是个害羞的小姑娘，遮遮掩掩，躲躲藏藏。',
          },
          {
            'title': '花儿',
            'category': '拟人句',
            'text': '花儿在风中笑弯了腰。',
          },
          {
            'title': '星星',
            'category': '拟人句',
            'text': '宁静的夜晚，只有那天上的星星在窃窃私语。',
          },
          {
            'title': '雨',
            'category': '拟人句',
            'text': '顽皮的雨滴最爱在雨伞上尽情地跳舞。',
          },
          {
            'title': '高兴',
            'category': '心情',
            'text': '听到这个好消息，姐妹俩手拉着手跳着，花裙子随风摆动，好像一对活泼、愉快的蝴蝶。',
          },
          {
            'title': '高兴',
            'category': '心情',
            'text': '我和弟弟高兴的手舞足蹈，活脱脱像个小松鼠。',
          },
          {
            'title': '高兴',
            'category': '心情',
            'text': '美华高兴得像一只欢快的喜鹊，“叽叽喳喳”地叫个不停。',
          },
          {
            'title': '高兴',
            'category': '心情',
            'text': '我一听，顿时欣喜若狂，两只眼睛眯得像两个小小的月牙儿。',
          },
          {
            'title': '高兴',
            'category': '心情',
            'text': '小美接过爸爸送给她的精美的手表，顿时心花怒放，两只眼睛眯得像两个小小的月牙儿',
          },
          {
            'title': '高兴',
            'category': '心情',
            'text': '她高兴得嘴巴向上翘，变成一弯月牙儿。',
          },
          {
            'title': '高兴',
            'category': '心情',
            'text': '花儿在灿烂地微笑，鸟儿在快乐地叫着，我的心情啊，像吃了蜜一样甜。',
          },
          {
            'title': '紧张',
            'category': '心情',
            'text': '我紧张的低着头，不敢看爸爸那张阴云密布的脸。',
          },
          {
            'title': '紧张',
            'category': '心情',
            'text': '他全身紧张得像块石头，心几乎提到了嗓子眼。',
          },
          {
            'title': '紧张',
            'category': '心情',
            'text': '我的心中好像有一面小鼓，一直在“咚咚咚”的敲着。',
          },
          {
            'title': '紧张',
            'category': '心情',
            'text': '我的心里像有十五只水桶打水，七上八下的，久久不能平静。',
          },
          {
            'title': '紧张',
            'category': '心情',
            'text': '我紧张的心情扑通扑通，就像心里有只兔子不停地跳着。',
          },
          {
            'title': '紧张',
            'category': '心情',
            'text': '我的心像压着一块大石头，不敢吐大气，生怕一张嘴，已经提到嗓子眼的心就会掉出来。',
          },
          {
            'title': '紧张',
            'category': '心情',
            'text': '小华的心里像有块无形的大石头压着，气都喘不过来了。',
          },
          {
            'title': '生气',
            'category': '心情',
            'text': '小华牙齿咬得“咯咯”作响，火冒三丈地大喊起来。',
          },
          {
            'title': '生气',
            'category': '心情',
            'text': '他红着眼睛，像一头发疯的狮子，样子十分可怕。',
          },
          {
            'title': '生气',
            'category': '心情',
            'text': '他越听越气，气得浑身发抖，眼睛冒着火光。',
          },
          {
            'title': '生气',
            'category': '心情',
            'text': '他气得全身瑟瑟发抖，脸青唇白，一句话也说不出来。',
          },
          {
            'title': '生气',
            'category': '心情',
            'text': '她气得脸像阴天一样，黑沉沉的。',
          },
          {
            'title': '生气',
            'category': '心情',
            'text': '他被激怒了，像只咆哮的狮子似的。',
          },
          {
            'title': '心情低落',
            'category': '心情',
            'text': '今天拿到了考卷，苦闷的内心像是乌云布满了天空一样。',
          },
          {
            'title': '心情低落',
            'category': '心情',
            'text': '形影不离的好朋友移居到国外生活，我的心里像断了线的风筝一样。',
          },
          {
            'title': '心情低落',
            'category': '心情',
            'text': '我的心顿时像被浇了一大盆冷水似的。',
          },
          {
            'title': '伤心',
            'category': '心情',
            'text': '他那双眼睛像拉开的闸门，泪水止不住地流了出来。',
          },
          {
            'title': '惭愧',
            'category': '心情',
            'text': '我觉得脸火辣辣的，手心冒出冷汗，心头好像有千万只蚂蚁爬过。',
          },
          {
            'title': '惭愧',
            'category': '心情',
            'text': '我的心里像开了锅的水一样翻滚，通红的脸像是着了火似的。',
          },
          {
            'title': '惭愧',
            'category': '心情',
            'text': '明华惭愧得无地自容，他的脸涨得通红通红的，像一个熟透的苹果。',
          },
          {
            'title': '惊吓',
            'category': '心情',
            'text': '她吓得心一下紧缩起来，好像冰凉的蛇爬上了后背。',
          },
          {
            'title': '惊吓',
            'category': '心情',
            'text': '我的心里七上八下，此时就像失足掉进热水里的鱼。',
          },
          {
            'title': '惊吓',
            'category': '心情',
            'text': '她吓得面如土色，好像见到鬼似的拔腿就跑。',
          },
          {
            'title': '惊吓',
            'category': '心情',
            'text': '他完全惊呆了，好像木头人一样一动也不动。',
          },
          {
            'title': '感动',
            'category': '心情',
            'text': '这件事真让人感动，心里像喝了蜂蜜似的甜丝丝的。',
          },
          {
            'title': '感动',
            'category': '心情',
            'text': '小华感动得泪如涌泉，不停地向我道谢。',
          },
          {
            'title': '感动',
            'category': '心情',
            'text': '你就像风雨中为我遮风挡雨的一把大伞，让我感到无比的温暖。',
          },
          {
            'title': '感动',
            'category': '心情',
            'text': '我的内心十分激动，像波涛汹涌的大海一样。',
          },
          {
            'title': '感动',
            'category': '心情',
            'text': '我听到这里，低下了头，泪水不自觉在眼眶中打转，泪水模糊了我的双眼。',
          },
          {
            'title': '感动',
            'category': '心情',
            'text': '一句句安慰的话，像一屡屡阳光照进我的心里。',
          },
          {
            'title': '马路',
            'category': '场景',
            'text': '马路上车水马龙，一排排的汽车如蚂蚁般秩序井然地行驶着。',
          },
          {
            'title': '马路',
            'category': '场景',
            'text': '马路上的出租车一辆接一辆，远远望去像一只只爬行的小甲虫。',
          },
          {
            'title': '马路',
            'category': '场景',
            'text': '这辆车的速度像火箭一样快。',
          },
          {
            'title': '马路',
            'category': '场景',
            'text': '汽车开的飞快,快得像闪电一闪而过。',
          },
          {
            'title': '马路',
            'category': '场景',
            'text': '随着一声震耳欲聋的声音传来，一辆摩托车像脱缰的野马一样冲了出来。',
          },
          {
            'title': '教室',
            'category': '场景',
            'text': '教室里同学们的吵闹声，好像蜜蜂在耳边嗡嗡直叫，脑袋都快炸掉了。',
          },
          {
            'title': '教室',
            'category': '场景',
            'text': '同学们在班里横冲直撞，桌子歪歪扭扭的，整个教室凌凌乱乱的，像刚打完仗似的。',
          },
          {
            'title': '教室',
            'category': '场景',
            'text': '闹哄哄的教室突然安静了下来，好像被关了静音一样，鸦雀无声。',
          },
          {
            'title': '教室',
            'category': '场景',
            'text': '老师前脚刚走出教室，教室里就乱成一团。几个调皮的学生在教室里竟然玩起你追我跑的游戏，把教室当成了游乐场。',
          },
          {
            'title': '教室',
            'category': '场景',
            'text': '下课铃声刚响，教室里就沸腾起来了。同学们迫不及待地离开了座位，原本上课时安静的教室立刻闹成了一团。',
          },
          {
            'title': '熟食中心',
            'category': '场景',
            'text': '一到午餐时间，熟食中心人山人海，座无虚席，每个摊位前都排起了长长的人龙。',
          },
          {
            'title': '大自然',
            'category': '场景',
            'text': '一朵朵的云像洁白的羽毛，轻轻地飘浮在空中。',
          },
          {
            'title': '大自然',
            'category': '场景',
            'text': '一阵风吹来，花儿摇了几下，那样子多迷人，真像一个个穿花裙的姑娘在跳着优美的舞蹈。',
          },
          {
            'title': '大自然',
            'category': '场景',
            'text': '一片片叶子从大树上落下，有的像一只只蝴蝶翩翩起舞。',
          },
          {
            'title': '大自然',
            'category': '场景',
            'text': '弯弯的月儿像小船。',
          },
          {
            'title': '大自然',
            'category': '场景',
            'text': '圆圆的月亮像一个碧玉盘。',
          },
          {
            'title': '大自然',
            'category': '场景',
            'text': '天空中的太阳好像一个大火球烧烤着大地。',
          },
          {
            'title': '瘦弱',
            'category': '人物',
            'text': '他身体非常瘦弱，像一根棍子一样站在那里，好像一阵风就将他吹倒一样。',
          },
          {
            'title': '瘦弱',
            'category': '人物',
            'text': '这场大病把王爷爷折磨得骨瘦如柴，令人心痛。',
          },
          {
            'title': '瘦弱',
            'category': '人物',
            'text': '她的脸上没有肉，身体又瘦又直，像根竹竿。',
          },
          {
            'title': '强壮',
            'category': '人物',
            'text': '他，高高的个子，大块头，像一只大黑熊似的。',
          },
          {
            'title': '强壮',
            'category': '人物',
            'text': '他浓眉大眼，身材高大，站着就像一座石塔，跑起来有如一阵狂风。',
          },
          {
            'title': '强壮',
            'category': '人物',
            'text': '表哥比我高出足有一个头，那强壮的身体，好像一棵挺拔的大树。',
          },
          {
            'title': '其他',
            'category': '人物',
            'text': '一双圆圆的大眼睛，眼珠滴溜儿直转，好像两颗黑珍珠。',
          },
          {
            'title': '凶',
            'category': '人物',
            'text': '这个男子戴着一副墨镜，阴云密布的脸好像一场暴风雨就要来临。',
          },
          {
            'title': '凶',
            'category': '人物',
            'text': '他一脸凶相，好像一头狮子要把人吃掉似的。',
          },
          {
            'title': '身体不舒服',
            'category': '人物',
            'text': '他看起来脸色苍白，豆大的汗水不停地从额头冒出，手还一直按着肚子。',
          },
          {
            'title': '小偷',
            'category': '人物',
            'text': '男子身材瘦小，穿着一件花衬衫，长得贼眉鼠眼，一直东张西望，行迹十分可疑。',
          },
          {
            'title': '妇女',
            'category': '人物',
            'text': '摊主是一位中年妇女，她穿着一件宽松的上衣，顶着一头乌黑的卷发，说起话来声音十分响亮。',
          },
          {
            'title': '叔叔',
            'category': '人物',
            'text': '张叔叔是个德士司机。他的身材高大，经常面带微笑，为人老实。每天早出晚归，经常乐于助人，给乘客带来很多方便。',
          },
          {
            'title': '烦恼',
            'category': '人物',
            'text': '下课的铃声响起，同学们三五成群地走出教室。可是明华却坐在那愁眉苦脸，唉声叹气。',
          },
          {
            'title': '动物',
            'category': '人物',
            'text': '我家的小狗是活泼可爱，穿着一身金黄色的外衣，长着黑珍珠似的眼睛。毛茸茸的大尾巴，还有一双像梅花一样的脚。',
          },
          {
            'title': '动物',
            'category': '人物',
            'text': '仓鼠的眼睛很小，就像两颗小黑豆，身子毛茸茸的，好像一团毛球。',
          },
          {
            'title': '动物',
            'category': '人物',
            'text': '我家有一只兔子，它的毛像一团白棉花，又细又密。',
          },
          {
            'title': '吃东西',
            'category': '动作',
            'text': '一股清香的味道飘来，我便迫不及待地夹上一块鲜美的鸡肉，那味道让我好像处在九霄云外。',
          },
          {
            'title': '吃东西',
            'category': '动作',
            'text': '看到牛肉面里肥美的汁肉，我像一匹饿狼扑了过去，享受着牛肉的美味。',
          },
          {
            'title': '吃东西',
            'category': '动作',
            'text': '看到香喷喷的鸡腿，肚子里的一条条馋虫好像要爬出来了。',
          },
          {
            'title': '勇敢',
            'category': '行为',
            'text': '张华勇猛地站出来， 像一个巨人一样站在小偷面前，一动不动。',
          },
          {
            'title': '勇敢',
            'category': '行为',
            'text': '士兵们冒着子弹和雨水，像猛虎似的冲向战场，英勇地杀死了敌人。',
          },
          {
            'title': '勇敢',
            'category': '行为',
            'text': '一座组屋着火了，消防战士像草原上的狼一样冲进火海。',
          },
          {
            'title': '勇敢',
            'category': '行为',
            'text': '警察像离弦的箭冲过去，抓住了正在逃跑的小偷。',
          },
          {
            'title': '作弊',
            'category': '行为',
            'text': '他左顾右盼，假装在思考着问题，眼睛却偷偷地向同桌的考卷瞄了一眼。',
          },
          {
            'title': '溜滑板',
            'category': '行为',
            'text': '我只要一上滑板就可以滑得飞快，像一只飞翔的鸟儿，溜来溜去。',
          },
          {
            'title': '溜滑板',
            'category': '行为',
            'text': '小光像离弦的箭一样滑了出去，还得意洋洋地大喊着。',
          },
          {
            'title': '溜滑板',
            'category': '行为',
            'text': '小华脚底下的滑板像一辆来不及刹车的汽车一样，冲了出去撞到了老奶奶。',
          },
        ],
        idioms: [
          {
            'title': '⾼兴',
            'category': '心情',
            'word': '欣喜若狂',
            'definition': '',
            'chineseDefinition': '高兴得像发狂一样。形容高兴到了极点。',
            'text': '听到老师要组织我们去参观美术馆，全班同学都欣喜若狂。',
          },
          {
            'title': '⾼兴',
            'category': '心情',
            'word': '眉开眼笑',
            'definition': '',
            'chineseDefinition': '眉头舒展，眼含笑意。形容非常高兴的神态。',
            'text': '一听到这个好消息，他高兴得眉开眼笑。',
          },
          {
            'title': '⾼兴',
            'category': '心情',
            'word': '喜出望外',
            'definition': '',
            'chineseDefinition': '遇上出乎意料的好事而感到特别高兴。',
            'text': '生日时收到芭比娃娃，令小女孩喜出望外。',
          },
          {
            'title': '⾼兴',
            'category': '心情',
            'word': '满心欢喜',
            'definition': '',
            'chineseDefinition': '形容非常高兴。',
            'text': '姐姐满心欢喜地打开妈妈从日本买来的生日礼物。',
          },
          {
            'title': '⾼兴',
            'category': '心情',
            'word': '欢呼雀跃',
            'definition': '',
            'chineseDefinition': '高兴得像麻雀一样跳跃。形容非常欢乐',
            'text': '听到我们班获胜的消息，大家欢呼雀跃。',
          },
          {
            'title': '⾼兴',
            'category': '心情',
            'word': '心花怒放',
            'definition': '',
            'chineseDefinition': '心里高兴得像花儿盛开一样。形容极其高兴。',
            'text': '他正在客厅心花怒放地谈他获奖的经过',
          },
          {
            'title': '紧张',
            'word': '怦怦直跳',
            'category': '心情',
            'definition': '',
            'chineseDefinition': '形容由于紧张或者惊吓而心跳加快。',
            'text': '到了考场，我的心不由自主地怦怦直跳，紧张极了。',
          },
          {
            'title': '紧张',
            'word': '结结巴巴',
            'category': '心情',
            'definition': '',
            'chineseDefinition': '形容说话不流利。',
            'text': '他结结巴巴地讲出了事情的经过。',
          },
          {
            'title': '紧张',
            'word': '坐立不安',
            'category': '心情',
            'definition': '',
            'chineseDefinition': '坐着也不是，站着也不是。形容心 情紧张，情绪不安。',
            'text': '这么晚了，哥哥还没有回家，妈妈急得坐立不安。',
          },
          {
            'title': '紧张',
            'word': '语无伦次',
            'category': '心情',
            'definition': '',
            'chineseDefinition': '讲话讲得乱七八糟，毫无次序。',
            'text': '华强上课偷偷看故事书，突然老师叫他回答问题，他语无伦次，结果也没回答对问题。',
          },
          {
            'title': '紧张',
            'word': '手足无措',
            'category': '心情',
            'definition': '',
            'chineseDefinition': '临事慌张，不知如何是好。',
            'text': '丽丽都被眼前突然发生的事情弄得手足无措。',
          },
          {
            'title': '紧张',
            'word': '七上八下',
            'category': '心情',
            'definition': '',
            'chineseDefinition': '形容心神不定或烦乱不安。',
            'text': '小刚听说校长叫他，心里七上八下的，不知道有什么事。',
          },
          {
            'title': '勇敢',
            'word': '一马当先',
            'category': '心情',
            'definition': '',
            'chineseDefinition': '比喻走在最前面，起带头作用。',
            'text': '无论班级有什么事，班长总是一马当先。',
          },
          {
            'title': '勇敢',
            'word': '奋不顾身',
            'category': '心情',
            'definition': '',
            'chineseDefinition': '奋勇向前，不顾自身安危。',
            'text': '警察叔叔奋不顾身地跳入水中，把落水儿童救上岸。',
          },
          {
            'title': '勇敢',
            'word': '勇往直前',
            'category': '心情',
            'definition': '',
            'chineseDefinition': '勇敢地一直向前进。',
            'text': '不论遇到什么困难和挫折，他总是勇往直前，决不放弃。',
          },
          {
            'title': '勇敢',
            'word': '见义勇为',
            'category': '心情',
            'definition': '',
            'chineseDefinition': '看到正义的事，就勇敢地去做。',
            'text': '李伟见义勇为的精神受到了人们的称赞。',
          },
          {
            'title': '勇敢',
            'word': '挺身而出',
            'category': '心情',
            'definition': '',
            'chineseDefinition': '挺身：撑直身体。挺直身体站出来。 形容面对着艰难或危险的事情，勇敢地站出来。',
            'text': '遇到危急的事情时，哥哥总是毫不犹豫的挺身而出。',
          },
          {
            'title': '勇敢',
            'word': '穷追不舍',
            'category': '心情',
            'definition': '',
            'chineseDefinition': '勇敢地追赶不放松。',
            'text': '警察对小偷穷追不舍，最后终于在一个停车场把小偷捉获。',
          },
          {
            'title': '喜欢',
            'word': '情有独钟',
            'category': '心情',
            'definition': '',
            'chineseDefinition': '对某一事物特别喜欢。',
            'text': '哥哥对打篮球情有独钟，而姐姐却对画画很感兴趣。',
          },
          {
            'title': '着急',
            'word': '心急火燎',
            'category': '心情',
            'definition': '',
            'chineseDefinition': '心里急得像火烧一样。形容非常焦急。',
            'text': '小明急得如坐针毡，心急火燎的。',
          },
          {
            'title': '着急',
            'word': '心乱如麻',
            'category': '心情',
            'definition': '',
            'chineseDefinition': '心里乱得象一团乱麻。形容心里非常烦乱。',
            'text': '我心乱如麻，一时也不知道怎么办才好。',
          },
          {
            'title': '着急',
            'word': '抓耳挠腮',
            'category': '心情',
            'definition': '',
            'chineseDefinition': '抓抓耳朵，搔搔腮帮子。形容心里焦急而无法 可想的样子。',
            'text': '飞机马上就要起飞了，他还没有来，急得我们几个抓耳挠腮，如热锅上的蚂蚁。',
          },
          {
            'title': '着急',
            'word': '坐卧不安',
            'category': '心情',
            'definition': '',
            'chineseDefinition': '坐不稳，睡不安。多形容心事重重或很着急的样子。',
            'text': '今天妈妈生病住进医院了，我整天坐卧不安 。',
          },
          {
            'title': '担心',
            'word': '如坐针毡',
            'category': '心情',
            'definition': '',
            'chineseDefinition': '形容心神不定，不得安生。',
            'text': '因为不知道家里出了什么事，他接电话后如坐针毡。',
          },
          {
            'title': '欣赏',
            'word': '赞不绝口',
            'category': '心情',
            'definition': '',
            'chineseDefinition': '不住口地称赞。',
            'text': '他画的画让人看了赞不绝口。',
          },
          {
            'title': '老人',
            'word': '布满皱纹',
            'category': '人物',
            'definition': '',
            'chineseDefinition': '形容人的面部全是皱纹，多数是指老年人的脸。',
            'text': '奶奶脸上布满皱纹，还戴着一副老花镜，总是面带着微笑。',
          },
          {
            'title': '老人',
            'word': '慈眉善目',
            'category': '人物',
            'definition': '',
            'chineseDefinition': '意思是形容人的容貌一副善良的样子。',
            'text': '这位老人脸上总是挂着笑，看起来慈眉善目的。',
          },
          {
            'title': '老人',
            'word': '身体硬朗',
            'category': '人物',
            'definition': '',
            'chineseDefinition': '指身体健壮、强硬有力，也可以用来夸赞相貌 棱角分明的男人，或指老人家身体健康。',
            'text': '我的奶奶满脸皱纹，但身体却很硬朗。',
          },
          {
            'title': '老人',
            'word': '满头银发',
            'category': '人物',
            'definition': '',
            'chineseDefinition': '满头白发的美称。',
            'text': '李爷爷虽然满头银发，但走起路来健步如飞，一点也看不出已经是七十岁了。',
          },
          {
            'title': '老人',
            'word': '和蔼可亲',
            'category': '人物',
            'definition': '',
            'chineseDefinition': '态度温和，待人和气，使人愿意亲近。',
            'text': '张爷爷满头银发，圆圆的脸，眼睛一笑就眯成一条缝，是一位和蔼可亲的老人。',
          },
          {
            'title': '老人',
            'word': '老态龙钟',
            'category': '人物',
            'definition': '',
            'chineseDefinition': '形容体态衰老，动作不灵便的样子。',
            'text': '你别看她老态龙钟，她的歌声仍然那样优美动听。',
          },
          {
            'title': '同学关系',
            'word': '团结友爱',
            'category': '人物',
            'definition': '',
            'chineseDefinition': '和睦友好，相互爱护，形容人与人之间友好和睦的关系。',
            'text': '同学们要团结友爱，而且互相帮助。',
          },
          {
            'title': '同学关系',
            'word': '形影不离',
            'category': '人物',
            'definition': '',
            'chineseDefinition': '意思是像身体和它的影子那样分不开，形容彼 此关系亲密，经常在一起。',
            'text': '他们俩是一对形影不离的好朋友。',
          },
          {
            'title': '同学关系',
            'word': '情同手足',
            'category': '人物',
            'definition': '',
            'chineseDefinition': '意思是关系如手和脚，一日也离不开；比喻情 谊深厚，如同兄弟一样。',
            'text': '我和小平虽然来自不同的国家，但我们却情同手足。',
          },
          {
            'title': '同学关系',
            'word': '和睦相处',
            'category': '人物',
            'definition': '',
            'chineseDefinition': '彼此和好地相处。',
            'text': '同学之间也须和睦相处。',
          },
          {
            'title': '同学关系',
            'word': '亲密无间',
            'category': '人物',
            'definition': '',
            'chineseDefinition': '形容关系十分亲密。',
            'text': '小华和国强是一对亲密无间的小伙伴。',
          },
          {
            'title': '同学关系',
            'word': '朝夕相处',
            'category': '人物',
            'definition': '',
            'chineseDefinition': '意思是从早到晚都在一起，形容关系密切。',
            'text': '六年的朝夕相处使同学们早已建立起了深厚的友谊。',
          },
          {
            'title': '邻居关系',
            'word': '守望相助',
            'category': '人物',
            'definition': '',
            'chineseDefinition': '形容彼此之间互相帮助。',
            'text': '现在我终于明白“邻居之间要守望相助”这句话的含义了。',
          },
          {
            'title': '邻居关系',
            'word': '其乐融融',
            'category': '人物',
            'definition': '',
            'chineseDefinition': '形容快乐和谐的景象。',
            'text': '邻居们欢聚一堂，其乐融融。',
          },
          {
            'title': '邻居关系',
            'word': '斤斤计较',
            'category': '人物',
            'definition': '',
            'chineseDefinition': '指对无关紧要的事过分计较。',
            'text': '邻居张阿姨从来不为了小事而斤斤计较。',
          },
          {
            'title': '邻居关系',
            'word': '多年邻居变成亲',
            'category': '人物',
            'definition': '',
            'chineseDefinition': '多年邻居，互相照顾，成了亲人。',
            'text': '我们照顾邻居李爷爷几年了，多年邻居变成了亲。',
          },
          {
            'title': '邻居关系',
            'word': '不是亲人、胜似亲人',
            'category': '人物',
            'definition': '',
            'chineseDefinition': '形容彼此之间感情很深，虽然不是亲人，但是感情像亲人一样。',
            'text': '张阿姨和我们家的关系非常好，不是亲人胜似亲人。',
          },
          {
            'title': '坏人',
            'word': '面善心狠',
            'category': '人物',
            'definition': '',
            'chineseDefinition': '表面亲切，心里却非常狠毒。',
            'text': '我一直把他当成是好朋友，后来才知道他是个面善心狠的人。',
          },
          {
            'title': '坏人',
            'word': '笑里藏刀',
            'category': '人物',
            'definition': '',
            'chineseDefinition': '指脸上挂着笑容，心中藏着杀人的尖刀；比喻外表和善，内心却十分阴险毒辣。',
            'text': '我看他只是表面友善，得小心提防他笑里藏刀。',
          },
          {
            'title': '坏人',
            'word': '目露凶光',
            'category': '人物',
            'definition': '',
            'chineseDefinition': '凶恶的眼神，要做坏事的神情。',
            'text': '他目露凶光，说完就向前走去。',
          },
          {
            'title': '坏人',
            'word': '尖嘴猴腮',
            'category': '人物',
            'definition': '',
            'chineseDefinition': '尖嘴巴，瘦面颊。形容人脸庞瘦削，相貌丑陋。',
            'text': '他长得尖嘴猴腮，骨瘦如柴，整天游手好闲，不干正经事。',
          },
          {
            'title': '坏人',
            'word': '贼眉鼠眼',
            'category': '人物',
            'definition': '',
            'chineseDefinition': '形容鬼鬼祟祟或邪恶不正的样子。',
            'text': '他长得贼眉鼠眼的，一看就不像好人。',
          },
          {
            'title': '坏人',
            'word': '鬼鬼祟祟',
            'category': '人物',
            'definition': '',
            'chineseDefinition': '形容行动偷偷摸摸，不光明正大。',
            'text': '我看见一个人鬼鬼祟祟地走进院子，不知要做什么。',
          },
          {
            'title': '毅力',
            'word': '不屈不挠',
            'category': '人物',
            'definition': '',
            'chineseDefinition': '不向困难低头或屈服。',
            'text': '他那不屈不挠的学习精神，令我们佩服！',
          },
          {
            'title': '毅力',
            'word': '持之以恒',
            'category': '人物',
            'definition': '',
            'chineseDefinition': '长久地坚持。',
            'text': '学习上没有什么秘诀，只有不懈努力、持之以恒才能取得好成绩。',
          },
          {
            'title': '毅力',
            'word': '坚定不移',
            'category': '人物',
            'definition': '',
            'chineseDefinition': '坚强，不动摇。',
            'text': '凭着坚定不移的精神，我们的登山队终于安全到达顶峰。',
          },
          {
            'title': '毅力',
            'word': '坚韧不拔',
            'category': '人物',
            'definition': '',
            'chineseDefinition': '坚定不移、不放弃。',
            'text': '不管在生活中遇到什么困难,我们都要有坚韧不拔的精神。',
          },
          {
            'title': '毅力',
            'word': '再接再厉',
            'category': '人物',
            'definition': '',
            'chineseDefinition': '比喻一次又一次不断努力。',
            'text': '老师鼓励我再接再厉，取得更好的成绩。',
          },
          {
            'title': '毅力',
            'word': '坚持不懈',
            'category': '人物',
            'definition': '',
            'chineseDefinition': '坚持到底，毫不松懈。',
            'text': '只要坚持不懈，一定会成功。',
          },
          {
            'title': '不想做',
            'word': '装聋作哑',
            'category': '行为',
            'definition': '',
            'chineseDefinition': '假装耳聋口哑，什么也没听见，什么也不说。形容故意不理睬，或故意置身事外。',
            'text': '你别装聋作哑了，我们早就知道这件事了。',
          },
          {
            'title': '不想做',
            'word': '袖手旁观',
            'category': '行为',
            'definition': '',
            'chineseDefinition': '把手揣在袖子里在一旁观看。比喻置身事外，不过问或不参与。',
            'text': '面对欺负低年级同学的行为，我们绝对不能袖手旁观。',
          },
          {
            'title': '不想做',
            'word': '不闻不问',
            'category': '行为',
            'definition': '',
            'chineseDefinition': '意思是指人家说的不听，也不主动去问；形容对事情不关心。',
            'text': '小华只顾埋头学习，班级的事情不闻不问。',
          },
          {
            'title': '不想做',
            'word': '幸灾乐祸',
            'category': '行为',
            'definition': '',
            'chineseDefinition': '指看到别人遭到灾祸反而很高兴。',
            'text': '他趁小丽不注意，偷偷把她的凳子抽了出来，然后幸灾乐祸地等着瞧热闹。',
          },
          {
            'title': '不想做',
            'word': '视而不见',
            'category': '行为',
            'definition': '',
            'chineseDefinition': '虽然睁着眼睛看了，却什么也没见到。形容对看到的事情漫不经心，不重视。',
            'text': '面对满地的垃圾，他竟然视而不见。',
          },
          {
            'title': '不想做',
            'word': '不理不睬',
            'category': '行为',
            'definition': '',
            'chineseDefinition': '对人或事物不闻不问，漠不关心。',
            'text': '我们很热情地和他打招呼，他却对我们不理不睬。',
          },

          {
            'title': '大自然',
            'word': '百花齐放',
            'category': '场景',
            'definition': '',
            'chineseDefinition': '各种各样的花同时开放。',
            'text': '植物园里百花齐放，散发出阵阵香味。',
          },
          {
            'title': '大自然',
            'word': '高大茂盛',
            'category': '场景',
            'definition': '',
            'chineseDefinition': '树木长得高大，而且枝叶密而多。',
            'text': '清晨，我来到景色优美的植物园，一棵棵高大茂盛的树木挺立着。',
          },
          {
            'title': '大自然',
            'word': '鸟语花香',
            'category': '场景',
            'definition': '',
            'chineseDefinition': '鸟儿鸣叫，花儿飘香。',
            'text': '公园里到处鸟语花香，空气清新。',
          },
          {
            'title': '大自然',
            'word': '绿草如茵',
            'category': '场景',
            'definition': '',
            'chineseDefinition': '绿油油的草地 。常指可供临时休息的草地。',
            'text': '我们来到了绿草如茵的小河边，欣赏着五颜六色的花朵。',
          },
          {
            'title': '大自然',
            'word': '碧空如洗',
            'category': '场景',
            'definition': '',
            'chineseDefinition': '指蓝色的天空像洗过一样的明净，形容 天气晴朗。',
            'text': '海边的天空一望无际，碧空如洗。',
          },
          {
            'title': '大自然',
            'word': '万紫千红',
            'category': '场景',
            'definition': '',
            'chineseDefinition': '意思是形容百花齐放，色彩艳丽。',
            'text': '花园中鲜花盛开，万紫千红，蝴蝶在翩翩起舞。',
          },
          {
            'title': '人群',
            'word': '人山人海',
            'category': '场景',
            'definition': '',
            'chineseDefinition': '比喻人非常多。',
            'text': '这次的书展吸引了很多人，展场里人山人海。',
          },
          {
            'title': '人群',
            'word': '车水马龙',
            'category': '场景',
            'definition': '',
            'chineseDefinition': '车如流水，马如游龙。形容交通繁忙， 繁华热闹。',
            'text': '大街上车水马龙的样子，甚是热闹。',
          },
          {
            'title': '人群',
            'word': '人来人往',
            'category': '场景',
            'definition': '',
            'chineseDefinition': '人来来往往连续不断。',
            'text': '昨天下午，姐姐在人来人往的地铁站里不小心丢失了钱包。',
          },
          {
            'title': '人群',
            'word': '座无虚席',
            'category': '场景',
            'definition': '',
            'chineseDefinition': '没有空着的座位。形容出席的人很多。',
            'text': '音乐会那天，音乐厅里座无虚席。',
          },
          {
            'title': '人群',
            'word': '络绎不绝',
            'category': '场景',
            'definition': '',
            'chineseDefinition': '形容行人、车马、船只等来往频繁；连续不断。',
            'text': '大街上往来的行人和车辆络绎不绝。',
          },
          {
            'title': '人群',
            'word': '热闹非凡',
            'category': '场景',
            'definition': '',
            'chineseDefinition': '意思是形容热闹的场面或景象。',
            'text': '新年时候的牛车水，热闹非凡。',
          },
          {
            'title': '混乱',
            'word': '散落一地',
            'category': '场景',
            'definition': '',
            'chineseDefinition': '物品分散下落得到处都是。',
            'text': '哥哥把散落一地的文具捡了起来。',
          },
          {
            'title': '混乱',
            'word': '四脚朝天',
            'category': '场景',
            'definition': '',
            'chineseDefinition': '形容仰面跌倒。',
            'text': '弟弟比赛得了第一名，乐得一蹦三尺高，差点就摔个四脚朝天。',
          },
          {
            'title': '混乱',
            'word': '纷纷逃走',
            'category': '场景',
            'definition': '',
            'chineseDefinition': '一个一个地都逃跑了。',
            'text': '最后有几个人觉得非常丢脸，纷纷逃走了。',
          },
          {
            'title': '混乱',
            'word': '追逐嬉闹',
            'category': '场景',
            'definition': '',
            'chineseDefinition': '互相追着打闹、欢快地玩耍。',
            'text': '几个顽皮的孩子在组屋楼下追逐嬉闹。',
          },
          {
            'title': '混乱',
            'word': '失去平衡',
            'category': '场景',
            'definition': '',
            'chineseDefinition': '不能保持均衡，不稳定。',
            'text': '当我溜滑板玩得正高兴时，不料身体失去平衡， 重重地摔了一跤，痛得我好半天才爬起来。',
          },
          {
            'title': '混乱',
            'word': '奋力而追',
            'category': '场景',
            'definition': '',
            'chineseDefinition': '用全部的力量去追赶。',
            'text': '小偷突然撒腿逃跑,李叔叔奋力而追，很快就把小偷抓住了。',
          },
          {
            'title': '行走',
            'word': '一瘸一拐',
            'category': '动作',
            'definition': '',
            'chineseDefinition': '意思是腿脚有毛病，行步时身体不平衡。',
            'text': '他是弯着腰,一瘸一拐的走回来。',
          },
          {
            'title': '行走',
            'word': '寸步难行',
            'category': '动作',
            'definition': '',
            'chineseDefinition': '连一步都难以进行。',
            'text': '那条街被车堵得寸步难行。',
          },
          {
            'title': '行走',
            'word': '行走如风',
            'category': '动作',
            'definition': '',
            'chineseDefinition': '形容走路的步子轻快得像风一样。',
            'text': '张华行走如风，一瞬间已追在前头。',
          },
          {
            'title': '行走',
            'word': '大步流星',
            'category': '动作',
            'definition': '',
            'chineseDefinition': '形容步子跨得大，走得快。',
            'text': '哥哥朝着我大步流星地走过来。',
          },
          {
            'title': '行走',
            'word': '头重脚轻',
            'category': '动作',
            'definition': '',
            'chineseDefinition': '形容头脑发胀；脚下无力。',
            'text': '感冒发烧两天之后，总觉得头重脚轻站立不稳。',
          },
          {
            'title': '行走',
            'word': '轻手轻脚',
            'category': '动作',
            'definition': '',
            'chineseDefinition': '形容手脚的动作比较轻。',
            'text': '他等父母熟睡后，轻手轻脚地溜了出来。',
          },
          {
            'title': '其他',
            'word': '滔滔不绝',
            'category': '动作',
            'definition': '',
            'chineseDefinition': '意思指像流水一般不间断，形容话很多，说起来没个完。',
            'text': '他只要一谈起电影，就滔滔不绝。',
          },
        ],
      },
      options: {
        openings: [
          {
            label: '全部',
            value: 'all',
            default: true,
          },
          {
            label: '回忆',
            value: '回忆',
          },
          {
            label: '人物',
            value: '人物',
          },
          {
            label: '天气',
            value: '天气',
          },
          {
            label: '其他',
            value: 'others',
          },
        ],
        proverbs: [
          {
            label: '全部',
            value: 'all',
          },
          {
            label: '开头结尾',
            value: '开头结尾',
          },
          {
            label: '描写',
            value: '描写',
          },
        ],
        metaphors: [
          {
            label: '全部',
            value: 'all',
          },
          {
            label: '拟人句',
            value: '拟人句',
          },
          {
            label: '心情',
            value: '心情',
          },
          {
            label: '人物',
            value: '人物',
          },
          {
            label: '场景',
            value: '场景',
          },
          {
            label: '行为',
            value: '行为',
          },
          {
            label: '动作',
            value: '动作',
          },
        ],
        idioms: [
          {
            label: '全部',
            value: 'all',
          },
          {
            label: '心情',
            value: '心情',
          },
          {
            label: '人物',
            value: '人物',
          },
          {
            label: '场景',
            value: '场景',
          },
          {
            label: '行为',
            value: '行为',
          },
          {
            label: '动作',
            value: '动作',
          },
        ],
      },
    };
  },
  computed: {
    filteredResults() {
      let toReturn;
      const entries = this.results[this.selectedCategory] || [];

      // Get all subcategories except 'all' and 'others'
      let subcategories;
      if (this.selectedCategory in this.options) {
        subcategories = this.options[this.selectedCategory]
            .filter((option) => option.value !== 'all' && option.value !== 'others')
            .map((option) => option.value);
      } else {
        subcategories = [];
      }

      // apply subcategory
      if (this.selectedSubcategory === 'all') {
        toReturn = entries;
      } else if (this.selectedSubcategory === 'others') {
        toReturn = entries.filter((entry) => {
          return !subcategories.includes(entry.category);
        });
      } else {
        toReturn = entries.filter((entry) => {
          return entry.category === this.selectedSubcategory;
        });
      }
      return toReturn;
    },
  },
  mounted() {
  },
  methods: {
    goProfileMain() {
      this.$router.push({name: 'profile-main'});
    },
    getCategoryIndex(category) {
      if (!this.options[this.selectedCategory]) return -1;
      // the first value is 'all' so we need to subtract 1
      const index = this.options[this.selectedCategory].findIndex((option) => option.value === category);
      return index > 0 ? index - 1 : -1;
    },
    selectCategory(val) {
      this.selectedSubcategory = 'all';
      this.selectedCategory = val;
      console.log('selectCategory', val);
      console.log('selectedSubcategory', this.selectedSubcategory);
    },
    selectSubcategory(val) {
      console.log(123, val);
      this.selectedSubcategory = val;
    },
    goCompoIndex() {
      this.$router.push({name: 'new-composition-overview'});
    },
  },
};
</script>


<style lang="scss" scoped>
  .resources__component {
    display: flex;
    flex-direction: row-reverse;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background: #fff;
    z-index: 2;
    background: #e9e9e9;
    &--nav {
      &--header {
        color: #fff;
        font-weight: 600;
        font-size: 1.2rem;
        i {
          margin-right: .25rem;
        }
      }
     &-container {
      height: 100%;
      &--mobile {
          // FOR RESPONSIVENESS SLIDE IN NAV
          background: #000000db;
          width: 100vw;
          position: absolute;
          height: 100%;
          display: none;
        }
      }
      padding: 2rem 2rem 2rem 1rem;
      background: #3c79d3;
      position: relative;
      height: 100%;
      width: 300px;
      &--profile {
        display: flex;
        justify-content: center;
        align-items: center;
        .user-avatar {
          img {
            background: #fff;
            border-radius: 50%;
            box-shadow: 0px 5px #7a7a7aa8;
            width: 75px;
            margin-right: .5rem;
          }
        }
        .user-name {
          font-size: 1.4rem;
          font-weight: 600;
          color: #fff;
          line-height: 1.2rem;
          // text-shadow: 0px 3px #0000007a;
          text-transform: capitalize;
        }
        .user-level {
          text-transform: capitalize;
          color: #fff;
          font-style: italic;
        }
      }
      &--routes {
        position: absolute;
        right: 0;
        margin-top: 3.5rem;
        li {
          i {
            margin-right: .5rem;
          }
          cursor: pointer;
          padding: 1rem 2rem;
          font-weight: 600;
          min-width: 250px;
          font-size: .9rem;
          color: #fff;
        }
        .active {
          border-top-left-radius: .5rem;
          border-bottom-left-radius: .5rem;
          background: #e9e9e9;
          color: #3c79d3;
          position: relative;
          &::before {
            content: "";
            position: absolute;
            background-color: rgba(0, 0, 0, 0);
            top: -50px;
            height: 50px;
            width: 25px;
            border-bottom-right-radius: .5rem;
            right: 0;
            box-shadow: 0 25px 0 0 #e9e9e9;
          }
          &::after {
            content: "";
            position: absolute;
            background-color: rgba(0, 0, 0, 0);
            bottom: -50px;
            height: 50px;
            width: 25px;
            border-top-right-radius: .5rem;
            right: 0;
            box-shadow: 0 -25px 0 0 #e9e9e9;
          }
       }
      }
      &--btn-home {
        position: absolute;
        bottom: 0;
        width: 100%;
        background: #3366b4;
        left: 0;
        padding: 1rem;
        padding-left: 2rem;
        font-weight: 600;
        color: #fff;
        font-size: .9rem;
        cursor: pointer;
        i {
          margin-right: .25rem;
        }
      }
      &--btn-close {
        position: absolute;
        bottom: 0;
        width: 100%;
        background: #3366b4;
        left: 0;
        padding: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1rem;
        font-weight: 600;
        color: #ffffff8a;
        font-size: 1.5rem;
        cursor: pointer;
        &:hover {
          color: #fff;
        }
        i {
          margin-right: .25rem;
        }
      }
    }
    &--content {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      flex: 1;
      flex-direction: column;
      overflow-x: hidden;
      overflow-y: scroll;
      padding: 1rem 5rem;
      &--item {
        width: 100%;
        margin: 1rem 0 0 0;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
      }
      .mobile-nav-icons {
        justify-content: center;
        align-items: center;
        flex-direction: row;
        font-size: 1.35rem;
        display: none;
        width: 100%;
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
        padding: 1rem;
        background: #e9e9e9;
        .fa-xmark {
          font-size: 1.8rem;
          margin-left: auto;
        }
        span {
          margin-left: 1rem;
          font-weight: 800;
        }
      }
    }
  }

  @media only screen and (max-width: 1250px) {
    .resources__component--nav-container {
      display: none;
    }
    .resources__component--nav-container--mobile {
      display: block;
      .resources__component--nav {
        padding: 2rem 1rem;
        width: 310px;
      }
      .resources__component--nav--routes {
        right: auto;
        margin-top: 2.5rem;
        .active {
          border-radius: .5rem;
          &::before {
            content: none;
          }
          &::after {
            content: none;
          }
        }
      }
    }
    .resources__component--content {
      height: 100%;
      justify-content: flex-start;
      padding: 1rem;
      &>div {
        justify-content: flex-start;
      }
      .mobile-nav-icons {
        display: flex;
        height: auto;
          i {
          cursor: pointer;
        }
      }
    }
  }
</style>
