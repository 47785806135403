<template>
  <block-container
    title="Video Lessons"
    fa-icon="book-open"
    >
    <div class="learn-index__container">
      <router-link v-for="lesson in lessons"
        :key="lesson.weekToDisplay"
        :class="{ disabled: lesson.weekToDisplay > currentWeek }"
        class="learn-index__container--item"
        :to="{name: 'video-lesson-main-latest'}">
          <div class="learn-index__container--text">
          <i v-if="lesson.weekToDisplay <= currentWeek" class="fa-solid fa-video"></i>
          <i v-else class="fa-solid fa-lock"></i>
          <span class="divider">|</span>
          <span>{{ lesson.chapter }}</span>
          <span class="divider">|</span>
          <span>Term {{ lesson.currentTerm }}</span>
          <span class="divider">|</span>
          <span>Week {{ lesson.weekToDisplay}}</span>
          <span class="divider">|</span>
          <span v-if="lesson.weekToDisplay == currentWeek">NEW</span>
        </div>
      </router-link>
    </div>
  </block-container>
</template>

<script>
// import {mapState} from 'vuex';
// import {mapState, mapGetters, mapActions} from 'vuex';

import BlockContainerComponent
  from '../common/BlockContainerComponent.vue';

export default {
  name: 'NewVideoLessonsOverview',
  components: {
    BlockContainer: BlockContainerComponent,
  },
  data() {
    return {
      currentWeek: 3,
      // mockdata
      lessons: [
        {
          chapter: '我的狗',
          level: 'primary_one',
          currentTerm: 1,
          weekToDisplay: 1,
        },
        {
          chapter: '我的猫',
          level: 'primary_one',
          currentTerm: 1,
          weekToDisplay: 2,
        },
        {
          chapter: '我的书',
          level: 'primary_one',
          currentTerm: 1,
          weekToDisplay: 3,
        },
        {
          chapter: '我的门',
          level: 'primary_one',
          currentTerm: 1,
          weekToDisplay: 4,
        },
        {
          chapter: '我的狗',
          level: 'primary_one',
          currentTerm: 1,
          weekToDisplay: 5,
        },
        {
          chapter: '我的猫',
          level: 'primary_one',
          currentTerm: 1,
          weekToDisplay: 6,
        },
        {
          chapter: '我的书',
          level: 'primary_one',
          currentTerm: 1,
          weekToDisplay: 7,
        },
        {
          chapter: '我的门',
          level: 'primary_one',
          currentTerm: 1,
          weekToDisplay: 8,
        },
        {
          chapter: '我的狗',
          level: 'primary_one',
          currentTerm: 1,
          weekToDisplay: 9,
        },
        {
          chapter: '我的猫',
          level: 'primary_one',
          currentTerm: 1,
          weekToDisplay: 10,
        },
        {
          chapter: '我的书',
          level: 'primary_one',
          currentTerm: 1,
          weekToDisplay: 11,
        },
        {
          chapter: '我的门',
          level: 'primary_one',
          currentTerm: 1,
          weekToDisplay: 12,
        },
        {
          chapter: '我的狗',
          level: 'primary_one',
          currentTerm: 1,
          weekToDisplay: 13,
        },
        {
          chapter: '我的猫',
          level: 'primary_one',
          currentTerm: 1,
          weekToDisplay: 14,
        },
        {
          chapter: '我的书',
          level: 'primary_one',
          currentTerm: 1,
          weekToDisplay: 15,
        },
        {
          chapter: '我的门',
          level: 'primary_one',
          currentTerm: 1,
          weekToDisplay: 16,
        },
        {
          chapter: '我的狗',
          level: 'primary_one',
          currentTerm: 1,
          weekToDisplay: 17,
        },
        {
          chapter: '我的猫',
          level: 'primary_one',
          currentTerm: 1,
          weekToDisplay: 18,
        },
        {
          chapter: '我的书',
          level: 'primary_one',
          currentTerm: 1,
          weekToDisplay: 19,
        },
        {
          chapter: '我的门',
          level: 'primary_one',
          currentTerm: 1,
          weekToDisplay: 20,
        },
      ],
    };
  },
  computed: {
  },
  mounted() {
  },
  methods: {
  },
};
</script>


<style lang="scss" scoped>
  .learn-index {
    &__container {
      height: 100%;
      background: linear-gradient(to bottom right, #1d244dc9, #1518293c);
      padding: 1rem 2rem;
      width: 100%;
      overflow-y: auto;
      overflow-x: hidden;
      position: absolute;
      text-align: center;
      gap: .75rem;
      display: flex;
      flex-direction: column;
      width: 100%;
      .disabled {
        pointer-events: none;
        opacity: .5;
        .learn-index__container--text {}
      }
      &--item {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        background: #fff;
        border-radius: 7.5px;
        text-decoration: none;
        border: 6px solid transparent;
        transition: all 0.2s;
        position: relative;
        padding: .25rem .5rem;
        &:hover {
          background: #fff;
          .learn-index__container--item-icon {
            background: #fff;
            border: 6px solid #2b92f8;
            i {
              color: #2b92f8;
            }
          }
        }
        .user-settings-content {
          height: 100%;
          width: 100%;
        }
        a {
          text-decoration: none;
        }
        &-icon {
          height: 75%;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 6px solid #e9e9e9;
          margin: .5rem .5rem;
          transition: all 0.2s;
          padding: 1rem;
          border-radius: 0.5rem;
          background: #fff;
          i {
            transition: all 0.2s;
            color: #b9b6c1;
            font-size: 3.5rem;
            margin-bottom: 5px;
          }
        }
      }
      &--text {
        font-weight: 600;
        color: #4e4e54;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .divider {
          margin: 0 .5rem;
        }
      }
    }
  }

  @media only screen and (max-width: 1560px) {
  }
  @media only screen and (max-width: 1366px),
              screen and (max-height: 870px) {
  }

  @media only screen and (max-width: 1150px),
  screen and (max-height: 690px) {
  }
  @media only screen and (max-width: 960px),
  screen and (max-height: 620px) {
  }
  @media only screen and (max-width: 690px) {
  }
  @media only screen and (max-width: 580px) {
  }
  @media only screen and (max-width: 480px) {
  }

</style>
