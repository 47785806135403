<template>
  <div class="battle-arena__component animated fadeIn faster">
    <div class="battle-arena__UI-top--container">
      <div class="battle-arena__UI-top--player-stats">
        <div class="battle-arena__UI-top--player-portrait">
          <frame-component
            :frame-color="'yellow'">
            <!-- swap in pet image for this  -->
            <img
              v-if="gameValues.pet_family_name == 'Fire Panda'"
              src="../../../assets/raid/panda-fire--portrait.png">
            <img
            v-if="gameValues.pet_family_name == 'Water Panda'"
              src="../../../assets/raid/panda-water--portrait.png">
          </frame-component>
        </div>
        <div class="battle-arena__UI-top--player-stats--content">
          <div class="battle-arena__UI-top--player-healthbar__container">
            <p> {{ gameValues.student_pet_health_percentage }}%</p>
              <div
                class="battle-arena__UI-top--player-healthbar"
                :style="{width:
                  (gameValues.student_pet_health_percentage) + '%'}"
              ></div>
          </div>
          <p>{{ gameValues.pet_family_name }}</p>
        </div>
      </div>
      <div class="battle-arena__UI-top--text">
        <img
          src="../../../assets/raid/ui-text-vs.png">
        <!-- TODO: @click to turn on or off bg music -->
        <!-- v-bind:class - play = 'fa-volume-up', mute = 'fa-volume-mute' -->
        <i @click="toggleSound"
          :class="{ 'fa-volume-up': playSound, 'fa-volume-mute': !playSound }"
          class="fas"></i>
        <!-- <i class="fas fa-volume-mute"></i> -->
      </div>
      <div class="battle-arena__UI-top--enemy-stats">
        <div class="battle-arena__UI-top--enemy-stats--content">
          <div class="battle-arena__UI-top--enemy-healthbar__container">
            <p> {{ gameValues.enemy_health_percentage }}%</p>
            <div
              class="battle-arena__UI-top--enemy-healthbar"
              :style="{width:
                (gameValues.enemy_health_percentage) + '%'}"
            ></div>
          </div>
          <p>{{ gameValues.raid_boss }}</p>
        </div>
        <div class="battle-arena__UI-top--enemy-portrait">
          <frame-component
            :frame-color="'yellow'">
            <img
              src="../../../assets/raid/raid-boss--portrait.png">
          </frame-component>
        </div>
      </div>
    </div>
    <div class="battle-arena__battlefield--UI">
      <div class="battle-arena__battlefield--player">
        <transition
          name="custom-classes-transition"
          enter-active-class="animated fadeInUp faster"
          leave-active-class="animated fadeOutUp faster">
          <battle-damage-text
            v-if="damageTextValues.student.show"
            :is-student="true"></battle-damage-text>
        </transition>
        <div class="battle-arena__battlefield--player-pet--container animated"
          :class="{ 'flash': animationValues.playStudentDamaged,
            'heartBeat': animationValues.playStudentAtk }">
          <div class="pet-elemental--icon">
            <i v-if="gameValues.pet_family_name == 'Fire Panda'"
               class="fas fa-fire"></i>
            <i v-if="gameValues.pet_family_name == 'Water Panda'"
              class="fas fa-tint"></i>
          </div>
          <pet-image
            class="idle-anim"
            :pet-key="gameValues.pet_key">
          </pet-image>
        </div>
      </div>
      <div class="battle-arena__battlefield--enemy">
        <transition
          name="custom-classes-transition"
          enter-active-class="animated fadeInUp faster"
          leave-active-class="animated fadeOutUp faster">
          <battle-damage-text
            v-if="damageTextValues.enemy.show"
            :is-student="false"></battle-damage-text>
        </transition>
        <div class="animated raid-boss--container"
          :class="{'flash': animationValues.playEnemyDamaged,
            'heartBeat': animationValues.playEnemyAtk }">
          <img src="../../../assets/raid/raid-boss.png">
        <!--   <img class="idle-anim delay-3"
            src="../../../assets/raid/raid-boss.png"> -->
          <div class="enemy-elemental--icon delay-3">
            <i class="fas fa-fire"></i>
          </div>
        </div>
      </div>
    </div>
    <transition
      leave-active-class="animated slideOutDown">
      <div v-if="animationValues.showSpellSelection"
        class="battle-arena__UI-bottom--container animated slideInUp">
        <div class="battle-arena__UI-bottom--btns
          animated slideInUp delay-2">
          <button
            v-for="spell in gameValues.pet_spells"
            :key="spell.pk"
            :disabled="fetching"
            @click="selectSpell(spell.id)">{{ spell.name }}</button>
          <button @click="runAway">Run Away</button>
        </div>
      </div>
    </transition>
      <!-- debugger -->
<!--     <div class="debugger">
      <p>Hover for Debugger</p>
 --><!--       <textarea
        v-model="combatMessages"
        rows=10
        :readonly="true">
      </textarea> -->
    <!-- </div> -->
    <!-- v-if Play Animation for battle -->
    <!-- If Raid boss attacks, anim is inversed scalex = -1 -->
    <battle-anim-overlay
    ></battle-anim-overlay>
    <div class="landscape-warning">
      <p>Please switch to Landscape Mode</p>
    </div>
  </div>
</template>

<script>

import {mapState, mapMutations} from 'vuex';
import {Howl} from 'howler';

// import {soundMixin} from '../../../mixins/sound';

import FrameComponent
  from '../utility/FrameComponent.vue';
import PetImageComponent
  from '../../pet/PetImageComponent.vue';
import BattleAnimOverlayComponent
  from './BattleAnimOverlayComponent.vue';
import BattleDamageTextComponent
  from './BattleDamageTextComponent.vue';

export default {
  name: 'BattleArena',
  components: {
    FrameComponent: FrameComponent,
    PetImage: PetImageComponent,
    BattleAnimOverlay: BattleAnimOverlayComponent,
    BattleDamageText: BattleDamageTextComponent,
  },
  // mixins: [soundMixin],
  props: {
  },
  data() {
    return {
      backgroundSound: null,
      playSound: true,
    };
  },
  computed: {
    ...mapState('raid', ['gameValues', 'animationValues',
      'damageTextValues', 'fetching']),
  },
  mounted: function() {
    this.backgroundSound = new Howl({
      src: ['https://smartschool-static.s3.ap-southeast-1.amazonaws.com/media/raid/music_epicfight.mp3'],
      autoplay: true,
      loop: true,
    });

    this.backgroundSound.play();
  },
  beforeDestroy() {
    this.backgroundSound.stop();
  },
  methods: {
    ...mapMutations('raid', ['goToGameState']),
    runAway() {
      this.goToGameState('index');
    },
    selectSpell(spellId) {
      this.$emit('selectSpell', spellId);
    },
    toggleSound() {
      console.log('click');
      if (this.playSound) {
        this.backgroundSound.stop();
        this.playSound = false;
      } else {
        this.backgroundSound.play();
        this.playSound = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .debugger {
    position: absolute;
    width: 400px;
    top: 25%;
    left: 50%;
    transform: translate(-50%, -25%);
    opacity: 0.2;
    text-align: center;
    cursor: pointer;
    transition: all 0.4s;
    font-weight: 900;
    textarea {
      opacity: 0;
      width: 400px;
      cursor: pointer;
      transition: all 0.4s;
      margin-top: 0.5rem;
    }
    &:hover {
      opacity: 1;
      textarea {
        opacity: 1;
      }
    }
  }

  .battle-arena {
    &__component {
      background: url(../../../../src/assets/raid/ui-arena-bg.jpg);
      background-size: cover;
      background-position: bottom;
      width: 100%;
      height: 100%;
      position: relative;
    }
    &__UI-top {
      &--text {
        display: flex;
        flex-direction: column;
        align-items: center;
        i {
          margin-top: 1rem;
          font-size: 2rem;
          z-index: 120;
          cursor: pointer;
        }
        .fa-volume-up {
          color: #33fff6;
        }
        .fa-volume-mute {
          color: #ff2e5a;
        }
      }
      &--container {
        position: absolute;
        top: 1.5rem;
        display: flex;
        width: 100%;
        justify-content: center;
      }
      &--player {
        &-stats{
          display: flex;
          flex: 1;
          margin: 0 1rem;
          &--content {
            flex: 1;
            display: flex;
            flex-direction: column;
            transform: translateY(1rem);
            p {
              text-align: left;
              font-weight: 900;
              font-family: 'baloo da 2';
              margin-left: 0.5rem;
              font-size: 1.2rem;
            }
          }
        }
        &-portrait{
          img {
            display: inline-flex;
            background: #5d95de;
          }
        }
        &-healthbar {
          height: 100%;
          width: 100%;
          background: #338cff;
          transition: all 1.25s;
          border: 4px solid #1068da;
          border-top-color: #7ab4ff;
          border-bottom-color: #216cce;
          border-left-width: 3px;
          &__container {
            position: relative;
            background: #e2defd8a;
            height: 50px;
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
            overflow: hidden;
            border: 5px solid #000;
            border-left: none;
            p {
              color: #fff;
              position: absolute;
              text-align: center;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              font-weight: 500;
            }
          }
        }
      }
      &--enemy {
        &-stats{
          display: flex;
          flex: 1;
          margin: 0 1rem;
          &--content {
            flex: 1;
            display: flex;
            flex-direction: column;
            transform: translateY(1rem);
            p {
              text-align: right;
              font-weight: 900;
              font-family: 'baloo da 2';
              margin-right: 0.5rem;
              font-size: 1.2rem;
            }
          }
        }
        &-portrait{
          img {
            display: inline-flex;
            background: #616161;
            transform: scaleX(-1);
          }
        }
        &-healthbar {
          position: absolute;
          right: 0;
          height: 100%;
          width: 100%;
          background: #ff2e5a;
          transition: all 1.25s;
          border: 4px solid #dc1f47;
          border-top-color: #ff7793;
          border-bottom-color: #8f1a32;
          border-right-width: 3px;
          &__container {
            position: relative;
            background: #e2defd8a;
            height: 50px;
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
            overflow: hidden;
            border: 5px solid #000;
            border-right: none;
            p {
              z-index: 20;
              color: #fff;
              position: absolute;
              text-align: center;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              font-weight: 500;
            }
          }
        }
      }
    }
    &__battlefield {
      &--UI {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        height: 80%;
      }
      &--player,
      &--enemy {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 50%;
        flex-direction: column;
      }
      &--player-pet--container {
        width: 300px;
        margin-bottom: -50px;
        p {
          text-align: center;
        }
      }
    }
    &__UI-bottom {
      &--container {
        background: url(../../../assets/raid/ui-bg-frame.png);
        height: 5.75rem;
        background-repeat: no-repeat;
        background-position: center;
        position: absolute;
        bottom: 0;
        width: 100%;
        z-index: 50;
      }
      &--btns {
        position: absolute;
        bottom: .5rem;
        width: 100%;
        text-align: center;
        button {
          background: #865bab;
          color: #fff;
          font-size: 1rem;
          font-weight: 900;
          font-family: 'baloo da 2';
          width: 160px;
          margin: 0.25rem;
          cursor: pointer;
          border-radius: 5px;
          border: 5px solid #000;
          padding: 0.25rem 0;
          transition: all 0.2s;
          &:focus {
            outline: none;
          }
          &:hover {
            transform: scale(1.05);
            background: #fbaa0f;
          }
        }
      }
    }
  }

  .pet-elemental--icon {
    font-size: 3rem;
    -webkit-text-stroke: 4px black;
    margin-bottom: -6rem;
    // animation: icon-anim 2.5s ease infinite;
    .fa-fire {
      color: #ffa32e;
    }
    .fa-tint {
      color: #33c5ff;
    }
  }
  .raid-boss--container {
    position: relative;
    img {
      height: 100%;
    }
  }
  .enemy-elemental--icon {
    display: inline-block;
    position: absolute;
    right: 0.1rem;
    font-size: 3rem;
    -webkit-text-stroke: 4px black;
    // animation: icon-anim 2.5s ease infinite;
    .fa-fire {
      color: #ffa32e;
    }
    .fa-tint {
      color: #33c5ff;
    }
  }

  .img-flip {
      transform: scaleX(-1);
  }
  .idle-anim {
    transform-origin: bottom;
    // animation: idle-anim 1.5s ease infinite;
  }
  .delay-2 {
    animation-delay: 0.2s;
  }
  .delay-3 {
    animation-delay: -0.5s;
  }

  .delay-1 {
    animation-delay: 0.1s;
  }

  @keyframes idle-anim {
    0% {
      transform: scaleY(1.025);
    }
    50% {
      transform: scaleY(0.975);
    }
    100% {
      transform: scaleY(1.025);
    }
  }
  @keyframes icon-anim {
    0% {
      transform: translateY(15%);
    }
    50% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(15%);
    }
  }
  .landscape-warning {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background: #000;
    z-index: 50;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-weight: 600;
    font-family: 'baloo da 2';
    display: none;
  }
  @media screen and (orientation:portrait) {
    .landscape-warning {
      display: flex;
    }
  }
  @media only screen and (max-height: 768px) {
    .raid-boss--container {
      height: 350px;
    }
    .battle-arena__battlefield--player-pet--container {
      width: 275px;
      margin-bottom: -45px;
    }
  }
  @media only screen and (max-height: 675px) {
    .raid-boss--container {
      height: 325px;
    }
    .battle-arena__battlefield--player-pet--container {
      width: 265px;
    }
  }
  @media only screen and (max-height: 635px) {
    .battle-arena__UI-top {
      &--text {
        width: 90px;
        margin-top: 0.5rem;
        img {
          width: 100%;
        }
        i {
          font-size: 1.75rem;
        }
      }
      &--container {
      top: 0.5rem;
      }
      &--player-stats,
      &--enemy-stats {
        margin: 0 0.5rem;
        &--content {
          transform: translateY(0.75rem);
        }
      }
      &--player-healthbar__container,
      &--enemy-healthbar__container {
        height: 40px;
        p {
          font-size: 0.9rem;
        }
      }
      &--player-portrait,
      &--enemy-portrait {
        img {
          width: 60px;
        }
      }
    }

    .battle-arena__battlefield--UI {
      height: 82.5%;
    }
    .battle-arena__UI-bottom--container {
      height: 4.9rem;
      background-size: 650px;
    }
    .battle-arena__UI-bottom--btns {
      bottom: .3rem;
      button{
        font-size: 0.85rem;
        width: 135px;
        padding: 0.1rem 0;
        border: 4px solid #000;
      }
    }
  }
  @media only screen and (max-height: 610px) {
    .pet-elemental--icon,
    .enemy-elemental--icon {
      font-size: 2.5rem;
      -webkit-text-stroke: 4px black;
    }

    .battle-arena__battlefield--UI {
      height: 85%;
    }
  }
  @media only screen and (max-height: 575px) {
    .raid-boss--container {
      height: 300px;
    }
    .battle-arena__battlefield--player-pet--container {
      width: 255px;
    }
    .battle-arena__UI-bottom--container {
      background: url(../../../assets/raid/ui-bg-frame--mobile.png);
      background-repeat: no-repeat;
      background-position: center;
      background-size: 600px;
      height: 4.5rem;
    }
  }
  @media only screen and (max-height: 525px) {
    .pet-elemental--icon,
    .enemy-elemental--icon {
      font-size: 2rem;
      -webkit-text-stroke: 3.5px black;
    }

    .raid-boss--container {
      height: 275px;
    }
    .battle-arena__battlefield--player-pet--container {
      width: 245px;
    }
  }
  @media only screen and (max-height: 445px) {
    .raid-boss--container {
      height: 230px;
    }
    .battle-arena__battlefield--player-pet--container {
      width: 215px;
    }
  }
  @media only screen and (max-height: 445px) {
    .pet-elemental--icon {
      margin-bottom: -4rem
    }
    .pet-elemental--icon,
    .enemy-elemental--icon {
      font-size: 1.75rem;
      -webkit-text-stroke: 3px black;
    }
    .battle-arena__UI-top {
      &--text {
        width: 60px;
        margin-top: 0.75rem;
        i {
          font-size: 1.5rem;
          margin-top: 0.5rem;
        }
      }
      &--container {
      top: 0;
      }
      &--player-stats {
        margin: 0 0.15rem;
        p {
          font-size: 1rem;
        }
        &--content {
          transform: translateY(0.75rem) translateX(-0.5rem);
        }
      }
      &--enemy-stats {
        margin: 0 0.15rem;
        p {
          font-size: 1rem;
        }
        &--content {
          transform: translateY(0.75rem) translateX(0.5rem);
        }
      }
      &--player-healthbar__container,
      &--enemy-healthbar__container {
        height: 35px;
        p {
          font-size: 0.75rem;
        }
      }
      &--player-portrait,
      &--enemy-portrait {
        transform: scale(0.8)
      }
    }
  }
  @media only screen and (max-height: 400px) {
    .pet-elemental--icon,
    .enemy-elemental--icon {
      font-size: 1.5rem;
      -webkit-text-stroke: 2.5px black;
    }
    .battle-arena__UI-top {
      &--player-stats,
      &--enemy-stats {
        p {
          font-size: 0.8rem;
        }
      }
    }

    .raid-boss--container {
      height: 225px;
    }
    .battle-arena__battlefield--player-pet--container {
      width: 215px;
    }
  }
  @media only screen and (max-height: 375px) {
    .raid-boss--container {
      height: 180px;
    }
    .battle-arena__battlefield--player-pet--container {
      width: 175px;
      margin-bottom: -40px;
    }
  }
  @media only screen and (max-height: 330px) {
    .raid-boss--container {
      height: 160px;
    }
    .battle-arena__battlefield--player-pet--container {
      width: 155px;
      margin-bottom: -30px;
    }
  }

</style>
