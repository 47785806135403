<template>
  <div class="fixed-component">
    <!-- keep library alive since it involves quite a bit of caching -->
    <keep-alive
      :include="['LibraryOverview', 'LibraryStoryarcMain', 'LibraryStory']">
      <router-view class="scrollable-component"></router-view>
    </keep-alive>
  </div>
</template>

<script>

export default {
  name: 'LibraryMain',
  components: {
  },
  data() {
    return {
    };
  },
  computed: {
  },
  mounted: function() {
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
</style>
