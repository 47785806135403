<template>
  <!-- <div @click="showSentences = !showSentences" -->
  <div
    @click="playAudio"
    class="oral-token__component">
    <div class="oral-token__component--word chinese-character">
      {{ word.text }}
    </div>
    <div class="oral-token__component--accuracy">
      Accuracy: {{ formatPercentage(word.correct_percentage) }}%
    </div>
    <audio :src="word.audio_src" ref="audio"></audio>
    <i class="fa-solid fa-volume-high"></i>
    <!-- <div class="oral-token__component--sentence" v-show="showSentences">
      <ul>
        <li v-for="(sentence, index) in word.sentences" :key="index">
          {{ sentence.text }}
          {{ sentence.token_score_in_sentence }}%
          {{ sentence.date_tested }}
        </li>
      </ul>
    </div> -->
  </div>
</template>

<script>
export default {
  name: 'OralToken',
  props: {
    word: Object,
  },
  data() {
    return {
      showSentences: false,
    };
  },
  mounted() {
  },
  methods: {
    playAudio() {
      console.log(this.word.audio_src);
      if (this.word.audio_src === '') return;
      this.$refs.audio.play();
    },
    formatPercentage(percent) {
      return Math.floor(percent * 100);
    },
  },
};
</script>

<style lang="scss" scoped>
  .oral-token__component {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2.5rem 0 2rem 0;
    background: #fff;
    border-radius: .5rem;
    width: calc(20% - 0.4rem);
    position: relative;
    cursor: pointer;
    max-height: 191px;
    &:hover {
      i {
        color: #991ece;
      }
    }
    i {
      position: absolute;
      top: .75rem;
      right: .75rem;
      color: #c7c7c7;
    }
    &--word {
      font-size: 2.5rem;
      margin-bottom: .25rem;
    }
    &--accuracy {
      font-weight: 500;
      font-style: italic;
      font-size: .9rem;
    }
    &--sentence {
      position: fixed;
      background: #000000e0;
      top: 0;
      left: 0;
      height: 100vh;
      width: 100vw;
      display: flex;
      justify-content: center;
      align-items: center;
      ul {
        background: #fff;
        padding: 1.5rem;
      }
    }
  }
  @media only screen and (max-width: 1500px) {
    .oral-token__component {
      width: calc(25% - .4rem);
    }
  }
  @media only screen and (max-width: 1000px) {
    .oral-token__component {
      padding: 1.5rem 0 1rem 0;
      width: calc(33.33% - .4rem);
    }
  }
  @media only screen and (max-width: 760px) {
    .oral-token__component {
      width: calc(50% - .4rem);
    }
  }
  @media only screen and (max-width: 520px) {
    .oral-token__component {
      &--word {
        font-size: 2rem;
      }
    }
  }

</style>
