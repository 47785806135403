<!-- Layout for ./learningtrack/modules/module -->
<template>
  <div class="subtrack-index__component row">
    <div class="subtrack-index__header">
      <router-link
        v-if="learningTrackType === 'vocabulary'"
        class="subtrack-index__header--back"
        :to="{name: 'learning-track-vocabulary-index'}">
        <i class="fas fa-angle-left"></i>
      </router-link>
      <router-link
        v-if="learningTrackType === 'assessment_book'"
        class="subtrack-index__header--back"
        :to="{name: 'learning-track-assessment-book-index'}">
        <i class="fas fa-angle-left"></i>
      </router-link>
      <h2
        v-if='learningTrack && subtrack'>
        {{ learningTrack.level | shorternLevel }} |
        {{ subtrack | splitCapitalise }}
        Track
      </h2>
    </div>
    <div class="subtrack-index__subheader">
      <p>Choose one of the following:</p>
    </div>
    <div class="subtrack-index__content">
      <learning-track-item
        class="subtrack-index__item"
        v-for="item in learningTrackItems"
        :key="item.index"
        :item="item"
        :stars="chineseQuizStars[item.chinese_quiz_id]">
      </learning-track-item>
    </div>
  </div>
</template>

<script>
import {mapState, mapGetters} from 'vuex';

import LearningTrackItemComponent from
  './LearningTrackItemComponent.vue';

export default {
  name: 'LearningTrackSubtrackIndex',
  components: {
    LearningTrackItem: LearningTrackItemComponent,
  },
  props: {
    learningTrackPk: Number,
    subtrack: String,
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapState(['chineseQuizStars']),
    ...mapGetters('learningTrack', ['getLearningTrackItemsByPkSubtrack',
      'getLearningTrackById']),
    // returns only the items in the learning track and subtrack
    learningTrackItems() {
      return this.getLearningTrackItemsByPkSubtrack(this.learningTrackPk,
          this.subtrack);
    },
    // refers to the selected learning track
    learningTrack() {
      return this.getLearningTrackById(this.learningTrackPk);
    },
    learningTrackType() {
      if (!this.learningTrack) return;
      return this.learningTrack.learning_track_type;
    },
  },
  mounted: function() {
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
  .subtrack-index {
    &__component {
      text-align: center;
      margin: 20px auto 0 auto;
    }
    &__header {
      background: #5a4179;
      color: #fff;
      padding: 10px;
      border-radius: 3px;
      margin-bottom: 5px;
      position: relative;
      &--back {
        position: absolute;
        left: 10px;
        font-size: 1.5em;
        color: #fff;
        margin-left: 5px;
      }
    }
    &__subheader {
      margin: 10px auto;
      font-size: 16px;
      font-style: italic;
    }
    &__content {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 40px;
      margin-bottom: 30px;
    }
    &__item {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
  }

  @media only screen and (max-width: 1140px) {
    .subtrack-index {
      &__component {
        margin: 10px auto 0 auto;
      }
      &__header {
        font-size: 16px;
        margin-bottom: 0;
      }
      &__content {
        margin-bottom: 55px;
        grid-gap: 20px;
        grid-template-columns: 1fr 1fr;
      }
    }
  }
  @media only screen and (max-width: 1140px) {
    .subtrack-index {
      &__content {
        margin-bottom: 65px;
      }
    }
  }

  @media only screen and (max-width: 630px) {
    .subtrack-index {
      &__content {
        grid-template-columns: 1fr;
      }
    }

  }


</style>
