/* eslint-disable */

(function(document,navigator,standalone) {
  // prevents links from apps from oppening in mobile safari
  // this javascript must be the first script in your <head>
  if ((standalone in navigator) && navigator[standalone]) {
    var curnode, location=document.location, stop=/^(a)$/i;
    document.addEventListener('click', function(e) {
      curnode=e.target;


      while (curnode && !(stop).test(curnode.nodeName)) {
        curnode=curnode.parentNode;
      }

      var skip = false;

      if(curnode) {
        Object.keys(curnode.dataset).forEach(function (key) {
          if( (/^v\-.+/i).test(key) ) {
            skip = true;
          }
        });
      }

      if(!curnode || skip || curnode.__vue__) {
        e.preventDefault();
        return;
      }

      // Condidions to do this only on links to your own app
      // if you want all links, use if('href' in curnode) instead.
      if(
        'href' in curnode && // is a link
        (chref=curnode.href).replace(location.href,'').indexOf('#') && // is not an anchor
        (	!(/^[a-z\+\.\-]+:/i).test(chref) ||                       // either does not have a proper scheme (relative links)
          chref.indexOf(location.protocol+'//'+location.host)===0 ) // or is in the same protocol and domain
      ) {
        e.preventDefault();
        location.href = curnode.href;
      }
    },false);
  }
})(document,window.navigator,'standalone');
