<template>
  <div v-if="results" class="main">
    <div>
      <h4>《{{ results.title }}》</h4>
      <ul>
        <li
          v-for="(word, index) in results.words" :key="index">
          {{ word.text }}
          <br>
          <!-- put in separate component -->
          Recognition:
          {{ convertRatingToText(word.recognition.rating) }}
          <correct-streak :streak-data="word.recognition.answer_history[0]"></correct-streak>
          <br>
          Recognition:
          {{ convertRatingToText(word.meaning.rating) }}
          <correct-streak :streak-data="word.meaning.answer_history[0]"></correct-streak>
          <hr>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>

import CorrectStreakComponent from './components/CorrectStreakComponent.vue';

import axios from 'axios';

export default {
  name: 'AnalyticsChapterVocabBreakdown',
  components: {
    CorrectStreak: CorrectStreakComponent,
  },
  props: {
    chapterId: Number,
  },
  data() {
    return {
      fetching: false,
      results: null,
    };
  },
  computed: {
  },
  mounted: function() {
    this.pullData();
  },
  methods: {
    pullData() {
      if (this.fetching) return;
      this.fetching = true;
      const url = '/analytics/api/chapter/';
      return axios
          .post(url, {
            chapterId: this.chapterId,
            // dateRange: this.dateRange,
            // filterBy: this.filterBy,
          })
          .then((response) => {
            this.results = response.data;
            console.log(this.results);
            this.fetching = false;
          })
          .catch((err) => {
            console.log(err);
            this.fetching = false;
          });
    },
    convertRatingToText(rating) {
      if (rating === 0) return 'Untested';
      if (rating === 1) return 'Unsure';
      if (rating === 2) return 'Learning';
      if (rating === 3) return 'Proficient';
      if (rating === 4) return 'Mastered';
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
