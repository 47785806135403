<template>
  <div class="game-overview__component row">
    <div class="game-overview__header">
      <h2>My Educational Games</h2>
    </div>
    <div class="game-overview__items">
      <router-link
        class="game-overview__item"
        :to="{name:'game-flashcard-memory'}">
        <h3 class="game-overview__item--text">
          Memory Flashcard Game
        </h3>
      </router-link>
    </div>
  </div>
</template>

<script>
// import axios from 'axios';
// import {mapState} from 'vuex';

export default {
  name: 'GameMain',
  components: {
  },
  props: {
  },
  data() {
    return {
    };
  },
  computed: {
    // ...mapState('library', ['currentStoryarc']),
  },
  mounted: function() {
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
  .game-overview {
    &__component {
      text-align: center;
      margin: 20px auto 0 auto;
    }
    &__header {
      background: #5a4179;
      color: #fff;
      padding: 10px;
      border-radius: 3px;
      margin-bottom: 25px;
    }
    &__items {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 40px;
      margin-bottom: 30px;
    }
    &__item {
      position: relative;
      max-width: 550px;
      max-height: 360px;
      color: #555;
      background: #fff;
      padding: 35px;
      border-radius: 3px;
      text-decoration: none;
      border: 8px solid transparent;
      transition: all 0.2s;
      &--text {
        font-size: 16px;
        font-style: italic;
      }
    }
  }
</style>
