<template>
  <div class="dynamic-book__index row">
    <h2 class="dynamic-book__index--title">Index</h2>
    <ul class="dynamic-book__index--list">
      <router-link
        class="dynamic-book__index--list-item"
        v-for="(book, index) in bookTitles"
        :key="index"
        :to="{name: 'dynamic-book-overview',
        params: {bookId: book.id}}">
        <div class="dynamic-book__index--list-item-icon">
          <div class="story-thumbnail"
            :class="'story-' + index + '-thumbnail'">
          </div>
          <p>{{ book.title }}</p>
        </div>
      </router-link>
    </ul>
  </div>
</template>

<script>

import {mapGetters, mapMutations} from 'vuex';

export default {
  name: 'DynamicBookIndex',
  components: {
  },
  props: {
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters('dynamicBook', ['bookTitles']),
    // thumbnailClass() {
    //   // Need to set '0' to this.storyId
    //   return 'story-' + '0' + '-thumbnail';
    // },

  },
  mounted: function() {
    // when we go to index page, then automatically reset
    this.setCurrentSceneId(0);
  },
  methods: {
    ...mapMutations('dynamicBook', ['setCurrentSceneId']),
  },
};
</script>

<style lang="scss" scoped>
.story-thumbnail {
  height: 0;
  width: 100%;
  padding-top: 50%;
  background-size: 100%;
  background-image: url('https://smartschool-static.s3-ap-southeast-1.amazonaws.com/media/dynamicbooks/2019_dec_holiday_camp/title-scene/title-thumbnails.jpg');
}

.story-0-thumbnail {
  background-position: 0 5%;
}

.story-1-thumbnail {
  background-position: 0 35%;
}

.story-2-thumbnail {
  background-position: 0 66%;
}

.story-3-thumbnail {
  background-position: 0 95%;
}

.dynamic-book__index {
  text-align: center;
  margin: 20px auto 0 auto;
  &--title {
    background: #5a4179;
    color: #fff;
    padding: 10px;
    border-radius: 3px;
    margin-bottom: 25px;
  }
  &--list {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 20px;
    &-item {
      background: #fff;
      padding: 30px;
      color: #000;
      text-decoration: none;
      &-icon {
        border-radius: 14px;
        border: 6px solid #efefef;
        padding: 20px;
        margin: 0 auto 10px auto;
        transition: all 0.2s;
        height: 151px;
        p {
          margin-top: 10px;
        }
        i {
          color: #aaa;
          font-size: 3.5rem;
          margin-bottom: 5px;
        }
      }
    }
  }
}
</style>
