<template>
  <div id="nav-bottom">
    <!-- <router-link to="/shop">
      <i class="fas fa-store"></i>
      Shop
    </router-link> -->
    <router-link to="/pet">
      <i class="fas fa-dog"></i>
      Pets
    </router-link>
    <router-link :to="{name: 'profile-main'}">
      <i class="fas fa-home"></i>
      Home
    </router-link>
    <router-link :to="{name: 'new-learn-overview'}">
      <i class="fas fa-book"></i>
      Learn
    </router-link>
    <router-link to="/library">
      <i class="fas fa-book-open"></i>
      Stories
    </router-link>
  </div>
</template>

<script>

export default {
  name: 'NavBottom',
  components: {
  },
  props: {
  },
  data() {
    return {
    };
  },
  computed: {
  },
  mounted: function() {
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
  #nav-bottom {
    text-align: center;
    position: fixed;
    height: 58px;
    bottom: 0;
    width: 100vw;
    background-color: #513369;
    font-family: 'Baloo Da 2', Avenir, Helvetica, Arial, sans-serif;
    a {
      border-top: 4px solid transparent;
      transition: border-top 0.2s, color 0.2s;
      background: #5a4179;
      display: inline-block;
      min-width: calc((100% / 5) - 3px);
      font-size: 1rem;
      padding: 10px 0;
      font-weight: 600;
      outline: none;
      text-decoration: none;
      color: rgba(255, 255, 255, 0.2);
      height: 100%;
      i {
        margin-right: 0.25rem;
      }
      &:last-child {
        border-right: none;
      }
      &.router-link-exact-active {
        background: #6e4290;
        color: #fff;
        font-size: 1.2rem;
        padding: 5.75px 0;
        border-top: 4px solid #b52491;
      }
    }
  }
  @media only screen and (max-width: 880px) {
    #nav-bottom {
      a {
        font-size: 0.8rem;
        padding: 12px 0;
        &.router-link-exact-active {
          font-size: 1rem;
          padding: 8px 0;
        }
      }
    }
  }
  @media only screen and (max-width: 640px) {
    #nav-bottom {
      a {
        display: inline-flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0;
        font-size: 0.5rem;
        i {
          margin-right: 0;
          margin-bottom: 0.125rem;
          font-size: 1.1rem;
        }
        &.router-link-exact-active {
          font-size: 0.65rem;
          padding: 0;
          i {
            margin-top: 0.25rem;
            font-size: 1.2rem;
          }
        }
      }
    }
  }
</style>
