<template>
  <countdown :time="timeToChallengeEnd"
    class="daily-challenge-menu__timer">
    <template slot-scope="props">
      <i class="fas fa-hourglass"></i>
      <div class="daily-challenge-menu__timer--numbers">
        <span class="daily-challenge-menu__timer--number">
          <span v-if="props.days < 10">0</span>{{ props.days }}
        </span>
        <span class="daily-challenge-menu__timer--number">
          <span v-if="props.hours < 10">0</span>{{ props.hours }}
        </span>
        <span class="daily-challenge-menu__timer--number">
          <span v-if="props.minutes < 10">0</span>{{ props.minutes }}
        </span>
        <span class="daily-challenge-menu__timer--number">
          <span v-if="props.seconds < 10">0</span>{{ props.seconds }}
        </span>
      </div>
      <div class="daily-challenge-menu__timer--labels">
        <span class="daily-challenge-menu__timer--label">days</span>
        <span class="daily-challenge-menu__timer--label">hours</span>
        <span class="daily-challenge-menu__timer--label">minutes</span>
        <span class="daily-challenge-menu__timer--label">seconds</span>
      </div>
      <div class="daily-challenge-menu__timer--dividers">
        <span class="daily-challenge-menu__timer--divider"></span>
        <span class="daily-challenge-menu__timer--divider"></span>
        <span class="daily-challenge-menu__timer--divider"></span>
        <span class="daily-challenge-menu__timer--divider"></span>
      </div>
      <div class="daily-challenge-menu__timer--title">Time Remaining:</div>
    </template>
  </countdown>
</template>

<script>

export default {
  name: 'DailyChallengeCountdown',
  components: {
  },
  mixins: [],
  props: {
    timeToChallengeEnd: Number,
  },
  data() {
    return {
    };
  },
  computed: {
  },
  mounted() {
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
  .daily-challenge-menu {
    &__timer {
      height: 80px;
      margin-left: 20px;
      align-self: center;
      width: 480px;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      background: linear-gradient(30deg, rgba(40,191,219,1) 0%,
                    rgba(194,22,212,1) 50%, rgba(233,145,28,1) 100%);
      font-family: iceland;
      color: #fff;
      i {
        margin-left: 10px;
        font-size: 40px;
      }
      &--numbers {
        margin-left: 15px;
      }
      &--number {
        width: 100px;
        display: inline-block;
        font-size: 60px;
        margin-bottom: 20px;
      }
      &--labels {
        text-transform: uppercase;
        position: absolute;
        bottom: 10px;
        margin-left: 52px;
      }
      &--label {
        width: 100px;
        display: inline-block;
        font-weight: bold;
      }
      &--title {
        position: absolute;
        top: -20px;
        right: 0;
        color: #555;
        text-transform: uppercase;
      }
      &--dividers {
        position: absolute;
        height: 100%;
        left: 60px;
      }
      &--divider {
        display: inline-block;
        width: 5px;
        height: 100%;
        background: #e9e9e9;
        &:not(:last-child) {
          margin-right: 93px;
        }
      }
    }
  }
  @media only screen and (max-width: 760px) {
    .daily-challenge-menu {
      &__timer {
        height: 50px;
        i {
          font-size: 30px;
        }
        &--numbers {
          margin-left: 20px;
        }
        &--number {
          font-size: 45px;
          margin-bottom: 13px;
        }
        &--labels {
          bottom: 2px;
        }
        &--label {
          font-size: 15px;
        }
      }
    }
  }
  @media only screen and (max-width: 630px) {
    .daily-challenge-menu {
      &__timer {
        width: auto;
        margin-left: 8px;
        i {
          font-size: 25px;
        }
        &--numbers {
          margin-left: 10px;
        }
        &--number {
          width: 60px;
          font-size: 40px;
          margin-bottom: 13px;
        }
        &--labels {
          bottom: 2px;
          margin-left: 37px;
        }
        &--label {
          width: 60px;
          font-size: 15px;
        }
        &--dividers {
          left: 38px;
        }
        &--divider {
          &:not(:last-child) {
            margin-right: 53px;
          }
        }
        &--title {
          font-size: 17px;
          top: -17px;
        }
      }
    }
  }
  @media only screen and (max-width: 414px) {
    .daily-challenge-menu {
      &__timer {
        height: 45px;
        i {
          font-size: 20px;
          margin-left: 8px;
        }
        &--number {
          width: 40px;
          font-size: 35px;
          margin-bottom: 8px;
        }
        &--labels {
          margin-left: 15px;
        }
        &--label {
          width: 40px;
          font-size: 10px;
        }
        &--dividers {
          left: 30px;
        }
        &--divider {
          &:not(:last-child) {
            margin-right: 36px;
          }
          width: 3px;
        }
        &--title {
          font-size: 14px;
          top: -14px;
        }

      }
    }
  }
</style>

