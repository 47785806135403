<template>
  <div class="templated-phrases-component chinese-character">
    <div v-if="topic" class="templated-phrases-component__container">
      <!-- {{ topic }} -->
      <div class="templated-phrases-component__header">
        <div class="templated-phrases-component__header--left">
          <h2 class="templated-phrases-component__header--left-main">
            通用好词佳句:
          </h2>
          <h3 class="templated-phrases-component__header--left-sub">
            {{ topic.chinese_title }}
          </h3>
        </div>
        <div class="templated-phrases-component__header--right">
          <p>模版：{{ this.questionIndex + 1 }} / {{ this.numTemplates }}</p>
        </div>
        <router-link
          class="chinese-character"
          :to="{name: 'new-oral-category-index', params: {category: 'templatedPhrases'}}">
          <a data-v-23d2ba43="" href="#" class="close"></a>
        </router-link>
      </div>
      <div class="templated-phrases-component__content">
        <div class="templated-phrases-component__content--template-container">
          <div class="templated-phrases-component__nav">
            <div @click="nextQns"
              :class="{ 'nav-disabled': questionIndex === 0 }"
              class="templated-phrases-component__nav-btn">
              <i class="fas fa-chevron-left"></i>
            </div>
            <transition name="fade" mode="out-in">
              <div v-if="toShow === 'template'"
                class="templated-phrases-component__content--template">
                  {{ templateText }}
                </div>
              <div v-if="toShow === 'templateWithStructure'"
                class="templated-phrases-component__content--template hint"
                v-html="templateWithStructure"></div>
              <div v-if="toShow === 'example'"
                class="templated-phrases-component__content--template hint"
                v-html="templateExample"></div>
            </transition>            <div @click="nextQns"
              :class="{ 'nav-disabled': questionIndex === numQuestions - 1 }"
              class="templated-phrases-component__nav-btn">
              <i class="fas fa-chevron-right"></i>
            </div>
          </div>


        </div>
        <div class="templated-phrases-component__content--info-container">
          <div class="templated-phrases-component__content--info-text">
            {{ currentTemplate['explanation'] }}
          </div>
          <div class="templated-phrases-component__content--info-btn--grp">
            <div class="templated-phrases-component__content--info-btn"
              @click="toShow = 'template'">
              Template
            </div>
            <div class="templated-phrases-component__content--info-btn"
              @click="toShow = 'templateWithStructure'">
              Structure
            </div>
            <div class="templated-phrases-component__content--info-btn"
              @click="toShow = 'example'">
              Example
            </div>
          </div>
        </div>
        <div class="templated-phrases-component__btn-practice--container">
          <router-link
            class="templated-phrases-component__btn-practice"
            :to="{name: 'oral-passage-with-question-id',
            params: {
              type: 'templatedphrases',
              entityId: topic.id,
              questionIndex: questionIndex,
            }}">
              Practice
          </router-link>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {mapMutations} from 'vuex';
import axios from 'axios';

export default {
  name: 'OralTemplatedPhraseTopic',
  components: {
  },
  props: {
    topicId: Number,
  },
  data() {
    return {
      questionIndex: 0,
      numTemplates: 0,
      topic: null,
      toShow: 'template',
      exampleId: 0,
    };
  },
  computed: {
    currentTemplate() {
      if (!this.topic || this.questionIndex >= this.topic.templates.length) return null;
      return this.topic.templates[this.questionIndex];
    },
    templateText() {
      return this.$options.filters.hyrateQuestionText(this.currentTemplate['template']);
    },
    // replaces the blanks in
    templateWithStructure() {
      if (!this.currentTemplate) return null;
      let text = this.currentTemplate['template'];
      for (let i = 0; i < this.currentTemplate['hints'].length; i++) {
        // replace only replaces the first instance
        // we loop through each of the hints, and replace a corresponding {__} with it
        text = text.replace('{__}', `<u class="hint">&nbsp;&nbsp;&nbsp;${this.currentTemplate['hints'][i]}&nbsp;&nbsp;&nbsp;</u>`);
      }
      return text;
    },
    // for now, we just show first example?
    templateExample() {
      if (!this.currentTemplate) return null;
      const data = this.currentTemplate['texts'][this.exampleId]['fills'];
      let text = this.currentTemplate['template'];
      for (let i = 0; i < data.length; i++) {
        // replace only replaces the first instance
        // we loop through each of the hints, and replace a corresponding {__} with it
        text = text.replace('{__}', `<u class="hint">&nbsp;&nbsp;&nbsp;${data[i]['text']}&nbsp;&nbsp;&nbsp;</u>`);
      }
      return text;
    },
  },
  mounted() {
    // checks to see if we need to pull free response topics
    // if (!this.freeResponseTopics || this.freeResponseTopics.length === 0) {
    this.setShowGlobalLoadingScreen(true);
    axios
        .get(`/vocab/templatedphrases/${this.topicId}/`)
        .then((response) => {
          this.topic = response.data;
          this.numTemplates = this.topic['templates'].length;
          console.log(this.topic);
          this.setShowGlobalLoadingScreen(false);
        });
    // }
  },
  methods: {
    ...mapMutations(['setShowGlobalLoadingScreen']),
    nextQns() {
      this.questionIndex++;
      if (this.questionIndex >= this.numTemplates) {
        this.questionIndex = 0;
        return;
      };
    },
  },
};

</script>

<style lang="scss" scoped>
.button-placeholder {
    width: 200px;
    text-align: center;
    border-radius: 0.5rem;
    font-size: 1.1rem;
    font-weight: 600;
    background: #22b492;
    box-shadow: 0px 5px #2e8771;
    color: #fff;
    padding: .5rem 1rem;
    cursor: pointer;
    transition: all 0.3s;
    &:hover {
      transform: scale(1.03);
      background: #3de7c0;
      box-shadow: 0px 5px #3bc6a4;
      &::after {
        transform: scaleX(1.1) scaleY(1.3);
        opacity: 0;
      }
    }
    &:active {
      transform: translateY(-1px);
    }
}

.hint ::v-deep u {
  color: red;
}

.templated-phrases-component {
  background: #fff;
  height: 100vh;
  width: 100vw;
  top: 0;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  &__container {
    height: 80%;
    max-width: 820px;
  }
  &__header {
    height: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    &--left {
      flex: 1;
      h2 {
        font-size: 1.25rem;
        font-weight: 600;
        flex: 1;
      }
      h3 {
        font-size: 1rem;
      }
    }
    p {
      font-size: .9rem;
      font-weight: 600;
      padding: 0.25rem 0.5rem;
      border-radius: 20px;
      background: #ffcf6d;
    }
  }
  &__content {
    height: 90%;
    width: 100%;
    &--template {
      margin: 0 1.5rem;
      &-container {
        height: 50%;
        text-align: center;
        border-top: 2px solid #ccc;
        border-bottom: 2px solid #ccc;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.25rem;
        font-weight: 600;
      }
    }
    &--info {
      &-btn {
        &--grp {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-wrap: wrap;
        }
        margin: 1rem 0.5rem;
        text-decoration: none;
        width: 175px;
        text-align: center;
        border-radius: 0.5rem;
        font-size: 1.1rem;
        font-weight: 600;
        background: #22b492;
        box-shadow: 0px 5px #2e8771;
        color: #fff;
        padding: .25rem;
        cursor: pointer;
        transition: all 0.3s;
        &:hover {
          transform: scale(1.03);
          background: #3de7c0;
          box-shadow: 0px 5px #3bc6a4;
          &::after {
            transform: scaleX(1.1) scaleY(1.3);
            opacity: 0;
          }
        }
        &:active {
          transform: translateY(-1px);
        }
      }
      &-container {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        flex-direction: column;
        height: 40%;
        padding: 0rem 3rem;
        border-radius: 1rem;
        margin: 1rem auto;
      }
      p {
        font-size: 1.2rem;
        text-indent: 40px;
        margin-bottom: 1rem;
        line-height: 2rem;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  &__nav {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    &-btn {
      padding: 0.5rem;
      border-radius: 1rem;
      cursor: pointer;
      justify-content: center;
      align-items: center;
      display: flex;
    }
  }
  &__btn-practice {
    text-decoration: none;
    &--container {
      height: 10%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    width: 250px;
    text-align: center;
    border-radius: 0.5rem;
    font-size: 1.1rem;
    font-weight: 600;
    background: #22b492;
    box-shadow: 0px 5px #2e8771;
    color: #fff;
    padding: .5rem 1rem;
    cursor: pointer;
    transition: all 0.3s;
    &:hover {
      transform: scale(1.03);
      background: #3de7c0;
      box-shadow: 0px 5px #3bc6a4;
      &::after {
        transform: scaleX(1.1) scaleY(1.3);
        opacity: 0;
      }
    }
    &:active {
      transform: translateY(-1px);
    }
  }
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity .2s
}

.fade-enter,
.fade-leave-to {
    opacity: 0
}

.nav-disabled {
  cursor: not-allowed;
  opacity: 0.1;
}

@media only screen and (max-width: 820px) {
  .close {
    left: 16px;
    top: 26px;
  }
  .templated-phrases-component__header--left {
    margin-left: 2.5rem;
  }
  .templated-phrases-component__header--right {
    margin-right: 1rem;
  }
  .templated-phrases-component__content--template-container {
    padding: 0 1rem;
  }
  .templated-phrases-component__content--template {
    margin: 0 0.5rem;
  }
  .templated-phrases-component__header {
    margin: 0 0.5rem;
  }
  .templated-phrases-component__container {
    height: 100%;
  }
  .templated-phrases-component__content--info-btn {
    margin: 0.5rem;
    font-size: 0.9rem;
    width: 140px;
  }
  .templated-phrases-component__content--info-container {
    padding: 0 1rem;
  }
  .templated-phrases-component__btn-practice {
    width: 100%;
    border-radius: 0;
    &--container {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      justify-content: flex-start;
      align-items: flex-end;
    }
  }
}
</style>
