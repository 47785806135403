<!-- TODO: add back video popover of vocab words -->
<template>
  <div>
    <ul>
      <li v-for="(word) in wordsToRecognise"
        :key="word.pk"
        class="keyword__list-item play-sound tap-btn-highlight">
        <div
          v-if="word.sprout_video_id"
          @click="showWordVideo(word)"
        >
            <div
              :class="{blinking: showUnseenVideosNotification}"
              class="keyword__list-item--video--icon">
              <span class="video-icon fas fa-video"></span>
            </div>
        </div>
<!--         <div
          v-if="word.video_code"
          @click="seenVocabVideo"
          :class="'wistia_async_' + word.video_code"
          class="wistia_embed
          keyword__list-item--video
          popover=true popoverContent=html">
            <div
              :class="{blinking: showUnseenVideosNotification}"
              class="keyword__list-item--video--icon">
              <span class="video-icon fas fa-video"></span>
            </div>
        </div> -->
        <div class="keyword__list-item--definition"
          @click="playSound(word.text, $event)">
          <p class="keyword">
            <span class="chinese-character">{{ word.text }}</span>
            <span class="hypy">[{{ word.pinyin }}]</span>
          </p>
          <p class="keyword-definition-container">
            <span class="definition-header">Definition:</span>
            <span class="definition-content">{{ word.definition }}</span>
          </p>
          <span class="sound-icon fas fa-volume-up"></span>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import {mapState} from 'vuex';
import {soundMixin} from '../../mixins/sound';

export default {
  name: 'WordList',
  components: {
  },
  mixins: [soundMixin],
  props: {
    wordsToRecognise: Array,
    manifest: Array,
  },
  data() {
    return {
      sounds: {},
    };
  },
  computed: {
    ...mapState(['seenFeatures']),
    // typeof(seenFeatures['vocab_video']) === 'undefined'
    // the above code is to check if feature doesn't exist in student
    // i.e. if feature is for another level; then don't show
    showUnseenVideosNotification() {
      return typeof(this.seenFeatures['vocab_video']) !== 'undefined' &&
        !this.seenFeatures['vocab_video'];
    },
  },
  watch: {
    // preloads sounds whenever we have new words
    wordsToRecognise: {
      immediate: true,
      handler() {
        if (!this.manifest || this.manifest.length == 0) {
          return;
        };
        this.preload(this.manifest, (data) => {
          this.sounds = data;
        });
      },
    },
  },
  mounted: function() {
  },
  methods: {
    playSound(word, event) {
      // hackish -> if user clicked on a video icon, don't playsound
      if (event.target.className.indexOf('video-icon') !== -1) return;

      const key = word + '_字';
      if (key in this.sounds) {
        this.sounds[key].play();
      };
    },
    showWordVideo(word) {
      this.seenVocabVideo();
      this.$emit('show-word-video',
          [word.sprout_video_id, word.sprout_security_token]);
    },
  },
};
</script>

<style lang="scss" scoped>
  .keyword__list-item {
    display: flex;
    min-width: 250px;
    &--video {
      padding: 0 5px;
      margin: 5px 5px 5px 0;
      border: 2px solid #eee;
      border-radius: 6px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      &--icon {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
      }
      &:hover {
        border: 2px solid #4ee8b7;
        .video-icon {
          color: #aa69f3;
        }
      }
    }
    &--definition {
      position: relative;
      padding: 10px 10px;
      margin: 5px 0;
      border: 2px solid #eee;
      border-radius: 6px;
      cursor: pointer;
      flex-grow: 1;
      .sound-icon {
        position: absolute;
        top: 10px;
        right: 10px;
      }
      &:hover {
        border: 2px solid #4ee8b7;
        .sound-icon {
          color: #aa69f3;
        }
      }
    }
  }
  .keyword {
    padding-bottom: .4rem;
    border-bottom: 1px solid #eee;
    .chinese-character {
      font-size: 1.2rem;
    }
    .hypy {
      font-size: 0.7rem;
    }
  }
  .keyword-definition-container {
    display: flex;
    font-size: .7rem;
    margin-top: .2rem;
    .definition-header {
      font-weight: bold;
      margin-right: 5px;
      margin-left: 10px;
    }
    .definition-content {
      font-style: italic;
      display: block;
      &:first-letter {
        text-transform: capitalize;
      }
    }
  }
  @media only screen and (max-width: 666px) and (max-height: 450px) {
    .keyword-container {
      min-width: auto;
    }
  }

  .blinking {
    color: #bd279b;
    animation: 3s infinite heartbeat;
  }
</style>
