<template>
  <div class="leaderboard__row"
    :class="{'current-student': currentStudent}">
    <span class="leaderboard__row--left">
      <span class="leaderboard__row--rank animated fadeInLeft">
        {{ rank + 1}}
      </span>
      <span class="leaderboard__row--portrait animated fadeInLeft delay-1s">
        <img class="avatar-img" v-if="avatar !== ''"
          :src='"../../../assets/avatars/" + avatar + ".png"' />
      </span>
      <span class="leaderboard__row--name animated fadeInLeft delay-2s">
        {{ name }}
      </span>
    </span>
    <span class="leaderboard__row--right">
      <span class="leaderboard__row--icons animated flipInY delay-3s">
        <span class="leaderboard__row--coin"
          v-if="typeOfLeaderboard === 'Coins'">
          <span class="coin-container">
            <i class="fas fa-star"></i>
          </span>
        </span>
        <span class="leaderboard__row--trophy"
          v-if="typeOfLeaderboard === 'Scores'">
          <i class="fas fa-trophy"></i>
        </span>
      </span>
      <span class="leaderboard__row--score animated fadeInRight delay-2s">
      {{ score }}
      </span>
    </span>
  </div>
</template>

<script>
import {mapState} from 'vuex';

export default {
  name: 'LeaderboardRow',
  components: {
  },
  props: {
    rank: Number,
    name: String,
    score: Number,
    currentStudent: Boolean,
    evenRow: Boolean,
    typeOfLeaderboard: String,
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapState(['avatar']),
  },
  mounted: function() {
  },
  methods: {
    isEvenRow(rank) {
      const row = rank + 1;
      return (row % 2 !== 0) ? 'bg-grey' : 'bg-white';
    },
  },
};
</script>

<style lang="scss" scoped>
  .delay-1s {
    animation-delay: 0.2s;
  }
  .delay-2s {
    animation-delay: 0.05s;
  }
  .delay-3s {
    animation-delay: 0.45s;
  }

  .current-student {
    .leaderboard__row {
      &--left{
        background: #ff9800;
      }
      &--right{
        background: #f17200;
      }
      &--rank {
        background: #ffcb00;
        color: #000;
      }
      &--name {
        color: #000;
      }
      &--trophy {
        color: #ffca00;
      }
    }
  }

  .coin-container {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    background: #ffbe43;
    height: 35px;
    width: 35px;
    border: 2px solid #000;
    border-radius: 50%;
    margin-right: 5px;
    i {
      font-size: 1rem;
      color: #fff;
    }
  }
  .leaderboard__row {
    border: 3PX solid #000;
    border-radius: 3px;
    overflow: hidden;
    height: 50px;
    font-family: 'Baloo Da 2';
    font-weight: 600;
    font-size: 1.5rem;
    margin: 2px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    &--left {
      background: #929292;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &--right{
      display: flex;
      justify-content: center;
      align-items: center;
      background: #656565;
    }
    &--rank {
      color: #b7b7b7;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 17.5px;
      border-radius: 3px;
      width: 30px;
      height: 30px;
      margin-right: 5px;
      margin-left: 15px;
      background: #676767;
    }
    &--portrait {
      background: #03a9f4;
      border-radius: 5px;
      border: 3px solid #000;
      height: 37.5px;
      width: 37.5px;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 5px;
      .avatar-img {
        max-height: 100%;
      }
    }
    &--name {
      color: #e4e4e4;
      width: 450px;
      text-align: left;
    }
    &--score {
      color: #fff;
      width: 100px;
    }
    &--icons {
      margin-left: 10px;
    }
    &--trophy {
      display: flex;
      color: #ffbe43;
    }
  }
  @media only screen and (max-width: 740px) {
    .leaderboard__row {
      &--name {
        width: 250px;
      }
    }
  }
  @media only screen and (max-width: 540px) {
    .leaderboard__row {
      height: 42.5px;
      &--name {
        font-size: 1.35rem;
      }
      &--portrait {
        height: 31.5px;
        width: 31.5px;
      }
      &--rank {
        font-size: 1.25rem;
        padding: 14.5px;
      }
      &--name {
        width: 135px;
      }
      &--score {
        width: 75px;
        font-size: 1.35rem;
      }
      &--trophy {
        font-size: 1.25rem;
      }
    }
    .coin-container {
      width: 30px;
      height: 30px;
      i {
        font-size: 0.85rem;
      }
    }
  }
  @media only screen and (max-width: 350px) {
    .leaderboard__row {
      height: 36.5px;
      &--name {
        font-size: 1.1rem;
      }
      &--portrait {
        height: 27.5px;
        width: 27.5px;
      }
      &--rank {
        font-size: 1.1rem;
        width: 15px;
        height: 15px;
        margin-left: 7.5px;
      }
      &--name {
        width: 115px;
      }
      &--score {
        width: 65px;
        font-size: 1.1rem;
      }
      &--trophy {
        font-size: 1.1rem;
      }
    }
    .coin-container {
      width: 25px;
      height: 25px;
      i {
        font-size: 0.75rem;
      }
    }
  }

</style>
