<template>
  <div v-if="info" @click="select" class="entity" :class="colorClass">
    <span
      class="entity__word chinese-character">{{ info.word }}
    </span>
    <span class="entity__pinyin">{{ pinyin }}</span>
  </div>
</template>

<script>

// import axios from 'axios';
import {mapMutations} from 'vuex';

// import {pinyinify} from '../../../vendor/pinyinify';

export default {
  name: 'OralEntity',
  components: {
  },
  props: {
    info: Object,
  },
  data() {
    return {
    };
  },
  computed: {
    // ...mapState(['studentId']),
    // ...mapState('guidedReading', ['selectedEntity', 'comprehensionId']),
    pinyin() {
      if (this.info.type === 'particle') return '';
      // // converts "tone3" to 3
      // const tone = this.info.details.tone[this.info.details.tone.length - 1];
      return this.info.details.pinyin;
    },
    colorClass() {
      if (this.info.type === 'particle') return;
      if (this.info.details.scores.overall_pron >= 90) {
        return 'green';
      }
      if (this.info.details.scores.overall_pron >= 60) {
        return 'yellow';
      }
      return 'red';
    },
  },
  mounted() {
  },
  methods: {
    ...mapMutations('guidedReading', ['setSelectedEntity']),
    select() {
      if (this.info['type'] === 'particle') return;
      this.setSelectedEntity(this.info['details']['token']);
      console.log(this.info['details']['token']);
    //   if (this.info['is_trackable']) {
    //     axios
    //         .post(`/api/v1/core/student/${this.studentId}/frequencylist/`, {
    //           wordId: this.info['pk'],
    //           comprehensionId: this.comprehensionId,
    //         });
    //   }
    },
  },
};
</script>

<style lang="scss" scoped>
.entity {
  &__word {
    margin-left: 0 !important;
    font-size: inherit;
  }
  cursor: pointer;
  width: 37.5px;
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 60px;
  &__pinyin {
    font-size: 0.6rem;
    text-align: center;
    font-family: "Heiti SC";
  }
}

.selected {
  background: #1dffca;
  border-radius: 0.3em;
  padding: 0.2rem 0;
}

.red {
  color: #ff6666;
}

.green {
  color: #22d183;
}

.yellow {
  color: #ff9f0d;
}

// @media only screen and (max-height: 740px) {
//   .entity {
//     height: 50px;
//   }
// }
@media only screen and (max-width: 375px) {
  .entity {
    height: 50px;
  }
}
// @media only screen and (max-width: 530px) {
//   .entity {
//     width: 30px;
//     &__pinyin {
//       font-size: 0.55rem;
//     }
//     &__word {
//       margin-left: 0 !important;
//     }
//   }
// }

</style>
