<template>
  <router-view></router-view>
</template>

<script>

export default {
  name: 'MultiplayerMain',
  components: {
  },
  props: {
  },
  data() {
    return {
    };
  },
  computed: {
  },
  mounted: function() {
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
</style>
