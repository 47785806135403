<template>
  <p class="paragraph">
    <sentence v-for="(text, index) in texts"
      :key="index"
      :sentence-index="index"
      :para-index="paraIndex"
      :text="text">
    </sentence>
  </p>

</template>

<script>

import SentenceComponent
  from './SentenceComponent.vue';

export default {
  name: 'ParagraphComponent',
  components: {
    Sentence: SentenceComponent,
  },
  props: {
    paraIndex: Number,
    texts: Array,
  },
  data() {
    return {
    };
  },
  computed: {
  },
  mounted() {
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
  .paragraph {
    padding-bottom: 30px;
    font-size: 1rem;
    letter-spacing: 0.05em;
    line-height: 150%;
    text-indent: 3rem;
    text-align: justify;
  }
</style>
