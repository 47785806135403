<template>
  <div @click.prevent="select" class="my-wordlist-row__component">
    <!-- word -->
    <div class="my-wordlist-row__component--icon-audio">
      <i class="fa-solid fa-volume-up"></i>
    </div>
    <div class="my-wordlist-row__component--content">
      <div :class="isWordLong"
        class="my-wordlist-row__component--content-word">
        <span class="my-wordlist-row__component--content-word-chinese chinese-character">{{ info.text }}</span>
        <span class="my-wordlist-row__component--content-word-hypy hypy">[ {{ info.pinyin }} ]</span>
      </div>
      <!-- eng def -->
      <div class="my-wordlist-row__component--content-definition">
        <!-- <span class="my-wordlist-row__component--definition-label">【英文】</span> -->
        <span class="my-wordlist-row__component--content-definition-body">{{ info.definition }}</span>
      </div>
    </div>
    <i class="fa-solid fa-circle-info"></i>
    <audio :src="info.audio_src" ref="audio"></audio>
  </div>
</template>

<script>

export default {
  name: 'MyWordlistRow',
  props: {
    info: Object,
  },
  components: {},
  data() {
    return {
    };
  },
  computed: {
    isWordLong() {
      if ( this.info.text.length >= 4) {
        return 'wordrow-flex-col';
      };
      return 'wordrow-flex-row';
    },
  },
  mounted() {
  },
  methods: {
    select() {
      if (this.info.audio_src === '') return;
      this.$refs.audio.play();
      this.$emit('selected', this.info);
    },
    // open() {
    //   // play audio when we open the modal
    //   // if (!this.showWordInfo) this.playAudio();
    //   // this.showWordInfo = !this.showWordInfo;
    //   console.log('open');
    //   this.playAudio();
    //   this.showWordInfo = true;
    // },
    // close() {
    //   console.log('close');
    //   this.showWordInfo = false;
    // },
    // playAudio() {
    //   if (this.info.audio_src === '') return;
    //   this.$refs.audio.play();
    // },
  },
};
</script>

<style lang="scss" scoped>
  .my-wordlist-row__component {
    display: flex;
    align-items: center;
    padding: 1rem;
    background: #fff;
    border-radius: .5rem;
    width: calc(33.33% - .5rem);
    position: relative;
    &:hover {
      .my-wordlist-row__component--icon-audio, .fa-circle-info {
        color: #2772fb;
      }
    }
    cursor: pointer;
    &--icon-audio {
      padding: .5rem;
      margin-right: .5rem;
      font-size: 1.1rem;
      color: #929292;
    }
    &--content {
      &-word {
        font-weight: 600;
        display: flex;
        &-chinese{
          font-size: 1.4rem;
        }
        &-hypy {
          align-self: center;
          margin-left: .25rem;
          margin-top: .25rem;
        }
      }
      &-definition {
        font-style: italic;
        font-size: .9rem;
        &-label {
          font-weight: 400;
        }
      }
    }
    .fa-circle-info {
      position: absolute;
      top: .75rem;
      right: .75rem;
      font-size: 1.2rem;
      color: #b2b2b2;
    }
  }
  .wordrow-flex-col {
    flex-direction: column;
    .my-wordlist-row__component--content-word-hypy {
      margin-left: 0;
    }
  }
  .wordrow-flex-row {
    flex-direction: row;
  }
  @media only screen and (max-width: 1420px) {
    .my-wordlist-row__component {
      width: calc(50% - .5rem);
    }
  }
  @media only screen and (max-width: 960px) {
    .my-wordlist-row__component {
      width: 100%;
    }
  }
</style>
