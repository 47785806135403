<template>
  <!-- start of modal -->
  <div class="popup">
    <div class="popup-container">
      <!-- header -->
      <div class="popup-container--header">
        <div v-if="info.chapter" class="popup-container--header-label">
          <span class="level">{{ info.chapter.level | convertPrimaryLevelToChinese }}</span>
          <span class="divider">|</span>
          <span class="">{{ info.chapter.number }}《{{ info.chapter.title }}》</span>
        </div>
        <div v-if="!info.chapter && info.story" class="popup-container--header-label">
          <span class="level">故事</span>
          <span class="divider">|</span>
          <span class="">《{{ info.story.title }}》</span>
        </div>
        <div v-if="!info.chapter && !info.story" class="popup-container--header-label">
          <span class="">扩展词语</span>
        </div>
        <div @click.prevent="_close" class="popup-container--header-btn-close">
          <i class="fa-solid fa-xmark"></i>
        </div>
      </div>
      <!-- body -->
      <div class="popup-container--body">
        <div class="popup-container-1">
          <!-- word -->
          <div @click.prevent="playAudio" class="popup-container-1-word">
            <div class="popup-container-1-word--chinese chinese-character">
              {{ info.text }}
            </div>
            <div class="popup-container-1-word--hypy hypy">
              [ {{ info.pinyin }} ]
              <span class="sound-icon fas fa-volume-up"></span>
            </div>
          </div>
          <!-- eng def -->
          <div class="popup-container-1-definition">
            <div class="popup-container-1-definition-label chinese-character">
              【英文】
            </div>
            <div class="popup-container-1-definition-body">
              <ul>
                <li v-for="(text, index) in meanings" :key="index">
                  <span class="popup-container-1-definition-body--index">
                    {{ index + 1 }}
                    </span>
                  <span class="popup-container-1-definition-body--text">
                    {{ meanings[index] }}
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <!-- picture -->
          <div v-if="'image_url' in info && info.image_url !== ''" class="popup-container-1-picture">
            <div class="popup-container-1-picture-label chinese-character">
              【图片】
            </div>
            <div class="popup-container-1-picture-container">
              <img :src="info.image_url">
              <!-- <img src='../../../assets/profile/btn-dailychallenge.jpg'/> -->
            </div>
          </div>
        </div>
        <div class="popup-container-2">
          <div class="popup-container-2-sentences-label chinese-character">
            【例句】
          </div>
          <ul class="popup-container-2-sentences"
            v-if="info.sentences.length > 0">
            <li
              v-for="(sentence, sentenceIndex) in info.sentences"
              :key="sentenceIndex">
              <!-- <span class="popup-container-2-sentences-icon--sound">
                <i class="fa-solid fa-volume-high"></i>
              </span> -->
              <span class="popup-container-2-sentences-index">
                {{ sentenceIndex + 1}}
              </span>
              <!--
                we want to style the word within the sentence
                note that we need to use deep to style scoped classes
                https://stackoverflow.com/questions/48331383/css-styles-not-being-applied-to-html-within-a-vue-component
              -->
              <span class="popup-container-2-sentences-sentence chinese-character"
                v-html="modifiyText(sentence)">
              </span>
            </li>
          </ul>
        </div>
        <div class="popup-container-3">
          <div class="popup-container-3--collocations-label chinese-character">
            【词语搭配】
          </div>
          <ul class="popup-container-3--collocations"
            v-if="info.collocations.length > 0">
            <!-- collocation is a tuple. First element is the text, second element is definiition  -->
            <li
              v-for="(collocation, collocationIndex) in info.collocations"
              :key="collocationIndex">
              <span class="collocations-index">
                {{ collocationIndex + 1 }}
              </span>
              <span class="collocations-content">
                <span class="chinese-character"
                  v-html="modifiyText(collocation[0])">
                </span>
                <span class="collocations-definition">{{ collocation[1] || '' }}</span>
              </span>
            </li>
          </ul>
        </div>
      </div>
      <!-- left side of modal -->
      <div class="popup-container--footer">
        <div v-if="includeIsRevisionButton" class="popup-container--footer-btns">
          <!-- To show for non-word-list items -->
          <!-- <button class="popup-container--footer-btns-0">
            <i class="fa-solid fa-plus"></i>
            Add to Word List
          </button> -->
          <!-- To show for word-list items -->
          <button @click="_remove" class="popup-container--footer-btns-0 popup-container--footer-btns-0-red">
            <i class="fa-solid fa-trash"></i>删除
          </button>
          <button @click="changeIsRevised(false)" class="popup-container--footer-btns-1">
            <i class="fa-solid fa-xmark"></i>我不会
          </button>
          <button @click="changeIsRevised(true)" class="popup-container--footer-btns-2">
            <i class="fa-solid fa-check"></i>我会了
          </button>
        </div>
      </div>
    </div>
    <audio :src="info.audio_src" ref="audio"></audio>
  </div>
</template>

<script>

// updated version of common/VocabTeachingComponent
// shows details of a word like sentence, collocation and image
// supposed to be used in multiple places
// requires parent component to implement a listener for close

import axios from 'axios';
import {mapState, mapMutations} from 'vuex';

export default {
  name: 'WordDetailPopup',
  components: {
  },
  props: {
    info: Object,
    includeIsRevisionButton: Boolean,
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapState(['studentId']),
    meanings() {
      // returns only the first three meanings
      return this.info['definition'].split(';').map((sentence) => {
        return sentence;
      }).slice(0, 2);
    },
  },
  mounted() {
    document.addEventListener('keydown', this.onKeydown);
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.onKeydown);
  },
  methods: {
    ...mapMutations('analytics', ['modifyIsRevised', 'removeFromWordlist']),
    // adds red class to text to emphasise word
    modifiyText(originalText) {
      const word = this.info.text;
      const entries = originalText.split(word);
      const separator = `<span class="word-highlight">${word}</span>`;
      const newText = entries.join(separator);
      return newText;
    },
    // closes popup when user presses escape
    onKeydown(event) {
      if (event.key === 'Escape') {
        this._close();
      }
    },
    _close() {
      this.$emit('close');
    },
    playAudio() {
      if (this.info.audio_src === '') return;
      this.$refs.audio.play();
    },
    // affects wordlist is_revised field (not sure if this will change in future)
    changeIsRevised(value) {
      console.log('changing');
      this.modifyIsRevised({wordId: this.info.word_id, value: value});
      const url = `/api/v1/core/student/${this.studentId}/wordlist/is_revised/`;
      axios
          .post(url, {wordId: this.info.word_id, value: value})
          .then((response) => {
            this.setWordlist(response.data);
            this.fetching = false;
          })
          .catch((err) => {
            console.log(err);
            this.fetching = false;
          });
      this.$emit('close');
    },
    // removes word from wordlist
    _remove() {
      axios
          .post(`/api/v1/core/student/${this.studentId}/wordlist/`, {
            wordId: this.info.word_id,
            inWordlist: false,
            comprehensionId: null,
          });
      this.removeFromWordlist(this.info.word_id);
      this.$emit('close');
    },
  },
};
</script>

<style lang="scss" scoped>
  // v-html needs to use v-deep
  ::v-deep .word-highlight {
    color: #ff6b00 !important;
    border-bottom: 2px solid #bababa;
  }
  .popup {
    background: #000000e0;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
    &-container {
      flex-direction: column;
      height: 90%;
      width: 90%;
      background: #fff;
      border-radius: .5rem;
      display: flex;
      // position: relative;
      // &--btn-close {
      //   position: absolute;
      //   top: .5rem;
      //   right: 1rem;
      //   color: #8a8a8a;
      //   font-size: 2rem;
      // }
      &--header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: .5rem 1rem;
        background: #efefef;
        border-top-left-radius: .25rem;
        border-top-right-radius: .25rem;
        &-label {
          font-weight: 800;
          font-size: .9rem;
          .divider {
            font-weight: 500;
            margin: 0 .5rem;
          }
        }
        &-btn-close {
          color: #8a8a8a;
          font-size: 1.5rem;
          line-height: 1rem;
          cursor: pointer;
        }
      }
      &--body {
        display: flex;
        flex: 1;
        margin: 1.5rem 5rem;
        gap: .5rem;
      }
      &-1 {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        width: 25%;
        border-right: 2px solid #efefef;
        .fa-volume-up {
          color: #929292;
        }
        &-word {
          font-weight: 800;
          margin-bottom: 1.5rem;
          display: flex;
          flex-direction: column;
          align-items: center;
          &:hover {
            .sound-icon {
              color: #2772fb;
            }
          }
          &--chinese {
            font-size: 3.5rem;
            line-height: 4rem;
          }
          &--hypy {
            position: relative;
            .sound-icon {
              position: absolute;
              right: -2rem;
            }
          }
        }
        &-definition {
          width: 90%;
          flex: 1;
          &-label{
            font-size: 1.2rem;
            font-weight: 400;
            width: 100%;
            border-top: 2px solid #efefef;
            padding: .5rem 0;
          }
          &-body {
            display: flex;
            margin-left: 1rem;
            ul {
              li {
                display: flex;
              }
            }
            &--index {
              margin-right: .5rem;
              border-radius: 50%;
              width: 25px;
              height: 25px;
              display: flex;
              justify-content: center;
              align-items: center;
              background: #049aff;
              color: #fff;
              margin-top: .1rem;
              font-size: .9rem;
            }
            &--text {}
          }
        }
        &-picture {
          width: 90%;
          &-label{
            font-size: 1.2rem;
            font-weight: 400;
            width: 100%;
            border-top: 2px solid #efefef;
            padding: .5rem 0;
          }
          &-container {
            max-height: 150px;
            display: flex;
            justify-content: center;
            align-items: center;
            margin-left: 1rem;
            overflow: hidden;
            border-radius: .25rem;
            img {
              height: 100%;
              width: 100%;
            }
          }
        }
      }
      &-2 {
        flex: 1;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        border-right: 2px solid #efefef;
        padding: 0 1rem 0 .5rem;
        &-sentences {
          display: flex;
          margin-left: 1rem;
          flex-direction: column;
            li {
              display: flex;
              padding: .5rem;
            }
          &-label{
            font-size: 1.2rem;
            font-weight: 400;
            width: 100%;
            padding: .5rem 0;
          }
          &-index {
            margin-right: .5rem;
            border-radius: 50%;
            width: 25px;
            height: 25px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #049aff;
            color: #fff;
            margin-top: .1rem;
            font-size: .9rem;
          }
          &-sentence {
            flex: 1;
            font-size: 1.15rem;
          }
        }
      }
      &-3 {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        width: 20%;
        &--collocations {
          margin-left: 1rem;
          &-label{
            font-size: 1.2rem;
            font-weight: 400;
            width: 100%;
            padding: .5rem 0;
          }
          li {
            display: flex;
            padding: .5rem;
            .collocations-index {
              margin-right: .5rem;
              border-radius: 50%;
              width: 25px;
              height: 25px;
              display: flex;
              justify-content: center;
              align-items: center;
              background: #049aff;
              color: #fff;
              margin-top: .1rem;
              font-size: .9rem;
            }
            .collocations-content {
              display: flex;
              flex: 1;
              flex-direction: column;
              .collocations-definition {
                font-size: .9rem;
                font-style: italic;
            }
            }
          }
        }
      }
      &--footer {
        text-align: center;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        &-btns {
          padding: 1.25rem;
          display: flex;
          justify-content: flex-end;
          gap: .75rem;
          border-top: 2px solid #efefef;
          margin: 0 3rem;
          button {
            padding: .5rem 0rem;
            width: 10rem;
            font-size: 1rem;
            border: none;
            color: #fff;
            font-weight: 700;
            border-radius: .25rem;
            cursor: pointer;
            i {
              margin-right: .5rem;
            }
          }
          &-1 {
            background:#ff557f;
          }
          &-2 {
            background:#00e395;
          }
          &-0 {
            margin-right: auto;
            background:#049aff;
            &-red {
              background: #ff557f;
            }
            i {
              margin-right: .25rem;
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 1480px) {
    .popup-container--body {
      margin: 1.5rem 2rem;
    }
    .popup-container--footer-btns {
      margin: 0 1.5rem;
    }
  }
  @media only screen and (max-width: 1024px) {
    .popup-container--body {
      flex-direction: column;
      overflow: auto;
      margin: 0;
      padding: 1rem 1.5rem;
    }
    .popup-container-2 {
      overflow-y: unset;
      border-right: none;
      padding: 0;
    }
    .popup-container-1 {
      width: 100%;
      border-right: none;
      &-word {
        align-items: flex-start;
        &--hypy {
          margin-left: 1rem;
        }
      }
      &-word, &-definition, &-picture {
        width: 100%;
        padding-bottom: 1rem;
        border-bottom: 2px solid #efefef;
      }
      &-word {
        margin-bottom: 0;
      }
      &-definition-label, &-picture-label {
        border-top: none;
        padding: 0;
        margin: .5rem 0;
      }
      &-picture {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
      }
    }
    .popup-container-3 {
      width: 100%;
      &-collocations-label {
        padding-top: 0;
      }
    }
    .popup-container-2 {
      border-bottom: 2px solid #efefef;
      padding-bottom: 1rem;
      &-sentences-label {
        padding-top: 0;
      }
    }
  }
  @media only screen and (min-width: 1024px) and (max-height: 860px) {
    .popup-container--header {
      padding: .35rem 1rem;
      &-label {
        font-size: .8rem;
      }
    }
    .popup-container--body {
      margin: 1rem 1.5rem;
      .popup-container-1 {
        &-word {
          margin-bottom: 1rem;
          &--chinese {
            font-size: 3rem;
          }
          &--hypy {
            font-size: .9rem;
          }
        }
        &-definition-label, &-picture-label {
          padding: .35rem 0;
          font-size: 1.1rem;
        }
        &-definition-body {
          &--index {
            margin-top: .2rem;
            font-size: .8rem;
            height: 20px;
            width: 20px;
          }
          &--text {
            font-size: .9rem;
          }
        }
      }
      .popup-container-2 {
        &-sentences-label {
          padding: .35rem 0;
          font-size: 1.1rem;
        }
        &-sentences-index {
            margin-top: .2rem;
            font-size: .8rem;
            height: 20px;
            width: 20px;
        }
        &-sentences-sentence {
          font-size: 1rem;
        }
      }
      .popup-container-3 {
        &--collocations-label {
          padding: .35rem 0;
          font-size: 1.1rem;
        }
        &--collocations li .collocations-index {
            margin-top: .2rem;
            font-size: .8rem;
            height: 20px;
            width: 20px;
        }
        &--collocations li .collocations-content .collocations-definition {
          font-size: .8rem;
        }
      }
    }
    .popup-container--footer-btns {
      padding: .75rem;
      gap: .5rem;
      button {
        font-size: .8rem;
      }
    }
  }
  @media only screen and (max-height: 690px) {
    .popup-container--body {
      flex-direction: column;
      overflow: auto;
      margin: 0;
      padding: 1rem 1.5rem;
    }
    .popup-container-2 {
      overflow-y: unset;
      border-right: none;
      padding: 0;
    }
    .popup-container--body {
      .popup-container-1 {
        width: 100%;
        border-right: none;
        &-word {
          align-items: flex-start;
          &--hypy {
            margin-left: 1rem;
          }
        }
        &-word, &-definition, &-picture {
          width: 100%;
          padding-bottom: 1rem;
          border-bottom: 2px solid #efefef;
        }
        &-word {
          margin-bottom: 0;
        }
        &-definition-label, &-picture-label {
          border-top: none;
          padding: 0;
          margin: .5rem 0;
        }
        &-picture {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
        }
      }
      .popup-container-3 {
        width: 100%;
        &-collocations-label {
          padding-top: 0;
        }
      }
      .popup-container-2 {
        border-bottom: 2px solid #efefef;
        padding-bottom: 1rem;
        &-sentences-label {
          padding-top: 0;
        }
      }
    }
  }
  @media only screen and (max-width: 510px) {
  .popup-container--header {
    padding: .35rem 1rem;
    &-label {
      font-size: .8rem;
    }
  }
  .popup-container--body {
    .popup-container-1 {
      &-word {
        margin-bottom: 1rem;
        &--chinese {
          font-size: 3rem;
        }
        &--hypy {
          font-size: .9rem;
        }
      }
      &-definition-label, &-picture-label {
        padding: .35rem 0;
        font-size: 1.1rem;
      }
      &-definition-body {
        &--index {
          margin-top: .2rem;
          font-size: .8rem;
          height: 20px;
          width: 20px;
        }
        &--text {
          font-size: .9rem;
        }
      }
    }
    .popup-container-2 {
      &-sentences-label {
        padding: .35rem 0;
        font-size: 1.1rem;
      }
      &-sentences-index {
          margin-top: .2rem;
          font-size: .8rem;
          height: 20px;
          width: 20px;
      }
      &-sentences-sentence {
        font-size: 1rem;
      }
    }
    .popup-container-3 {
      &--collocations-label {
        padding: .35rem 0;
        font-size: 1.1rem;
      }
      &--collocations li .collocations-index {
          margin-top: .2rem;
          font-size: .8rem;
          height: 20px;
          width: 20px;
      }
      &--collocations li .collocations-content .collocations-definition {
        font-size: .8rem;
      }
    }
  }
  .popup-container--footer-btns {
    padding: .75rem;
    gap: .5rem;
    margin: 0;
    justify-content: center;
    button {
      font-size: .8rem;
      width: 50%;
      padding: .5rem 0;
    }
  }
}

</style>
