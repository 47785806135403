<template>
  <div
    class="sprite-pet_base_pose"
    :class="petClass">
  </div>
</template>

<script>
export default {
  name: 'PetContainer',
  components: {
  },
  mixins: [],
  props: {
    imageKey: String,
  },
  data() {
    return {
    };
  },
  computed: {
    petClass() {
      // converts pina koala_0 into pina_koala_0
      const img = this.imageKey.split(' ').join('_');
      return 'pet_base_pose_' + img;
    },
  },
  mounted() {
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
  .sprite-pet_base_pose {
    display:inline-block;
    overflow:hidden;
    background-repeat: no-repeat;
    background-image:url(../../assets/pet/pet_base_pose.png);
    background-size: cover;
  }

  .pet_base_pose_bbsaurus_0 {width:202px; height:191px; background-position: -0px -0px}
  .pet_base_pose_burnox_0 {width:202px; height:191px; background-position: -202px -0px}
  .pet_base_pose_flamebun_0 {width:202px; height:191px; background-position: -404px -0px}
  .pet_base_pose_mangsha_0 {width:202px; height:191px; background-position: -606px -0px}
  .pet_base_pose_rakoon_0 {width:202px; height:191px; background-position: -1010px -0px}
  .pet_base_pose_pina_koala_0 {width:202px; height:191px; background-position: -808px -0px}
  .pet_base_pose_seabub_0 {width:202px; height:191px; background-position: -1212px -0px}
  .pet_base_pose_shadowfox_0 {width:202px; height:191px; background-position: -1414px -0px}

  @media only screen and (max-width: 1023px) {
    .pet_base_pose_bbsaurus_0 {width:151.5px; height:143.25px; background-position: -0px -0px}
    .pet_base_pose_burnox_0 {width:151.5px; height:143.25px; background-position: -151.5px -0px}
    .pet_base_pose_flamebun_0 {width:151.5px; height:143.25px; background-position: -303px -0px}
    .pet_base_pose_mangsha_0 {width:151.5px; height:143.25px; background-position: -454.5px -0px}
    .pet_base_pose_rakoon_0 {width:151.5px; height:143.25px; background-position: -757.5px -0px}
    .pet_base_pose_pina_koala_0 {width:151.5px; height:143.25px; background-position: -606px -0px}
    .pet_base_pose_seabub_0 {width:151.5px; height:143.25px; background-position: -909px -0px}
    .pet_base_pose_shadowfox_0 {width:151.5px; height:143.25px; background-position: -1060.5px -0px}
  }
  @media only screen and (min-width: 665px) and (max-height: 715px) {
    .pet_base_pose_bbsaurus_0 {width:151.5px; height:143.25px; background-position: -0px -0px}
    .pet_base_pose_burnox_0 {width:151.5px; height:143.25px; background-position: -151.5px -0px}
    .pet_base_pose_flamebun_0 {width:151.5px; height:143.25px; background-position: -303px -0px}
    .pet_base_pose_mangsha_0 {width:151.5px; height:143.25px; background-position: -454.5px -0px}
    .pet_base_pose_rakoon_0 {width:151.5px; height:143.25px; background-position: -757.5px -0px}
    .pet_base_pose_pina_koala_0 {width:151.5px; height:143.25px; background-position: -606px -0px}
    .pet_base_pose_seabub_0 {width:151.5px; height:143.25px; background-position: -909px -0px}
    .pet_base_pose_shadowfox_0 {width:151.5px; height:143.25px; background-position: -1060.5px -0px}
  }
  @media only screen and (min-width: 665px) and (max-height: 630px) {
    .pet_base_pose_bbsaurus_0 {width:101px; height:95.5px;
      background-position: -0px -0px}
    .pet_base_pose_burnox_0 {width:101px; height:95.5px;
      background-position: -101px -0px}
    .pet_base_pose_flamebun_0 {width:101px; height:95.5px;
      background-position: -202px -0px}
    .pet_base_pose_mangsha_0 {width:101px; height:95.5px;
      background-position: -303px -0px}
    .pet_base_pose_rakoon_0 {width:101px; height:95.5px;
      background-position: -505px -0px}
    .pet_base_pose_pina_koala_0 {width:101px; height:95.5px;
      background-position: -404px -0px}
    .pet_base_pose_seabub_0 {width:101px; height:95.5px;
      background-position: -606px -0px}
    .pet_base_pose_shadowfox_0 {width:101px; height:95.5px;
      background-position: -707px -0px}
  }

</style>
