<template>
  <div class="scrollable-component">
    <div class="video-lesson__component" v-if="lesson">
      <!-- created an animated bar at bottom of screen reminding user that there are videos below -->
      <scroll-reminder></scroll-reminder>
      <!-- for videos -->
      <div class="video-lesson__video--container">
        <div class="video-lesson__video">
          <video-player
            v-if="lesson.videos"
            :code="videoCode"
            :play-callback="playCallback"></video-player>
        </div>
      </div>
      <!-- video info and links -->
      <div class="video-lesson__content--container row">
        <div class="video-lesson__info">
          <div class="video-lesson__info--text">
            <div class="video-lesson__info--text--subtitle">
              <!-- <p>
                {{ lesson.level | splitCapitalise}}
                <span>|</span>
                Term {{ currentTerm }}
                <span>|</span>
                Week {{ weekToDisplay }}
                <span>|</span>
                {{ lesson.date | formatDate }}
              </p> -->
            </div>
            <div class="video-lesson__info--text--title">
              <h2>
                <span class="chinese-character">
                  <span v-if="lesson.category_verbose">
                    {{lesson.category_verbose }} / </span>
                  <span v-else>
                    考试技巧 / </span>
                  <span>
                    {{ lesson.title }}
                  </span>
                </span>
                <!-- TODO: Add video counter to show which video currently? -->
                <span class="video-lesson__info--text--title--counter">
                  (Part {{ currentVideoIndex + 1 }}/{{ lesson.videos.length }})
                </span>
              </h2>
            </div>
            <div class="video-lesson__info--text--body">
              <h2 class="video-lesson__info--text--body--title">
                INSTRUCTIONS:
              </h2>
              <p class="video-lesson__info--text--body--text">
                <i class="fas fa-caret-right"></i>
                Watch the videos lesson and follow along the
                teacher for this week's exercise.
              </p>
              <p class="video-lesson__info--text--body--text">
                <i class="fas fa-caret-right"></i>
                Download and complete your in-class
                worksheet exercises along with the teacher.
              </p>
            </div>
          </div>
          <div class="video-lesson__info--download">
            <h4 class="video-lesson__info--download--title">
              <i class="fas fa-download"></i>
              Lesson Materials
            </h4>
            <div class="video-lesson__info--download--links">
              <a :href="lesson.inclass_link"
                target="_blank">
                <i class="fas fa-file-alt"></i>
                In-Class Worksheet
              </a>
            </div>
          </div>
        </div>
        <div class="video-lesson__nav">
          <a class="pointer" @click="back">
            <i class="fas fa-caret-left"></i>
            Return
          </a>
        </div>
        <div class="video-lesson__links">
          <h4 class="video-lesson__links--title">
            <i class="fas fa-video"></i>
            Video Lessons
            <a href="#" style="float:right;"
              @click="isChineseTitles = !isChineseTitles">
              <i class="fas fa-language"></i>
            </a>
          </h4>

          <ul class="video-lesson__links--content">
            <li class="video-lesson__links--link"
              :class="{'video-lesson__links--link--active':
              video.video_code === currentVideoCode}"
              v-for="video in lesson.videos" :key="video.index">
              <a href='#' @click="currentVideoIndex = video.index">
                <div class="video-lesson__links--link--index">
                  <p>{{ video.index + 1}}</p>
                </div>
                <div class="video-lesson__links--link--text">
                  <p v-if="isChineseTitles"
                    class="video-lesson__links--link--text--chn
                  chinese-character">
                    {{ video.title }}
                  </p>
                  <p v-else
                    class="video-lesson__links--link--text--eng">
                    {{ video.english_title }}
                  </p>
                </div>
              </a>
              <i class="fas fa-star video-lesson__links--link--unread"
                :class="{'video-lesson__links--link--read':
                (video.id in watchedCompoLessonVideos)}"></i>
            </li>
          </ul>
        </div>
      </div>
      <popup v-if="showTrialPopup"
        @close-popup="showTrialPopup = !showTrialPopup">
      </popup>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import {mapState, mapMutations} from 'vuex';

import VideoPlayerComponent from '../../common/VideoPlayerComponent.vue';
import ScrollReminderComponent from '../../common/ScrollReminderComponent.vue';

import PopupComponent
  from '../../videolesson/PopupComponent.vue';

export default {
  name: 'NewCompositionLesson',
  components: {
    VideoPlayer: VideoPlayerComponent,
    Popup: PopupComponent,
    ScrollReminder: ScrollReminderComponent,
  },
  props: {
    week: Number,
  },
  data() {
    return {
      lesson: null,
      currentVideoIndex: 0,
      // placeholder for applying :class to video links when video is watched
      isChineseTitles: true,
      video: null,
      showTrialPopup: false,
      lessonType: '',
    };
  },
  computed: {
    ...mapState(['studentType', 'watchedCompoLessonVideos']),
    // weekToDisplay() {
    //   const modulusWeek = (this.lesson.week + 1) % 12;
    //   return modulusWeek !== 0 ? modulusWeek : 12;
    // },
    // currentTerm() {
    //   if (!this.lesson) return;
    //   return Math.floor(this.lesson.week / 12) + 1;
    // },
    // sprout video version
    videoCode() {
      if (!this.lesson || this.lesson.videos.length === 0) return;

      return [this.lesson.videos[this.currentVideoIndex].sprout_video_id,
        this.lesson.videos[this.currentVideoIndex].sprout_security_token];
    },
  },
  watch: {
  },
  mounted: function() {
    this.pullData();
  },
  methods: {
    ...mapMutations(['addToWatchedCompoLessonVideos']),
    back() {
      this.$router.push({name: 'new-composition-lesson-index'});
    },
    pullData(week) {
      let url;
      if (typeof week ==='undefined') {
        week = this.week;
      }

      // if (this.$route.path.indexOf('compo') > -1) {
      //   this.lessonType = 'compo';
      // } else {
      //   this.lessonType = 'compreh';
      // }

      // if no week is passed
      if (typeof week === 'undefined' || week === -1) {
        url = '/vocab/extramaterial/';
      } else {
        url = '/vocab/extramaterial/' + week + '/';
      };
      console.log(url);
      axios
          .get(url)
          .then((response) => {
            this.lesson = response.data;
            console.log(response.data);

            if (this.lesson && this.lesson.videos &&
              this.lesson.videos.length > 0) {
              // do nothing for now
            } else {
              // if there are no videos, redirect to profile-main
              console.log('no videos');
              this.$router.push({name: 'profile-main'});
            };
          });
    },
    // checkIfFinishWatching() {
    //   const videoId = this.lesson.videos[this.currentVideoIndex].id;
    //   // if video is already watched, then don't
    //   // need to send a message to backend
    //   if (videoId in this.watchedPrimarySchoolLessonVideos) return;

    //   if (this.video.percentWatched() > 0.6) {
    //     console.log('finished watching');

    //     // marks the video as watched and updates
    //  both backend and stored data
    //     axios
    //         .post('/api/v1/core/lessonvideorecord/watched/', {
    //           primary_school_lesson_video_id: videoId,
    //         })
    //         .then((response) => {
    //           this.addToWatchedPrimarySchoolLessonVideos(videoId);
    //         });
    //   };
    // },
  },
};
</script>

<style lang="scss" scoped>
.pointer {
  cursor: pointer;
}

.scrollable-component {
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
}
  .video-lesson {
    &__parental-checklist {
      position: fixed;
      background: rgba(0, 0, 0, 0.9);
      top: 0;
      left: 0;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      z-index: 30;
      height: 100%;
      width: 100%;
      &--header {
        text-align: center;
        color: #fff;
        margin-bottom: 30px;
      }
      &--popup {
        position: relative;
        margin-bottom: 250px;
      }
      &--close {
        position: absolute;
        right: -10px;
        top: -10px;
        cursor: pointer;
        color: #fff;
        font-size: 1.4rem;
      }
      &--item {
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        h3 {
          margin-right: 20px;
        }
        &--ratings {
          color: rgba(255,255,255,0.2);
          font-size: 1.4rem;
          cursor: pointer;

          &--selected {
            color: #ffc800;
          }

        }
      }
    }
    &__component {
      position: relative;
      background: #fff;
      padding-bottom: 5px;
      margin-top: 50px;
    }
    &__nav {
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 50px;
      background: #5a4179;
      display: flex;
      align-items: center;
      a {
        color: #fff;
        margin-left: .5rem;
      }
    }
    &__video {
      max-height: 585px;
      max-width: 1024px;
      margin: auto;
      text-align: center;
      &--container {
        background: #000;
      }
    }
    &__content {
      &--container {
        margin: 30px auto 30px auto;
      }
    }
    &__info {
      display: flex;
      padding-bottom: .4rem;
      border-bottom: 1px solid #eee;
      &--text {
        flex: 1;
        margin-right: 5rem;
        &--title {
          margin-bottom: .5rem;
          color: #222;
          padding: 0.4rem;
          border-bottom: 1px solid #eee;
          h2 {
            font-size: 1.75rem;
            margin-bottom: .2rem;
          }
          &--counter {
            font-size: 1.25rem;
          }
        }
        &--subtitle {
          text-align: right;
          font-size: 0.8rem;
          font-weight: bold;
          padding-bottom: .4rem;
          border-bottom: 1px solid #eee;
        }
        &--body {
          font-size: .85rem;
          margin-left: 1rem;
          &--title {
            font-size: .85rem;
          }
          &--text {
            margin: 5px;
            margin-left: 10px;
            i {
              margin-right: 5px;
            }
          }
        }
      }
      &--download {
        &--title {
          padding-bottom: 5px;
          border-bottom: 1px solid #eee;
          text-align: center;
          i {
            margin-right: 5px;
          }
        }
        &--links {
          display: flex;
          flex-direction: column;
          a {
            cursor: pointer;
            margin: 7.5px 0 2.5px 0;
            width: 100%;
            background: #ececec;
            border: 1px solid #cacaca;
            padding: 10px 30px;
            color: #777;
            margin-right: auto;
            text-decoration: none;
            font-weight: bolder;
            transition: all 0.2s;
            i {
              margin-right: 5px;
            }
            &:hover {
              color: #fff;
              background-color: #bd279b;
              border: 1px solid #bd279b;
            }
          }
        }
      }
    }
    &__links {
      &--title {
        margin: 10px 0;
        background: #5a4179;
        color: #fff;
        padding: 10px;
        border-radius: 3px;
        i {
          margin-right: 5px;
        }
      }
      &--content {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 10px;
      }
      &--link {
        border-radius: 6px;
        position: relative;
        a {
          cursor: pointer;
          overflow: hidden;
          border-radius: 6px;
          color: #000;
          display: block;
          padding: 15px 0;
          border: 2px solid #eee;
          min-width: 250px;
          text-decoration: none;
          display: grid;
          grid-template-columns: 1fr 3fr;
          &:hover {
            background: #2ed09d;
            .video-lesson__links--link--index,
            .video-lesson__links--link--text {
              color: #fff;
            }
          }
        }
        &--unread {
          position: absolute;
          bottom: 8px;
          right: 8px;
          color: #eee;
        }
        &--read {
          color: #ffbd43;
        }
        &--index {
          font-size: 1.5rem;
          border-right: 2px solid #eee;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        &--text {
          margin-left: 20px;
          &--chn {
            font-size: 1.15rem;
            margin-bottom: 3px;
          }
          &--eng {
            font-size: 0.8rem;
          }
        }
        &--active {
          a {
            border: 2px solid #4ee8b7;
            .video-lesson__links--link--index {
              border-right: 2px solid #aa69f3;
            }
          }
          &:hover {
            a {
              color: #222;
              &:hover {
                background: #aa69f3;
              }
            }
            .video-lesson__links--link--index {
              border-right: 2px solid #fff;
            }
          }
        }
      }
    }
  }

  @media only screen and (max-width: 1140px) {
    .video-lesson {
      &__nav--btns {
        margin: 0 20px;
      }
      &__info {
        margin: 30px 20px 20px 20px;
      }
      &__content {
        &--container {
          margin: 0;
        }
      }
      &__links {
        &--title {
          border-radius: 0;
        }
        &--content {
          grid-template-columns: 1fr 1fr;
          margin: 0 20px 20px 20px;
        }
      }
    }
  }
  @media only screen and (max-width: 1115px) {
    .video-lesson {
      &__links {
        margin-bottom: 64px;
      }
      &__info {
        &--text {
          margin-right: 2rem;
        }
      }
      &__nav--btns {
        margin-bottom: 64px;
      }
    }
  }
  @media only screen and (max-width: 920px) {
    .video-lesson {
      &__links {
        &--content {
          grid-template-columns: 1fr;
        }
      }
    }
  }
  @media only screen and (max-width: 860px) {
    .video-lesson {
      &__info {
        display: block;
        margin: 0;
        border-bottom: none;
        &--text {
          margin: 20px;
          border-bottom: 1px solid #eee;
          &--body {
            margin-bottom: .5rem;
          }
        }
        &--download {
          &--title {
            text-align: left;
            margin: 10px 0;
            background: #5a4179;
            color: #fff;
            padding: 10px;
            border-radius: 0;
          }
          &--links {
          }
        }
      }
    }
  }
  @media only screen and (max-width: 640px) {
    .video-lesson {
      &__links {
        &--content {
          grid-template-columns: 1fr;
        }
      }
    }
  }
  @media only screen and (max-width: 630px) {
    .sproutvideo-player {
      width: 100vw;
      height: calc(100vw * 0.75);
    }
  }
  @media only screen and (max-width: 530px) {
    .video-lesson {
      &__nav--btns {
        grid-template-columns: 1fr;
        a {
          margin: 0;
        }
      }
      &__info {
        &--download--links {
          font-size: 80%;
        }
        &--text {
          &--subtitle {
            font-size: 70%;
          }
          &--body {
            font-size: 80%;
          }
          &--title{
            font-size: 80%;
            &--counter {
              font-size: 1rem;
            }
            h2 {
              font-size: 1.5rem;
            }
          }
        }
      }
    }
    .video-lesson__links--link {
      &--index {
        font-size: 1.25rem
      }
      &--text {
        &--chn {
          font-size: 1rem;
        }
        &--eng {
          font-size: 0.7rem;
        }
      }
    }
  }
  @media only screen and (max-width: 400px) {
    .video-lesson__info--text {
      &--subtitle {
        font-size: 60%;
      }
      &--body {
        font-size: 70%;
      }
      &--title{
        font-size: 70%;
        &--counter {
          font-size: .8rem;
        }
        h2 {
          font-size: 1.25rem;
        }
      }
    }
  }
  @media only screen and (max-width: 330px) {
    .video-lesson__info--text {
      margin: 10px;
    }
  }
</style>
