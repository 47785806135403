<template>
  <div class="comprehension_component--container">
    <a href="#" class="close"
      @click.prevent="endComprehension"></a>
    <div class="comprehension_content--1">
      <h2 class="chinese-character">阅读理解</h2>
      <div class="comprehension_content--1-passage">
        <p v-for="(tokens, paraIndex) in entityData" :key="paraIndex"
          class="paragraph">
          <entity
            v-for="(info, index) in entityData[paraIndex]"
            :key="index"
            :info="info"></entity>
        </p>
      </div>
    </div>
    <div class="comprehension_content--2">
      <div class="comprehension_content--2-btns">
        <div class="comprehension_content--2-btn-1 chinese-character"
          :class="{ 'btn-active': !selectedEntity && !showDictionaryTooltip }"
          @click="clear">
          问题
        </div>
        <div class="comprehension_content--2-btn-2 chinese-character"
          :class="{ 'btn-active': selectedEntity ||
          showDictionaryTooltip }"
          @click="showDictionaryTooltip = true">
          词典
        </div>
      </div>
      <div class="comprehension_content--2-container">
        <div v-if="showDictionaryTooltip && !selectedEntity"
          class="comprehension_content--2-dictionary-tooltip">
          <p class="comprehension_content--2-dictionary-tooltip-1 chinese-character">
            词典
          </p>
          <i class="fas fa-book-open"></i>
          <p class="comprehension_content--2-dictionary-tooltip-2">
            Select any word in the passage to see its meaning.
          </p>
          <p class="comprehension_content--2-dictionary-tooltip-3">
            For core words, click on bookmark icon to save to your wordlist.
          </p>
        </div>
        <div v-if="selectedEntity"
          class="comprehension_content--2-dictionary">
          <teaching-screen
            v-if="selectedEntity"
            :word="selectedEntity.text"
            :pk="selectedEntity.pk || -1"
            :definition="selectedEntity.definition"
            :example-sentence="selectedEntity.sentence || ''"
            :src="selectedEntity.src || ''"
            :pinyin="selectedEntity.pinyin"
            :chapter="selectedEntity.chapter || null"
            :story="selectedEntity.story || null"
            :subtopic="selectedEntity.subtopic || null"
            :is-trackable="selectedEntity.is_trackable"
          ></teaching-screen>
         <!--  <div>
            <button>I know this</button>
            <button>I am still learning this</button>
            <button>I don't know</button>
          </div> -->
        </div>
        <div v-if="!selectedEntity && !showDictionaryTooltip"
          class="comprehension_content--2-questions">
          <div class="comprehension_content--2-question-container"
            v-for="(qn, index) in questions" :key="index">
            <div class="comprehension_content--2-question-label">
              {{ index + 1 }}
            </div>
            <div class="comprehension_content--2-question">
              <p class="chinese-character
                comprehension_content--2-question-text">
                {{ qn.text }}
              </p>
              <div
                class="chinese-character comprehension_content--2-question-text-2">
                <div class="comprehension_content--2-question-blank">
                  <div v-show="showAnswers"
                    class="chinese-character
                    comprehension_content--2-question-answer">
                    {{ qn.answer }}
                  </div>
                </div>
                <div
                  v-if="qn.marks && qn.marks >= 0"
                  class="comprehension_content--2-question-marks">
                  ({{ qn.marks }}分)
                </div>
              </div>
            </div>
          </div>
        </div>
        <button v-if="!selectedEntity && !showDictionaryTooltip"
          class="comprehension_content--2-btn-answer"
          @click="toggleAnswers">
          <span v-if="!showAnswers">参考答案</span>
          <span v-if="showAnswers">隐藏答案</span>
        </button>
        <div v-if="showParentalVerificationForm"
          class="comprehension_content--parental-verification">
          <div class="comprehension_content--parental-verification--modal">
            <h4>Please enter your parent's email for verification:</h4>
            <form action="">
              <input class="input-textbox" v-model="emailToCheck" type="text" placeholder="Parent's Email...">
              <p class="input-error-msg"
                v-if="showErrorMessage">
                Incorrect email, please try again.
              </p>
              <input class="input-submit" @click.prevent="verify" value="Verify">
            </form>
            <button class="comprehension_content--parental-verification--modal-btn-close"
              @click.prevent="closeParentVerificationForm">
              <i class="fa-solid fa-xmark"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapState, mapMutations} from 'vuex';
import axios from 'axios';

import EntityComponent
  from '../../common/guidedreading/EntityComponent.vue';

import TeachingScreenComponent
  from '../../common/guidedreading/TeachingScreenComponent.vue';


export default {
  name: 'NewComprehensionPassage',
  components: {
    Entity: EntityComponent,
    TeachingScreen: TeachingScreenComponent,
  },
  props: {
    level: String,
    comprehensionId: Number,
  },
  watch: {
  },
  data() {
    return {
      showAnswers: false,
      validated: false,
      questions: [],
      showDictionaryTooltip: true,
      showParentalVerificationForm: false,
      emailToCheck: '',
      showErrorMessage: false,
    };
  },
  computed: {
    ...mapState(['parentEmail']),
    ...mapState('guidedReading', ['entityData', 'selectedEntity']),
  },
  mounted() {
    this.pullData(this.comprehensionId);
  },
  methods: {
    ...mapMutations('guidedReading', ['setEntityData', 'setSelectedEntity', 'setComprehensionId']),
    pullData(comprehensionId) {
      const url = `/vocab/comprehension/${this.comprehensionId}/`;

      axios
          .get(url)
          .then((response) => {
            console.log(response.data);
            this.setComprehensionId(response.data['id']);
            this.setEntityData(response.data['hydrated_text']);
            this.questions = response.data['questions'];
            console.log(this.questions);
          });
    },
    clear() {
      this.setSelectedEntity(null);
      this.showDictionaryTooltip = false;
    },
    verify() {
      const emailToCheck = this.emailToCheck.toLowerCase().trim();
      if (emailToCheck !== this.parentEmail.toLowerCase().trim()) {
        this.showErrorMessage = true;
        return;
      }
      this.showErrorMessage = false;
      this.showAnswers = true;
      this.showParentalVerificationForm = false;
      this.validated = true;
    },
    closeParentVerificationForm() {
      this.showErrorMessage = false;
      this.showAnswers = false;
      this.showParentalVerificationForm = false;
      this.validated = false;
    },
    toggleAnswers() {
      // we only need parents to validate once
      if (!this.showAnswers && !this.validated) {
        this.showParentalVerificationForm = true;
        return;
      }
      this.showAnswers = !this.showAnswers;
    },
    endComprehension() {
      this.$router.push({name: 'new-comprehension-passage-index'});
    },
  },
  destroyed() {
    console.log('destroying');
    this.clear();
  },
};
</script>

<style lang="scss" scoped>
  .close {
    position: absolute;
    left: 32px;
    top: 32px;
    width: 32px;
    height: 32px;
    opacity: 0.3;
  }
  .close:hover {
    opacity: 1;
  }
  .close:before, .close:after {
    position: absolute;
    left: 15px;
    content: '';
    height: 33px;
    width: 2px;
    background-color: #333;
  }
  .close:before {
    transform: rotate(45deg);
  }
  .close:after {
    transform: rotate(-45deg);
  }
  .comprehension {
    &_component--container {
      z-index: 100;
      position: fixed;
      height: 100vh;
      width: 100vw;
      left: 0;
      top: 0;
      background: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &_content {
      &--1 {
        flex: 1;
        margin-left: 5rem;
        height: 80%;
        h2 {
          margin-bottom: 1.5rem;
        }
        &-passage {
          overflow-y: scroll;
          height: calc(100% - 66px);
          padding-right: 2rem;
          &::-webkit-scrollbar {
            width: 0.75rem;
          }
          &::-webkit-scrollbar-track {
            background: #ebebeb;
          }
          &::-webkit-scrollbar-thumb {
            background: linear-gradient(45deg, orange, darksalmon);
          }
        }
      }
      &--2 {
        flex: 1;
        height: 80%;
        margin-right: 5rem;
        &-btns {
          display: flex;
          height: 66px;
          justify-content: center;
          align-items: center;
          font-weight: 600;
          color: #fff;
          margin-left: 2rem;
          margin-top: -1rem;
          margin-bottom: 1rem;
        }
        &-btn {
          &-answer {
            margin-top: 1.25rem;
            width: 100%;
            padding: 0.5rem;
            border-radius: 0.5rem;
            cursor: pointer;
            border: none;
            font-size: 1rem;
            background: #ececec;
            &:hover {
              background: #0fd;
            }
          }
          &-1 {
            margin-right: 1rem;
            padding: 0.5rem 1rem;
            background: #e5e5e5;
            cursor: pointer;
            border-radius: 0.5rem;
            transition: 0.1s linear all;
            font-size: 1.3em;
          }
          &-2 {
            margin-left: 01rem;
            padding: 0.5rem 1rem;
            background: #e5e5e5;
            cursor: pointer;
            border-radius: 0.5rem;
            transition: 0.1s linear all;
            font-size: 1.3em;
          }
        }
        &-container {
          margin-left: 2rem;
          height: calc(100% - 66px);
        }
        &-dictionary {
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          &-tooltip {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            height: 100%;
            border: 2px solid #ccc;
            border-radius: 1rem;
            i {
              font-size: 10rem;
              color: #70e9b8;
              padding: 1.5rem;
              border-top: 3px solid #f7f4f4;
              border-bottom: 3px solid #f7f4f4;
              margin-top: .5rem;
              margin-bottom: 1.5rem;
            }
            &-1 {
              font-size: 2rem;
              font-weight: 800;
            }
            &-2 {
              font-size: 0.9rem;
              font-weight: 800;
              margin-bottom: 0.25rem;
            }
            &-3 {
              font-size: 0.8rem;
              font-style: italic;
              margin-bottom: 2rem;
            }
          }
        }
        &-questions {
          height: 90%;
          font-size: 1.1rem;
          overflow-y: scroll;
          padding-right: 2rem;
          &::-webkit-scrollbar {
            width: 0.75rem;
          }
          &::-webkit-scrollbar-track {
            background: #ebebeb;
          }
          &::-webkit-scrollbar-thumb {
            background: linear-gradient(45deg, orange, darksalmon);
          }
        }
        &-question {
          width: 88%;
          &-answer {
            text-align: left;
            display: block;
            color: #007eff;
          }
          &-label {
            font-family: 'Baloo Da 2', Avenir, Helvetica, Arial, sans-serif;
            background: #ececec;
            border-radius: 0.5rem;
            padding: 0.2rem 0.5rem;
            font-weight: 600;
            margin-right: 0.25rem;
            width: 12%;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          &-container {
            display: flex;
            padding: 1rem 0;
            border-top: 2px dashed #cecece;
            &:last-of-type {
              border-bottom: 2px dashed #cecece;
            }
          }
          &-text {
            padding: 0.2rem;
            margin-bottom: 0.5rem;
            &-2 {
              display: flex;
            }
          }
          &-blank {
            border-bottom: 1px solid #000;
            padding: 0.2rem;
            padding-bottom: 0.5rem;
            width: 88%;
          }
          &-marks {
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
            width: 12%;
          }
        }
      }
    }
  }

  .paragraph {
    padding-bottom: 30px;
    font-size: 19pt;
    letter-spacing: 0.1em;
    line-height: 150%;
    text-indent: 3rem;
  }

  .explanation-container {
    position: fixed;
    top: 75px;
    right: 15%;
    z-index: 1;
    width: 40%;
    padding-bottom: 0 !important;

    .title {
      padding-top: 1rem !important;
      padding-bottom: 1rem !important;
      text-align: left !important;
      margin-bottom: 0 !important;
      color: black;
    }

    .explanation-inner-container {
      background-color: #fff;
      border-radius: 3rem;
      padding: 1rem !important;
    }

  }

  .corner {
    border-radius: 3rem;
  }
  .btn-active {
    background: #009eff;
    color: #fff;
  }
  .btn-answers-active {
    background: #0fd;
  }
  .comprehension_content--parental-verification {
    position: fixed;
    height: 100vh;
    width: 100vw;
    background: #000000ed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    &--modal {
      padding: 2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: .5rem;
      background: #fff;
      border-radius: .25rem;
      margin-bottom: 6rem;
      position: relative;
      &-btn-close {
        position: absolute;
        top: .5rem;
        right: .5rem;
        color: #a3a3a3;
        background: none;
        border: none;
        cursor: pointer;
        font-size: 1.5rem;
      }
      h4 {
        margin-bottom: .75rem;
      }
      form {
        display: flex;
        flex-direction: column;
        width: 90%;
        gap: 1rem;
        position: relative;
        .input-error-msg {
          position: absolute;
          top: -.5rem;
          right: 0;
          color: #f00;
          font-weight: 600;
          font-size: .7rem;
        }
        .input-textbox {
          background: #e8e8e8;
          color: #000;
          box-shadow: 0 4px 7px 0 rgb(0 0 0 / 19%);
          border: 0;
          outline: 0;
          margin: .5rem 0;
          padding: .5rem 1rem;

        }
        .input-submit {
          text-align: center;
          border: none;
          padding: .5rem;
          background: #23aaff;
          border-radius: .25rem;
          color: #fff;
          font-weight: 600;
          border-bottom: 5px solid #0a86d4;
          cursor: pointer;
        }
      }
    }
  }
  @media only screen and (max-width: 1480px) {
    .comprehension_content--1 h2 {
      font-size: 1.4rem;
    }
    .paragraph {
      font-size: 1.1rem;
      text-indent: 2.5rem;
      padding-bottom: 15px;
    }
    .comprehension_content--2-questions {
      font-size: 1rem;
    }
    .comprehension_content--2-btn-answer {
      font-size: 0.95rem;
    }
    .comprehension_content--2-btn-1 {
      font-size: 1.2rem;
      padding: 0.4rem 0.85rem;
    }
    .comprehension_content--2-btn-2 {
      font-size: 1.2rem;
      padding: 0.4rem 0.85rem;
    }
  }
  @media only screen and (max-width: 1380px) {
    .comprehension_content--1-passage {
      padding-right: 1rem;
    }
    .comprehension_content--1 {
      margin-left: 4rem;
    }
    .comprehension_content--2 {
      margin-right: 4rem;
    }
    .comprehension_content--2-questions {
      padding-right: 1rem;
    }
    .comprehension_content--2-container {
      margin-left: 1rem;
    }
  }
  @media only screen and (max-width: 1150px) {
    .comprehension_content--2-question-blank {
      width: 83%;
    }
    .comprehension_content--2-question-marks {
      width: 17%;
    }
    .comprehension_content--2-questions {
      font-size: 0.95rem;
    }
    .comprehension_content--2-btn-answer {
      font-size: 0.9rem;
    }
    .comprehension_content--2-btn-1 {
      font-size: 1.1rem;
      padding: 0.35rem 0.75rem;
    }
    .comprehension_content--2-btn-2 {
      font-size: 1.1rem;
      padding: 0.35rem 0.75rem;
    }
    .comprehension_content--1 {
      margin-left: 3rem;
    }
    .comprehension_content--2 {
      margin-right: 3rem;
    }
  }
  @media only screen and (max-width: 900px) {
    .comprehension_content--2-question-blank {
      width: 80%;
    }
    .comprehension_content--2-question-marks {
      width: 20%;
    }
  }
</style>
