<template>
  <div
    v-if="show"
    class="loading-screen">
    <div class="loading-screen-content">
      <img src="https://s3.ap-southeast-1.amazonaws.com/smartschool-static/static/frontend/img/loading_pic.png">
      <span class="shadow"></span>
    </div>
  </div>
</template>

<script>

export default {
  name: 'LoadingScreen',
  components: {
  },
  props: {
    propShow: Boolean,
    minWaitTime: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      timeOfLoad: null,
      // private variable that toggles between showing and hiding component
      show: false,
      timeoutId: -1,
    };
  },
  watch: {
    propShow: {
      immediate: true,
      handler: function(newVal, oldVal) {
        // if propShow is changed to true, then show loading screen
        if (newVal) {
          this.show = true;
          this.timeOfLoad = Date.now();
          // this is necessary to handle situations where there are two loading screens in succession
          clearTimeout(this.timeoutId);
        } else {
          // when propShow is changed to false, we need to see if
          // enough time has elapsed
          // if the amount of time that has elapsed (loadin time)
          // is greater than min weight time, we show immediately
          // otherwise we wait for the difference in time before showing
          const timeElapsed = Date.now() - this.timeOfLoad;
          this.timeoutId = setTimeout(() => {
            this.show = false;
          }, Math.max(this.minWaitTime - timeElapsed, 0));
        }
      },
    },
  },
  mounted() {
  },
};
</script>

<style lang="scss" scoped>
  .loading-screen {
    z-index: 9999;
    position: fixed;
    height: 100vh;
    width: 100vw;
    background: #fff;
    left: 0;
    top: 0;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .loading-screen-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .loading-screen-content img {
    width: 65%;
    animation:jump .8s ease-in infinite;
    transform-origin: bottom;
  }
  .shadow {
    z-index:-1;
    margin-top: -20px;
    display:block;
    border-radius:100%;
    height:20px;
    width:130px;
    background:#000;
    opacity:.3;
    animation:scale-shadow .8s ease-in infinite;
  }

  @media only screen and (max-height: 420px) {
    .loading-screen-content {
      margin-top: 50px;
    }
  }
  @media only screen and (max-height: 320px) {
    .loading-screen-content {
      margin-top: 40px;
    }
    .loading-screen-content img {
      width: 55%;
    }
  }

  @keyframes jump{
    0%{
        transform:translateY(0) scale(1.15,.8);
    }
    20%{
        transform:translateY(-35px) scaleY(1.1);
    }
    50%{
        transform:translateY(-50px) scale(1);
    }
    80%{
        transform:translateY(-35px) scale(1);
    }
    to{
        transform:translateY(0) scale(1.15,.8);
    }
  }

  @keyframes scale-shadow {
    0%{
        opacity:.3;
        transform:scale(1)
    }
    50%{
        opacity:.2;
        transform:scale(.5)
    }
    to{
        opacity:.3;
        transform:scale(1)
    }
  }
</style>
