<template>
  <div>
    <ul class="keyword-component">
      <li v-for="(word) in wordsToRecognise"
        :key="word.pk"
        class="keyword-container play-sound tap-btn-highlight"
        @click="playSound(word.text)">
        <p class="keyword">
          <span class="hypy">{{ word.text }}</span>
        </p>
        <span class="sound-icon fas fa-volume-up"></span>
      </li>
    </ul>
  </div>
</template>

<script>
import {soundMixin} from '../../mixins/sound';

export default {
  name: 'PinyinList',
  components: {
  },
  mixins: [soundMixin],
  props: {
    wordsToRecognise: Array,
    manifest: Array,
  },
  data() {
    return {
      sounds: {},
    };
  },
  computed: {
  },
  watch: {
    // preloads sounds whenever we have new words
    wordsToRecognise: {
      immediate: true,
      handler() {
        if (!this.manifest || this.manifest.length == 0) {
          return;
        };
        this.preload(this.manifest, (data) => {
          this.sounds = data;
        });
      },
    },
  },
  mounted: function() {
  },
  methods: {
    playSound(word) {
      const key = word + '_字';
      if (key in this.sounds) {
        this.sounds[key].play();
      };
    },
  },
};
</script>

<style lang="scss" scoped>
  .keyword-component {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 20px;
    margin-bottom: 40px;
  }
  .keyword-container {
    position: relative;
    border: 4px solid #eee;
    border-radius: 6px;
    cursor: pointer;
    min-width: 100px;
    min-height: 100px;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    .sound-icon {
      position: absolute;
      top: 5px;
      right: 5px;
    }
    &:hover {
      border: 4px solid #6e9fec;
      .sound-icon {
        color: #aa69f3;
      }
    }
  }

  .keyword {
    font-size: 2rem;
  }

  @media only screen and (max-width: 490px){
    .keyword-component {
      grid-gap: 15px;
      margin-bottom: 35px;
    }
    .keyword-container {
      min-width: 90px;
      min-height: 90px;
    }
  }

  @media only screen and (max-width: 450px){
    .keyword-component {
      grid-gap: 15px;
      margin-bottom: 30px;
    }
    .keyword-container {
      min-width: 80px;
      min-height: 80px;
    }
    .sound-icon {
      font-size: 16px;
      top: 3px;
      right: 3px;
    }
  }
  @media only screen and (max-width: 390px){
    .sound-icon {
      font-size: 12px;
      top: 2px;
      right: 2px;
    }
    .keyword-component {
      grid-gap: 10px;
      margin-bottom: 25px;
    }
    .keyword-container {
      min-width: 70px;
      min-height: 70px;
    }
  }

  @media only screen and (max-width: 666px) and (max-height: 450px) {
    .keyword-container {
      min-width: auto;
    }
  }
</style>
