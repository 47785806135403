<template>
  <div v-if="pet" class="pet-info_component">
    <!-- left component -->
    <router-link
      class="pet-info_component--back"
      :to="{name: 'pet-overview'}">
      <i class="fas fa-angle-left"></i>
    </router-link>
    <div class="pet-info_splash">
      <img :src="getSplashImg(pet.image_key)">
    </div>
    <!-- middle component -->
    <div class="pet-info_overview">
      <div class="pet-info_overview--header">
        <div class="pet-info_overview--header-portrait">
          <div class="sprite-portrait"
            :class="'portrait_' + pet.image_key"></div>
        </div>
        <div class="pet-info_overview--header-text">
          <h1 class="pet-info_overview--header-text-name">
            {{ pet.name }}
          </h1>
          <rating class="rating-star-container"
            image="star"
            :current-score="pet.star"
            :max-score=5>
          </rating>
        </div>
        <div class="pet-info_overview--header-element">
          ELEM
        </div>
      </div>
      <div class="pet-info_overview--level">
        <div class="pet-info_overview--level-text">
          <span class="pet-info_overview--level-text-1">
            Lv.
          </span>
          <span class="pet-info_overview--level-text-2">
            {{ pet.level }}
          </span>
        </div>
        <div class="pet-info_overview--level-progress-container">
          <div class="pet-info_overview--level-progress-bar">
          </div>
          <div class="pet-info_overview--level-progress-label">
            Experience Points to Next Level
          </div>
        </div>
      </div>
      <div class="pet-info_overview--base-pose">
        <div class="sprite-pet_base_pose idle-animation"
          :class="petClass"></div>
      </div>
      <p class="pet-info_overview--desc">
        {{ pet.description }}
      </p>
    </div>

    <!-- right component -->
    <div class="pet-info_attributes">
      <div class="pet-info_attributes--border-top">
        <!-- <button class="btn-small">
          English
        </button>
        <button disabled class="btn-small">
          中文
        </button> -->
      </div>
      <div class="pet-info_attributes--contents">
        <!-- abilities container -->
        <div class="pet-info_abilities">
          <h2 class="pet-info_abilities--header">
            Pet's Abilities
          </h2>
          <div class="pet-info_abilities--container">
            <div class="pet-info_abilities--basic">
              <div class="sprite-icon icon-spell-basic"></div>
              <p class="pet-info_abilities--basic-label">
                Basic
              </p>
            </div>
            <div class="pet-info_abilities--ult">
              <div class="sprite-icon icon-spell-ult"></div>
              <p class="pet-info_abilities--ult-label">
                Ultimate
              </p>
            </div>
            <div class="pet-info_abilities--passive">
              <div class="sprite-icon icon-spell-passive"></div>
              <p class="pet-info_abilities--passive-label">
                Passive
              </p>
            </div>
          </div>
        </div>
        <!-- stats container -->
        <div class="pet-info_stats">
          <div class="pet-info_stats--atk">
            <div class="sprite-icon icon-stats-atk"></div>
            <div class="pet-info_stats--atk-rating">
              <p class="pet-info_stats--atk-rating-label">Attack</p>
              <rating class="rating-star-container"
                image="star"
                :current-score="pet.info.attributes.attack"
                :max-score=5>
              </rating>
            </div>
          </div>
          <div class="pet-info_stats--def">
            <div class="sprite-icon icon-stats-def"></div>
            <div class="pet-info_stats--def-rating">
              <p class="pet-info_stats--atk-rating-label">Defence</p>
              <rating class="rating-star-container"
                image="star"
                :current-score="pet.info.attributes.defence"
                :max-score=5>
              </rating>
            </div>
          </div>
          <div class="pet-info_stats--health">
            <div class="sprite-icon icon-stats-health"></div>
            <div class="pet-info_stats--health-rating">
              <p class="pet-info_stats--atk-rating-label">Health</p>
              <rating class="rating-star-container"
                image="star"
                :current-score="pet.info.attributes.health"
                :max-score=5>
              </rating>
            </div>
          </div>
        </div>
      </div>
      <div class="pet-info_attributes--border-btm">
        <button class="btn-large"
          @click="selectAsActivePet">
          Select
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import {mapGetters, mapMutations} from 'vuex';

import RatingComponent from '../common/RatingComponent.vue';

export default {
  name: 'PetInfo',
  components: {
    Rating: RatingComponent,
  },
  props: {
    petId: Number,
  },
  mixins: [],
  data() {
    return {
      pet: null,
    };
  },
  computed: {
    // ...mapState(['pets', 'gameValues']),
    ...mapGetters('pet', ['getPetById']),
    petClass() {
      if (!this.pet) return;
      // converts pina koala_0 into pina_koala_0
      const img = this.pet.image_key.split(' ').join('_');
      return 'pet_base_pose_' + img;
    },
  },
  mounted() {
    this.pet = this.getPetById(this.petId);
  },
  methods: {
    ...mapMutations('pet', ['setActivePet']),
    selectAsActivePet() {
      axios
          .post('/petgame/player/change_active_pet/', {
            activePetId: this.petId,
          })
          .then((response) => {
            this.setActivePet(this.pet);
            this.$router.push({name: 'pet-overview'});
          })
          .catch((error) => {
            alert('Error setting active pet');
          });
    },
    getSplashImg(img) {
      return require('../../assets/pet/splash/' + img + '.jpg');
    },
  },
};
</script>

<style lang="scss" scoped>

  .sprite-portrait {
    display: inline-block;
    overflow: hidden;
    background-repeat: no-repeat;
    background-image: url('../../assets/pet/portraits.png');
    background-size: cover;
  }

  .portrait_bbsaurus_0 {width:120px; height:124px; background-position: -0px -0px}
  .portrait_burnox_0 {width:120px; height:124px; background-position: -120px -0px}
  .portrait_flamebun_0 {width:120px; height:124px; background-position: -240px -0px}
  .portrait_mangsha_0 {width:120px; height:124px; background-position: -360px -0px}
  .portrait_rakoon_0 {width:120px; height:124px; background-position: -600px -0px}
  .portrait_pina_koala_0 {width:120px; height:124px; background-position: -480px -0px}
  .portrait_seabub_0 {width:120px; height:124px; background-position: -720px -0px}
  .portrait_shadowfox_0 {width:120px; height:124px; background-position: -840px -0px}

  .sprite-pet_base_pose {
    display:inline-block;
    overflow:hidden;
    background-repeat: no-repeat;
    background-image:url(../../assets/pet/pet_base_pose.png);
    background-size: cover;
  }

  .pet_base_pose_bbsaurus_0 {width: 404px; height: 382px; background-position: -0px -0px}
  .pet_base_pose_burnox_0 {width: 404px; height: 382px; background-position: -404px -0px}
  .pet_base_pose_flamebun_0 {width: 404px; height: 382px; background-position: -808px -0px}
  .pet_base_pose_mangsha_0 {width: 404px; height: 382px; background-position: -1212px -0px}
  .pet_base_pose_rakoon_0 {width: 404px; height: 382px; background-position: -2020px -0px}
  .pet_base_pose_pina_koala_0 {width: 404px; height: 382px; background-position: -1616px -0px}
  .pet_base_pose_seabub_0 {width: 404px; height: 382px; background-position: -2424px -0px}
  .pet_base_pose_shadowfox_0 {width: 404px; height: 382px; background-position: -2828px -0px}

  .sprite-icon {display:inline-block; overflow:hidden; background-repeat: no-repeat;background-image:url(../../assets/pet/icons.png);}

  .icon-spell-basic {width:86px; height:87px; background-position: -0px -0px}
  .icon-spell-passive {width:86px; height:87px; background-position: -0px -87px}
  .icon-spell-ult {width:86px; height:87px; background-position: -0px -174px}
  .icon-stats-atk {width:72px; height:71px; background-position: -0px -261px}
  .icon-stats-def {width:72px; height:71px; background-position: -0px -332px}
  .icon-stats-health {width:72px; height:71px; background-position: -0px -403px}

  * {
    font-family: 'Baloo Da 2', Avenir, Helvetica, Arial, sans-serif;
  }
  .pet-info {
    &_component {
      display: flex;
      height: 100vh;
      position: fixed;
      top: 0;
      left: 0;
      background: #fff;
      &--back {
        position: absolute;
        font-size: 1.75rem;
        color: #000;
        z-index: 99;
        background: #ffffff9e;
        padding: 0.15rem 1.5rem;
        line-height: 1;
        border-bottom-right-radius: 20px;
        i {
          font-family: "Font Awesome 5 Free";
        }
      }
    }
    &_splash {
      position: relative;
      img {
        height: 100%;
        object-fit: cover;
        display: block;
      }
    }
    &_splash:after {
      content: "";
      position: absolute;
      z-index: 1;
      top: 0;
      right: 0;
      pointer-events: none;
      background-image: linear-gradient(to right,
                        rgba(255,255,255,0),
                        #fff 90%);
      width: 30%;
      height: 100%;
    }
    &_overview {
      flex: 1;
      margin-left: 0.5rem;
      margin-right: 1rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      &--header {
        margin-top: 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        &-text {
          margin-left: 0.75rem;
          flex: 1;
          &-name {
            text-transform: uppercase;
            line-height: 1;
          }
        }
        &-element {
          width: 80px;
          height: 80px;
          background: #36ee9b;
          border-radius: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: 800;
          border: 4px solid #555;
        }
      }
      &--level{
        display: flex;
        &-text {
          line-height: 1;
          padding: 0.25rem 1.25rem  0.25rem 0.5rem;
          background: #67ffdf;
          clip-path: polygon(0 0, 100% 0%, 80% 100%, 0% 100%);
          border-radius: 6px;
          border-top-right-radius: 0;
          &-1{
            font-weight: 500;
            font-size: 1.5rem;
          }
          &-2{
            font-weight: 600;
            font-size: 2rem;
          }
        }
        &-progress {
          &-container{
            flex: 1;
            margin: 0 0 0 -1rem;
          }
          &-bar{
            width: 100%;
            height: 30px;
            background: #e6e6e6;
            border-radius: 6px;
          }
          &-label{
            font-size: 0.6rem;
            font-weight: 800;
            margin-left: 0.5rem;
          }
        }
      }
      &--base-pose{
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow:hidden;
        background-repeat: no-repeat;
        background-image:url(../../assets/pet/bg_grid.jpg);
        background-size: cover;
      }
      &--desc {
        margin: 1rem 2rem 3rem 1rem;
        text-align: center;
        font-weight: 600;
      }
    }
    &_attributes {
      flex: 1;
      display: flex;
      flex-direction: column;
      &--border {
        &-top {
          height: 10vh;
          background: #e6e6e6;
          display: flex;
          justify-content: center;
          align-items: center;
          clip-path: polygon(0 0, 100% 0, 100% 100%, 5% 100%);
        }
        &-btm {
          height: 20vh;
          background: #e6e6e6;
          display: flex;
          justify-content: center;
          align-items: center;
          clip-path: polygon(5% 0, 100% 0, 100% 100%, 0 100%);
        }
      }
      &--contents {
        flex: 1;
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
      }
    }
    &_abilities {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      &--header {
        padding: 0.25rem 4rem;
        line-height: 1;
        background: #e6e6e6;
        clip-path: polygon(5% 0%, 100% 0%, 95% 100%, 0% 100%);
      }
      &--container {
        display: flex;
        padding: 2rem 2rem 1.25rem 2rem;
        margin-top: -1rem;
        background: #f6f6f6;
        border-radius: 10px;
      }
      &--basic, &--ult, &--passive {
        text-align: center;
        margin: 0 0.5rem;
        &-label {
          line-height: 1;
          font-weight: 700;
          font-size: 0.75rem;
          font-style: italic;
          margin-top: -0.25rem;
        }
      }
      &--ult {
        margin-right: 1rem;
      }
      &--passive {
        margin-left: 1rem;
      }
    }
    &_stats {
      margin-top: 2rem;
      &--atk, &--def, &--health {
        display: flex;
        &-rating {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          margin-left: 0.5rem;
          &-label {
            font-weight: 700;
            font-size: .75rem;
            line-height: 1;
            text-transform: uppercase;
          }
        }
      }
    }
  }
  .btn {
    &-large {
      width: 200px;
      padding: 0.5rem 1rem;
      border: 4px solid #000;
      border-radius: 10px;
      line-height: 1;
      font-size: 1.25rem;
      text-transform: uppercase;
      font-weight: 800;
      background: #fff;
      cursor: pointer;
    }
    &-small {
      width: 150px;
      padding: 0.5rem;
      text-transform: uppercase;
      font-weight: 800;
      background: #fff;
      cursor: pointer;
      border: 2px solid #000;
      border-radius: 10px;
      line-height: 1;
      &:not(:last-child) {
        margin-right: 1rem;
      }
    }
  }
  .idle-animation {
    transform-origin: bottom;
    animation: 3s infinite cubic-bezier(0.4, 0, 0.2, 1) idleAnim;
  }
  @keyframes idleAnim {
    0% {
      transform: scaleY(0.965);
    }
    50% {
      transform: scaleY(1.025);
    }
    100% {
      transform: scaleY(0.965);
    }
  }

</style>

<style lang="scss">
.pet-info {
  &_overview {
    &--header {
      &-text {
        .rating-star-container {
          .rating-container {
            text-align: left;
            i {
              font-size: 1.5rem;
              margin-right: 0.25rem;
            }
          }
        }
      }
    }
  }
  &_stats {
    .rating-star-container {
      .rating-container {
        text-align: left;
        i {
          font-size: 1.5rem;
          margin-right: 0.25rem;
        }
      }
    }
  }
}
</style>

