<template>
  <block-container
    title="Password"
    fa-icon="lock"
    previous-page-name="new-settings-overview"
    >
    <form method="post" class="change-password__component--form">
      <span
        v-if="errorMessage !== ''"
        class="error-message">
        {{ errorMessage }}
      </span>
      <div class="change-password__component--form-row">
        <h4 class="change-password__component--form-title">Current Password</h4>
        <input
          autocomplete="off"
          v-model="input.oldPassword"
          class="change-password__component--form-input"
          type="password"
          placeholder="Old Password..."/>
      </div>
      <div class="change-password__component--form-row">
        <h4 class="change-password__content--form-title">New Password</h4>
        <input
          autocomplete="off"
          v-model="input.newPassword"
          class="change-password__component--form-input"
          type="password"
          placeholder="New Password..."/>
      </div>
      <div class="change-password__component--form-row">
        <h4 class="change-password__content--form-title">
          Confirm New Password
        </h4>
        <input
          autocomplete="off"
          v-model="input.newDuplicatePassword"
          class="change-password__component--form-input"
          placeholder="Type New Password again..."
          type="password"/>
      </div>
      <button
        class="btn-submit"
        type="submit"
        @click.prevent="changePassword">
        Submit
      </button>
    </form>
  </block-container>
</template>

<script>

import {mapState} from 'vuex';
import axios from 'axios';

import BlockContainerComponent
  from '../common/BlockContainerComponent.vue';

export default {
  name: 'NewSettingsPassword',
  components: {
    BlockContainer: BlockContainerComponent,
  },
  data() {
    return {
      input: {
        oldPassword: '',
        newPassword: '',
        newDuplicatePassword: '',
      },
      errorMessage: '',
    };
  },
  computed: {
    ...mapState(['studentName']),
  },
  mounted() {
  },
  methods: {
    changePassword() {
      this.errorMessage = '';

      if (this.input.oldPassword != ''
        && this.input.newPassword != ''
        && this.input.newDuplicatePassword != '') {
        if (this.input.newPassword !== this.input.newDuplicatePassword) {
          this.errorMessage = 'New passwords must match';
          return;
        };
        axios
            .put('/api/v1/core/change_password/', {
              old_password: this.input.oldPassword,
              new_password: this.input.newPassword,
            })
            .then((response) => {
              alert('Successfully changed password.');
              this.$router.push({name: 'profile-main'});
            })
            .catch((error) => {
              this.errorMessage = 'Error changing password';
            });
      } else {
        this.errorMessage = 'Please fill in all the fields.';
      };
    },
  },
};
</script>


<style lang="scss" scoped>
  .change-password__component--form {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    flex-direction: column;
    position: relative;
    background: #fff;
    border-bottom-left-radius: .4rem;
    border-bottom-right-radius: .4rem;
    &-row {
      width: 60%;
    }
    &-title{
      text-align: left;
      margin-bottom: 6px;
    }
    &-input{
      background-color: #eee;
      padding: 15px;
      width: 100%;
      display: block;
      border-radius: 10px;
      outline: none;
      border: 2px solid #ddd;
      margin-bottom: 12px;
      &:focus {
        border: 2px solid #ac2fb1;
      }
    }
  }

  .error-message {
    color: red;
    position: absolute;
    top: 0;
    right: 0;
  }

  .btn-submit {
    margin-top: 2.5rem;
    position: relative;
    font-size: 15px;
    font-weight: 600;
    text-decoration: none;
    color: #fff;
    text-transform: uppercase;
    display: block;
    border: 2px solid #1c6cd9;
    width: 200px;
    height: 50px;
    border-radius: .5rem;
    background: #5881fc;
    padding: 0 25px;
    margin-bottom: 2rem;
    cursor: pointer;
    transition: all 0.2s;
    &:focus {
      outline: none;
    }
    &:hover {
      transform: scale(1.04);
      background-color: #58bdfc;
      border: 2px solid #3a9edd;
    }
    &:active {
      transform: scale(1.02);
      background: #5881fc;
      border: 2px solid #1c6cd9;
    }
  }

  @media only screen and (max-width: 1366px),
            screen and (max-height: 870px)  {
              .change-password__component--form-row {
                width: 70%;
              }
            }
</style>
