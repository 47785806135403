<template>
  <div class="pet-container__component">
    <div class="pet-container__card">
      <pet-image
        :image-key="pet.image_key">
      </pet-image>
      <div class="pet-container__desc">
        <div class="pet-container__desc--line"></div>
        <p class="pet-container__desc--type">{{ pet.name }} </p>
        <p class="pet-container__desc--lvl">(Level {{ pet.level }})</p>
        <rating class="rating-star-container"
          image="star"
          :current-score="pet.star"
          :max-score=5>
        </rating>
      </div>
    </div>
  </div>
</template>
<script>


import PetImageComponent
  from './PetImageComponent.vue';

import RatingComponent from '../common/RatingComponent.vue';

export default {
  name: 'PetContainer',
  components: {
    PetImage: PetImageComponent,
    Rating: RatingComponent,
  },
  mixins: [],
  props: {
    pet: Object,
  },
  data() {
    return {
      changeNamePopup: false,
      input: {
        name: '',
      },
      errorMessage: '',
    };
  },
  computed: {
  },
  mounted() {
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
  .pet-container {
    &__card {
      position: relative;
      height: 100%;
      background: linear-gradient(#44cbff, #ffffff);
      border-radius: 5px;
      border: 5px solid #c8fcff;
      text-align: center;
      cursor: pointer;
      padding: 10px 0;
    }
    &__new {
      position: absolute;
      top: -10px;
      right: -10px;
      font-size: 15px;
      background-color: #4cefb3;
      color: white;
      padding: 5px 10px;
      border-radius: 5px;
      box-shadow: 0 5px 5px rgba(0,0,0,0.2);
      text-transform: uppercase;
      font-weight: 700;
    }

    &__name {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 20px 0 0 0;
      font-size: 16.5px;
      font-weight: bold;
    }
    &__desc {
      font-weight: bold;
      &--line {
        border-top: 2px solid #555;
        margin: 0 10%;
      }
      &--lvl {
        font-size: 80%;
        margin-bottom: -0.25rem;
        margin-top: 0.25rem;
      }
      &--type {
        margin-bottom: -0.5rem;
      }
    }
  }
  .change-name__popup {
    position: absolute;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    background: rgba(0,0,0,0.9);
    z-index: 20;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #fff;
    button {
      padding: 10px 20px;
      margin-top: 10px;
    }
  }
  @media only screen and (max-width: 1024px) {
    .pet-container {
      &__desc {
        font-size: 90%;
      }
    }
  }
  @media only screen and (max-width: 820px) {
    .pet-container {
      &__desc {
        font-size: 80%;
      }
    }
  }
  @media only screen and (max-width: 420px) {
    .pet-container__card {
      padding: 5px 0;
    }
  }
  @media only screen and (max-width: 340px) {
    .pet-container {
      &__desc {
        font-size: 70%;
      }
    }
  }
  @media only screen and (max-height: 715px) {
    .pet-container {
      &__desc {
        font-size: 90%;
      }
    }
  }

</style>
