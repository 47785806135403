<template>
  <div class="arrow-container">
    <router-link
      class="arrow-link"
      v-if="linkToStory !== -1"
      :to="{name: 'library-story',
      params: {storyId: linkToStory}}">
      <i class="fas arrow"
        :class="arrowClass"></i>
    </router-link>
    <div class="arrow-link disabled" v-else>
      <i class="fas arrow"
        :class="arrowClass"></i>
    </div>
  </div>
</template>

<script>
// import {mapState} from 'vuex';

export default {
  name: 'LibraryStoryArrow',
  components: {
  },
  props: {
    // if true, then show back arrow
    // otherwise show forward arrow
    previousArrow: Boolean,
    linkToStory: Number,
  },
  data() {
    return {
    };
  },
  computed: {
    // ...mapState('library', ['currentStoryarc']),
    arrowClass() {
      return {
        'back-arrow fa-caret-left': this.previousArrow,
        'forward-arrow fa-caret-right': !this.previousArrow,
      };
    },
  },
  mounted: function() {
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>

  .arrow-container {
    margin-left: 20px;
    width: 10rem;
    a {
      background: #ececec;
      border: 1px solid #cacaca;
      color: #777;
      padding: 7px 10px;
    }
  }
  .arrow-link,
  .arrow-link:link,
  .arrow-link:visited {
    width: auto;
    display: block;
    text-decoration: none;
    transition: all 0.2s;
    i {
      width: 100%;
      font-size: 1.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .arrow-link:hover,
  .arrow-link:active {
      color: #fff;
      background-color: #bd279b;
      border: 1px solid #bd279b;
  }
  .arrow {
    width: 40px;
  }

  .back-arrow::after {
    content: "Previous Episode";
    font-size: 1rem;
    margin-left: 10px;
    font-family: 'Lato', 'Arial', sans-serif;
    font-weight: normal;
    margin-bottom: 2px;
  }

  .forward-arrow {
    display: block;
    &::before {
      content: "Next Episode";
      font-size: 1rem;
      font-family: 'Lato', 'Arial', sans-serif;
      font-weight: normal;
      margin-bottom: 2px;
    }
    &::after {
      content: "\f0da";
      padding-left: 2px;
      display: inline-block;
      margin-left: 10px;

    }
  }

  .disabled {
    cursor: not-allowed;
    opacity: 0.2;
    padding: 7px 10px;
    background: #ececec;
    border: 1px solid #cacaca;
    color: #666;
    pointer-events: none;
    transition: all 0.2s;
  }

  @media only screen and (max-width: 1023px) {
    .arrow-container {
      margin-left: 0;
      &:first-child {
        margin-bottom: 6px;
      }
    }
  }
  @media only screen and (max-width: 800px) {
    .arrow-container {
      flex: 1;
      &:first-child {
      }
      &:last-child {
        margin-left: 20px;
      }
    }
  }
  @media only screen and (max-width: 480px) {
    .back-arrow::after,
    .forward-arrow::before {
      font-size: .8rem;
    }
    .forward-arrow::after,
    .back-arrow::before {
      font-size: 1.2rem;
    }
  }
  @media only screen and (max-width: 400px) {
    .arrow-container {
      width: 100%;
      &:first-child {
        margin-bottom: 6px;
      }
      &:last-child {
        margin-left: 0;
      }
    }
  }
  @media only screen and (max-width: 850px) and (max-height: 450px) {
    .arrow-container {
      width: 100%;
    }
    .arrow-link,
    .arrow-link:link,
    .arrow-link:visited {
      i {
        font-size: 1rem;
      }
    }
    .back-arrow::after,
    .forward-arrow::before {
      font-size: .8rem;
      margin-left: 6px;
    }

  }

</style>
