<template>
  <div class="user-level-progressbar">
    <div class="user-level-progressbar--text">
      <p
        :class="{'user-level-progressbar--text-num--small': knownWordsExceedingFourDigits}"
        class="user-level-progressbar--text-num">
        {{ numKnownWords }}
      </p>
      <p class="user-level-progressbar--text-label">Known Words</p>
    </div>
    <div class="user-level-progressbar--bg">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1" width="333px"
        height="333px">
        <defs>
          <linearGradient id="ProgressBarBG">
            <stop offset="0%" stop-color="#0000004e" />
            <stop offset="100%" stop-color="#0000004e" />
          </linearGradient>
        </defs>
        <circle cx="167" cy="167" r="150" stroke-linecap="round" />
      </svg>
    </div>
    <div class="user-level-progressbar--bar">
      <svg class="user-level-progressbar--bar"
        xmlns="http://www.w3.org/2000/svg"
        version="1.1" width="333px"
        height="333px">
        <defs>
          <linearGradient id="ProgressBarColor">
            <stop offset="0%" stop-color="#FFB128" />
            <stop offset="100%" stop-color="#FFB128" />
          </linearGradient>
        </defs>
        <circle cx="167" cy="167" r="150" stroke-linecap="round" />
      </svg>
    </div>
  </div>
</template>

<script>


export default {
  name: 'UserLevelProgressbar',
  components: {
  },
  props: {
    numKnownWords: Number,
  },
  data() {
    return {
    };
  },
  computed: {
    // when known words are 4 digits; change to 999 later
    knownWordsExceedingFourDigits() {
      return this.numKnownWords > 999;
      //
    },
  },
  mounted() {
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>

.user-level-progressbar {
  height: 333px;
  width: 333px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  &--text {
    background: linear-gradient(#29E3FF, #6472FB);
    height: 75%;
    width: 75%;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #fff;
    border: 5px solid #5162CF;
    text-shadow: 0px 3px rgba(0, 0, 0, 0.4784313725);
    &-num {
      font-size: 6.5rem;
      font-weight: 600;
      margin-top: -1.5rem;
      margin-bottom: -0.75rem;
      &--small {
        font-size: 5rem;
        margin-bottom: 0;
        margin-top: -1rem;
      }
    }
    &-label {
      font-size: 1.3rem;
      margin-top: -2.5rem;
    }
  }
  &--bg {
    circle {
      fill: none;
      stroke: url(#ProgressBarBG);
      stroke-width: 25px;
    }
    svg {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
  &--bar {
    circle {
      fill: none;
      stroke: url(#ProgressBarColor);
      stroke-width: 25px;
      // stroke-dasharray: 320;
      stroke-dashoffset: 00;
    }
    svg {
      position: absolute;
      top: 0;
      left: 0;
    }
  }
}

@media only screen and (max-width: 1560px), screen and (max-height: 800px) {
  .user-level-progressbar {
    height: 300px;
    width: 300px;
    &--text {
      &-num {
        font-size: 5.5rem;
        margin-top: -1.75rem;
        margin-bottom: -.5rem;
        &--small {
          font-size: 4.5rem;
          margin-bottom: 0;
          margin-top: -1rem;
        }
      }
      &-label {
        font-size: 1.2rem;
      }
    }
    &--bg {
      svg {
        width: 300px;
        height: 300px;
      }
      circle {
        stroke-width: 23.75px;
        transform: scale(.9, .9);
      }
    }
    &--bar {
      svg {
        width: 300px;
        height: 300px;
      }
      circle {
        stroke-width: 23.75px;
        transform: scale(.9, .9);
      }
    }
  }
}
@media only screen and (max-width: 1366px), screen and (max-height: 690px) {

  .user-level-progressbar {
    height: 266px;
    width: 266px;
    &--text {
      &-num {
        font-size: 5rem;
        margin-top: -1.5rem;
        margin-bottom: -.25rem;
        &--small {
          font-size: 4rem;
          margin-bottom: .25rem;
          margin-top: -1rem;
        }
      }
      &-label {
        font-size: 1.1rem;
      }
    }
    &--bg {
      svg {
        width: 266px;
        height: 266px;
      }
      circle {
        stroke-width: 22.5px;
        transform: scale(.8, .8);
      }
    }
    &--bar {
      svg {
        width: 266px;
        height: 266px;
      }
      circle {
        stroke-width: 22.5px;
        transform: scale(.8, .8);
      }
    }
  }
}

@media only screen and (max-width: 1080px) {
  .user-level-progressbar {
    height: 233px;
    width: 233px;
    &--text {
      &-num {
        font-size: 4.5rem;
        margin-top: -1.25rem;
        margin-bottom: 0rem;
        &--small {
          font-size: 3.5rem;
          margin-bottom: .5rem;
          margin-top: -.5rem;
        }
      }
      &-label {
        font-size: 1.1rem;
      }
    }
    &--bg {
      svg {
        width: 233px;
        height: 233px;
      }
      circle {
        stroke-width: 22.5px;
        transform: scale(.7, .7);
      }
    }
    &--bar {
      svg {
        width: 233px;
        height: 233px;
      }
      circle {
        stroke-width: 22.5px;
        transform: scale(.7, .7);
      }
    }
  }

}
</style>
