<!-- layout for ./learningtracks/modules -->
<template>
  <div class="term-index__component row">
    <div class="term-index__header">
      <router-link
        class="term-index__header--back"
        :to="{name: 'new-learn-overview'}">
        <i class="fas fa-angle-left"></i>
      </router-link>
      <!-- shows which learning track user is on -->
      <h2>Han Yu Pin Yin</h2>
    </div>
    <learning-track-subtopic-container
      v-for="learningTrack in learningTracks"
      :key="learningTrack.pk"
      :learning-track="learningTrack"
      class="term-index__content--revision">
    </learning-track-subtopic-container>
  </div>
</template>

<script>

// mapState
import {mapGetters} from 'vuex';
// import _filter from 'lodash.filter';

import LearningTrackSubtopicContainerComponent from
  '../LearningTrackSubtopicContainerComponent.vue';

export default {
  name: 'LearningTrackPinyinIndex',
  components: {
    LearningTrackSubtopicContainer: LearningTrackSubtopicContainerComponent,
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapGetters('learningTrack', ['getLearningTracksByType']),
    // filtered by the learningTypeTrack
    learningTracks() {
      return this.getLearningTracksByType('pinyin');
    },
  },
  mounted: function() {
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
  .term-index {
    &__component {
      text-align: center;
      margin: 20px auto 0 auto;
    }
    &__header {
      background: #5a4179;
      color: #fff;
      padding: 10px;
      border-radius: 3px;
      margin-bottom: 25px;
      position: relative;
      &--back {
        position: absolute;
        left: 10px;
        font-size: 1.5em;
        color: #fff;
        margin-left: 5px;
      }
    }
  }
  @media only screen and (max-width: 1140px) {
    .term-index {
      &__component {
        margin: 10px auto 0 auto;
      }
      &__header {
        font-size: 16px;
        margin-bottom: 15px;
      }
    }
  }
</style>
