<!-- Layout for ./learningtrack/ebook/dashboard module -->
<template>
  <div class="learning-track-ebook-dashboard__component row">
    <div class="learning-track-ebook-dashboard__header">
      <router-link
        class="learning-track-ebook-dashboard__header--back"
        :to="{name: 'new-learning-track-overview'}">
        <i class="fas fa-angle-left"></i>
      </router-link>
      <h2>Write a new E-Book</h2>
    </div>
    <div class="learning-track-ebook-dashboard__content">
      <div class="learning-track-ebook-dashboard__new-book">
        <i class="learning-track-ebook-dashboard__new-book--icon
          fas fa-pencil-alt"></i>
        <form class="learning-track-ebook-dashboard__new-book--form">
          <h3>What is the title of your book?</h3>
          <div class="learning-track-ebook-dashboard__new-book--title">
            <span
              class="learning-track-ebook-dashboard__new-book--title-quotes">
            </span>
            <input
              v-model="title"
              class="learning-track-ebook-dashboard__new-book--title-input"
              placeholder="New Title..." type="text">
            <span
              class="learning-track-ebook-dashboard__new-book--title-quotes">
            </span>
          </div>
          <button class="learning-track-ebook-dashboard__new-book--btn"
            @click.prevent="submit">
            Create
          </button>
        </form>
      </div>
      <div class="learning-track-ebook-dashboard__header u-margin-top">
        <h2>Books I've Written</h2>
      </div>
      <div class="learning-track-ebook-dashboard__existing-books">
        <div
          v-for="ebook in ebooks"
          :key="ebook.pk"
          class="learning-track-ebook-dashboard__existing-book">
          <div class="learning-track-ebook-dashboard__existing-book--icon">
            <i class="fas fa-book-open"></i>
            <div class="learning-track-ebook-dashboard__existing-book--title">
              {{ ebook.title }}
            </div>
          </div>
          <a
            target="_blank"
            class="learning-track-ebook-dashboard__existing-book--btn"
            :href="externalLink(ebook.pk)">
            View
          </a>
          <router-link
            class="learning-track-ebook-dashboard__existing-book--btn"
            :to="{name: 'learning-track-ebook',
            params: {ebookPk: ebook.pk,
              pageIndex: 0}}">
            Edit
          </router-link>
          <!-- TODO: Button to DELETE e-book -->
<!--           <button
            class="learning-track-ebook-dashboard__existing-book--btn
              btn-delete">
            Delete
          </button>
 -->        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
// import {mapState, mapGetters} from 'vuex';
import {mapState, mapMutations} from 'vuex';


export default {
  name: 'LearningTrackEbookDashboard',
  components: {
  },
  data() {
    return {
      title: '',
    };
  },
  computed: {
    ...mapState('learningTrack', ['ebooks']),
  },
  mounted: function() {
    if (this.ebooks.length > 0) return;

    axios
        .get('/blog/api/ebook/student/')
        .then((response) => {
          for (let i = 0; i < response.data.length; i++) {
            this.addToEbooks(response.data[i]);
          };
        });
  },
  methods: {
    ...mapMutations('learningTrack', ['addToEbooks']),
    submit() {
      if (this.title === '') return;

      axios
          .post('/blog/api/ebook/', {
            title: this.title,
          })
          .then((response) => {
            this.addToEbooks(response.data);
            this.$router.push({name: 'learning-track-ebook', params: {
              ebookPk: response.data.pk,
              pageIndex: 0,
            }});
          });
    },
    externalLink(pk) {
      return '/blog/ebooks/' + pk + '/';
    },
  },
};
</script>

<style lang="scss" scoped>
  .u-margin-top {
    margin-top: 20px;
  }
  .learning-track-ebook-dashboard {
    &__component {
      text-align: center;
      margin: 20px auto 0 auto;
    }
    &__header {
      background: #5a4179;
      color: #fff;
      padding: 10px;
      border-radius: 3px;
      margin-bottom: 25px;
      position: relative;
      &--back {
        position: absolute;
        left: 10px;
        font-size: 1.5em;
        color: #fff;
        margin-left: 5px;
      }
    }
    &__content {
    }
    &__new-book {
      background: #fff;
      padding: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      &--icon {
        font-size: 100px;
        padding: 20px;
        margin-right: 40px;
        border: 8px solid #555;
        border-radius: 20px;
      }
      &--form {
      }
      &--btn {
        width: 100px;
        padding: 5px;
        display: block;
        border: 3px solid #6e9fec;
        border-radius: 4px;
        margin: 0 auto;
        background: #6e9fec;
        font-size: 16px;
        color: #fff;
      }
      &--title {
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom: 1px solid #eee;
        margin-bottom: 15px;
        &-input {
          font-size: 20px;
          margin: 10px;
          width: 300px;
          padding: 5px;
          text-align: center;
          &::placeholder {
            opacity: 0.4;
          }
        }
        &-quotes {
          font-size: 40px;
        }
      }
    }
    &__existing-books {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 30px;
      margin-bottom: 24px;
    }
    &__existing-book {
      background: #fff;
      padding: 35px;
      color: #555;
      border-radius: 3px;
      text-decoration: none;
      border: 6px solid transparent;
      transition: all 0.2s;
      &--icon {
        display: flex;
        flex-direction: column;
        border-radius: 14px;
        border: 6px solid #efefef;
        padding: 20px;
        margin: 0 auto 10px auto;
        transition: all 0.2s;
        height: 151px;
        width: 260px;
        i {
          transition: all 0.2s;
          color: #aaa;
          font-size: 3.5rem;
          margin-bottom: 5px;
        }
      }
      &--title {
        font-weight: 700;
        margin-top: 5px;
      }
      &--btn {
        margin-top: 10px;
        display: inline-block;
        width: 80px;
        text-decoration: none;
        color: #6e9fec;
        border: 3px solid #6e9fec;
        border-radius: 4px;
        font-size: 14px;
        padding: 3px 0;
        &:hover {
          background: #6e9fec;
          color: #fff;
        }
        &:not(:last-child) {
          margin-right: 10px;
        }
        &:nth-child(2) {
          background: #6e9fec;
          border: 3px solid #6e9fec;
          color: #fff;
        }
      }
    }
  }
  .btn-delete {
    border: 3px solid #ff7272;
    color: #ff7272;
    cursor: pointer;
    &:hover {
      color: #fff;
      background: #ff7272;
    }
  }

  @media only screen and (max-width: 1140px) {
    .learning-track-ebook-dashboard {
      &__component {
        margin-top: 10px;
      }
      &__header {
        font-size: 16px;
        margin-bottom: 15px;
      }

      &__existing-books {
        grid-template-columns: 1fr 1fr;
        grid-gap: 20px;
      }
    }
  }

  @media only screen and (max-width: 1023px) {
    .learning-track-ebook-dashboard {
      &__existing-books {
        margin-bottom: 64px;
      }
    }
  }

  @media only screen and (max-width: 720px) {
    .learning-track-ebook-dashboard {
      &__existing-books {
        grid-template-columns: 1fr;
      }
    }
  }
  @media only screen and (max-width: 580px) {
    .learning-track-ebook-dashboard {
      &__new-book {
        flex-direction: column;
        &--icon {
          margin-right: 0;
          margin-bottom: 15px;
          font-size: 60px;
        }
      }
    }
  }

</style>
