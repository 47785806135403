<template>
  <div class="narration-scene__component">
    <div
      class="bg"
      :class="bgClass">
    </div>
    <div
      class="narrator-picture animated"
      :style="narratorBackgroundImageStyle"
      :class="[narratorClass, animationClass]"
      @click="beginAnimation"
      @animationend="animateClick = false">
    </div>
    <div class="narration-scene__textbox">
      <div
        class="narration-scene__textbox--narrator hypy">
        <p class="narration-scene__textbox--narrator-text">
          {{ narrator }}
        </p>
      </div>
      <div class="narration-scene__textbox--content">
        <!-- <i class="fas fa-volume-up icon-voiceover"></i> -->
        <!-- <read-aloud-audio></read-aloud-audio> -->
        <!-- <i class="fas fa-quote-left icon-quote"></i> -->
        <p>{{ textToDisplay }}</p>
        <!-- <i class="fas fa-quote-right icon-quote"></i> -->
      </div>
      <slot></slot>
    </div>
  </div>
</template>

<script>

import {mapState} from 'vuex';

// https://stackoverflow.com/questions/48652941/vue-click-animation-without-settimeout

// import ReadAloudAudioComponent
//   from '../../common/ReadAloudAudioComponent.vue';

export default {
  name: 'DynamicBookNarrationScene',
  components: {
    // ReadAloudAudio: ReadAloudAudioComponent,
  },
  props: {
    narrator: String,
    narratorAnimation: String,
    textToDisplay: String,
    narratorState: String,
    background: String,
    transition: String,
  },
  data() {
    return {
      animateTransition: true,
      // use to determine whether to make item animate
      animateClick: false,
      baseSpriteUrl:
        'https://smartschool-static.s3-ap-southeast-1.amazonaws.com/media/dynamicbooks/2019_dec_holiday_camp/narration-scene/narrator/',
    };
  },
  computed: {
    ...mapState('dynamicBook', ['currentSceneId']),
    animationClass() {
      const results = {};
      results[this.narratorAnimation] = this.animateClick;
      return results;
    },
    bgClass() {
      // checks that background is valid
      if (!this.background || typeof this.background === 'undefined') {
        return;
      };

      return 'dialogue-bg-' + this.background;
    },
    narratorKey() {
      const narratorMap = {
        '熊猫姐姐': 'panda_sister',
        '熊猫博士': 'boshi_panda',
        '熊猫爸爸': 'panda_dad',
        '熊猫妈妈': 'panda_mum',
        '熊猫弟弟': 'panda_brother',
        '熊猫姐姐和弟弟': 'panda_siblings',
        '熊猫爸爸和妈妈': 'panda_parents',
        '熊猫⼀家⼈': 'panda-family-group',
        '魔幻饺子': 'dumpling-pixie',
        '魔幻汤圆': 'tangyuan-pixie',
        '魔幻饺子和汤圆': 'pixie-group',
        '⿊熊爸爸': 'black_bear_dad',
        '黑熊宝宝': 'black_bear_baby',
        '黑熊爸爸和宝宝': 'black_bear_group',
      };

      return narratorMap[this.narrator];
    },
    narratorBackgroundImageStyle() {
      // checks that narratorKey is valid
      if (!this.narratorKey || typeof this.narratorKey === 'undefined') {
        return;
      }

      const spriteUrl = this.baseSpriteUrl +
        this.narratorKey.split('_').join('-') + '.png';

      return {
        backgroundImage: 'url(' + spriteUrl +')',
      };
    },
    narratorClass() {
      // checks that narratorKey is valid
      if (!this.narratorKey || typeof this.narratorKey === 'undefined') {
        return;
      };

      // e.g. panda-sister-confused
      const pictureName = this.narratorKey.split('_').join('-')
        + '-' + this.narratorState;

      const results = {};
      results[pictureName] = true;

      // for animation
      // setting default values
      if (!this.transition || this.transition === '') {
        results['slideInLeft'] = this.animateTransition;
      } else {
        results[this.transition] = this.animateTransition;
      }

      return results;
    },
  },
  watch: {
    currentSceneId: {
      immediate: true,
      handler(newVal, oldVal) {
        this.animateTransition = true;

        setTimeout(() => {
          this.animateTransition = false;
        }, 1000);
      },
    },
  },
  mounted: function() {
  },
  methods: {
    beginAnimation() {
      this.animateClick = true;
    },
  },
};
</script>

<style lang="scss" scoped>
  .bg {
    height: 0;
    width: 100%;
    padding-top: 50%;
    background-size: 100%;
    background-image: url('https://smartschool-static.s3-ap-southeast-1.amazonaws.com/media/dynamicbooks/2019_dec_holiday_camp/narration-scene/sprite-dialogue-bg.png');
    // @media only screen and (min-width: 667px) and (min-height: 375px) {
    //   background-size: 85%;
    //   padding-top: 52.5%;
    // }
    // @media only screen and (min-width: 736px) and (min-height: 414px) {
    //   background-size: 85%;
    //   padding-top: 52.5%;
    // }
    // @media only screen and (min-width: 812px) and (min-height: 375px) {
    //   background-size: 75%;
    //   padding-top: 46.75%;
    // }
    // @media only screen and (min-width: 1024px) and (min-height: 768px) {
    //   background-size: 105%;
    //   padding-top: 65.5%;
    // }
    // @media only screen and (min-width: 1280px) and (min-height: 800px) {
    //   background-size: 90%;
    //   padding-top: 56.15%;
    // }
    // @media only screen and (min-width: 1920px) and (min-height: 800px) {
    //   background-size: 70%;
    //   padding-top: 43.65%;
    // }
    // @media only screen and (min-width: 1440px) and (min-height: 900px) {
    //   background-size: 90%;
    //   padding-top: 56.15%;
    // }
    // @media only screen and (min-width: 1366px) and (min-height: 1024px) {
    //   background-size: 105%;
    //   padding-top: 65.5%;
    // }
    // @media only screen and (min-width: 2560px) and (min-height: 1200px) {
    //   background-size: 82%;
    //   padding-top: 51.15%;
    // }
  }

  .dialogue-bg-bamboo-green {
    background-position: 0 0%;
  }
  .dialogue-bg-bamboo-white {
    background-position: 0 56.65%;
  }
  .dialogue-bg-cave {
    background-position: 0 113%;
  }
  .dialogue-bg-black {
    background: #000;
  }

  .narrator-picture {
    position: absolute;
    background-size: 100%;
    max-height: 100vh;
    height: 0;
    width: 70%;
    padding-top: 43.5%;
    cursor: pointer;

    // @media only screen and (min-width: 667px) and (min-height: 375px) {
    //   width: 85%;
    //   padding-top: 52.5%;
    // }
    // @media only screen and (min-width: 736px) and (min-height: 414px) {
    //   width: 85%;
    //   padding-top: 52.5%;
    // }
    // @media only screen and (min-width: 812px) and (min-height: 375px) {
    //   width: 75%;
    //   padding-top: 44.5%;
    // }
    // @media only screen and (min-width: 1024px) and (min-height: 768px) {
    //   width: 105%;
    //   padding-top: 65.5%;
    // }
    // @media only screen and (min-width: 1280px) and (min-height: 800px) {
    //   width: 90%;
    //   padding-top: 54.5%;
    // }
    // @media only screen and (min-width: 1920px) and (min-height: 800px) {
    //   width: 70%;
    //   padding-top: 42.5%;
    // }
    // @media only screen and (min-width: 1440px) and (min-height: 900px) {
    //   width: 90%;
    //   padding-top: 54.5%;
    // }
    // @media only screen and (min-width: 1366px) and (min-height: 1024px) {
    //   width: 105%;
    //   padding-top: 68.5%;
    // }
    // @media only screen and (min-width: 2560px) and (min-height: 1200px) {
    //   width: 75%;
    //   padding-top: 46.5%;
    // }
  }
  .panda-sister-angryarmsfolded {
    background-position: 0 0%;
    background-size: 100%;
  }
  .panda-sister-angryarmsonhips {
    background-position: 0 7.692308%;
    background-size: 100%;
  }
  .panda-sister-cheerful {
    background-position: 0 15.384615%;
    background-size: 100%;
  }
  .panda-sister-eureka {
    background-position: 0 23.076923%;
    background-size: 100%;
  }
  .panda-sister-happyarmsdown {
    background-position: 0 30.769231%;
    background-size: 100%;
  }
  .panda-sister-happyarmsfolded {
    background-position: 0 38.461538%;
    background-size: 100%;
  }
  .panda-sister-happyarmsonhips {
    background-position: 0 46.153846%;
    background-size: 100%;
  }
  .panda-sister-pointdown {
    background-position: 0 53.846154%;
    background-size: 100%;
  }
  .panda-sister-pointup {
    background-position: 0 61.538462%;
    background-size: 100%;
  }
  .panda-sister-puzzled {
    background-position: 0 69.230769%;
    background-size: 100%;
  }
  .panda-sister-sad {
    background-position: 0 76.923077%;
    background-size: 100%;
  }
  .panda-sister-think {
    background-position: 0 84.615385%;
    background-size: 100%;
  }
  .panda-sister-thumbsup {
    background-position: 0 92.307692%;
    background-size: 100%;
  }
  .panda-sister-wave {
    background-position: 0 100%;
    background-size: 100%;
  }

  .panda-brother-angryarmsfolded {
      background-position: 0 0%;
      background-size: 100%;
  }
  .panda-brother-angryarmsonhips {
      background-position: 0 7.692308%;
      background-size: 100%;
  }
  .panda-brother-cheerful {
      background-position: 0 15.384615%;
      background-size: 100%;
  }
  .panda-brother-eureka {
      background-position: 0 23.076923%;
      background-size: 100%;
  }
  .panda-brother-happyarmsdown {
      background-position: 0 30.769231%;
      background-size: 100%;
  }
  .panda-brother-happyarmsfolded {
      background-position: 0 38.461538%;
      background-size: 100%;
  }
  .panda-brother-happyarmsonhips {
      background-position: 0 46.153846%;
      background-size: 100%;
  }
  .panda-brother-pointdown {
      background-position: 0 53.846154%;
      background-size: 100%;
  }
  .panda-brother-pointup {
      background-position: 0 61.538462%;
      background-size: 100%;
  }
  .panda-brother-puzzled {
      background-position: 0 69.230769%;
      background-size: 100%;
  }
  .panda-brother-sad {
      background-position: 0 76.923077%;
      background-size: 100%;
  }
  .panda-brother-think {
      background-position: 0 84.615385%;
      background-size: 100%;
  }
  .panda-brother-thumbsup {
      background-position: 0 92.307692%;
      background-size: 100%;
  }
  .panda-brother-wave {
      background-position: 0 100%;
      background-size: 100%;
  }

  .boshi-panda-confused {
    background-position: 0 0%;
    background-size: 100%;
  }
  .boshi-panda-eureka {
    background-position: 0 12.5%;
    background-size: 100%;
  }
  .boshi-panda-happyhandsdown {
    background-position: 0 25%;
    background-size: 100%;
  }
  .boshi-panda-happyhandsonhips {
    background-position: 0 37.5%;
    background-size: 100%;
  }
  .boshi-panda-reluctantsmile {
    background-position: 0 50%;
    background-size: 100%;
  }
  .boshi-panda-sad {
    background-position: 0 62.5%;
    background-size: 100%;
  }
  .boshi-panda-thinkone {
    background-position: 0 75%;
    background-size: 100%;
  }
  .boshi-panda-thinktwo {
    background-position: 0 87.5%;
    background-size: 100%;
  }
  .boshi-panda-wave {
    background-position: 0 100%;
    background-size: 100%;
  }

  .panda-dad-eureka {
    background-position: 0 0%;
    background-size: 100%;
  }
  .panda-dad-happyarmsfolded {
    background-position: 0 9.090909%;
    background-size: 100%;
  }
  .panda-dad-happyhandsonhips {
    background-position: 0 18.181818%;
    background-size: 100%;
  }
  .panda-dad-laughone {
    background-position: 0 27.272727%;
    background-size: 100%;
  }
  .panda-dad-laughtwo {
    background-position: 0 36.363636%;
    background-size: 100%;
  }
  .panda-dad-lecture {
    background-position: 0 45.454545%;
    background-size: 100%;
  }
  .panda-dad-shock {
    background-position: 0 54.545455%;
    background-size: 100%;
  }
  .panda-dad-strong {
    background-position: 0 63.636364%;
    background-size: 100%;
  }
  .panda-dad-think {
    background-position: 0 72.727273%;
    background-size: 100%;
  }
  .panda-dad-thumbsup {
    background-position: 0 81.818182%;
    background-size: 100%;
  }
  .panda-dad-wand {
    background-position: 0 90.969091%;
    background-size: 100%;
  }
  .panda-dad-wave {
    background-position: 0 100%;
    background-size: 100%;
  }

  .panda-mum-cook {
    background-position: 0 0%;
    background-size: 100%;
  }
  .panda-mum-educate {
    background-position: 0 12.5%;
    background-size: 100%;
  }
  .panda-mum-educatetwo {
    background-position: 0 25%;
    background-size: 100%;
  }
  .panda-mum-happyarmsfolded {
    background-position: 0 37.5%;
    background-size: 100%;
  }
  .panda-mum-happyhandsonhips {
    background-position: 0 50%;
    background-size: 100%;
  }
  .panda-mum-puzzled {
    background-position: 0 62.5%;
    background-size: 100%;
  }
  .panda-mum-stop {
    background-position: 0 75%;
    background-size: 100%;
  }
  .panda-mum-thumbsup {
    background-position: 0 87.5%;
    background-size: 100%;
  }
  .panda-mum-wave {
    background-position: 0 100%;
    background-size: 100%;
  }

  .panda-parents-angry {
    background-position: 0 0%;
    background-size: 100%;
  }
  .panda-parents-educate {
    background-position: 0 25%;
    background-size: 100%;
  }
  .panda-parents-happy {
    background-position: 0 50%;
    background-size: 100%;
  }
  .panda-parents-instruct {
    background-position: 0 75%;
    background-size: 100%;
  }
  .panda-parents-puzzled {
    background-position: 0 100%;
    background-size: 100%;
  }

  .panda-siblings-angry {
    background-position: 0 0%;
    background-size: 100%;
  }
  .panda-siblings-angrytwo {
    background-position: 0 11.111111%;
    background-size: 100%;
  }
  .panda-siblings-cheerful {
    background-position: 0 22.222222%;
    background-size: 100%;
  }
  .panda-siblings-happy {
    background-position: 0 33.333333%;
    background-size: 100%;
  }
  .panda-siblings-makeapoint {
    background-position: 0 44.444444%;
    background-size: 100%;
  }
  .panda-siblings-pointdown {
    background-position: 0 55.555556%;
    background-size: 100%;
  }
  .panda-siblings-puzzled {
    background-position: 0 66.666667%;
    background-size: 100%;
  }
  .panda-siblings-think {
    background-position: 0 77.777778%;
    background-size: 100%;
  }
  .panda-siblings-thumbsup {
    background-position: 0 88.888889%;
    background-size: 100%;
  }
  .panda-siblings-wave {
    background-position: 0 100%;
    background-size: 100%;
  }
  .dumpling-pixie-cheerfulone {
    background-position: 0 0%;
    background-size: 100%;
  }
  .dumpling-pixie-cheerfultwo {
    background-position: 0 16.666667%;
    background-size: 100%;
  }
  .dumpling-pixie-confused {
    background-position: 0 33.333333%;
    background-size: 100%;
  }
  .dumpling-pixie-eureka {
    background-position: 0 50%;
    background-size: 100%;
  }
  .dumpling-pixie-happyfront {
    background-position: 0 66.666667%;
    background-size: 100%;
  }
  .dumpling-pixie-thumbsup {
    background-position: 0 83.333333%;
    background-size: 100%;
  }
  .dumpling-pixie-wave {
    background-position: 0 100%;
    background-size: 100%;
  }

  .tangyuan-pixie-cheerful {
    background-position: 0 0%;
    background-size: 100%;
  }
  .tangyuan-pixie-cheerfultwo {
    background-position: 0 16.666667%;
    background-size: 100%;
  }
  .tangyuan-pixie-confused {
    background-position: 0 33.333333%;
    background-size: 100%;
  }
  .tangyuan-pixie-eureka {
    background-position: 0 50%;
    background-size: 100%;
  }
  .tangyuan-pixie-happyfront {
    background-position: 0 66.666667%;
    background-size: 100%;
  }
  .tangyuan-pixie-thumbsup {
    background-position: 0 83.333333%;
    background-size: 100%;
  }
  .tangyuan-pixie-wave {
    background-position: 0 100%;
    background-size: 100%;
  }
  .pixie-group-cheerful {
    background-position: 0 0%;
    background-size: 100%;
  }
  .pixie-group-confused {
    background-position: 0 50%;
    background-size: 100%;
  }
  .pixie-group-wave {
    background-position: 0 100%;
    background-size: 100%;
  }

  .black-bear-baby-cheerful {
    background-position: 0 0%;
  }
  .black-bear-baby-cryone {
    background-position: 0 10%;
  }
  .black-bear-baby-crythree {
    background-position: 0 20%;
  }
  .black-bear-baby-crytwo {
    background-position: 0 30%;
  }
  .black-bear-baby-happyfront {
    background-position: 0 40%;
  }
  .black-bear-baby-holdbowl {
    background-position: 0 50%;
  }
  .black-bear-baby-pointdown {
    background-position: 0 60%;
  }
  .black-bear-baby-sadone {
    background-position: 0 70%;
  }
  .black-bear-baby-sadtwo {
    background-position: 0 80%;
  }
  .black-bear-baby-shock {
    background-position: 0 90%;
  }
  .black-bear-baby-wave {
    background-position: 0 100%;
  }


  .black-bear-dad-apologetic {
    background-position: 0 0%;
  }
  .black-bear-dad-checkbowl {
    background-position: 0 8.333333%;
  }
  .black-bear-dad-cryone {
    background-position: 0 16.666667%;
  }
  .black-bear-dad-crythree {
    background-position: 0 25%;
  }
  .black-bear-dad-crytwo {
    background-position: 0 33.333333%;
  }
  .black-bear-dad-guilty {
    background-position: 0 41.666667%;
  }
  .black-bear-dad-happy {
    background-position: 0 50%;
  }
  .black-bear-dad-puzzled {
    background-position: 0 58.333333%;
  }
  .black-bear-dad-sadholdbowl {
    background-position: 0 66.666667%;
  }
  .black-bear-dad-sadone {
    background-position: 0 75%;
  }
  .black-bear-dad-sadtwo {
    background-position: 0 83.333333%;
  }
  .black-bear-dad-shock {
    background-position: 0 91.666667%;
  }
  .black-bear-dad-wave {
    background-position: 0 100%;
  }


  .black-bear-group-apologetic {
    background-position: 0 0%;
  }
  .black-bear-group-cheerful {
    background-position: 0 16.666667%;
  }
  .black-bear-group-guilty {
    background-position: 0 33.333333%;
  }
  .black-bear-group-happy {
    background-position: 0 50%;
  }
  .black-bear-group-holdbowl {
    background-position: 0 66.666667%;
  }
  .black-bear-group-shock {
    background-position: 0 83.333333%;
  }
  .black-bear-group-wave {
    background-position: 0 100%;
  }


  .panda-family-group-happy {
    background-position: 0 0%;
  }
  .panda-family-group-sad {
    background-position: 0 50%;
  }
  .panda-family-group-wave {
    background-position: 0 100%;
  }

  // Narration Scene Styles
  .narration-scene {
    &__component {
      position: relative;
      height: 100vh;
      width: 100vw;
      display: flex;
      justify-content: center;
    }
    &__textbox {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      background: #222;
      height: 30vh;
      border-top: 10px solid #333;
      &--narrator {
        position: relative;
        color: #fff;
        &-text {
          position: absolute;
          top: -70px;
          left: 0;
          font-size: 2rem;
          padding: 15px 30px;
          background: #d41978;
          border: 5px solid #ff0081;
          border-radius: 5px;
        }
      }
      &--content {
        margin: 0 40px;
        color: #fff;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.5rem;
        .icon-quote {
          margin-top: -50px;
          color: #555;
        }
        p {
          text-align: center;
          margin: 0 30px;
          font-size: 2.5rem;
        }
      }
    }
  }
  /* Landscape */
  // ipad pro
  @media only screen
  and (min-device-width: 1024px)
  and (max-device-width: 1366px)
  and (-webkit-min-device-pixel-ratio: 2) {
    .narrator-picture {
      width: 100%;
      padding-top: 61.5%;
    }
    .bg {
      background-size: 105%;
      padding-top: 65.5%;
    }
  }
  // ipad
  @media only screen and (min-device-width : 768px)
  and (max-device-width : 1024px) and (orientation : landscape) {
     .narrator-picture {
      width: 100%;
      padding-top: 61.5%;
      margin-top: 3%;
    }
    .bg {
      background-size: 105%;
      padding-top: 65.5%;
    }
  }

</style>
