const getDefaultState = () => {
  return {
    numberOfQuestionsAttempted: 0,
    choiceSelected: '',
    answeredAllQuestions: false,
    gameRunning: false,
    staticPath: 'https://s3-ap-southeast-1.amazonaws.com/smartschool-static',
    // can be notAnswered, correct, or wrong
    answeredStatus: 'notAnswered',
    showReportModal: false,
    animateQuestionComponent: true,
    // core, highfrequency, or level (user selects which level)
    dailyChallengeSubcategory: 'core',
    dailyChallengeSelectedLevel: '', // only when subcategory is level
    inputMethod: 'microphone', // for active usage quiz; swaps between keyboard and microphone
  };
};

const state = getDefaultState();

export const quiz = {
  namespaced: true,
  state,
  mutations: {
    resetStateMutation(state) {
      Object.assign(state, getDefaultState());
    },
    setNumberOfQuestionsAttempted: function(state, value) {
      state.numberOfQuestionsAttempted = value;
    },
    resetNumberOfQuestionsAttempted: function(state) {
      state.numberOfQuestionsAttempted = 0;
    },
    setChoiceSelected: function(state, choice) {
      state.choiceSelected = choice;
    },
    setDailyChallengeSubcategory: function(state, choice) {
      state.dailyChallengeSubcategory = choice;
    },
    setDailyChallengeSelectedLevel: (state, value) => {
      state.dailyChallengeSelectedLevel = value;
    },
    setAnsweredStatus: function(state, value) {
      console.log('setting answered status', value);
      state.answeredStatus = value;
    },
    setAnsweredAllQuestions: function(state, value) {
      state.answeredAllQuestions = value;
    },
    setShowReportModal: function(state, value) {
      state.showReportModal = value;
    },
    setAnimateQuestionComponent: function(state, value) {
      state.animateQuestionComponent = value;
    },
    setGameRunning: function(state, value) {
      state.gameRunning = value;
    },
    setInputMethod: function(state, value) {
      state.inputMethod = value;
    },
  },
  actions: {

  },
};
