<template>
  <div class="popup__component">
    <div
      class="popup__btn tap-btn-highlight"
      @click.prevent='show'>
      <slot></slot>
    </div>
    <div v-if='showPopup'
      @click.prevent='next'
      class="popup__container tap-btn-highlight">
      <!-- Dialogue Component -->
      <div class="popup__background"></div>
      <div
        class="popup__content">
        <div class="popup__content--spbubble">
          <div class="popup__content--spbubble-tail"></div>
          <div class="popup__content--spbubble-tail-border"></div>
          <p class="popup__content--spbubble-text">
            {{ currentMessage }}
            <i class="fas fa-caret-down"></i>
          </p>
        </div>
        <div class="popup__content--panda">
          <img src="../../assets/boshipanda.png"/>
          <div class="popup__content--grass">
            <img class="popup__content--grass-img"
              src="../../assets/grass-1.png"/>
            <img class="popup__content--grass-img"
              src="../../assets/grass-2.png"/>
            <img class="popup__content--grass-img"
              src="../../assets/grass-1.png"/>
          </div>
        </div>
      </div>
    </div>
    <!-- Quest Tracker Component -->
<!--     <div class="popup__tracker">
      <div class="img"></div>
      <div>
        <p>The shy panda is hiding.</p>
        <p>Lure him out to play with Gemstones!</p>
      </div>
      <div>
        <div>
          <i class="fas fa-gem"></i>
          <i class="fas fa-gem"></i>
          <i class="fas fa-gem"></i>
          <i class="fas fa-gem"></i>
          <i class="fas fa-gem"></i>
          <i class="fas fa-gem"></i>
        </div>
        <div>
          <p>0/6 Gems Collected</p>
        </div>
      </div>
      <div>
        <div @click.prevent='hide'>Close</div>
      </div>
    </div> -->
  </div>
</template>

<script>

import axios from 'axios';
import {mapState, mapGetters, mapActions} from 'vuex';

export default {
  name: 'SpeechBubblePopup',
  components: {
  },
  props: {
    messages: Array,
    // this is called once hidden
    callbackOnClose: Function,
    // used to determine if we should open popup at the start
    autoShow: Boolean,
    open: {
      type: Boolean,
      default: false,
    },
    // used to determine if we should update backend
    // that notification has been seen
    sendToBackend: {
      type: Boolean,
      default: true,
    },
    notificationCategory: String,
    notificationSubcategory: String,
  },
  watch: {
    // when the prop open is set to true, run show method
    open: function(newVal, oldVal) {
      if (newVal) {
        this.show();
      }
    },
  },
  data() {
    return {
      index: 0,
      showPopup: false,
    };
  },
  computed: {
    ...mapState(['studentId', 'studentName']),
    ...mapGetters(['getNotificationByCategories']),
    currentMessage() {
      if (this.index < this.messages.length) {
        const message = this.messages[this.index];
        return message.replace(/{student_name}/g, this.studentName);
      }

      return '';
    },
  },
  mounted() {
    if (this.autoShow) this.show();
  },
  methods: {
    ...mapActions(['setNotificationAsSeenOrCreate']),
    show() {
      this.index = 0;
      this.showPopup = true;

      // validation
      if (!this.sendToBackend || !this.notificationCategory ||
        !this.notificationSubcategory) {
        return;
      };

      const notification = this.getNotificationByCategories(
          this.notificationCategory, this.notificationSubcategory);

      // if notification has not been seen before, update on backend
      if (typeof notification === 'undefined' || !notification.seen) {
        console.log('preparing to send to backend');
        const url = '/api/v1/core/student/' +
          this.studentId + '/notifications/';

        axios
            .post(url, {
              category: this.notificationCategory,
              subCategory: this.notificationSubcategory,
            })
            .then((response) => {
              this.setNotificationAsSeenOrCreate(response.data);
            });
      };
    },
    next() {
      this.index++;
      // once we are done with messages, close & run callback
      if (this.index >= this.messages.length) {
        this.showPopup = false;
        // end callback
        if (typeof this.callbackOnClose !== 'undefined') this.callbackOnClose();
      };
    },
  },
};
</script>

<style lang="scss" scoped>

  .div, p, span, img {
    -webkit-user-select: none; /* Chrome all / Safari all */
    -moz-user-select: none;   /* Firefox all */
    -ms-user-select: none;  /* IE 10+ */
     user-select: none;  /* Likely future */
  }

  .popup {
    &__component {
    }
    // Toggle between dialogue and quest tracker Placeholder
    &__dialogue {
      // display: none;
      display: block;
    }
    &__tracker {
      // display: block;
      display: none;
    }
    // dialogue styles
    &__background {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100vw;
      background: #5dffa6;
      height: 200px;
    }
    &__content {
      width: 630px;
      height: 100vh;
      position: relative;
      &--spbubble {
        border: 5px solid #fff;
        height: 200px;
        background: #dcdcdc;
        position: absolute;
        bottom: 570px;
        left: 120px;
        border-radius: 20px;
        color: #555;
        padding: 20px;
        display: flex;
        align-items: center;
        &-tail,
        &-tail-border {
          height: 125px;
          width: 75px;
          background: #dcdcdc;
          position: absolute;
          bottom: -120px;
          left: 190px;
          -webkit-clip-path: polygon(23% 0, 0% 100%, 100% 0);
          clip-path: polygon(23% 0, 0% 100%, 100% 0);
          z-index: 30;
          &-border {
            left: 186px;
            bottom: -126px;
            width: 80px;
            background: #fff;
            z-index: 20;
          }
        }
        &-text{
          width: 460px;
          word-wrap: break-word;
          margin-bottom: 20px;
          i {
            position: absolute;
            bottom: 0;
            right: 10px;
            animation: blink 1.2s linear infinite;
          }
        }
      }
      &--grass {
        &-img {
          position: absolute;
          bottom: 25px;
          &:nth-child(1) {
            left: -40px;
          }
          &:nth-child(2) {
            left: 320px;
          }
          &:nth-child(3) {
            left: 600px;
            width: 40px;
            bottom: 40px;
            transform: rotate(20deg);
          }
        }
      }
      &--panda{
        position: absolute;
        bottom: 150px;
        z-index: 20;
        left: 0;
        & > img {
          width: 300px;
        }
      }
    }
    &__btn {
      cursor: pointer;
      width: 100px;
      height: 100px;
      position: relative;
      i {
        position: absolute;
        top: -5px;
        right: -10px;
        color: #fff;
        font-size: 35px;
        text-shadow: 3px 3px 0px rgba(0,0,0,0.2);
        animation: blink-slow 3s linear infinite;
        z-index: 5;
      }
      &--dialogue {
        border-radius: 50%;
        border: 5px solid #00e5ff;
        background: linear-gradient(150deg, rgba(45,176,255,1) 0%,
                    rgba(226,0,255,1) 100%);
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        img {
          width: 90px;
          margin-top: 30px;
        }
      }
    }
    &__container {
      height: 100vh;
      width: 100vw;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      color: #fff;
      font-size: 40px;
      position: absolute;
      z-index: 10;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.85);
      cursor: pointer;
    }
  }
  @media only screen and (max-width: 760px) {
    .popup {
      &__btn {
        width: 65px;
        height: 65px;
        i {
          font-size: 25px;
        }
        &--dialogue {
          img {
            width: 50px;
            margin-top: 15px;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 600px) {
    .popup {
      &__btn {
        i {
          right: -5px;
        }
      }
    }
  }
  @media only screen and (max-width: 680px) {
    .popup {
      &__container {
        font-size: 28px;
      }
      &__background {
        height: 100px;
      }
      &__content {
        width: 360px;
        &--spbubble {
          bottom: 400px;
          left: 0;
          &-tail,
          &-tail-border {
            left: 230px;
            &-border {
              left: 226px;
            }
          }
          &-text {
            width: auto;
          }
        }
        &--grass {
          &-img {
            bottom: 20px;
            &:nth-child(2) {
              left: 200px;
            }
            &:nth-child(3) {
              bottom: 30px;
              left: 280px;
            }
          }
        }
        &--panda {
          left: 30px;
          bottom: 60px;
          & >img {
            width: 200px;
          }
        }
      }
    }
  }
  @media only screen and (min-width: 720px) and (max-height: 790px) {
    .popup {
      &__container {
        font-size: 28px;
      }
      &__background {
        height: 100px;
      }
      &__content {
        width: 360px;
        &--spbubble {
          bottom: 400px;
          left: 0;
          &-tail,
          &-tail-border {
            left: 230px;
            &-border {
              left: 226px;
            }
          }
          &-text {
            width: auto;
          }
        }
        &--grass {
          &-img {
            &:nth-child(1) {
              left: -150px;
            }
            &:nth-child(2) {
              left: 220px;
            }
            &:nth-child(3) {
              left: 400px;
              bottom: 30px;
            }
          }
        }
        &--panda {
          left: 30px;
          bottom: 60px;
          & > img {
            width: 200px;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 900px) and (max-height: 420px) {
    .popup {
      &__container {
        font-size: 28px;
      }
      &__background {
        height: 100px;
      }
      &__content {
        width: 720px;
        &--spbubble {
          height: 150px;
          bottom: 210px;
          left: 280px;
          width: 360px;
          &-tail,
          &-tail-border {
            height: 80px;
            width: 75px;
            left: 5px;
            bottom: -76px;
            &-border {
              left: 1px;
              bottom: -82px;
            }
          }
          &-text {
            width: auto;
          }
        }
        &--grass {
          &-img {
            bottom: 50px;
            &:nth-child(1) {
              width: 40px;
              left: -20px;
              bottom: 60px;
            }
            &:nth-child(2) {
              left: 250px;
            }
            &:nth-child(3) {
              left: 400px;
              bottom: 60px;
            }
          }
        }

        &--panda {
          left: 60px;
          bottom: 25px;
          & > img {
            width: 200px;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 650px) and (max-height: 360px) {
    .popup {
      &__background {
        height: 75px;
      }
      &__content {
        &--spbubble {
          bottom: 175px;
          left: 255px;
        }
        &--grass {
          &-img {
            bottom: 30px;
            &:nth-child(1) {
              bottom: 40px;
            }
            &:nth-child(2) {
              left: 250px;
            }
            &:nth-child(3) {
              bottom: 40px;
            }
          }
        }
        &--panda {
          left: 100px;
          & > img {
            width: 150px;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 650px) and (max-height: 330px) {
    .popup {
      &__background {
        height: 50px;
      }
      &__content {
        &--spbubble {
          bottom: 155px;
          left: 255px;
        }
        &--grass {
          &-img {
            bottom: 8px;
            &:nth-child(1) {
              bottom: 10px;
            }
            &:nth-child(2) {
              left: 250px;
            }
            &:nth-child(3) {
              bottom: 10px;
            }
          }
        }
        &--panda {
          left: 100px;
          & > img {
            width: 150px;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 370px) {
    .popup {
      &__container {
        font-size: 27px;
      }
      &__background {
        height: 75px;
      }
      &__content {
        width: 320px;
        &--spbubble {
          margin: 0 20px;
          bottom: 320px;
          left: 0;
          &-tail,
          &-tail-border {
            left: 180px;
            bottom: -80px;
            height: 85px;
            width: 60px;
            &-border {
              left: 176px;
              bottom: -86px;
            }
          }
          &-text {
            width: auto;
          }
        }
        &--grass {
          &-img {
            &:nth-child(2) {
              left: 150px;
            }
            &:nth-child(3) {
              left: 250px;
            }
          }
        }
        &--panda {
          left: 30px;
          bottom: 40px;
          & > img {
            width: 175px;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 380px) {
    .popup {
      &__btn {
        width: 60px;
        height: 60px;
        i { font-size: 20px; }
        &--dialogue {
          img {
            width: 45px;
            margin-top: 10px;
          }
        }
      }
    }
  }

  @keyframes blink{
    0%{opacity: 0.5;}
    20%{opacity: 1;}
    30%{opacity: 1;}
    50%{opacity: 0.5;}
    100%{opacity: 0;}
  }
  @keyframes blink-slow{
    0%{opacity: 0;}
    50%{opacity: 1;}
    100%{opacity: 0;}
  }
</style>
