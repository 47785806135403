<template>
  <div class="multiplayer__room-selection--component animated fadeIn faster">
    <router-link
      :to="{name:'profile-main'}">
      <i class="fas fa-times multiplayer__room-selection--exit"></i>
    </router-link>
    <div class="multiplayer__room-selection--header animated bounceIn">
      <h1 class="multiplayer__room-selection--header-main">
        KidStartNow!
      </h1>
      <p class="multiplayer__room-selection--header-sub">
        Interactive Online Classroom
      </p>
    </div>
    <form class="multiplayer__room-selection--form">
<!--       <input
        class="multiplayer__room-selection--form-pin animated fadeInUp
          faster delay-1"
        v-model="roomPin"
        type="text"
        placeholder="Room PIN"
        size="100"> -->
      <button @click.prevent="enterRoom"
        class="multiplayer__room-selection--form-btn animated fadeInUp
        faster delay-2">
        Enter
      </button>
<!--       <input
        type="submit"
        value="Enter"> -->
    </form>
    <div class="multiplayer__room-selection--footer animated fadeInUp faster">
      <p>{{ studentName }}</p>
      <span>|</span>
      <p>{{ level | splitCapitalise }}</p>
      <p></p>
    </div>
    <!-- ERROR POPUPS -->
    <div
      v-if="errorMessage"
      class="multiplayer__room-selection--errors animated fadeIn faster">
      <div class="multiplayer__room-selection--errors-container">
        <div class="multiplayer__room-selection--errors-header">
          <h1>Oh No!</h1>
          <h2>Something's Wrong!</h2>
        </div>
        <div class="multiplayer__room-selection--errors-body">
          <p>
            {{ errorMessage }}
          </p>
        </div>
        <p class="multiplayer__room-selection--errors-btn
          animated bounceIn delay-2"
          v-on:click="errorReset">
          Try Again
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import {mapState, mapMutations} from 'vuex';

// import VocabTeachingComponent
//   from '../common/VocabTeachingComponent.vue';


export default {
  name: 'MultiplayerRoomSelection',
  components: {
    // VocabTeaching: VocabTeachingComponent,
  },
  props: {
  },
  data() {
    return {
      errorMessage: '',
    };
  },
  computed: {
    ...mapState(['studentName', 'level']),
    ...mapState('multiplayer', ['roomId']),
  },
  mounted: function() {
  },
  methods: {
    ...mapMutations('multiplayer', ['setInfo']),
    errorReset() {
      this.errorMessage = '';
    },
    enterRoom() {
      axios
          .get('/multiplayer/api/room/')
          .then((response) => {
            this.setInfo(response.data);
            this.$router.push({name: 'multiplayer-room'});
          })
          .catch((error) => {
            this.errorMessage = error.response.data.detail;
            return;
          });
    },
  },
};
</script>

<style lang="scss" scoped>
  .delay-1 {
    animation-delay: 0.05s;
  }
  .delay-2 {
    animation-delay: 0.125s;
  }
  .multiplayer__room-selection {
    &--exit {
      position: absolute;
      top: 20px;
      right: 20px;
      color: #ffffff40;
      font-size: 2rem;
      cursor: pointer;
      &:hover {
        color: #ffffff80;
      }
    }
    &--component {
      font-family: 'baloo da 2';
      position: absolute;
      background: radial-gradient(#449dbf, #2a80d8);
      height: 100vh;
      width: 100%;
      top: 0;
      left: 0;
      z-index: 20;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
    &--header {
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      &-main {
        font-size: 2.75rem;
        margin-bottom: -15px;
      }
      &-sub {
        font-size: 1.15rem;
        padding: 2.5px;
        border-top: 3px solid #ddd6;
        margin-bottom: 15px;
      }
    }
    &--form {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      max-width: 11.5rem;
      width: 150px;
      input,
      button {
        font-family: 'baloo da 2';
        font-weight: 900;
        width: 100%;
        height: 2.5rem;
        font-size: 1.1rem;
      }
      &-pin {
        margin-bottom: 0.5rem;
        text-align: center;
        outline: none;
        border-width: 4px;
        border: 4px solid #ccc;
        &::placeholder {
          color: #c7c7c7;
        }
        &:focus,
        &:active {
          border-style: solid;
          border-color: rgb(51, 51, 51);
          border-image: initial;
        }
        &:hover {
          outline: 0px;
        }
      }
      &-btn {
        color: #fff;
        background: #ff7614;
        text-decoration: none;
        outline: none;
        border: 4px solid #222;
        cursor: pointer;
        transition: all 0.3s;
        &:hover {
          transform: scale(1.1);
        }
        &:active {
          transform: scale(1.05);
        }
      }
    }
    &--footer {
      display: flex;
      color: #ffffff80;
      position: absolute;
      bottom: 10px;
      font-weight: 900;
      span {
        margin: 0 10px;
      }
    }
    &--errors {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #00000090;
      height: 100vh;
      width: 100%;
      text-align: center;
      z-index: 20;
      &-container {
        padding: 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background: #fff;
        border-radius: 5px;
      }
      &-header {
        h2 {
          margin-top: -20px;
          border-bottom: 3px solid #ddda;
          margin-bottom: 20px;
        }
      }
      &-body {
        width: 100%;
        border-bottom: 3px solid #ddda;
        padding-bottom: 20px;
        margin-bottom: 20px;
      }
      &-btn {
        font-weight: 900;
        color: #fff;
        background: #1493ff;
        text-decoration: none;
        outline: none;
        border: 4px solid #222;
        cursor: pointer;
        transition: all 0.3s;
        padding: 5px 20px;
        border-radius: 5px;
        &:hover {
          transform: scale(1.1);
        }
        &:active {
          transform: scale(1.05);
        }
      }
    }
  }
</style>
