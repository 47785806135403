import Vue from 'vue';

const getDefaultState = () => {
  return {
  // storyarcs contains limited info on the available storyarcs
    // does not contain the words, etc
    storyarcs: [],
    // contains stories in currentStoryarc
    storiesInCurrentStoryarc: [],
    latestStory: null,
  };
};

const state = getDefaultState();

export const library = {
  namespaced: true,
  state,
  mutations: {
    resetStateMutation(state) {
      Object.assign(state, getDefaultState());
    },
    setStoryarcs(state, value) {
      state.storyarcs = value;
    },
    setStoriesInCurrentStoryarc(state, value) {
      state.storiesInCurrentStoryarc = value;
    },
    setLatestStory(state, value) {
      state.latestStory = value;
    },
  },
  actions: {
    // note -> we need to use Vue.set to maintain reactivity
    updateStory({commit, state, getters}, story) {
      if (story.pk === state.latestStory.pk) {
        Vue.set(state, 'latestStory', story);
      };

      const index = state.storiesInCurrentStoryarc.findIndex((element) => {
        return element.pk === story.pk;
      });
      Vue.set(state.storiesInCurrentStoryarc, index, story);
    },
    // toggleStoryDifficulty({commit, state, getters}, payload) {
    //   // we need to modify both the story in storiesInCurrentStoryarc
    //   // and in latestStory (if it is latest story)
    //   if (payload.storyId === state.latestStory.pk) {
    //     Vue.set(state.latestStory, 'difficulty', payload.difficulty);
    //   };

    //   const story = getters.getStoryById(payload.storyId);
    //   Vue.set(story, 'difficulty', payload.difficulty);
    // },
  },
  getters: {
    // method-style access (because we want to pass parameters)
    // https://vuex.vuejs.org/guide/getters.html
    getStoryarcById(state) {
      return (pk) => {
        return state.storyarcs.find((storyarc) => storyarc.pk === pk);
      };
    },
    // possible bug if user makes changes to latest story (e.g. upgrade video)
    // and is only changed in storiesInCurrentStoryarc but not latest story
    getStoryById(state) {
      return (pk) => {
        // if user clicks on latest story
        if (pk === state.latestStory.pk) {
          return state.latestStory;
        } else {
          return state.storiesInCurrentStoryarc.find((story) => {
            return pk === story.pk;
          });
        };
      };
    },
  },
};
