<template>
  <div class="fixed-component">
    <div class="scrollable-component">
      <div class="oral-index__component row">
        <h2 class="oral-index__component--header">
          <i class="fas fa-book-reader"></i>
          口试练习
          <span>|</span>
          {{ formattedLevel }}
        </h2>
        <div class="oral-index__container">
          <div v-if="itemsToShow.indexOf('oralTextbookChapter') > -1" class="oral-index__container--item">
            <router-link
              class="user-settings-content"
              :to="{name: 'oral-textbook-chapter-index'}">
              <div class="oral-index__container--item-icon">
                <i class="fas fa-book icon-main"></i>
              </div>
              <div class="oral-index__container--text">
                <span class="oral-index__container--text-title">
                  朗读课本
                </span>
                <br>
              </div>
            </router-link>
          </div>
          <div v-if="itemsToShow.indexOf('oralPassage') > -1" class="oral-index__container--item">
            <router-link
              class="user-settings-content"
              :to="{name: 'oral-readaloud-index'}">
              <div class="oral-index__container--item-icon">
                <i class="fas fa-newspaper icon-main"></i>
              </div>
              <div class="oral-index__container--text">
                <span class="oral-index__container--text-title">
                  朗读短文
                </span>
                <br>
              </div>
            </router-link>
          </div>
          <div v-if="itemsToShow.indexOf('freeResponse') > -1" class="oral-index__container--item">
            <router-link
              class="user-settings-content"
              :to="{name: 'oral-free-response-index'}">
              <div class="oral-index__container--item-icon">
                <i class="fas fa-comments icon-main"></i>
              </div>
              <div class="oral-index__container--text">
                <span class="oral-index__container--text-title">
                  会话题目
                </span>
                <br>
              </div>
            </router-link>
          </div>
          <div v-if="itemsToShow.indexOf('templatedPhrases') > -1" class="oral-index__container--item">
            <router-link
              class="user-settings-content"
              :to="{name: 'oral-templated-phrases-index'}">
              <div class="oral-index__container--item-icon">
                <i class="fas fa-thumbs-up icon-main"></i>
              </div>
              <div class="oral-index__container--text">
                <span class="oral-index__container--text-title">
                  通用好词佳句
                </span>
                <br>
              </div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapState} from 'vuex';

export default {
  name: 'OralOverview',
  components: {
  },
  props: {
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapState(['level']),
    formattedLevel() {
      return this.$options.filters.splitCapitalise(this.level);
    },
    // different levels have different things to show
    itemsToShow() {
      const items = [];

      if (['primary_one', 'primary_two', 'primary_three', 'primary_four', 'primary_five', 'primary_six'].indexOf(this.level) > -1) {
        items.push('oralTextbookChapter');
      }
      if (this.level === 'primary_three' || this.level === 'primary_four' || this.level === 'primary_five' || this.level === 'primary_six') {
        items.push('oralPassage');
      }
      if (this.level === 'primary_four' || this.level === 'primary_five' || this.level === 'primary_six') {
        items.push('freeResponse');
      }
      if (this.level === 'primary_four' || this.level === 'primary_five' || this.level === 'primary_six') {
        items.push('templatedPhrases');
      }
      return items;
    },
  },
  mounted() {
  },
  methods: {
  },
};

</script>

<style lang="scss" scoped>
  .oral-index {
    &__component {
      text-align: center;
      margin: 20px auto 84px auto;
      &--header {
        i {
          margin-right: 0.5rem;
        }
        background: #5a4179;
        color: #fff;
        padding: 10px;
        border-radius: 3px;
        margin-bottom: 25px;
        span {
          margin: 0 0.25rem;
        }
      }
    }
    &__container {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 40px;
      margin-bottom: 30px;
      &--item {
        background: #fff;
        padding: 3rem 2rem 2rem;
        color: #555;
        border-radius: 3px;
        text-decoration: none;
        border: 6px solid transparent;
        transition: all 0.2s;
        position: relative;
        a {
          color: #555;
          text-decoration: none;
        }
        &-icon {
          border-radius: 14px;
          border: 6px solid #efefef;
          padding: 20px 80px;
          margin: .5rem auto;
          transition: all 0.2s;
          width: 260px;
          margin-bottom: 1.25rem;
          &:hover {
            padding: 20px;
            border: 6px solid #b3cdff;
            i {
              color: #6e9fec;
            }
          }
          i {
            transition: all 0.2s;
            color: #aaa;
            font-size: 3.5rem;
            margin-bottom: 5px;
          }
        }
        &-header {
          display: flex;
          justify-content: center;
          font-weight: 600;
          font-size: 1.1rem;
          margin-bottom: 0.25rem;
          span {
            margin: 0 .25rem;
          }
        }
        &-subheader {
          font-size: 0.8rem;
        }
        &-overlay {
          position: absolute;
          text-align: center;
          top: -1rem;
          left: 0;
          width: 100%;
          p {
            background: #01dac8;
            display: inline-block;
            padding: 0.25rem 2rem;
            color: #fff;
            font-weight: 600;
            text-transform: uppercase;
            border-radius: 5px
          }
        }
      }
      &--text {
        &-title {
          font-weight: 600;
          font-size: 1.15rem;
        }
        &-num-words {
          position: absolute;
          top: 1rem;
          right: 1rem;
          font-size: 0.9rem;
        }
      }
    }
  }
  @media only screen and (max-width: 1140px) {
    .oral-index {
      &__component {
        margin-bottom: 64px;
      }
      &__container {
        grid-gap: 20px;
        grid-template-columns: 1fr 1fr;
      }
    }
  }
  @media only screen and (max-width: 720px) {
    .oral-index {
      &__container {
        grid-template-columns: 1fr;
      }
    }
  }
  @media only screen and (max-width: 580px) {
    .oral-index__component--header {
      font-size: 1.1rem;
    }
  }
  @media only screen and (max-width: 450px) {
    .oral-index__component--header {
      font-size: 1rem;
    }
  }
  @media only screen and (max-width: 390px) {
    .oral-index__component--header {
      font-size: 0.9rem;
    }
  }
  @media only screen and (max-width: 350px) {
    .oral-index__component--header {
      font-size: 0.8rem;
    }
    .oral-index__container--item-icon {
      width: auto;
    }
  }

  .num-words {
    font-size: 0.8em;
  }
</style>
