<template>
  <block-container
    title="Comprehension"
    fa-icon="book-open"
    >
    <div class="learn-index__container">
      <router-link
        class="learn-index__container--item"
        :to="{name: 'new-comprehension-passage-index'}">
        <div class="user-settings-content">
          <div class="learn-index__container--item-icon">
            <i class="fas fa-book-open"></i>
          </div>
          <div class="learn-index__container--text">
            <span class="learn-index__container--text-title">
              篇章练习
            </span>
            <br>
          </div>
        </div>
      </router-link>
      <router-link
        class="learn-index__container--item"
        :to="{name: 'new-comprehension-meaning-search-practice'}">
        <div class="user-settings-content">
          <div class="learn-index__container--item-icon">
            <i class="fas fa-book-open"></i>
          </div>
          <div class="learn-index__container--text">
            <span class="learn-index__container--text-title">
              词语理解练习
            </span>
            <br>
          </div>
        </div>
      </router-link>
      <!-- <router-link
        class="learn-index__container--item"
        :to="{name: 'comprehension-index'}">
        <div class="user-settings-content">
          <div class="learn-index__container--item-icon">
            <i class="fas fa-book-open"></i>
          </div>
          <div class="learn-index__container--text">
            <span class="learn-index__container--text-title">
              常见词语
            </span>
            <br>
          </div>
        </div>
      </router-link>
      <router-link
        v-if="showTechniqueVideos"
        class="learn-index__container--item"
        :to="{name: 'comprehension-index'}">
        <div class="user-settings-content">
          <div class="learn-index__container--item-icon">
            <i class="fas fa-book-open"></i>
          </div>
          <div class="learn-index__container--text">
            <span class="learn-index__container--text-title">
              技巧影片
            </span>
            <br>
          </div>
        </div>
      </router-link> -->
    </div>
  </block-container>
</template>

<script>
import {mapState} from 'vuex';
// import {mapState, mapGetters, mapActions} from 'vuex';

import BlockContainerComponent
  from '../common/BlockContainerComponent.vue';

export default {
  name: 'NewComprehensionOverview',
  components: {
    BlockContainer: BlockContainerComponent,
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapState(['level']),
    showTechniqueVideos() {
      return true;
      return ['primary_two', 'primary_three', 'primary_four', 'primary_five'].indexOf(this.level) > -1;
    },
  },
  mounted() {
  },
  methods: {
  },
};
</script>


<style lang="scss" scoped>
  .learn-index {
    &__container {
      height: 100%;
      background: linear-gradient(to bottom right, #1d244dc9, #1518293c);
      padding: 2rem;
      width: 100%;
      overflow-y: auto;
      overflow-x: hidden;
      position: absolute;
      text-align: center;
      grid-gap: 1rem;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      justify-content: center;
      width: 100%;
      grid-gap: 1.5rem;
      &--item {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        background: #fff;
        border-radius: 7.5px;
        text-decoration: none;
        border: 6px solid transparent;
        transition: all 0.2s;
        position: relative;
        &:hover {
          background: #fff;
          .learn-index__container--item-icon {
            background: #fff;
            border: 6px solid #2b92f8;
            i {
              color: #2b92f8;
            }
          }
        }
        .user-settings-content {
          height: 100%;
          width: 100%;
        }
        a {
          text-decoration: none;
        }
        &-icon {
          height: 75%;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 6px solid #e9e9e9;
          margin: .5rem .5rem;
          transition: all 0.2s;
          padding: 1rem;
          border-radius: 0.5rem;
          background: #fff;
          i {
            transition: all 0.2s;
            color: #b9b6c1;
            font-size: 3.5rem;
            margin-bottom: 5px;
          }
        }
      }
      &--text {
        width: 100%;
        height: calc(25% - 1rem);
        display: flex;
        justify-content: center;
        align-items: center;
        &-title {
          font-weight: 600;
          font-size: 1rem;
          color: #4e4e54;
        }
      }
    }
  }

  .num-words {
    font-size: 0.8em;
  }

  @media only screen and (max-width: 1560px) {
    .learn-index__container {
      grid-template-columns: 1fr 1fr;
    }
  }
  @media only screen and (max-width: 1366px),
              screen and (max-height: 870px) {
    .learn-index {
      &__container {
        padding: 1.5rem;
        grid-gap: 1.25rem;
        &--item {
          padding: .25rem;
          &-icon {
            padding: .75rem;
            i {
              font-size: 3rem;
            }
          }
        }
        &--text {
          &-title {
            font-size: .9rem;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 1150px),
  screen and (max-height: 690px) {
  }
  @media only screen and (max-width: 960px),
  screen and (max-height: 620px) {
    .learn-index {
      &__container {
        background: none;
        width: 100%;
        height: 100%;
      }
    }
  }
  @media only screen and (max-width: 690px) {
    .learn-index__container {
      grid-template-columns: 1fr;
    }
  }
  @media only screen and (max-width: 580px) {
    .learn-index__container {
      grid-gap: 1rem;
    }
  }
  @media only screen and (max-width: 480px) {
    .learn-index__container--item-icon {
      padding: .5rem;
      i {
        font-size: 2.5rem;
      }
    }
    .learn-index__container--text-title {
      font-size: .8rem;
    }
  }

  .custom-icon--1 {
    font-size: 2rem;
    color: #fff;
    display: inline-block;
    transition: all 0.2s;
    span:first-of-type {
      font-family: 'ZCOOL KuaiLe', cursive;
      background: #aaa;
      margin-right: 3px;
      padding: 10px;
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
    }
    span:last-of-type {
      font-family: 'ZCOOL KuaiLe', cursive;
      background: #aaa;
      margin-left: 3px;
      padding: 10px;
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
    }
  }

  .custom-icon--2 {
    font-size: 1.25rem;
    color: #fff;
    display: inline-block;
    width: 180px;
    transition: all 0.2s;
    span {
      display: inline-block;
      min-width: 1.5rem;
      background: #aaa;
      margin-right: 3px;
      border-radius: 6px;
    }
    span:last-of-type {
      margin-right: 0;
      margin-top: 10px;
    }
  }
</style>
