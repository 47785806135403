<template>
  <div :style='cssProps'
    class="frame__component">
    <div class="frame__bevel">
      <div class="frame__color">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'FrameComponent',
  components: {
  },
  props: {
    // thin, normal, or thick
    frameSize: String,
    // yellow, purple
    frameColor: String,
  },
  data() {
    return {
      baseColor: '',
      bevelColor: '',
      highlightColor: '',
      shadowColor: '',
      borderSize: '',
      blackFrameSize: '',
      bevelSize: '',
    };
  },
  computed: {
    cssProps() {
      return {
        '--base-color': this.baseColor,
        '--bevel-color': this.bevelColor,
        '--highlight-color': this.highlightColor,
        '--shadow-color': this.shadowColor,
        '--black-frame-size': this.blackFrameSize + 'px',
        '--border-size': this.borderSize + 'px',
        '--bevel-size': this.bevelSize + 'px',
      };
    },
  },
  mounted: function() {
    this.setColor(this.frameColor);
    this.setBorderSize(this.frameSize);
  },
  methods: {
    setColor(color) {
      if (color == 'yellow') {
        this.baseColor = '#ffd22e';
        this.bevelColor= '#f9a800';
        this.highlightColor= '#ffbd29';
        this.shadowColor= '#ff9700';
      } else {
        this.baseColor = '#000';
        this.bevelColor= '#fff';
        this.highlightColor= '#fff';
        this.shadowColor= '#fff';
      }
    },
    setBorderSize(size) {
      if (size == 'thin') {
        this.borderSize = '8';
        this.blackFrameSize = '4';
        this.bevelSize = '3';
      } else if (size == 'thick') {
        this.borderSize = '12';
        this.blackFrameSize = '8';
        this.bevelSize = '6';
      } else {
        this.borderSize = '4';
        this.blackFrameSize = '6';
        this.bevelSize = '8';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .frame {
    &__component {
      border: var(--black-frame-size) solid #000;
      border-radius: 10px;
    }
    &__bevel {
      border: var(--bevel-size) solid var(--bevel-color);
      border-top-color: var(--highlight-color);
      border-bottom-color: var(--shadow-color);;
      border-radius: 4px;
    }
    &__color {
      border: var(--border-size) solid var(--base-color);
      display: inline-flex;
    }
  }
</style>
