<template>
  <div v-if="currentStory" id="main" class="story-container">
    <!-- Video Player -->
    <div class="video-player-wrapper">
      <div>
        <video-player
        :code="videoCode"
        :play-callback="playCallback"></video-player>
      </div>
    </div>

    <!-- The Modal -->
    <div
      v-if="showWordVideoModal && sproutWordVideoSrc !== ''"
      id="myModal" class="modal">
      <!-- Modal content -->
      <div class="modal-content">
        <span @click="closeWordVideoModal" class="close">&times;</span>
        <iframe class='sproutvideo-player'
          :src="sproutWordVideoSrc"
          width='630' height='473' frameborder='0' allowfullscreen></iframe>
      </div>
    </div>

    <div class="content-container row">
      <div class="story-desc-container">
        <div class="story-title-container">
          <div class="story-title">
            <h2 class="chinese-character">{{ currentStory.chineseTitle }}</h2>
            <p>
              {{ getStoryarcById(currentStory.storyarc)['title'] }}
              <span>|</span>Episode {{ currentStory.index + 1 }}<span>|</span>
              <rating class="rating-star-container"
                image="star"
                :current-score="stars"
                :max-score=3>
              </rating>
            </p>
          </div>
        </div>
        <div class=toggle-options>
          <div
            v-if="hasNoSubtitlesOption"
            id="subtitle-toggle"
            class="tap-btn-highlight"
            @click="toggleSubtitles">
            <span class="checkbox-label">Subtitles:</span>
            <!-- stop propagation on the child element,
            as it will be caught by div anyway -->
            <input
              id="switch-subtitles"
              type="checkbox"
              :checked="englishSubtitlesForLibrary"
              @click.stop=""
            />
            <label for="switch-subtitles">
              <div class="switch-btn"></div>
            </label>
          </div>
          <div
            v-if="hasDifficultyOption"
            id="difficulty-toggle"
            class="tap-btn-highlight"
            @click.prevent="toggleDifficulty">
            <span class="checkbox-label">Hard Mode:</span>
            <input
              id="switch-difficulty"
              type="checkbox"
              :checked="currentStory.difficulty !== 'normal'"
              />
            <label for="switch-difficulty">
              <div class="switch-btn"></div>
            </label>
          </div>
        </div>
        <!-- <label for="test-checkbox">test</label> -->
        <div class="story-desc">
          <h2>story summary:</h2>
          <p>{{ currentStory.description }}</p>
        </div>
        <div class="story-nav-container">
          <router-link
            :to="{name: 'library-storyarc-main',
            params: {storyarcId: currentStory.storyarc}}">
            <i class="fas fa-list-ul"></i>View Episodes
          </router-link>
          <div class="episode-nav-container">
            <library-story-arrow
              v-if="finishedFetching"
              :previous-arrow="true"
              :link-to-story="currentStory.storyLinks.previous">
            </library-story-arrow>
            <library-story-arrow
              v-if="finishedFetching"
              :previous-arrow="false"
              :link-to-story="currentStory.storyLinks.next">
            </library-story-arrow>
          </div>
        </div>
      </div>

      <div class="vocab-container">
        <h2><i class="fas fa-book"></i> lesson keywords</h2>
        <word-list
          :words-to-recognise="currentStory.wordsToRecognise"
          :manifest="manifest"
          @show-word-video="showWordVideo"
        >
        </word-list>
        <a v-if="currentStory.worksheet"
          :href="currentStory.worksheet" target="_blank" class="quiz-btn">
          <i class="fas fa-file-alt"></i>
          Worksheet
        </a>
        <router-link
          v-if="currentStory.chineseQuizPk > -1"
          class="quiz-btn"
          :to="{name: 'learning-track-quiz',
          params: {quizId: currentStory.chineseQuizPk}}">
          take the quiz!
        </router-link>

        <!-- if not seen before, add a blinking icon-->
        <!-- :class="{flashing: showUnseenMemoryGameNotification}" -->
        <router-link
          v-if="currentStory.chineseQuizPk > -1"
          class="quiz-btn"
          :to="{name: 'game-flashcard-memory',
          params: {quizId: currentStory.chineseQuizPk}}">
          Play a Game!
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import {mapState, mapMutations, mapGetters, mapActions} from 'vuex';

import VideoPlayerComponent from '../common/VideoPlayerComponent.vue';
import RatingComponent from '../common/RatingComponent.vue';
import WordListComponent from '../common/WordListComponent.vue';
import LibraryStoryArrowComponent from './LibraryStoryArrowComponent.vue';

import {utilities} from '../../mixins/utilities';

export default {
  name: 'LibraryStory',
  components: {
    Rating: RatingComponent,
    VideoPlayer: VideoPlayerComponent,
    LibraryStoryArrow: LibraryStoryArrowComponent,
    WordList: WordListComponent,
  },
  mixins: [utilities],
  props: {
    storyId: Number,
  },
  data() {
    return {
      // have we fetched data for stories
      // if not, disable navigation buttons
      finishedFetching: false,
      showWordVideoModal: false,
      sproutWordVideoSrc: '',
    };
  },
  computed: {
    ...mapState(['englishSubtitlesForLibrary', 'chineseQuizStars']),
    ...mapState('library', ['storiesInCurrentStoryarc', 'latestStory']),
    ...mapGetters('library', ['getStoryarcById', 'getStoryById']),
    currentStory() {
      return this.getStoryById(this.storyId);
    },
    stars() {
      return this.chineseQuizStars[this.currentStory.chineseQuizPk];
    },
    // if there is no no_subtitles video code, then we shouldn't show
    hasNoSubtitlesOption() {
      if (!this.currentStory) return;

      return this.currentStory.videoCodes['no_subtitles'].code !== '';
    },
    hasDifficultyOption() {
      if (!this.currentStory) return;

      return this.currentStory.condition_to_unlock_challenge !== 'locked';
    },
    videoCode() {
      if (!this.currentStory) return;

      // if user wants no stubtitles and code exists, return it
      if (!this.englishSubtitlesForLibrary && this.hasNoSubtitlesOption) {
        // this was legacy code for wistia
        // return this.currentStory.videoCodes['no_subtitles'].code;

        return [this.currentStory.videoCodes['no_subtitles'].sprout_video_id,
          this.currentStory.videoCodes['no_subtitles'].sprout_security_token];
      };
      // otherwise return eng_subtitles version by default

      // this was legacy code for wistia
      // return this.currentStory.videoCodes['eng_subtitles'].code;
      return [this.currentStory.videoCodes['eng_subtitles'].sprout_video_id,
        this.currentStory.videoCodes['eng_subtitles'].sprout_security_token];
    },
    manifest() {
      if (this.currentStory && this.currentStory.manifest) {
        return JSON.parse(this.currentStory.manifest);
      };

      return [];
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler(to, from) {
        if (to && to.name === 'library-story') {
          this.scrollToTop('#main');

          // if user clicked the latest story button, then need to pull
          // remainder of stories from latest story arc
          // from server (latest story is loaded initially)
          // but not the remainder of the stories
          // we need to also make sure that the stories are
          // from the correct storyarc
          if (this.storiesInCurrentStoryarc.length === 0 ||
            this.storiesInCurrentStoryarc[0].storyarc !==
            this.currentStory.storyarc) {
            // fetch data
            // not the best code -> pulls storyarc data based off story id
            // as if user directly accesses the story
            // this.currentStory.storyarc is not available yet
            this.setShowGlobalLoadingScreen(true);
            axios
                .get('/library/api/storyarc/story_id/' + this.storyId + '/')
                // .get('/library/api/storyarc/' + this.currentStory.storyarc)
                .then((response) => {
                  this.finishedFetching = true;
                  this.setStoriesInCurrentStoryarc(response.data);
                  this.setShowGlobalLoadingScreen(false);
                });
          } else {
            // don't need to fetch story data
            this.finishedFetching = true;
            this.setShowGlobalLoadingScreen(false);
          }
        };
      },
    },
  },
  mounted: function() {
    console.log(this.currentStory);
  },
  methods: {
    ...mapMutations(['setEnglishSubtitlesForLibrary', 'setShowGlobalLoadingScreen']),
    ...mapMutations('library', ['setStoriesInCurrentStoryarc']),
    ...mapActions('library', ['updateStory']),
    // if story is not read yet, mark as read once played
    playCallback() {
      // we cannot use currentStory directly
      // because if it's latest story, it will update this.latestStory
      // not the element in this.storiesInCurrentStoryarc
      // (which is was is shown in storyarc main)
      const story = this.storiesInCurrentStoryarc.find((story) => {
        return this.storyId === story.pk;
      });

      // only update book as marked if it's not been read yet
      if (story.read) return;

      console.log('callback on play');
      // marks the story as read and updates both backend and stored data
      axios
          .post('/api/v1/core/readingrecord/read/', {
            storyId: this.storyId,
          })
          .then((response) => {
            console.log('book marked read');
            story.read = true;
          });
    },
    toggleSubtitles() {
      console.log('toggle subtitles');
      // marks the story as read and updates both backend and stored data
      axios
          .post('/api/v1/core/student/togglesubtitles/')
          .then((response) => {
            this.setEnglishSubtitlesForLibrary(
                !this.englishSubtitlesForLibrary);
          });
    },
    toggleDifficulty() {
      console.log('toggle difficulty');
      // prevents toggling of difficulty if challenge version doesn't exist
      if (!this.hasDifficultyOption) return;
      const story = this.currentStory;

      if (story.condition_to_unlock_challenge === 'three_stars_on_quiz'
        && story.difficulty === 'normal' && this.stars < 3) {
        alert('Get three stars on normal mode to unlock hard mode.');
        return;
      };
      // marks the story as read and updates both backend and stored data
      axios
          .post('/api/v1/core/readingrecord/toggledifficulty/', {
            storyId: this.storyId,
          })
          .then((response) => {
            this.updateStory(response.data.story);
          });
    },
    // modal should be closed when click on close button or outside of modal
    closeWordVideoModal(event) {
      this.showWordVideoModal = false;
    },
    showWordVideo(data) {
      const baseUrl = 'https://videos.sproutvideo.com/embed/';
      this.sproutWordVideoSrc = baseUrl + data[0] + '/' + data[1];
      this.showWordVideoModal = true;
    },
  },
};
</script>

<style lang="scss" scoped>
  .story-container {
    padding-bottom: 100px;
  }
  // Toggle
  .toggle-options {
    position: absolute;
    top: 35px;
    right: 10px;
  }
  #subtitle-toggle,
  #difficulty-toggle {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    span {
      margin-right: 6px;
      font-size: 14px;
      font-weight: bold;
    }
  }

  .switch-btn {
    position: absolute;
    top: 2.5px;
    left: 3px;
    width: 20px;
    height: 20px;
    background: #fff;
    border-radius: 90px;
    transition: 0.3s;
  }

  #switch-subtitles:checked + label {
    background: #5ae6c2;
  }
  #switch-difficulty:checked + label {
    background: #ea3d8a;
  }

  input:checked + label .switch-btn {
    left: calc(100% - 3px);
    transform: translateX(-100%);
  }

  label:active .switch-btn {
    width: 25px;
  }

  // Others
  .story-container {
    background: #fff;
  }

  .story-desc-container {
    margin-right: 2rem;
    position: relative;
  }
  .video-player-wrapper {
    background: #000;
    display: block;
    div:first-child {
      max-width: 780px;
      max-height: 585px;
      margin: auto;
    }
  }
  .story-title-container {
    display: flex;
    margin-top: 30px;
    .story-title {
      flex: 1;
      h2 {
        font-size: 1.75rem;
        margin-bottom: .5rem;
        color: #222;
      }
      p {
        font-size: 0.8rem;
        font-weight: bold;
        padding-bottom: .4rem;
        border-bottom: 1px solid #eee;
        span {
          margin: 0 8px;
        }
      }
    }
  }
  .story-desc {
    margin-top: .8rem;
    font-size: .8rem;
    padding-bottom: .8rem;
    border-bottom: 1px solid #eee;
    line-height: 1.2rem;
    // height: 10vh;
    h2{
      text-transform: uppercase;
      font-size: .8rem;
      color: #222;
    }

    p {
      margin-left: .5rem;
    }
  }
  .rating-star-container {
    display: inline-block;
  }

  .content-container {
    display: grid;
    grid-template-columns: 3fr 1fr;
  }

  .story-nav-container {
    display: flex;
    align-items: flex-start;
    margin: 30px 0;
    a:link,
    a:visited {
      background: #ececec;
      border: 1px solid #cacaca;
      padding: 10px 30px;
      color: #777;
      margin-right: auto;
      text-decoration: none;
      font-weight: bolder;
      transition: all 0.2s;
      i {
        margin-right: 10px;
      }
    }
    a:hover,
    a:active {
      color: #fff;
      background-color: #bd279b;
      border: 1px solid #bd279b;
    }
  }

  .episode-nav-container {
    display: flex;
  }
  .vocab-container {
    margin: 30px 0;
    h2 {
      text-transform: capitalize;
      font-size: 120%;
      padding-bottom: 5px;
      border-bottom: 1px solid #eee;
    }
  }
  .quiz-btn:link,
  .quiz-btn:visited {
    width: 100%;
    display: inline-block;
    text-decoration: none;
    color: #777;
    text-transform: capitalize;
    background: #ececec;
    border: 1px solid #cacaca;
    padding: 10px 0;
    text-align: center;
    font-weight: bolder;
    transition: all 0.2s;
    margin-bottom: 10px;
  }
  .quiz-btn:hover,
  .quiz-btn:active {
    color: #fff;
    background-color: #bd279b;
    border: 1px solid #bd279b;
  }

  @media only screen and (max-width: 1200px) {
    .content-container {
      margin: 0 20px;
    }
  }
  @media only screen and (max-width: 1023px) {
    .content-container {
      margin-bottom: 50px;
    }
    .episode-nav-container {
      flex-direction: column;
    }
    .story-desc-container {
      margin-right: 1rem;
    }
  }
  @media only screen and (max-width: 800px) {
    .episode-nav-container {
      flex-direction: row;
      width: 100%;
    }
    .story-nav-container {
      flex-direction: column;
      a:link,
      a:visited {
        background: #fff;
        width: 100%;
        text-align: center;
        margin-bottom: 8px;
      }
      a:hover,
      a:active {
        color: #fff;
        background-color: #bd279b;
        border: 1px solid #bd279b;
      }
    }
    .toggle-options {
      position: relative;
      top: 0;
      left: 0;
      padding-bottom: 10px;
      border-bottom: 1px solid #eee;
    }
    #subtitle-toggle,
    #difficulty-toggle {
      margin-top: 10px;
      &:not(:last-child) {
        margin-right: 20px;
      }
      display: inline-flex;
      justify-content: flex-start;
    }
  }
  @media only screen and (max-width: 745px) {
    .content-container {
      display: block;
      margin-bottom: 68px;
    }
    .story-desc-container {
      margin-right: 0;
    }
    .story-desc {
      height: auto;
    }
  }
  @media only screen and (max-width: 480px) {
    .story-nav-container,
    .quiz-btn {
      font-size: 80%;
    }
    .story-nav-container {
      margin: 10px 0;
    }
    .story-title-container {
      .story-title {
        h2 {
          font-size: 1.5rem;
        }
        p {
          font-size: 0.7rem;
        }
      }
    }
    .story-desc {
      font-size: .7rem;
      h2 {
        font-size: 0.7rem;
      }
    }
  }
  @media only screen and (max-width: 400px) {
    .story-title-container {
      margin-top: 20px;
    }
    .episode-nav-container {
      flex-direction: column;
    }
  }
  @media only screen and (max-height: 700px) {
    .video-player-wrapper {
      div:first-child {
        max-width: calc((100vh - 114px) / 3 * 4);
      }
   }
  }
  @media only screen and (max-width: 850px) and (max-height: 450px) {
    .video-player-wrapper {
      width: calc((100vh - 114px) / 3 * 4);
      position: fixed;
      background: #fff;
      div:first-child {
        max-width: calc((100vh - 114px) / 3 * 4);
        margin: 0;
      }
    }
    .content-container {
      display: block;
      margin-left: 100vh;
    }
    .story-desc-container {
      display: inline-block;
      margin: 0;
      font-size: 90%;
    }
    .story-title-container {
      margin-top: 3.5vh;
      .story-title {
        h2 {
          font-size: 1.5rem;
        }
        p {
          font-size: 0.7rem;
        }
      }
    }
    .toggle-options {
      position: relative;
      top: 0;
      left: 0;
      padding-bottom: 10px;
      border-bottom: 1px solid #eee;
    }
    #subtitle-toggle,
    #difficulty-toggle {
      margin-top: 10px;
      &:not(:last-child) {
        margin-right: 15px;
      }
      display: inline-flex;
      justify-content: flex-start;
    }

    .story-desc {
      font-size: .7rem;
      margin-top: .5rem;
      padding-bottom: .5rem;
      h2 {
        font-size: .7rem;
      }
    }
    .story-nav-container {
      flex-direction: column;
      margin-top: 10px;
      margin-bottom: 0;
      a:link,
      a:visited {
        font-size: 85%;
        background: #fff;
        width: 100%;
        text-align: center;
        margin-bottom: 8px;
      }
      a:hover,
      a:active {
        color: #fff;
        background-color: #bd279b;
        border: 1px solid #bd279b;
      }
    }
    .episode-nav-container {
      flex-direction: column;
      width: 100%;
      div:last-of-type {
        margin-left: auto;
      }
    }

    .vocab-container {
      margin-top: 15px;
    }
  }
  @media only screen and (max-width: 666px) and (max-height: 321px) {
    .content-container {
      margin-left: 92.5vh;
    }
  }

  input[type=checkbox]{
    height: 0;
    width: 0;
    visibility: hidden;
  }

  label {
    cursor: pointer;
    width: 45px;
    height: 25px;
    background: grey;
    display: inline-block;
    border-radius: 100px;
    position: relative;
    margin-top: 3.4px;
  }
  .flashing {
    animation: 3s infinite flash-anim;
    background: #bd279b !important;
    color: #fff !important;
  }

  @keyframes flash-anim {
    0% {
      transform: scale(1);
    }
    10% {
      transform: scale(1.1);
    }
    25% {
      transform: scale(1);
    }
    40% {
      transform: scale(1.1);
    }
    60% {
      transform: scale(1);
    }
    100% {
      transform: scale(1);
    }
  }

  /* The Modal (background) */
  .modal {
    display: block; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
  }

  /* Modal Content/Box */
  .modal-content {
    background-color: #fefefe;
    margin: 15% auto; /* 15% from the top and centered */
    padding: 20px;
    border: 1px solid #888;
    width: 80%; /* Could be more or less, depending on screen size */
  }

  /* The Close Button */
  .close {
    color: #aaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
  }

  .close:hover,
  .close:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }

</style>
