<template>
  <div class="popup__component animated fadeIn faster">
    <div class="popup__component--container animated bounceIn fast">
      <i class="fas fa-times"
        @click="closePopup"
      ></i>
      <div class="popup__component--content">
        <i class="popup__component--icon fas fa-arrow-alt-circle-up"></i>
        <h2 class="popup__component--text-header">
          Upgrade to Premium!
        </h2>
        <p class="popup__component--text-desc">
          Sign up for a premium account to access lessons from other weeks!
        </p>
      </div>
      <div
        class="popup__component--btn"
        @click="closePopup">
        <p>Return</p>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'PopupComponent',
  components: {
  },
  props: {
  },
  data() {
    return {
    };
  },
  computed: {
  },
  mounted: function() {
  },
  methods: {
    closePopup() {
      this.$emit('close-popup');
    },
  },
};
</script>

<style lang="scss" scoped>
  .popup__component {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 30;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    &--container {
      position: relative;
      width: 20rem;
      background: #fff;
      border-radius: 3px;
      .fa-times {
        cursor: pointer;
        position: absolute;
        top: -.5rem;
        right: -.5rem;
        width: 1.75rem;
        height: 1.75rem;
        border-radius: 50%;
        background: #ff3c3c;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 700;
        font-size: 1.2rem;
      }
    }
    &--content {
      padding: 2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      text-align: center;
    }
    &--icon {
      font-size: 7rem;
      margin-bottom: 1rem;
      color: #3088e6;
    }
    &--text{
      &-header {
        padding-bottom: 0.5rem;
        margin-bottom: 0.5rem;
        border-bottom: 2px solid #e9e9e9;
      }
      &-desc {
        max-width: 275px;
        font-style: italic;
        font-size: 1rem;
        margin-bottom: 1.75rem;
      }
    }
    &--btn {
      cursor: pointer;
      color: #fff;
      position: absolute;
      width: 100%;
      bottom: 0;
      background: #3088e6;
      padding: 0.5rem;
      border-bottom-left-radius: 3px;
      border-bottom-right-radius: 3px;
      text-align: center;
      font-weight: 700;
    }
  }

  @media only screen and (max-height: 600px) {
    .popup__component--container {
      width: 17.5rem;
    }
  }
  @media only screen and (max-height: 515px) {
    .popup__component--container {
      width: 15rem;
    }
  }
  @media only screen and (max-width: 450px) {
    .popup__component--container {
      width: 17.5rem;
    }
  }
  @media only screen and (max-width: 400px) {
    .popup__component--container {
      width: 15rem;
    }
  }
</style>
