<template>
  <div class="choice-scene__component">
    <p v-if="!gameCompleted"
      class="choice-scene__text">
      {{ textToDisplay }}
    </p>
    <p
      class="choice-scene__text"
      v-if="gameCompleted">
        成功了！
    </p>
    <div class="choice-scene__btn--group">
      <div class="tangyuan-group">
        <span @click="incrementScore"
          class="tangyuan-item tangyuan-item-white"
          :style="itemStyle">
        </span>
        <span @click="incrementScore"
          class="tangyuan-item tangyuan-item-pink"
          :style="itemStyle">
        </span>
        <span @click="incrementScore"
          class="tangyuan-item tangyuan-item-white"
          :style="itemStyle">
        </span>
        <span @click="incrementScore"
          class="tangyuan-item tangyuan-item-pink"
          :style="itemStyle">
        </span>
        <span @click="incrementScore"
          class="tangyuan-item tangyuan-item-white"
          :style="itemStyle">
        </span>
        <span @click="incrementScore"
          class="tangyuan-item tangyuan-item-pink"
          :style="itemStyle">
        </span>
        <span @click="incrementScore"
          class="tangyuan-item tangyuan-item-white"
          :style="itemStyle">
        </span>
      </div>
      <div class="tangyuan-group">
        <span @click="incrementScore"
          class="tangyuan-item tangyuan-item-pink"
          :style="itemStyle">
        </span>
        <span @click="incrementScore"
          class="tangyuan-item tangyuan-item-white"
          :style="itemStyle">
        </span>
        <span @click="incrementScore"
          class="tangyuan-item tangyuan-item-pink"
          :style="itemStyle">
        </span>
        <span @click="incrementScore"
          class="tangyuan-item tangyuan-item-white"
          :style="itemStyle">
        </span>
        <span @click="incrementScore"
          class="tangyuan-item tangyuan-item-pink"
          :style="itemStyle">
        </span>
        <span @click="incrementScore"
          class="tangyuan-item tangyuan-item-white"
          :style="itemStyle">
        </span>
        <span @click="incrementScore"
          class="tangyuan-item tangyuan-item-pink"
          :style="itemStyle">
        </span>
      </div>
      <div class="tangyuan-group">
        <span @click="incrementScore"
          class="tangyuan-item tangyuan-item-white"
          :style="itemStyle">
        </span>
        <span @click="incrementScore"
          class="tangyuan-item tangyuan-item-pink"
          :style="itemStyle">
        </span>
        <span @click="incrementScore"
          class="tangyuan-item tangyuan-item-white"
          :style="itemStyle">
        </span>
        <span @click="incrementScore"
          class="tangyuan-item tangyuan-item-pink"
          :style="itemStyle">
        </span>
        <span @click="incrementScore"
          class="tangyuan-item tangyuan-item-white"
          :style="itemStyle">
        </span>
        <span @click="incrementScore"
          class="tangyuan-item tangyuan-item-pink"
          :style="itemStyle">
        </span>
        <span @click="incrementScore"
          class="tangyuan-item tangyuan-item-white"
          :style="itemStyle">
        </span>
      </div>
    </div>
  </div>
</template>

<script>

import {mapActions} from 'vuex';

export default {
  name: 'TangyuanClickerScene',
  components: {
  },
  props: {
    narrator: String,
    textToDisplay: String,
    scoreTarget: {
      type: Number,
      default: 10,
    },
    // in miliseconds
    decayRate: {
      type: Number,
      default: 400,
    },
  },
  data() {
    return {
      gameCompleted: false,
      decayInterval: null,
      // the number of times clicked
      score: 5,
    };
  },
  computed: {
    itemStyle() {
      const rotateText = 'rotate(' +
        this.score / this.scoreTarget * 360 +'deg)';
      return {
        'border-radius': this.score / this.scoreTarget * 50 + '%',
        /* IE 9 */
        '-ms-transform': rotateText,
        /* Safari 3-8 */
        '-webkit-transform': rotateText,
      };
    },
  },
  mounted() {
    // potential bug -> when we move from one clicker
    // scene to another clicker scene
    // when decayInterval is not updated
    // add code to clear interval
    this.decayInterval = setInterval(() => {
      this.decrementScore();
    }, this.decayRate);
  },
  methods: {
    ...mapActions('dynamicBook', ['goToScene']),
    incrementScore() {
      if (this.gameCompleted) return;
      this.score += 1;

      if (this.score >= this.scoreTarget) {
        this.gameCompleted = true;
        clearInterval(this.decayInterval);
      }
    },
    decrementScore() {
      if (this.score <= 0) return;
      this.score -= 1;
    },
    _goTo(nextSceneId) {
      this.goToScene(nextSceneId);
    },
  },
};
</script>

<style lang="scss" scoped>
  .choice-scene {
    &__component {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      flex-direction: column;
    }
    &__text {
      font-size: 2rem;
      color: #fff;
    }
    &__btn {
      padding: 10px 20px;
      border: none;
      border-radius: 10px;
      background: #fff;
      font-size: 1.2rem;
      cursor: pointer;
      &:not(:last-child) {
        margin-right: 20px;
      }
      &--group {
        margin-top: 40px;
      }
    }
  }

  .tangyuan-group {
    &:not(:last-child) {
      margin-bottom: 30px;
    }
  }
  .tangyuan-item {
    display: inline-block;
    width: 100px;
    height: 100px;
    &-pink {
      background: #ff4a9d;
    }
    &-white {
      background: #fff;
    }
    &:not(:last-child) {
      margin-right: 30px;
    }
  }
</style>
