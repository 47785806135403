<template>
  <div class="game-flashcard-memory__component">
    <!-- start scene -->
    <game-flash-card-memory-start
      v-if="gameStatus === 0"
      :start-game="startGame"></game-flash-card-memory-start>

    <!-- game scene -->
    <div class="game-scene__component"
      v-if="gameStatus === 1">
      <div class="game-scene__component--content">
        <i
          class="btn fas fa-chevron-left game-scene__component--close-icon"
          @click="stopAllSounds(); resetGame()"></i>
        <div class="game-scene__component--title">
          <h2>
            Level {{ this.currentLevel }}
          </h2>
          <h4>
            Score: {{ this.currentScore }}
          </h4>
        </div>
        <div class="game-scene__component--game-instructions">
          <p>
            You will hear {{ this.currentLevel }} set(s) of words.
          </p>
          <p>
            Click them in the order you hear them!
          </p>
        </div>
<!--    <p class="game-scene__component--game-seq">
          Game Sequence: {{ gameSequence }}
        </p>
 -->        <div class="game-scene__btns">
          <button v-for="word in words"
            :key="word.index"
            :class="{ 'disabled': !userTurn || !finishedPlayingQuestionSounds}"
            class="game-scene__btn"
            @click="checkUserInput(word)">{{ word }}</button>
        </div>
        <p class="game-scene__component--user-seq">
          <span v-for="word in userSequence"
          :key="word.index">
            {{ word }}
          </span>
        </p>
        <button
          v-if="levelCompleted && !disableClickingNextLevel"
          @click="levelPassed ? nextLevel() : endGame()"
          :class="{ disabled : !levelCompleted,
            'bg-wrong': !levelPassed,
            'bg-correct': levelPassed }"
          class="game-scene__btn--result">
          <span v-if="levelPassed">Next Level</span>
          <span v-else="">Game Over</span>
        </button>
      </div>
    </div>

    <!-- gameover/leaderboard scene -->
    <game-summary
      v-if="gameStatus === 2"
      :leaderboard-data="leaderboard"
      :student-map="studentMap"
      :current-level="currentLevel"
      :current-score="currentScore"
      :new-coins="newCoins"
      :coins-to-add="coinsToAdd"
    ></game-summary>
  </div>
</template>

<script>
import axios from 'axios';

import GameFlashCardMemoryStartComponent
  from './GameFlashCardMemoryStartComponent.vue';

import GameSummaryComponent
  from './GameSummaryComponent.vue';

import {soundMixin} from '../../mixins/sound';
import {utilities} from '../../mixins/utilities';

export default {
  name: 'GameMain',
  components: {
    GameFlashCardMemoryStart: GameFlashCardMemoryStartComponent,
    GameSummary: GameSummaryComponent,
  },
  props: {
    quizId: Number,
  },
  mixins: [soundMixin, utilities],
  data() {
    return {
      // 0 to show start menu, 1 when playing, 2 when ended
      gameStatus: 0,
      currentLevel: 0,
      currentScore: 0,
      // in miliseconds
      pauseBeforeQuestionIsPlayed: 1000,
      pauseBetweenSounds: 1000,
      maxLevel: 5,
      disableClickingNextLevel: false,
      words: [],
      // very hackish - used to force sounds to stop playing
      // we need to do this because we use recursion to play a sequence
      // of sounds
      stopSoundOrder: false,
      finishedPlayingQuestionSounds: false,
      // queue used to ensure that there will always be
      // relatively even number of words tested
      wordsQueue: [],
      sounds: [],
      manifest: {},
      gameSequence: [],
      userSequence: [],
      userTurn: false,
      levelPassed: false,
      levelCompleted: true,

      // for leaderboard
      leaderboard: [],
      studentMap: {},
      newCoins: -1,
      coinsToAdd: 0,
    };
  },
  computed: {
  },
  mounted: function() {
  },
  // if user clicks on back arrow before ending game
  // need to stop playing sounds
  beforeDestroy() {
    this.stopAllSounds();
  },
  methods: {
    resetGame() {
      this.gameStatus = 0;
      this.gameSequence = [];
      this.userSequence = [];
      this.words = [];
      this.wordsQueue = [];
      this.currentLevel = 0;
      this.currentScore = 0;
      this.userTurn = false;
      this.levelPassed = false;
      this.levelCompleted = true;
      this.disableClickingNextLevel = false;
      this.finishedPlayingQuestionSounds = false;
    },
    // loops through this.sounds object and stops playing sounds
    stopAllSounds() {
      this.stopSoundOrder = true;
      for (const key in this.sounds) {
        if (this.sounds.hasOwnProperty(key)) {
          this.sounds[key].stop();
        }
      }
    },
    // https://stackoverflow.com/questions/54867318/sequential-execution-of-promise-all
    playQuestionSound(word) {
      return () => {
        return new Promise((resolve) => {
          const key = word + '_字';
          if (this.stopSoundOrder) return;
          this.sounds[key].play();
          this.sounds[key].on('end', () => {
            resolve(word);
          });
        });
      };
    },
    // takes array of promises and current index
    // if there are still sounds to be played, move to next
    playSequenceRecursion(arr, index) {
      // hackish
      // stop playing sound if stopSoundOrder is true
      // stopSoundOrder is reset to false when we restart the game
      if (this.stopSoundOrder) {
        return;
      }

      // when we first try to play the sound, set
      // finishedPlayingQuestionSounds to false
      // after we finish playing all the sounds, then set to true
      if (index === 0) {
        this.finishedPlayingQuestionSounds = false;
      }

      // arr[index]() runs the promise to play the sound
      arr[index]()
          .then((data) => {
            // if there is another word to play, pause between words
            if (index < arr.length - 1) {
              setTimeout(() => {
                this.playSequenceRecursion(arr, index + 1);
              }, this.pauseBetweenSounds);
            } else {
              // finished playing all the sounds
              this.finishedPlayingQuestionSounds = true;
            };
          });
    },
    playGameSequence() {
      // stores a sequence of promises that plays sound
      // uses recursion to play sequence of sounds (with pause in between)
      const arrSoundPromises = [];
      for (let i = 0; i < this.gameSequence.length; i++) {
        const word = this.gameSequence[i];
        arrSoundPromises.push(this.playQuestionSound(word));
      };
      this.playSequenceRecursion(arrSoundPromises, 0);
    },
    getWordsFromBackend() {
      const url = '/api/v1/brain/chinesequiz/' + this.quizId + '/memory_game/';
      return axios
          .post(url, {})
          .then((response) => {
            // take no more than 4 words
            this.words = response.data['words'].slice(0, 4);
            // for sounds
            this.manifest = JSON.parse(response.data['manifest']);
          });
    },
    startGame() {
      // reset values first in case this game was played previously
      this.resetGame();
      // hackish
      // do not reset this.stopSoundOrder in resetGame
      // this can cause bug with sound continuing to play
      this.stopSoundOrder = false;
      this.getWordsFromBackend()
          .then((response) => {
            this.gameStatus = 1;
            // preloads sounds
            this.preload(this.manifest, (data) => {
              this.sounds = data;
              // start game only after sounds have been loaded
              this.nextLevel();
            });
          });
    },
    endGame() {
      this.stopAllSounds();
      const url = '/api/v1/brain/chinesequiz/' + this.quizId +
        '/update_leaderboards/';
      return axios
          .post(url, {score: this.currentScore})
          .then((response) => {
            console.log(response.data);
            this.studentMap = response.data['student_map'];
            // array of tuples (array in javascript)
            // tuple has 3 elements - student_rank,student_id, score)
            this.leaderboard = response.data['leaderboard'];
            this.gameStatus = 2;
            this.newCoins = response.data['coins'];
            this.coinsToAdd = response.data['coins_to_add'];
          });
    },
    nextLevel() {
      // we only want the user to click on next level once
      if (this.disableClickingNextLevel) return;
      this.disableClickingNextLevel = true;

      this.currentLevel++;
      this.userSequence = [];
      this.updateGameSequence();

      setTimeout(() => {
        this.playGameSequence();
        this.toggleUserTurn();
        this.toggleLevelCompleted();
        this.disableClickingNextLevel = false;
      }, this.pauseBeforeQuestionIsPlayed);
    },
    updateGameSequence() {
      // const newWord = this.randomWord();
      const newWord = this.weightedRandomWord();
      this.gameSequence.push(newWord);
    },
    randomWord() {
      // use this.words.length rather than hardcoding 4
      const randomIndex = Math.floor(Math.random() * this.words.length);
      return this.words[randomIndex];
    },
    // returns a random word, but ensures that each word is tested
    weightedRandomWord() {
      // if wordsQueue is empty, add a copy of each word to the queue
      // use for loop instead of slice() to avoid vue reactivity issues
      if (this.wordsQueue.length === 0) {
        for (let i = 0; i < this.words.length; i++) {
          this.wordsQueue.push(this.words[i]);
        };
        this.shuffleArray(this.wordsQueue);
      };

      return this.wordsQueue.pop();
    },
    checkUserInput(word) {
      // pass value as argument rather than using innerhtml
      this.userSequence.push(word);

      // if we want sound to play on clicking button, uncomment
      // ensures other sounds stop playing
      // this.stopAllSounds();
      // this.sounds[word + '_字'].play();

      const sequenceIndex = this.userSequence.length - 1;
      if (this.userSequence[sequenceIndex] ===
          this.gameSequence[sequenceIndex]) {
        // add 1 to current score for every correct word
        this.currentScore++;
        // completion of current level
        if (this.userSequence.length === this.gameSequence.length) {
          // plays different correct sound depending on level
          let soundToPlay;
          if (this.currentLevel % 10 === 0) {
            soundToPlay = 'perfect';
          } else if (this.currentLevel % 3 === 0) {
            soundToPlay = 'correct_2';
          } else if (this.currentLevel % 5 === 0) {
            soundToPlay = 'correct_3';
          } else {
            soundToPlay = 'correct';
          }

          this.sounds[soundToPlay].play();
          return this.levelResult(true);
        }
      } else {
        this.sounds['wrong'].play();
        return this.levelResult(false);
      };
    },
    levelResult(passed) {
      this.toggleUserTurn();
      this.toggleLevelCompleted();
      this.levelPassed = passed;
    },
    toggleUserTurn() {
      this.userTurn = !this.userTurn;
    },
    toggleLevelCompleted() {
      this.levelCompleted = !this.levelCompleted;
    },
  },
};
</script>

<style lang="scss" scoped>
  .game-flashcard-memory {
    &__component {
      height: 100vh;
      width: 100%;
      background: #fff;
      z-index: 30;
      position: absolute;
      top: 0;
      left: 0;
      &--close {
        position: absolute;
        top: 30px;
        right: 30px;
        &-icon {
          color: #cccccc;
          font-size: 2rem;
        }
      }
    }
  }
  /* Start Scene */
  .start-scene {
    &__component {
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      &--content {
        margin-bottom: 3rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      &--logo {
        height: 75px;
        width: 75px;
        margin: 5px;
        &:nth-child(1) {
          border-top-left-radius: 50%;
          background: rgb(255, 192, 98);
        }
        &:nth-child(2) {
          border-top-right-radius: 50%;
          background: rgb(255, 125, 138);
        }
        &:nth-child(3) {
          border-bottom-left-radius: 50%;

          background: rgb(164, 138, 255);
        }
        &:nth-child(4) {
          border-bottom-right-radius: 50%;

          background: rgb(80, 241, 144);
        }
        &--container{
          display: flex;
          justify-content: center;
          width: 225px;
          flex-wrap: wrap;
          margin-bottom: 20px;
        }
      }
    }
    &__btn--start {
      margin-top: 10px;
      background: #00b3ec;
      padding: 0.75rem 1.5rem;
      border-radius: 15px;
      color: #fff;
      font-size: 1rem;
      font-weight: bold;
    }
  }
.start-scene__btns {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.start-scene__title {
  &--main {
    margin-bottom: 0.3rem;
  }
  &--sub {
    margin-bottom: 1rem;
    font-weight: 300;
  }
}


/* GameScene */
.game-scene__component {
  height: 100vh;
  &--close-icon {
    color: #cccccc;
    position: absolute;
    left: 30px;
    top: 30px;
    font-size: 2rem;
  }
}
.game-scene__component--content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.game-scene__btns {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin: 0 10px;
}

.game-scene__btn {
  flex: 1;
  padding: 60px 50px;
  font-size: 200%;
  border-radius: 5px;
  cursor: pointer;
  opacity: 0.5;
  margin: 10px;
  white-space: nowrap;
  transition: all 0.2s;
  &:hover {
    opacity: 1;
  }
  &:nth-child(1) {
    background: rgb(255, 192, 98);
  }
  &:nth-child(2) {
    background: rgb(255, 125, 138);
  }
  &:nth-child(3) {
    background: rgb(164, 138, 255);
  }
  &:nth-child(4) {
    background: rgb(80, 241, 144);
  }
}

.game-scene__component--title {
  margin-bottom: 1rem;
  text-align: center;
  h2 {
    margin-bottom: 0.2rem;
  }
}

.game-scene__component--game-instructions {
  margin-bottom: 0.5rem;
  text-align: center;
  border-top: 2px solid #eee;
  padding: 1rem;
}
.game-scene__component--user-seq {
  margin-top: 1rem;
  margin-bottom: 2rem;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  span {
    border-radius: 5px;
    background: #f8f8f8;
    padding: 5px 10px;
    border: 2px solid #ccc;
    margin: 10px;
  }
}
.game-scene__btn--result {
  position: absolute;
  bottom: 50px;
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  font-weight: bold;
  min-width: 200px;
  border-radius: 15px;
  color: #fff;
  cursor: pointer;
}
/* General */

.disabled {
  cursor: not-allowed;
  pointer-events: none;
  opacity: 0.15;
}

a, button, .btn {
  cursor: pointer;
}
button:focus {
  outline: none;
}

.bg-correct {
  background: #00b3ec;
}
.bg-wrong {
  background: #db6372;
}
@media only screen and (max-width: 768px) {
  .game-scene__btn {
    padding: 50px 30px;
  }
}
@media only screen and (max-width: 660px) {
  .game-scene__btn {
    padding: 20px 30px;
    font-size: 150%;
    margin: 5px;
  }
}
@media only screen and (max-width: 500px) {
  .game-scene__btn--result {
    font-size: 1rem;
    padding: 0.75rem 1.5rem;
  }
  .game-scene__component--user-seq {
    margin-bottom: 6rem;
    span {
      font-size: 0.7rem;
      margin: 5px;
    }
  }
}
@media only screen and (max-width: 350px) {
  .game-scene__btn {
    padding: 15px 25px;
    font-size: 120%;
  }
}
@media only screen and (max-width: 400px) {
  .game-scene__component {
    &--title {
      font-size: 1.3rem;
      margin-bottom: 0.75rem;
    }
    &--game-instructions {
      font-size: 0.8rem;
      margin-bottom: 0.2rem;
      padding: 0.65rem;
    }
    &--user-seq {
      margin-top: 0.2rem;
    }
  }
  .game-scene__btn--result {
    font-size: 0.75rem;
    padding: 0.5rem 1.25rem;
  }
}
</style>
