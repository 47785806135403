<template>
  <span @click="select" class="entity chinese-character"
  :class="{
    selected: mode ==='selection' && isSelected,
    correct: mode === 'showAnswer' && info.text === answer,
    wrong: mode === 'showAnswer' && isSelected && info.text !== answer}"
  >{{ info.text }}</span>
</template>

<script>

// specialised component that's for meaning search
// some behavior that is different from the usual passage entity
import {mapState, mapMutations} from 'vuex';

export default {
  name: 'MeaningSearchEntity',
  components: {
  },
  props: {
    info: Object,
    // selection, showAnswer
    mode: String,
    answer: String,
    index: {
      type: Number,
      default: -1,
    },
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapState(['studentId']),
    ...mapState('guidedReading', ['selectedEntity', 'comprehensionId']),
    isSelected() {
      if (this.info['type'] === 'particle' || !this.selectedEntity) return false;
      // unlike comprehension passage entity, we only highlight the entity with the same text and index
      return this.selectedEntity['text'] === this.info['text'] && this.selectedEntity['index'] === this.index;
    },
  },
  mounted() {
  },
  methods: {
    ...mapMutations('guidedReading', ['setSelectedEntity']),
    select() {
      if (this.info['type'] === 'particle') return;
      console.log(this.answer, this.answer === this.info.text);
      this.setSelectedEntity({...this.info, index: this.index});
    },
  },
};
</script>

<style lang="scss" scoped>
.entity {
  cursor: pointer;
}

.selected {
  background: #1DD2FF;
  border-radius: 0.3em;
  padding: 0.2rem 0;
}

.correct {
  background: #1DFFCA;
  border-radius: 0.3em;
  padding: 0.2rem 0;
}

.wrong {
  background: #FF5566;
  border-radius: 0.3em;
  padding: 0.2rem 0;
}
</style>
