<template>
  <div class="popup-notification__component animated fadeIn faster">
    <div class="popup-notification__container animated bounceIn faster">
      <div
        v-for="(quiz, index) in quizzes" :key="index"
        class="item-icon">
        <router-link
          class="text-black"
          :to="{name: 'oral-new-passage',
          params: {
            type: 'textbookchapter',
            entityId: quiz.id,
          }}">
          <i class="fas fa-comment-alt icon-main"></i>
          练习 {{ quiz.part_number + 1 }}
        </router-link>
      </div>
      <i
        @click="closePopup"
        class="fas fa-times popup-notification__close--icon"></i>
    </div>
  </div>
</template>


<script>
// import axios from 'axios';
// import {mapState} from 'vuex';

export default {
  name: 'QuizConfirmationPopup',
  components: {
  },
  props: {
    quizzes: Array,
  },
  data() {
    return {
    };
  },
  computed: {
    // ...mapState('library', ['currentStoryarc']),
  },
  mounted: function() {
  },
  methods: {
    closePopup() {
      this.$emit('closePopup');
    },
  },
};
</script>

<style lang="scss" scoped>
  /* ugly; to fix; need to click on icon to move foward;  */
  .text-black {
    color: black;
  }

  .popup-notification {
    &__component {
      position: fixed;
      background: rgba(0,0,0,.85);
      height: 100vh;
      width: 100vw;
      z-index: 50;
      top: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &__container {
      position: relative;
      max-width: 450px;
      text-align: center;
      padding: 2rem 3rem;
      background: #fff;
      color: #222;
      border-radius: 5px;
      p {
        margin-bottom: 1rem;
      }
    }
    &__close {
      &--icon {
        position: absolute;
        top: 1rem;
        right: 1rem;
        cursor: pointer;
        color: #ccc;
        font-size: 1.2rem;
      }
      &--btn {
        padding: .25rem .75rem;
        border: 4px solid #000;
        background: #8131ff;
        border-radius: 5px;
        margin: 1rem auto 0 auto;
        max-width: 150px;
        color: #fff;
        font-weight: 700;
        cursor: pointer;
      }
    }
  }

  .item {
    background: #fff;
    padding: 3rem 2rem 2rem;
    color: #555;
    border-radius: 3px;
    text-decoration: none;
    border: 6px solid transparent;
    transition: all 0.2s;
    position: relative;
    a {
      color: #555;
      text-decoration: none;
    }
    &-icon {
      border-radius: 14px;
      border: 6px solid #efefef;
      padding: 20px 80px;
      margin: .5rem auto;
      transition: all 0.2s;
      width: 260px;
      margin-bottom: 1.25rem;
      i {
        transition: all 0.2s;
        color: #aaa;
        font-size: 3.5rem;
        margin-bottom: 5px;
      }
    }
  }

  /* couldnt get hover to work properly, removing it for now
  &:hover {
    padding: 20px;
    border: 6px solid #b3cdff;
    i {
      color: #6e9fec;
    }
  }
  */
</style>
