<template>
  <div class="teaching-screen-container animated pulse">
    <h2>click to hear the correct sound</h2>
    <div id="revise-word-container"
      class="tap-btn-highlight">
      <div @click.prevent="toggleWordlist"
        @click="toggleWordList"
        class="wordlist-bookmark--container"
        :class="{'wordlist-bookmark-filled': inWordlist}">
        <div class="wordlist-bookmark--toggle">
          <i class="fas" :class="{'fa-minus': inWordlist, 'fa-plus': !inWordlist}"></i>
        </div>
        <i class="wordlist-bookmark fas fa-bookmark">
        </i>
      </div>
      <div v-if="chapter" class="revise-word--header">
        <p><b>{{ chapter.level }}</b></p>
        <p>{{ chapter.number }}《{{ chapter.title }}》</p>
      </div>
      <div class="revise-word--group"
        @click.prevent="playSound">
        <p class="chinese-character revise-word">{{ word }}</p>
        <p class="hypy">
          [{{ pinyin }}] <i class="fas fa-volume-up revise-icon"></i>
       </p>
       <p v-if="modifiedExamplesentence.length > 0"
         class="chinese-character example-sentence">
         <span v-html="modifiedExamplesentence"></span>
       </p>
     </div>
     <div id="revise-word-translate">
       <p><b>Definition: </b>{{ definition }} </p>
     </div>
     <h4 v-if="showToggleOverlay" class="word-list--overlay animated fadeIn">
        <span v-if="inWordlist" class="animated fadeInUp">Added to Wordlist!</span>
        <span v-if="!inWordlist" class="animated fadeInUp">Removed from Wordlist!</span>
      </h4>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import {mapState, mapMutations} from 'vuex';

export default {
  name: 'QuizTeachingScreen',
  components: {
  },
  props: {
    word: String,
    wordId: Number,
    exampleSentence: String,
    definition: String,
    pinyin: String,
    sounds: Object,
    chapter: Object,
  },
  data() {
    return {
      showToggleOverlay: false,
    };
  },
  computed: {
    ...mapState(['studentId']),
    ...mapState('quiz', []),
    ...mapState('analytics', ['wordlistIds']),
    modifiedExamplesentence() {
      const entries = this.exampleSentence.split(this.word);
      const separator = `<span class="word-highlight">${this.word}</span>`;
      const text = entries.join(separator);
      return text;
    },
    inWordlist() {
      return this.wordId in this.wordlistIds;
    },
  },
  mounted() {
  },
  methods: {
    ...mapMutations('quiz', ['setChoiceSelected']),
    ...mapMutations('analytics', ['removeFromWordlistIds', 'addToWordlistIds']),
    playSound() {
      this.sounds[this.word + '_字'].play();
    },
    toggleWordlist() {
      try {
        // we show the frontend effect and assume it will be completed on the backend
        if (this.inWordlist) {
          this.removeFromWordlistIds(this.wordId);
        } else {
          this.addToWordlistIds(this.wordId);
        }
        this.showToggleOverlay = !this.showToggleOverlay;
        setTimeout(() => {
          this.showToggleOverlay = !this.showToggleOverlay;
        }, 2000);

        // actual backend call
        axios
            .post(`/api/v1/core/student/${this.studentId}/wordlist/`, {
              wordId: this.wordId,
              inWordlist: this.inWordlist,
              comprehensionId: null,
            });
      } catch (err) {
        console.log(err);
      }
    },
  },
};
</script>

<style lang="scss" scoped>

  .wordlist-bookmark {
    display: flex;
    justify-content: center;
    align-items: center;
    &--toggle {
      font-size: 1rem;
      margin-right: 0.5rem;
      margin-bottom: 0.25rem;
      opacity: 0;
      transition: 0.2s linear opacity;
      transition: 0.2s linear all;
      transform: translateX(1rem);
    }
    &--container {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      right: 1rem;
      top: 1rem;
      font-size: 1.25rem;
      color: #e5e5e5;
      transition: 0.2s linear all;
      cursor: pointer;
      &:hover {
        color: #ff9a00;
        .wordlist-bookmark--toggle {
          opacity: 1;
          transform: translateX(0);
        }
      }
    }
  }
  .wordlist-bookmark-filled {
    color: #ff9a00;
    &:hover {
      color: #de0101;
    }
  }
  // v-html needs to use v-deep
  ::v-deep .word-highlight {
    color: #ff6b00 !important;
    border-bottom: 2px solid #bababa;
  }

  .revise-word--header {
    padding: 0.5rem;
    margin-top: 0.5rem;
    font-size: 0.8rem;
  }

  .teaching-screen-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 140px;
  }

  .teaching-screen-container > h2 {
    text-transform: uppercase;
    font-size: 90%;
    padding: 20px;
    border-top: 2px solid #eee;
    border-bottom: 2px solid #eee;
  }

  #revise-word-container {
    border: 2px solid black;
    border-radius: 12px;
    cursor: pointer;
    text-align: center;
    margin: 16px auto;
    position: relative;
    transition: opacity 0.2s;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0.25rem 2rem;
    position: relative;
  }

  .word-list--overlay {
    position: absolute;
    height: 100%;
    width: 100%;
    background: #fff;
    border-radius: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  #revise-word-container:hover {
  }

  #revise-word-container .revise-word {
    font-size: 700%;
    padding: 20px;
  }

  .revise-word--group {
    border-bottom: 2px solid #e9e9e9;
    border-top: 2px solid #e9e9e9;
    margin-top: 1rem;
  }
  .revise-word--header {
    padding: 0.5rem;
    margin-top: 0.5rem;
    font-size: 0.8rem;
    margin-bottom: -1rem;
  }

  .revise-word + p {
    padding: 0 0 20px 0;
    font-size: 90%;
  }

  .revise-word--group {
    position: relative;
  }

  .revise-word + p i {
    position: absolute;
    margin-left: .25rem;
    font-size: 120%;
    color: #634daf;
  }
  .revise-word--group:hover {
    .revise-icon {
      color: #e035ff;
    }
  }
  #revise-word-translate {
    font-size: 80%;
    text-align: center;
    margin: 1rem 30px;
  }
  .example-sentence {
    margin-bottom: 20px;
    text-align: center;
    font-size: 1.2rem;
    border-radius: 20px;
    background: #f0f0f0;
    padding: 0.25rem 1rem;
  }

  @media only screen and (max-width: 527px) and (min-height: 400px) {
    .teaching-screen-container {
      margin-bottom: 100px;
    }

    .teaching-screen-container > h2 {
      font-size: 80%;
    }
    #revise-word-container {
      margin-top: 30px;
    }
    #revise-word-container .revise-word {
      font-size: 10vh;
    }
    .revise-word + p {
      font-size: 80%;
    }
  }
  @media only screen and (max-width: 350px) and (min-height: 400px) {
    .teaching-screen-container {
      margin-bottom: 80px;
    }

    .teaching-screen-container > h2 {
      font-size: 70%;
    }
  }

  @media only screen and (max-height: 556px) {
    .teaching-screen-container {
      margin-bottom: 60px;
    }
    #revise-word-container .revise-word {
      padding: 20px 10px 10px 10px;
    }
    .revise-word + p i {
      right: 6px;
      top: 6px;
    }
    .revise-word + p {
      padding-bottom: 10px;
    }
  }

  @media only screen and (max-height: 415px) {
    .teaching-screen-container > h2 {
      font-size: 80%;
      padding: 10px;
    }
    #revise-word-container .revise-word {
      font-size: 500%;
    }
  }
  @media only screen and (max-height: 330px) {
    #revise-word-container .revise-word {
      font-size: 350%;
    }

  }
</style>
