<template>
  <div class="fixed-component">
    <div class="scrollable-component">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>

export default {
  name: 'PetMain',
  components: {
  },
  mixins: [],
  data() {
    return {
    };
  },
  computed: {
  },
  mounted() {
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
</style>
