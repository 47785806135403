<template>
  <div class="popup-notification__component animated fadeIn faster">
    <div class="popup-notification__container animated bounceIn faster">
      <slot>Error</slot>
      <i
        @click="closePopup"
        class="fas fa-times popup-notification__close--icon"></i>
      <div
        @click="closePopup"
        class="popup-notification__close--btn">
        Close
      </div>
    </div>
  </div>
</template>

<script>
// import axios from 'axios';
// import {mapState} from 'vuex';

export default {
  name: 'PopupNotification',
  components: {
  },
  props: {
  },
  data() {
    return {
    };
  },
  computed: {
    // ...mapState('library', ['currentStoryarc']),
  },
  mounted: function() {
  },
  methods: {
    closePopup() {
      this.$emit('closePopup');
    },
  },
};
</script>

<style lang="scss" scoped>
  .popup-notification {
    &__component {
      position: fixed;
      background: rgba(0,0,0,.85);
      height: 100vh;
      width: 100vw;
      z-index: 50;
      top: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &__container {
      position: relative;
      max-width: 450px;
      text-align: center;
      padding: 2rem 3rem;
      background: #fff;
      color: #222;
      border-radius: 5px;
      p {
        margin-bottom: 1rem;
      }
    }
    &__close {
      &--icon {
        position: absolute;
        top: 1rem;
        right: 1rem;
        cursor: pointer;
        color: #ccc;
        font-size: 1.2rem;
      }
      &--btn {
        padding: .25rem .75rem;
        border: 4px solid #000;
        background: #8131ff;
        border-radius: 5px;
        margin: 1rem auto 0 auto;
        max-width: 150px;
        color: #fff;
        font-weight: 700;
        cursor: pointer;
      }
    }
  }
</style>
