<template>
  <div class="dynamic-book__main-component">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'DynamicBookMain',
  components: {
  },
  props: {
  },
  data() {
    return {
    };
  },
  computed: {
  },
  mounted: function() {
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
.dynamic-book__main-component {
}

</style>
