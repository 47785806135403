<template>
  <div id="sidebar-right">
    <div class="sidebar-right__content animated slideInRight faster"
      :class="{ 'slideOutRight': closeSidebarAnim }">
      <section class="sidebar-right__section">
        <h3>
          <i class="fas fa-school"></i>
          <span>MY DASHBOARD</span>
        </h3>
        <ul>
          <li @click="closeSidebar">
            <router-link
              class="user-settings-content"
              :to="{name: 'main-leaderboard-coin'}">
              <span>Coin Leaderboards</span>
            </router-link>
          </li>
          <li v-if="studentType === 'attending'"
            @click="closeSidebar">
            <router-link
              class="user-settings-content"
              :to="{name: 'multiplayer-room-selection'}">
              <span>Multiplayer Classroom</span>
            </router-link>
          </li>
<!--           <li @click="closeSidebar">
            <router-link
              class="user-settings-content"
              :to="{name: 'question-checker'}">
              <span>Question Checker</span>
            </router-link>
          </li> -->

          <li @click="closeSidebar" v-if="showComprehension">
            <router-link
              class="user-settings-content"
              :to="{name: 'comprehension-index',
              params: {level: this.level}
            }">
              <span>Comprehension</span>
            </router-link>
          </li>
          <li @click="closeSidebar" v-if="showOral">
            <router-link
              class="user-settings-content"
              :to="{name: 'new-oral-overview'}">
              <span>Oral Practice</span>
            </router-link>
          </li>
          <li @click="closeSidebar">
            <transition
              name="custom-classes-transition"
              enter-active-class="animated fadeIn faster"
              leave-active-class="animated fadeOut faster">
              <router-link
                v-if="showWorksheetDownload"
                class="user-settings-content"
                :to="{name: 'new-learn-worksheet-download'}">
                <span>Download Worksheet</span>
              </router-link>
            </transition>
          </li>
        </ul>
      </section>
      <section v-if="hasSiblings"
        class="sidebar-right__section">
        <h3>
          <i class="fas fa-exchange-alt"></i>
          <span>SWITCH USERS</span>
        </h3>
        <TheHeaderSwitchUser
          class="user-profiles"
          v-on:closePopup="closeSidebar">
        </TheHeaderSwitchUser>
      </section>
      <section class="sidebar-right__section">
        <h3>
          <i class="fas fa-users-cog"></i>
          <span>USER SETTINGS</span>
        </h3>
        <ul>
          <li @click="closeSidebar">
            <router-link
              class="user-settings-content"
              :to="{name: 'analytics-overview'}">
              <span>Analytics</span>
            </router-link>
          </li>
          <li @click="closeSidebar">
            <router-link
              class="user-settings-content"
              :to="{name: 'new-settings-billing-index'}">
              <span>Billing</span>
            </router-link>
          </li>
          <li @click="closeSidebar">
            <router-link
              class="user-settings-content"
              :to="{name: 'new-settings-password'}">
              <span>Change Password</span>
            </router-link>
          </li>
          <li v-if="isWebApp"
            @click="closeSidebar">
            <a
              class="user-settings-content"
              @click.prevent="resetApp">
              <span>Reset App</span>
            </a>
          </li>
          <li @click="closeSidebar">
            <a
              id="logout"
              class="user-settings-content"
              @click.prevent="logout">
              <span>Logout</span>
            </a>
          </li>
        </ul>
      </section>

      <section class="close-btn-container"
        @click="closeSidebar">
        <i class="fas fa-times"></i>
      </section>
    </div>
    <div @click="closeSidebar"
      class="sidebar-right__bg"></div>
  </div>
</template>

<script>

import {mapState, mapActions, mapGetters} from 'vuex';
import TheHeaderSwitchUserComponent from './TheHeaderSwitchUserComponent.vue';

export default {
  name: 'SidebarRight',
  components: {
    TheHeaderSwitchUser: TheHeaderSwitchUserComponent,
  },
  props: {
  },
  data() {
    return {
      closeSidebarAnim: false,
      hasSiblings: true,
    };
  },
  computed: {
    ...mapState(['isWebApp', 'level', 'studentType', 'studentId']),
    ...mapGetters(['isDiamond']),
    // only show worksheet download if P1-5; no P6 yet
    showWorksheetDownload() {
      if (this.level === 'primary_one' || this.level === 'primary_two' ||
        this.level === 'primary_three' || this.level === 'primary_four' ||
        this.level === 'primary_five' || this.level === 'primary_six') {
        return true;
      }
      return false;
    },
    showComprehension() {
      return this.level === 'primary_two' || this.level === 'primary_three' || this.level === 'primary_four' || this.level === 'primary_five' || this.level === 'primary_six';
    },
    // oral only for primary school
    showOral() {
      return ['primary_one', 'primary_two', 'primary_three', 'primary_four', 'primary_five', 'primary_six'].indexOf(this.level) > -1;
    },
    // KIV - just a route for staff to check qustions
    showQuestionChecker() {
      // local
      return this.studentId === 1;
    },
  },
  mounted: function() {
  },
  methods: {
    closeSidebar() {
      this.closeSidebarAnim = true;
      setTimeout(()=> this.$emit('close-sidebar'), 50);
    },
    ...mapActions(['resetState']),
    // trylogout for component method since action is also called logout
    logout() {
      console.log('trying to logout');
      this.resetState();

      // redirects to login page
      this.$router.push({name: 'login-main'});
    },
    // force reset of app
    resetApp() {
      window.location.reload(true);
    },
  },
};

</script>

<style lang="scss" scoped>
  #logout {
    cursor: pointer;
  }
  #sidebar-right {
    position: fixed;
    left: 0;
    top: 0;

    /* added by dan to ensure nav doesnt get covered in analytics page; fix if not suitable */
    z-index: 3;
  }
  .sidebar-right {
    &__bg {
      background: rgba(0, 0, 0, 0.9);
      height: 100vh;
      width: 100vw;
    }
    &__section {
      display: block;
      font-family: 'Baloo Da 2', Avenir, Helvetica, Arial, sans-serif;
      font-size: 1.2rem;
      margin-top: 0;
      transition: 0.2s all linear;
      h3 {
        padding: 0.5rem 1.25rem;
        background: #896cab;
        color: #fff;
        span {
          margin-left: 0.5rem;
        }
      }
      li {
        padding: 0.25rem 0;
        cursor: pointer;
        a {
          padding-left: 2rem;
          color: #555;
          text-decoration: none;
          width: 100%;
          display: inline-block;
        }
      }
    }
    &__content {
      position: fixed;
      top: 0;
      right: 0;
      background: #efefef;
      text-align: left;
      height: 100vh;
    }
  }
  .close-btn-container {
    cursor: pointer;
    color: #ccc;
    border-top: 4px solid #dedede;
    background: #e4e4e4;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    padding: 0.65rem 0;
    justify-content: center;
    transition: 0.1s all linear;
    font-size: 1.25rem;
    &:hover {
      color: #999;
    }
  }

  @media only screen and (max-height: 1100px) {
    .sidebar-right__section{
      font-size: 1.1rem;
      h3 {
        font-size: 1.3rem;
        padding: 0.3rem 1.25rem;
      }
      li {
        padding: 0.2rem 0;
      }
    }
    .close-btn-container {
      font-size: 1.2rem;
      padding: 0.6rem 0;
    }
  }

  @media only screen and (max-height: 850px) {
    .sidebar-right__section {
      font-size: 1rem;
      h3 {
        font-size: 1.2rem;
        padding: 0.2rem 1.25rem;
      }
      li {
        padding: 0.15rem 0;
      }
    }
    .close-btn-container {
      font-size: 1.1rem;
      padding: 0.55rem 0;
    }
  }
  @media only screen and (max-height: 720px) {
    .sidebar-right__section {
      font-size: 0.9rem;
      h3 {
        font-size: 1.05rem;
      }
    }
    .close-btn-container {
      font-size: 1rem;
      padding: 0.5rem 0;
    }
  }
  @media only screen and (max-height: 640px) {
    .sidebar-right__content {
      width: 230px;
    }
    .sidebar-right__section {
      font-size: 0.8rem;
      h3 {
        font-size: 0.95rem;
      }
    }
    .close-btn-container {
      font-size: 0.9rem;
      padding: 0.4rem 0;
    }
  }
  @media only screen and (max-height: 520px) {
    .sidebar-right__content {
      width: 210px;
    }
    .sidebar-right__section {
      font-size: 0.7rem;
      h3 {
        font-size: 0.85rem;
      }
    }
    .close-btn-container {
      font-size: 0.8rem;
      padding: 0.3rem 0;
    }
  }
  @media only screen and (max-height: 485px) {
    .sidebar-right__content {
      width: 180px;
    }
    .sidebar-right__section {
      font-size: 0.6rem;
      h3 {
        font-size: 0.75rem;
        padding: 0.2rem 0.5rem;
      }
      .user-profiles {
        margin-left: -0.8rem;
      }
      li {
        a {
          padding-left: 1rem;
        }
      }
    }
    .close-btn-container {
      font-size: 0.7rem;
      padding: 0.2rem 0;
    }
  }
  @media only screen and (max-height: 405px) {
    .sidebar-right__content {
      width: 160px;
    }
    .sidebar-right__section {
      font-size: 0.5rem;
      h3 {
        font-size: 0.6rem;
      }
    }
    .close-btn-container {
      font-size: 0.55rem;
      padding: 0.2rem 0;
    }
  }
  @media only screen and (max-height: 350px) {
    .sidebar-right__content {
      width: 160px;
    }
    .sidebar-right__section {
      font-size: 0.4rem;
      h3 {
        font-size: 0.5rem;
      }
    }
  }
</style>
