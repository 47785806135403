<template>
  <div>
    <h3>BOSS FIGHT</h3>
    <div>
      <p>Goal:</p>
      <p>
        Get happiness value up to 100 before timer expires to capture the panda!
      </p>
    </div>
    <h5>Happiness Gauge</h5>
    {{ happinessValue }} / 100
    <countdown :time="2 * 60 * 1000">
      <template slot-scope="props">
        <p>
          {{ props.minutes }} minutes
          {{ props.seconds }} seconds remaining
        </p>
      </template>
    </countdown>
    <hr>
    <button
      @click.prevent="answerCorrectly"
    >Correct Answer</button>
  </div>
</template>

<script>

// import {mapState} from 'vuex';
// import QuizMainComponent from '../common/quiz/QuizMainComponent.vue';

export default {
  name: 'DailyChallengeBossMain',
  components: {
  },
  mixins: [],
  data() {
    return {
      backgroundAudio: null,
      happinessValue: 0,
    };
  },
  computed: {
    // ...mapState('dailyChallenge', ['selectedDailyChallengeUnlockedDate']),
  },
  mounted() {
    // background music
    this.backgroundAudio = new Howl({
      autoplay: true,
      loop: true,
      src: ['https://smartschool-static.s3-ap-southeast-1.amazonaws.com/media/dailychallenges/background.m4a'],
    });
  },
  destroyed() {
    this.backgroundAudio.stop();
  },
  methods: {
    answerCorrectly() {
      this.happinessValue++;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>
