<template>
  <block-container
    title="篇章练习"
    subtitle="Passage Practice"
    fa-icon="book-open"
    previous-page-name="new-comprehension-overview"
    >
    <div class="body-content">
      <div
        class="comprehension__container">
        <router-link
          v-for="(comprehension, index) in comprehensions"
          :key="index"
          :to="{name: 'new-comprehension-passage',
          params: {comprehensionId: comprehension.id}}"
          class="comprehension__container--item">
          <i class="fa-solid fa-book-open"></i>
          <span class="comprehension__container--item-divider">|</span>
          <span class="comprehension__container--item-title">
            {{ comprehension.category_verbose }}
            |
            Term {{ getTerm(comprehension.week) }}
            |
            Week {{ getWeek(comprehension.week) }}
          </span>
          <!-- <span v-if="entity.id !== 'custom' && entity.attempts === 0"
            class="comprehension__container--item-tag comprehension__container--item-tag--new">
            New
          </span>
          <span v-if="entity.id !== 'custom' && entity.attempts > 0"
            class="comprehension__container--item-tag comprehension__container--item-tag--attempts">
            Attempts: {{ entity.attempts }}
          </span> -->
        </router-link>
      </div>
    </div>
  </block-container>
</template>

<script>

import BlockContainerComponent
  from '../common/BlockContainerComponent.vue';

import {mapState, mapMutations} from 'vuex';
import axios from 'axios';

export default {
  name: 'NewComprehensionPassageIndex',
  components: {
    BlockContainer: BlockContainerComponent,
  },
  props: {
  },
  data() {
    return {
      entities: [],
    };
  },
  computed: {
    ...mapState(['level']),
    ...mapState('guidedReading', ['comprehensions']),
  },
  mounted() {
    this.setShowGlobalLoadingScreen(true);
    axios
        .get(`/vocab/comprehension/passages/${this.level}/`)
        .then((response) => {
          this.setComprehensions(response.data);
          this.setShowGlobalLoadingScreen(false);
        });
  },
  methods: {
    ...mapMutations(['setShowGlobalLoadingScreen']),
    ...mapMutations('guidedReading', ['setComprehensions']),
    getTerm(week) {
      const term = Math.floor(week / 12) + 1;
      return term;
    },
    getWeek(week) {
      let modulusWeek = (week + 1) % 12;
      modulusWeek = modulusWeek !== 0 ? modulusWeek : 12;
      return modulusWeek;
    },
  },
};

</script>

<style lang="scss" scoped>
  .body-content {
    height: 100%;
    width: 100%;
    overflow: hidden;
    position: relative;
  }
  .oral-index {
    &__container {
      height: 100%;
      padding: 2rem;
      width: 100%;
      overflow-y: auto;
      overflow-x: hidden;
      position: absolute;
      text-align: center;
      grid-gap: 1rem;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      justify-content: center;
      width: 100%;
      grid-gap: 1.5rem;
      &--padding {
        padding: 0rem 2rem 6rem 2rem;
      }
      &--item {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        background: #fff;
        border-radius: 7.5px;
        text-decoration: none;
        border: 6px solid transparent;
        transition: all 0.2s;
        position: relative;
        &:hover {
          background: #fff;
          .oral-index__container--item-icon {
            background: #fff;
            border: 6px solid #2b92f8;
            i {
              color: #2b92f8;
            }
          }
        }
        .user-settings-content {
          height: 100%;
          width: 100%;
        }
        a {
          text-decoration: none;
        }
        &-icon {
          height: 75%;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 6px solid #e9e9e9;
          margin: .5rem .5rem;
          transition: all 0.2s;
          padding: 1rem;
          border-radius: 0.5rem;
          background: #fff;
          i {
            transition: all 0.2s;
            color: #b9b6c1;
            font-size: 3.5rem;
            margin-bottom: 5px;
          }
        }
      }
      &--text {
        width: 100%;
        height: calc(25% - 1rem);
        display: flex;
        justify-content: center;
        align-items: center;
        &-title {
          font-weight: 600;
          font-size: 1rem;
          color: #4e4e54;
        }
      }
    }
  }
  .comprehension {
    &__container {
      max-height: 100%;
      overflow-y: auto;
      overflow-x: hidden;
      position: absolute;
      text-align: center;
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      width: 100%;
      gap: 1rem;
      flex-wrap: wrap;
      padding: 1rem 2rem 1.5rem 2rem;
      &--item {
        text-align: left;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        background: #fff;
        border-radius: 7.5px;
        text-decoration: none;
        transition: all 0.2s;
        position: relative;
        width: 100%;
        // flex-basis: 48.5%;
        font-weight: 600;
        font-size: 1rem;
        color: #4e4e54;
        padding: .25rem .5rem;
        border: 4px solid transparent;
        &:first-of-type {
          .comprehension__container--item-tag {
            display: none;
          }
        }
        &-divider {
          margin: 0 .5rem;
        }
        &-tag {
          margin-left: auto;
          font-size: .8rem;
          padding: .1rem 0.5rem;
          border-radius: .25rem;
          color: #fff;
          &--new {
            background: #36d4ac;
          }
          &--attempts {
            background: #d825ff;
          }
        }
        &:hover {
          color: #2b92f8;
          border: 4px solid #2b92f8;
        }
      }
    }
  }

  @media only screen and (max-width: 1560px) {
    .oral-index__container {
      grid-template-columns: 1fr 1fr;
    }
  }
  @media only screen and (max-width: 1366px),
              screen and (max-height: 870px) {
    .oral-index {
      &__container {
        padding: 1.5rem;
        grid-gap: 1.25rem;
        &--padding {
          padding: 1rem 1.5rem 1rem 1.5rem;
        }
        &--item {
          padding: .25rem;
          &-icon {
            padding: .75rem;
            i {
              font-size: 3rem;
            }
          }
        }
        &--text {
          &-title {
            font-size: .9rem;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 1150px),
  screen and (max-height: 690px) {
    .comprehension__container {
      gap: .75rem;
    }
    .comprehension__container--item {
      font-size: .85rem;
      &-tag {
        font-size: .7rem;
      }
    }
  }
  @media only screen and (max-width: 960px),
  screen and (max-height: 620px) {
    .oral-index {
      &__container {
        background: none;
        width: 100%;
        height: 100%;
      }
    }
  }
  @media only screen and (max-width: 690px) {
    .oral-index__container {
      grid-template-columns: 1fr;
    }
  }
  @media only screen and (max-width: 580px) {
    .comprehension__container {
      padding: 1rem;
    }
  }
  @media only screen and (max-width: 480px) {
    .oral-index__container--item-icon {
      padding: .5rem;
      i {
        font-size: 2.5rem;
      }
    }
    .oral-index__container--text-title {
      font-size: .8rem;
    }
  }
  @media only screen and (max-height: 720px) {
    .comprehension__container--item {
      font-size: .85rem;
      &-tag {
        font-size: .7rem;
      }
    }
  }
</style>
