<template>
  <block-container
    title="作文课"
    subtitle="Technique Lessons"
    fa-icon="pencil-alt"
    previous-page-name="new-composition-overview"
    >
    <div class="learn-index__container">
      <!-- Compo Video Filters -->
      <filter-buttons class="filter-btns" :options="filterOptions" @select="select" :selected="compoType"></filter-buttons>
      <router-link v-for="(composition, index) in filteredCompositions"
        :key="index"
        class="learn-index__container--item"
        :to="{name: 'new-composition-lesson', params: {week: composition.week}}">
          <div class="learn-index__container--text">
          <i class="fa-solid fa-video"></i>
          <span class="divider">|</span>
          <span>{{ composition.title }}</span>
        </div>
      </router-link>
    </div>
  </block-container>
</template>

<script>

import {mapMutations} from 'vuex';
import axios from 'axios';

import BlockContainerComponent
  from '../common/BlockContainerComponent.vue';

import FilterButtonsComponent
  from './components/FilterButtonsComponent.vue';

export default {
  name: 'NewCompositionLessonIndex',
  components: {
    BlockContainer: BlockContainerComponent,
    FilterButtons: FilterButtonsComponent,
  },
  data() {
    return {
      compoType: 'all',
      compositions: [],
      filterOptions: [
        {
          label: '全部',
          value: 'all',
        },
        {
          label: '看图作文',
          value: '看图作文',
        },
        {
          label: '命题作文',
          value: '命题作文',
        },
        {
          label: '完成文章',
          value: '完成文章',
        },
      ],
    };
  },
  computed: {
    filteredCompositions() {
      let toReturn;
      if (this.compoType === 'all') {
        toReturn = this.compositions;
      } else {
        toReturn = this.compositions.filter((composition) => {
          return composition.category_verbose === this.compoType;
        });
      }
      return toReturn;
    },
  },
  mounted() {
    this.setShowGlobalLoadingScreen(true);
    axios
        .get(`/vocab/extramaterial/compositions/`)
        .then((response) => {
          this.compositions = response.data;
          console.log(this.compositions);
          this.setShowGlobalLoadingScreen(false);
        });
  },
  methods: {
    ...mapMutations(['setShowGlobalLoadingScreen']),
    select(val) {
      this.compoType = val;
    },
  },
};
</script>


<style lang="scss" scoped>
  .learn-index {
    &__container {
      height: 100%;
      background: linear-gradient(to bottom right, #1d244dc9, #1518293c);
      padding: 1rem 2rem;
      width: 100%;
      overflow-y: auto;
      overflow-x: hidden;
      position: absolute;
      text-align: center;
      gap: .75rem;
      display: flex;
      flex-direction: column;
      width: 100%;
      .disabled {
        pointer-events: none;
        opacity: .5;
        .learn-index__container--text {}
      }
      &--item {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        background: #fff;
        border-radius: 7.5px;
        text-decoration: none;
        border: 6px solid transparent;
        transition: all 0.2s;
        position: relative;
        padding: .25rem .5rem;
        &:hover {
          background: #fff;
          .learn-index__container--item-icon {
            background: #fff;
            border: 6px solid #2b92f8;
            i {
              color: #2b92f8;
            }
          }
        }
        .user-settings-content {
          height: 100%;
          width: 100%;
        }
        a {
          text-decoration: none;
        }
        &-icon {
          height: 75%;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 6px solid #e9e9e9;
          margin: .5rem .5rem;
          transition: all 0.2s;
          padding: 1rem;
          border-radius: 0.5rem;
          background: #fff;
          i {
            transition: all 0.2s;
            color: #b9b6c1;
            font-size: 3.5rem;
            margin-bottom: 5px;
          }
        }
      }
      &--text {
        font-weight: 600;
        color: #4e4e54;
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .divider {
          margin: 0 .5rem;
        }
      }
    }
  }

  @media only screen and (max-width: 1560px) {
  }
  @media only screen and (max-width: 1366px),
              screen and (max-height: 870px) {
  }

  @media only screen and (max-width: 1150px),
  screen and (max-height: 690px) {
  }
  @media only screen and (max-width: 960px),
  screen and (max-height: 620px) {
  }
  @media only screen and (max-width: 690px) {
  }
  @media only screen and (max-width: 580px) {
    .learn-index__container {
      padding: 1rem;
      .filter-btns {
        margin-top: 0px;
      }
    }
  }
  @media only screen and (max-width: 480px) {
  }

</style>
