<template>
  <div
    class="button-image"
    :class="buttonClass"
    @click="_goTo(nextSceneId)">
    <p>{{ text }}</p>
  </div>
</template>

<script>

import {mapActions} from 'vuex';

export default {
  name: 'ImageButton',
  components: {
  },
  props: {
    text: String,
    nextSceneId: Number,
    imageName: String,
  },
  data() {
    return {
    };
  },
  computed: {
    buttonClass() {
      // checks that imageName is valid
      if (!this.imageName || typeof this.imageName === 'undefined') {
        return;
      };

      return 'btn-' + this.imageName;
    },
  },
  mounted: function() {
  },
  methods: {
    ...mapActions('dynamicBook', ['goToScene']),
    _goTo(nextSceneId) {
      this.goToScene(nextSceneId);
    },
  },
};
</script>

<style lang="scss" scoped>
  .button-image {
    position: relative;
    cursor: pointer;
    height: 0;
    width: 20%;
    background-repeat: no-repeat;
    padding-top: 26%;
    background-size: 100%;
    background-image: url('https://smartschool-static.s3-ap-southeast-1.amazonaws.com/media/dynamicbooks/2019_dec_holiday_camp/narration-scene/sprite-btns.png');
    display: flex;
    justify-content: center;
    p {
      overflow-wrap: break-word;
      width: 65%;
      text-align: center;
      margin-top: -47%;
      color: #fff;
      font-size: 2rem;
    }
  }

  .btn-food-1 { background-position: 0 0%; background-size: 100%; }
  .btn-food-2 { background-position: 0 6.25%; background-size: 100%; }
  .btn-food-3 { background-position: 0 12.5%; background-size: 100%; }
  .btn-yes { background-position: 0 18.75%; background-size: 100%; }
  .btn-no { background-position: 0 25%; background-size: 100%; }
  .btn-calendar { background-position: 0 31.25%; background-size: 100%; }
  .btn-ingredient-4 { background-position: 0 37.5%; background-size: 100%; }
  .btn-ingredient-5 { background-position: 0 43.75%; background-size: 100%; }
  .btn-ingredient-6 { background-position: 0 50%; background-size: 100%; }
  .btn-ingredient-7 { background-position: 0 56.25%; background-size: 100%; }
  .btn-panda-bro {
    background-position: 0 62.2%;
    background-size: 100%;
    padding-top: 23%;
    p {
      margin-top: -30%;
    }

  }
  .btn-panda-dad {
    background-position: 0 68.75%;
    background-size: 100%;
    p {
      margin-top: -30%;
    }
  }
  .btn-panda-mum {
    background-position: 0 75%;
    background-size: 100%;
    p {
      margin-top: -30%;
    }

  }
  .btn-panda-sis {
    background-position: 0 80.85%;
    background-size: 100%;
    padding-top: 23%;
    p {
      margin-top: -30%;
    }
  }
  .btn-suspect-1 { background-position: 0 87.5%; background-size: 100%; }
  .btn-suspect-2 { background-position: 0 93.75%; background-size: 100%; }
  .btn-suspect-3 { background-position: 0 100%; background-size: 100%; }

    /* Landscape */
  // ipad pro
  @media only screen
  and (min-device-width: 1024px)
  and (max-device-width: 1366px)
  and (-webkit-min-device-pixel-ratio: 2) {
    .button-image {
      width: 25%;
      padding-top: 33%;
      p {
        font-size: 1.5rem;
      }
    }
    .btn-panda-sis,
    .btn-panda-bro {
      padding-top: 30.5%;
    }

  }
  // ipad
  @media only screen and (min-device-width : 768px)
  and (max-device-width : 1024px) and (orientation : landscape) {
    .button-image {
      width: 25%;
      padding-top: 33%;
      p {
        font-size: 1.5rem;
      }
    }
    .btn-panda-sis,
    .btn-panda-bro {
      padding-top: 30.5%;
    }

  }

</style>
