<template>
  <span :class="{highlight: toHighlight}">{{ textWithSpace }}</span>
</template>

<script>
import {mapState, mapGetters} from 'vuex';

export default {
  name: 'SentenceComponent',
  components: {
  },
  props: {
    paraIndex: Number, // index of parent paragraph within current passage
    sentenceIndex: Number, // index of sentence within the current paragraph
    text: String,
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapState('guidedReading', ['comprehensionState', 'sentencesToHighlight']),
    ...mapGetters('guidedReading', ['currentQuestion']),
    toHighlight() {
      // highlight the sentences where the correct answer came from after student has answered
      if (this.comprehensionState !== 2 || !this.currentQuestion || this.currentQuestion.sentences_to_highlight.length === 0) return;
      // after student has answered, we want to highlight the sentences where the correct answer comes from
      // to support multiple paragraphs, we have an array of string "x_y", where x is paraIndex, and y is sentenceIndex
      for (const entry of this.currentQuestion.sentences_to_highlight) {
        let [paraIndex, sentenceIndex] = entry.split('_');
        paraIndex = parseInt(paraIndex);
        sentenceIndex = parseInt(sentenceIndex);
        if (this.paraIndex === paraIndex && this.sentenceIndex === sentenceIndex) return true;
      }
      return false;
    },
    // adds a single space unless it's the first sentence in the paragraph
    textWithSpace() {
      return this.index === 0 ? this.text : ` ${this.text}`;
    },
  },
  mounted() {
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
  .highlight {
    background: #ffc700;
    border-radius: .25rem;
  }
</style>
