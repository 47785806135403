import Vue from 'vue';

export const setAuthenticated = (state, value) => {
  state.authenticated = value;
};

export const setFetchingInitialData = (state, value) => {
  state.fetchingInitialData = value;
};

export const setShowGlobalLoadingScreen = (state, value) => {
  state.showGlobalLoadingScreen = value;
};

export const setStudentId = (state, value) => {
  state.studentId = value;
};

export const setStudentName = (state, value) => {
  state.studentName = value;
};

export const setSubject = (state, value) => {
  state.subject = value;
};

export const setStudentType = (state, value) => {
  state.studentType = value;
};

export const setCurrentDifficulty = (state, value) => {
  state.currentDifficulty = value;
};

export const setParentEmail = (state, value) => {
  state.parentEmail = value;
};

export const setTotalKnownWords = (state, value) => {
  state.totalKnownWords = value;
};

export const setMissions = (state, value) => {
  state.missions = value;
};

export const setChallenges = (state, value) => {
  state.challenges = value;
};

export const setIsStoreEnabled = (state, value) => {
  state.isStoreEnabled = value;
};

export const setListLevelChapters = (state, value) => {
  state.listLevelChapters = value;
};

export const setCoinTransactions = (state, value) => {
  state.coinTransactions = value;
};

export const setChineseQuizStars = (state, value) => {
  state.chineseQuizStars = value;
};

export const setQuizzesCompletedInfo = (state, value) => {
  state.quizzesCompletedInfo = value;
};

export const setWatchedPrimarySchoolLessonVideos = (state, value) => {
  state.watchedPrimarySchoolLessonVideos = value;
};

export const addToWatchedPrimarySchoolLessonVideos = (state, value) => {
  Vue.set(state.watchedPrimarySchoolLessonVideos,
      value, true);
};

export const setWatchedPreschoolLessonVideos = (state, value) => {
  state.watchedPreschoolLessonVideos = value;
};

export const addToWatchedPreschoolLessonVideos = (state, value) => {
  Vue.set(state.watchedPreschoolLessonVideos,
      value, true);
};

export const setWatchedCompoLessonVideos = (state, value) => {
  state.watchedCompoLessonVideos = value;
};

export const addToWatchedCompoLessonVideos = (state, value) => {
  Vue.set(state.watchedCompoLessonVideos,
      value, true);
};

// to be removed
export const setNotifications = (state, value) => {
  state.notifications = value;
};

export const setUsedFeatures = (state, value) => {
  state.usedFeatures = value;
};

export const addToUsedFeatures = (state, value) => {
  Vue.set(state.usedFeatures, value, true);
};

export const setInitialRoute = (state, value) => {
  state.initialRoute = value;
};

export const clearInitialRoute = (state, value) => {
  state.initialRoute = null;
};

export const setIsWidgetsShown = (state, value) => {
  state.isWidgetsShown = value;
};

export const setIsMobileNavMoreMenuShown = (state, value) => {
  state.isMobileNavMoreMenuShown = value;
};

export const setIsChallengesPopupShown = (state, value) => {
  state.isChallengesPopupShown = value;
};

export const setIsWebApp = (state, value) => {
  state.isWebApp = value;
};

export const setIsShowNavBottom = (state, value) => {
  state.isShowNavBottom = value;
};

export const setVocabSubscription = (state, value) => {
  state.vocabSubscription = value;
};

export const setQuests = (state, value) => {
  state.quests = value;
};

export const setLoginStreak = (state, value) => {
  state.loginStreak = value;
};

// // adds pet
// export const addPet = (state, pet) => {
//   Vue.set(state.pets.pets_owned,
//       state.pets.pets_owned.length, pet);
// };

// export const incrementPetCounts = (state, petKey) => {
//   if (!petKey in state.pets.pet_counts ||
//     isNaN(state.pets.pet_counts[petKey])) {
//     Vue.set(state.pets.pet_counts, petKey, 0);
//   };

//   Vue.set(state.pets.pet_counts,
//       petKey, state.pets.pet_counts[petKey] + 1);
// };


// update the stars for a particular chinese quiz id if it is higher
export const updateStarsIfBetter = (state, payload) => {
  const chineseQuizPk = payload.chineseQuizPK;
  const starsFromQuiz = payload.starsFromQuiz;

  if (starsFromQuiz <=
    state.chineseQuizStars[chineseQuizPk]) return;

  Vue.set(state.chineseQuizStars, chineseQuizPk, starsFromQuiz);
};

export const addCoinTransaction = (state, payload) => {
  state.coinTransactions[payload.category] += payload.coinsToAdd;
  state.coinTransactions['total'] += payload.coinsToAdd;
};

export const setDiamonds = (state, value) => {
  state.diamonds = value;
};

// export const setRemainingDailyAttempts = (state, value) => {
//   state.remainingDailyAttempts = value;
// };

export const setCoins = (state, value) => {
  state.coins = value;
};

export const setEnglishSubtitlesForLibrary = (state, value) => {
  state.englishSubtitlesForLibrary = value;
};

export const setAvatar = (state, value) => {
  state.avatar = value;
};

export const setLevel = (state, value) => {
  state.level = value;
};

export const setSiblings = (state, value) => {
  state.siblings = value;
};

export const setCocos = (state, value) => {
  state.cocos = value;
};

export const setRegistration = (state, value) => {
  state.registration = value;
};

export const setFullScreenModalComponentDetails = (state, value) => {
  state.fullScreenModalComponentDetails = value;
};

export const setTimeLastParentAuthenticated = (state, value) => {
  state.timeLastParentAuthenticated = value;
};


