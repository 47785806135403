<template>
  <div class="currency">
    <!-- <i class="fas fa-bars"></i> -->
    <router-link
      v-if="avatar"
      tag="div"
      class="user-avatar"
      :to="{name: 'new-play-avatar'}">
      <img class="avatar-img"
        :src='"../../../assets/avatars/" + avatar + ".png"' />
    </router-link>
    <div class="daily-streak">
      <i class="fas fa-fire-alt"></i>
      <p>{{ loginStreak.current_streak }}</p>
    </div>
    <div class="currency--coins">
      <img src="../../../assets/profile/coin.png">
      <p>{{ coins }}</p>
    </div>
    <!-- <div class="currency--diamonds">
      <img src="../../../assets/profile/diamond.png">
      <p>{{ diamonds }}</p>
    </div> -->
    <router-link
        class="currency--diamonds"
        :to="{name: 'new-missions-overview'}">
        <img src="../../../assets/profile/diamond.png">
        <p>{{ diamonds }}</p>
      </router-link>
  </div>
</template>

<script>
import {mapState} from 'vuex';

export default {
  name: 'CurrencyContainer',
  components: {
  },
  props: {
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapState(['coins', 'diamonds', 'loginStreak', 'avatar']),
  },
  mounted() {
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
  .daily-streak {
    display: none;
    width: 25%;
    i {
      color: #ffaf28;
      margin-right: 0.25rem;
      font-size: 1.2rem;
    }
    color: #fff;
    justify-content: center;
    align-items: center;
    margin-bottom: 0.25rem;
    p {
      font-size: .9rem;
      font-weight: 600;
    }
  }
  .user-avatar {
    justify-content: center;
    align-items: center;
    width: 25%;
    display: none;
    margin-bottom: 0.25rem;
    img {
      cursor: pointer;
      border-radius: 50%;
      background: #fff;
      width: 35px;
    }
  }
  .fa-bars {
    display: none;
    color: #fff;
    font-size: 1.4rem;
    margin-bottom: .25rem;
    position: absolute;
    left: 1rem;
  }
  .currency {
    position: fixed;
    top: 0;
    right: 0;
    width: 345.5px;
    align-self: flex-start;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 3rem;
    background: linear-gradient(#29b5ff, #6472FB);
    padding: 0 .25rem;
    border-bottom-left-radius: 0.75rem;
    box-shadow: 5px -5px 0px 0px rgba(0,0,0,.168627451) inset;
    &--coins, &--diamonds {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 0.5rem;
      width: 40%;
      margin: 0 .5rem .5rem .5rem;
      img {
        width: 35px;
        z-index: 2;
      }
      p {
        width: 100%;
        color: #fff;
        font-size: 1.15rem;
        font-weight: 600;
        line-height: 1.65rem;
        background: #233167;
        padding: 0 1rem;
        text-align: center;
        margin-left: -1rem;
        border-radius: 0.25rem;
        padding-left: 1.75rem;
      }
    }
  }
  @media only screen and (max-width: 1366px),
              screen and (max-height: 870px) {
    .currency {
      width: 282.5px;
      padding: 0;
      height: 2.5rem;
      &--coins, &--diamonds {
        width: 45%;
        margin: 0 .5rem .25rem .5rem;
        img {
          width: 30px;
        }
        p {
          font-size: 1rem;
          line-height: 1.45rem;
        }
      }
    }
  }
  @media only screen and (max-width: 1150px),
            screen and (max-height: 720px) {
    .currency {
      width: 282.5px;
      padding: 0;
      height: 2.5rem;
      &--coins, &--diamonds {
        width: 45%;
        margin: 0 .25rem .25rem .25rem;
        img {
          width: 30px;
        }
        p {
          font-size: 1rem;
          line-height: 1.45rem;
        }
      }
    }
  }
  @media only screen and (max-width: 1150px),
            screen and (max-height: 720px) {
    .currency {
      width: 232.5px;
      &--coins, &--diamonds {
        p {
          font-size: 0.9rem;
        }
      }
    }
  }
  @media only screen and (max-width: 960px),
           screen and (max-height: 620px) {
    .user-avatar {
      display: flex;
    }
    .daily-streak {
      display: flex;
    }
    .currency {
      width: 100%;
      border-bottom-left-radius: 0;
      box-shadow: 0px -5px 0px 0px rgba(0,0,0,.168627451) inset;
      justify-content: space-around;
      &--coins, &--diamonds {
        width: 25%;
        img {
          width: 25px;
        }
        p {
          width: auto;
          background: none;
          padding: 0;
          margin: 0;
          margin-left: .25rem;
        }
      }
    }
    .fa-bars {
      display: block;
    }
  }
</style>
