<template>
  <div>
    <h1>Success</h1>
  </div>
</template>

<script>

export default {
  name: 'BillingSuccess',
  components: {
  },
  props: {
  },
  data() {
    return {
    };
  },
  computed: {
    // ...mapState(['studentName', 'avatar', 'level']),
  },
  mounted: function() {
  },
  methods: {
  },
};

</script>

<style lang="scss" scoped>

</style>
