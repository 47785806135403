<template>
  <div class="fixed-component">
    <div class="scrollable-component">
      <div class="worksheet-download__component row">
        <h2 class="worksheet-download__component--header">
          <i class="fas fa-download"></i>
          Download Worksheets
          <span>|</span>
          {{ formattedLevel }}
          | <button @click="showAll = !showAll">
            <span v-if="!showAll">Show all worksheets</span>
            <span v-else>Show latest worksheets</span>
          </button>
        </h2>
        <div class="worksheet-download__container">
          <div
            v-for="(doc, index) in filteredDocuments"
            :key="index"
            class="worksheet-download__container--item">
            <a :href="doc.url"
              target="_blank">
              <div class="worksheet-download__container--item-icon">
                <!-- <i class="fas fa-file-alt icon-main"></i> -->
                <span v-if="doc.type === 'homework'">功课</span>
                <span v-else>课堂练习</span>
              </div>
              <div class="worksheet-download__container--item-header">
                <p>Term {{ getTerm(doc.week) }}</p>
                <span>|</span>
                <p>Week {{ getWeek(doc.week) }}</p>
              </div>
              <p class="worksheet-download__container--item-subheader">
                {{ doc.chapter_title }}
              </p>
              <p class="worksheet-download__container--item-subheader">
                ({{ doc.lesson_date }})
              </p>
              <div v-if="doc.is_current_week"
                class="worksheet-download__container--item-overlay">
                <p>Current Week</p>
              </div>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex';
import axios from 'axios';

export default {
  name: 'WorksheetDownload',
  components: {
  },
  props: {
  },
  data() {
    return {
      documents: [],
      showAll: false, // to avoid clutter, by default, we only show the latest 9 documents
    };
  },
  computed: {
    ...mapState(['level']),
    formattedLevel() {
      return this.$options.filters.splitCapitalise(this.level);
    },
    filteredDocuments() {
      if (this.showAll) return this.documents;
      return this.documents.slice(this.documents.length - 9);
    },
  },
  mounted() {
    axios
        .get('/vocab/primaryschoollesson/download_documents/')
        .then((response) => {
          this.documents = response.data;
        });
  },
  methods: {
    getTerm(week) {
      const term = Math.floor(week / 12) + 1;
      return term;
    },
    getWeek(week) {
      let modulusWeek = (week + 1) % 12;
      modulusWeek = modulusWeek !== 0 ? modulusWeek : 12;
      return modulusWeek;
    },
  },
};

</script>

<style lang="scss" scoped>
  .worksheet-download {
    &__component {
      text-align: center;
      margin: 20px auto 84px auto;
      &--header {
        background: #5a4179;
        color: #fff;
        padding: 10px;
        border-radius: 3px;
        margin-bottom: 25px;
        span {
          margin: 0 0.25rem;
        }
      }
    }
    &__container {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 40px;
      margin-bottom: 30px;
      &--item {
        background: #fff;
        padding: 35px;
        color: #555;
        border-radius: 3px;
        text-decoration: none;
        border: 6px solid transparent;
        transition: all 0.2s;
        position: relative;
        a {
          color: #555;
          text-decoration: none;
        }
        &-icon {
          border-radius: 14px;
          border: 6px solid #efefef;
          padding: 20px 80px;
          margin: 0 auto 10px auto;
          transition: all 0.2s;
          width: 260px;
          &:hover {
            padding: 20px;
            border: 6px solid #b3cdff;
            i {
              color: #6e9fec;
            }
          }
          i {
            transition: all 0.2s;
            color: #aaa;
            font-size: 3.5rem;
            margin-bottom: 5px;
          }
        }
        &-header {
          display: flex;
          justify-content: center;
          font-weight: 600;
          font-size: 1.1rem;
          margin-bottom: 0.25rem;
          span {
            margin: 0 .25rem;
          }
        }
        &-subheader {
          font-size: 0.8rem;
        }
        &-overlay {
          position: absolute;
          text-align: center;
          top: -1rem;
          left: 0;
          width: 100%;
          p {
            background: #01dac8;
            display: inline-block;
            padding: 0.25rem 2rem;
            color: #fff;
            font-weight: 600;
            text-transform: uppercase;
            border-radius: 5px
          }
        }
      }
    }
  }
  @media only screen and (max-width: 1140px) {
    .worksheet-download {
      &__component {
        margin-bottom: 64px;
      }
      &__container {
        grid-gap: 20px;
        grid-template-columns: 1fr 1fr;
      }
    }
  }
  @media only screen and (max-width: 720px) {
    .worksheet-download {
      &__container {
        grid-template-columns: 1fr;
      }
    }
  }
  @media only screen and (max-width: 580px) {
    .worksheet-download__component--header {
      font-size: 1.1rem;
    }
  }
  @media only screen and (max-width: 450px) {
    .worksheet-download__component--header {
      font-size: 1rem;
    }
  }
  @media only screen and (max-width: 390px) {
    .worksheet-download__component--header {
      font-size: 0.9rem;
    }
  }
  @media only screen and (max-width: 350px) {
    .worksheet-download__component--header {
      font-size: 0.8rem;
    }
    .worksheet-download__container--item-icon {
      width: auto;
    }
  }

</style>
