<template>
  <div class="daily-challenge-menu__component row">
    <div class="daily-challenge-menu__header">
      <speech-bubble-popup
        :messages='messages'
        :auto-show='showInitialPopup'
        notification-category='daily_challenge'
        notification-subcategory='initial'
      >
        <div class="popup__btn--dialogue">
          <img src="../../assets/boshipanda.png" />
        </div>
        <i class="fas fa-comment"></i>
      </speech-bubble-popup>
      <daily-challenge-countdown
        :time-to-challenge-end="timeToChallengeEnd">
      </daily-challenge-countdown>
<!--       <daily-challenge-options-popup>
      </daily-challenge-options-popup> -->
    </div>


    <div class="daily-challenge-menu__content">
      <daily-challenge-day-button
        class="daily-challenge-menu__button tap-btn-highlight"
        v-for='(stars, unlocked_date) in dailyQuizInfo.stars'
        :key='unlocked_date'
        :unlocked_date='unlocked_date'
        :stars='stars'
        :completed='dailyQuizInfo.weeklySummary[unlocked_date].completed'
        :colors='colors'
      ></daily-challenge-day-button>
    </div>
    <!-- kiv: design boss button as separate button -->
    <!-- @click.prevent="goToBoss" removed for now -->
    <div
      class="daily-challenge-menu__btn-boss"
      :class="{gradientBackgroundClass: completed}">
      <div class="daily-challenge-menu__btn-boss--image">
        <img src="../../assets/boshipanda.png" />
      </div>
      <div class="daily-challenge-menu__btn-boss--content">

        <div class="daily-challenge-menu__btn-boss--title">
          <!-- if not completed -->
          <p v-if="!completed"
            class="daily-challenge-menu__btn-boss--title-text"
            style="color: #555">
            <i class="fas fa-question-circle"></i>
            <!-- Find all 6 Gems and get a reward! -->
            Feed me Apples and get a reward!
          </p>
          <!-- v-if completed -->
          <div v-else
            class="daily-challenge-menu__btn-boss--title-text">
            <!-- <p>Congratulations! You've found all 6 gems!</p> -->
            <p>Congratulations! You've found all the apples!</p>
          </div>
          <!-- to show when completed -->
        </div>
        <div
          class="daily-challenge-menu__btn-boss--gems">
          <daily-challenge-key
            class="daily-challenge-menu__btn-boss--gems-icon"
            :class="{gemsIconCompleted: completed}"
            v-for='(color, index) in colors'
            :key='index'
            :color='color'
            :completed='completedArr[index]'
          ></daily-challenge-key>
          <p v-if="!completed"
            class="daily-challenge-menu__btn-boss--gems-text">
            <!-- ( {{ amountCompleted }} / 6 Gems Collected ) -->
            ( {{ amountCompleted }} / 6 Apples Fed )
          </p>
        </div>

        <div
          v-if="completed"
          :class="{'heartbeat-animation': !dailyQuizInfo.weeklyRewardClaimed}"
          >
          <!-- show when user hasn't collected weekly reward -->
          <div
            class="daily-challenge-menu__btn-boss--reward-btn"
            v-if="!dailyQuizInfo.weeklyRewardClaimed"
            @click.prevent="_collectWeeklyReward"
          >
            <i class="fas fa-gift"></i>
            Claim Rewards!
          </div>
          <div
            class="daily-challenge-menu__btn-boss--reward-claimed"
            v-else>
            <i class="fas fa-gift"></i>
            Rewards Claimed
          </div>
        </div>
      </div>
    </div>

    <!-- hacked solution/ need to fix later / put this below,
      so it doesn't affect styling -->
    <!-- this popup is for when user claims a reward -->
    <speech-bubble-popup
      :messages='["Congratulations, you win 10 coins!"]'
      :auto-show='false'
      :send-to-backend='false'
      :open='showRewardPopup'
    >
    </speech-bubble-popup>
  </div>
</template>

<script>
import {mapState, mapMutations, mapGetters, mapActions} from 'vuex';

import DailyChallengeDayButtonComponent
  from './DailyChallengeDayButtonComponent.vue';

import DailyChallengeKeyComponent
  from './DailyChallengeKeyComponent.vue';

import DailyChallengeCountdownComponent
  from './DailyChallengeCountdownComponent.vue';

// import DailyChallengeOptionsPopupComponent
//   from './DailyChallengeOptionsPopupComponent.vue';

import SpeechBubblePopupComponent
  from '../common/SpeechBubblePopupComponent.vue';


export default {
  name: 'DailyChallengeOverview',
  components: {
    DailyChallengeDayButton: DailyChallengeDayButtonComponent,
    DailyChallengeKey: DailyChallengeKeyComponent,
    SpeechBubblePopup: SpeechBubblePopupComponent,
    DailyChallengeCountdown: DailyChallengeCountdownComponent,
    // DailyChallengeOptionsPopup: DailyChallengeOptionsPopupComponent,
  },
  mixins: [],
  data() {
    return {
      currentTime: new Date(),
      // note -> we made first and last color the same for now
      // need to update last color
      colors: ['#a48aff', '#ff7d8a', '#ffc062',
        '#50f190', '#7baeff', '#e57dff'],
      messages: ['Hello there! Welcome to the Daily Challenges.',
        'Do you have any apples?',
        'I would love to eat some...',
        'Can you please find some for me?'],
      // messages: ['Hello there! Welcome to the Daily Challenges.',
      //   'I seem to have lost my gems...',
      //   'Can you please help me find them?'],
      showRewardPopup: false,
      showParentControlPopup: false,
    // Archive: Panda Boss Quest Popup Message...
    // ---
    // messages: ['Hello there! Welcome to the Daily Challenges.',
    //   'Have you come to play with my panda friends?',
    //   'Unfortunately they\'re abit shy...',
    //   'Try coaxing one of them out with shiny gemstones. They love those!'],
    };
  },
  computed: {
    ...mapState(['level', 'usedFeatures']),
    ...mapState('dailyChallenge', ['dailyQuizInfo']),
    ...mapState('quiz', ['allowDailyQuiz']),
    // show popup if user has never seen it before
    // note that we only store notifications in server if it has been seen
    // so if it is not present, means not seen
    showInitialPopup() {
      return !this.hasUsedFeature()('daily_challenge');
      // console.log(this.notifications);
      // const notification =
      //   this.getNotificationByCategories('daily_challenge', 'initial')
      //   || null;
      // return !notification || !notification.seen;
    },
    timeToChallengeEnd() {
      if (this.unlocked) return 0;

      // need to set the hours to get the timer to work properly
      const targetDate = new Date(this.dailyQuizInfo.weekendQuizEndDate);
      targetDate.setHours(23);
      targetDate.setMinutes(59, 59);
      return Math.max(targetDate - this.currentTime, 0);
    },
    // converts the completed field within dailyQuizInfo into an array
    completedArr() {
      const results = [];
      for (const key in this.dailyQuizInfo.weeklySummary) {
        if (this.dailyQuizInfo.weeklySummary.hasOwnProperty(key)) {
          results.push(this.dailyQuizInfo.weeklySummary[key].completed);
        };
      };
      return results;
    },
    amountCompleted() {
      if (!this.completedArr) return 0;
      let numberCompleted = 0;
      for (let i = 0; i < this.completedArr.length; i++) {
        if (this.completedArr[i] === true) numberCompleted++;
      };
      return numberCompleted;
    },
    completed() {
      return this.amountCompleted === 6;
    },
  },
  mounted() {
  },
  methods: {
    ...mapGetters(['hasUsedFeature']),
    ...mapMutations(['addPet', 'incrementPetCounts']),
    ...mapActions('dailyChallenge', ['collectWeeklyReward']),
    // goToBoss() {
    //   // KIV -> add validation code based on unlocking
    //   this.$router.push({name: 'daily-challenge-boss'});
    // },
    testCallback() {
      console.log('this is a callback function');
    },
    // _ in front to differentiate from action collectWeeklyReward
    _collectWeeklyReward() {
      this.collectWeeklyReward()
          .then((data) => {
            console.log('done successfully');
            // updates Vuex
            for (let i = 0; i < data.loot.length; i++) {
              this.addPet(data.loot[i]);
              this.incrementPetCounts(data.loot[i].pet_key);
            };

            // shows reward popup
            this.showRewardPopup = true;
          });
    },
  },
};
</script>

<style lang="scss" scoped>
  .daily-challenge-menu {
    &__component {
      text-align: center;
      margin: 20px auto 0 auto;
    }
    &__header {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 50px auto 30px auto;
    }
    &__content {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
    }
    &__button {
      width: 340px;
      margin: 20px;
    }
  }
  @media only screen and (max-width: 760px) {
    .daily-challenge-menu {
      &__header {
        margin: 20px auto 5px auto;
      }
      &__button {
        margin: 10px;
      }
    }
  }

  @media only screen and (max-width: 414px) {
    .daily-challenge-menu {
      &__component {
        margin-top: 10px;
      }
      &__header {
        margin: 10px auto 5px auto;
      }
    }
  }

  .daily-challenge-menu__btn-boss.gradientBackgroundClass {
    // background: linear-gradient(150deg, #2db0ff 0%, #e200ff 100%);
    background: linear-gradient(150deg, #ffe000 0%, #ff00bc 100%);
    // border: 5px solid #00e5ff;
    border: 5px solid #fbff00;
  }
  .daily-challenge-menu__btn-boss--gems-text.gemsIconCompleted {
    border: 4px solid #4ddeff;
    border: 4px solid #ffc800;
  }
  .daily-challenge-menu__btn-boss {
    width: 655px;
    height: 225px;
    margin: 20px auto;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #dadada;
    border-radius: 10px;
    &--image {
      margin-left: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 150px;
      }
      /* cursor: pointer; */
    }
    &--content {
      flex: 1;
      align-self: stretch;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      margin-right: 10px;
    }
    &--title {
      color: #fff;
      font-weight: bold;
      text-transform: capitalize;
      &-text {
        margin: 20px;
      }
    }
    &--reward-btn,
    &--reward-claimed{
      i {
        margin-right: 5px;
      }
    }
    &--reward-btn {
      border: 3px solid #fff700;
      background: #f9b722;
      font-weight: bold;
      color: #fff;
      padding: 5px 10px;
      border-radius: 5px;
      margin-bottom: 15px;
      cursor: pointer;
    }
    &--reward-claimed {
      border: 3px solid darken(#fff700, 30%);
      background: darken(#f9b722, 30%);
      font-weight: bold;
      color: darken(#fff, 30%);
      padding: 5px 10px;
      border-radius: 5px;
      margin-bottom: 15px;
      cursor: not-allowed;

    }
    &--gems {
      border: 4px solid #555;
      border-radius: 20px;
      background: #fff;
      padding: 6px 12px;
      margin-bottom: 20px;
      &-icon {
        font-size: 30px;
        color: #d2d2d2;
      }
      &-text {
        font-weight: bold;
        font-size: 16px;
        margin-bottom: 6px;
      }
    }
  }

  .heartbeat-animation {
    animation: 3s infinite heartbeat;
  }

  @keyframes heartbeat {
    0% {
      transform: scale(1);
    }
    10% {
      transform: scale(1.1);
    }
    25% {
      transform: scale(1);
    }
    40% {
      transform: scale(1.1);
    }
    60% {
      transform: scale(1);
    }
    100% {
      transform: scale(1);
    }
  }

  @media only screen and (max-width: 1024px) {
    .daily-challenge-menu__btn-boss {
      margin-bottom: 0px;
    }
  }
  @media only screen and (max-width: 680px) {
    .daily-challenge-menu__btn-boss {
      flex-direction: column;
      height: auto;
      width: auto;
      margin: 20px 10px 0px 10px;
      padding: 20px;
      &--content {
        margin-right: 0;
      }
      &--image {
        margin: 0;
      }
    }
  }
  @media only screen and (max-width: 430px) {
    .daily-challenge-menu__btn-boss {
      &--gems-icon {
        font-size: 20px;
      }
    }
  }
  @media only screen and (max-width: 360px) {
    .daily-challenge-menu__btn-boss {
      &--title-text {
        font-size: 19px;
      }
    }
  }
</style>

