<!-- where the majority of the code is at -->
<template>
  <div>
    <h2>Raid Info</h2>
    <p>
      Answer Questions to attack the boss!
    </p>
    <p>
      Defeat the boss to earn rewards!
    </p>
  </div>
</template>

<script>

export default {
  name: 'RaidInfo',
  components: {
  },
  props: {
  },
  data() {
    return {
    };
  },
  computed: {
  },
  mounted: function() {
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
h2 {
  padding: 0.5rem;
  border-bottom: 2px solid #eee;
  margin-bottom: 1rem;
}
</style>
