<template>
  <div class="oral-component">
    <router-link
      v-if="type === 'readaloud'"
      class="chinese-character"
      :to="{name: 'oral-readaloud-index'}">
      <a data-v-23d2ba43="" href="#" class="close"></a>
    </router-link>
    <router-link
      v-if="type === 'freeresponse'"
      class="chinese-character"
      :to="{name: 'oral-readaloud-index'}">
      <a data-v-23d2ba43="" href="#" class="close"></a>
    </router-link>
    <router-link v-if="type === 'templatedphrases'"
      class="chinese-character"
      :to="{name: 'oral-templated-phrases-index'}">
      <a data-v-23d2ba43="" href="#" class="close"></a>
    </router-link>
    <div class="oral-component__content" :class="paragraphClass">
      <div v-if="state !== 4" class="oral-component__content--header chinese-character">
        <span
          v-if="type !== 'templatedphrases'"
          class="oral-component__content--header-title">
          《{{ passageData.chinese_title }}》
        </span>
        <span v-if="currentPara"
          class="oral-component__content--header-para-number chinese-character">
          段落: {{ paraIndex + 1 }} / {{ this.passageData.texts.length }}
        </span>
      </div>
      <div v-if="state !== 4"
        class="oral-component__content--passage">
        <!-- question container -->
        <div v-if="currentPara && [0, 1, 2].indexOf(state) > -1"
          class="oral-component__content--passage-qns">
          <p v-for="(word, index) in currentPara.clean_text"
            class="chinese-character" :key="index">
            {{ word }}
          </p>
        </div>
        <!-- results container -->
        <div v-if="wordsWithPunctuation && state === 3"
          class="oral-component__content--passage-result">
          <!-- <p class="oral-component__content--passage-result-header">
            Your Results
          </p> -->
          <!-- results text -->
          <div class="oral-component__content--passage-result-body">
            <entity
              v-for="(word, index) in wordsWithPunctuation"
              :key="index"
              :info="word"></entity>
          </div>
          <!-- <p class="oral-component__content--passage-result-footer">
            ( Click on any word above to learn more )
          </p> -->
        </div>
      </div>
      <div v-if="state !==4"
        class="oral-component__content--dashboard">
        <!-- Recorder -->
        <div v-if="[0, 1].indexOf(state) > -1"
          class="oral-component__content--dashboard-recorder">
          <!-- <span class="oral-component__content--dashboard-recorder-timer"
            v-if="isRecording">
            00:00
          </span> -->
          <div class="oral-component__content--dashboard-recorder-btn">
            <img v-if="!isRecording" src="../../assets/oral/icon-recorder.png" class="microphone" @click="toggleRecording" />
            <img v-if="isRecording" src="../../assets/oral/icon_recording.png" class="microphone" @click="toggleRecording" />
          </div>
          <div class="oral-component__content--dashboard-recorder-text">
            <span v-if="state === 0">
              ( Click the microphone icon to start recording )
            </span>
            <span v-else>
              ( Recording in progress... )
            </span>
          </div>
        </div>
        <!-- Loading -->
        <div v-if="state === 2"
          class="oral-component__content--dashboard-loading">
          <img src="../../assets/oral/indicator.gif" />
        </div>
        <!-- Results -->
        <div v-show="state === 3"
          class="oral-component__content--dashboard-results">
          <div v-if="results"
            class="oral-component__content--dashboard-results-score">
            <div class="oral-component__content--dashboard-results-score-item">
              <div class="oral-component__content--dashboard-results-score-name">
                Overall
              </div>
              <div class="oral-component__content--dashboard-results-score-value">
                {{ results.overall }}
              </div>
            </div>
            <div class="oral-component__content--dashboard-results-score-item">
              <div class="oral-component__content--dashboard-results-score-name">
                Fluency
              </div>
              <div class="oral-component__content--dashboard-results-score-value">
                {{ results.fluency }}
              </div>
            </div>
            <div class="oral-component__content--dashboard-results-score-item">
              <div class="oral-component__content--dashboard-results-score-name">
                Tone
              </div>
              <div class="oral-component__content--dashboard-results-score-value">
                {{ results.tone }}
              </div>
            </div>
            <div class="oral-component__content--dashboard-results-score-item">
              <div class="oral-component__content--dashboard-results-score-name">
                Pronunciation
              </div>
              <div class="oral-component__content--dashboard-results-score-value">
                {{ results.pronunciation }}
              </div>
            </div>
          </div>
          <div class="oral-component__content--dashboard-results-audio">
            <!-- buttons are visible and used to control what is played -->
            <div
              class="oral-component__content--dashboard-results-audio-btn"
              @click.prevent="playAudio('student')">
              <i class="fas fa-volume-up sound-icon"></i>
              <span>Your Recording</span>
            </div>
            <div
              class="oral-component__content--dashboard-results-audio-btn oral-component__content--dashboard-results-audio-btn-teacher"
              @click.prevent="playAudio('teacher')">
              <i class="fas fa-volume-up sound-icon"></i>
              <span>Teacher's Recording</span>
            </div>
            {{ teacherAudioSrc }}
            <audio v-if="teacherAudioSrc && type !== 'templatedphrases'" :src="teacherAudioSrc" ref="teacherAudio"></audio>
          </div>
          <div class="oral-component__content--dashboard-results-btn-next"
            @click="goNext()">
            Continue
          </div>
        </div>
      </div>
      <div v-if="state === 4 && passageScores"
        class="oral-component__content--dashboard--final-scene">
         <div class="oral-component__content--dashboard--final-scene--header">
          <p>Final Score</p>
          <p v-if="type === 'readaloud'" class="chinese-character">
            《{{ passageData.chinese_title }}》
          </p>
        </div>
        <div class="oral-component__content--dashboard--final-scene--score">
          <div
            class="oral-component__content--dashboard--final-scene--score-item">
            <div
              class="oral-component__content--dashboard--final-scene--score-name">
              Overall
            </div>
            <div
              class="oral-component__content--dashboard--final-scene--score-value">
              {{ passageScores['overall'] }}
            </div>
          </div>
          <div
            class="oral-component__content--dashboard--final-scene--score-item">
            <div
              class="oral-component__content--dashboard--final-scene--score-name">
              Fluency
            </div>
            <div
              class="oral-component__content--dashboard--final-scene--score-value">
              {{ passageScores['fluency'] }}
            </div>
          </div>
          <div
            class="oral-component__content--dashboard--final-scene--score-item">
            <div
              class="oral-component__content--dashboard--final-scene--score-name">
              Tone
            </div>
            <div
              class="oral-component__content--dashboard--final-scene--score-value">
              {{ passageScores['tone'] }}
            </div>
          </div>
          <div
            class="oral-component__content--dashboard--final-scene--score-item">
            <div
              class="oral-component__content--dashboard--final-scene--score-name">
              Pronunciation
            </div>
            <div
              class="oral-component__content--dashboard--final-scene--score-value">
              {{ passageScores['pronunciation'] }}
            </div>
          </div>
        </div>
        <div class="oral-component__content--dashboard--final-scene--revision">
          <h4 class="oral-component__content--dashboard--final-scene--revision-header">
            Top 5 Words to Revise
          </h4>
          <ul class="oral-component__content--dashboard--final-scene--revision-list">
            <li class="chinese-character"
              v-for="(word, index) in wordsToRevise" :key="index">
              {{ word.word }}
            </li>
          </ul>
        </div>
        <div class="oral-component__content--dashboard--final-scene--reward-btn"
          @click="onShowRewardsPage">
          <span v-if="!hasClaimedRewards">Collect Rewards</span>
          <span v-else>Rewards Claimed!</span>
        </div>
      </div>
    </div>
    <basic-reward v-if="showRewardsPage"
      :coins-to-add="results['coins_to_add']"
      :close-rewards-callback="onCloseRewards">
    </basic-reward>
  </div>
</template>

<script>

import {mapState, mapMutations} from 'vuex';
import axios from 'axios';
import Recorder from '../../../vendor/recorder';

import EntityComponent
  from './EntityComponent.vue';

import BasicRewardComponent
  from '../common/BasicRewardComponent.vue';

// miliseconds, to prevent accidental double clicking
const MINIMUM_RECORD_TIME = 500;

export default {
  name: 'OralPassage',
  components: {
    Entity: EntityComponent,
    BasicReward: BasicRewardComponent,
  },
  props: {
    type: String,
    entityId: Number,
    questionIndex: Number,
  },
  data() {
    return {
      /*
       * 0: untested
       * 1: recording
       * 2: finished recording, waiting for system to return results
       * 3: results returned for the current para
       * 4: finished all the recordings for all the para, time to show overall results
      **/
      state: 0,
      passageData: [],
      cumulativeScores: {overall: 0, fluency: 0, pronunciation: 0, tone: 0},
      paraIndex: -1, // each para is one question
      currentPara: '',
      isRecording: false,
      hasMicrophonePermission: false,
      recorder: null,
      gumStream: null,
      results: null,
      startRecordingTime: null,
      fetching: false,
      studentAudio: null,
      wrongWords: {},
      showRewardsPage: false,
      hasClaimedRewards: false,
      url: '',
    };
  },
  metaInfo: {
    // using minifed version of recorder caused an error
    script: [
      // {src: 'https://cdnjs.cloudflare.com/ajax/libs/recorderjs/0.1.0/recorder.js', async: true, defer: true},
    ],
  },
  computed: {
    ...mapState(['studentId']),
    // ...mapState('guidedReading', ['comprehensions']),
    // formattedLevel() {
    //   return this.$options.filters.splitCapitalise(this.level);
    // },
    text() {
      if (!this.currentPara) return '';
      return this.currentPara.clean_text;
    },
    audioIcon() {
      return this.isRecording ? 'fa-stop' : 'fa-microphone';
    },
    teacherAudioSrc() {
      // for now, no templated phrase audio
      if (!this.currentPara || this.type === 'templatedphrases') return '';
      if (this.paraIndex >= this.passageData.texts.length) return '';
      if (this.type === 'readaloud') {
        return `https://smartschool-static.s3.ap-southeast-1.amazonaws.com/media/oral_passages/primary_six/${this.entityId}_${this.paraIndex}.mp3`;
      } else {
        return `https://smartschool-static.s3.ap-southeast-1.amazonaws.com/media/oral_freeresponse/primary_six/${this.entityId}_${this.questionIndex}_${this.paraIndex}.mp3`;
      }
    },
    passageScores() {
      if (this.state !== 4) return null;
      const results = {};

      for (const key in this.cumulativeScores) {
        results[key] = Math.floor(this.cumulativeScores[key] / this.passageData.texts.length);
      }
      return results;
    },
    paragraphClass() {
      if (!this.currentPara) return '';
      if (this.text.length <= 40) return 'paragraph-short';
      return this.text.length <= 60 ? 'paragraph-medium': 'paragraph-long';
    },
    // the result from api doesnt have any punctuation, so we need to add it back
    wordsWithPunctuation() {
      if (!this.results) return [];
      let i = 0; // cleanText counter
      let j = 0; // result counter
      let final = [];

      final = [];
      try {
        while (i < this.text.length) {
          // if out of bounds or a punctuation particle
          if (j >= this.results.words.length || this.text[i] !== this.results.words[j].word) {
            final.push({
              type: 'particle',
              word: this.text[i],
              details: null,
            });
          } else {
            final.push({
              type: 'character',
              word: this.results.words[j].word,
              details: this.results.words[j],
            });
            j++;
          }
          i++;
        };
      } catch (err) {
        console.log(err);
      }
      return final;
    },
    // words to revise at end of passage
    // currently prioritise words that are wrong more times, then by cumulative score
    // note if a word 朋友 is completely wrong, this counds as 2 times as 2 characters
    wordsToRevise() {
      if (this.state !== 4) return [];
      const results = [];
      for (const word in this.wrongWords) {
        const entry = {
          word: word,
          num: this.wrongWords[word]['num'],
          cumulativeScore: this.wrongWords[word]['cumulative_score'],
        };
        results.push(entry);
      };

      // prioritise words with highest frequency of error, followed by lowest score
      results.sort((a, b) => {
        return b['num'] - a['num'] || a['cumulativeScore'] - b['cumulativeScore'];
      });
      // return top 5 words to return
      return results.slice(0, 5);
    },
  },
  mounted() {
    // checks to see if user has provided permission to use microphone
    navigator.permissions.query({
      name: 'microphone',
    })
        .then((permissionStatus) => {
          this.hasMicrophonePermission = permissionStatus.state !== 'denied';
        })
        .catch((error) => {
          console.log(error);
          this.hasMicrophonePermission = false;
        });
    this.pullData();
  },
  methods: {
    ...mapMutations(['setCoins']),
    ...mapMutations('widgets', ['setCoinLeaderboard']),
    pullData() {
      if (this.type === 'readaloud') {
        this.url = `/vocab/oralpassage/${this.entityId}/`;
      } else {
        this.url = `/vocab/${this.type}/${this.entityId}/${this.questionIndex}/`;
      }
      axios
          .get(this.url)
          .then((response) => {
            this.passageData = response.data;
            console.log(this.passageData);
            this.nextPara();
          });
    },
    // checks to see if we have reached end of passage or can we show next para
    // adjusts the state accordingly
    nextPara() {
      this.paraIndex++;
      if (this.paraIndex >= this.passageData.texts.length) {
        this.currentPara = null;
        this.paraIndex = -1;
        this.end();
        return;
      }

      this.currentPara = this.passageData.texts[this.paraIndex];
      this.state = 0;
    },
    // to prevent user from accidentally double clicking, we will record for minimum of at least 1s
    toggleRecording() {
      if (this.isRecording) {
        this.stopRecording();
      } else {
        this.startRecording();
      }
    },
    // https://blog.addpipe.com/using-recorder-js-to-capture-wav-audio-in-your-html5-web-site/
    startRecording() {
      if (this.fetching) return;
      this.state = 1;
      this.isRecording = true;
      this.result = null;
      this.startRecordingTime = new Date();

      // shim for AudioContext when it's not avb.
      const AudioContext = window.AudioContext || window.webkitAudioContext;
      const audioContext = new AudioContext;


      navigator.mediaDevices.getUserMedia({audio: true, video: false}).then((stream) => {
        /* assign to gumStream for later use */
        this.gumStream = stream;
        /* use the stream */
        const input = audioContext.createMediaStreamSource(stream);
        // Create the Recorder object and configure to record mono sound (1 channel)
        this.recorder = new Recorder(input, {
          numChannels: 1,
        });
        // start the recording process
        this.recorder.record();
      }).catch((err) => {
        // enable the record button if getUserMedia() fails
        alert(err);
        console.log(err);
        this.isRecording = false;
      });
    },
    stopRecording() {
      if (new Date() - this.startRecordingTime < MINIMUM_RECORD_TIME) {
        return;
      }
      this.state = 2;

      if (this.fetching) {
        return;
      }
      this.isRecording = false;

      // tell the recorder to stop the recording
      this.recorder.stop(); // stop microphone access
      this.gumStream.getAudioTracks()[0].stop();
      // create the wav blob and pass it on to createDownloadLink
      this.recorder.exportWAV(this.createDownloadLink);
    },
    createDownloadLink(blob) {
      const au = document.createElement('audio');
      au.controls = false;
      au.src = URL.createObjectURL(blob);
      this.studentAudio = au;

      const filename = new Date().toISOString();
      const formData = new FormData();
      formData.append('audio_data', blob, filename);
      console.log(this.currentPara);
      formData.append('text', this.currentPara.ref_text);
      formData.append('paraIndex', this.paraIndex);
      formData.append('studentId', this.studentId);
      this.fetching = true;

      axios
          .post(this.url, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then((response) => {
            this.results = response.data;
            console.log(this.results);
            for (const word in this.results['wrong_words']) {
              if (typeof this.wrongWords[word] === 'undefined') {
                this.wrongWords[word] = this.results['wrong_words'][word];
              } else {
                this.wrongWords[word]['cumulative_score'] += this.results['wrong_words'][word]['cumulative_score'];
                this.wrongWords[word]['num'] += this.results['wrong_words'][word]['num'];
              }
            }
            this.cumulativeScores['overall'] += this.results['overall'];
            this.cumulativeScores['fluency'] += this.results['fluency'];
            this.cumulativeScores['tone'] += this.results['tone'];
            this.cumulativeScores['pronunciation'] += this.results['pronunciation'];
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            this.fetching = false;
            this.state = 3;
          });
    },
    stopAudio() {
      try {
        if (this.$refs.teacherAudio) {
          this.$refs.teacherAudio.pause();
          this.$refs.teacherAudio.currentTime = 0;
        }
        if (this.studentAudio) {
          this.studentAudio.pause();
          this.studentAudio.currentTime = 0;
        }
      } catch (err) {
        console.log(err);
      }
    },
    // if student audio is selected, stop teacher audio first, then play student audio
    // and vice versa
    playAudio(version) {
      this.stopAudio();
      if (version === 'student') {
        this.studentAudio.play();
      } else {
        this.$refs.teacherAudio.play();
      }
    },
    goNext() {
      this.stopAudio();
      // next para will check if we should go to next paragraph or end
      this.nextPara();
    },
    end() {
      this.state = 4;
    },
    onShowRewardsPage() {
      if (this.hasClaimedRewards) return;
      this.hasClaimedRewards = true;
      this.showRewardsPage = true;
    },
    onCloseRewards() {
      // set coins
      this.setCoins(this.results.coins);
      this.setCoinLeaderboard(this.results.coin_leaderboard);
      this.showRewardsPage = false;
      if (this.type === 'readaloud') {
        this.$router.push({name: 'oral-readaloud-index'});
      } else if (this.type === 'freeresponse') {
        this.$router.push({name: 'oral-free-response-index'});
      } else {
        this.$router.push({name: 'oral-templated-phrases-index'});
      }
    },
  },
};

</script>

<style lang="scss" scoped>
  a {
    text-decoration: none;
  }
  .paragraph-long {
    max-width: 820px;
  }
  .paragraph-medium {
    max-width: 720px;
  }
  .paragraph-short {
    max-width: 620px;
  }

  .oral-component {
    background: #fff;
    height: 100vh;
    width: 100vw;
    top: 0;
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    &__content {
      margin: 0 1rem;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      &--header {
        margin: .5rem 0;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        &-title {
          font-size: 1.25rem;
          font-weight: 600;
          flex: 1;
        }
        &-para-number {
          font-size: 0.9rem;
          font-weight: 600;
          padding: 0.25rem 0.5rem;
          border-radius: 20px;
          background: #ffcf6d;
        }
      }
      &--passage {
        border-top: 2px solid #ccc;
        border-bottom: 2px solid #ccc;
        padding: 2rem 0rem;
        position: relative;
        &-qns {
          display: flex;
          flex-wrap: wrap;
          :first-child {
            margin-left: 75px;
          }
          p {
            font-size: 1.35rem;
            width: 37.5px;
            display: flex;
            justify-content: center;
            align-items: flex-start;
            height: 60px;
          }
        }
        &-result {
          display: flex;
          justify-content: center;
          &-header {
            position: absolute;
            top: 1.5rem;
            font-size: 1rem;
            font-weight: 600;
          }
          &-body{
            display: flex;
            flex-wrap: wrap;
            font-size: 1.35rem;
            :first-child {
              margin-left: 75px;
            }
          }
          &-footer {
            position: absolute;
            bottom: 1.5rem;
            font-size: .7rem;
            font-weight: 600;
            font-style: italic
          }
        }
      }
      &--dashboard {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        position: relative;
        height: 200px;
        width: 100%;
        margin: 1rem auto;
        &-recorder {
          margin-top: 1rem;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          &-timer {
            position: absolute;
            top: 0;
            font-size: 0.9rem;
            font-weight: 600;
            text-align: center;
          }
          &-text {
            font-size: 0.75rem;
            font-weight: 600;
            font-style: italic;
            margin-top: 1rem;
          }
          &-btn {
            cursor: pointer;
            transition: all 0.3s;
            &:hover {
              transform: scale(1.05);
              &::after {
                transform: scaleX(1.1) scaleY(1.3);
                opacity: 0;
              }
            }
            &:active {
              transform: translateY(-1px);
            }
          }
        }
        &-loading {
          text-align: center;
          position: absolute;
          z-index: -1;
          top: -2.5rem;
        }
        &-results {
          width: 100%;
          display: flex;
          justify-content: center;
          flex-direction: column;
          &-score {
            display: flex;
            justify-content: center;
            align-items: center;
            &-item {
              flex: 1;
              display: flex;
              align-items: center;
              justify-content: space-between;
              flex-direction: column;
              height: 60px;
              text-align: center;
              border-right: 1px solid #d2e8ff;
              margin: 1rem 0;
              &:last-child {
                border-right: none;
              }
            }
            &-name {
              font-size: .85rem;
              font-weight: 700;
              color: #333;
            }
            &-value {
              font-size: 1.5rem;
              font-weight: 700;
              color: #1377e1;
            }
          }
          &-audio {
            display: flex;
            justify-content: center;
            margin-top: 1rem;
            &-btn {
              display: flex;
              justify-content: center;
              width: 250px;
              text-align: center;
              border-radius: 0.5rem;
              font-size: 0.75rem;
              font-weight: 600;
              margin: 0 0.5rem;
              background: #7a58ed;
              box-shadow: 0px 5px #5f47b0;
              color: #fff;
              padding: .5rem 1rem;
              cursor: pointer;
              transition: all 0.3s;
              border: 2px solid #7a58ed;
              &:hover {
                transform: scale(1.03);
                background: #8f6fff;
                &::after {
                  transform: scaleX(1.1) scaleY(1.3);
                  opacity: 0;
                }
              }
              &-teacher {
                background: #fff;
                color: #7a58ed;
                &:hover {
                  background: #7a58ed;
                  color: #fff;
                }
              }
              &:active {
                transform: translateY(-1px);
              }
              i {
                margin-right: 0.5rem;
                font-size: 1rem;
              }
            }
          }
          &-btn-next {
            position: fixed;
            bottom: 0;
            left: 0;
            width: 100%;
            text-align: center;
            font-weight: 600;
            background: #22b492;
            color: #fff;
            padding: .75rem 0;
            cursor: pointer;
            transition: all 0.3s;
            &:hover {
              background: #27dfb4;
              &::after {
              }
            }
          }
        }
        &--final-scene {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          margin-bottom: 2rem;
          &--header {
            font-size: 1.2rem;
            font-weight: 700;
            display: flex;
            justify-content: space-between;
            width: 100%;
            margin-bottom: 1rem;
          }
          &--score {
            display: flex;
            justify-content: center;
            align-items: center;
            border-top: 2px solid #00000029;
            border-bottom: 2px solid #00000029;
            padding: 2rem 0;
            margin-bottom: 2rem;
            flex-wrap: wrap;
            &-item {
              min-width: 8rem;
              flex: 1;
              display: flex;
              align-items: center;
              justify-content: space-between;
              flex-direction: column;
              height: 60px;
              text-align: center;
              border-right: 1px solid #d2e8ff;
              margin-bottom: 1rem;
              &:last-child {
                border-right: none;
              }
            }
            &-name {
              font-size: 1rem;
              font-weight: 700;
              color: #333;
            }
            &-value {
              font-size: 1.75rem;
              font-weight: 700;
              color: #1377e1;
            }
          }
          &--revision {
            &-header{
              text-align: center;
              margin-bottom: 1rem;
            }
            &-list{
              display: flex;
              width: 100%;
              flex-wrap: wrap;
              justify-content: center;
              align-items: center;
              li {
                padding: 0.5rem 1rem;
                border: 2px solid #265fcf;
                font-weight: 700;
                color: #265fcf;
                margin: .5rem;
                border-radius: 2rem;
                cursor: pointer;
                &:hover {
                  color: #fff;
                  background: #265fcf;
                }
              }
            }
          }
          &--reward-btn {
            margin-top: 3rem;
            cursor: pointer;
            width: 250px;
            text-align: center;
            border-radius: 0.5rem;
            font-size: 1.1rem;
            font-weight: 600;
            background: #22b492;
            box-shadow: 0px 5px #2e8771;
            color: #fff;
            padding: .5rem 1rem;
            cursor: pointer;
            transition: all 0.3s;
            &:hover {
              transform: scale(1.03);
              background: #3de7c0;
              box-shadow: 0px 5px #3bc6a4;
              &::after {
                transform: scaleX(1.1) scaleY(1.3);
                opacity: 0;
              }
            }
            &:active {
              transform: translateY(-1px);
            }
          }
        }
      }
    }
  }

  .close {
    position: absolute;
    left: 32px;
    top: 32px;
    width: 32px;
    height: 32px;
    opacity: .3;
    transition: all 0.3s;
  }

  @media only screen and (max-height: 740px) {
    .oral-component__content--passage {
      padding: 1.5rem 0;
      &-qns {
        p {
          height: 50px;
        }
      }
    }
    .oral-component__content--dashboard {
      height: 150px;
      &-recorder {
        margin-top: 0.5rem;
      }
      &-results {
        &-score-item {
          margin-top: 0;
          margin-bottom: 0.5rem;
        }
        &-audio {
          margin-top: 0.5rem;
        }
      }
    }
  }
  @media only screen and (max-height: 650px) {
    .oral-component__content--dashboard {
      &-recorder {
        &-text {
          font-size: 0.7rem;
          margin-top: 0.5rem;
        }
        &-btn {
          img {
            width: 80px;
          }
        }
      }

      &-results {
        &-audio-btn {
          font-size: 0.7rem;
          box-shadow: 0px 3px #5f47b0;
          padding: 0.3rem;
          width: 210px;
        }
        &-btn-next {
          font-size: 0.8rem;
          padding: 0.5rem 0;
        }
        &-score {
          &-name{
            font-size: 0.75rem;
          }
          &-value {
            font-size: 1.25rem;
          }
          &-item {
            height: 50px;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 830px) {
    .oral-component__content--dashboard {
      height: 150px;
    }
  }
  @media only screen and (max-width: 680px) {
    .oral-component__content--passage {
      padding: 1.5rem 0;
      &-qns {
        p {
          height: 50px;
        }
      }
    }
    .oral-component__content--dashboard {
      &-results {
        &-audio {
          margin-top: 0.5rem;
          &-btn {
            padding: .25rem 0;
            margin: 0 0.25rem;
            width: 50%;
            font-size: 0.6rem;
            box-shadow: 0px 3px #5f47b0;
            i {
              font-size: 0.8rem;
              margin-right: 0.25rem;
            }
          }
        }
        &-btn-next {
          font-size: 0.8rem;
          padding: 0.5rem 0;
        }
        &-score {
          &-name{
            font-size: 0.75rem;
          }
          &-value {
            font-size: 1.25rem;
          }
          &-item {
            margin: 0;
            height: 50px;
            flex: auto;
            padding: 0;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 530px) {
    .oral-component__content--dashboard {
      height: 110px;
      margin-top: 0.5rem;
    }
  }
  @media only screen and (max-width: 490px) {
    .oral-component__content--dashboard {
      height: 80px;
      margin-top: 0.5rem;
    }
  }
  @media only screen and (max-width: 490px) {
    .close {
      display: none;
    }
    .oral-component__content--dashboard {
      height: 100px;
      margin-top: 0.5rem;
    }
  }
  @media only screen and (max-width: 530px) {
    .oral-component__content--header {
      &-title {
        font-size: 1rem;
      }
      &-para-number {
        font-size: 0.7rem;
      }
    }
    .oral-component__content--dashboard {
      margin-top: 1.5rem;
      &-recorder {
        margin-top: 0.25rem;
        img {
          width: 80px;
        }
        &-text {
          margin-top: 0.5rem;
          font-size: 0.7rem;
        }
      }
      &-loading {
        top: -3.5rem;
      }
    }
    .oral-component__content--passage {
      padding: .75rem 0;
      &-qns {
        :first-child {
          margin-left: 60px;
        }
        p {
        width: 30px;
        font-size: 1.25rem;
        }
      }
    }
    .oral-component__content--passage-result-body {
      font-size: 1.25rem;
      :first-child {
        margin-left: 60px;
      }
    }
  }
  </style>

