<template>
  <div class="purchase-popup__component animated fadeIn faster">
    <!-- Not Enough Diamonds -->
    <div v-if="!canUserRedeem"
      class="purchase-popup__error--container animated bounceIn fast">
      <i class="fas fa-times"
        @click="closePopup"
      ></i>
      <div class="purchase-popup__error--content">
        <h2 class="purchase-popup__error--text-header">
          Oh No!
          <i class="far fa-frown"></i>
        </h2>
        <img class="purchase-popup__img"
          :src="itemClicked.photo">

        <p class="purchase-popup__error--text-desc">
          <span v-if="reasonForRejection === 'insufficient_diamonds'">
            You don't have enough diamonds to purchase this.
          </span>
          <span v-if="reasonForRejection === 'not_member'">
            You can only redeem items after you have become a member.
          </span>
          <span v-if="reasonForRejection === 'sold_out'">
            This item is sold out.
          </span>
        </p>
      </div>
      <div
        class="purchase-popup__btn"
        @click="closePopup">
        <p>Return to Shop</p>
      </div>
    </div>
    <!-- Enough Diamonds -->
    <!-- Confirm Purchase -->
    <div
      v-if="canUserRedeem && !isItemRedeemed"
      class="purchase-popup__confirmation--container animated bounceIn fast">
      <i class="fas fa-times"
        @click="closePopup"
      ></i>
      <div class="purchase-popup__confirmation--content">
        <h2 class="purchase-popup__confirmation--text-header">
          Confirm Purchase
        </h2>
        <img class="purchase-popup__img"
          :src="itemClicked.photo">
        <p class="purchase-popup__confirmation--text-desc">
          {{ itemClicked.title }}
        </p>
        <div
          class="purchase-popup__btn"
          @click="confirmPurchase">
          <i class="fas fa-gem"></i>
          {{ itemClicked.price }}
        </div>
      </div>
    </div>
    <!-- Purchase Successful -->
    <div
      v-if="canUserRedeem && isItemRedeemed"
      class="purchase-popup__success--container animated flipInX faster">
      <i class="fas fa-times"
        @click="closePopup"
      ></i>
      <div class="purchase-popup__success--content">
        <h2 class="purchase-popup__confirmation--text-header">
          Successfully Redeemed
        </h2>
        <i class="far fa-check-circle purchase-popup__icon"></i>
        <p class="purchase-popup__confirmation--text-desc">
          Please contact 9820-7272 for delivery assistance.
        </p>
        <div
          class="purchase-popup__btn"
          @click="closePopup">
          <p>Continue</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'PurchasePopup',
  components: {
  },
  props: {
    canUserRedeem: Boolean,
    itemClicked: Object,
    redeemItem: Function,
    reasonForRejection: String,
  },
  data() {
    return {
      isItemRedeemed: false,
    };
  },
  computed: {
  },
  mounted: function() {
  },
  methods: {
    closePopup() {
      this.$emit('close-popup');
    },
    confirmPurchase() {
      this.redeemItem(this.itemClicked);
      this.isItemRedeemed = !this.isItemRedeemed;
    },
  },
};
</script>

<style lang="scss" scoped>
  .purchase-popup__icon {
    width: 250px;
    margin-bottom: .5rem;
    border-top: 2px solid #f1f1f1;
    border-bottom: 2px solid #f1f1f1;
    padding: 1rem 0;
    font-size: 7rem;
  }
  .purchase-popup__img {
    width: 300px;
    max-height: 300px;
    object-fit: cover;
    margin-bottom: .5rem;
    border-top: 2px solid #f1f1f1;
    border-bottom: 2px solid #f1f1f1;
    padding: 1rem 0
  }
  .purchase-popup {
    &__component {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 30;
      width: 100vw;
      height: 100vh;
      background: rgba(0, 0, 0, 0.8);
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &__error,
    &__confirmation,
    &__success {
      &--container {
        position: relative;
        width: 20rem;
        background: #fff;
        border-radius: 3px;
        .fa-times {
          cursor: pointer;
          position: absolute;
          top: -.5rem;
          right: -.5rem;
          width: 1.75rem;
          height: 1.75rem;
          border-radius: 50%;
          background: #ff3c3c;
          color: #fff;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: 700;
          font-size: 1.2rem;
        }
      }
      &--content {
        padding: 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-align: center;
      }
      &--text{
        &-header {
          margin-bottom: 0.5rem;
        }
        &-icon {
        }
        &-desc {
          max-width: 250px;
          font-style: italic;
          font-size: 0.9rem;
          margin-bottom: 1.75rem;
        }
      }
    }
    &__btn {
      cursor: pointer;
      color: #fff;
      position: absolute;
      width: 100%;
      bottom: 0;
      background: #3088e6;
      padding: 0.5rem;
      border-bottom-left-radius: 3px;
      border-bottom-right-radius: 3px;
      text-align: center;
      font-weight: 700;
    }
  }

  @media only screen and (max-height: 675px) {
    .purchase-popup__img {
      width: 250px;
    }
  }
  @media only screen and (max-height: 600px) {
    .purchase-popup__img {
      width: 200px;
    }
    .purchase-popup__error--container,
    .purchase-popup__confirmation--container,
    .purchase-popup__success--container {
      width: 17.5rem;
    }
  }
  @media only screen and (max-height: 515px) {
    .purchase-popup__img {
      width: 150px;
    }
    .purchase-popup__error--container,
    .purchase-popup__confirmation--container,
    .purchase-popup__success--container {
      width: 15rem;
    }
  }
  @media only screen and (max-width: 450px) {
    .purchase-popup__img {
      width: 200px;
    }
    .purchase-popup__error--container,
    .purchase-popup__confirmation--container,
    .purchase-popup__success--container {
      width: 17.5rem;
    }
  }
  @media only screen and (max-width: 400px) {
    .purchase-popup__img {
      width: 150px;
    }
    .purchase-popup__error--container,
    .purchase-popup__confirmation--container,
    .purchase-popup__success--container {
      width: 15rem;
    }
  }
</style>
