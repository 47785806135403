<template>
  <div class="container">
    <div class="header"><div></div>
      <router-link
        v-if="previousPageName && (typeof previousPageParams === 'undefined')"
        tag="div"
        :to="{name: previousPageName}"
        class="btn-return">
        <i class="fa-solid fa-chevron-left"></i>
      </router-link>
      <router-link
        v-if="previousPageName && (typeof previousPageParams !== 'undefined')"
        tag="div"
        :to="{name: previousPageName, params: previousPageParams}"
        class="btn-return">
        <i class="fa-solid fa-chevron-left"></i>
      </router-link>
      <!-- if previousPageName is empty, go back to main page -->
      <router-link
        v-if="!previousPageName"
        tag="div"
        :to="{name: 'profile-main'}"
        class="btn-return">
          <i class="fa-solid fa-chevron-left"></i>
      </router-link>
      <div class="header-text--container">
        <i class="icon-decorative fas" :class="fasClass"></i>
        <div class="header-text animated flipInX faster">
          <h2 v-if="title" class="header-text-main">
            {{ title }}
          </h2>
          <p v-if="subtitle" class="header-text-sub">
            {{ subtitle }}
          </p>
        </div>
      </div>
    </div>
    <div class="body animated fadeIn" :style="{background: backgroundStyle}">
      <slot></slot>
    </div>
  </div>
</template>

<script>


export default {
  name: 'BlockContainer',
  components: {
  },
  props: {
    title: String,
    subtitle: String,
    faIcon: String,
    bodyBackgroundColor: String,
    // where page should go to on clicking back button
    previousPageName: String,
    previousPageParams: Object,
  },
  data() {
    return {
    };
  },
  computed: {
    fasClass() {
      if (this.faIcon) {
        return 'fa-' + this.faIcon;
      };
      return 'fa-rocket';
    },
    // if bodyBackgroundColor is passed in, use that; otherwise use default style
    backgroundStyle() {
      if (this.bodyBackgroundColor) return this.bodyBackgroundColor;
      // default
      const overlay = 'linear-gradient(to bottom right, rgba(29, 36, 77, 0.7882352941), rgba(21, 24, 41, 0.2352941176))';
      return overlay;
    },
  },
  mounted() {
  },
  methods: {
  },
};
</script>


<style lang="scss" scoped>
  .container {
    width: 90%;
    height: 90%;
    border-top: 5px solid #f582ff;
    border-left: 5px solid #f582ff;
    border-bottom: 8px solid #3f83c57a;
    border-right: 8px solid #3f83c57a;
    border-radius: 0.75rem;
    max-width: 1280px;
    max-height: 1080px;
  }
  .header {
    width: 100%;
    position: relative;
    overflow: hidden;
    background: linear-gradient(to top, #29b5ff, #6472FB);
    color: #fff;
    height: 160px;
    display: flex;
    align-items: center;
    border-bottom: 7px solid #67d3f7;
    border-top-left-radius: .5rem;
    .icon-decorative {
      position: absolute;
      top: -1rem;
      left: -2rem;
      font-size: 12rem;
      opacity: 0.1;
    }
    .btn-return {
      cursor: pointer;
      font-size: 2rem;
      background: linear-gradient(to bottom right, #d1f0ff, #a7bfff);
      display: flex;
      justify-content: center;
      align-items: center;
      width: 90px;
      height: 100%;
      border-top-left-radius: .5rem;
      border-right: 7px solid #67d3f7;
      text-shadow: 2px 4px rgba(0,0,0,.5);
      &:hover {
        background: #4efdec;
        color: #fff;
      }
    }
    &-text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-left: 2rem;
      &-main {
        font-size: 2.25rem;
        text-shadow: 4px 6px rgba(0,0,0,.5);
      }
      &--container {
        overflow: hidden;
        position: relative;
        height: 100%;
        width: 100%;
        display: flex;
      }
      &-sub {
        font-weight: 600;
        font-size: 1.1rem;
        margin-top: -0.75rem;
        text-shadow: 2px 4px rgba(0,0,0,.4);
      }
    }
  }
  .body {
    height: calc(100% - 160px);
    position: relative;
  }

  @media only screen and (max-width: 1366px),
              screen and (max-height: 870px)  {
                .header {
                  height: 140px;
                  &-text {
                    &-main {
                      font-size: 2rem;
                    }
                    &-sub {
                      font-size: 1rem;
                    }
                  }
                  .icon-decorative {
                    font-size: 11rem;
                  }
                  .btn-return {
                    width: 80px;
                  }
                }
                .body {
                  height: calc(100% - 140px);
                }
              }
  @media only screen and (max-width: 1150px),
              screen and (max-height: 720px) {
                .header {
                  height: 120px;
                  &-text {
                    &-main {
                      font-size: 1.8rem;
                      text-shadow: 4px 4px rgba(0,0,0,.5);
                    }
                    &-sub {
                      font-size: .85rem;
                      text-shadow: 2px 3px rgba(0,0,0,.4);
                    }
                  }
                  .icon-decorative {
                    font-size: 10rem;
                  }
                  .btn-return {
                    width: 70px;
                  }
                }
                .body {
                  height: calc(100% - 120px);
                }
              }
    @media only screen and (max-width: 960px),
                screen and (max-height: 620px) {
                .container {
                  width: 100%;
                  border: none;
                  border-radius: 0;
                  height: calc(100% - 100px);
                }
                .header {
                  border-bottom: 5px solid #67d3f7;
                  height: 100px;
                  border-radius: 0;
                  &-text {
                    &-main {
                      font-size: 1.6rem;
                    }
                    &-sub {
                      font-size: .75rem;
                      margin-top: -.5rem;
                    }
                  }
                  .icon-decorative {
                    font-size: 9rem;
                  }
                  .btn-return {
                    width: 60px;
                    border-radius: 0;
                  }
                }
                .body {
                  height: calc(100% - 50px);
                }
              }
</style>
