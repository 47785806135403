import { render, staticRenderFns } from "./PictureVocabQuizComponent.vue?vue&type=template&id=1b545b20&scoped=true&"
import script from "./PictureVocabQuizComponent.vue?vue&type=script&lang=js&"
export * from "./PictureVocabQuizComponent.vue?vue&type=script&lang=js&"
import style0 from "./PictureVocabQuizComponent.vue?vue&type=style&index=0&id=1b545b20&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1b545b20",
  null
  
)

export default component.exports