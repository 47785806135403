<!--
  this is a component that is embededded in another page like daily challenge or quiz after they have completed
 -->
<template>
  <div class="main">
    <div class="main--container">
      <div class="main--header">
        <h2>
          <i class="fa-solid fa-rocket"></i>
          Missions Progression
        </h2>
        <i class="main--header__btn-close fas fa-times"
        @click.prevent="emitClose"></i>
      </div>
      <div class="body-content">
        <div class="mission-items">
          <mission-row
            v-for="(mission, index) in selectedMissions" :key="index" :info="mission"
            @claim="claim"
          class="mission-item">
          </mission-row>
        </div>
      </div>
    </div>
    <basic-reward v-if="showRewardsPage"
      :diamonds-to-add="2"
      :close-rewards-callback="onCloseRewards">
    </basic-reward>
  </div>
</template>
<script>

import {mapMutations, mapGetters} from 'vuex';

import BasicRewardComponent
  from '../../../common/BasicRewardComponent.vue';

import MissionRowComponent
  from './MissionRowComponent.vue';

export default {
  name: 'EmbeddedMissionCompletion',
  components: {
    BasicReward: BasicRewardComponent,
    MissionRow: MissionRowComponent,
  },
  props: {
  },
  data() {
    return {
      showRewardsPage: false,
      diamondsToAdd: 1,
    };
  },
  computed: {
    selectedMissions() {
      return this.getMissions()('daily_challenge') || [];
    },
  },
  mounted() {
  },
  methods: {
    ...mapMutations(['setDiamonds', 'setMissions']),
    ...mapGetters(['getMissions']),
    // this closes the embeddedmissionscompletion component
    emitClose() {
      // console.log('emit close');
      this.$emit('close');
    },
    // opens the showRewards Page
    claim(args) {
      this.setDiamonds(args.responseData.diamonds);
      this.setMissions(args.responseData.missions);
      this.diamondsToAdd = args.responseData.diamonds_to_add;
      this.showRewardsPage = true;
      // console.log(this.diamondsToAdd, this.showRewardsPage);
    },
    // called after user has opened rewards page, and clicks on it to close rewards page
    onCloseRewards() {
      this.showRewardsPage = false;
    },
  },
};
</script>

<style lang="scss" scoped>
  .body-content {
    height: 100%;
    width: 100%;
    overflow: hidden;
    position: relative;
    background: linear-gradient(to right bottom, rgba(29, 36, 77, 0.79), rgba(21, 24, 41, 0.86));
    .mission-items {
      padding: 2rem;
      overflow-y: auto;
      overflow-x: hidden;
      height: 100%;
    }
  }
  // need to fix zindex? currently set to everything
  .main {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 30;
    color: #fff;
    overflow: hidden;
    background-image: url("../../../../assets/bg-missions.jpg");
    background-size: cover;
    background-position: bottom right;
    display: flex;
    justify-content: center;
    align-items: center;
    &--container {
      margin: 1rem;
      width: 1024px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      height: 90%;
      border-top: 5px solid #f582ff;
      border-left: 5px solid #f582ff;
      border-bottom: 8px solid rgba(63, 131, 197, .4784313725);
      border-right: 8px solid rgba(63, 131, 197, .4784313725);
      border-radius: .75rem;
      max-width: 1280px;
      max-height: 1080px;
    }
    &--header {
      padding-left: 1rem;
      width: 100%;
      position: relative;
      overflow: hidden;
      background: linear-gradient(to top, #29b5ff, #6472FB);
      color: #fff;
      height: 160px;
      display: flex;
      align-items: center;
      border-bottom: 7px solid #67d3f7;
      border-top-left-radius: .5rem;
      &__btn-close {
        position: absolute;
        top: 1rem;
        right: 1rem;
        cursor: pointer;
        font-size: 2rem;
        color: #dedaff85;
        &:hover {
          color: #fff;
        }

      }
      .fa-rocket {
        position: absolute;
        top: -1rem;
        left: 1rem;
        font-size: 12rem;
        opacity: .1;
      }
    }
    // &--subheader {
    //   font-size: 0.8rem;
    //   z-index: 30;
    // }
  }
  // &__diamonds {
  //   background: #1989fd;
  // }

  .sunburst {
    background: #1989fd;
    z-index: 10;
    position: absolute;
  }

  @keyframes rotating-anim {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.rotating-anim {
  -webkit-animation: rotating-anim 20s linear infinite;
  -moz-animation: rotating-anim 20s linear infinite;
  -ms-animation: rotating-anim 20s linear infinite;
  -o-animation: rotating-anim 20s linear infinite;
  animation: rotating-anim 20s linear infinite;
}

@media only screen and (max-width: 730px),
  screen and (max-height: 720px) {
    .main--container {
      margin: 0;
      height: 100%;
      width: 100%;
    }
    .body-content {
      .mission-items {
        padding: 1rem;
      }
    }
  }
</style>
