<template>
  <div id="active-vocab-container">
    <quiz-main
      quiz-type="oralActiveVocab"
      :quiz-id="topicId"
    ></quiz-main>
  </div>
</template>

<script>
import QuizMainComponent from '../../common/quiz/QuizMainComponent.vue';

export default {
  name: 'NewOralPictureVocabQuiz',
  components: {
    QuizMain: QuizMainComponent,
  },
  props: {
    topicId: Number,
  },
  data() {
    return {};
  },
  computed: {
  },
};

</script>

<style lang="scss" scoped>
  #active-vocab-container {
    margin: 0 auto;
    height: 100%;
  }
</style>
