import Vue from 'vue';
import _filter from 'lodash.filter';


const getDefaultState = () => {
  // note: index resets for every subtrack
  const results = {
    learningTracks: [],
    numberLearningTracks: {},
    ebooks: [],
    // saves this route so we can route back after quiz ends
    routePriorToStartingQuiz: null,
    // used to determine if subtopic word list should be shown or hidden
    isShowSuptopicWordList: false,
  };

  return results;
};

const state = getDefaultState();

// changed name to learningTrackModule rather than learningTrack
// since it was easy to accidentally name a variable learningTrack
export const learningTrackModule = {
  namespaced: true,
  state,
  mutations: {
    resetStateMutation(state) {
      Object.assign(state, getDefaultState());
    },
    setLearningTracks(state, value) {
      state.learningTracks = value;
    },
    setIsShowSuptopicWordList(state, value) {
      state.isShowSuptopicWordList = value;
    },
    addToEbooks(state, value) {
      Vue.set(state.ebooks,
          state.ebooks.length, value);
    },
    replaceEbookByPk(state, payload) {
      const index = state.ebooks.findIndex((ebook) => {
        return ebook.pk === payload.pk;
      });

      Vue.set(state.ebooks, index, payload.value);
    },
    setRoutePriorToStartingQuiz(state, value) {
      state.routePriorToStartingQuiz = value;
    },
    setNumberLearningTracks(state, payload) {
      Vue.set(state.numberLearningTracks,
          payload.category, payload.value);
    },
  },
  actions: {
    initialLoadLearningTracks: ({commit, state}, payload) => {
      commit('setLearningTracks', payload);
      const counts = {};

      for (let i = 0; i < payload.length; i++) {
        const learningTrackType = payload[i].learning_track_type;
        if (typeof counts[learningTrackType] === 'undefined') {
          counts[learningTrackType] = 0;
        };

        counts[learningTrackType]++;
      };
      for (const key in counts) {
        commit('setNumberLearningTracks', {
          category: key,
          value: counts[key],
        });
      }
    },
  },
  getters: {
    // use learning track name + _ + subtrack as key
    // only for normal difficulty
    subtracksCompletionPercentage: (state, getters, rootState) => {
      const total = {};
      const completed = {};
      const results = {};

      for (let i = 0; i < state.learningTracks.length; i++) {
        // only works for vocab and pinyin quizzes for now
        if (state.learningTracks[i].learning_track_type !== 'vocabulary' &&
          state.learningTracks[i].learning_track_type !== 'pinyin') {
          continue;
        };

        for (let j = 0; j < state.learningTracks[i].items.length; j++) {
          const item = state.learningTracks[i].items[j];
          const key = state.learningTracks[i].name + '_' +
              item.subtrack;

          if (item.difficulty !== 'normal') continue;

          if (typeof total[key] === 'undefined') {
            total[key] = 0;
            completed[key] = 0;
            results[key] = 0;
          };

          // each quiz has max of 3 stars
          total[key] += 3;
          completed[key] +=
              rootState.chineseQuizStars[item.chinese_quiz_id];
        };
      };

      for (const [key, value] of Object.entries(total)) {
        results[key] = Math.floor(completed[key] / value
            * 100) / 100;
      };
      // return rootState.chineseQuizStars;
      return results;
    },
    // method-style access (because we want to pass parameters)
    // https://vuex.vuejs.org/guide/getters.html
    // takes a learning track id, and returns the learning track object
    getEbookById(state) {
      return (pk) => {
        return _filter(state.ebooks, (ebook) => {
          return ebook.pk === pk;
        })[0];
      };
    },
    getLearningTrackById(state) {
      return (pk) => {
        const filteredResults = _filter(state.learningTracks, (lt) => {
          return lt.pk === pk;
        });
        return filteredResults[0];
      };
    },
    // skip if learning track is writing
    // returns learning track item based on quiz id, otherwise return null
    getLearningTrackItemByQuizId(state) {
      return (pk) => {
        for (let i = 0; i < state.learningTracks.length; i++) {
          if (state.learningTracks[i].learning_track_type === 'writing') {
            continue;
          };

          const items = state.learningTracks[i].items;
          for (let j = 0; j < items.length; j++) {
            if (Number(items[j].chinese_quiz_id) == pk) return items[j];
          };
        };
        return null;
      };
    },
    getLearningTrackItemsByPkSubtrack(state) {
      return (learningTrackPk, subtrack, includeChallengeItems) => {
        // by default, don't include challenge items
        if (typeof includeChallengeItems === 'undefined') {
          includeChallengeItems = false;
        };

        const selectedLearningTrack =
        state.learningTracks.find((learningTrack) => {
          return 'pk' in learningTrack && learningTrack.pk === learningTrackPk;
        });

        console.log(selectedLearningTrack);
        // validation -> only continue if there is a selectedLearningTrack
        if (typeof selectedLearningTrack === 'undefined') return null;

        // returns all items
        if (includeChallengeItems) {
          return _filter(selectedLearningTrack.items, (lti) => {
            return lti.subtrack === subtrack;
          });
        }
        // returns items that are normal difficulty
        return _filter(selectedLearningTrack.items, (lti) => {
          return lti.subtrack === subtrack && lti.difficulty === 'normal';
        });
      };
    },
    getLearningTracksByType(state) {
      return (learningTrackType) => {
        return _filter(state.learningTracks, (lt) => {
          return lt.learning_track_type === learningTrackType;
        });
      };
    },
  },
};
