<template>
  <div class="raid-index__component">
    <raid-start-up
      v-if="gameState === 'index'"
      @getPets="getPets"
    ></raid-start-up>
    <pet-selection
      v-if="gameState === 'petSelection'"
      @startBattle="startBattle"
      :pets-for-selection='petsForSelection'
    ></pet-selection>
    <battle-arena
      v-if="gameState === 'battleArena' ||
      gameState === 'question' ||
      gameState === 'raidResult'"
      @selectSpell="selectSpell"
    ></battle-arena>
    <game-main
      v-if="gameState === 'question'"
      :question="questionData['question']"
      :show-answer="showAnswer"
      @answerQuestion="answerQuestion"
    ></game-main>
    <!-- need to improve -->
    <raid-result
      v-if="gameState === 'raidResult'"
      :coins-to-add="questionData.coins_to_add"
      :diamonds-to-add="questionData.diamonds_to_add"
      :diamonds-obtained-this-week="questionData.diamonds_obtained_this_week"
    ></raid-result>
  </div>
</template>

<script>

import axios from 'axios';
import {mapState, mapMutations, mapActions} from 'vuex';

import RaidStartUpComponent
  from './indexcommon/StartUpComponent.vue';
import PetSelectionComponent
  from './indexcommon/PetSelectionComponent.vue';
import BattleArenaComponent
  from './battlearena/BattleArenaComponent.vue';
import GameMainComponent
  from './GameMainComponent.vue';
import RaidResultComponent
  from './indexcommon/RaidResultComponent.vue';

export default {
  name: 'RaidIndex',
  components: {
    RaidStartUp: RaidStartUpComponent,
    PetSelection: PetSelectionComponent,
    BattleArena: BattleArenaComponent,
    GameMain: GameMainComponent,
    RaidResult: RaidResultComponent,
  },
  props: {
  },
  data() {
    return {
      questionData: {},
      battlePets: [],
      spellSelected: -1,
      showAnswer: false,
      subCategory: 'level',
    };
  },
  computed: {
    ...mapState(['level']),
    ...mapState('raid', ['gameState', 'fetching']),
    // poor code, refactor in the future
    gameResults() {
      if (!this.questionData ||
          typeof this.questionData.quiz_ended === 'undefined') return null;

      if (!this.questionData.quiz_ended) return null;
      return {
      };
    },
  },
  mounted: function() {
    console.log(this.level);
    // if not primary school level, go to profile page
    if (this.level !== 'primary_one' && this.level !== 'primary_two' &&
      this.level !== 'primary_three' && this.level !== 'primary_four' &&
      this.level !== 'primary_five' && this.level !== 'primary_six') {
      this.$router.push({name: 'profile-main'});
    };
  },
  beforeDestroy() {
    this.resetStateMutation();
  },
  methods: {
    ...mapMutations(['setDiamonds']),
    ...mapMutations('raid', ['goToGameState', 'setGameValues',
      'resetStateMutation', 'setFetching']),
    ...mapActions('raid', ['playBattleResults']),
    // pulls a list of pets for selection
    getPets(subCategory) {
      this.subCategory = subCategory;
      axios
          .get('/gamification/pet/can_battle_pets/')
          .then((response) => {
            this.petsForSelection = response.data;
            this.goToGameState('petSelection');
          });
    },
    // KIV FOR NOW -> always raid boss id of 1
    startBattle(petId) {
      if (this.fetching) return;
      this.setFetching(true);
      const d = new Date();
      const dString = d.getFullYear() + '-' +
        (d.getMonth() + 1) + '-' + d.getDate();
      axios
          .post('/api/v1/brain/chinesedailyquiz/', {
            dailyChallengeUnlockedDate: dString,
            userAgent: window.navigator.userAgent,
            category: 'application',
            subCategory: this.subCategory,
            petId: petId,
            raidBoss: 1,
          })
          .then((response) => {
            console.log(response.data);
            this.setGameValues(response.data.pet_battle);
            delete response.data.pet_battle;
            this.questionData = response.data;
            this.goToGameState('battleArena');
            this.setFetching(false);
          });
    },
    selectSpell(spellId) {
      this.spellSelected = spellId;
      this.goToGameState('question');
    },
    answerQuestion(choice) {
      // prevents multiple calls to server
      if (this.fetching) return;
      this.setFetching(true);

      // this.studentAnswer = choice;
      const url = '/api/v1/brain/quizattempt/' +
        this.questionData.quiz_attempt_id + '/raidquestionattempt/';

      const questionAttemptId = this.questionData.next_question_attempt_id;

      // the first time we answer, timeSpentOnResult is 0,
      // so adding it doesnt change anything
      axios
          .post(url, {
            questionAttemptId: questionAttemptId,
            attemptedAnswer: choice,
            // there is no timer for raids (more for the self-assessment book)
            timerDuration: 0,
            spellSelected: this.spellSelected,
          })
          .then((response) => {
            console.log(response.data);
            this.showAnswer = true;
            // we want to show the correct results for 2s
            // before we show animation
            setTimeout(() => {
              this.goToGameState('battleArena');
              this.playBattleResults(response.data['battle_results'])
                  .then(() => {
                    this.questionData = response.data;
                    // will only be called if game has ended
                    if (typeof response.data.diamonds !== 'undefined') {
                      this.setDiamonds(response.data.diamonds);
                    }
                  });

              this.showAnswer = false;
              this.setFetching(false);
            }, 3300);
          });
    },
  },
};
</script>

<style lang="scss" scoped>
  .raid-index__component {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    background: url(../../../src/assets/raid/bg-blue.png);
  }
</style>
