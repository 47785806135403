<!-- Layout for ./learningtrack/ebook/.. module -->
<template>
  <div class="learning-track-ebook__component row">
    <div class="learning-track-ebook__header">
      <router-link
        class="learning-track-ebook__header--back"
        :to="{name: 'learning-track-ebook-dashboard'}">
        <i class="fas fa-angle-left"></i>
      </router-link>
      <h2>我的电子书</h2>
    </div>
    <!-- lightbox gallery -->
    <div class="learning-track-ebook__workspace">
      <div
        class="learning-track-ebook__workspace--gallery"
        v-if="showGallery">
        <div class="learning-track-ebook__workspace--gallery-bg"></div>
        <div class="learning-track-ebook__workspace--gallery-content row">
          <div class="learning-track-ebook__workspace--gallery-header">
            <h2>图片</h2>
            <!-- TODO: On click <i> close gallery -->
            <i
              @click.prevent="showGallery=false"
              class="fas fa-times"></i>
          </div>
          <div class="learning-track-ebook__workspace--gallery-btns">
            <button
              @click.prevent="galleryPicsCategory='小猪'"
              class="learning-track-ebook__workspace--gallery-btn">
              小猪
            </button>
            <button
              @click.prevent="galleryPicsCategory='小狼'"
              class="learning-track-ebook__workspace--gallery-btn">
              小狼
            </button>
            <button
              @click.prevent="galleryPicsCategory='圣诞老人'"
              class="learning-track-ebook__workspace--gallery-btn">
              圣诞老人
            </button>
            <button
              @click.prevent="galleryPicsCategory='礼物'"
              class="learning-track-ebook__workspace--gallery-btn">
              礼物
            </button>
            <button
              @click.prevent="galleryPicsCategory='汤圆'"
              class="learning-track-ebook__workspace--gallery-btn">
              汤圆
            </button>
            <button
              @click.prevent="galleryPicsCategory='饺子'"
              class="learning-track-ebook__workspace--gallery-btn">
              饺子</button>
            <button
              @click.prevent="galleryPicsCategory='其他'"
              class="learning-track-ebook__workspace--gallery-btn">
              其他</button>
          </div>
          <div class="learning-track-ebook__workspace--gallery-images">
            <!-- TODO: On click, upload the selected image to current page -->
            <img
              class="learning-track-ebook__workspace--gallery-image"
              v-for="galleryPic in filteredGalleryPics"
              :key="galleryPic.index"
              :src="galleryPic.src"
              @click.prevent="setGalleryPic(galleryPic)">
          </div>
        </div>
      </div>
      <form class="learning-track-ebook__workspace--form">
        <h3 class="learning-track-ebook__subheader">题目</h3>
        <input
          type="text"
          :value="newTitle"
          @input="updateNewTitle"
          @keydown.enter.prevent
          class="learning-track-ebook__workspace--title">
        <h3 class="learning-track-ebook__subheader">图画</h3>
        <div class="learning-track-ebook__workspace--img">
          <!-- v-if show icon if no pictures -->
          <i
            v-if="!picture"
            class="far fa-image img-placeholder"></i>
          <!-- else show actual uploaded img -->
          <img
            v-if="picture"
            class="view-ebook__component--img animated"
            :class="newAnimation"
            :src="picture">
          <!-- :preview="true" -->
          <image-uploader
            :debug="1"
            :preview="false"
            :quality="1.0"
            :autoRotate=true
            outputFormat="verbose"
            :className="['fileinput', { 'fileinput--loaded' : hasImage }]"
            capture="environment"
            accept="image/*"
            doNotResize="['gif', 'svg']"
            @input="setImage"
            @onComplete="completeSettingImage"
          >
            <label class="image-upload-btn--container"
              for="fileInput" slot="upload-label">
              <div class="image-upload-btn">
                <figure>
                  <i class="fas fa-camera"></i>
                </figure>
                <span class="upload-caption">
                  {{
                  hasImage ? "Replace Image" : "Upload Image"
                  }}
                </span>
              </div>
            </label>
          </image-uploader>
          <button
            class="btn-show-gallery"
            @click.prevent="openGallery">
            Show from gallery
          </button>
        </div>
        <h3 class="learning-track-ebook__subheader">动画选择</h3>
        <div>
          <input type="radio" id="heart-beat"
            value="heartBeat" v-model="newAnimation">
          <label for="heart-beat">Heartbeat</label>

          <input type="radio" id="rubberBand" value="rubberBand"
            v-model="newAnimation">
          <label for="rubberBand">Rubberband</label>
          <br>

          <input type="radio" id="bounce" value="bounce"
            v-model="newAnimation">
          <label for="bounce">Bounce</label>

          <input type="radio" id="flash" value="flash"
            v-model="newAnimation">
          <label for="flash">Flash</label>
          <br>

          <input type="radio" id="flip" value="flip"
            v-model="newAnimation">
          <label for="flip">Flip</label>

          <input type="radio" id="rollIn" value="rollIn"
            v-model="newAnimation">
          <label for="rollIn">Roll In</label>
          <br>
        </div>
        <h3 class="learning-track-ebook__subheader">内容</h3>
        <textarea
          maxlength="120"
          placeholder="请在此打字..."
          :value="newContent"
          @input="updateNewContent"
          class="learning-track-ebook__workspace--text">
        </textarea>
        <p class="page-counter">
          Page: {{ pageIndex + 1 }} / {{ ebook.pages.length }}
        </p>
      </form>
      <!-- <h2>{{ ebook }}</h2> -->
      <div class="learning-track-ebook__workspace--pages">
        <button
          @click.prevent="savePage"
          class="save-btn">
          <i class="fas fa-save"></i>
          <span>SAVE PROGRESS</span>
        </button>
        <ul>
          <li
            v-for="index in pageIndexRange"
            :key="index"
            @click.prevent="goToPage(index)"
            class="learning-track-ebook__workspace--page"
            :class="{'learning-track-ebook__workspace--page-selected':
              index === pageIndex}">
            <!-- > v-if another page is being edited currently, show fa-book -->
            <i v-if="index !== pageIndex" class="fas fa-book"></i>
            <!-- > v-if this is current page being edited show fa-book-open-->
            <i v-if="index === pageIndex" class="fas fa-book-open"></i>
            <p>Page {{ index + 1 }}</p>
<!--             -- clicking on red minus will delete page
            <i class="learning-track-ebook__workspace--page-delete
              fas fa-minus">
            </i> -->
          </li>
          <li
            v-if="ebook.pages.length < 6"
            @click.prevent="createNewPage"
            class="learning-track-ebook__workspace--page">
            <i class="fas fa-plus"></i>
            <p>New Page</p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>

import axios from 'axios';
import {mapState, mapGetters, mapMutations} from 'vuex';

// https://itnext.io/anyway-this-is-how-to-use-v-model-with-vuex-computed-setter-in-action-320eb682c976
// https://vuex.vuejs.org/guide/forms.html

// uses this module for image uploading
// https://www.npmjs.com/package/vue-image-upload-resize
// https://codesandbox.io/s/mqnow97omj?module=%2Fsrc%2Fcomponents%2FHelloWorld.vue

export default {
  name: 'LearningTrackEbook',
  components: {
  },
  props: {
    ebookPk: Number,
    pageIndex: Number,
  },
  data() {
    return {
      newTitle: '',
      newContent: '',
      newAnimation: '',
      // used to remind users to save
      modified: false,
      // for the vue uploader
      hasImage: false,
      image: null,
      showGallery: false,
      // KIV -> hardcode gallery src
      galleryPics: [
        {
          index: 0,
          key: 'pics/小狼/1.jpg',
          src: 'https://smartschool-static.s3.ap-southeast-1.amazonaws.com/media/ebooks/pics/%E4%B8%89%E5%8F%AA%E5%B0%8F%E7%8C%AA%E5%92%8C%E7%8B%BC/1.jpg',
        },
        {
          index: 1,
          key: 'pics/其他/2.jpg',
          src: 'https://smartschool-static.s3.ap-southeast-1.amazonaws.com/media/ebooks/pics/%E4%B8%89%E5%8F%AA%E5%B0%8F%E7%8C%AA%E5%92%8C%E7%8B%BC/2.jpg',
        },
        {
          index: 2,
          key: 'pics/小猪/3.jpg',
          src: 'https://smartschool-static.s3.ap-southeast-1.amazonaws.com/media/ebooks/pics/%E4%B8%89%E5%8F%AA%E5%B0%8F%E7%8C%AA%E5%92%8C%E7%8B%BC/3.jpg',
        },
        {
          index: 3,
          key: 'pics/小猪/4.jpg',
          src: 'https://smartschool-static.s3.ap-southeast-1.amazonaws.com/media/ebooks/pics/%E4%B8%89%E5%8F%AA%E5%B0%8F%E7%8C%AA%E5%92%8C%E7%8B%BC/4.jpg',
        },
        {
          index: 4,
          key: 'pics/小猪/4.jpg',
          src: 'https://smartschool-static.s3.ap-southeast-1.amazonaws.com/media/ebooks/pics/%E4%B8%89%E5%8F%AA%E5%B0%8F%E7%8C%AA%E5%92%8C%E7%8B%BC/5.png',
        },
        {
          index: 5,
          key: 'pics/小猪/5.jpg',
          src: 'https://smartschool-static.s3.ap-southeast-1.amazonaws.com/media/ebooks/pics/%E4%B8%89%E5%8F%AA%E5%B0%8F%E7%8C%AA%E5%92%8C%E7%8B%BC/6.jpg',
        },
        {
          index: 6,
          key: 'pics/小猪/8.jpg',
          src: 'https://smartschool-static.s3.ap-southeast-1.amazonaws.com/media/ebooks/pics/%E4%B8%89%E5%8F%AA%E5%B0%8F%E7%8C%AA%E5%92%8C%E7%8B%BC/8.jpg',
        },
        {
          index: 7,
          key: 'pics/小猪/9.jpg',
          src: 'https://smartschool-static.s3.ap-southeast-1.amazonaws.com/media/ebooks/pics/%E4%B8%89%E5%8F%AA%E5%B0%8F%E7%8C%AA%E5%92%8C%E7%8B%BC/9.png',
        },
        {
          index: 8,
          key: 'pics/小猪/15.jpg',
          src: 'https://smartschool-static.s3.ap-southeast-1.amazonaws.com/media/ebooks/pics/%E4%B8%89%E5%8F%AA%E5%B0%8F%E7%8C%AA%E5%92%8C%E7%8B%BC/15.jpg',
        },
        {
          index: 9,
          key: 'pics/小猪/16.jpg',
          src: 'https://smartschool-static.s3.ap-southeast-1.amazonaws.com/media/ebooks/pics/%E4%B8%89%E5%8F%AA%E5%B0%8F%E7%8C%AA%E5%92%8C%E7%8B%BC/16.jpg',
        },
        {
          index: 10,
          key: 'pics/小狼/17.jpg',
          src: 'https://smartschool-static.s3.ap-southeast-1.amazonaws.com/media/ebooks/pics/%E4%B8%89%E5%8F%AA%E5%B0%8F%E7%8C%AA%E5%92%8C%E7%8B%BC/17.jpg',
        },
        {
          index: 11,
          key: 'pics/小狼/18.jpg',
          src: 'https://smartschool-static.s3.ap-southeast-1.amazonaws.com/media/ebooks/pics/%E4%B8%89%E5%8F%AA%E5%B0%8F%E7%8C%AA%E5%92%8C%E7%8B%BC/18.jpg',
        },
        {
          index: 12,
          key: 'pics/小狼/19.jpg',
          src: 'https://smartschool-static.s3.ap-southeast-1.amazonaws.com/media/ebooks/pics/%E4%B8%89%E5%8F%AA%E5%B0%8F%E7%8C%AA%E5%92%8C%E7%8B%BC/19.jpg',
        },
        {
          index: 13,
          key: 'pics/小狼/20.jpg',
          src: 'https://smartschool-static.s3.ap-southeast-1.amazonaws.com/media/ebooks/pics/%E4%B8%89%E5%8F%AA%E5%B0%8F%E7%8C%AA%E5%92%8C%E7%8B%BC/20.jpg',
        },
        {
          index: 14,
          key: 'pics/小狼/21.jpg',
          src: 'https://smartschool-static.s3.ap-southeast-1.amazonaws.com/media/ebooks/pics/%E4%B8%89%E5%8F%AA%E5%B0%8F%E7%8C%AA%E5%92%8C%E7%8B%BC/21.jpg',
        },
        {
          index: 15,
          key: 'pics/小狼/22.png',
          src: 'https://smartschool-static.s3.ap-southeast-1.amazonaws.com/media/ebooks/pics/%E4%B8%89%E5%8F%AA%E5%B0%8F%E7%8C%AA%E5%92%8C%E7%8B%BC/22.png',
        },
        {
          index: 16,
          key: 'pics/圣诞老人/23.jpg',
          src: 'https://smartschool-static.s3.ap-southeast-1.amazonaws.com/media/ebooks/pics/%E4%B8%89%E5%8F%AA%E5%B0%8F%E7%8C%AA%E5%92%8C%E7%8B%BC/23.jpg',
        },
        {
          index: 17,
          key: 'pics/圣诞老人/24.jpg',
          src: 'https://smartschool-static.s3.ap-southeast-1.amazonaws.com/media/ebooks/pics/%E4%B8%89%E5%8F%AA%E5%B0%8F%E7%8C%AA%E5%92%8C%E7%8B%BC/24.jpg',
        },
        {
          index: 18,
          key: 'pics/圣诞老人/25.jpg',
          src: 'https://smartschool-static.s3.ap-southeast-1.amazonaws.com/media/ebooks/pics/%E4%B8%89%E5%8F%AA%E5%B0%8F%E7%8C%AA%E5%92%8C%E7%8B%BC/25.jpg',
        },
        {
          index: 19,
          key: 'pics/圣诞老人/26.jpg',
          src: 'https://smartschool-static.s3.ap-southeast-1.amazonaws.com/media/ebooks/pics/%E4%B8%89%E5%8F%AA%E5%B0%8F%E7%8C%AA%E5%92%8C%E7%8B%BC/26.jpg',
        },
        {
          index: 20,
          key: 'pics/圣诞老人/27.jpg',
          src: 'https://smartschool-static.s3.ap-southeast-1.amazonaws.com/media/ebooks/pics/%E4%B8%89%E5%8F%AA%E5%B0%8F%E7%8C%AA%E5%92%8C%E7%8B%BC/27.jpg',
        },
        {
          index: 21,
          key: 'pics/圣诞老人/28.jpg',
          src: 'https://smartschool-static.s3.ap-southeast-1.amazonaws.com/media/ebooks/pics/%E4%B8%89%E5%8F%AA%E5%B0%8F%E7%8C%AA%E5%92%8C%E7%8B%BC/28.jpg',
        },
        {
          index: 22,
          key: 'pics/礼物/29.jpg',
          src: 'https://smartschool-static.s3.ap-southeast-1.amazonaws.com/media/ebooks/pics/%E4%B8%89%E5%8F%AA%E5%B0%8F%E7%8C%AA%E5%92%8C%E7%8B%BC/29.jpg',
        },
        {
          index: 23,
          key: 'pics/礼物/30.jpg',
          src: 'https://smartschool-static.s3.ap-southeast-1.amazonaws.com/media/ebooks/pics/%E4%B8%89%E5%8F%AA%E5%B0%8F%E7%8C%AA%E5%92%8C%E7%8B%BC/30.jpg',
        },
        {
          index: 24,
          key: 'pics/礼物/31.jpg',
          src: 'https://smartschool-static.s3.ap-southeast-1.amazonaws.com/media/ebooks/pics/%E4%B8%89%E5%8F%AA%E5%B0%8F%E7%8C%AA%E5%92%8C%E7%8B%BC/31.jpg',
        },
        {
          index: 25,
          key: 'pics/礼物/32.jpg',
          src: 'https://smartschool-static.s3.ap-southeast-1.amazonaws.com/media/ebooks/pics/%E4%B8%89%E5%8F%AA%E5%B0%8F%E7%8C%AA%E5%92%8C%E7%8B%BC/32.jpg',
        },
        {
          index: 26,
          key: 'pics/礼物/33.jpg',
          src: 'https://smartschool-static.s3.ap-southeast-1.amazonaws.com/media/ebooks/pics/%E4%B8%89%E5%8F%AA%E5%B0%8F%E7%8C%AA%E5%92%8C%E7%8B%BC/33.jpg',
        },
        {
          index: 27,
          key: 'pics/礼物/34.jpg',
          src: 'https://smartschool-static.s3.ap-southeast-1.amazonaws.com/media/ebooks/pics/%E4%B8%89%E5%8F%AA%E5%B0%8F%E7%8C%AA%E5%92%8C%E7%8B%BC/34.jpg',
        },
        {
          index: 28,
          key: 'pics/汤圆/35.jpg',
          src: 'https://smartschool-static.s3.ap-southeast-1.amazonaws.com/media/ebooks/pics/%E4%B8%89%E5%8F%AA%E5%B0%8F%E7%8C%AA%E5%92%8C%E7%8B%BC/35.jpg',
        },
        {
          index: 29,
          key: 'pics/汤圆/36.jpg',
          src: 'https://smartschool-static.s3.ap-southeast-1.amazonaws.com/media/ebooks/pics/%E4%B8%89%E5%8F%AA%E5%B0%8F%E7%8C%AA%E5%92%8C%E7%8B%BC/36.jpg',
        },
        {
          index: 30,
          key: 'pics/汤圆/37.jpg',
          src: 'https://smartschool-static.s3.ap-southeast-1.amazonaws.com/media/ebooks/pics/%E4%B8%89%E5%8F%AA%E5%B0%8F%E7%8C%AA%E5%92%8C%E7%8B%BC/37.jpg',
        },
        {
          index: 31,
          key: 'pics/汤圆/38.jpg',
          src: 'https://smartschool-static.s3.ap-southeast-1.amazonaws.com/media/ebooks/pics/%E4%B8%89%E5%8F%AA%E5%B0%8F%E7%8C%AA%E5%92%8C%E7%8B%BC/38.jpg',
        },
        {
          index: 32,
          key: 'pics/汤圆/39.jpg',
          src: 'https://smartschool-static.s3.ap-southeast-1.amazonaws.com/media/ebooks/pics/%E4%B8%89%E5%8F%AA%E5%B0%8F%E7%8C%AA%E5%92%8C%E7%8B%BC/39.jpg',
        },
        {
          index: 33,
          key: 'pics/汤圆/40.jpg',
          src: 'https://smartschool-static.s3.ap-southeast-1.amazonaws.com/media/ebooks/pics/%E4%B8%89%E5%8F%AA%E5%B0%8F%E7%8C%AA%E5%92%8C%E7%8B%BC/40.png',
        },
        {
          index: 34,
          key: 'pics/汤圆/41.jpg',
          src: 'https://smartschool-static.s3.ap-southeast-1.amazonaws.com/media/ebooks/pics/%E4%B8%89%E5%8F%AA%E5%B0%8F%E7%8C%AA%E5%92%8C%E7%8B%BC/41.jpg',
        },
        {
          index: 34,
          key: 'pics/汤圆/42.jpg',
          src: 'https://smartschool-static.s3.ap-southeast-1.amazonaws.com/media/ebooks/pics/%E4%B8%89%E5%8F%AA%E5%B0%8F%E7%8C%AA%E5%92%8C%E7%8B%BC/42.jpg',
        },
        {
          index: 35,
          key: 'pics/饺子/43.jpg',
          src: 'https://smartschool-static.s3.ap-southeast-1.amazonaws.com/media/ebooks/pics/%E4%B8%89%E5%8F%AA%E5%B0%8F%E7%8C%AA%E5%92%8C%E7%8B%BC/43.jpg',
        },
        {
          index: 36,
          key: 'pics/饺子/44.jpg',
          src: 'https://smartschool-static.s3.ap-southeast-1.amazonaws.com/media/ebooks/pics/%E4%B8%89%E5%8F%AA%E5%B0%8F%E7%8C%AA%E5%92%8C%E7%8B%BC/44.jpg',
        },
        {
          index: 37,
          key: 'pics/饺子/45.jpg',
          src: 'https://smartschool-static.s3.ap-southeast-1.amazonaws.com/media/ebooks/pics/%E4%B8%89%E5%8F%AA%E5%B0%8F%E7%8C%AA%E5%92%8C%E7%8B%BC/45.jpg',
        },
        {
          index: 38,
          key: 'pics/饺子/46.jpg',
          src: 'https://smartschool-static.s3.ap-southeast-1.amazonaws.com/media/ebooks/pics/%E4%B8%89%E5%8F%AA%E5%B0%8F%E7%8C%AA%E5%92%8C%E7%8B%BC/46.png',
        },
        {
          index: 39,
          key: 'pics/饺子/47.jpg',
          src: 'https://smartschool-static.s3.ap-southeast-1.amazonaws.com/media/ebooks/pics/%E4%B8%89%E5%8F%AA%E5%B0%8F%E7%8C%AA%E5%92%8C%E7%8B%BC/47.jpg',
        },
        {
          index: 40,
          key: 'pics/饺子/48.jpg',
          src: 'https://smartschool-static.s3.ap-southeast-1.amazonaws.com/media/ebooks/pics/%E4%B8%89%E5%8F%AA%E5%B0%8F%E7%8C%AA%E5%92%8C%E7%8B%BC/48.jpg',
        },
        {
          index: 41,
          key: 'pics/饺子/49.jpg',
          src: 'https://smartschool-static.s3.ap-southeast-1.amazonaws.com/media/ebooks/pics/%E4%B8%89%E5%8F%AA%E5%B0%8F%E7%8C%AA%E5%92%8C%E7%8B%BC/49.jpg',
        },
        {
          index: 42,
          key: 'pics/饺子/50.jpg',
          src: 'https://smartschool-static.s3.ap-southeast-1.amazonaws.com/media/ebooks/pics/%E4%B8%89%E5%8F%AA%E5%B0%8F%E7%8C%AA%E5%92%8C%E7%8B%BC/50.jpg',
        },
      ],
      galleryPicsCategory: '小猪',
    };
  },
  computed: {
    ...mapState('learningTrack', ['ebooks']),
    ...mapGetters('learningTrack', ['getEbookById']),
    ebook() {
      return this.getEbookById(this.ebookPk);
    },
    page() {
      if (!this.ebook) return;
      if (this.ebook.pages.length === 0) return;

      return this.ebook.pages[this.pageIndex];
    },
    pageIndexRange() {
      if (!this.ebook) return;

      const results = [];
      for (let i = 0; i < this.ebook.pages.length; i++) {
        results.push(this.ebook.pages[i].index);
      };

      // when we are creating a new page, we haven't stored the new page in the
      // ebook.pages object yet; but we still want it to appear on the
      // sidebar
      if (this.pageIndex >= this.ebook.pages.length) {
        results.push(this.pageIndex);
      }
      return results;
    },
    filteredGalleryPics() {
      const results = [];
      for (let i = 0; i < this.galleryPics.length; i++) {
        if (this.galleryPics[i].key.indexOf(this.galleryPicsCategory) > -1) {
          results.push(this.galleryPics[i]);
        };
      };
      return results;
    },
    picture: function() {
      if (!this.page || !this.page.picture) return;
      return this.page.picture;
    },
  },
  // when leaving for other pages, check if it's save
  beforeRouteLeave(to, from, next) {
    if (!this.modified) {
      next();
      return;
    };

    const answer = window.confirm(
        'Do you really want to leave? you have unsaved changes!');

    if (answer) {
      // resets modified counter if user decides to leave anyway
      this.modified = false;
      next();
    } else {
      next(false);
    };
  },
  watch: {
    pageIndex() {
      this.newTitle = this.ebook.title;

      // if it is a new page, then show blank content
      this.newContent = this.pageIndex >= this.ebook.pages.length ?
        '' : this.page.content;

      // if it is a new page, then animation is blank
      this.newAnimation = this.pageIndex >= this.ebook.pages.length ?
        '' : this.page.animation;
    },
  },
  mounted: function() {
    // check if ebooks have been loaded
    if (this.ebooks.length === 0) {
      this.$router.push({name: 'learning-track-ebook-dashboard'});
    }

    this.newTitle = this.ebook.title;
    this.newContent = (this.page && this.page.content) || '';
    // if it is a new page, then animation is blank
    this.newAnimation = (this.page && this.page.animation) || '';
  },
  methods: {
    ...mapMutations('learningTrack', ['replaceEbookByPk']),
    // we don't update vuex until we update backend database
    updateNewTitle(e) {
      this.modified = true;
      this.newTitle = e.target.value;
    },
    updateNewContent(e) {
      this.modified = true;
      this.newContent = e.target.value;
    },
    openGallery() {
      this.showGallery = true;
    },
    createPageHelper(pageIndex, title, content, animation,
        createNewPage, callback) {
      if (this.newTitle === '') {
        alert('Title is empty');
        return;
      };

      const url = '/blog/api/ebook/' + this.ebook.pk + '/page/';
      axios
          .post(url, {
            pageIndex: pageIndex,
            title: title,
            content: content,
            animation: animation,
            createNewPage: createNewPage,
          })
          .then((response) => {
            const newEbook = response.data;
            console.log(newEbook);
            this.replaceEbookByPk({
              pk: newEbook.pk,
              value: newEbook,
            });
            this.modified = false;
            callback(response.data);
          })
          .catch((error) => {
            console.log(error);
            alert('There was an error trying to save');
          });
    },
    // saves current page, creates a new page and goes to new page
    createNewPage() {
      this.createPageHelper(this.pageIndex, this.newTitle,
          this.newContent, this.newAnimation, true, () => {
            this.$router.push({name: 'learning-track-ebook',
              params: {ebookPk: this.ebook.pk,
                pageIndex: this.ebook.pages.length - 1}});
          });
    },
    // saves current page and goes to new page
    goToPage(targetPageIndex) {
      this.createPageHelper(this.pageIndex, this.newTitle,
          this.newContent, this.newAnimation, false, () => {
            this.$router.push({name: 'learning-track-ebook',
              params: {ebookPk: this.ebook.pk,
                pageIndex: targetPageIndex}});
          });
    },
    savePage() {
      this.createPageHelper(this.pageIndex, this.newTitle,
          this.newContent, this.newAnimation, false, () => {
            alert('Successfully saved!');
          });
    },
    setImage(file) {
      this.hasImage = true;
      this.image = file;
    },
    setGalleryPic(galleryPic) {
      axios
          .post('/blog/api/page/' + this.page.pk + '/set_gallery_picture/', {
            key: galleryPic.key,
            src: galleryPic.src,
          })
          .then((response) => {
            this.showGallery = false;
            console.log('successfully changed photo');
            this.page.picture = response.data;
          })
          .catch((error) => {
            alert('Error selecting photo');
            this.showGallery = false;
          });
    },
    completeSettingImage() {
      const formData = new FormData();
      console.log(this.image);

      formData.append('dataUrl', this.image.dataUrl);
      formData.append('name', this.image.name);

      axios({
        method: 'post',
        url: '/blog/api/page/' + this.page.pk + '/upload_picture/',
        data: formData,
        config: {headers: {'Content-Type': 'multipart/form-data'}},
      })
          .then((response) => {
            // handle success
            console.log('successfully uploaded photo');
            this.page.picture = response.data;
            // clears the file uploader
            // so that we can upload the same photo on different pages
            document.getElementById('fileInput').value = '';
          });
    },
  },
};
</script>

<style lang="scss" scoped>
  .learning-track-ebook {
    &__component {
      text-align: center;
      margin: 20px auto 0 auto;
    }
    &__header {
      background: #5a4179;
      color: #fff;
      padding: 10px;
      border-radius: 3px;
      margin-bottom: 25px;
      position: relative;
      &--back {
        position: absolute;
        left: 10px;
        font-size: 1.5em;
        color: #fff;
        margin-left: 5px;
      }
    }
    &__subheader {
      background: #5a4179;
      color: #fff;
      padding: 5px;
    }
    &__workspace {
      display: flex;
      margin-bottom: 20px;
      position: relative;
      &--gallery {
        top: -135px;
        position: absolute;
        width: 100%;
        &-bg {
          position: fixed;
          height: 100vh;
          width: 100vw;
          z-index: 10;
          top: 0;
          left: 0;
          background: rgba(0, 0, 0, 0.85);
        }
        &-header {
          margin: 20px;
          padding: 10px;
          display: flex;
          color: #fff;
          background: #5a4179;
          justify-content: center;
          align-items: center;
          position: relative;
          h2 {
            flex: 1;
          }
          i {
            position: absolute;
            right: 15px;
            cursor: pointer;
            font-size: 30px;
          }
        }
        &-btns {
          margin: 0 20px 25px 20px;
          display: grid;
          grid-gap: 20px;
          grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
          justify-items: center;
        }
        &-btn {
          padding: 10px 20px;
          width: 100%;
          font-size: 20px;
          color: #fff;
          background: #6e9fec;
          border: 3px solid #6e9fec;
          outline: none;
          cursor: pointer;
          border-radius: 3px;
          &:hover {
            color: #6e9fec;
            background: #fff;
            border: 3px solid #6e9fec;
          }
        }
        &-images {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr 1fr;
          grid-gap: 25px;
          align-items: center;
          justify-items: center;
          margin-bottom: 25px;
        }
        &-image {
          border-radius: 3px;
          cursor: pointer;
          width: 240px;
          height: 240px;
          object-fit: cover;
        }
        &-content {
          width: 100%;
          position: absolute;
          z-index: 20;
        }
      }
      &--form {
        flex: 1;
      }
      &--pages {
        width: 200px;
        margin-left: 50px;
      }

      &--title {
        width: 100%;
        margin-bottom: 20px;
        font-size: 20px;
        padding: 5px;
        text-align: center;
      }
      &--img {
        min-height: 285px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background:  #fff;
        border-radius: 3px;
        padding: 30px 30px 10px 30px;
        margin-bottom: 20px;
      }
      &--text {
        font-family: 'arial';
        width: 100%;
        height: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
        background:  #fff;
        border-radius: 3px;
        resize: none;
        font-size: 20px;
        margin-bottom: 10px;
        padding: 10px 20px;
      }
      &--page {
        position: relative;
        height: 85px;
        width: 100%;
        color: #b9b9b9;
        background: rgba(255, 255, 255, 0.4);
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        border: 5px solid transparent;
        border-radius: 3px;
        transition: all 0.2s;
        .fa-book,
        .fa-book-open {
          font-size: 35px;
          margin-bottom: 6px;
        }
        p {
          font-size: 14px;
          font-weight: 700;
          text-transform: uppercase;
        }
        &:hover {
          color: #fff;
          background: #5393ca;
          border: 5px solid #a7d6ff;
        }
        &:not(:last-child) {
          margin-bottom: 10px;
        }
        &-delete {
          position: absolute;
          right: 10px;
          top: 5px;
          color: #e46E7a;
          cursor: pointer;
          font-size: 16px;
        }
        &-selected {
          color: #fff;
          background: #5393ca;
          border: 5px solid #a7d6ff;
        }
        a {
          text-decoration: none;
          color: #555;
          .fa-plus {
            font-size: 36px;
          }
          span {
            display: block;
            font-size: 16px;
            margin-top: 8px;
          }
        }
      }
    }
  }
  .view-ebook__component--img {
  }
  .img-btn {
    font-size: 18px;
    width: 200px;
    padding: 10px;
    cursor: pointer;
  }
  .save-btn {
    cursor: pointer;
    font-size: 18px;
    max-width: 240px;
    width: 100%;
    margin-bottom: 20px;
    background: #ef5757;
    color: #fff;
    padding: 9px 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    // border-radius: 5px;
    i {
      // margin-right: 4px;
      font-size: 40px;
      margin-bottom: 5px;
    }

  }
  .img-placeholder {
    cursor: pointer;
    margin: 10px;
    font-size: 150px;
    opacity: .2;
  }

  .btn-show-gallery {
    background: #6e9fec;
    color: #fff;
    font-size: 18px;
    padding: 5px 20px;
    border: 5px solid #6e9fec;
    border-radius: 5px;
    margin-bottom: 10px;
    cursor: pointer;
  }

  .image-upload-btn {
    display: flex;
    // width: 100%;
    border: 5px solid #6e9fec;
    background: #fff;
    color: #6e9fec;
    padding: 5px 20px;
    border-radius: 5px;
    &:hover {
      background: #6e9fec;
      color: #fff;
    }
    &--container {
      display: flex;
      justify-content: center;
      padding: 10px 60px;
      margin: 10px auto 0 auto;
      figure {
        margin-right: 10px;
      }
    }
  }
  .page-counter {
    font-size: 16px;
    font-weight: 700;
  }

  @media only screen and (max-width: 1140px) {
    .learning-track-ebook {
      &__component {
        margin-top: 10px;
      }
      &__header {
        font-size: 16px;
        margin-bottom: 15px;
      }
      &__subheader {
        font-size: 20px;
      }
      &__existing-books {
        grid-template-columns: 1fr 1fr;
        grid-gap: 20px;
      }
      &__workspace{
        &--gallery {
          &-images {
            grid-template-columns: 1fr 1fr 1fr;
          }
        }
        &--title{
          margin-bottom: 15px;
        }
        &--img{
          margin-bottom: 15px;
        }
        &--pages {
          margin-left: 25px;
        }
      }
    }
    .save-btn {
      margin-bottom: 12px;
      padding: 7px 20px;
    }
  }
  @media only screen and (max-width: 1024px) {
    .learning-track-ebook {
      &__workspace {
        margin-bottom: 65px;
      }
    }
  }

  @media only screen and (max-width: 860px) {
    .learning-track-ebook {
      &__workspace{
        &--gallery {
          &-btns {
            grid-template-columns: 1fr 1fr 1fr;
          }
          &-images {
            grid-template-columns: 1fr 1fr;
          }
        }
      }
    }
  }


  @media only screen and (max-width: 780px) {
    .learning-track-ebook {
      &__workspace {
        flex-direction: column;
        &--pages {
          width: auto;
          margin: 10px 20px;
          ul {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 10px;
          }
        }
      }
    }
    .save-btn {
      max-width: 100%;
    }
  }

  @media only screen and (max-width: 560px) {
    .learning-track-ebook {
      &__workspace{
        &--gallery {
          &-images {
            grid-template-columns: 1fr;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 400px) {
    .learning-track-ebook {
      &__workspace{
        &--gallery {
          &-btns {
            grid-template-columns: 1fr 1fr;
          }
        }
      }
    }

  }

</style>

<style lang="scss">
  #fileInput {
    display: none;
    color: #555;
  }
  .upload-caption {
    cursor: pointer;
  }
</style>
