<!-- template for item in ./learningtracks/modules/ -->
<template>
  <div class="term-index__component row">
    <div class="term-index__item">
      <h3 v-if="title"
        class="term-index__item--header">
        {{ title | splitCapitalise }} Track
      </h3>
      <h3 v-else
        class="term-index__item--header">
        {{ learningTrack.level | splitCapitalise }} Track
      </h3>
      <p class="term-index__item--subheader">
        Click on a Badge to Select the Level:
      </p>
      <div
        v-if="learningTrack && typeof learningTrack.items !== 'undefined'
          && learningTrack.items.length > 0"
        class="term-index__item--badges">
        <learning-track-item
          v-for="item in learningTrack.items"
          :key="item.release_schedule_index"
          class="subtrack-index__item"
          :item="item"
          :stars="chineseQuizStars[item.chinese_quiz_id]"
          :title-to-use="item.subtrack"
        >
        </learning-track-item>
      </div>
    </div>
  </div>
</template>

<script>
// import axios from 'axios';
import {mapState} from 'vuex';

import LearningTrackItemComponent from
  './LearningTrackItemComponent.vue';

export default {
  name: 'LearningSubtopicTrackContainer',
  components: {
    LearningTrackItem: LearningTrackItemComponent,
  },
  props: {
    learningTrack: Object,
    title: String,
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapState(['chineseQuizStars']),
    // ...mapGetters('learningTrack', ['getLearningTrackItemsByPkSubtrack']),
  },
  mounted: function() {
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
  .term-index {
    &__component {
      text-align: center;
      margin: 20px auto 0 auto;
    }
    &__header {
      background: #5a4179;
      color: #fff;
      padding: 10px;
      border-radius: 3px;
      margin-bottom: 25px;
      position: relative;
      &--back {
        position: absolute;
        left: 10px;
        font-size: 1.5em;
        color: #fff;
        margin-left: 5px;
      }
    }
  }

  .term-index__item {
  color: #555;
  background: #fff;
  padding: 35px;
  border-radius: 3px;
  text-decoration: none;
  position: relative;
  // max-width: 550px;
    &--header {
      display: inline-block;
      padding: 5px 80px;
      border-bottom: 2px solid #333;
    }
    &--subheader {
      margin-top: 5px;
      font-size: 16px;
      font-style: italic;
    }
    &--badges {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      // display: grid;
      // grid-template-rows: 1fr 1fr 1fr 1fr;
      // grid-column-gap: 15px;
    }
  }
  .subtrack-index__item {
    padding: 10px 75px;
  }
  @media only screen and (max-width: 1140px) {
    .term-index {
      &__component {
        margin: 10px auto 0 auto;
      }
      &__header {
        font-size: 16px;
        margin-bottom: 15px;
      }
      &__item {
        margin-bottom: 40px;
        &--header {
          padding: 5px 70px;
        }
      }
      &__content {
        &:last-of-type {
          margin-bottom: 65px;
        }
        grid-gap: 20px;
        grid-template-columns: 1fr;
      }
    }
  }
  @media only screen and (max-width: 412px) {
    .term-index__item {
      &--header {
        padding: 5px 50px;
      }
    }
  }
  @media only screen and (max-width: 374px) {
    .term-index__item {
      &--header {
        padding: 5px 20px;
      }
    }
  }

</style>
