<template>
  <router-link
    class="chinese-character"
    :to="toRoute">
    <a data-v-23d2ba43="" href="#" class="close"></a>
  </router-link>
</template>

<script>


export default {
  name: 'OralReturnIndexButton',
  components: {
  },
  props: {
    type: String,
  },
  data() {
    return {
    };
  },
  computed: {
    toRoute() {
      if (this.type === 'readaloud') {
        return {name: 'new-oral-category-index', params: {category: 'readaloud'}};
      } else if (this.type === 'freeresponse') {
        return {name: 'new-oral-category-index', params: {category: 'freeResponse'}};
      } else if (this.type === 'templatedphrases') {
        return {name: 'new-oral-category-index', params: {category: 'templatedPhrases'}};
      } else if (this.type === 'textbookchapter') {
        return {name: 'new-oral-category-index', params: {category: 'oralTextbookChapter'}};
      }
      return {};
    },
  },
  mounted() {
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
.close {
  position: absolute;
  left: 32px;
  top: 32px;
  width: 32px;
  height: 32px;
  opacity: .3;
  transition: all 0.3s;
}

@media only screen and (max-width: 1080px) {
  .close {
    top: 16px;
  }
}

@media only screen and (max-width: 630px) {
  .close {
    left: 16px;
  }
}
</style>
