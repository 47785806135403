<template>
  <div id="report-bug-container">
    <!-- dialog box content -->
    <div v-if="!showAlert" class="report-bug-content">
      <!-- button to close report dialog box -->
      <a class="btn-close" href="#" @click.prevent="closeReportModal">
        <div class="btn-close-container">
          <i class="fas fa-times"></i>
        </div>
      </a>
      <div>
        <h4 class="report-bug-header">
          <i class="fas fa-question-circle"></i> If you can't play the audio:
        </h4>
        <p class="report-bug-details">Check that your device is not on mute.</p>
        <p class="report-bug-details">
          Otherwise, click this button to reset the app.
          <button
            @click.prevent='resetApp'>Reset</button>
        </p>
      </div>
      <div>
        <h4 class="report-bug-header">
          <i class="fas fa-exclamation-circle"></i> I wish to make a report:
        </h4>
        <div class="report-bug-details">
          <div @click="setSelectedOption('incorrect_audio')">
            <input id="incorrect_audio" type="radio" name="report" checked>
            <label for="incorrect_audio">
              The audio is not working.
            </label>
          </div>
          <div @click="setSelectedOption('incorrect_answer')">
            <input id="incorrect_answer" type="radio" name="report">
            <label for="incorrect_answer">
              There is not a correct answer in the options given.
            </label>
          </div>
          <button
            id="report-bug-button"
            class="btn-submit"
            @click.prevent="submitReport">
            Submit
          </button>
        </div>
      </div>
    </div>
    <!-- alert box content -->
    <div v-if="showAlert" class="alert-container">
      <div class="alert-header">
        <h4 v-if="sentSuccessfully">
          <i class="fas fa-flag"></i>success!
        </h4>
        <h4 v-else>
          <i class="fas fa-sad-tear"></i>uh-oh!
        </h4>
      </div>
      <div class="alert-content">
        <span v-if="sentSuccessfully">
          <p>Thank you for submitting a report.</p>
          <p>We will look into it shortly.</p>
        </span>
        <span v-else>
          <p>Your report did not get sent out.</p>
          <p>Please try again later.</p>
        </span>
      </div>
      <!-- TODO: clicking this button should also skip to next question -->
      <button class="btn-close-alert" @click.prevent="closeReportModal">
        Continue
      </button>
    </div>
  </div>
</template>

<script>
import {mapMutations} from 'vuex';
import axios from 'axios';

export default {
  name: 'QuizReportModal',
  props: {
    word: String,
    quizAttemptId: Number,
  },
  data() {
    return {
      selectedOption: '',
      sentSuccessfully: false,
      showAlert: false,
    };
  },
  computed: {
  },
  mounted() {
  },
  methods: {
    ...mapMutations('quiz', ['setShowReportModal']),
    closeReportModal() {
      this.setShowReportModal(false);
    },
    setSelectedOption(val) {
      this.selectedOption = val;
    },
    // force reset of app
    resetApp() {
      window.location.reload(true);
    },
    submitReport() {
      if (this.setSelectedOption === '') return;

      axios
          .post('/api/v1/brain/chinesedailyquiz/report/', {
            reportedError: this.selectedOption,
            quizAttemptId: this.quizAttemptId,
            word: this.word,
          })
          .then((response) => {
            this.sentSuccessfully = response.data['status'] === 200;
            this.showAlert = true;
          });
    },
  },
};
</script>

<style lang="scss" scoped>
  #report-bug-container {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0, 0, 0, 0.9);
  }

  .report-bug-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    border-radius: 8px;
    font-size: 90%;
    margin-bottom: 2vh;
  }

  .report-bug-content > div {
    background: #564fa2;
  }

  .btn-close {
    color: #fff;
  }
  .btn-close-container {
    background: #786bcc;
    border-radius: 50%;
    height: 45px;
    width: 45px;
    position: absolute;
    right: -15px;
    top: -15px;
    border: 3px solid #fff;
    font-size: 140%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .report-bug-header {
    color: #fff;
    padding: 20px;
    text-align: center;
  }

  .report-bug-content div:first-of-type .report-bug-header{
    padding-top: 30px;
  }

  .report-bug-content > div:first-of-type {
    border-radius: 8px 8px 0 0;
  }
  .report-bug-content > div:last-of-type {
    width: 100%;
    padding-bottom: 35px;
    border-radius: 0 0 8px 8px;
  }

  .report-bug-details {
    color: #000;
    background: #fff;
    padding: 20px 30px;
    font-size: 85%;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .report-bug-details div {
    padding: 0 0 10px 0;
  }

  .btn-submit {
    height: 40px;
    border-radius: 8px;
    background-color: #d9534f;
    color: #fff;
    font-size: 97.5%;
    margin-top: 10px;
    transition: transform 0.2s;
    cursor: pointer;
  }

  .btn-submit:focus,
  .btn-submit:active {
    outline: none;
  }

  .btn-submit:hover {
    transform: scale(1.03);
  }

  .alert-container {
    background: #fff;
    color: #000;
    border-radius: 8px;
    overflow: hidden;
    font-size: 90%;
    text-align: center;
    margin-bottom: 2vh;
    margin: 10px;
  }
  .alert-container h4 {
    background: #564fa2;
    text-transform: uppercase;
    padding: 20px;
    color: #fff;
    border: 1px solid #564fa2;
  }

  .alert-container h4 i {
    margin-right: 5px;
  }

  .alert-content {
    padding: 20px;
    text-align: center;
    font-size: 95%;
  }

  .btn-close-alert {
    height: 40px;
    width: 200px;
    border-radius: 8px;
    background-color: #564fa2;
    color: #fff;
    font-size: 97.5%;
    margin-bottom: 20px;
    transition: transform 0.2s;
  }
  .btn-close-alert:active,
  .btn-close-alert:focus {
    outline: none;
  }
  .btn-close-alert:hover {
    transform: scale(1.03);
  }

  @media only screen and (max-width: 350px) {
    .report-bug-content {
      font-size: 80%;
    }
    .btn-close-container {
      height: 35px;
      width: 35px;
      right: -12.5px;
      top: -12.5px;
    }
  }

  @media only screen and (max-height: 435px) {
    .report-bug-content {
      margin-bottom: 0;
      flex-direction: row;
      margin: 20px;
    }
    .report-bug-content > div {
      padding-bottom: 35px;
    }

    .report-bug-content > div:first-of-type {
      align-self: flex-start;
      margin-right: 10px;
      border-radius: 8px;
    }

    .report-bug-content > div:last-of-type {
      width: auto;
      border-radius: 8px;
    }
    .report-bug-content > div:last-of-type > h4 {
      padding-top: 30px;
    }
    .alert-container {
      margin-bottom: 0;
    }
    .report-bug-details {
      flex: 1;
    }
  }
</style>
