<template>
  <div v-if="isPrimarySchool && speechAnalytics" class="analytics">
    <div class="analytics-container">
      <div class="analytics-header">
        <h2 class="analytics-header-main">
          SpeechAI Analytics
        </h2>
        <router-link
          class="analytics-header-sub"
          :to="{name: 'analytics-overview'}">
          View All
        </router-link>
      </div>
      <div class="analytics-content">
        <div class="analytics-content-icon">
          <i class="fas fa-comments"></i>
        </div>
        <div class="analytics-content-text">
          <h3 class="analytics-content-text-main">
            {{ speechAnalytics.accuracy }}% accuracy
          </h3>
          <p v-if="speechAnalytics.num_sentences > 1" class="analytics-content-text-sub">
            You have spoken {{ speechAnalytics.num_sentences }} sentences!
          </p>
          <p v-else class="analytics-content-text-sub">
            You have not spoken any sentences yet.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState, mapGetters} from 'vuex';

export default {
  name: 'SpeechAnalytics',
  components: {
  },
  props: {
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapState(['coins', 'diamonds']),
    ...mapState('widgets', ['speechAnalytics']),
    ...mapGetters(['isPrimarySchool']),
  },
  mounted() {
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
  .analytics {
    background: #132647;
    color: #fff;
    margin: .75rem 1rem .75rem 0;
    padding: .25rem;
    border-radius: 0.5rem;
    &-container {
      padding: .25rem .5rem;
      border: 5px solid #1D335C;
      border-radius: 0.5rem;
    }
    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      &-main {
        font-size: 1rem;
      }
      &-sub {
        font-size: 0.75rem;
        text-decoration: none;
        &:hover {
          color: #fff;
        }
      }
    }
    &-content {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0.25rem;
      &-icon {
        font-size: 2.75rem;
        color: #F89F2B;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      &-text {
        margin-left: 1rem;
        &-main {
          font-size: .9rem;
        }
        &-sub {
          font-size: 0.75rem;
          line-height: .9rem;
          opacity: 0.6;
        }
      }
    }
  }
  @media only screen and (max-width: 1366px),
              screen and (max-height: 870px) {
    .analytics {
      margin: .5rem .5rem 0.5rem 0;
      &-header {
        &-main {
          font-size: 0.9rem;
        }
        &-sub {
          font-size: 0.7rem;
        }
      }
      &-content {
        margin: 0 0.25rem 0.25rem 0.25rem;
        &-icon {
          font-size: 2.5rem;
        }
        &-text {
          &-main {
            font-size: 0.8rem;
          }
          &-sub {
            font-size: 0.7rem;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 1150px),
            screen and (max-height: 720px) {
              .analytics {
      margin: .5rem .25rem 0.25rem 0;
      &-header {
        &-main {
          font-size: 0.75rem;
        }
        &-sub {
          font-size: 0.55rem;
        }
      }
      &-content {
        margin: 0.25rem;
        &-icon {
          font-size: 2rem;
        }
        &-text {
          margin-left: .5rem;
          &-main {
            font-size: 0.7rem;
          }
          &-sub {
            font-size: 0.6rem;
          }
        }
      }
    }

  }

</style>
