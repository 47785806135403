<template>
   <div id="container">
      <div id="game-area">
        <div id="tile-map">
          <div class="tile"></div>
          <div class="tile"></div>
          <div class="tile"></div>
          <div class="tile"></div>
          <div class="tile"></div>
          <div class="tile"></div>
          <div class="tile"></div>
          <div class="tile"></div>
          <div class="tile"></div>
        </div>
        <div id="info-panel">
          <h2>Game Information</h2>
          <p>Commands and game state will be displayed here.</p>
        </div>
      </div>
  </div>
</template>

<script>
// import {mapState, mapMutations} from 'vuex';

export default {
  name: 'NewPlayIslandMain',
  components: {
  },
  data() {
    return {
    };
  },
  computed: {
    // ...mapState(['level', 'cocos', 'listLevelChapters']),
  },
  mounted() {
  },
  methods: {
  },
};
</script>


<style lang="scss" scoped>
body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  font-family: Arial, sans-serif;
}
#container {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
}
#game-area {
  display: flex;
  width: 100%;
  height: 100%;
}
#tile-map {
  flex: 1;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  gap: 5px;
  padding: 10px;
  background-color: #f0f0f0;
}
.tile {
  background-color: #ffffff;
  border: 1px solid #ccc;
}
#info-panel {
  flex: 1;
  padding: 20px;
  background-color: #ffffff;
  overflow-y: auto;
}
</style>
