<template>
  <div
    class="portrait-image"
    :class="portraitClass">
  </div>
</template>

<script>


export default {
  name: 'ImagePortrait',
  components: {
  },
  props: {
    portrait: String,
  },
  data() {
    return {
    };
  },
  computed: {
    portraitClass() {
      // checks that portrait is valid
      if (!this.portrait || typeof this.portrait === 'undefined') {
        return;
      };

      return 'portrait-' + this.portrait;
    },
  },
  mounted: function() {
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
  .portrait-image {
      height: 0;
      width: 30%;
      background-repeat: no-repeat;
      padding-top: 25%;
      background-size: 100%;
      background-image: url('https://smartschool-static.s3-ap-southeast-1.amazonaws.com/media/dynamicbooks/2019_dec_holiday_camp/narration-scene/sprite-portrait.png');
  }

  .portrait-dumpling-cheerful {
    background-position: 0 0%;
  }
  .portrait-dumpling-point {
    background-position: 0 4.761905%;
  }
  .portrait-dumpling-thumbsup {
    background-position: 0 9.52381%;
  }
  .portrait-panda-bro-cheerful {
    background-position: 0 14.285714%;
  }
  .portrait-panda-bro-correct {
    background-position: 0 19.047619%;
  }
  .portrait-panda-bro-happy {
    background-position: 0 23.809524%;
  }
  .portrait-panda-bro-sad {
    background-position: 0 28.571429%;
  }
  .portrait-panda-dad-happy {
    background-position: 0 33.333333%;
  }
  .portrait-panda-dad-point {
    background-position: 0 38.095238%;
  }
  .portrait-panda-dad-puzzled {
    background-position: 0 42.857143%;
  }
  .portrait-panda-mum-happy {
    background-position: 0 47.619048%;
  }
  .portrait-panda-mum-puzzled {
    background-position: 0 52.380952%;
  }
  .portrait-panda-mum-thumbsup {
    background-position: 0 57.142857%;
  }
  .portrait-panda-mum-wrong {
    background-position: 0 61.904762%;
  }
  .portrait-panda-siblings-puzzled {
    background-position: 0 66.666667%;
  }
  .portrait-panda-sis-cheerful {
    background-position: 0 71.428571%;
  }
  .portrait-panda-sis-correct {
    background-position: 0 76.190476%;
  }
  .portrait-panda-sis-happy {
    background-position: 0 80.952381%;
  }
  .portrait-panda-boshi {
    background-position: 0 85.714286%;
  }
  .portrait-tangyuan-cheerful {
    background-position: 0 90.47619%;
  }
  .portrait-tangyuan-point {
    background-position: 0 95.238095%;
  }
  .portrait-tangyuan-thumbsup {
    background-position: 0 100%;
  }


</style>
