<template>
  <div class="analytics__component animated fadeIn faster">
    <div class="analytics__component--content">
      <div class="mobile-nav-icons">
        <i @click="isMobileSideNavShown = !isMobileSideNavShown" class="fa-solid fa-bars"></i>
        <i @click="goProfileMain" class="fa-solid fa-xmark"></i>
      </div>
      <!-- CHARTS -->
      <div v-if="navStatus === 1"
        class="analytics__component--content--learning-progress">
        <!-- if loading -->
        <div v-if="fetching">
          <img src="../../../assets/loading.gif">
        </div>
        <div v-if="!fetching" class="learning-progress__header">
          <h2>Learning Progress - Overview</h2>
          <div class="learning-progress__filter" role="group">
            <button v-for="(range, index) in ['daily', 'weekly', 'monthly']"
              type="button" class="learning-progress__filter--btn"
              :key="index"
              @click="dateRange=range"
              :class="{active: dateRange === range}">
              {{ range }}
            </button>
          </div>
        </div>
        <div v-if="!fetching" class="learning-progress__body">
          <div v-if="status === 0" class="learning-progress__body--error">
            <p>No questions were attempted within the selected date range. Please select more days.</p>
          </div>
          <div v-if="status === 1" class="learning-progress__body--charts">
            <div class="chart chart--1">
              <bar-chart
              :chart-data="numQuestionDoneBarChartData"
              :chart-options="numQuestionDoneBarChartOptions"
              :height="CHART_HEIGHT"></bar-chart>
            </div>
            <div class="chart chart--2">
              <bar-chart
                :chart-data="wordsExposedBarChartData"
                :chart-options="wordsExposedBarChartOptions"
                :height="CHART_HEIGHT"></bar-chart>
            </div>
            <div class="chart chart--3">
              <bar-chart
              :chart-data="questionBreakdownByQuizTypeBarChartData"
              :chart-options="questionBreakdownByQuizTypeBarChartOptions"
              :height="CHART_HEIGHT"></bar-chart>
            </div>
            <div class="chart chart--4">
              <pie-chart
              :chart-data="wordBreakdownPieChartData"
              :chart-options="wordBreakdownPieChartOptions"
              :height="CHART_HEIGHT"></pie-chart>
            </div>
          </div>
        </div>
      </div>
      <!-- Chapter Proficiency -->
      <div v-if="navStatus === 2" class="analytics__component--content--chapter-proficiency--container">
        <div v-if="status === 1" class="analytics__component--content--chapter-proficiency chapter-proficiency">
          <div class="chapter-proficiency__header">
            <div class="chapter-proficiency__header-text">
              <h2>Chapter Proficiency</h2>
              <v-select class="chapter-proficiency__header-text--dropdown" :options="validLevelOptions" label="label" index="code" v-model="selectedLevel" />
            </div>
            <div class="chapter-proficiency__header-progress">
              <progress id="overall-level-progress" max="100" :value="percentageWordsKnownData['overall_data']['percent_known']"></progress>
              <p class="chapter-proficiency__header-progress--text">
                Words Mastered: {{ percentageWordsKnownData['overall_data']['percent_known'] }}%
              </p>
            </div>
          </div>
          <div class="chapter-proficiency__body">
              <!-- <chapter-words-popup
                v-if="showChapterWordsPopup"
                @close="closeChapterWordsPopup"
                :info="selectedChapterInfo">
              </chapter-words-popup> -->
            <ul>
              <li
                v-for="(row, index) in percentageWordsKnownData['chapter_data']"
                :key="index"
                @click="goToChapterVocabBreakdown(row)"
              >
                <small v-if="subject ==='chinese'" class="text-muted">CH. {{ row['chapter_number'] + 1 }}: <span class="chinese-character">{{ row['title'] }}</span></small>
                <small v-if="subject ==='malay'" class="text-muted malay-character">CH. {{ row['chapter_number'] + 1 }}: {{ row['title'] }}</small>
                <progress class="progress-bar" max="100" :value="row['percent_known']"></progress>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!-- Vocab Proficiency -->
      <div v-if="navStatus === 3"
        class="analytics__component--content--vocab-proficiency">
        <words-category-list v-if="status === 1"
          :info="results.words_category_list"
        ></words-category-list>
      </div>
      <div v-if="navStatus === 4" class="oral-proficiency">
        <div class="oral-proficiency__header">
          <h2>Oral Proficiency</h2>
          <h4>Top {{ results.oral_words_to_revise.length }} Words to Revise</h4>
        </div>
        <div v-if="results.oral_words_to_revise.length > 0" class="oral-proficiency__body">
          <oral-token v-for="(word, index) in results.oral_words_to_revise" :key="index" :word="word"></oral-token>
        </div>
        <div v-else>
          <span>No words to revise yet, please do more questions.</span>
        </div>
      </div>
      <!-- My Wordlist -->
      <div v-if="navStatus === 5"
        class="">
        <my-wordlist></my-wordlist>
        <!-- <words-category-list v-if="status === 1"
          :info="results.words_category_list"
        ></words-category-list> -->
      </div>
      <!-- <h6>
          Words to Revise
        </h6>
        <table class="table revision-table">
          <caption>Green ticks and red crosses represent correct and wrong question attempts.</caption>
          <thead>
            <tr>
              <th>Word</th>
              <th v-tooltip="'This rating tracks how strong a word is in your long-term memory.'">
                Memory <i class="fas fa-question-circle help-tip fa-light"></i>
              </th>
              <th>Answer History</th>
              <th>Last Tested</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(word, index) in results.words_to_revise"
            :key="index">
              <td class="chinese-character text-medium">{{ word.text }}</td>
              <td class="memory-strength-cell">
                <img v-if="word.stability < 1"
                  src="../../../assets/flower-orange-1.svg">
                <img v-if="word.stability == 1"
                  src="../../../assets/flower-orange-2.svg">
                <img v-if="word.stability == 2"
                  src="../../../assets/flower-orange-3.svg">
                <img v-if="word.stability == 3"
                  src="../../../assets/flower-orange-4.svg">
                <img v-if="word.stability == 4"
                  src="../../../assets/flower-orange-5.svg">
              </td>
              <td>
                <correct-streak :streak-data="word.answer_history[0]" :attempted-answers="word.student_actual_answers"></correct-streak>
              </td>
              <td>{{ word.answer_history[1] }}</td>
            </tr>
          </tbody>
      </table> -->
      <div v-if="navStatus === 6">
        <div v-if="status === 1" class="row mt-4">
          <div class="col-md-6" v-if="results.mistakes_overcame.length > 0">
            <h6 v-tooltip="`Hover over the crosses and ticks to see ${studentName}'s attempted answers`">
              Top {{ results.mistakes_overcame.length }} Mistakes Overcame <i class="fas fa-question-circle help-tip fa-light"></i>
            </h6>
            <ul>
              <li v-for="(mistake, index) in results.mistakes_overcame" :key="index" class="mistake_overcame">
                <span v-if="mistake.category === 'assessment'">
                  Question: <span class="chinese-character text-medium">{{ mistake.question }}</span><br>
                  Answer: <span class="chinese-character text-medium">{{ mistake.answer }}</span><br>
                </span>
                <span v-else>
                  Word: <span class="chinese-character text-medium">{{ mistake.question }} ({{ mistake.pinyin }})</span><br>
                  Definition: {{ mistake.definition }}<br>
                </span>
                <span>Answer History: </span><correct-streak :streak-data="mistake.answer_history[0]" :attempted-answers="mistake.student_actual_answers"></correct-streak>
                <hr>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- desktop nav -->
    <div class="analytics__component--nav-container">
      <div class="analytics__component--nav animated slideInLeft">
        <div class="analytics__component--nav--profile">
          <div class="user-avatar">
            <img class="avatar-img"
              :src='"../../../assets/avatars/" + avatar + ".png"' />
          </div>
          <div class="user-info">
            <p class="user-name">
              {{ studentName }}
            </p>
            <p class="user-level">{{ verboseLevel }}</p>
          </div>
        </div>
        <ul class="analytics__component--nav--routes">
          <li :class="{ active: navStatus === 1 }"
            @click="navStatus = 1">
            <i class="fa-solid fa-chart-line"></i>
            Learning Progress
          </li>
          <li
            :class="{ active: navStatus === 2 }"
            @click="navStatus = 2">
            <i class="fa-solid fa-book"></i>
            Chapter Proficiency
          </li>
          <li :class="{ active: navStatus === 3 }"
            @click="navStatus = 3">
            <i class="fa-solid fa-bookmark"></i>
            Vocab Proficiency
          </li>
          <li v-if="subject === 'chinese'" :class="{ active: navStatus === 4 }"
            @click="navStatus = 4">
            <i class="fa-solid fa-comments"></i>
            Oral Proficiency
          </li>
          <li :class="{ active: navStatus === 5 }"
            @click="navStatus = 5">
            <i class="fa-solid fa-list"></i>
            My Wordlist
          </li>
          <!-- <li :class="{ active: navStatus === 6 }"
            @click="navStatus = 5">
            <i class="fa-solid fa-hand-fist"></i>
            Mistakes Overcome
          </li> -->
        </ul>
        <div @click="goProfileMain" class="analytics__component--nav--btn-home">
          <i class="fa-solid fa-house"></i>
          Back To Home
        </div>
      </div>
    </div>
    <!-- mobile nav -->
    <div v-if="isMobileSideNavShown" class="analytics__component--nav-container--mobile">
      <div class="analytics__component--nav animated slideInLeft">
        <div class="analytics__component--nav--profile">
          <div class="user-avatar">
            <img class="avatar-img"
              :src='"../../../assets/avatars/" + avatar + ".png"' />
          </div>
          <div class="user-info">
            <p class="user-name">
              {{ studentName }}
            </p>
            <p class="user-level">{{ verboseLevel }}</p>
          </div>
        </div>
        <ul class="analytics__component--nav--routes">
          <li :class="{ active: navStatus === 1 }"
            @click="navStatus = 1; isMobileSideNavShown = !isMobileSideNavShown">
            <i class="fa-solid fa-chart-line"></i>
            Learning Progress
          </li>
          <li
            :class="{ active: navStatus === 2 }"
            @click="navStatus = 2; isMobileSideNavShown = !isMobileSideNavShown">
            <i class="fa-solid fa-book"></i>
            Chapter Proficiency
          </li>
          <li :class="{ active: navStatus === 3 }"
            @click="navStatus = 3; isMobileSideNavShown = !isMobileSideNavShown">
            <i class="fa-solid fa-bookmark"></i>
            Vocab Proficiency
          </li>
          <li v-if="subject === 'chinese'" :class="{ active: navStatus === 4 }"
            @click="navStatus = 4; isMobileSideNavShown = !isMobileSideNavShown">
            <i class="fa-solid fa-comments"></i>
            Oral Proficiency
          </li>
          <li v-if="subject === 'chinese'" :class="{ active: navStatus === 5 }"
            @click="navStatus = 5; isMobileSideNavShown = !isMobileSideNavShown">
            <i class="fa-solid fa-list"></i>
            My Wordlist
          </li>
          <!-- <li :class="{ active: navStatus ===  }"
            @click="navStatus = 5; isMobileSideNavShown = !isMobileSideNavShown"">
            <i class="fa-solid fa-hand-fist"></i>
            Mistakes Overcome
          </li> -->
        </ul>
        <div @click="isMobileSideNavShown = !isMobileSideNavShown"
          class="analytics__component--nav--btn-close">
          <i class="fa-solid fa-xmark"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import {mapState, mapGetters} from 'vuex';
import PieChart from '../../common/charts/Pie.vue';
import BarChart from '../../common/charts/Bar.vue';
import CorrectStreakComponent from './components/CorrectStreakComponent.vue';
import WordsCategoryListComponent from './components/WordsCategoryListComponent.vue';
import OralTokenComponent from './components/OralTokenComponent.vue';
import MyWordlistComponent from './components/MyWordlistComponent.vue';


import {soundMixin} from '../../../mixins/sound';

export default {
  name: 'AnalyticsOverview',
  components: {
    BarChart,
    PieChart,
    CorrectStreak: CorrectStreakComponent,
    WordsCategoryList: WordsCategoryListComponent,
    OralToken: OralTokenComponent,
    MyWordlist: MyWordlistComponent,
  },
  mixins: [soundMixin],
  props: {
  },
  data() {
    return {
      dateRange: 'daily',
      filterBy: 'level',
      fetching: false,
      status: -1,
      results: {},
      CHART_HEIGHT: 225,
      selectedLevel: '',
      // showChapterWordsPopup: false,
      // selectedChapterInfo: null,
      navStatus: 1,
      isMobileSideNavShown: false,
    };
  },
  computed: {
    ...mapState(['studentName', 'level', 'avatar', 'siblings', 'subject']),
    ...mapGetters(['isPreschool', 'validLevelOptions']),
    // show primary school levels but do not show levels that are above student's level
    // for instance, if child is P3, show P1, P2 and P3 only
    options() {
      return this.validLevelOptions;
    },
    verboseLevel() {
      return this.level.split('_').join(' ');
    },
    percentageWordsKnownData() {
      if (this.status !== 1 || this.selectedLevel === '') return;
      if (!(this.selectedLevel in this.results.word_percent_known_by_level_chapter)) return [];
      return this.results.word_percent_known_by_level_chapter[this.selectedLevel];
    },
    numQuestionDoneBarChartData() {
      if (this.status !== 1) return;
      return {
        labels: Object.keys(this.results.questions_done_by_date.daily),
        datasets: [{
          type: 'line',
          label: 'Cumulative #questions done',
          yAxisID: 'right-y-axis',
          backgroundColor: 'grey',
          data: Object.values(this.results.questions_done_by_date.cumulative),
        },
        {
          type: 'bar',
          label: '#questions done',
          yAxisID: 'left-y-axis',
          backgroundColor: '#76d6d5',
          data: Object.values(this.results.questions_done_by_date.daily),
        }],
      };
    },
    numQuestionDoneBarChartOptions() {
      if (this.status !== 1) return;
      return {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: 'bottom',
          },
          title: {
            display: true,
            text: 'Questions Done',
          },
          datalabels: {
            // don't show numbers as too many points for mixed chart
            formatter: (value) => {
              return '';
            },
          },
        },
        scales: {
          'left-y-axis': {
            type: 'linear',
            position: 'left',
            grid: {
              display: false,
            },
          },
          'right-y-axis': {
            type: 'linear',
            position: 'right',
            grid: {
              display: false,
            },
          },
          'x': {
            grid: {
              display: false,
            },
          },
        },
      };
    },
    questionBreakdownByQuizTypeBarChartData() {
      if (this.status !== 1) return;
      const labels = this.subject === 'chinese' ? ['Vocab', 'Paper 2 (语文应用)', 'Oral']
        : ['Vocab', 'Paper 2'];

      const data = this.subject === 'chinese' ? [this.results.breakdown.vocab.num_done, this.results.breakdown.assessment.num_done, this.results.breakdown.oral.num_done]
        : [this.results.breakdown.vocab.num_done, this.results.breakdown.assessment.num_done];

      return {
        labels: labels,
        datasets: [{
          label: 'Number of questions done',
          backgroundColor: ['#76d6d5', '#a0bbe5', '#6de29d'],
          data: data,
        }],
      };
    },
    questionBreakdownByQuizTypeBarChartOptions() {
      if (this.status !== 1) return;
      const correctPercentages = [this.formatPercentage(this.results.breakdown.vocab.correct_percentage),
        this.formatPercentage(this.results.breakdown.assessment.correct_percentage)];
      // for chinese, need to consider oral as well
      if (this.subject === 'chinese') {
        correctPercentages.push(this.formatPercentage(this.results.breakdown.oral.correct_percentage));
      }

      return {
        indexAxis: 'y',
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: 'bottom',
          },
          title: {
            display: true,
            text: 'Quiz Breakdown',
          },
          datalabels: {
            formatter: (value, context) => {
              // avoids cluttering the chart if no questions have been done
              if (value === 0) return '';
              // we want to show both the number of questions done, and the correct %
              return `${correctPercentages[context.dataIndex]}% correct`;
            },
          },
        },
        scales: {
          x: {
            grid: {
              display: false,
            },
          },
          y: {
            grid: {
              display: false,
            },
          },
        },
      };
    },
    wordBreakdownPieChartData() {
      if (this.status !== 1) return;
      return {
        labels: Object.keys(this.results.word_mastery_breakdown),
        datasets: [
          {
            backgroundColor: ['#a0bbe5', '#76d6d5', '#6de29d'],
            data: Object.values(this.results.word_mastery_breakdown),
          },
        ],
      };
    },
    wordBreakdownPieChartOptions() {
      if (this.status !== 1) return;
      return {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: 'bottom',
          },
          title: {
            display: true,
            // text: 'Word Mastery Breakdown',
            text: `Total Known Words: ${this.results.num_words_known || 0}`,
          },
        },
      };
    },
    wordsExposedBarChartData() {
      if (this.status !== 1) return;
      return {
        labels: ['Revised', 'Seen'],
        datasets: [{
          label: 'Number of unique characters',
          backgroundColor: ['#76d6d5', '#a0bbe5'],
          data: [this.results.num_chars_actively_revised, this.results.num_chars_exposed_to]}],
      };
    },
    wordsExposedBarChartOptions() {
      if (this.status !== 1) return;
      return {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: 'bottom',
          },
          title: {
            display: true,
            text: 'Number of words revised',
          },
          datalabels: {
            formatter: (value) => {
              return value > 0 ? value : '';
            },
          },
        },
        scales: {
          y: {
            grid: {
              display: false,
            },
          },
          x: {
            grid: {
              display: false,
            },
          },
        },
      };
    },
  },
  watch: {
    dateRange() {
      this.updateUI();
    },
    filterBy() {
      this.updateUI();
    },
    $route() {
      this.updateUI();
    },
  },
  mounted: function() {
    this.updateUI();
  },
  methods: {
    goProfileMain() {
      this.$router.push({name: 'profile-main'});
    },
    updateUI() {
      if (this.fetching) return;
      // resetting variables
      this.fetching = true;
      this.navStatus = 1;
      this.results = {};
      const url = '/analytics/api/summary/';
      return axios
          .post(url, {
            dateRange: this.dateRange,
            filterBy: this.filterBy,
            subject: this.subject,
          })
          .then((response) => {
            // -1 if not pulled; 0 if no questions done; 1 if successful
            this.status = response.data[0];
            this.results = response.data[1];
            this.fetching = false;
            console.log(this.results);

            if (this.selectedLevel === '') {
              this.selectedLevel = this.level;
            }
            // const manifest = JSON.parse(this.results.manifest);
            // this.preload(manifest, (data) => {
            //   this.sounds = data;
            //   this.soundsLoaded = true;
            // });
          })
          .catch((err) => {
            console.log(err);
            this.fetching = false;
          });
    },
    formatPercentage(percent) {
      return Math.floor(percent * 100);
    },
    goToChapterVocabBreakdown(row) {
      // this.$router.push({
      //   name: 'analytics-chapter-vocab-breakdown',
      //   params: {chapterId: row.chapter_id},
      // });
    },
    // openChapterWordsPopup(row) {
    //   this.showChapterWordsPopup = true;
    //   this.selectedChapterInfo = row;
    // },
    // closeChapterWordsPopup() {
    //   this.showChapterWordsPopup = false;
    //   this.selectedChapterInfo = null;
    // },
  },
};
</script>

<style lang="scss" scoped>
  .analytics__component {
    display: flex;
    flex-direction: row-reverse;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background: #fff;
    z-index: 2;
    background: #e9e9e9;
    &--nav {
     &-container {
      height: 100%;
      &--mobile {
          // FOR RESPONSIVENESS SLIDE IN NAV
          background: #000000db;
          width: 100vw;
          position: absolute;
          height: 100%;
          display: none;
        }
      }
      padding: 2rem 2rem 2rem 1rem;
      background: #3c79d3;
      position: relative;
      height: 100%;
      width: 300px;
      &--profile {
        display: flex;
        justify-content: center;
        align-items: center;
        .user-avatar {
          img {
            background: #fff;
            border-radius: 50%;
            box-shadow: 0px 5px #7a7a7aa8;
            width: 75px;
            margin-right: .5rem;
          }
        }
        .user-name {
          font-size: 1.4rem;
          font-weight: 600;
          color: #fff;
          line-height: 1.2rem;
          // text-shadow: 0px 3px #0000007a;
          text-transform: capitalize;
        }
        .user-level {
          text-transform: capitalize;
          color: #fff;
          font-style: italic;
        }
      }
      &--routes {
        position: absolute;
        right: 0;
        margin-top: 3.5rem;
        li {
          i {
            margin-right: .5rem;
          }
          cursor: pointer;
          padding: 1rem 2rem;
          font-weight: 600;
          min-width: 250px;
          font-size: .9rem;
          color: #fff;
        }
        .active {
          border-top-left-radius: .5rem;
          border-bottom-left-radius: .5rem;
          background: #e9e9e9;
          color: #3c79d3;
          position: relative;
          &::before {
            content: "";
            position: absolute;
            background-color: rgba(0, 0, 0, 0);
            top: -50px;
            height: 50px;
            width: 25px;
            border-bottom-right-radius: .5rem;
            right: 0;
            box-shadow: 0 25px 0 0 #e9e9e9;
          }
          &::after {
            content: "";
            position: absolute;
            background-color: rgba(0, 0, 0, 0);
            bottom: -50px;
            height: 50px;
            width: 25px;
            border-top-right-radius: .5rem;
            right: 0;
            box-shadow: 0 -25px 0 0 #e9e9e9;
          }
       }
      }
      &--btn-home {
        position: absolute;
        bottom: 0;
        width: 100%;
        background: #3366b4;
        left: 0;
        padding: 1rem;
        padding-left: 2rem;
        font-weight: 600;
        color: #fff;
        font-size: .9rem;
        cursor: pointer;
        i {
          margin-right: .25rem;
        }
      }
      &--btn-close {
        position: absolute;
        bottom: 0;
        width: 100%;
        background: #3366b4;
        left: 0;
        padding: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 1rem;
        font-weight: 600;
        color: #ffffff8a;
        font-size: 1.5rem;
        cursor: pointer;
        &:hover {
          color: #fff;
        }
        i {
          margin-right: .25rem;
        }
      }
    }
    &--content {
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 1;
      flex-direction: column;
      > div {
        height: 100%;
        width: 100%;
        padding: 1.5rem;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        max-width: 1360px;
      }
      .mobile-nav-icons {
        justify-content: space-between;
        flex-direction: row;
        padding: 0 1rem;
        font-size: 1.35rem;
        display: none;
        .fa-xmark {
          font-size: 1.8rem;
        }
      }
    }
  }

  .learning-progress {
    &__header {
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: center;
      margin-bottom: 1rem;
      padding: .5rem;
      color: #1f5ed0;
    }
    &__filter {
      display: flex;
      grid-gap: .5rem;
      &--btn {
        padding: .5rem;
        min-width: 4.5rem;
        text-transform: capitalize;
        font-weight: 600;
        background: #b5b5b5;
        color: #dadada;
        border: none;
        cursor: pointer;
        outline: inherit;
        border-radius: .25rem;
      }
      .active {
        color: #fff;
        background: #1f5ed0;
      }
    }
    &__body {
      width: 100%;
      height: 550px;
      display: flex;
      justify-content: center;
      align-items: center;
      &--charts {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        grid-gap: 1rem;
        .chart {
          background: #fff;
          padding: 1rem;
          border-radius: .5rem;
          height: 100%;
          > div {
            max-height: 265px;
            height: 100%;
          }
          &--1, &--3 {
            width: calc(65% - 1rem);
            height: 100%;
          }
          &--2, &--4 {
            height: 100%;
            width: calc(35% - 1rem);
          }
          &--4 {
            position: relative;
            .words-known {
              position: absolute;
              text-align: center;
              bottom: -30px;
              width: calc(100% - 2rem)
            }
          }
        }
      }
    }
  }

  .chapter-proficiency {
    width: 100%;
    min-height: 600px;
    &__header {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-bottom: 1rem;
      &-text {
        color: #1f5ed0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        line-height: 1rem;
        margin-bottom: .5rem;
        &--dropdown {
          width: 300px;
          background: #fff;
        }
      }
      &-progress {
        width: 100%;
        position: relative;
        height: 1.5rem;
        #overall-level-progress {
          width: 100%;
          height: 100%;
        }
        &--text {
          position: absolute;
          top: 0;
          text-align: center;
          width: 100%;
        }
      }
    }
    &__body {
      display: flex;
      grid-gap: 1rem;
      ul {
        display: flex;
        flex-wrap: wrap;
        grid-gap: .5rem;
        width: 100%;
        li {
          display: flex;
          justify-content: center;
          align-items: flex-start;
          flex-direction: column;
          padding: .75rem;
          font-weight: 500;
          line-height: 1.4rem;
          font-size: .9rem;
          width: calc(25% - .375rem);
          background: #fff;
          border-radius: .25rem;
          .progress-bar {
            width: 100%;
            height: .65rem;
          }
          cursor: pointer;
        }
      }
    }
  }
  .oral-proficiency {
    width: 100%;
    min-height: 600px;
    &__header {
      display: flex;
      flex-direction: column;
      width: 100%;
      margin-bottom: 1rem;
      h2 {
        color: #1f5ed0;
        line-height: 1.2rem;
      }
      h4 {
        color: #2772fb;
      }
    }
    &__body {
      display: flex;
      grid-gap: .5rem;
      flex-wrap: wrap;
      width: 100%;
      min-height: 392px;
    }
  }

  .memory-strength-cell {
    width: 30%;
    padding: .5rem 0;
    img {
      width: 20%;
    }
  }

  progress {
    border-radius: 1rem;
    width: 100%;
    overflow: hidden;
    border: 1px solid #d0d0d0;
  }
  progress::-webkit-progress-bar {
    background-color: rgb(244, 244, 244);
    border-radius: 1rem;
  }
  progress::-webkit-progress-value {
    background-color: rgb(13, 138, 255);
    border-radius: 1rem;
  }
  progress::-moz-progress-bar {
    /* style rules */
  }
  @media only screen and (max-width: 1420px) {
    .analytics__component--nav {
      width: 250px;
      &--btn-home {
        font-size: .8rem;
      }
      &--profile {
        .user-name {
          font-size: 1.2rem;
          line-height: 1.1rem;
        }
        .user-level {
          font-size: .85rem;
        }
        .user-avatar img {
          width: 60px;
        }
      }
      &--routes {
        margin-top: 2.5rem;
        width: 230px;
        li {
          font-size: .8rem;
          min-width: 230px;
          padding: 1rem;
        }
      }
    }
  }

  @media only screen and (max-width: 1250px) {
    .analytics__component--nav-container {
      display: none;
    }
    .analytics__component--nav-container--mobile {
      display: block;
      .analytics__component--nav {
        padding: 2rem 1rem;
        width: 310px;
      }
      .analytics__component--nav--routes {
        right: auto;
        margin-top: 2.5rem;
        .active {
          border-radius: .5rem;
          &::before {
            content: none;
          }
          &::after {
            content: none;
          }
        }
      }
    }
    .analytics__component--content {
      height: 100%;
      justify-content: flex-start;
      &>div {
        padding: .25rem 1rem;
        justify-content: flex-start;
      }
      .learning-progress__body {
        align-items: start;
        overflow: scroll;
        height: calc(100% - 107px);
        padding-bottom: 2rem;
      }
      .mobile-nav-icons {
        display: flex;
        height: auto;
        padding: .75rem 1rem;
        i {
          cursor: pointer;
        }
      }
    }
  }
  @media only screen and (max-width: 1250px) {
    .chapter-proficiency {
      height: 100%;
    }
    .chapter-proficiency__body {
      height: calc(100% - 180px);
      align-items: flex-start;
      ul li {
      width: calc(33.33% - .375rem);
      }
    }
  }
  @media only screen and (max-width: 920px) {
    .analytics__component--content--chapter-proficiency--container {
      height: calc(100% - 56px);
    }
    .analytics__component--content>div {
      padding: 0 1rem;
    }
    .learning-progress__header {
      padding: 0 1rem;
      margin-bottom: .75rem;
    }
    .learning-progress__body--charts {
      justify-content: stretch;
      .chart {
        &--1, &--2, &--3, &--4 {
          width: 100%;
          margin: 0 1.5rem;
        }
      }
    }
    .analytics__component--content {
      .analytics__component--content--learning-progress {
        padding: 0;
      }
      .mobile-nav-icons {
        padding: .5rem 1rem;
      }
    }
  }
  @media only screen and (max-width: 760px) {
    .analytics__component--content {
      .oral-proficiency {
        padding: 0;
        &__header, &__body {
          padding: 0 .75rem;
        }
        &__header {
          margin-bottom: .75rem;
          h2 {
            font-size: 1.4rem;
          }
          h4 {
            font-size: .9rem;
          }
        }
        &__body {
          overflow: scroll;
          padding-bottom: .75rem;
        }
      }
    }
    .chapter-proficiency__body {
      height: calc(100% - 200px);
      overflow: scroll;
      ul li {
        width: 100%;
      }
    }
    .analytics__component--content--chapter-proficiency--container {
      padding: 0 .75rem !important;
    }
    .chapter-proficiency__header-text {
      flex-wrap: wrap;
      h2 {
        font-size: 1.4rem;
      }
      &--dropdown {
        width: 100%;
        margin-top: .5rem;
      }
    }
    .analytics__component--content {
      .mobile-nav-icons {
        padding: .5rem .75rem;
      }
      .learning-progress__body {
        height: calc(100% - 138px);
        padding-bottom: 1rem;
      }
    }
    .learning-progress__header {
      padding: 0 .75rem;
      flex-wrap: wrap;
      h2 {
        font-size: 1.4rem;
      }
      div {
        width: 100%;
        button {
          flex: 1;
        }
      }
    }
    .learning-progress__body--charts {
      justify-content: stretch;
      grid-gap: .75rem;
      .chart {
        &--1, &--2, &--3, &--4 {
          margin: 0 .75rem;
        }
      }
    }
  }
  @media only screen and (max-width: 420px) {
    .analytics__component--content {
      .oral-proficiency {
        &__header {
          h2 {
            font-size: 1.25rem;
          }
          h4 {
            font-size: .8rem;
          }
        }
      }
    }
    .chapter-proficiency__header-text {
      h2 {
        font-size: 1.25rem;
      }
    }
    .learning-progress__header {
      h2 {
        font-size: 1.25rem;
      }
    }
    .learning-progress__filter--btn {
      font-size: .6rem;
      padding: .35rem;
    }
  }
</style>
