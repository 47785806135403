<template>
  <div
    :class="{active: isActive}"
    class="leaderboard-row__component">
    <div class="leaderboard-row__content--rank">
      <h2>{{ studentInfo.rank }}</h2>
    </div>
    <div class="leaderboard-row__content--avatar">
      <img
        :class="{'active-avatar': isActive}"
        :src="avatarUrl" alt="">
    </div>
    <div class="leaderboard-row__content--text">
      <div v-if="crownColor !== ''"
        class="leaderboard-row__content--crown">
        <img :src="crownColor" alt="">
      </div>
      <h2 class="leaderboard-row__content--text-name">
        {{ studentInfo.name }}
      </h2>
      <div class="leaderboard-row__content--text-coin">
        <p>{{ studentInfo.coins }}</p>
      </div>
    </div>
    <div class="leaderboard-row__content--coin-img">
      <img src="../../assets/leaderboards/coin.png" alt="">
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex';

export default {
  name: 'LeaderboardRow',
  components: {
  },
  props: {
    studentInfo: Object,
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapState(['studentId']),
    isActive() {
      return this.studentId === this.studentInfo['id'];
    },
    crownColor() {
      if (this.studentInfo['rank'] > 3) return '';
      let color = '';
      if (this.studentInfo['rank'] === 1) {
        color = 'gold';
      } else if (this.studentInfo['rank'] === 2) {
        color = 'silver';
      } else {
        color = 'bronze';
      }
      return require(`../../assets/leaderboards/crown-${color}.png`);
    },
    avatarUrl() {
      return require(`../../assets/avatars/${this.studentInfo['avatar']}.png`);
    },
  },
  mounted: function() {
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=Baloo+Da+2:wght@400;500;600;700;800&display=swap');
* {
  font-family: 'Baloo Da 2', Avenir, Helvetica, Arial, sans-serif;
}
.leaderboard-row {
  &__component {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-radius: 15px;
    height: calc(100% / 5);
    padding-right: 2rem;
  }
  &__content {
    &--rank {
      width: 4rem;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.3rem;
      border-top-left-radius: 15px;
      border-bottom-left-radius: 15px;
      height: 100%;
      color: #fff;
      h2 {
        text-shadow: -3px 1px 15px rgba(0, 51, 102, 0.7);
        margin-bottom: -0.2rem
      }
    }
    &--avatar {
      width: 75px;
      height: 75px;
      border-radius: 50%;
      background-color: #fff;
      margin: 0 .25rem;
      margin-right: 0.5rem;
      overflow: hidden;
      box-shadow: 0 6px 7px rgba(0, 51, 102, 0.21);
      img {
        width: 100%;
        padding: .25rem;
        margin-top: .25rem;
      }
    }
    &--text {
      flex: 1;
      display: flex;
      justify-content: space-between;
      background: #fff;
      border-radius: 15px;
      padding: .25rem 1.25rem;
      width: 100%;
      white-space: nowrap;
      text-overflow: ellipsis;
      box-shadow: 0 6px 7px rgba(0, 51, 102, 0.21);
      position: relative;
      &-name {
        color: #08536d;
        font-weight: 700;
        font-size: 1.2rem;
        margin-right: 1rem;
      }
      &-coin {
        margin-right: 1.5rem;
        color: #503327;
        font-weight: 700;
        font-size: 1.2rem;
      }
    }
    &--crown {
      position: absolute;
      top: -38px;
      left: -17px;
      img {
        width: 3rem;
      }
    }
    &--coin {
      &-img {
        position: absolute;
        right: 1rem;
        margin-top: 0.5rem;
        img {
          width: 100%;
        }
      }
    }
  }
}

.active {
  background-color: #3bedff;
  box-shadow: 0 6px 7px rgba(0, 51, 102, 0.21);
  .leaderboard-row__content--rank {
    background-color: #11d6ec;
  }
  .leaderboard-row__content--text {
    &-name,
    &-coin {
      font-weight: 800;
    }
  }
}

@media screen and (max-width: 1500px) {
  .leaderboard-row__content--avatar {
    width: 70px;
    height: 70px;
  }
}
@media screen and (max-width: 1230px) {
  .leaderboard-row__content--avatar img {
    padding: 0.1rem;
    margin-top: 0.15rem;
  }
  .leaderboard-row__content--crown {
    top: -31px;
    left: -14px;
    img {
      width: 2.5rem;
    }
  }
  .leaderboard-row__content--avatar {
    margin: 0 .5rem;
    width: 65px;
    height: 65px;
  }
  .leaderboard-row__content--text-name {
    font-size: 1.1rem;
  }
  .leaderboard-row__content--text-coin {
    font-size: 1.1rem;
    margin-right: 1.25rem;
  }
  .leaderboard-row__content--coin-img {
    width: 60px;
    height: 60px;
    margin-top: 0;
  }
  .leaderboard-row__content--rank {
    font-size: 1.1rem;
    width: 3.5rem;
  }
}
@media screen and (max-width: 1150px) {

  .leaderboard-row__content--text-name {
    font-size: 1rem;
  }
  .leaderboard-row__content--text-coin {
    font-size: 1rem;
  }
  .leaderboard-row__content--rank {
    font-size: 1rem;
    width: 3.25rem;
  }
}
@media screen and (max-width: 1100px) {
  .leaderboard-row__content--avatar {
    width: 60px;
    height: 60px;
  }
  .leaderboard-row__content--coin-img {
    width: 55px;
    height: 55px;
  }
  .leaderboard-row__content--text {
    padding: 0.25rem 1rem;
    border-radius: 12px;
  }
  .leaderboard-row__content--text-name {
    font-size: 0.9rem;
  }
  .leaderboard-row__content--text-coin {
    font-size: 0.9rem;
  }
  .leaderboard-row__content--rank {
    font-size: .9rem;
    width: 3rem;
  }

}
@media screen and (max-width: 1020px) {
  .leaderboard-row__content--text-name {
    font-size: 0.8rem;
  }
  .leaderboard-row__content--text-coin {
    font-size: 0.8rem;
  }
  .leaderboard-row__content--text {
    border-radius: 10px;
  }
  .leaderboard-row__content--rank {
    font-size: .8rem;
    width: 2.75rem;
  }
}
@media screen and (max-width: 970px) {
  .leaderboard-row__content--avatar {
    width: 55px;
    height: 55px;
  }
  .leaderboard-row__content--coin-img {
    width: 50px;
    height: 50px;
  }
  .leaderboard-row__content--text {
    padding: 0.25rem .75rem;
    border-radius: 8px;
  }
  .leaderboard-row__content--text-name {
    font-size: 0.75rem;
  }
  .leaderboard-row__content--text-coin {
    font-size: 0.75rem;
  }
  .leaderboard-row__content--rank {
    font-size: .75rem;
    width: 2.5rem;
  }
}
@media screen and (max-width: 915px) and (min-height: 667px)  {
  .leaderboard-row__content--text-name {
    font-size: 1.1rem;
  }
  .leaderboard-row__content--text-coin {
    font-size: 1.1rem;
    margin-right: 1rem;
  }
  .leaderboard-row__content--rank {
    font-size: 1.1rem;
    width: 3.5rem;
  }
}
@media screen and (max-width: 565px) {
  .leaderboard-row__content--text-name {
    font-size: 1rem;
  }
  .leaderboard-row__content--text-coin {
    font-size: 1rem;
  }
  .leaderboard-row__content--rank {
    font-size: 1rem;
    width: 3.25rem;
  }
}
@media screen and (max-width: 540px) {
  .leaderboard-row__content--text-name {
    font-size: 0.9rem;
  }
  .leaderboard-row__content--text-coin {
    font-size: 0.9rem;
  }
  .leaderboard-row__content--rank {
    font-size: .9rem;
    width: 3rem;
  }
}
@media screen and (max-width: 495px) {
  .leaderboard-row__content--text-name {
    font-size: 0.8rem;
  }
  .leaderboard-row__content--text-coin {
    font-size: 0.8rem;
  }
  .leaderboard-row__content--rank {
    font-size: .8rem;
    width: 2.75rem;
  }
  .leaderboard-row__content--crown {
      top: -21px;
      left: -8px;
  }
}
@media screen and (max-width: 915px) and (max-height: 1100px) {
  .leaderboard-row__content--crown {
      top: -27px;
      left: -12px;
    img {
      width: 2.25rem;
    }
  }
}
@media screen and (max-width: 915px) and (max-height: 1045px) {
  .leaderboard-row__content--crown {
      top: -25px;
      left: -10px;
    img {
      width: 2rem;
    }
  }
}
@media screen and (max-width: 915px) and (max-height: 1000px) {
  .leaderboard-row__content--crown {
      top: -20px;
      left: -8px;
    img {
      width: 1.75rem;
    }
  }
}
@media screen and (max-width: 915px) and (max-height: 945px) {
  .leaderboard-row__content--avatar img {
    padding: 0.1rem;
    margin-top: 0.15rem;
  }
  .leaderboard-row__content--text {
    padding: 0.1rem 0.75rem;
  }
  .leaderboard-row__content--avatar {
    width: 50px;
    height: 50px;
  }
  .leaderboard-row__content--coin-img {
    width: 45px;
    height: 45px;
  }
}
@media screen and (max-width: 915px) and (max-height: 855px) {
  .leaderboard-row__content--crown {
      top: -15px;
    img {
      width: 1.5rem;
    }
  }
  .leaderboard-row__content--text {
    padding: 0rem 0.75rem;
  }
  .leaderboard-row__content--avatar {
    width: 45px;
    height: 45px;
  }
  .leaderboard-row__content--coin-img {
    width: 40px;
    height: 40px;
  }
}
@media screen and (max-width: 440px) {
  .leaderboard-row__content--avatar {
    width: 45px;
    height: 45px;
  }
  .leaderboard-row__content--coin-img {
    width: 40px;
    height: 40px;
  }
  .leaderboard-row__content--text {
    padding: 0.2rem 0.5rem;
  }
  .leaderboard-row__content--text-name {
    font-size: 0.7rem;
  }
  .leaderboard-row__content--text-coin {
    font-size: 0.7rem;
    margin-right: 0.75rem;
  }
  .leaderboard-row__content--rank {
    width: 2.1rem;
    font-size: 0.7rem;
  }
}
@media screen and (max-width: 380px) {
  .leaderboard-row__content--avatar {
    width: 35px;
    height: 35px;
    margin: 0.25rem;
  }
  .leaderboard-row__content--coin-img {
    width: 35px;
    height: 35px;
  }
}
@media screen and (max-width:915px) and (max-height: 615px) {
  .leaderboard-row__content--text {
    padding: 0.25rem .75rem;
    &-coin {
      margin-right: 0.5rem;
    }
  }
}
@media screen and (max-width:816px) and (max-height: 550px) {
  .leaderboard-row__content--text {
    padding: 0.15rem .5rem;
    &-coin {
      margin-right: 0.5rem;
    }
  }
  .leaderboard-row__content--avatar {
    width: 40px;
    height: 40px;
  }
  .leaderboard-row__content--coin-img {
    width: 35px;
    height: 35px;
  }
}
@media screen and (max-width:915px) and (max-height: 470px) {
  .leaderboard-row__content--text {
    padding: 0.1rem .25rem;
    &-coin {
      margin-right: 0.5rem;
    }
  }
  .leaderboard-row__content--avatar {
    width: 35px;
    height: 35px;
  }
  .leaderboard-row__content--coin-img {
    width: 30px;
    height: 30px;
  }
}
@media screen and (max-width:667px) and (max-height: 400px) {
  .leaderboard-row__content--avatar {
    margin: 0.25rem;
  }
  .leaderboard-row__content--text-name {
    font-size: 0.7rem;
  }
  .leaderboard-row__content--text-coin {
    font-size: 0.7rem;
    margin-right: 0.5rem;
  }
  .leaderboard-row__content--rank {
    width: 2.1rem;
    font-size: 0.7rem;
  }
}
</style>
