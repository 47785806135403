const getDefaultState = () => {
  const results = {
    componentNames: [],
    speechAnalytics: {},
    coinLeaderboard: {},
  };

  return results;
};

import Vue from 'vue';
const state = getDefaultState();

export const widgets = {
  namespaced: true,
  state,
  mutations: {
    resetStateMutation(state) {
      Object.assign(state, getDefaultState());
    },
    // affects what widgets to show
    setComponentNames(state, value) {
      Vue.set(state, 'componentNames', value);
    },
    setSpeechAnalytics(state, value) {
      Vue.set(state, 'speechAnalytics', value);
    },
    setCoinLeaderboard(state, value) {
      Vue.set(state, 'coinLeaderboard', value);
    },
  },
  actions: {
  },
  getters: {
  },
};
