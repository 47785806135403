<template>
  <div class="pet-overview__component row">
    <div class="pet-overview__header">
      <h2 v-if="pets.length > 0">My Pets ({{ pets.length }} / {{ gameValues['max_num_pets'] }})</h2>
      <h2 v-else>My Pets</h2>
      <!-- kiv stack pets until next update -->
<!--       <i class="fas fa-layer-group pet-overview__header--stack-icon"
        :class="{ 'pet-stacked': stackPets }"
        @click="toggleStackPets"></i> -->
    </div>
    <div class="pet-overview__content--empty"
      v-if="pets.length === 0">
      <i class="far fa-sad-cry"></i>
      <div class="pet-overview__content--empty-text">
        <p class="pet-overview__content--empty-text-1">
          Uh oh... You have no pets...
        <p>
        <p class="pet-overview__content--empty-text-2">
          Do Daily Challenge to gain pets!
        </p>
      </div>
<!--       <router-link
        class="pet-overview__content--empty-btn"
        tag="div"
        :to="{name: 'daily-challenge-overview'}">
          Collect more Pets Now!
      </router-link> -->
    </div>
    <div class="pet-overview__content">

      <!--
        HARDCODE: cannot merge beyond level 3 (which reads as 4)
        in future, do check what is max level per pet
      -->
      <router-link
        :to="{name: 'pet-info', params: {petId: pet.id}}"
        tag="div"
        class="pet-overview__content--pets"
        :key="counter"
        v-for="(pet, counter) in pets">
        <pet-container
          :pet="pet"
          ></pet-container>
      </router-link>
    </div>

    <div v-if="showMergePopup"
      @click="showMergePopup = false"
      class="merge-panda__popup">

      <!-- first panel -->
      <div class="merge-panda__content" v-if="askForUserInputMerge">
        <h2 class="merge-panda__content--title">
          Merge your Pandas into more Powerful Ones!
        </h2>
        <div class="merge-panda__content--imgs">
          <div
            v-for="n in numberofPetsToMerge"
            :key="n"
            class="pet-container__card">
            <pet-image
              :pet-key="selectedPetToMerge.pet_key">
            </pet-image>
          </div>
        </div>
        <!-- Text show merge 3 or merge 5 depending on how many user has -->
        <button
          @click.stop="sendMergeToBackend"
        >Merge {{ numberofPetsToMerge }}</button>
      </div>

      <!-- second panel -->
      <div class="merge-panda__content" v-else>
        <h2 class="merge-panda__content--title">
          Congratulations! You've successfully merged your Pandas!
        </h2>
        <div class="merge-panda__content--imgs">
          <!-- Spawn next level cards -->
          <div
            v-for="n in numberOfMergedPets"
            :key="n"
            class="pet-container__card">
            <pet-image
              :pet-key="getNextLevelPetKey(selectedPetToMerge.pet_key)">
            </pet-image>
          </div>
        </div>
        <button>
          Close
        </button>
      </div>
    </div>

  </div>
</template>

<script>
import axios from 'axios';
import {mapState, mapMutations} from 'vuex';

import PetContainerComponent
  from './PetContainerComponent.vue';

import PetImageComponent
  from './PetImageComponent.vue';

export default {
  name: 'PetOverview',
  components: {
    PetContainer: PetContainerComponent,
    PetImage: PetImageComponent,
  },
  mixins: [],
  data() {
    return {
      mergeType: '',
      selectedPetTypeToMerge: '',
      stackPets: true,
      showMergePopup: false,
      askForUserInputMerge: true,
    };
  },
  computed: {
    ...mapState('pet', ['pets', 'gameValues']),
    // petsOwned() {
    //   // creates a copy of this.pets.pets_owned to avoid side effects
    //   return this.pets.pets_owned.slice(0).sort((a, b) => {
    //     return a.index - b.index;
    //   });
    // },
    // // hack -> converts "merge3" to 3 or "merge5" to 5
    // // need it to show how many pets are being merged in v-for
    // numberofPetsToMerge() {
    //   if (this.mergeType === '') return 0;
    //   return Number(this.mergeType[this.mergeType.length -1]);
    // },
    // numberOfMergedPets() {
    //   if (this.mergeType === '') return 0;
    //   return this.mergeType === 'merge3' ? 1 : 2;
    // },
  },
  mounted: function() {
    // checks to see if pets are present; if not, pull
    if (Object.keys(this.pets).length === 0) {
      this.setShowGlobalLoadingScreen(true);
      axios
          .get('/api/v1/core/student/pets/')
          .then((response) => {
            this.setPets(response.data);
            this.setShowGlobalLoadingScreen(false);
          })
          .catch((error) => {
            console.log(error);
            console.log('having issue pullng');
            this.setShowGlobalLoadingScreen(false);
          });
    }
  },
  methods: {
    ...mapMutations(['setShowGlobalLoadingScreen']),
    ...mapMutations('pet', ['setPets']),
    toggleStackPets() {
      this.stackPets = !this.stackPets;
    },
    //   // very hackish way to do this
    //   // assumes the second last element is the level and that the key is
    //   // composed of different attributes with _ as separator
    //   getNextLevelPetKey(currentLevelPetKey) {
    //     const arrItems = currentLevelPetKey.split('_');
    //     const levelIndex = arrItems.length - 2;
    //     arrItems[levelIndex] = String(Number(arrItems[levelIndex]) + 1);
    //     return arrItems.join('_');
    //   },
    //   mergePet(pet) {
    //     this.askForUserInputMerge = true;
    //     const petCount = this.pets.pet_counts[pet.pet_key];

    //     if (petCount < 3) {
    //       alert('Insufficent pets to merge (requires ' + threshold + ')');
    //       return;
    //     } else if (petCount >= 5) {
    //       this.mergeType = 'merge5';
    //     } else {
    //       this.mergeType = 'merge3';
    //     };

    //     this.selectedPetToMerge = pet;
    //     this.showMergePopup = true;
    //   },
    //   sendMergeToBackend() {
    //     axios
    //         .post('/gamification/pet/merge/', {
    //           petKey: this.selectedPetToMerge.pet_key,
    //           petPk: this.selectedPetToMerge.pk,
    //           mergeType: this.mergeType,
    //         })
    //         .then((response) => {
    //           console.log(response.data);
    //           this.setPets(response.data);
    //           // sends it to the congratulations screen
    //           this.askForUserInputMerge = false;
    //         })
    //         // if there is error merging, then close the popup
    //         .catch((error) => {
    //           alert('Error merging pets');
    //           this.showMergePopup = false;
    //         });
    //   },
  },
};
</script>

<style lang="scss" scoped>
  .pet-overview {
    &__component {
      margin-top: 20px;
      margin-bottom: 164px;
    }
    &__header {
      background: #5a4179;
      color: #fff;
      padding: 10px;
      border-radius: 3px;
      margin-bottom: 25px;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      &--stack-icon {
        position: absolute;
        right: 20px;
        font-size: 30px;
        opacity: 0.2;
        cursor: pointer;
      }
    }
    &__content {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      grid-gap: 20px;
      margin-bottom: 10px;
      &--pets {
        cursor: pointer;
      }
      &--empty {
        text-align: center;
        margin: 0 auto;
        background: #fff;
        max-width: 500px;
        padding: 20px;
        i {
          font-size: 150px;
          color: #6E9FEC;
        }
        &-text {
          font-weight: bold;
          margin: 20px 0;
          &-1 {
            font-size: 30px;
          }
          &-2 {
            font-size: 24px;
            padding-bottom: 30px;
            border-bottom: 2px solid #555;
          }
        }
        &-btn {
          background: #c33e5d;
          color: #fff;
          text-transform: uppercase;
          text-decoration: none;
          padding: 15px 0;
          max-width: 400px;
          margin: 0 auto;
          font-weight: bold;
          border-radius: 5px;
          cursor: pointer;
        }
      }
    }
  }
  .pet-stacked {
    opacity: 1;
  }
  .merge-panda {
    &__popup {
      position: absolute;
      height: 100vh;
      width: 100vw;
      top: 0;
      left: 0;
      background: rgba(0,0,0,0.9);
      z-index: 20;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      button {
        font-size: 16px;
        background: #c33e5d;
        color: #fff;
        text-transform: uppercase;
        text-decoration: none;
        padding: 15px 0;
        width: 150px;
        margin: 20px auto;
        font-weight: bold;
        border-radius: 5px;
        cursor: pointer;
        outline: none;
        border: none;
      }
    }
    &__content {
      text-align: center;
      &--title {
        width: 100%;
        color: #fff;
        padding: 10px;
        border-radius: 3px;
        margin-bottom: 25px;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      &--imgs {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: 0 auto;
        max-width: 1140px;
        .pet-container__card {
          position: relative;
          height: 100%;
          background: linear-gradient(#44cbff, #ffffff);
          border-radius: 5px;
          border: 5px solid #c8fcff;
          text-align: center;
          margin: 5px 5px;
          width: 200px;
        }
      }
    }
  }
  @media only screen and (max-width: 1140px) {
    .pet-overview {
      &__component {
        margin-top: 10px;
      }
      &__header {
        font-size: 16px;
        margin-bottom: 15px;
      }
      &__content {
        grid-gap: 10px;
      }
    }
    .merge-panda__content--imgs {
      .pet-container__card {
        width: 175px;
      }
    }
  }
  @media only screen and (max-width: 1023px) {
    .pet-overview {
      &__content {
        margin-bottom: 64px;
      }
    }
  }

  @media only screen and (max-width: 980px) {
    .merge-panda__content--imgs {
      .pet-container__card {
        width: 150px;
      }
    }
  }
  @media only screen and (max-width: 800px) {
    .pet-overview {
      &__content {
        grid-template-columns: repeat(4, 1fr);
      }
    }
    .merge-panda__content {
      &--title {
        font-size: 130%;
      }
      &--imgs {
        .pet-container__card {
          width: 125px;
        }
      }
    }
  }
  @media only screen and (max-width: 680px) {
    .merge-panda__content--imgs {
      .pet-container__card {
        width: 100px;
      }
    }
  }
  @media only screen and (max-width: 560px) {
    .merge-panda__content {
      &--title {
      font-size: 100%;
      }
      &--imgs {
        .pet-container__card {
          width: 90px;
        }
      }
    }
  }
  @media only screen and (max-width: 520px) {
    .pet-overview {
      &__content {
        grid-template-columns: repeat(3, 1fr);
      }
    }
  }
  @media only screen and (max-width: 380px) {
    .pet-overview {
      &__content {
        &--empty {
          &-text {
            &-1 {
              font-size: 24px;
            }
            &-2 {
              font-size: 18px;
            }
          }
          &-btn {
            font-size: 16px;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 374px) {
    // .pet-overview {
    //   &__content {
    //     grid-template-columns: 1fr;
    //   }
    // }
  }
</style>

