<template>
  <div v-if="isEventIconShown" class="boshipanda__component">
    <div @click="setIsChallengesPopupShown(true)"
      class="boshipanda__icon">
      <img class="boshipanda__icon--img"
      src="../../../assets/icon-events-sevenday.jpg">
      <div class="boshipanda__icon--notification"></div>
    </div>
    <div v-if="isChallengesPopupShown && challenges.length > 0"
      class="events animated fadeIn faster">
      <events @isClosed="setIsChallengesPopupShown(!isChallengesPopupShown)"></events>
    </div>
  </div>
</template>

<script>
import {mapState, mapMutations} from 'vuex';

import EventsComponent
  from './events/EventsComponent.vue';

export default {
  name: 'Boshipanda',
  components: {
    Events: EventsComponent,
  },
  props: {
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapState(['isChallengesPopupShown', 'challenges']),
    isEventIconShown() {
      const SHOW_ON_PAGES = ['profile-main'];
      // don't show icon on certains pages
      if (SHOW_ON_PAGES.indexOf(this.$route.name) === -1) return false;
      // show only if have challenges
      return this.challenges && this.challenges.length > 0;
    },
    // ...mapGetters(['currentlyShowingFullScreenComponents']),
  },
  mounted() {
  },
  methods: {
    ...mapMutations(['setIsChallengesPopupShown']),
  },
};
</script>

<style lang="scss" scoped>
  .boshipanda {
    &__icon{
      z-index: 1;
      position: absolute;
      bottom: 1.5rem;
      right: 1.5rem;
      height: 70px;
      width: 70px;
      cursor: pointer;
      transition: .1s linear all;
      &:hover {
        transform: scale(1.05);
      }
      &:active {
        transform: scale(.95);
      }
      &--img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        box-shadow: 0 0px 6px 5px #00fdff;
      }
      &--notification {
        position: absolute;
        right: .1rem;
        top: .15rem;
        height: .5rem;
        width: .5rem;
        background: #ffeb10;
        border-radius: 50%;
        box-shadow: 0px 0px 4px 3px #ff7d10;
      }
    }
  }
  .events {
    height: 100%;
    width: 100%;
    z-index: 2;
    background: #000000ca;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  @media only screen and (max-width: 1366px),
              screen and (max-height: 870px) {
  }
  @media only screen and (max-width: 1150px),
            screen and (max-height: 720px) {
  }
  @media only screen and (max-width: 960px),
           screen and (max-height: 620px) {
            .boshipanda__icon {
              bottom: calc(1rem + 50px);
              right: .8rem;
              height: 60px;
              width: 60px;
            }
           }
</style>
