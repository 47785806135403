<template>
<!--   <div id="video_container"
    class="wistia_embed seo=false videoFoam=true"
    :class="wistiaClass"
    style="height:100%;width:100%">&nbsp;
  </div> -->
  <iframe class='sproutvideo-player'
    :src='sproutSrc'
    width='630' height='473' frameborder='0' allowfullscreen></iframe>
</template>

<script>

export default {
  name: 'VideoPlayer',
  props: {
    code: Array,
    playCallback: Function,
  },
  data() {
    return {
      video: '',
    };
  },
  computed: {
    sproutSrc() {
      const baseUrl = 'https://videos.sproutvideo.com/embed/';
      return baseUrl + this.code[0] + '/' + this.code[1];
    },
    // wistiaClass() {
    //   const classRoute = 'wistia_async_' + this.code;

    //   const result = {};
    //   result[classRoute] = typeof this.code !=='undefined'
    // && this.code !== '';
    //   return result;
    // },
    // ...mapState('library', ['currentStoryArc']),
  },
  watch: {
    // attaches bindings to wistia player once loaded
    code(newVal) {
      this.updateComponent();
    },
  },
  mounted() {
    console.log('mounting video player component');
    this.updateComponent();
  },
  methods: {
    updateComponent() {
      if (typeof this.code ==='undefined' || this.code === '') {
        return;
      };

      // when we are going to a different video (e.g. next, previous)
      // we need to use the replaceWith method to change the video code
      if (this.video && this.video.hashedId() !== this.code) {
        this.video.replaceWith(this.code);
      };

      // // wistia code to store a link to the video
      // window._wq = window._wq || [];
      // _wq.push({id: this.code, onReady: (video) => {
      //   this.video = video;

      //   video.bind('play', () => {
      //     this.playCallback();
      //   });
      // }});
    },
  },
};
</script>

<style lang="scss" scoped>
  #video_container {
    margin: auto;
  }
</style>
