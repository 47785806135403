<template>
  <div class="toggle-difficulty__component">
    <p>故事难度：</p>
    <button
      class="toggle-difficulty__btn"
      @click="_toggleDifficulty">{{ text }}</button>
  </div>
</template>

<script>

import {mapState, mapMutations} from 'vuex';

export default {
  name: 'ToggleDifficulty',
  components: {
  },
  props: {
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapState('dynamicBook', ['difficulty']),
    text() {
      return (this.difficulty === 0 ? 'EASY' : 'INTERMEDIATE');
    },

  },
  mounted: function() {
  },
  methods: {
    ...mapMutations('dynamicBook', ['toggleDifficulty']),
    _toggleDifficulty() {
      this.toggleDifficulty();
      const action = this.difficulty === 1 ? 'increased' : 'decreased';
      alert('Difficulty ' + action + ' for the rest of the book.');
    },
  },
};
</script>

<style lang="scss" scoped>
  .toggle-difficulty {
    &__component {
      position: absolute;
      left: 20px;
      bottom: 20px;
      p {
        margin-bottom: 10px;
        color: #fff;
      }
    }
    &__btn {
      padding: 10px 20px;
      font-size: .8rem;
      border: none;
      text-decoration: none;
      outline: none;
    }
  }
</style>
