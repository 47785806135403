<template>
  <!-- Placeholder -->
  <div class="raid-boss__component">
    <div class="raid-boss__stats--container animated bounceIn delay-1">
      <div class="raid-boss__stats--portrait--container">
        <div class="raid-boss__stats--portrait--frame">
          <div class="raid-boss__stats--portrait--highlight">
            <div class="highlight-white-2"></div>
            <div class="raid-boss__stats--portrait">
              <img src="../../../assets/raid/raid-boss--portrait.png">
            </div>
          </div>
        </div>
      </div>
      <div class="raid-boss__stats--healthbar-container--frame">
        <p>{{ healthPercent * 100 + '%'}}</p>
        <div class="raid-boss__stats--healthbar-container">
          <div class="highlight-white-2"></div>
          <div
            class="raid-boss__stats--healthbar"
            :style="{width:healthPercent * 100 + '%'}"
          ></div>
        </div>
      </div>
    </div>
    <div class="raid-boss__card--container animated jackInTheBox delay-2">
      <div class="raid-boss__card--frame">
        <div class="raid-boss__card--highlight">
          <div class="highlight-white"></div>
          <div class="raid-boss__card">
            <img src="../../../assets/raid/raid-boss.png">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'RaidBossStats',
  components: {
  },
  props: {
    healthPercent: Number,
  },
  data() {
    return {
    };
  },
  computed: {
  },
  mounted: function() {
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
  .highlight-white {
    width: 10px;
    height: 5px;
    border-radius: 45%;
    background: #ffffff94;
    position: absolute;
    top: 2px;
    right: 3px;
  }
  .highlight-white-2 {
    width: 7.5px;
    height: 3px;
    border-radius: 45%;
    background: #ffffff94;
    position: absolute;
    top: 1.5px;
    right: 2px;
  }
  .delay-1 {
    animation-delay: 0.3s;
  }
  .delay-2 {
    animation-delay: 0.4s
  }
  .raid-boss {
    &__component {
      margin: 1rem;
    }
    &__card {
      background: #bdc0c1;
      display: inline-flex;
      padding: 1.25rem 0.5rem;
      border: 3px solid #cca857;
      &--highlight {
        border: 4px solid #ff9317;
        display: inline-flex;
        border-top-color: #ffd22e;
        border-left-color: #f5bc03;
        border-right-color: #ffba17;
        border-bottom-color: #f9ab3b;
      }
      &--container {
        border: 4px solid #000;
        display: inline-flex;
        border-radius: 5px;
      }
      &--frame {
        border: 10px solid #ff9317;
        display: inline-flex;
        border-top-color: #ffbd29;
        border-left-color: #f9a800;
        border-right-color: #f9a800;
        border-bottom-color: #ff9700;
      }
      i {
        font-size: 7.5rem;
        padding: 5rem 2rem;
      }
    }
    &__stats {
      &--container {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 0.5rem;
      }
      &--portrait {
        border: 1px solid #cca857;
        width: 3rem;
        height: 3rem;
        background: #bcbfc1;
        &--highlight {
          border: 2px solid #ff9317;
          display: inline-flex;
          border-top-color: #ffd22e;
          border-left-color: #f5bc03;
          border-right-color: #ffba17;
          border-bottom-color: #f9ab3b;
        }
        &--container {
          border: 4px solid #000;
          display: inline-flex;
          border-radius: 5px;
          position: relative;
        }
        &--frame {
          border: 5px solid #ff9317;
          display: inline-flex;
          border-top-color: #ffbd29;
          border-left-color: #f9a800;
          border-right-color: #f9a800;
          border-bottom-color: #ff9700;
        }
        img {
          width: 101%;
          height: 101%;
        }
      }
      &--healthbar {
        height: 100%;
        width: 100%;
        background: #de3e5a;
        &-container {
          background: #f3f3f3;
          width: 375px;
          height: 40px;
          overflow: hidden;
          border: 6px solid #ce334f;
          border-top: 6px solid #ea5e78;
          border-left: 3px solid #ce334f;
          &--frame {
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
            border: 4px solid #000;
            border-left: none;
            position: relative;
            p {
              position: absolute;
              text-align: center;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
              font-weight: 500;
            }

          }
        }
      }
    }
  }
  @media only screen and (max-height: 960px) {
    .raid-boss__card {
      img {
        width: 325px;
        height: 361px;
      }
    }
  }
  @media only screen and (max-height: 920px) {
    .raid-boss__card {
      img {
        width: 300px;
        height: 333.5px;
      }
    }
  }
  @media only screen and (max-height: 860px) {
    .raid-boss__card {
      img {
        width: 250px;
        height: 277.8px
      }
    }
  }
  @media only screen and (max-height: 700px) {
    .raid-boss__card {
      img {
        width: 200px;
        height: 222.3px;
      }
    }
  }
  @media only screen and (max-height: 660px) {
    .raid-boss__card {
      img {
        width: 175px;
        height: 194.5px;
      }
    }
  }
  @media only screen and (max-height: 612px) {
    .raid-boss__stats--container {
      display: none;
    }
  }
  @media only screen and (max-height: 460px) {
    .raid-boss__card {
      img {
        width: 150px;
        height: 166.7px;
      }
    }
  }
  @media only screen and (max-height: 414px) {
    .raid-boss__card {
      padding: 0.5rem 0.5rem;
      img {
        width: 125px;
        height: 139px;
      }
    }
    .raid-boss {
      &__component {
        margin: 0;
      }
    }
  }
  @media only screen and (max-height: 320px) {
    .raid-boss__card {
      padding: 0.5rem 0.5rem;
      img {
        width: 80px;
        height: 89px;
      }
    }
  }
  @media only screen and (max-width: 475px) {
    .raid-boss__stats--healthbar-container {
      width: 275px;
      height: 30px;
    }
    .raid-boss__stats--portrait {
      width: 2.5rem;
      height: 2.5rem;
    }
    .raid-boss {
      &__component {
        margin: 0;
      }
    }
  }
  @media only screen and (max-width: 414px) {
    .raid-boss__stats--healthbar-container {
      width: 250px;
    }
    .raid-boss__card {
      img {
        width: 175px;
        height: 194.5px;
      }
    }
  }
  @media only screen and (max-width: 330px) {
    .raid-boss__stats--healthbar-container {
      width: 225px;
    }
    .raid-boss__card {
      img {
        width: 125px;
        height: 139px;
      }
    }
  }

</style>
