<!--
  https://medium.com/js-dojo/how-to-create-an-animated-countdown-timer-with-vue-89738903823f
-->
<template>
  <div
    v-if="!hide"
    class="base-timer animated flipInX">
    <svg class="base-timer__svg" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
      <g class="base-timer__circle">
        <circle
          class="base-timer__path-elapsed" cx="50" cy="50" r="45"></circle>
        <path
          id="base-timer-path-remaining"
          :stroke-dasharray="circleDasharray"
          class="base-timer__path-remaining"
          :class="remainingPathColor"
          d="
            M 50, 50
            m -45, 0
            a 45,45 0 1,0 90,0
            a 45,45 0 1,0 -90,0
          "
        ></path>
      </g>
    </svg>
    <span id="base-timer-label"
      class="base-timer__label animated heartBeat infinite">
      {{ formattedTimeLeft }}
    </span>
  </div>
</template>

<script>
// import axios from 'axios';
// import {mapState} from 'vuex';

export default {
  name: 'Timer',
  components: {
  },
  props: {
    timerDuration: {
      type: Number,
      required: true,
    },
    hideWhenDone: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      hide: false,
      timerInterval: null,
      timePassed: 0,
    };
  },
  computed: {
    formattedTimeLeft() {
      const timeLeft = this.timerDuration - this.timePassed;
      // const minutes = Math.floor(timeLeft / 60);
      const seconds = timeLeft;
      // let seconds = timeLeft % 60;

      /* If the value of seconds is less than 10,
       then display seconds with a leading zero */
      // if (seconds < 10) {
      //   seconds = '0' + seconds;
      // }

      /* The output in MM:SS format */
      // return minutes + ':' + seconds;
      return seconds;
    },
    // Divides time left by the defined time limit
    timeFraction() {
      const rawTimeFraction = (this.timerDuration - this.timePassed)
          / this.timerDuration;

      return rawTimeFraction -
          (1 / this.timerDuration) * (1 - rawTimeFraction);
    },
    circleDasharray() {
      const FULL_DASH_ARRAY = 283;
      return (this.timeFraction * FULL_DASH_ARRAY).toFixed(0) + ' 283';
    },
    COLOR_CODES() {
      // thresholds are in %
      return {
        info: {
          color: 'green',
        },
        warning: {
          color: 'orange',
          threshold: 0.67,
        },
        alert: {
          color: 'red',
          threshold: 0.33,
        },
      };
    },
    remainingPathColor() {
      const {alert, warning, info} = this.COLOR_CODES;

      if (this.timeFraction <= alert.threshold) {
        return alert.color;
      } else if (this.timeFraction <= warning.threshold) {
        return warning.color;
      } else {
        return info.color;
      };
    },
  },
  mounted: function() {
    this.startTimer();
  },
  methods: {
    startTimer() {
      this.timerInterval = setInterval(() => {
        this.timePassed += 1;

        // stops duration once reached
        if (this.timePassed >= this.timerDuration) {
          console.log('ending');
          clearInterval(this.timerInterval);
          if (this.hideWhenDone) this.hide = true;
          this.$emit('end', 'hello');
        };
      }, 1000);
    },
  },
};
</script>

<style lang="scss" scoped>
  body {
    font-family: sans-serif;
  }

  .base-timer {
    position: relative;
    width: 200px;
    height: 200px;
    animation-delay: 0.3s;
  }

  .base-timer__svg {
    transform: scaleX(-1);
    background: #ffffff95;
    border-radius: 50%
  }

  .base-timer__circle {
    fill: none;
    stroke: none;
  }

  .base-timer__path-elapsed {
    stroke-width: 7px;
    stroke: grey;
  }

  .base-timer__path-remaining {
    stroke-width: 7px;
    stroke-linecap: round;
    transform: rotate(90deg);
    transform-origin: center;
    transition: 1s linear all;
    fill-rule: nonzero;
    stroke: currentColor;
  }

  .base-timer__path-remaining.green {
    color: rgb(65, 184, 131);
  }

  .base-timer__path-remaining.orange {
    color: orange;
  }

  .base-timer__path-remaining.red {
    color: red;
  }

  .base-timer__label {
    position: absolute;
    width: 200px;
    height: 200px;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 3.5rem;
    animation-delay: 1s;
  }
</style>
