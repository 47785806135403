<template>
  <block-container
    title="Switch User"
    fa-icon="users"
    previous-page-name="new-settings-overview"
    >
    <ul class="switch-user__component">
      <li class="switch-user__component--item active">
        <div class="switch-user__component--item-label">
          Current
        </div>
        <div class="switch-user__component--item-img">
          <img class="avatar-img"
          :src='"../../../assets/avatars/" + avatar + ".png"' />
        </div>
        <div class="switch-user__component--item-text">
          <p>{{ studentName }}</p>
        </div>
      </li>
      <li v-for="(sibling, index) in siblings"
        :key="index"
        class="switch-user__component--item"
        @click="switchUser(sibling)"
        >
        <div class="switch-user__component--item-img">
          <img class="avatar-img"
            :src='"../../../assets/avatars/" + sibling.avatar + ".png"' />
        </div>
        <div class="switch-user__component--item-text">
          <p>{{ sibling.display_name }}</p>
        </div>
      </li>
    </ul>
  </block-container>
</template>

<script>

import {mapState, mapMutations, mapActions} from 'vuex';

import BlockContainerComponent
  from '../common/BlockContainerComponent.vue';

export default {
  name: 'NewSettingsSwitchUser',
  components: {
    BlockContainer: BlockContainerComponent,
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapState(['studentName', 'siblings', 'avatar']),
  },
  mounted() {
  },
  methods: {
    ...mapMutations(['setShowGlobalLoadingScreen']),
    ...mapActions(['loadToken', 'pullData']),
    switchUser(sibling) {
      this.loadToken(sibling.token)
          .then((response) => {
            this.pullData()
                .then((response) => {
                  this.$router.push({name: 'profile-main'});
                  this.setShowGlobalLoadingScreen(false);
                })
                .catch((error) => {
                  window.location.reload();
                });
          });
      this.setShowGlobalLoadingScreen(true);
    },
  },
};
</script>


<style lang="scss" scoped>
  .switch-user__component {
    width: 100%;
    padding: 4rem;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    position: absolute;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    text-align: center;
    grid-gap: 2rem;
    &--item {
      height: 100%;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      text-align: center;
      background: #fff;
      border-radius: 0.75rem;
      text-decoration: none;
      border: 7px solid transparent;
      transition: all 0.2s;
      position: relative;
      box-shadow: 0px 5px #bebebe;
      &-label {
        position: absolute;
        top: -1rem;
        font-weight: 600;
        font-size: 1.1rem;
        background: #1deac7;
        color: #fff;
        padding: 0 1rem;
        border-radius: .5rem;
        box-shadow: 0px 5px #ad2fca;
      }
      &-img {
        overflow: hidden;
        border-top-right-radius: .4rem;
        border-top-left-radius: .4rem;
        background: #f525fa;
        width: 100%;
        height: 80%;
        display: flex;
        justify-content: center;
        align-items: flex-end;
      }
      &-text {
        width: 100%;
        height: 20%;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.2rem;
        font-weight: 600;
      }
      &:hover {
        background: #fff;
        border: 7px solid #bd2bf8;
        box-shadow: 0px 5px #8d2fca;
      }
      img {
        width: 100%;
        background: linear-gradient(to top, #ffd600, transparent);
      }
    }
    .active {
      border: 7px solid #ffaf50;
      box-shadow: 0px 5px #b37a38;
    }
  }
    @media only screen and (max-width: 1560px) {
    }
    @media only screen and (max-width: 1366px) {
      .switch-user__component {
        grid-template-columns: 1fr 1fr;
      }
    }
    @media only screen and (max-width: 960px),
      screen and (max-height: 620px) {
      .switch-user__component {
        grid-template-columns: 1fr 1fr 1fr;
      }

    }
    @media only screen and (max-width: 680px) {
      .switch-user__component {
        grid-template-columns: 1fr 1fr;
      }
    }
    @media only screen and (max-width: 510px) {
      .switch-user__component {
        grid-template-rows: 1fr 1fr;
        padding: 2.5rem;
      }
    }
    @media only screen and (max-width: 440px) {
      .switch-user__component {
        grid-template-rows: 1fr 1fr 1fr;
        padding: 1rem;
        grid-gap: 1rem;
        padding-top: 2rem;
      }
    }
</style>
