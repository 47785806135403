<template>
  <ul class="diamond-shop__item-list--component animated fadeIn faster">
    <shop-item
      v-for="item in items"
      :key="item.index"
      :item="item"
      :ordered-counts="orderedCounts"
      :popup-toggle="popupToggle"
    ></shop-item>
  </ul>
</template>

<script>

import ShopItemComponent
  from './ItemComponent.vue';

export default {
  name: 'ShopItemList',
  components: {
    ShopItem: ShopItemComponent,
  },
  props: {
    items: Array,
    orderedCounts: Object,
    popupToggle: Function,
  },
  data() {
    return {
    };
  },
  computed: {
  },
  mounted: function() {
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
  .diamond-shop {
    &__item-list {
      &--component {
        display: flex;
        flex-wrap: wrap;
      }
    }
  }
  @media only screen and (max-width: 1115px) {
    .diamond-shop__item-list--component {
      margin-bottom: 32px;
    }
  }
</style>
