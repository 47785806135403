<template>
  <div class="switch-user-profiles">
    <!-- user profile template-->
    <!-- foreach profile, generate template -->
    <div class="switch-user-profile">
      <img
        v-if="avatar !== ''"
        class="user-avatar"
        :src='"../../assets/avatars/" + avatar + ".png"' />
      <span class="student-name">{{ studentName }}</span>
      <!-- v-if: apply class 'logged-in' if logged in -->
      <!--       apply class 'disabled' if not logged in -->
      <i class="fas fa-check logged-in"></i>
    </div>
    <!-- PLACEHOLDER EXAMPLE: sibling user profiles -->
    <a v-for="sibling in siblings"
      :key="sibling.id"
      class="switch-user-profile"
      @click="switchUser(sibling.token)">
      <img
        v-if="avatar !== ''"
        class="user-avatar"
        :src='"../../assets/avatars/" + sibling.avatar + ".png"' />
        <!-- hardcode styles -->
      <span class="student-name">
        {{ sibling.display_name }}
      </span>
      <i class="fas fa-check disabled"></i>
    </a>
  </div>
</template>

<script>
import {mapState, mapActions} from 'vuex';

export default {
  name: 'TheHeaderSwitchUser',
  components: {
  },
  props: {
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapState(['studentName', 'avatar', 'siblings']),
  },
  mounted: function() {
  },
  methods: {
    ...mapActions(['loadToken', 'pullData']),
    switchUser(token) {
      this.loadToken(token)
          .then((response) => {
            this.pullData()
                .then((response) => {
                  this.$router.push({name: 'profile-main'});
                });
          });
      this.$emit('closePopup');
    },
  },
};
</script>

<style lang="scss" scoped>
  .disabled {
    display: none;
  }
  .switch-user-profile {
    padding: 5px 20px;
    display: flex;
    align-items: center;
    cursor: pointer;
    .user-avatar {
      margin-left: 10px;
      height: 20%;
      width: 20%;
      border: 2px solid #ddd;
      background-color: white;
      border-radius: 50%;
      padding: 2% 2%;
    }
    .student-name {
      color: #333;
      font-size: 90%;
      margin-left: 8px;
      text-transform: capitalize;
    }
    .logged-in {
      color: #ff5d83;
      margin-left: auto;
    }
    &:hover {
      background: #eee;
    }
  }
</style>
