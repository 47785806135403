<template>
  <div class="choice-scene__component">
    <!-- <p>Narrator Name: {{ narrator }}</p> -->
    <p class="choice-scene__text"><slot></slot> {{ textToDisplay }}</p>
    <div class="choice-scene__btn--group">
      <button
      class="choice-scene__btn"
      v-for="choice in choices"
      :key="choice.nextSceneId"
      @click="_goTo(choice.nextSceneId)">
    {{ choice.text }}</button>

    </div>
  </div>
</template>

<script>

import {mapActions} from 'vuex';

export default {
  name: 'DynamicBookChoiceScene',
  components: {
  },
  props: {
    narrator: String,
    textToDisplay: String,
    choices: Array,
  },
  data() {
    return {
    };
  },
  computed: {
  },
  methods: {
    ...mapActions('dynamicBook', ['goToScene']),
    _goTo(nextSceneId) {
      this.goToScene(nextSceneId);
    },
  },
};
</script>

<style lang="scss" scoped>
  .choice-scene {
    &__component {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      flex-direction: column;
    }
    &__text {
      font-size: 2rem;
      color: #fff;
    }
    &__btn {
      padding: 10px 20px;
      border: none;
      border-radius: 10px;
      background: #fff;
      font-size: 1.2rem;
      cursor: pointer;
      &:not(:last-child) {
        margin-right: 20px;
      }
      &--group {
        margin-top: 40px;
      }
    }
  }
</style>
