<template>
  <div class="reward__component animated fadeIn faster">
    <!-- COINS -->
    <div class="reward__content--container">
      <div class="reward__content--loots">
        <div v-if="coinsToAdd > 0"
          class="reward__content--coins animated bounceIn fast">
          <div class="reward__content--header">
            <h2>COINS</h2>
          </div>
          <div class="reward__content--img">
            <div class="coin-circle">
              <i class="fas fa-star"></i>
            </div>
          </div>
          <div class="reward__content--count">
            <h2>x{{coinsToAdd}}</h2>
          </div>
        </div>
        <!-- <div v-if="diamondsToAdd > 0"
          class="reward__content--diamonds animated bounceIn fast">
          <div class="reward__content--header">
            <h2>DIAMONDS</h2>
          </div>
          <div class="reward__content--img">
            <div class="diamond-circle">
              <i class="fas fa-gem"></i>
            </div>
          </div>
          <div class="reward__content--count">
            <h2>x{{diamondsToAdd}}</h2>
          </div>
        </div> -->
      </div>
      <img class="sunburst rotating-anim" src="../../../assets/sunburst.png">
      <router-link
        :to="{name:'main-leaderboard-assessment-book',
        params: {assessmentBookId: assessmentBookId}}"
        :assessment-book-id="assessmentBookId"
        class="reward__content--btn
        animated fadeInUp fast">
        <span>Continue</span>
      </router-link>
      <!-- <div
        @click="togglePopup = !togglePopup"
        class="reward__content--footer">
        <p class="reward__content--footer-counter">
          Weekly Diamonds Earned: {{ diamondsObtainedThisWeek }} / 20
        </p>
        <p
          class="reward__content--footer-info">
          How to Earn Diamonds?
        </p>
      </div>
      <popup-notification
        v-if="togglePopup"
        v-on:closePopup="togglePopup = !togglePopup">
        <h2>How to Earn Diamonds?</h2>
        <p>You earn Diamonds by answering questions correctly.</p>
        <h2>Weekly Diamond Limit</h2>
        <p>You can earn up to 20 Diamonds per week.
          Please try again next week if you have hit your limit.</p>
      </popup-notification> -->
    </div>
  </div>
</template>

<script>

// import PopupNotification
//   from '../../common/PopupNotification.vue';

export default {
  name: 'LearningTrackAssessmentBookReward',
  components: {
    // PopupNotification: PopupNotification,
  },
  props: {
    assessmentBookId: Number,
    coinsToAdd: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      togglePopup: false,
    };
  },
  computed: {
  },
  mounted: function() {
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
// STYLES FOR LOOTS
.reward {
  &__component {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 30;
    background: #59e0c1;
    overflow: hidden;
  }
  &__content {
    &--footer {
      position: absolute;
      bottom: 1rem;
      z-index: 40;
      text-align: center;
      &-counter {
        color: #000;
        font-weight: 900;
        font-size: 0.9rem;
        margin-bottom: 0.25rem;
      }
      &-info {
        display: inline-block;
        cursor: pointer;
        background: #775fff;
        color: #fff;
        font-weight: 900;
        border-radius: 50px;
        padding: 0.15rem 2rem;
        border: 4px solid #424242;
        font-size: 0.8rem
      }
    }
    &--loots {
      display: flex;
      justify-content: space-between;
    }
    &--coins,
    &--diamonds {
      display: inline-flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding: 1rem 2.5rem;
      background: #fff;
      color: #000;
      border-radius: 10px;
      border: 8px solid #000;
      min-width: 265px;
      margin: 0 0.5rem;
    }
    &--coins {
      background: radial-gradient(circle, #07ffe8 0%, #007eff 100%);
    }
    &--diamonds {
      background: radial-gradient(circle, #ffe500 0%, #ff8d00 100%);
    }
    &--container {
      padding-bottom: 75px;
      position: absolute;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
    &--header{}
    &--img {
      margin: 20px 0;
    }
    &--count {}
    &--btn {
      margin-top: 2rem;
      cursor: pointer;
      display: inline-block;
      padding: 0.25rem 1rem;
      font-weight: 900;
      font-size: 1.1rem;
      border-radius: 5px;
      border: 4px solid #22222280;
      transition: all 0.3s;
      outline: none;
      background: #e8e8e8;
      color: #000;
      text-decoration: none;
      &:hover {
        display: inline-block;
        transform: scale(1.1);
        border: 4px solid #222222;
      }
      &:active {
        display: inline-block;
        transform: scale(1.075);
      }
    }
  }
}
// STYLES FOR COINS
.coin-circle {
  background: #ffbe43;
  border: 6px solid #000;
  border-radius: 50%;
  height: 120px;
  width: 120px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  i {
    font-size: 4rem;
    color: #fff;
  }
}

// STYLES FOR DIAMONDS
.diamond-circle {
  border: 6px solid #000;
  background: #fff;
  border-radius: 50%;
  height: 120px;
  width: 120px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  i {
    margin-top: 10px;
    font-size: 4rem;
    color: #4f7dff;
  }
}

// SUNBURST ANIMATION AND STYLINGS
.sunburst {
  z-index: 40;
  position: absolute;
}
.reward__content--coins,
.reward__content--diamonds,
.reward__content--btn {
  z-index: 50;
}

@-webkit-keyframes rotating-anim /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating-anim {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.rotating-anim {
  -webkit-animation: rotating-anim 20s linear infinite;
  -moz-animation: rotating-anim 20s linear infinite;
  -ms-animation: rotating-anim 20s linear infinite;
  -o-animation: rotating-anim 20s linear infinite;
  animation: rotating-anim 20s linear infinite;
}
</style>
