<template>
  <block-container
    title="任务"
    subtitle="Complete Missions to Win Diamonds!"
    :breadcrumb-info="breadcrumbInfo"
    :body-background-color="bodyBackgroundColor"
    >
    <div class="body-content">
      <div class="mission-category-btns">
        <div class="mission-category-btn"
          @click="missionCategory = 'daily'"
          :class="{ 'mission-category-btn--active': missionCategory === 'daily' }">
          Daily
        </div>
        <div class="mission-category-btn"
          @click="missionCategory = 'weekly'"
          :class="{ 'mission-category-btn--active': missionCategory === 'weekly' }">
          Weekly
        </div>
        <!-- <router-link
          class="mission-category-btn"
          :to="{name: 'new-events-overview'}">
          Special
        </router-link> -->
      </div>
      <div v-if="missionCategory == 'daily'"
        class="mission-items">
        <mission-row
          v-for="(mission, index) in missions.daily"
          @claim="claim"
          :key="index" :info="mission">
        </mission-row>
      </div>
      <!-- test: weekly -->
      <div v-if="missionCategory == 'weekly'"
        class="mission-items">
        <mission-row
          v-for="(mission, index) in missions.weekly"
          @claim="claim"
          :key="index" :info="mission">
        </mission-row>
      </div>
      <!-- test: special -->
      <div v-if="missionCategory == 'special'"
        class="mission-items">
        <div class="mission-item">
          <div class="mission-item--col-1">
            <img src="../../../assets/profile/diamond.png">
          </div>
          <div class="mission-item--col-2">
            <div class="mission-item--col-2--text">
              <h3>Special Mission 1</h3>
            </div>
            <div class="mission-item--col-2--progress">
              <div class="mission-item--col-2--progress-bar">
                <div class="mission-item--col-2--progress-bar--fill"></div>
              </div>
              <div class="mission-item--col-2--progress-text">
                0 / 3
              </div>
            </div>
          </div>
          <div class="mission-item--col-3">
            <i class="go-icon">Go</i>
          </div>
        </div>
      </div>
    </div>
    <basic-reward v-if="showRewardsPage"
      :diamonds-to-add="diamondsToAdd"
      :close-rewards-callback="onCloseRewards">
    </basic-reward>
    <narrator-popup
      v-if="showOnboardingPopup"
      :dialogue-data="dialogueData"
      :end-callback="onCloseOnboardingPopup">
    </narrator-popup>
  </block-container>
</template>

<script>
import axios from 'axios';
import BasicRewardComponent
  from '../../common/BasicRewardComponent.vue';

import BlockContainerComponent
  from '../common/BlockContainerComponent.vue';

import MissionRowComponent
  from './components/MissionRowComponent.vue';

import NarratorPopupComponent
  from '../../common/NarratorPopupComponent.vue';

import {mapState, mapMutations, mapGetters} from 'vuex';

export default {
  name: 'NewMissionsOverview',
  components: {
    BlockContainer: BlockContainerComponent,
    BasicReward: BasicRewardComponent,
    MissionRow: MissionRowComponent,
    NarratorPopup: NarratorPopupComponent,
  },
  data() {
    return {
      fetching: false,
      missionCategory: 'daily',
      diamondsToAdd: 0,
      showRewardsPage: false,
      showOnboardingPopup: false,
      bodyBackgroundColor: 'linear-gradient(to right bottom, rgba(29, 36, 77, 0.79), rgb(21 24 41 / 86%))',
    };
  },
  computed: {
    ...mapState(['missions']),
    dialogueData() {
      return [
        {
          narrator: 'flamebun',
          texts: ['Complete missions by answering Chinese questions, and win diamonds.', 'New missions are added daily, so remember to check-in everyday.'],
        },
        {
          narrator: 'mangsha',
          texts: ['Here\'s a free gift for logging in. Click on the Gift icon to claim it!'],
        },
      ];
    },
  },
  mounted() {
    if (!this.hasUsedFeature()('onboarding_popup_missions')) {
      this.showOnboardingPopup = true;
    }
  },
  methods: {
    ...mapMutations(['setDiamonds', 'setMissions']),
    ...mapGetters(['hasUsedFeature']),
    // opens the showRewards Page
    claim(args) {
      console.log('trying to claims');
      console.log(args);
      this.setDiamonds(args.responseData.diamonds);
      this.setMissions(args.responseData.missions);
      this.diamondsToAdd = args.responseData.diamonds_to_add;
      this.showRewardsPage = true;
    },
    // called after user has opened rewards page, and clicks on it to close
    onCloseRewards() {
      this.showRewardsPage = false;
    },
    onCloseOnboardingPopup() {
      this.showOnboardingPopup = false;
      axios
          .post('/api/v1/core/student/used_features/', {
            featureName: 'onboarding_popup_missions',
          });
      this.addToUsedFeatures('onboarding_popup_missions');
    },
  },
};
</script>


<style lang="scss" scoped>

  .body-content {
    height: 100%;
    width: 100%;
    overflow: hidden;
    position: relative;
  }
  .mission-items {
    height: 100%;
    width: 100%;
    padding: 0rem 2rem 7rem 2rem;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .mission-category {
    &-btn {
      border-bottom: 4px solid #193295;
      border-right: 4px solid #193295;
      border-top: 4px solid #5879fe;
      border-left: 4px solid #5879fe;
      color: #fff;
      background: #2c57ff;
      padding: .1rem;
      width: 45%;
      border-radius: 0.5rem;
      font-size: .9rem;
      margin-right: .5rem;
      text-align: center;
      cursor: pointer;
      &:last-child {
        margin-right: 0;
      }
      &:hover {
        box-shadow: 0 0px 1px 3px #ff00dd;
      }
      &--active {
        color: #fff;
        background: #ffa025;
        font-weight: 600;
        border-top: 4px solid #bf6800;
        border-left: 4px solid #bf6800;
        border-bottom: 4px solid #ffe758;
        border-right: 4px solid #ffe758;
        box-shadow: 0 0px 1px 3px #00ffea;
        &:hover {
          box-shadow: 0 0px 1px 3px #00ffea;
          cursor: auto;
        }
      }
    }
    &-btns {
      display: flex;
      margin-top: 1rem;
      margin-bottom: 1rem;
      justify-content: center;
      align-items: center;
    }
  }

  @media only screen and (max-width: 1366px),
  screen and (max-height: 870px)  {
    .mission-item {
      &--col-1 {
        font-size: 1.75rem;
        width: 90px;
      }
      &--col-2 {
        &--progress-bar {
          height: .8rem;
        }
        &--text, &--progress-text {
          font-size: 0.9rem;
        }
      }
      &--col-3 {
        font-size: 1.75rem;
        width: 90px;
        .go-icon {
          font-size: 1.25rem;
        }
      }
    }
  }
  @media only screen and (max-width: 520px) {
    .mission-items {
      padding: 0rem .75rem 7rem .75rem;
    }
    .mission-category-btns {
      margin-top: 1rem;
    }
  }

  @media only screen and (max-width: 420px) {
    .mission-category-btn {
      width: 5rem;
    }
  }
</style>
