<template>
  <span @click="select" class="entity chinese-character" :class="{selected: isSelected}">{{ info.text }}</span>
</template>

<script>

import axios from 'axios';
import {mapState, mapMutations} from 'vuex';

export default {
  name: 'GuidedReadingEntity',
  components: {
  },
  props: {
    info: Object,
    highlightIfSimilarEntitySelected: {
      type: Boolean,
      default: true,
    },
    index: {
      type: Number,
      default: -1,
    },
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapState(['studentId']),
    ...mapState('guidedReading', ['selectedEntity', 'comprehensionId']),
    isSelected() {
      if (this.info['type'] === 'particle' || !this.selectedEntity) return false;
      // If highlightIfSimilarEntitySelected is true, highlight all entities with the same text
      // If false, only highlight the entity with the same text and index
      // kiv: this wont work if same character has different meanings in same passage - e.g. 地 (de vs di)
      // fix that in future
      if (this.highlightIfSimilarEntitySelected) {
        return this.selectedEntity['text'] === this.info['text'];
      }
      return this.selectedEntity['text'] === this.info['text'] && this.selectedEntity['index'] === this.index;
    },
  },
  mounted() {
    // validation
    if (!this.highlightIfSimilarEntitySelected && this.index === -1) {
      alert('Index must be specified when highlightIfSimilarEntitySelected is false');
    }
  },
  methods: {
    ...mapMutations('guidedReading', ['setSelectedEntity']),
    select() {
      if (this.info['type'] === 'particle') return;
      if (this.highlightIfSimilarEntitySelected) {
        this.setSelectedEntity(this.info);
      } else {
        this.setSelectedEntity({...this.info, index: this.index});
      }
      if (this.info['is_trackable']) {
        axios
            .post(`/api/v1/core/student/${this.studentId}/frequencylist/`, {
              wordId: this.info['pk'],
              comprehensionId: this.comprehensionId,
            });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.entity {
  cursor: pointer;
}

.selected {
  background: #1dffca;
  border-radius: 0.3em;
  padding: 0.2rem 0;
}

.correct {
  background: #431dff;
  border-radius: 0.3em;
  padding: 0.2rem 0;
}

.wrong {
  background: #ff1d1d;
  border-radius: 0.3em;
  padding: 0.2rem 0;
}
</style>
