<template>
  <div class="quiz-header__component">
    <a href="#" class="quiz-header__component--btn-close"
      @click.prevent="$emit('endQuizCallback')">
      <i class="fa-solid fa-xmark"></i>
    </a>
    <div class="quiz-header__component--progress-bar">
      <div
        :style="{width: progressBarWidth}"
        class="quiz-header__component--progress-bar--fill"></div>
    </div>
    <div class="quiz-header__component--qns-tracker">
      Qns {{ questionSubmitted }} / {{ totalNumberOfQuestions }}
    </div>
  </div>
</template>

<script>

export default {
  name: 'QuizHeader',
  components: {
  },
  props: {
    totalNumberOfQuestions: Number,
    questionSubmitted: Number,

  },
  data() {
    return {
    };
  },
  computed: {
    progressBarWidth() {
      const percentage = Math.ceil((this.questionSubmitted) / this.totalNumberOfQuestions * 100);
      return `${percentage}%`;
    },
  },
  mounted() {
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
  .quiz-header__component {
    position: absolute;
    top: 0;
    height: 100px;
    width: 100%;
    max-width: 1080px;
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 0 30px;
    // btn-close
    &--btn-close {
      opacity: 0.3;
      color: #000;
      font-size: 1.5rem;
      font-family: "Lato","Arial",sans-serif;
      &:hover {
        opacity: .5;
      }
    }
    // progress bar
    &--progress {
      flex: 1;
      &-bar {
        height: 1rem;
        border-radius: 2rem;
        background: #1a1a1a24;
        flex: 1;
        &--fill {
          // width for progress bar
          transition: .5s all linear;
          width: 50%;
          height: 100%;
          background: #ffb350;
          border-radius: 2rem;
        }
      }
    }
    // question counter
    &--qns-tracker {
      font-family: 'baloo da 2';
      text-align: right;
      font-size: .75rem;
      font-weight: bold;
      padding: 0.25rem 0.5rem;
      border-radius: 20px;
      background: #ffcf6d;
    }
  }
  @media only screen and (max-width: 699px) {
    .quiz-header__component {
      padding: 0 16px;
      height: 80px;
    }
  }

</style>
