<template>
  <i
    :style='keyStyleObject'
    class="fas fa-apple-alt"
  ></i>
<!--   <i
    :style='keyStyleObject'
    class="fas fa-gem"
   ></i>-->
</template>

<script>

export default {
  name: 'DailyChallengeKey',
  components: {
  },
  mixins: [],
  props: {
    color: String,
    completed: Boolean,
  },
  data() {
    return {
    };
  },
  computed: {
    keyStyleObject() {
      return {
        color: this.completed ? this.color : '',
      };
    },
  },
  mounted() {
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
  i {
    padding: 10px;
  }
  @media only screen and (max-width: 360px) {
    i {
      padding: 6px;
    }
  }

</style>
