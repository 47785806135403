<template>
  <div class="leaderboard">
    <div class="leaderboard-container">
      <div class="leaderboard-header">
        <h2 class="leaderboard-header-main">
          {{ title }}
        </h2>
        <router-link
          class="leaderboard-header-sub"
          :to="{name: 'main-leaderboard-coin'}">
          View All
        </router-link>
      </div>
      <div class="leaderboard-content">
        <div class="leaderboard-content-icon">
          <i class="fas fa-trophy"></i>
        </div>
        <!-- when child has a rank -->
        <div v-if="coinLeaderboard['rank']" class="leaderboard-content-text">
          <h3 class="leaderboard-content-text-main">
            You are ranked #{{ coinLeaderboard['rank'] }}
          </h3>
          <p class="leaderboard-content-text-sub">
            <span v-if="coinLeaderboard['coins_to_exceed_next'] > 0" >
              Earn {{ coinLeaderboard['coins_to_exceed_next'] }} more coins to overtake {{ coinLeaderboard['next_player_name'] }}!
            </span>
            <span v-else>
              Congratulations!!!
            </span>
          </p>
        </div>
        <!-- if child has not done anything this month, rank is None -->
        <div v-else class="leaderboard-content-text">
          <h3 class="leaderboard-content-text-main">
            No rank yet.
          </h3>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex';

export default {
  name: 'Leaderboard',
  components: {
  },
  props: {
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapState('widgets', ['coinLeaderboard']),
    title() {
      const MONTHS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

      const currentMonth = MONTHS[new Date().getMonth()];
      return `Leaderboard (${currentMonth})`;
    },
  },
  mounted() {
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
  .leaderboard {
    background: #132647;
    color: #fff;
    margin: .75rem 1rem .75rem 0;
    padding: .25rem;
    border-radius: 0.5rem;
    &-container {
      padding: .25rem .5rem;
      border: 5px solid #1D335C;
      border-radius: 0.5rem;
    }
    &-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      &-main {
        font-size: 1rem;
      }
      &-sub {
        font-size: 0.75rem;
        text-decoration: none;
        &:hover {
          color: #fff;
        }
      }
    }
    &-content {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0.25rem;
      &-icon {
        font-size: 2.75rem;
        color: #F89F2B;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      &-text {
        margin-left: 1rem;
        &-main {
          font-size: .9rem;
        }
        &-sub {
          font-size: 0.75rem;
          line-height: .9rem;
          opacity: 0.6;
        }
      }
    }
  }
  @media only screen and (max-width: 1366px),
              screen and (max-height: 870px) {
    .leaderboard {
      margin: .5rem .5rem 0.5rem 0;
      &-header {
        &-main {
          font-size: 0.9rem;
        }
        &-sub {
          font-size: 0.7rem;
        }
      }
      &-content {
        margin: 0 0.25rem 0.25rem 0.25rem;
        &-icon {
          font-size: 2.5rem;
        }
        &-text {
          &-main {
            font-size: 0.8rem;
          }
          &-sub {
            font-size: 0.7rem;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 1150px),
            screen and (max-height: 720px) {
              .leaderboard {
      margin: .5rem .25rem 0.25rem 0;
      &-header {
        &-main {
          font-size: 0.75rem;
        }
        &-sub {
          font-size: 0.55rem;
        }
      }
      &-content {
        margin: 0.25rem;
        &-icon {
          font-size: 1.75rem;
        }
        &-text {
          margin-left: .5rem;
          &-main {
            font-size: 0.65rem;
          }
          &-sub {
            font-size: 0.6rem;
          }
        }
      }
    }
  }

</style>
