<template>
  <div class="free-response-component chinese-character">
    <div v-if="topic" class="free-response-component__container">
      <!-- {{ topic }} -->
      <div class="free-response-component__header">
        <h2>会话练习：{{ topic.title }}</h2>
        <p>问题：{{ this.questionIndex + 1 }} / {{ this.numQuestions }}</p>
        <router-link
          class="chinese-character"
          :to="{name: 'new-oral-category-index', params: {category: 'freeResponse'}}">
          <a data-v-23d2ba43="" href="#" class="close"></a>
        </router-link>
      </div>
      <div class="free-response-component__content">
        <div class="free-response-component__content--qns-container">
          <div class="free-response-component__nav">
            <div @click="nextQns"
              :class="{ 'nav-disabled': questionIndex === 0 }"
              class="free-response-component__nav-btn">
              <i class="fas fa-chevron-left"></i>
            </div>
            <transition name="fade" mode="out-in">
              <div class="free-response-component__content--qns"
              >{{ currentQuestion['question_text'] }}</div>
            </transition>
            <div @click="nextQns"
              :class="{ 'nav-disabled': questionIndex === numQuestions - 1 }"
              class="free-response-component__nav-btn">
              <i class="fas fa-chevron-right"></i>
            </div>
          </div>
        </div>
        <div class="free-response-component__content--ans">
          <transition name="fade" mode="out-in">
            <div>
              <!-- for now, we only have 1 answer per question -->
              <!-- if we have different answers of different difficulty, then replace 0 with something variable -->
              <p v-for="(text, index) in currentQuestion.answers[0]['clean_text']" :key="index">
                {{ text }}
              </p>
            </div>
          </transition>
        </div>
        <div class="free-response-component__btn-practice--container">
          <router-link
            class="free-response-component__btn-practice"
            :to="{name: 'oral-new-passage',
            params: {
              type: 'freeresponse',
              entityId: currentQuestion.answers[0].id,
            }}">
              Practice
          </router-link>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import {mapMutations} from 'vuex';
import axios from 'axios';

export default {
  name: 'OralFreeResponseTopic',
  components: {
  },
  props: {
    topicId: Number,
  },
  data() {
    return {
      questionIndex: 0,
      numQuestions: 0,
      topic: null,
    };
  },
  computed: {
    currentQuestion() {
      if (!this.topic || this.questionIndex >= this.topic.questions.length) return null;
      return this.topic.questions[this.questionIndex];
    },
  },
  mounted() {
    // checks to see if we need to pull free response topics
    this.setShowGlobalLoadingScreen(true);

    axios
        .get(`/vocab/oralfreeresponse/${this.topicId}/topic/`)
        .then((response) => {
          this.topic = response.data;
          this.numQuestions = this.topic['questions'].length;
          console.log(this.topic);
          this.setShowGlobalLoadingScreen(false);
        });
    // }
  },
  methods: {
    ...mapMutations(['setShowGlobalLoadingScreen']),
    nextQns() {
      this.questionIndex++;
      if (this.questionIndex >= this.numQuestions) {
        this.questionIndex = 0;
        return;
      };
    },
  },
};

</script>

<style lang="scss" scoped>
.free-response-component {
  background: #fff;
  height: 100vh;
  width: 100vw;
  top: 0;
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  &__container {
    height: 80%;
    max-width: 820px;
  }
  &__header {
    height: 10%;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    h2 {
      font-size: 1.25rem;
      font-weight: 600;
      flex: 1;
    }
    p {
      font-size: .9rem;
      font-weight: 600;
      padding: 0.25rem 0.5rem;
      border-radius: 20px;
      background: #ffcf6d;
    }
  }
  &__content {
    height: 90%;
    width: 100%;
    &--qns {
      margin: 0 1rem;
      &-container {
        height: 20%;
        text-align: center;
        border-top: 2px solid #ccc;
        border-bottom: 2px solid #ccc;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.25rem;
        font-weight: 600;
      }
    }
    &--ans{
      height: 70%;
      padding: 0rem 3rem;
      border-radius: 1rem;
      margin: 1rem auto;
      overflow: auto;
      p {
        font-size: 1.2rem;
        text-indent: 40px;
        margin-bottom: 1rem;
        line-height: 2rem;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
  &__nav {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    &-btn {
      padding: 0.5rem;
      border-radius: 1rem;
      cursor: pointer;
      justify-content: center;
      align-items: center;
      display: flex;
    }
  }
  &__btn-practice {
    &--container {
      height: 10%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    text-decoration: none;
    width: 250px;
    text-align: center;
    border-radius: 0.5rem;
    font-size: 1.1rem;
    font-weight: 600;
    background: #22b492;
    box-shadow: 0px 5px #2e8771;
    color: #fff;
    padding: .5rem 1rem;
    cursor: pointer;
    transition: all 0.3s;
    &:hover {
      transform: scale(1.03);
      background: #3de7c0;
      box-shadow: 0px 5px #3bc6a4;
      &::after {
        transform: scaleX(1.1) scaleY(1.3);
        opacity: 0;
      }
    }
    &:active {
      transform: translateY(-1px);
    }
  }
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity .2s
}

.fade-enter,
.fade-leave-to {
    opacity: 0
}

.nav-disabled {
  cursor: not-allowed;
  opacity: 0.1;
}


@media only screen and (max-width: 820px) {
  .free-response-component__content--ans {
    padding: 0 1rem;
  }
  .free-response-component__header {
    h2 {
      margin-left: 2.5rem;
    }
    p {
      margin-right: 0.5rem;
    }
  }
  .free-response-component__container {
    height: 100%;
  }
  .free-response-component__btn-practice {
    width: 100%;
    border-radius: 0;
    &--container {
      position: fixed;
      bottom: 0;
      width: 100%;
      justify-content: flex-start;
      align-items: flex-end;
    }
  }
  .close {
    top: 25px;
    left: 10px;
  }
}
@media only screen and (max-width: 400px) {
  .free-response-component__header {
    h2 {
      font-size: 1rem;
    }
    p {
      font-size: 0.8rem;
    }
  }
}
</style>
