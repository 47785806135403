<!--
  controls all the widgets to be shown on right side of screen
  as every screen will have different widgets, we can't use a footer component
  instead, user will pass in a list of widget names, and component will display accordingly
  data loading (from vuex, or ajax call if necessary) will be handled by individual widgets
-->
<template>
  <div class="content-right">
    <div class="content-right--notifications">
      <component
        v-for="(name, index) in componentNames"
        :is="name"
        :key="index"
        class="widget animated fadeInDown"
      ></component>
    </div>
  </div>
</template>

<script>
import LeaderboardComponent
  from './LeaderboardComponent.vue';

import DailyStreakComponent
  from './DailyStreakComponent.vue';

import MissionsComponent
  from './MissionsComponent.vue';

import SpeechAnalyticsComponent
  from './SpeechAnalyticsComponent.vue';

export default {
  name: 'WidgetContainerComponent',
  components: {
    Leaderboard: LeaderboardComponent,
    DailyStreak: DailyStreakComponent,
    Missions: MissionsComponent,
    SpeechAnalytics: SpeechAnalyticsComponent,
  },
  props: {
    componentNames: Array,
  },
  data() {
    return {
    };
  },
  computed: {
  },
  mounted() {
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
  .content-right  {
    height: 100%;
    &--notifications {
      top: 3rem;
      bottom: 0;
      overflow-y: scroll;
      overflow-x: hidden;
      position: fixed;
      &::-webkit-scrollbar {
        display: none;
      }
    }
  }
  @media only screen and (max-width: 1366px),
              screen and (max-height: 870px) {
    .content-right  {
      &--notifications {
        top: 2.5rem;
      }
    }
  }
</style>
