<template>
  <div class="raid-startup__component row animated fadeIn">
    <h1 class="animated fadeInDown">Defeat the Boss!</h1>
    <!-- <event-countdown></event-countdown> -->
    <raid-boss-stats
      :health-percent="raidData.healthPercent"></raid-boss-stats>
    <div class="raid-startup__btns">
     <!--  <button disabled
        class="raid-startup__btn animated fadeInLeft delay-1">
        Raid Leaderboard
      </button> -->
      <button class="raid-startup__btn animated fadeInUp delay-2"
        @click="onChallengeClicked(raidType)">
        <!-- <span class="raid-startup__btn--overlay">
          Attempts Left: {{ raidData.attemptsLeft }}/3
        </span> -->
        <span v-if="this.raidType == 'idioms'">
          Idiom Battle!
        </span>
        <span v-else>
          Battle!
        </span>
      </button>
    </div>
   <!--  <user-overall-raid-stats
      :overall-damage-done="raidData.overallDamageDone">
    </user-overall-raid-stats> -->
    <div class="raid-startup__info--container">
      <span
        class="raid-startup__info--btn
        animated fadeIn delay-5 slower"
        @click="toggleRaidInfo = !toggleRaidInfo">!</span>
      <transition
        name="custom-classes-transition"
        leave-active-class="animated fadeOut">
        <popup-notification
          v-if="toggleRaidInfo"
          v-on:closePopup="toggleRaidInfo = !toggleRaidInfo">
          <raid-info></raid-info>
        </popup-notification>
      </transition>
    </div>
    <router-link
      v-if="raidType == 'level'"
      :to="{name:'profile-main'}"
      class="raid-startup__close--btn animated fadeIn delay-5 slower">
      <i class="fas fa-chevron-left"></i>
    </router-link>
    <router-link
      v-if="raidType == 'idioms'"
      :to="{name: 'learning-track-idiom-index'}"
      class="raid-startup__close--btn animated fadeIn delay-5 slower">
      <i class="fas fa-chevron-left"></i>
    </router-link>
  </div>
</template>
<script>


// import EventCountdown
//   from './EventCountdownComponent.vue';
import PopupNotification
  from '../../common/PopupNotification.vue';
import RaidInfo
  from './InfoComponent.vue';
import RaidBossStats
  from './BossStats.vue';
// import UserOverallRaidStats
//   from './UserOverallRaidStats.vue';

export default {
  name: 'RaidStartUp',
  components: {
    // EventCountdown: EventCountdown,
    PopupNotification: PopupNotification,
    RaidInfo: RaidInfo,
    RaidBossStats: RaidBossStats,
    // UserOverallRaidStats: UserOverallRaidStats,
  },
  props: {
  },
  data() {
    return {
      raidType: '',
      toggleRaidInfo: false,
      raidData: {
        attemptsLeft: 3,
        healthPercent: 1,
        overallDamageDone: 1,
      },
    };
  },
  created() {
    this.raidType = this.$route.params.raidType;
  },
  computed: {
  },
  mounted() {
  },
  methods: {
    onChallengeClicked(subCategory) {
      this.$emit('getPets', subCategory);
    },

    // need to remove
    // placeholder
    _challenge() {
      if (this.raidData.attemptsLeft <= 0) {
        const message = 'You have used up all your challenges for today.' +
          'Please try again tomorrow!';
        alert(message);
        return;
      };
      this.raidData.attemptsLeft -= 1;
      this.raidData.healthPercent -= 0.05;
      this.raidData.overallDamageDone += 5;
    },
  },
};
</script>

<style lang="scss" scoped>
  .delay-1 {
    animation-delay: 0.2s;
  }
  .delay-2 {
    animation-delay: 0.3s
  }
  .delay-3 {
    animation-delay: 0.4s;
  }
  .delay-4 {
    animation-delay: 0.5s;
  }
  .delay-5 {
    animation-delay: 0.6s
  }

  .raid-startup {
    &__component {
      color: #fff;
      font-family: 'baloo da 2';
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      height: 100%;
      width: 100%;
      text-align: center;
      position: relative;
      h1 {
        font-size: 2.75rem;
      }
    }
    &__info {
      &--btn {
        position: absolute;
        top: 2rem;
        right: 2rem;
        display: inline-block;
        height: 1.75rem;
        width: 1.75rem;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #fbc933;
        color: #000;
        border: 3px solid #000;
        font-weight: 900;
        cursor: pointer;
        border-radius: 5px;
        font-size: 1.25rem;
        transition: all 0.2s;
        &:hover {
          transform: scale(1.1);
        }
      }
    }
    &__close {
      &--btn {
        position: absolute;
        top: 2rem;
        left: 2rem;
        cursor: pointer;
        transition: all 0.2s;
        i {
          font-size: 1.75rem;
          opacity: 0.2;
          color: #fff;
          transition: all 0.2s;
        }
        &:hover {
          transform: scale(1.1);
          i {
            opacity: 1;
          }
        }
      }
    }
    &__btns {
      // display: inline-flex;
      // justify-content: space-between;
      margin-bottom: 1rem;
    }
    &__btn {
      background: #fbaa0f;
      font-size: 1.5rem;
      font-weight: 900;
      font-family: 'baloo da 2';
      padding: 0 .75rem;
      border-radius: 5px;
      cursor: pointer;
      border: 5px solid #000;
      min-width: 10rem;
      margin: 0.5rem;
      position: relative;
      margin-top: 1rem;
      transition: all 0.2s;
      &:focus {
        outline: none;
      }
      &:hover {
        transform: scaleX(1.1);
      }
      &--overlay {
        position: absolute;
        top: -1.1rem;
        right: 0;
        font-size: 0.7rem;
        font-weight: 600;
      }
    }
  }
  @media only screen and (max-width: 600px) {
    .raid-startup__component h1 {
      font-size: 2rem;
    }
    .raid-startup__close--btn {
      top: 1rem;
      left: 1rem;
    }
    .raid-startup__info--btn {
      top: 1rem;
      right: 1rem;
    }
  }
  @media only screen and (max-width: 330px) {
    .raid-startup__component h1 {
      font-size: 1.5rem;
      margin-top: 2rem;
    }
    .raid-startup__btn {
      font-size: 1.25rem;
      min-width: 6rem;
    }
  }
  @media only screen and (max-height: 580px) {
    .raid-startup__component h1 {
      font-size: 2rem;
    }
    .raid-startup__btn {
      font-size: 1.25rem;
      min-width: 6rem;
      margin: 0;
      margin-top: 1rem;
    }
    .raid-startup__close--btn {
      top: 1rem;
      left: 1rem;
    }
    .raid-startup__info--btn {
      top: 1rem;
      right: 1rem;
    }
  }
</style>
