<template>
  <div class="event-mission-item">
    <div class="event-mission-item--col-1">
      <i class="fa-solid fa-ticket"></i>
      <p class="event-mission-item--col-1--num">{{ info.reward }}</p>
    </div>
    <div class="event-mission-item--col-2">
      <div class="event-mission-item--col-2--text">
        <h3>{{ info.description }}</h3>
      </div>
      <div class="event-mission-item--col-2--progress">
        <div class="event-mission-item--col-2--progress-bar">
          <div
            :style="{width: progressBarWidth}"
            class="event-mission-item--col-2--progress-bar--fill"></div>
        </div>
        <div class="event-mission-item--col-2--progress-text">
          {{ info.current_times_done }} / {{ info.target_times_done }}
        </div>
      </div>
    </div>
    <div @click="onClickNext" class="event-mission-item--col-3">
      <!-- not completed -->
      <i v-if="info.status <= 1" class="go-icon">Go</i>
      <!-- completed but not yet collected gift -->
      <i v-if="info.status === 2" class="fa-solid fa-gift large-heartbeat-animation"></i>
      <!-- collected already -->
      <i v-if="info.status === 3" class="fa fa-check"></i>
    </div>
  </div>
</template>
<script>

// import axios from 'axios';
import {mapState, mapMutations} from 'vuex';

// breakdown of status
// NOT_STARTED: int = 0
// ONGOING: int = 1
// COMPLETED_UNCLAIMED: int = 2
// COMPLETED_CLAIMED: int = 3

export default {
  name: 'Task',
  components: {
  },
  props: {
    info: Object,
  },
  data() {
    return {
      fetching: false,
    };
  },
  computed: {
    ...mapState(['studentId']),
    progressBarWidth() {
      if (!this.info) return 0;
      const percentage = Math.ceil(this.info.current_times_done / this.info.target_times_done * 100);
      return `${percentage}%`;
    },
  },
  mounted() {
  },
  methods: {
    ...mapMutations(['setIsChallengesPopupShown']),
    claimTask() {
      this.$emit('claim', this.info.key);
    },
    onClickNext() {
      // claimed already, do nothing
      if (this.info.status === 3) return;
      if (this.info.status === 2) {
        this.claimTask();
        return;
      }
      // if haven't completed, navigate
      console.log(this.info.name);
      // kiv - eventually go directly to the level (e.g. if p4 words, go to p4 level)
      if (this.info.name === 'words') {
        this.$router.push({name: 'new-learn-vocab-index'});
      } else if (this.info.name === 'sentences') {
        this.$router.push({name: 'new-oral-category-index', params: {category: 'oralTextbookChapter'}});
      }
      this.setIsChallengesPopupShown(false);
      return;
    },
  },
};
</script>

<style lang="scss">
  .event-mission-item {
    margin-bottom: .5rem;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100px;
    background: #3f3750;
    color: #fff;
    border-radius: .5rem;
    text-shadow: 0px 2px #18171445;
    border: 3px solid #b3b3b3;
    &:last-child {
      margin-bottom: 0rem;
    }
    &--col {
      &-1, &-2, &-3 {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      &-1, &-3 {
        width: 100px;
        height: 100%;
      }
      &-1 {
        background: linear-gradient(to bottom, #291B1A, #59507E);
        color: #fff;
        font-size: 2rem;
        border-top-left-radius: .3rem;
        border-bottom-left-radius: .3rem;
        position: relative;
        .fa-ticket {
          color: #fff355;
          transform: rotate(-45deg);
          text-shadow: 1px 1px 2px #ff7b00, 0 0 15px rgba(255, 152, 0, .6117647059), 0 0 7px orange;
        }
        &--num {
          position: absolute;
          bottom: 0px;
          right: 4px;
          font-size: .9rem;
        }
      }
      &-2 {
        flex-direction: column;
        flex: 1;
        margin-left: 1rem;
        &--text {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-weight: 600;
        }
        &--progress {
          display: flex;
          width: 100%;
          justify-content: center;
          align-items: center;
          &-text {
            width: 75px;
            font-size: .8rem;
            display: flex;
            justify-content: flex-end;
            align-items: center;
          }
          &-bar {
            height: 1rem;
            border-radius: 2rem;
            background: #1a1a1a5c;
            flex: 1;
            &--fill {
              // width for mission completion
              width: 50%;
              height: 100%;
              background: #ffb350;
              border-radius: 2rem;
            }
          }
        }
      }
      &-3 {
        font-size: 2rem;
        color: #fff;
        .fa-gift {
          color: #ffb350;
          cursor: pointer;
        }
        .go-icon {
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: 600;
          font-size: 1.5rem;
          padding: 0.15rem 0.5rem;
          border-radius: .5rem;
          color: #fff;
          background: #50c09f;
          cursor: pointer;
          &:hover {
            box-shadow: 0 0px 1px 3px #ff00dd;
          }
        }
        .fa-check {
          color: #d1d1d1;
        }
      }
    }
    &s {
      height: 100%;
      width: 100%;
      padding: .5rem;
      overflow-y: auto;
      overflow-x: hidden;
      background: linear-gradient(to bottom, #fff, #6d7bc5);
    }
  }

  @media only screen and (max-width: 1366px),
  screen and (max-height: 870px)  {
    .event-mission-item {
      height: 90px;
      &--col-1 {
        font-size: 1.9rem;
        &--num {
          font-size: .8rem;
        }
      }
      &--col-1, &--col-3 {
        width: 90px;
      }
      &--col-2 {
        &--text {
          font-size: .95rem;
          line-height: 1.3rem;
        }
        &--progress {
          &-bar {
            height: .9rem;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 1150px),
  screen and (max-height: 720px) {
    .event-mission-item {
      height: 80px;
      &--col-1 {
        font-size: 1.8rem;
        &--num {
          font-size: .7rem;
        }
      }
      &--col-1, &--col-3 {
        width: 80px;
      }
      &--col-2 {
        &--text {
          font-size: .9rem;
          line-height: .8rem;
          margin-top: .5rem;
        }
        &--progress {
          &-bar {
            height: .8rem;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 960px), screen and (max-height: 620px) {
    .event-mission-item {
      height: 65px;
      &--col-1 {
        font-size: 1.5rem;
        &--num {
          right: 2px;
          font-size: 0.6rem;
        }
      }
      &--col-1, &--col-3 {
        width: 65px;
      }
      &--col-2 {
        &--text {
          font-size: .8rem;
          line-height: .7rem;
          margin-top: .5rem;
        }
        &--progress {
          &-bar {
            height: .65rem;
          }
        }
      }
      &--col-3 {
        font-size: 1.5rem;
      }
    }
  }
  @media only screen and (max-width: 635px) {
    .event-mission-item {
      &--col-1 {
        font-size: 1.4rem;
      }
      &--col-1, &--col-3 {
      }
      &--col-2 {
        &--text {
          font-size: .75rem;
          line-height: .7rem;
          margin-top: .5rem;
        }
      }
    }
  }

  @media only screen and (max-width: 520px) {
    .event-mission-item {
      &--col-1, &--col-3 {
        width: 55px;
      }
      &--col-2 {
        &--text {
          font-size: .65rem;
          margin-top: .25rem;
        }
        &--progress {
          &-text {
            font-size: .75rem;
            width: 65px;
          }
          &-bar {
            height: .65rem;
          }
        }
      }
      &--col-3 {
        font-size: 1.5rem;
      }
    }
  }
</style>
