<template>
  <div class="list-of-words__component--container">
    <div class="list-of-words__component">
      <h4 class="list-of-words__component--header">{{ title }}</h4>
      <ul class="list-of-words__component--words">
        <li class="list-of-words__component--word"
          v-for="(word, index) in wordListToShow" :key="index">
          <span class="chinese-character">{{ word.text }}</span>
        </li>
        <li>
          <!-- for not tested words, we don't show all words -->
          <span v-if="overallRating === 0 && wordList.length > 20" class="chinese-character">还剩{{ wordList.length - 20 }}字</span>
        </li>
      </ul>
    </div>
    <div class="list-of-words__component--mobile"
      @click="openMobileWordList">
      <button
        class="list-of-words__component--mobile--btn">
        {{ title }}
      </button>
    </div>
  </div>
</template>

<script>
import {mapState} from 'vuex';

export default {
  name: 'ListOfWords',
  props: {
    overallRating: Number,
    wordList: Array,
  },
  data() {
    return {
      isWordListShown: false,
    };
  },
  computed: {
    ...mapState(['studentName']),
    title() {
      const overallRatingToTitleMap = {
        0: 'Not Tested Yet',
        1: 'Incorrect',
        2: 'Learning',
        3: 'Proficient',
        4: 'Mastered',
      };

      return this.wordList.length === 0 ? overallRatingToTitleMap[this.overallRating] :
        `${overallRatingToTitleMap[this.overallRating]} (${this.wordList.length})`;
    },
    wordListToShow() {
      if (this.overallRating === 0) return this.wordList.slice(0, 20);
      return this.wordList;
    },
  },
  mounted() {
  },
  methods: {
    openMobileWordList() {
      this.$emit('openMobileWordList', {
        title: this.title,
        index: this.overallRating,
      });
    },
  },
};
</script>

<style lang="scss" scoped>

  .list-of-words__component {
    &--mobile {
      display: none;
      border-radius: .5rem;
      background: #1f5ed0;
      color: #fff;
      border-top: 4px solid #2665db;
      border-bottom: 4px solid #2455b0;
      position: relative;
      cursor: pointer;
      &--btn {
        background: none;
        color: inherit;
        border: none;
        padding: 0;
        font: inherit;
        cursor: pointer;
        outline: inherit;
      }
      &--popup {
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background: #fff;
        overflow: scroll;
        color: #000;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        padding: 1rem;
        &-word {
          text-align: center;
          width: 50%;
          border: 1px solid #e8e8e8;
        }
      }
    }
    &--container {
      width: 100%;
      height: 100%;
    }
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: .25rem;
    background: #fff;
    border-radius: .5rem;
    &--header {
      border-top-right-radius: .5rem;
      border-top-left-radius: .5rem;
      background: #3c79d3;
      color: #fff;
      width: 100%;
      text-align: center;
      padding: .5rem;
      font-size: .85rem;
    }
    &--words {
      overflow-y: auto;
      width: 100%;
      background: #fff;
      border-radius: .5rem;
      padding: 1rem;
      display: flex;
      flex-wrap: wrap;
      grid-gap: .25rem;
    }
    &--word {
      padding: .25rem;
      border: 1px solid #e8e8e8;
      text-align: center;
      width: calc(50% - .125rem);
      border-radius: .5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 45px;
    }
  }

  @media only screen and (max-width: 1558px) {
    .list-of-words__component--word {
      width: 100%;
    }
  }

  @media only screen and (max-width: 1250px) {
    .list-of-words__component--word {
      width: calc(50% - .125rem);
    }
  }

  @media only screen and (max-width: 1235px) {
    .list-of-words__component--word {
      width: 100%;
    }
  }

  @media only screen and (max-width: 960px) {
    .list-of-words__component {
      display: none;
      &--container {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      &--mobile {
        display: flex;;
        flex: 1;
        justify-content: center;
        align-items: center;
        height: 100%  ;
      }
    }
  }

</style>
