<template>
  <div id="library-container">
    <div id="stories">
      <library-storyarc-thumbnail
        v-for="(story, index) in storiesInCurrentStoryarc"
        :key="story.id"
        :story="story"
        :index="index"
        :unlocked="story.unlocked"
        :read="story.read">
      </library-storyarc-thumbnail>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import {mapState, mapMutations} from 'vuex';

import LibraryStoryarcThumbnailComponent from
  './LibraryStoryarcThumbnailComponent.vue';

import {utilities} from '../../mixins/utilities';

export default {
  name: 'LibraryStoryarcMain',
  components: {
    LibraryStoryarcThumbnail: LibraryStoryarcThumbnailComponent,
  },
  mixins: [utilities],
  props: {
    storyarcId: Number,
  },
  data() {
    return {
      stories: [],
      selected: null,
      info: {},
      doneInitialFetching: false,
      currTime: null,
    };
  },
  computed: {
    ...mapState('library', ['storiesInCurrentStoryarc']),
  },
  watch: {
    // pull information on selected storyarc if we don't already have the data
    $route: {
      immediate: true,
      handler(to, from) {
        // should only activate if we are routing to library storyarc main
        if (to && to.name === 'library-storyarc-main') {
          // forces page to scroll to top when navigating to it
          // as keep-alive will remember position
          this.scrollToTop('#library-container');
          // if stories are already pulled, then do not refetch
          if (this.storiesInCurrentStoryarc.length > 0 &&
            this.storiesInCurrentStoryarc[0].storyarc === this.storyarcId) {
            return;
          }
          this.setShowGlobalLoadingScreen(true);
          // fetch data
          // https://www.reddit.com/r/vuejs/comments/7w6okz/auth_token_stored_in_localstorage_not_working_on/
          axios
              .get('/library/api/storyarc/' + this.storyarcId + '/')
              .then((response) => {
                this.setStoriesInCurrentStoryarc(response.data);
                this.setShowGlobalLoadingScreen(false);
              })
              .catch((error) => {
                console.log(axios.defaults.headers.common);
                console.log('having issue pullng');
                this.setShowGlobalLoadingScreen(false);
              });
        };
      },
    },
  },
  mounted: function() {
    console.log('mounting library storyarc main component');
  },
  methods: {
    ...mapMutations(['setShowGlobalLoadingScreen']),
    ...mapMutations('library', ['setStoriesInCurrentStoryarc']),
  },
};
</script>

<style lang="scss" scoped>
#library-container {
  margin-bottom: 140px;
}
#stories {
  margin: 0 16px;
}

@media only screen and (max-width: 1023px) {
  #stories {
    margin: 0 8px 64px 8px;
  }
}
</style>
