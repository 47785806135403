<template>
  <div class="damage-battle-text__component">
    <p v-if="values.attackResult === 'crit'"
      class="crit-label">
      Critical Hit!
    </p>
    <p v-if="values.attackResult !== 'miss'"
      class="damage-battle-text--number"
      :class="damageClass">-{{ values.damage }}</p>
    <p v-else
      class="miss">MISS!</p>
  </div>
</template>

<script>
import {mapState} from 'vuex';

export default {
  name: 'BattleDamageText',
  components: {
  },
  props: {
    isStudent: Boolean,
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapState('raid', ['damageTextValues']),
    values() {
      return this.isStudent ? this.damageTextValues['student'] :
          this.damageTextValues['enemy'];
    },
    damageClass() {
      if (!this.values) return;

      return {
        'crit': this.values.attackResult === 'crit',
      };
    },

  },
  mounted: function() {
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
  .damage-battle-text__component {
    text-align: center;
    font-family: 'baloo da 2';
    font-size: 3rem;
    font-weight: 900;
    color: #fff;
    -webkit-text-stroke: 4px black;
  }
  .crit {
    font-size: 4rem;
    font-weight: bold;
    color: yellow;
    &-label {
      font-size: 2rem;
      color: yellow;
      margin-bottom: -2rem;
      -webkit-text-stroke: 3px black;
    }
  }

  .miss {
    font-size: 4rem;
    color: red;
  }
  @media only screen and (max-height: 860px) {
    .damage-battle-text__component {
      margin-bottom: -1rem;
    }
    .crit-label {
      font-size: 1.75rem;
    }
    .damage-battle-text--number {
      font-size: 2.5rem;
      margin-top: 0.5rem;
    }
    .miss {
      font-size: 3.5rem;
    }
  }
  @media only screen and (max-height: 768px) {
    .crit-label {
      font-size: 1.5rem;
    }
    .damage-battle-text--number {
      font-size: 2rem;
      margin-top: 1rem;
    }
  }
  @media only screen and (max-height: 560px) {
    .crit-label {
      font-size: 1.35rem;
      -webkit-text-stroke: 2.5px black;
    }
    .damage-battle-text--number {
      font-size: 1.75rem;
      -webkit-text-stroke: 2.5px black;
    }
    .miss {
      font-size: 3rem;
    }
  }
  @media only screen and (max-height: 500px) {
    .crit-label {
      font-size: 1.1rem;
      -webkit-text-stroke: 2px black;
    }
    .damage-battle-text--number {
      font-size: 1.5rem;
      -webkit-text-stroke: 2px black;
    }
    .miss {
      font-size: 2.5rem;
    }
  }
  @media only screen and (max-height: 320px) {
    .miss {
      font-size: 2rem;
    }
  }

</style>
