<template>
  <div class="main-leaderboard__component animated fadeIn faster">
    <div class="main-leaderboard__content">
      <div class="main-leaderboard__header">
        <h1 class="animated bounceIn">
          Session Leaderboard
        </h1>
      </div>
      <div class="main-leaderboard__body">
        <!-- leaderboard component -->
        <leaderboard-main
          :leaderboard-data="leaderboard"
          :student-map="studentMap"
          :title="'Session Leaderboard'"
          :type-of-leaderboard="'Scores'">
        </leaderboard-main>
      </div>
      <div class="main-leaderboard__btn--close animated fadeInUp">
        <router-link
          :to="{name:'profile-main'}">
          Close
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import {mapState} from 'vuex';

import LeaderboardMainComponent
  from '../common/leaderboard/LeaderboardMainComponent.vue';

export default {
  name: 'MainLeaderboardGroupSession',
  components: {
    LeaderboardMain: LeaderboardMainComponent,
  },
  props: {
    sessionId: Number,
  },
  data() {
    return {
      leaderboard: [],
      studentMap: {},
    };
  },
  computed: {
    ...mapState(['studentId']),
  },
  mounted: function() {
    // showUnseenLeaderboardNotification
    const url = '/api/v1/core/student/' + this.studentId +
      '/room_session_leaderboards/' + this.sessionId + '/';
    console.log(url);
    return axios
        .get(url)
        .then((response) => {
          console.log(response.data);
          this.studentMap = response.data['student_map'];
          // array of tuples (array in javascript)
          // tuple has 3 elements - student_rank,student_id, score)
          this.leaderboard = response.data['leaderboard'];
        });
  },
  methods: {
    isActive(value) {
      return this.leaderboardActive === value;
    },
  },
};
</script>

<style lang="scss" scoped>
  .main-leaderboard {
    &__content {
      // padding: 60px;
      // border-radius: 5px;
      // border: 5px solid #dedede;
    }
    &__header {
      margin-bottom: 10px;
      display: flex;
      justify-content: space-between;
      h1 {
        text-transform: uppercase;
        color: #fff;
        font-family: 'baloo da 2';
      }
    }
    &__component {
      // background-image: linear-gradient(rgba(255,255,255,0.8), rgba(255,255,255,0.8)),url(http://127.0.0.1:3000/splash-image.jpg?45a0e2202dcf0d732027d0a458f77b94);
      background: radial-gradient(#40c0f1, #302792);
      z-index: 30;
      width: 100%;
      min-height: 100vh;
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      // height: calc(100% - 64px);
    }
    &__btns {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &__btn {
      padding: 0.25rem 1rem;
      background: #3e3e3e;
      border: 4px solid #222;
      color: #fff;
      font-family: 'Baloo Da 2';
      font-size: 1rem;
      opacity: 50%;
      cursor: pointer;
      font-weight: 700;
      &:focus {
        outline: none;
      }
      &--close {
        text-align: center;
        margin-top: 30px;
        a {
          border-radius: 5px;
          padding: 0.25rem 2rem;
          background: #3e3e3e;
          border: 4px solid #222;
          color: #fff;
          font-weight: 700;
          font-family: 'Baloo Da 2';
          text-decoration: none;
          display: inline-block;
        }
      }
    }
  }

  .active {
    background: #ff733d;
    border: 4px solid #000;
    opacity: 100%;
  }

  @media only screen and (max-width: 740px) {
    .main-leaderboard {
      &__header {
        flex-direction: column;
        align-items: center;
      }
    }
  }
  @media only screen and (max-width: 380px) {
    .main-leaderboard {
      &__header {
        font-size: 0.9rem;
      }
      &__btn {
        font-size: 0.85rem;
        padding: 0 0.75rem;
        &--close {
          margin-top: 15px;
        }
      }
    }
  }
  @media only screen and (max-width: 330px) {
    .main-leaderboard {
      &__header {
        font-size: 0.75rem;
      }
      &__btn {
        font-size: 0.85rem;
        padding: 0 0.75rem;
        &--close {
          margin-top: 10px;
          a {
            padding: 0 1rem;
          }
        }
      }
    }
  }
</style>
