<template>
  <!-- MAIN PAGE -->
  <div id="pandabox-reward-menu">
    <div v-if="!boolShowRewardsPage" class="pandabox-reward__container">
      <h2 class="pandabox-reward__title">
        congratulations!
      </h2>
      <p v-if="!hasClaimedRewards"
        class="pandabox-reward__text">
        You've earned a Treasure Chest!
      </p>
      <p v-else>
        You've claimed a Treasure Chest!
      </p>
      <div class="pandabox-reward__img">
        <i class="fas fa-gift"></i>
      </div>
      <button
        v-if="!hasClaimedRewards"
        @click="showRewardsPage"
        class="pandabox-reward__btn">
        Claim My Rewards!
      </button>
      <button
        v-else
        class="pandabox-reward__btn"
        @click.prevent="endQuiz()">
        Do Another Quiz
      </button>
    </div>
    <div @click="rewardSequence"
      v-if='boolShowRewardsPage' class="pandabox-reward__loots">
      <!-- COINS -->
      <div v-if='currentRewardCategory === "coins"'
        class="pandabox-reward__loots--container pandabox-reward__coins">
        <div class="pandabox-reward__loots--subheader">
          <p>BONUS!</p>
        </div>
        <div class="pandabox-reward__loots--header">
          <h2>COINS</h2>
        </div>
        <div class="pandabox-reward__loots--img">
          <div class="coin-circle">
            <i class="fas fa-star"></i>
          </div>
        </div>
        <div class="pandabox-reward__loots--count">
          <h2>x{{coinsToAdd}}</h2>
        </div>
        <img class="sunburst rotating-anim" src="../../../assets/sunburst.png">
      </div>
      <mission-completion
        @close="endQuiz"
        v-show="hasMissionsForCurrentQuizType && showMissions">
      </mission-completion>
    </div>
  </div>
</template>

<script>
import {mapState, mapMutations} from 'vuex';

// made a lot of changes  to this page, should eventually rebuild this

import MissionCompletionComponent from '../../new/missions/components/EmbeddedMissionCompletionComponent.vue';

export default {
  name: 'QuizCompletionScreen',
  components: {
    MissionCompletion: MissionCompletionComponent,
  },
  props: {
    endQuizCallback: Function,
    newCoins: Number,
    coinsToAdd: Number,
    loot: Array,
    quizType: String,
  },
  data() {
    return {
      boolShowRewardsPage: false,
      rewardSequenceCategories: ['coins', 'missions'],
      // controls which sequence to show after claiming
      // starts at -1 as clicking on claim reward will add 1, so becomes 0
      sequenceCounter: -1,
      hasClaimedRewards: false,
      showMissions: false,
    };
  },
  computed: {
    ...mapState(['studentName', 'avatar']),
    // kiv: only for daily challenge
    hasMissionsForCurrentQuizType() {
      console.log(this.quizType);
      return this.quizType === 'dailyChallenge';
    },
    currentRewardCategory() {
      return this.rewardSequenceCategories[this.sequenceCounter];
    },
  },
  mounted() {
  },
  methods: {
    ...mapMutations('quiz', ['setAnsweredAllQuestions', 'setGameRunning']),
    ...mapMutations(['setCoins']),
    endQuiz() {
      this.setAnsweredAllQuestions(false);
      this.setGameRunning(false);
      this.endQuizCallback();
    },
    showRewardsPage() {
      this.boolShowRewardsPage = true;
      this.hasClaimedRewards = true;
      this.rewardSequence();
    },
    rewardSequence() {
      if (this.sequenceCounter >= this.rewardSequenceCategories.length - 1) {
        // this.boolShowRewardsPage = false;
        // opening the page will automatically add 1 to sequence counter
        // hence we start with -1 rather than 0
        this.sequenceCounter = -1;
        // this.endQuiz();
      } else {
        this.sequenceCounter++;
        // further validation for each category
        // if we need to skip, we call reward sequence again
        if (this.currentRewardCategory === 'coins') {
          if (this.coinsToAdd === 0) {
            console.log('skipping as no coins');
            this.rewardSequence();
          } else {
            // set coins to new coins
            this.setCoins(this.newCoins);
          }
        } else if (this.currentRewardCategory === 'missions') {
          // if there's an active mission for this quiz type
          if (this.hasMissionsForCurrentQuizType) {
            this.showMissions = true;
          } else {
            this.endQuiz();
          }
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
// STYLES FOR PANDABOX REWARD MENU PAGE
  #pandabox-reward-menu {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    position: fixed;
    background: #e9e9e9;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
  .pandabox-reward {
    &__container {
      padding: 30px;
      background: #fff;
      border-radius: 10px;
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
      align-items: center;
      margin: 10px;
      text-align: center;
    }
    &__title {
      text-transform: uppercase;
      margin: 2px auto 2px auto;
    }
    &__text {
      width: 100%;
      text-align: center;
      padding-bottom: 10px;
      border-bottom: 2px solid #eee;
      margin-bottom: 12px;
    }
    &__img {
      font-size: 8rem;
      color: #6e81de;
    }
    &__btn {
      margin-top: 10px;
      padding: 10px 0;
      font-size: 110%;
      border-radius: 4px;
      border: 1px solid transparent;
      cursor: pointer;
      background: #7b57a9;
      color: white;
      text-align: center;
      font-weight: 400;
      transition: transform 0.2s;
      text-decoration: none;
      width: 100%;
    }
  }

// STYLES FOR LOOTS
.pandabox-reward {
  &__loots {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    // z-index: 30;
    color: #fff;
    overflow: hidden;
    &--container {
      padding-bottom: 75px;
      position: absolute;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
    &--img {
      margin: 20px 0;
    }
    &--subheader {
      font-size: 0.8rem;
    }
  }
  &__coins {
    background: #59e0c1;
    .pandabox-reward__loots--subheader {
      visibility: hidden;
    }
  }
  &__diamonds {
    background: #1989fd;
  }
  &__pets {
    background: #000;
    &--card {
      border: 6px solid #b2e6ff;
      background: linear-gradient(#83baff, #e614ff);
      width: 245.25px;
      height: 273.75px;
      border-radius: 10px;
      color: #fff;
      margin-top: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      font-family: arial;
      font-weight: bold;
    }
  }
  &__summary {
    background: #000;
  }
}
// STYLES FOR COINS
.coin-circle {
  background: #ffbe43;
  border: 6px solid #000;
  border-radius: 50%;
  height: 120px;
  width: 120px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  i {
    font-size: 4rem;
    color: #fff;
  }
}

// STYLES FOR DIAMONDS
.diamond-circle {
  border: 6px solid #000;
  background: #fff;
  border-radius: 50%;
  height: 120px;
  width: 120px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  i {
    margin-top: 10px;
    font-size: 4rem;
    color: #4f7dff;
  }
}

// SUNBURST ANIMATION AND STYLINGS
.sunburst {
  z-index: 10;
  position: absolute;
}
.pandabox-reward__loots--subheader,
.pandabox-reward__loots--header,
.pandabox-reward__loots--img,
.pandabox-reward__loots--count {
  z-index: 20;
}

@-webkit-keyframes rotating-anim /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating-anim {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.rotating-anim {
  -webkit-animation: rotating-anim 20s linear infinite;
  -moz-animation: rotating-anim 20s linear infinite;
  -ms-animation: rotating-anim 20s linear infinite;
  -o-animation: rotating-anim 20s linear infinite;
  animation: rotating-anim 20s linear infinite;
}
</style>
