<template>
  <block-container
    :title="title"
    subtitle="Download Worksheet"
    fa-icon="graduation-cap"
    previous-page-name="new-learn-overview"
    >
    <div class="worksheet-download__component">
      <div v-for="(doc, index) in filteredDocuments"
        :key="index"
        class="worksheet-download__component--item">
        <div class="worksheet-download__component--item-title">
          <div class="worksheet-download__component--item-title-main">
            <p v-if="doc.chapterTitle !== ''">{{ doc.chapterTitle }}</p>
            <p v-else>复习</p>
          </div>
          <div class="worksheet-download__component--item-title-sub">
            <p>Term {{ getTerm(doc.week) }}</p>
            <span>|</span>
            <p>Week {{ getWeek(doc.week) }} </p>
            <span>|</span>
            <p>{{ doc.lessonDate }}</p>
          </div>
          <div v-if="doc.isCurrentWeek" class="worksheet-download__component--item-label">
            LATEST
          </div>
        </div>
        <div class="worksheet-download__component--item-btns">
          <a v-if="doc.inclassLink !== ''" :href="doc.inclassLink" target="_blank"
            class="worksheet-download__component--item-btn btn-classwork">
            课堂练习
          </a>
          <a v-if="doc.homeworkLink !== ''" :href="doc.homeworkLink" target="_blank"
            class="worksheet-download__component--item-btn btn-homework">
            功课
          </a>
        </div>
      </div>

    </div>
    <!-- <div class="worksheet-index__container">
      <div
        v-for="(doc, index) in documents"
        :key="index"
        class="worksheet-download__container--item">
        <a :href="doc.url"
          target="_blank">
          <div class="worksheet-download__container--item-icon">
            <span v-if="doc.type === 'homework'">功课</span>
            <span v-else>课堂练习</span>
          </div>
          <div class="worksheet-download__container--item-header">
            <p>Term {{ getTerm(doc.week) }}</p>
            <span>|</span>
            <p>Week {{ getWeek(doc.week) }}</p>
          </div>
          <p class="worksheet-download__container--item-subheader">
            {{ doc.chapter_title }}
          </p>
          <p class="worksheet-download__container--item-subheader">
            ({{ doc.lesson_date }})
          </p>
          <div v-if="doc.is_current_week"
            class="worksheet-download__container--item-overlay">
            <p>Current Week</p>
          </div>
        </a>
      </div>
    </div> -->
  </block-container>
</template>

<script>

import BlockContainerComponent
  from '../common/BlockContainerComponent.vue';

import {mapGetters} from 'vuex';
import axios from 'axios';

export default {
  name: 'NewLearnWorksheetDownload',
  components: {
    BlockContainer: BlockContainerComponent,
  },
  props: {
  },
  data() {
    return {
      documents: [],
      showAll: false, // to avoid clutter, by default, we only show the latest 9 documents
    };
  },
  computed: {
    ...mapGetters(['chineseLevel']),
    title() {
      return '下载练习题';
    },
    filteredDocuments() {
      if (this.documents.length === 0) return;
      const results = [];
      const resultsMap = {};

      for (const doc of this.documents) {
        if (!(doc.week in resultsMap)) {
          resultsMap[doc.week] = {
            week: doc.week,
            homeworkLink: '',
            inclassLink: '',
            isCurrentWeek: doc.is_current_week,
            chapterTitle: doc.chapter_title,
            lessonDate: doc.lesson_date,
          };
        };

        if (doc.type === 'inclass') {
          resultsMap[doc.week]['inclassLink'] = doc.url;
        } else {
          resultsMap[doc.week]['homeworkLink'] = doc.url;
        }
      }

      for (const key in resultsMap) {
        results.push(resultsMap[key]);
      }
      results.sort((a, b) => b['week'] - a['week']);
      return results;
      // if (this.showAll) return this.documents;
      // return this.documents.slice(this.documents.length - 9);
    },
  },
  mounted() {
    axios
        .get('/vocab/primaryschoollesson/download_documents/')
        .then((response) => {
          this.documents = response.data.sort((a, b) => b.week - a.week);
          console.log(this.documents);
        });
  },
  methods: {
    getTerm(week) {
      const term = Math.floor(week / 12) + 1;
      return term;
    },
    getWeek(week) {
      let modulusWeek = (week + 1) % 12;
      modulusWeek = modulusWeek !== 0 ? modulusWeek : 12;
      return modulusWeek;
    },
  },
};

</script>

<style lang="scss" scoped>
  .worksheet-download__component {
    height: 100%;
    background: linear-gradient(to bottom right, #1d244dc9, #1518293c);
    padding: 2rem;
    overflow-y: auto;
    overflow-x: hidden;
    position: absolute;
    text-align: center;
    width: 100%;
    display: flex;
    flex-direction: column;
    &--item {
      width: 100%;
      padding: .75rem;
      display: flex;
      background: #fff;
      border-radius: .5rem;
      margin-bottom: 1rem;
      position: relative;
      &-label {
        position: absolute;
        top: -1rem;
        left: .5rem;
        background: #1deac7;
        border-radius: .5rem;
        font-weight: 600;
        padding: 0 1rem;
        color: #fff;
        font-size: 0.8rem;
        box-shadow: 0px 2px #d3d3d3;
      }
      &-title {
        font-weight: 600;
        display: flex;
        flex-direction: column;
        flex: 1;
        justify-content: center;
        &-main {
          text-align: left;
        }
        &-sub {
          text-align: left;
          font-size: 0.7rem;
          font-style: italic;
          line-height: .5rem;
          display: flex;
          span {
            margin: 0 0.25rem;
          }
        }
      }
      &-btns {
        display: flex;
        min-width: 200px;
        gap: .5rem;
      }
      &-btn {
        text-shadow: 2px 2px rgba(0,0,0,.5);
        color: #fff;
        border-radius: .25rem;
        padding: .25rem .5rem;
        text-decoration: none;
        flex: 1;
      }
      .btn-homework {
        background: #a03aff;
      }
      .btn-classwork {
        background: #3a87ff;
        flex: 2;
      }
    }
  }
  @media only screen and (max-width: 1200px) {
    .worksheet-download__component--item {
      flex-direction: column;
      &-btn {
        flex: 1;
        font-size: .85rem;
        padding: .15rem;
        margin-top: .5rem;
      }
      .btn-classwork {
        flex: 1;
      }
    }
  }
  @media only screen and (max-width: 960px),
    screen and (max-height: 620px) {
  }
  @media only screen and (max-width: 430px) {
    .worksheet-download__component {
      padding: 1rem;
      padding-top: 2.5rem;
    }
  }
  @media only screen and (max-width: 400px) {
    .worksheet-download__component--item-title-main {
      font-size: .95rem;
    }
  }
</style>
