<template>
  <div class="battle-anim__component">
    <transition
      name="custom-classes-transition"
      enter-active-class="animated flipInX"
      leave-active-class="animated flipOutX">
      <div class="turn-overlay turn-overlay--player"
        v-if="animationValues.playStudentTurn">
        <p class="turn-text delay-1">Your Pet Attacks!</p>
      </div>
    </transition>
    <transition
      name="custom-classes-transition"
      enter-active-class="animated flipInX"
      leave-active-class="animated flipOutX">
      <div class="turn-overlay turn-overlay--enemy"
        v-if="animationValues.playEnemyTurn">
        <p class="turn-text delay-1">Enemy Attacks!</p>
      </div>
    </transition>
    <div class="spell"
      :class="projectileClass">
      <img
        v-if="spellKey !== ''"
        :src="getImgUrl(spellKey)"
        :class="{reverse: this.animationValues.playEnemyProjectile}">
    </div>
  </div>
</template>

<script>
import {mapState, mapMutations} from 'vuex';

export default {
  name: 'BattleAnim',
  components: {
  },
  props: {
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapState('raid', ['combatLog', 'gameValues', 'animationValues',
      'spellKey']),
    projectileClass() {
      return {
        'spell-anim-forward': this.animationValues.playStudentProjectile,
        'spell-anim-backwards': this.animationValues.playEnemyProjectile,
      };
    },
  },
  mounted: function() {
  },
  methods: {
    ...mapMutations('raid', ['goToGameState']),
    // https://stackoverflow.com/questions/40491506/vue-js-dynamic-images-not-working
    getImgUrl(pic) {
      return require('../../../assets/raid/' + pic + '.png');
    },
    // showBattleResult() {
    //   this.goToGameState('battleResult');
    // },
  },
};
</script>

<style lang="scss" scoped>
  .turn {
    &-overlay {
      height: 225px;
      width: 150%;
      position: absolute;
      z-index: 50;
      display: flex;
      justify-content: center;
      align-items: center;
      &--player{
        background: #0095ff;
        border: 12px solid #00e7ff;
      }
      &--enemy{
        background: #ff2e5a;
        border: 12px solid #dc1f47;
      }
    }
    &-text {
      font-size: 4rem;
      color: #fff;
      font-family: 'baloo da 2';
      font-weight: 900;
      -webkit-text-stroke: 6px #222;
    }
  }
  .battle-anim__component {
    width: 100%;
    height: 100%;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    .spell {
      margin-top: 10rem;
      opacity: 0;
    }
  }
  .spell-anim-forward {
    animation: 1.5s linear projectile forwards;
    animation-delay: 2.5s;
  }

  .spell-anim-backwards {
    animation: 1.5s linear projectile-reverse;
    animation-delay: 2.5s;
  }

  @keyframes projectile {
    0% {
      opacity: 0;
      transform: translateX(-80%);
    }
    30% {
      opacity: 1;
    }
    90% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      transform: translateX(80%);
    }
  }

  @keyframes projectile-reverse {
    0% {
      opacity: 0;
      transform: translateX(80%);
    }
    30% {
      opacity: 1;
    }
    90% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      transform: translateX(-80%);
    }
  }

  .reverse {
    transform: scaleX(-1) !important;
  }
  .delay-1 {
    animation-delay: 0.5s;
  }
  @media only screen and (max-height: 480px) {
    .turn-overlay {
      height: 180px;
    }
    .turn-text {
      -webkit-text-stroke: 5px #222;
    }
  }
  @media only screen and (max-height: 400px) {
    .turn-overlay {
      height: 150px;
    }
  }
  @media only screen and (max-height: 330px) {
    .turn-overlay {
      height: 120px;
    }
    .turn-text {
      font-size: 3rem;
      -webkit-text-stroke: 3px #222;
    }
  }
  @media only screen and (max-height: 680px) {
    .battle-anim__component .spell {
      margin-top: 6.5rem;
    }
  }
  @media only screen and (max-height: 600px) {
    .battle-anim__component .spell img {
      width: 175px;
    }
  }
  @media only screen and (max-height: 330px) {
    .battle-anim__component .spell img {
      width: 150px;
    }
    .battle-anim__component .spell {
      margin-top: 4.5rem;
    }
  }
</style>
