<template>
  <div class="my-wordlist__component">
    <!-- if loading -->
    <div v-if="fetching">
      <img src="../../../../assets/loading.gif">
    </div>
    <div v-else class="my-wordlist__component--content">
      <div class="my-wordlist__component--content-header">
        <div>
          <h2>My Wordlist</h2>
          <h4>Click on any word to see example sentences</h4>
        </div>
        <div class="my-wordlist__component--content-header-filter">
          <button @click="category='unrevised'"
            type="button" class="my-wordlist__component--content-header-filter--btn"
            :class="{active: category==='unrevised'}">
            我不会 ({{ unRevisedWords.length }})
          </button>
          <button @click="category='isRevised'"
            type="button" class="my-wordlist__component--content-header-filter--btn"
            :class="{active: category==='isRevised'}">
            我会 ({{ isRevisedWords.length }})
          </button>
        </div>
      </div>
      <div class="my-wordlist__component--content-body">
        <row
          v-for="(word, index) in filteredWords"
          @selected="onSelectInfo"
          :key="index"
          :info="word">
        </row>
      </div>
    </div>
    <word-detail-popup
      v-if="selectedInfo"
      :info="selectedInfo"
      @close="selectedInfo=null"
      :include-is-revision-button="true"
    ></word-detail-popup>
  </div>
</template>

<script>
import MyWordlistRowComponent from './MyWordlistRowComponent.vue';
import WordDetailPopupComponent from '../../common/WordDetailPopupComponent.vue';
import axios from 'axios';

import {mapState, mapMutations} from 'vuex';

export default {
  name: 'MyWordlist',
  props: {
  },
  components: {
    Row: MyWordlistRowComponent,
    WordDetailPopup: WordDetailPopupComponent,
  },
  data() {
    return {
      fetching: false,
      category: 'unrevised',
      selectedInfo: null,
    };
  },
  computed: {
    ...mapState(['studentId']),
    ...mapState('analytics', ['wordlist', 'wordlistIds']),
    isRevisedWords() {
      if (!this.wordlist) return [];
      return this.wordlist.filter((word) => word.is_revised);
    },
    unRevisedWords() {
      return this.wordlist.filter((word) => !word.is_revised);
    },
    filteredWords() {
      if (!this.wordlist) return [];
      if (this.category === 'unrevised') {
        return this.unRevisedWords;
      }
      return this.isRevisedWords;
    },
  },
  mounted() {
    this.pullData();
  },
  methods: {
    ...mapMutations('analytics', ['setWordlist']),
    onSelectInfo(args) {
      this.selectedInfo = args;
    },
    pullData() {
      if (this.fetching) return;
      this.fetching = true;

      const url = `/api/v1/core/student/${this.studentId}/wordlist/`;
      return axios
          .get(url)
          .then((response) => {
            this.setWordlist(response.data);
            this.fetching = false;
          })
          .catch((err) => {
            console.log(err);
            this.fetching = false;
          });
    },
  },
};
</script>

<style lang="scss" scoped>
  .my-wordlist__component {
    width: 100%;
    height: 628px;
    &--content {
      &-header{
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-bottom: 1rem;
        h2 {
          line-height: 1.2rem;
          color: #1f5ed0;
        }
        h4 {
          color: #2772fb;
        }
        &-filter {
          display: flex;
          grid-gap: .5rem;
          &--btn {
            padding: .5rem;
            min-width: 5rem;
            text-transform: capitalize;
            font-weight: 600;
            background: #b5b5b5;
            color: #dadada;
            border: none;
            cursor: pointer;
            outline: inherit;
            border-radius: .25rem;
          }
          .active {
            color: #fff;
            background: #1f5ed0;
          }
        }
      }
      &-body {
        display: flex;
        overflow-y: auto;;
        grid-gap: .5rem;
        flex-wrap: wrap;
        max-height: 550px;
      }
    }
  }
  @media only screen and (max-width: 680px) {
    .my-wordlist__component--content-header {
      flex-direction: column;
      h2 {
        font-size: 1.25rem;
      }
      h4 {
        font-size: .85rem;
      }
    }
    .my-wordlist__component--content-header-filter--btn {
      flex: 1;
    }
  }
</style>
