<template>
  <div class="previous-attempts-popup__component">
    <speech-pronunciation-popup v-if="showRevisionPopup"
      @closePopup="showRevisionPopup = !showRevisionPopup"
      :texts="['1234']"></speech-pronunciation-popup>
    <div class="previous-attempts-popup__component--container">
      <i @click="closePopup"
        class="fa-solid fa-xmark btn-close"></i>
      <div>
        PREVIOUS ATTEMPTS GOES HERE
        <ul>
          <li v-for="(attempt, index) in attempts" :key="index">
            Attempted: {{ formatDate(attempt.created) }}
            {{ attempt.revised_answer }}
            <button @click="showRevisionPopup = true">Revise</button>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

import SpeechPronunciationPopupComponent from '../common/SpeechPronunciationPopupComponent.vue';

export default {
  name: 'PreviousAiPracticeAttempts',
  components: {
    SpeechPronunciationPopup: SpeechPronunciationPopupComponent,
  },
  props: {
    questionId: String,
  },
  data() {
    return {
      showRevisionPopup: true,
      fetching: false,
      attempts: [],
    };
  },
  computed: {
  },
  methods: {
    closePopup() {
      this.$emit('closePopup');
    },
    formatDate(dateString) {
      const date = new Date(dateString);
      const day = date.getDate().toString().padStart(2, '0');
      const month = date.toLocaleString('default', {month: 'short'});
      const year = date.getFullYear();
      return `${day} ${month} ${year}`;
    },
  },
  mounted() {
    //   this.setShowGlobalLoadingScreen(true);
    axios
        .get(`/vocab/oralfreeresponse/${this.questionId}/attempts_history/`)
        .then((response) => {
          console.log(response.data);
          this.attempts = response.data.filter((attempt) => {
            return attempt.revised_answer && attempt.revised_answer !== '';
          });
          // this.setFreeResponseQuestions(response.data);
          // this.setShowGlobalLoadingScreen(false);
        });
  },
};

</script>

<style lang="scss" scoped>
  .previous-attempts-popup__component {
    background: #000000e6;
    position: fixed;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    &--container {
      position: relative;
      background: #fff;
      border-radius: 1rem;
      width: calc(100% - 2rem);
      height: calc(100% - 2rem);
      display: flex;
      justify-content: center;
      align-items: center;
      .btn-close {
        position: absolute;
        font-size: 1.5rem;
        color: #a8a8a8;
        top: 1rem;
        right: 1rem;
      }
    }
  }
  </style>
