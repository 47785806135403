<template>
  <div class="multiplayer-room__waiting--component">
    <i class="far fa-clock animated bounceIn"></i>
    <p class="animated fadeInUp faster">- 请等待老师开始课室 -</p>
    <div class="anim-waiting animated fadeInUp faster delay-1s">
      <i class="fas fa-circle"></i>
      <i class="fas fa-circle"></i>
      <i class="fas fa-circle"></i>
    </div>
  </div>
</template>
<script>

// import {mapState, mapGetters} from 'vuex';

export default {
  name: 'MultiplayerRoomWaiting',
  props: {
    teacher: Object,
  },
  data() {
    return {
    };
  },
  computed: {
  },
  mounted() {
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
  .delay-1s {
    animation-delay: 0.15s;
  }
  .multiplayer-room__waiting {
    &--component {
      height: 100vh;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      color: #fff;
      background: #ffa500;
      i {
        font-size: 7.5rem;
        margin-bottom: 20px;
      }
      p {
        font-weight: 400;
        font-size: 1.2rem;
        margin-bottom: 20px;
      }
    }
  }
  @keyframes blink {
      0% {
        opacity: .2;
        transform: scale(1);
      }
      20% {
        opacity: 1;
        transform: scale(1.5);
      }
      100% {
        opacity: .2;
        transform: scale(1);
      }
  }
  .anim-waiting {
    display: flex;
    justify-content: center;
    align-items: center;
    i {
      margin: 0 2.5px;
      font-size: 0.5rem;
      display: inline-block;
      animation-name: blink;
      animation-duration: 1.4s;
      animation-iteration-count: infinite;
      animation-fill-mode: both;
      margin-bottom: 20px;
      &:nth-child(2) {
        animation-delay: .2s;
      }
      &:nth-child(3) {
        animation-delay: .4s;
      }
    }
  }
</style>
