<template>
  <div class="raid-result__component">
    <reward
      v-if="winner == 1"
      :coins-to-add="coinsToAdd"
    ></reward>
    <div class="game-over__component animated fadeIn delay-1">
      <div v-if="winner == 2"
        class="game-over__popup animated bounceIn delay-1">
        <i class="fas fa-heart-broken"></i>
        <h2 class="animated bounceIn">Your pet has fainted.</h2>
        <button @click="gameOver">
          Try Again
        </button>
      </div>
    </div>
  </div>
</template>

<script>

import {mapState, mapMutations} from 'vuex';

import RewardComponent
  from './RewardComponent.vue';

export default {
  name: 'RaidResult',
  components: {
    Reward: RewardComponent,
  },
  props: {
    coinsToAdd: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapState('raid', ['winner']),
  },
  mounted: function() {
  },
  methods: {
    ...mapMutations('raid', ['goToGameState']),
    gameOver() {
      this.goToGameState('index');
    },
  },
};
</script>

<style lang="scss" scoped>
  .raid-result__component {
    z-index: 100;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
  .delay-1 {
    animation-delay: 1s;
  }
  .game-over__popup {
    background: #fff;
    text-align: center;
    padding: 2rem;
    border-radius: 5px;
  }
  .game-over {
    &__component {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      height: 100%;
      width: 100%;
      background: rgba(0, 0, 0, 0.8);
      i {
        font-size: 8rem;
        padding: 2rem;
        color: #fff;
        background: #ff5353;
        border-radius: 50%;
        margin-bottom: 1rem;
      }
      h2 {
        margin-bottom: 1rem;
      }
      button {
        width: 175px;
        cursor: pointer;
        border-radius: 5px;
        border: 3.5px solid #adadad;
        padding: 0.5rem;
        transition: all 0.2s;
        &:focus {
          outline: none;
        }
        &:hover {
          transform: scale(1.05);
        }
      }
    }
  }
  @media only screen and (max-height: 480px) {
    .game-over__component i {
      font-size: 6rem;
    }
  }
  @media only screen and (max-height: 440px) {
    .game-over__component i {
      font-size: 4rem;
    }
  }
  @media only screen and (max-height: 414px) {
    .game-over__component i {
      font-size: 3rem;
    }
    .game-over__component h2 {
      font-size: 1.25rem;
    }
  }
  @media only screen and (max-height: 320px) {
    .game-over__popup {
      padding: 1rem;
    }
    .game-over__component i {
      font-size: 2.5rem;
    }
    .game-over__component h2 {
      font-size: 1.1rem;
    }
  }
</style>
