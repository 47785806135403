<!-- layout for ./learningtracks/ -->
<template>
  <div class="learning-track-overview__component row">
    <!-- to use loading-screen component, set show to true --><!--
    <loading-screen
      :prop-show="true"
      :min-wait-time="3000"></loading-screen> -->
    <div class="learning-track-overview__header">
      <h2>My Learning Tracks</h2>
    </div>
    <div class="learning-track-overview__items">
      <router-link
        v-if="isPrimarySchool"
        class="learning-track-overview__item"
        :to="{name: 'worksheet-download'}">
        <i class="fas fa-pencil-alt learning-track-overview__item--icon">
        </i>
        <h3 class="learning-track-overview__item--header">Worksheets</h3>
        <p class="learning-track-overview__item--text">
          Download and practise!
        </p>
      </router-link>
      <!-- hard code for vocabulary -->
      <router-link
        class="learning-track-overview__item"
        :to="{name: 'learning-track-vocabulary-index'}">
        <!-- <i class="fas fa-language learning-track-overview__item--icon">
        </i> -->
        <p class="learning-track-overview__item--icon custom-icon--1">
          <span>认</span><span>字</span>
        </p>
        <h3 class="learning-track-overview__item--header">Textbook Vocabulary</h3>
        <!-- p: [completed modules / total no. of modules in learning track] -->
        <p class="learning-track-overview__item--text">
          {{ numberLearningTracks['vocabulary'] }} Tracks Available
        </p>
      </router-link>
      <router-link
        class="learning-track-overview__item"
        :to="{name: 'learning-track-high-frequency-index',
        params: {learningTrackType: 'vocabulary'}}">
        <!-- <i class="fas fa-language learning-track-overview__item--icon">
        </i> -->
        <p class="learning-track-overview__item--icon custom-icon--1">
          <span>认</span><span>字</span>
        </p>
        <h3 class="learning-track-overview__item--header">High-Frequency Words</h3>
        <!-- p: [completed modules / total no. of modules in learning track] -->
        <p class="learning-track-overview__item--text">
          {{ numberLearningTracks['high_frequency'] }} Tracks Available
        </p>
      </router-link>
      <!-- pinyin -->
      <router-link
        v-if="numberLearningTracks['pinyin']"
        class="learning-track-overview__item"
        :to="{name: 'learning-track-pinyin-index',
        params: {learningTrackType: 'pinyin'}}">
        <!-- TODO: v-if new content -->
        <p class="new">new</p>
        <div class="learning-track-overview__item--icon custom-icon--2 hypy">
          <span>ā</span>
          <span>á</span>
          <span>ǎ</span>
          <span>à</span>
        </div>
        <h3 class="learning-track-overview__item--header">Han Yu Pin Yin</h3>
        <p class="learning-track-overview__item--text">
          {{ numberLearningTracks['pinyin'] }} Tracks Available
        </p>
      </router-link>
      <div
        v-if="!numberLearningTracks['pinyin']"
        class="learning-track-overview__item disabled">
        <div class="learning-track-overview__item--icon custom-icon--2 hypy">
          <span>ā</span>
          <span>á</span>
          <span>ǎ</span>
          <span>à</span>
        </div>
        <h3 class="learning-track-overview__item--header">Han Yu Pin Yin</h3>
        <!-- p: if not released yet, show coming soon instead-->
        <p class="learning-track-overview__item--text">Locked!</p>
      </div>
      <!-- idioms -->
      <router-link
        v-if="numberLearningTracks['idioms']"
        class="learning-track-overview__item"
        :to="{name: 'learning-track-idiom-index',
        params: {learningTrackType: 'idioms'}}">
        <p class="learning-track-overview__item--icon custom-icon--1">
          <span>成</span><span>语</span>
        </p>
        <h3 class="learning-track-overview__item--header">Idioms</h3>
        <!-- p: [completed modules / total no. of modules in learning track] -->
        <p class="learning-track-overview__item--text">
          Learn good idioms!
        </p>
      </router-link>
      <!-- assessment book -->
      <router-link
        v-if="numberLearningTracks['assessment_book']"
        class="learning-track-overview__item"
        :to="{name: 'learning-track-assessment-book-index',
        params: {learningTrackType: 'assessment_book'}}">
        <i class="fas fa-book learning-track-overview__item--icon">
        </i>
        <p class="new">new</p>
        <h3 class="learning-track-overview__item--header">Assessment Book</h3>
        <p class="learning-track-overview__item--text">
          {{ numberLearningTracks['assessment_book'] }} Tracks Available
        </p>
      </router-link>
      <div
        v-if="!numberLearningTracks['assessment_book']"
        class="learning-track-overview__item disabled">
        <i class="fas fa-book learning-track-overview__item--icon">
        </i>
        <h3 class="learning-track-overview__item--header">Assessment Book</h3>
        <p class="learning-track-overview__item--text">Locked</p>
      </div>
<!--       <router-link
        class="learning-track-overview__item"
        :to="{name: 'learning-track-ebook-dashboard',
        params: {learningTrackType: 'writing'}}">
        <i class="fas fa-pencil-alt learning-track-overview__item--icon">
        </i>
        <h3 class="learning-track-overview__item--header">Exam Revision</h3>
        <p class="learning-track-overview__item--text">
          Create an amazing EBook!
        </p>
      </router-link> -->
      <!-- writing
      <router-link
        class="learning-track-overview__item"
        :to="{name: 'learning-track-ebook-dashboard',
        params: {learningTrackType: 'writing'}}">
        <i class="fas fa-pencil-alt learning-track-overview__item--icon">
        </i>
        <h3 class="learning-track-overview__item--header">Writing</h3>
        <p class="learning-track-overview__item--text">
          Create an amazing EBook!
        </p>
      </router-link>
      -->
<!--       <div
        v-if="studentType=== 'digital_trial' ||
          !numberLearningTracks['writing']"
        class="learning-track-overview__item disabled">
        <i class="fas fa-pencil-alt learning-track-overview__item--icon">
        </i>
        <h3 class="learning-track-overview__item--header">Writing</h3>
        <p class="learning-track-overview__item--text">
          Create an amazing EBook!
        </p>
      </div> -->
    </div>
  </div>
</template>

<script>

import {mapState, mapGetters} from 'vuex';
// import LoadingScreenComponent
//   from '../common/LoadingScreenComponent.vue';

export default {
  name: 'LearningTrackOverview',
  components: {
  },
  props: {
  },
  data() {
    return {
    };
  },
  computed: {
    ...mapState(['studentType']),
    ...mapGetters(['isPrimarySchool']),
  },
  mounted: function() {
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
  .new {
    position: absolute;
    top: 5px;
    right: 5px;
    background-color: #49d689;
    color: white;
    padding: 5px 7.5px;
    border-radius: 5px;
    box-shadow: 0 5px 5px rgba(0,0,0,0.2);
    text-transform: uppercase;
    font-weight: 700;
  }

  .learning-track-overview {
    &__component {
      text-align: center;
      padding: 20px;
    }
    &__header {
      background: #5a4179;
      color: #fff;
      padding: 10px;
      border-radius: 3px;
      margin-bottom: 25px;
    }
    &__items {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 20px;
    }
    &__item {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      position: relative;
      max-width: 550px;
      height: 350px;
      color: #555;
      background: #fff;
      padding: 35px;
      border-radius: 3px;
      text-decoration: none;
      border: 8px solid transparent;
      transition: all 0.2s;
      &--icon {
        color: #aaa;
        border-radius: 20px;
        border: 8px solid #555;
        padding: 25px 40px;
        margin: 0 auto 20px auto;
        font-size: 4.5rem;
        transition: all 0.2s;
      }
      &--text {
        font-size: 16px;
        font-style: italic;
      }
      &:hover {
        border: 8px solid #555;
        .learning-track-overview {
          &__item--icon {
            color: #6E9FEC;
            border: 8px solid transparent;
            font-size: 6.25rem;
            padding: 0;
            margin: 10px auto 20px auto;
          }
        }
        .custom-icon--1 {
          font-size: 3rem;
          margin: 40px auto 30px auto;
          span {
            background: #6E9FEC;
            color: #fff;
            padding: 20px;
          }
          span:last-of-type {
            margin-left: 6px;
          }
          span:first-of-type {
            margin-right: 6px;
          }
        }
        .custom-icon--2 {
          font-size: 2rem;
          margin: 0px auto 15px auto;
          span {
            background: #6E9FEC;
            color: #fff;
            padding: 10px 20px;
          }
        }
      }
    }
  }

  .custom-icon--1 {
    font-size: 2rem;
    color: #fff;
    display: inline-block;
    padding: 48px 25px;
    transition: all 0.2s;
    span:first-of-type {
      font-family: 'ZCOOL KuaiLe', cursive;
      background: #aaa;
      margin-right: 3px;
      padding: 10px;
      border-top-left-radius: 6px;
      border-bottom-left-radius: 6px;
    }
    span:last-of-type {
      font-family: 'ZCOOL KuaiLe', cursive;
      background: #aaa;
      margin-left: 3px;
      padding: 10px;
      border-top-right-radius: 6px;
      border-bottom-right-radius: 6px;
    }
  }
  .custom-icon--2 {
    font-size: 1.25rem;
    color: #fff;
    display: inline-block;
    width: 180px;
    transition: all 0.2s;
    padding: 20px 10px;
    span {
      display: inline-block;
      min-width: 2.65rem;
      background: #aaa;
      margin-right: 3px;
      padding: 10px;
      border-radius: 6px;
    }
    span:last-of-type {
      margin-right: 0;
      margin-top: 10px;
    }
  }


  .cursor-notallowed {
    cursor: not-allowed;
  }
  .disabled {
    opacity: 0.5;
    pointer-events: none;
    .learning-track-overview {
      &__item {
        &--icon {
          color: #999;
        }
      }
    }
    .custom-icon--2 {
      color: #fff;
    }
  }
  @media only screen and (max-width: 1140px) {
    .learning-track-overview {
      &__component {
        margin: 10px auto 0 auto;
      }
      &__header {
        font-size: 16px;
        margin-bottom: 15px;
      }
      &__items {
        margin-bottom: 65px;
        grid-gap: 20px;
      }
      &__item {
        padding: 15px;
        &:hover {
          border: 8px solid transparent;
          .custom-icon--1 {
            margin: 30px auto;
          }

        }
      }
    }
  }
  @media only screen and (max-width: 910px){
    .new {
      top: 10px;
      right: 10px;
      padding: 3px 6px;
      font-size: 90%
    }
  }

  @media only screen and (max-width: 600px) {
    .learning-track-overview {
      &__items {
        grid-template-columns: 1fr;
      }
      &__item {
        max-width: 600px;
        &--icon{
          color: #6E9FEC;
          border: 8px solid transparent;
          font-size: 4rem;
          padding: 0;
          margin: 10px auto 10px auto;
        }
      }
    }
    .custom-icon--1 {
      font-size: 3.35rem;
      span:first-of-type,
      span:last-of-type {
        background: #6E9FEC;
        color: #fff;
      }
    }
    .custom-icon--2 {
      font-size: 2rem;
      span {
        background: #6E9FEC;
        color: #fff;
      }
    }
  }

</style>
