<!-- Template for items in ./learningtrack/modules/module -->
<template>
  <div class="learning-track-item__component">
    <!-- for vocab and pinyin quizzes -->

    <router-link
      v-if="item.item_type === 'quiz' &&
        item.quiz_type !== 'primary_school_assessment_book_quiz'"
      :to="{name: 'learning-track-quiz',
      params: {quizId: Number(item.chinese_quiz_id)}}">
      <div class="learning-track-item__icon">
        <i class="fa-solid fa-graduation-cap"></i>
        <!-- <i class="fas fa-graduation-cap "></i> -->
        <rating class="rating-star-container"
          image="star"
          :current-score="stars"
          :max-score=3>
        </rating>
      </div>
      <span class="item-desc">
        {{ title }}
      </span>
    </router-link>

    <!-- for assessment book quizzes -->
    <router-link
      v-if="item.quiz_type === 'primary_school_assessment_book_quiz'"
      :to="{name: 'learning-track-assessment-book',
      params: {quizId: Number(item.assessment_book_id)}}">
      <div class="learning-track-item__icon">
        <i class="fas fa-graduation-cap "></i>
        <!-- hide stars for now -->
<!--         <rating class="rating-star-container"
          image="star"
          :current-score="stars"
          :max-score=3>
        </rating> -->
      </div>
      <span class="item-desc">
        {{ title }}
      </span>
    </router-link>
  </div>
</template>
<script>

import RatingComponent from '../common/RatingComponent.vue';

export default {
  name: 'LearningTrackSubtrackItem',
  components: {
    Rating: RatingComponent,
  },
  props: {
    item: Object,
    stars: Number,
    // can be blank, but if passed in, then use it
    titleToUse: String,
  },
  data() {
    return {
    };
  },
  computed: {
    title() {
      if (typeof this.titleToUse !== 'undefined' &&
        this.titleToUse !== '') return this.titleToUse;
      const displayTitle = this.item.display_title;
      if (!this.item.part_number || this.item.part_number === 0) {
        return displayTitle;
      }

      return displayTitle + ' (' + (this.item.part_number + 1) + ')';
    },
  },
  mounted: function() {
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
  .learning-track-item {
    &__component {
      background: #fff;
      padding: 35px;
      color: #555;
      border-radius: 3px;
      text-decoration: none;
      border: 6px solid transparent;
      transition: all 0.2s;
      &:hover {
        // border: 6px solid #555;
        .learning-track-item__icon {
          // border: 6px solid transparent;
          // border-radius: 50%;
          padding: 20px;
          height: 151px;
          // width: 151px;
          border: 6px solid #b3cdff;
          i {
            color: #6e9fec;
            // font-size: 5rem;
          }
        }
      }
    }
    &__icon {
      border-radius: 14px;
      border: 6px solid #efefef;
      padding: 20px 80px;
      margin: 0 auto 10px auto;
      transition: all 0.2s;
      height: 151px;
      width: 260px;
      i {
        transition: all 0.2s;
        color: #aaa;
        font-size: 3.5rem;
        margin-bottom: 5px;
      }
    }
  }
  .item-desc {
    color: #555;
    font-size: 22.5px;
  }
  @media only screen and (max-width: 1140px) {
    .learning-track-item {
      &__component {
        padding: 15px;
      }
    }
  }
</style>
