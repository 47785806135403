<!-- layout for ./learningtracks/modules -->
<template>
  <div class="term-index__component row">
    <div class="term-index__header">
      <router-link
        class="term-index__header--back"
        :to="{name: 'new-learn-vocab-index'}">
        <i class="fas fa-angle-left"></i>
      </router-link>
      <!-- shows which learning track user is on -->
      <h2>Idioms</h2>
    </div>
    <learning-track-subtopic-container
      v-for="learningTrack in learningTracks"
      :key="learningTrack.pk"
      :learning-track="learningTrack"
      class="term-index__content--revision">
    </learning-track-subtopic-container>
    <!-- PLACEHOLDERS
    <div class="term-index__content">
      <router-link
        :to="{name: 'raid-index', params: { raidType: 'idioms'}}"
        class="term-index__content--row">
        <div class="learning-track-item__component">
          <div class="learning-track-item__icon">
            <i class="fas fa-paw "></i>
          </div>
          <span class="item-desc">
            Pet Battle
          </span>
        </div>
      </router-link>
    </div>
     -->
  </div>
</template>

<script>

// mapState
import {mapState} from 'vuex';
import {mapGetters} from 'vuex';
// import _filter from 'lodash.filter';

import LearningTrackSubtopicContainerComponent from
  '../LearningTrackSubtopicContainerComponent.vue';

export default {
  name: 'LearningTrackIdiomsIndex',
  components: {
    LearningTrackSubtopicContainer: LearningTrackSubtopicContainerComponent,
  },
  data() {
    return {
      learningTrack: Object,
    };
  },
  computed: {
    ...mapState(['chineseQuizStars']),
    ...mapGetters('learningTrack', ['getLearningTracksByType']),
    // filtered by the learningTypeTrack
    learningTracks() {
      return this.getLearningTracksByType('idioms');
    },
  },
  mounted: function() {
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
  .term-index {
    &__component {
      text-align: center;
      margin: 20px auto 0 auto;
    }
    &__header {
      background: #5a4179;
      color: #fff;
      padding: 10px;
      border-radius: 3px;
      margin-bottom: 25px;
      position: relative;
      &--back {
        position: absolute;
        left: 10px;
        font-size: 1.5em;
        color: #fff;
        margin-left: 5px;
      }
    }
    &__content {
      margin-top: 0.5rem;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 20px;
      margin-bottom: 35px;
      &--row {
        grid-column: 1/ span 2;
      }
      &--unlocked {
        padding-bottom: 10px;
      }
      &--locked {
      }
    }
  }
  @media only screen and (max-width: 1140px) {
    .term-index {
      &__component {
        margin: 10px auto 0 auto;
      }
      &__header {
        font-size: 16px;
        margin-bottom: 15px;
      }
    }
  }
  .learning-track-item {
    &__component {
      cursor: pointer;
      background: #fff;
      padding: 25px;
      color: #555;
      border-radius: 3px;
      text-decoration: none;
      border: 6px solid transparent;
      transition: all 0.2s;
      &:hover {
        // border: 6px solid #555;
        .learning-track-item__icon {
          // border: 6px solid transparent;
          // border-radius: 50%;
          padding: 20px;
          // width: 151px;
          border: 6px solid #b3cdff;
          i {
            color: #6e9fec;
            // font-size: 5rem;
          }
        }
      }
    }
    &__icon {
      border-radius: 14px;
      border: 6px solid #efefef;
      padding: 20px 80px;
      margin: 0 auto 10px auto;
      transition: all 0.2s;
      width: 260px;
      i {
        transition: all 0.2s;
        color: #aaa;
        font-size: 3.5rem;
        margin-bottom: 5px;
      }
    }
  }
  .item-desc {
    color: #555;
    font-size: 22.5px;
  }
  @media only screen and (max-width: 1140px) {
    .learning-track-item {
      &__component {
        padding: 15px;
      }
    }
  }
</style>
