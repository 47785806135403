<!-- a frame scene with clickable items -->
<template>
  <div class="dynamic-book__frame--component">
    <div class="dynamic-book__frame--border">
      <div class="dynamic-book__frame--dialogue row">
        <image-portrait
          v-if="portrait"
          :portrait="portrait"></image-portrait>
        <p class="dynamic-book__frame--text">
          {{ textToDisplay }}
        </p>
        <!-- <read-aloud-audio></read-aloud-audio> -->
      </div>
      <!-- replace with images in the future -->
      <div class="dynamic-book__frame--image">
        <image-button
          v-for="choice in choices"
          class="heartbeat-animation"
          :key="choice.nextSceneId"
          :text="choice.text"
          :image-name="choice.imageName"
          :next-scene-id="choice.nextSceneId"
        ></image-button>
      </div>
    </div>
    <slot></slot>
  </div>
</template>

<script>

import ImageButtonComponent
  from '../../common/ImageButtonComponent.vue';

import ImagePortraitComponent
  from '../../common/ImagePortraitComponent.vue';
// import ReadAloudAudioComponent
//   from '../../common/ReadAloudAudioComponent.vue';

export default {
  name: 'DynamicBookFrameClickablePopupScene',
  components: {
    ImageButton: ImageButtonComponent,
    ImagePortrait: ImagePortraitComponent,
    // ReadAloudAudio: ReadAloudAudioComponent,
  },
  props: {
    textToDisplay: String,
    portrait: String,
    choices: Array,
  },
  data() {
    return {
    };
  },
  computed: {
  },
  mounted: function() {
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
  .dynamic-book__frame {
    &--border {
      border-radius: 10px;
      border: 5px solid #444;
      width: calc(100vw - 30px);
      height: 100vh;
      margin: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      position: relative;
    }
    &--component {
      height: 100vh;
      width: 100vw;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      &::after {
        content: '';
        background: url('https://smartschool-static.s3-ap-southeast-1.amazonaws.com/media/dynamicbooks/2019_dec_holiday_camp/others/bg-pattern.jpg');
        opacity: 0.3;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: absolute;
        z-index: -1;
      }

    }
    &--dialogue {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 35vh;
      margin-bottom: 55vh;
      padding: 0 2rem;
      z-index: 20;
    }
    &--text {
      width: 75%;
      color: #fff;
      font-size: 2.65rem;
      text-align: center;
    }
    &--image {
      margin-top: 75px;
      width: 100%;
      position: absolute;
      display: flex;
      justify-content: center;
    }
  }

  /* Landscape */
  // ipad pro
  @media only screen
  and (min-device-width: 1024px)
  and (max-device-width: 1366px)
  and (-webkit-min-device-pixel-ratio: 2) {
    .dynamic-book__frame {
      &--text {
        width: 80%;
        font-size: 2.4rem;
      }
    }
  }
  // ipad
  @media only screen and (min-device-width : 768px)
  and (max-device-width : 1024px) and (orientation : landscape) {
    .dynamic-book__frame {
      &--text {
        font-size: 2.4rem;
        width: 75%;
      }
    }
  }

</style>
