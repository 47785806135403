<template>
  <div class="order-list__component animated fadeIn faster">
    <!-- Order List -->
    <ul class="order-list">
      <!-- EACH ITEM -->
      <li class="order-list__item"
        v-for="(item, index) in purchasedItems" :key="item.index">
        <div class="order-list__item--index">
          {{ index + 1 }}
        </div>
        <div class="order-list__item--content">
          <img class="order-list__item--content-img"
            :src="item.photo">
          <div class="order-list__item--content-text">
            {{ item.title }}
          </div>
        </div>
        <div @click="selectItemForCancellation(item.pk)"
          class="order-list__item--btn-cancel">
          <i class="fas fa-times"></i>
        </div>
      </li>
    </ul>
    <div class="order-list__footer">
      <p>
        {{purchasedItems.length}} Total Order(s) Displayed
      </p>
    </div>
    <!-- popup cancellation confirmation -->
    <div v-if="showCancelPopup"
      class="cancel-item__popup animated fadeIn faster">
      <div class="cancel-item__popup--container animated bounceIn fast">
        <p class="cancel-item__popup--text">
          Are you sure you want to cancel this order?
        </p>
        <div class="cancel-item__popup--btns">
          <p class="cancel-item__popup--btn cancel-item__popup--btn-yes"
            @click="confirmCancellation(true)">
            Yes
          </p>
          <p class="cancel-item__popup--btn cancel-item__popup--btn-no"
            @click="confirmCancellation(false)">
            No
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ShopTransactionHistory',
  components: {
  },
  props: {
    itemsMap: Object,
    orderedCounts: Object,
  },
  data() {
    return {
      showCancelPopup: false,
      selectedItemIdForCancellation: -1,
    };
  },
  computed: {
    purchasedItems() {
      const results = [];
      for (const itemId in this.orderedCounts) {
        // if a player has purchased the same item twice
        // we want to show the item twice
        // so we duplicate it
        for (let i = 0; i < this.orderedCounts[itemId]; i++) {
          results.push(this.itemsMap[itemId]);
        }
      };
      return results;
    },
  },
  mounted: function() {
  },
  methods: {
    // two step cancellation process
    // step 1 is showing a popup asking child if really want to cancel
    selectItemForCancellation(itemId) {
      console.log('do you want to cancel?');
      this.selectedItemIdForCancellation = itemId;
      this.showCancelPopup = true;
    },
    confirmCancellation(proceed) {
      if (proceed) {
        this.$emit('cancel', this.selectedItemIdForCancellation);
      }
      this.selectedItemIdForCancellation = -1;
      this.showCancelPopup = false;
    },
    closePopup() {
      this.$emit('close-popup');
    },
    cancel(itemId) {
    },
  },
};
</script>

<style lang="scss" scoped>
.order-list {
  &__component {
  }
  &__item {
    margin-bottom: 1rem;
    background: #fff;
    height: 6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.25rem;
    font-weight: 700;
    border-radius: 5px;
    &--index {
      width: 10%;
      text-align: center;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &--content {
      border-right: 3px solid #f1f1f1;
      border-left: 3px solid #f1f1f1;
      height: 4rem;
      display: flex;
      width: 80%;
      align-items: center;
      &-img {
        margin: 0 1rem;
        width: 60px;
        height: 50px;
        object-fit: cover;
      }
      &-text {
      }
    }
    &--btn-cancel {
      height: 100%;
      width: 10%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: #ff5b5b;
      cursor: pointer;
      &:hover {
        background: #ff5b5b;
        color: #fff;
      }
      i {
        font-size: 1.5rem;
      }
    }
  }
  &__footer {
    display: flex;
    justify-content: center;
    font-size: 0.8rem;
    font-weight: 800;
    margin-top: 2rem;
  }
}
.cancel-item__popup {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 30;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  &--container {
    position: relative;
    background: #fff;
    border-radius: 3px;
    text-align: center;
  }
  &--text {
    margin: 2rem 3rem;
    font-weight: 800;
    width: 12.5rem;
  }
  &--btns {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &--btn {
    flex: 1;
    padding: .5rem;
    font-weight: 800;
    cursor: pointer;
    &-yes {
      background: #ffbebe;
      color: #fff;
      border-bottom-left-radius: 3px;
      &:hover {
        background: #ff5b5b;
        color: #fff;
      }
    }
    &-no {
      background: #90dbff;
      color: #fff;
      border-bottom-right-radius: 3px;
      &:hover {
        background: #00a7f7;
        color: #fff;
      }
    }
  }
}
</style>
