<!-- where the majority of the code is at -->
<template>
  <div class="quiz-popup__component animated fadeIn faster"
    :class="{ fadeOut: closeComponent}">
    <div class="quiz-popup__container animated bounceIn"
      :class="{ bounceOut: closePopup }">
      <p v-if="!question.image"
        class="quiz-popup__question quiz-popup__question--text"
        v-html="hydratedQuestionText"></p>
      <div v-if="question.image" class="quiz-popup__question">
        <img :src="question.image">
      </div>
      <ul
        v-if="!showAnswer"
        class="quiz-popup__choices">
        <li class="quiz-popup__choice"
          :class="questionClass"
          v-for="(choice, index) in shuffledChoices"
          @click="_answerQuestion(choice)"
          :key="index">
          {{ choice }}
        </li>
      </ul>
      <ul
        v-else
        class="quiz-popup__choices">
        <li class="quiz-popup__choice"
          :class="[questionClass, getChoiceClass(choice)]"
          v-for="(choice, index) in shuffledChoices"
          :key="index">
          {{ choice }}
        </li>
      </ul>
      <div v-if="showAnswer" class="countdown">
      </div>
    </div>
  </div>
</template>

<script>
import {mapMutations, mapActions} from 'vuex';

import {utilities} from '../../mixins/utilities';

export default {
  name: 'RaidGameMain',
  components: {
  },
  props: {
    question: Object,
    showAnswer: {
      type: Boolean,
      default: false,
    },
  },
  mixins: [utilities],
  data() {
    return {
      studentAnswer: '',
      closePopup: false,
      closeComponent: false,
      canClickOnAnswer: true,
    };
  },
  computed: {
    questionClass() {
      // validation
      if (!this.question || this.question === {}) return;
      if (!this.question.answer_type ||
        this.question.answer_type === '') return;

      return this.question.answerType === 'hypy' ?
        'hypy' : 'chinese-character';
    },
    shuffledChoices() {
      if (!this.question) return;

      return this.shuffleArray(this.question.choices);
    },
    currentQuestion() {
      return this.questionData[this.questionIndex];
    },
    hydratedQuestionText() {
      if (this.question.text === '') return;
      return this.$options.filters.hyrateQuestionText(this.question.text);
    },
  },
  mounted: function() {
  },
  methods: {
    ...mapMutations('raid', ['goToGameState']),
    ...mapActions('raid', ['calculateBattle']),
    _answerQuestion(choice) {
      if (!this.canClickOnAnswer) return;
      this.canClickOnAnswer = false;
      // do not allow users to click again on the buttons while closing
      if (this.closePopup || this.closeComponent) return;

      setTimeout(()=>{
        this.closePopup = true;
      }, 2150);
      setTimeout(()=>{
        this.closeComponent = true;
        this.canClickOnAnswer = true;
      }, 2900);
      this.studentAnswer = choice;
      this.$emit('answerQuestion', choice);
    },
    getChoiceClass(choice) {
      if (!this.showAnswer) return '';
      if (this.question.correct_answer === this.studentAnswer) {
        return choice === this.question.correct_answer ? 'correct':
          'neutral';
      } else {
        if (choice === this.question.correct_answer) return 'correct';
        if (choice === this.studentAnswer) return 'wrong';
        return 'neutral';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .countdown {
    position: absolute;
    top: 0;
    left: 0;
    height: 30px;
    background: #cccccc99;
    animation: countdown-bar 2s linear forwards;
  }

  @keyframes countdown-bar {
    0% {
      // background: #00d0ff;
      width: 100%;
    }
    50% {
      // background: #ffa500;
    }
    100% {
      // background: #ff0052;
      width: 0%;
    }
  }

  .quiz-popup {
    &__component {
      position: fixed;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      background: rgba(0, 0, 0, 0.9);
    }
    &__container {
      overflow: hidden;
      width: 90%;
      height: 90%;
      text-align: center;
      padding: 2rem 3rem;
      background: #fff;
      color: #222;
      border-radius: 5px;
      position: relative;
    }
    &__question {
      height: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
      &--text {
        font-size: 2rem;
      }
      img {
        height: 100%;
      }
    }
    &__choices {
      height: 50%;
      display: flex;
      flex-wrap: wrap;
    }
    &__choice {
      margin: 0.25rem;
      width: calc(50% - 0.5rem);
      color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      font-size: 2.25rem;
      border-radius: 3px;
      transition: all 0.25s;
      border: 5px solid transparent;
      &:nth-child(4) {
        background: #fb5784;
      }
      &:nth-child(3) {
        background: #7e55e0;
      }
      &:nth-child(2) {
        background: #efad1c;
      }
      &:nth-child(1) {
        background: #1abfa1;
      }
    }
  }

  .correct {
    background: #14d49f !important;
    color: #fff;
  }
  .wrong {
    background: #fd6969 !important;
    color: #fff;
    opacity: 0.7;
  }
  .neutral {
    background: #cecece !important;
    color: #fff;
    opacity: 0.7;
  }

  @media only screen and (max-height: 460px) {
    .quiz-popup {
      &__question {
        font-size: 1.5rem;
      }
      &__choice {
        font-size: 1.5rem;
      }
    }
  }
  @media only screen and (max-height: 360px) {
    .quiz-popup {
      &__question {
        font-size: 1.25rem;
      }
      &__choice {
        font-size: 1.25rem;
      }
    }
  }
  @media only screen and (max-width: 1024px) {
    .quiz-popup {
      &__container {
        padding: 1rem 2rem;
      }
      &__question {
        font-size: 1.5rem;
      }
      &__choice {
        font-size: 1.5rem;
      }
    }
  }
  @media only screen and (max-width: 760px) {
    .quiz-popup {
      &__container {
        padding: 1rem 1.5rem;
      }
      &__question {
        font-size: 1.35rem;
      }
      &__choice {
        font-size: 1.35rem;
      }
    }
  }
  @media only screen and (max-width: 760px) {
    .quiz-popup {
      &__container {
        padding: 0.5rem 1rem;
      }
      &__question {
        font-size: 1.15rem;
      }
      &__choice {
        font-size: 1.15rem;
      }
    }
  }
</style>
