<template>
  <div class="oral-component">
    <return-index-button :type="type"></return-index-button>
    <div class="oral-component__content" :class="paragraphClass">
      <div v-if="state !== 4" class="oral-component__content--header chinese-character">
        <span
          v-if="type !== 'templatedphrases'"
          class="oral-component__content--header-title">
          《{{ passageData.title }}》
        </span>
        <span v-if="paraIndex > -1"
          class="oral-component__content--header-para-number chinese-character">
          段落: {{ paraIndex + 1 }} / {{ this.numParagraphs }}
        </span>
      </div>
      <div v-if="state !== 4"
        class="oral-component__content--passage">
        <!-- question container -->
        <div v-if="paraIndex > -1 && [0, 1, 2].indexOf(state) > -1"
          class="oral-component__content--passage-qns">
          <p v-for="(word, index) in text"
            class="chinese-character" :key="index">
            {{ word }}
          </p>
        </div>
        <!-- results container -->
        <div v-if="wordsWithPunctuation && state === 3"
          class="oral-component__content--passage-result">
          <entity
            v-for="(word, index) in wordsWithPunctuation"
            :key="index"
            :info="word"></entity>
        </div>
        <div v-if="wordsWithPunctuation && state === 3"
          class="oral-component__content--passage-result--legend">
          <div class="oral-component__content--passage-result--legend-1">
            <i class="fa-solid fa-square"></i>
            <span>Can Improve</span>
          </div>
          <div class="oral-component__content--passage-result--legend-2">
            <i class="fa-solid fa-square"></i>
            <span>Good</span>
          </div>
          <div class="oral-component__content--passage-result--legend-3">
            <i class="fa-solid fa-square"></i>
            <span>Excellent</span>
          </div>
        </div>
      </div>
      <div v-if="state !==4"
        class="oral-component__content--dashboard">
        <!-- Recorder -->
        <div v-if="[0, 1].indexOf(state) > -1"
          class="oral-component__content--dashboard-recorder">
          <!-- <span class="oral-component__content--dashboard-recorder-timer"
            v-if="isRecording">
            00:00
          </span> -->
          <div class="oral-component__content--dashboard-recorder-btn">
            <img v-if="!isRecordingBuffered" src="../../assets/oral/icon-recorder.png" class="microphone" @click="toggleRecording" />
            <img v-if="isRecordingBuffered" src="../../assets/oral/icon_recording.png" class="microphone" @click="toggleRecording" />
          </div>
          <div class="oral-component__content--dashboard-recorder-text">
            <span v-if="state === 0 && !isRecordingBuffered">
              ( Click the microphone icon to start recording )
            </span>
            <span v-if="state !== 0 && isRecordingBuffered">
              ( Recording in progress... )
            </span>
          </div>
        </div>
        <!-- Loading -->
        <div v-if="state === 2"
          class="oral-component__content--dashboard-loading">
          <img src="../../assets/oral/indicator.gif" />
        </div>
        <!-- Results -->
        <div v-show="state === 3"
          class="oral-component__content--dashboard-results">
          <div v-if="results"
            class="oral-component__content--dashboard-results-score">
            <div class="oral-component__content--dashboard-results-score-item">
              <div class="oral-component__content--dashboard-results-score-name">
                Overall
              </div>
              <div class="oral-component__content--dashboard-results-score-value">
                {{ results.overall }}
              </div>
            </div>
            <div class="oral-component__content--dashboard-results-score-item">
              <div class="oral-component__content--dashboard-results-score-name">
                Fluency
              </div>
              <div class="oral-component__content--dashboard-results-score-value">
                {{ results.fluency }}
              </div>
            </div>
            <div class="oral-component__content--dashboard-results-score-item">
              <div class="oral-component__content--dashboard-results-score-name">
                Tone
              </div>
              <div class="oral-component__content--dashboard-results-score-value">
                {{ results.tone }}
              </div>
            </div>
            <div class="oral-component__content--dashboard-results-score-item">
              <div class="oral-component__content--dashboard-results-score-name">
                Pronunciation
              </div>
              <div class="oral-component__content--dashboard-results-score-value">
                {{ results.pronunciation }}
              </div>
            </div>
          </div>
          <div class="oral-component__content--dashboard-results-audio">
            <!-- buttons are visible and used to control what is played -->
            <div
              class="oral-component__content--dashboard-results-audio-btn"
              @click.prevent="playAudio('student')">
              <i class="fas fa-volume-up sound-icon"></i>
              <span>Your Recording</span>
            </div>
            <div v-if="teacherAudioSrc && (type !== 'templatedphrases')"
              class="oral-component__content--dashboard-results-audio-btn oral-component__content--dashboard-results-audio-btn-teacher"
              @click.prevent="playAudio('teacher')">
              <i class="fas fa-volume-up sound-icon"></i>
              <span>Teacher's Recording</span>
            </div>
            <audio :src="teacherAudioSrc" ref="teacherAudio"></audio>
          <div
            class="oral-component__content--dashboard-results-audio-btn
            oral-component__content--dashboard-results-audio-btn-rerecord"
            @click.prevent="rerecord">
            <i class="fas fa-arrows-rotate sound-icon"></i>
            <span>Rerecord</span>
          </div>
          </div>
          <p class="oral-component__content--dashboard-results-footer">
            ( Click on any word in the passage above to learn more )
          </p>
          <div class="oral-component__content--dashboard-results-btn-next"
            @click="goNext()">
            Continue
          </div>
        </div>
      </div>
      <div v-if="state === 4"
        class="oral-component__content--dashboard--final-scene">
         <div class="oral-component__content--dashboard--final-scene--header">
          <p class="oral-component__content--dashboard--final-scene--header-main">
            Final Score
          </p>
          <p class="oral-component__content--dashboard--final-scene--header-sub chinese-character"
            v-if="type === 'readaloud'">
            《{{ passageData.chinese_title }}》
          </p>
        </div>
        <div class="oral-component__content--dashboard--final-scene--score">
          <div
            class="oral-component__content--dashboard--final-scene--score-item">
            <div
              class="oral-component__content--dashboard--final-scene--score-name">
              Overall
            </div>
            <div
              class="oral-component__content--dashboard--final-scene--score-value">
              {{ results['final_scores']['overall'] }}
            </div>
          </div>
          <div
            class="oral-component__content--dashboard--final-scene--score-item">
            <div
              class="oral-component__content--dashboard--final-scene--score-name">
              Fluency
            </div>
            <div
              class="oral-component__content--dashboard--final-scene--score-value">
              {{ results['final_scores']['fluency'] }}
            </div>
          </div>
          <div
            class="oral-component__content--dashboard--final-scene--score-item">
            <div
              class="oral-component__content--dashboard--final-scene--score-name">
              Tone
            </div>
            <div
              class="oral-component__content--dashboard--final-scene--score-value">
              {{ results['final_scores']['tone'] }}
            </div>
          </div>
          <div
            class="oral-component__content--dashboard--final-scene--score-item">
            <div
              class="oral-component__content--dashboard--final-scene--score-name">
              Pronunciation
            </div>
            <div
              class="oral-component__content--dashboard--final-scene--score-value">
              {{ results['final_scores']['pronunciation'] }}
            </div>
          </div>
        </div>
        <div
          v-if="results['words_to_revise'].length > 0"
          class="oral-component__content--dashboard--final-scene--revision">
          <h4 class="oral-component__content--dashboard--final-scene--revision-header">
            Top Words to Revise
          </h4>
          <ul class="oral-component__content--dashboard--final-scene--revision-list">
            <li class="chinese-character"
              v-for="(word, index) in results['words_to_revise']" :key="index">
              {{ word.text }}
            </li>
          </ul>
        </div>
        <div class="oral-component__content--dashboard--final-scene--reward-btn"
          @click="onShowRewardsPage">
          <span v-if="!hasClaimedRewards">Collect Rewards</span>
          <span v-else>Rewards Claimed!</span>
        </div>
      </div>
    </div>
    <div v-if="state === 3 && selectedEntity"
      class="oral-component__popup-notification animated fadeIn faster"
      @click.self="setSelectedEntity(null)">
      <div class="oral-component__popup-notification--content-container animated fadeInUp faster">
        <!-- <div class="oral-component__popup-notification--btn-close-container">
          <a
            class="oral-component__popup-notification--btn-close"
            @click="setSelectedEntity(null)">
          </a>
        </div> -->
        <!-- teaching popup -->
        <teaching-screen
          class="oral-component__popup-notification--content"
          :word="selectedEntity.text"
          :pk="selectedEntity.pk || -1"
          :definition="selectedEntity.definition"
          :example-sentence="selectedEntity.sentence || ''"
          :src="selectedEntity.src || ''"
          :pinyin="selectedEntity.pinyin"
          :chapter="selectedEntity.chapter || null"
          :story="selectedEntity.story || null"
          :subtopic="selectedEntity.subtopic || null"
          :is-trackable="selectedEntity.is_trackable"
        ></teaching-screen>
      </div>
    </div>
    <basic-reward v-if="showRewardsPage"
      :coins-to-add="results['coins_to_add']"
      :close-rewards-callback="onCloseRewards">
    </basic-reward>
  </div>
</template>

<script>

import {mapState, mapMutations} from 'vuex';
import axios from 'axios';
import Recorder from '../../../vendor/recorder';

// from oral/common
import EntityComponent
  from './EntityComponent.vue';

import ReturnIndexButtonComponent
  from './common/ReturnIndexButtonComponent.vue';
// end oral/common

// start from ../common
import TeachingScreenComponent
  from '../common/guidedreading/TeachingScreenComponent.vue';

import BasicRewardComponent
  from '../common/BasicRewardComponent.vue';
// end ../common folder


// miliseconds, to prevent accidental double clicking
const MINIMUM_RECORD_TIME = 500;

export default {
  name: 'OralNewPassage',
  components: {
    Entity: EntityComponent,
    BasicReward: BasicRewardComponent,
    TeachingScreen: TeachingScreenComponent,
    ReturnIndexButton: ReturnIndexButtonComponent,
  },
  props: {
    type: String,
    entityId: Number,
  },
  data() {
    return {
      /*
       * 0: untested
       * 1: recording
       * 2: finished recording, waiting for system to return results
       * 3: results returned for the current para
       * 4: finished all the recordings for all the para, time to show overall results
      **/
      state: 0,
      passageData: [],
      numParagraphs: -1,
      paraIndex: -1, // each para is one question
      isRecording: false,
      // we don't want the record button to change immediately as that can cause the initial word to be cut off
      // hence, when isRecording changes to true, isRecordingBuffered only changes 0.5s later
      isRecordingBuffered: false,
      hasMicrophonePermission: false,
      recorder: null,
      gumStream: null,
      results: null,
      startRecordingTime: null,
      fetching: false,
      studentAudio: null,
      showRewardsPage: false,
      hasClaimedRewards: false,
      quizAttemptId: -1,
      url: '',
      isRerecordedQuestion: false,
    };
  },
  metaInfo: {
    // using minifed version of recorder caused an error
    script: [
      // {src: 'https://cdnjs.cloudflare.com/ajax/libs/recorderjs/0.1.0/recorder.js', async: true, defer: true},
    ],
  },
  computed: {
    ...mapState(['studentId']),
    ...mapState('guidedReading', ['selectedEntity', 'textbookChapterId']),
    // refers to the text of the current paragraph
    text() {
      if (!this.passageData || this.paraIndex < 0) return '';
      return this.passageData.clean_text[this.paraIndex];
    },
    // refers to the pinyin of the current paragraph
    pinyin() {
      if (!this.passageData || this.paraIndex < 0) return [];
      // return ['wo', 'de', 'ya'];
      return this.passageData.pinyin[this.paraIndex].split(' ');
    },
    audioIcon() {
      return this.isRecording ? 'fa-stop' : 'fa-microphone';
    },
    teacherAudioSrc() {
      console.log('huh');
      console.log(this.type);
      if (!this.paraIndex < 0 || this.paraIndex >= this.numParagraphs) return '';
      if (this.type === 'readaloud') {
        return `https://smartschool-static.s3.ap-southeast-1.amazonaws.com/media/oral_passages/${this.passageData.level}/${this.entityId}_${this.paraIndex}.mp3`;
      } else if (this.type === 'freeresponse') {
        // very bad code - please fix in future
        if (this.entityId >= 10) return '';
        return `https://smartschool-static.s3.ap-southeast-1.amazonaws.com/media/oral_freeresponse/${this.entityId}_${this.paraIndex}.mp3`;
      } else if (this.type === 'textbookchapter') {
        try {
          return this.passageData.sentences[this.paraIndex]['audio_mp3'];
        } catch (err) {
          return '';
        }
      } else {
        return '';
      }
      // else if (this.type === '') {
      //   return `https://smartschool-static.s3.ap-southeast-1.amazonaws.com/media/oral_freeresponse/primary_six/${this.entityId}_${this.questionIndex}_${this.paraIndex}.mp3`;
      // }
    },
    paragraphClass() {
      if (!this.paraIndex < 0) return '';
      if (this.text.length <= 40) return 'paragraph-short';
      return this.text.length <= 60 ? 'paragraph-medium': 'paragraph-long';
    },
    // the result from api doesnt have any punctuation, so we need to add it back
    wordsWithPunctuation() {
      if (!this.results) return [];
      let i = 0; // cleanText counter
      let j = 0; // result counter
      // word token counter (more complicated as double chars like 朋友 counts as one counter)
      // note that word tokens dont contain particles
      let k = 0;
      let charInTokenCounter = 0;
      let final = [];

      final = [];
      try {
        while (i < this.text.length) {
          // if out of bounds or a punctuation particle
          if (j >= this.results.words.length || this.text[i] !== this.results.words[j].word) {
            final.push({
              type: 'particle',
              word: this.text[i],
              details: null,
            });
          } else {
            // note that token can be a phrase like 朋友, while word is singular character
            // thus, 朋 and 友 would be two different characters that have the same token
            const token = this.results.word_tokens[k];
            const details = this.results.words[j];
            // better to use our inputted ref_pinyin
            const info = this.results.words[j];
            info['pinyin'] = this.pinyin[j];
            details['token'] = token;
            final.push({
              type: 'character',
              word: this.results.words[j].word,
              details: info,
            });
            j++;

            // checks whether we need to move to next token
            if (charInTokenCounter + 1 >= token['text'].length) {
              charInTokenCounter = 0;
              k++;
            } else {
              charInTokenCounter++;
            }
          }
          i++;
        };
      } catch (err) {
        console.log(err);
      }
      return final;
    },
  },
  mounted() {
    // NOTE: safari doesn't support navigator.permissions
    if (navigator.permissions) {
      // checks to see if user has provided permission to use microphone
      navigator.permissions.query({
        name: 'microphone',
      })
          .then((permissionStatus) => {
            this.hasMicrophonePermission = permissionStatus.state !== 'denied';
          })
          .catch((error) => {
            console.log(error);
            this.hasMicrophonePermission = false;
          });
    } else {
      // Check if mediaDevices loaded.
      if (navigator.mediaDevices !== undefined) {
        // Req microphone permissions
        navigator.mediaDevices.getUserMedia({audio: true}).then((stream) => {
          // Mic permissions granted, handle however you wish
          this.hasMicrophonePermission = true;
        }).catch((err) => {
          // Mic permissions denied, handle however you wish
          this.hasMicrophonePermission = false;
        });
      } else {
        // Out of luck at this point, handle however you wish.
        this.hasMicrophonePermission = false;
      }
    }
    this.pullData();
  },
  methods: {
    ...mapMutations(['setCoins', 'setQuizzesCompletedInfo']),
    ...mapMutations('widgets', ['setCoinLeaderboard']),
    ...mapMutations('guidedReading', ['setSelectedEntity']),
    pullData() {
      if (this.type === 'readaloud') {
        this.url = `/vocab/oralpassage/${this.entityId}/passage/`;
      } else if (this.type === 'freeresponse') {
        this.url = `/vocab/oralfreeresponse/${this.entityId}/answer/`;
      } else if (this.type === 'textbookchapter') {
        // if user came to this page directly without setting textbook, go back to oral page
        if (this.textbookChapterId === -1) {
          this.$router.push({name: 'new-oral-category-index', params: {category: 'oralTextbookChapter'}});
        }
        this.url = `/vocab/oraltextbookchapter/${this.entityId}/quiz/`;
      } else {
        this.url = `/vocab/${this.type}/${this.entityId}/${this.questionIndex}/`;
      }
      axios
          .get(this.url)
          .then((response) => {
            this.passageData = response.data;
            console.log(this.passageData);
            this.quizAttemptId = this.passageData['quiz_attempt_id'];
            this.numParagraphs = this.passageData.num_paragraphs;
            this.nextPara();
          });
    },
    reset() {
      this.gumStream = null;
      this.state = 0;
    },
    rerecord() {
      this.reset();
      this.isRerecordedQuestion = true;
    },
    // checks to see if we have reached end of passage or can we show next para
    // adjusts the state accordingly
    nextPara() {
      this.paraIndex++;
      // end
      if (this.paraIndex >= this.numParagraphs) {
        this.paraIndex = -1;
        // updates stars for the quiz
        this.setQuizzesCompletedInfo(this.results['quizzes_completed_info']);
        this.end();
        return;
      }
      this.state = 0;
      this.isRerecordedQuestion = false;
    },
    // to prevent user from accidentally double clicking, we will record for minimum of at least 1s
    toggleRecording() {
      if (this.isRecording) {
        this.stopRecording();
      } else {
        this.startRecording();
      }
    },
    // https://blog.addpipe.com/using-recorder-js-to-capture-wav-audio-in-your-html5-web-site/
    startRecording() {
      if (this.fetching) return;
      this.state = 1;
      this.isRecording = true;
      setTimeout(() => {
        this.isRecordingBuffered = true;
      }, 150);
      this.result = null;
      this.startRecordingTime = new Date();

      // shim for AudioContext when it's not avb.
      const AudioContext = window.AudioContext || window.webkitAudioContext;
      const audioContext = new AudioContext;

      navigator.mediaDevices.getUserMedia({audio: true, video: false}).then((stream) => {
        /* assign to gumStream for later use */
        this.gumStream = stream;
        /* use the stream */
        const input = audioContext.createMediaStreamSource(stream);
        // Create the Recorder object and configure to record mono sound (1 channel)
        this.recorder = new Recorder(input, {
          numChannels: 1,
        });
        // start the recording process
        this.recorder.record();
      }).catch((err) => {
        // enable the record button if getUserMedia() fails
        alert(err);
        console.log(err);
        this.isRecording = false;
        this.isRecordingBuffered = false;
      });
    },
    stopRecording() {
      if (new Date() - this.startRecordingTime < MINIMUM_RECORD_TIME) {
        return;
      }
      this.state = 2;

      if (this.fetching) {
        return;
      }
      this.isRecording = false;
      this.isRecordingBuffered = false;

      // tell the recorder to stop the recording
      if (!this.recorder) {
        alert('Error recording, please try again.');
        this.reset();
        return;
      }
      this.recorder.stop(); // stop microphone access
      this.gumStream.getAudioTracks()[0].stop();
      // create the wav blob and pass it on to createDownloadLink
      this.recorder.exportWAV(this.createDownloadLink);
    },
    createDownloadLink(blob) {
      const au = document.createElement('audio');
      au.controls = false;
      au.src = URL.createObjectURL(blob);
      this.studentAudio = au;

      const filename = new Date().toISOString();
      const formData = new FormData();
      formData.append('audioData', blob, filename);
      formData.append('paragraphIndex', this.paraIndex);
      formData.append('quizAttemptId', this.quizAttemptId);
      formData.append('isRerecordedQuestion', this.isRerecordedQuestion);
      this.fetching = true;

      axios
          .post(this.url, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then((response) => {
            this.results = response.data;
            console.log(this.results);
            this.fetching = false;
            this.state = 3;
          })
          .catch((err) => {
            console.log(err);
            alert(err);
            this.fetching = false;
            this.reset();
          });
    },
    stopAudio() {
      if (this.$refs.teacherAudio) {
        this.$refs.teacherAudio.pause();
        this.$refs.teacherAudio.currentTime = 0;
      }
      if (this.studentAudio) {
        this.studentAudio.pause();
        this.studentAudio.currentTime = 0;
      }
    },
    // if student audio is selected, stop teacher audio first, then play student audio
    // and vice versa
    playAudio(version) {
      this.stopAudio();
      if (version === 'student') {
        this.studentAudio.play();
      } else {
        this.$refs.teacherAudio.play();
      }
    },
    goNext() {
      this.stopAudio();
      // next para will check if we should go to next paragraph or end
      this.nextPara();
    },
    end() {
      this.state = 4;
    },
    onShowRewardsPage() {
      if (this.hasClaimedRewards) return;
      this.hasClaimedRewards = true;
      this.showRewardsPage = true;
    },
    onCloseRewards() {
      // set coins
      this.setCoins(this.results.coins);
      this.setCoinLeaderboard(this.results.coin_leaderboard);
      this.showRewardsPage = false;
      if (this.type === 'readaloud') {
        this.$router.push({name: 'new-oral-category-index', params: {category: 'readaloud'}});
      } else if (this.type === 'freeresponse') {
        this.$router.push({name: 'new-oral-category-index', params: {category: 'freeResponse'}});
      } else if (this.type === 'textbookchapter') {
        // for textbook, we want to go back to the page where students can select the quiz they want
        this.$router.push({name: 'new-oral-textbook-chapter-topic', params: {
          type: 'oralTextbookChapter',
          topicId: this.textbookChapterId,
        }});
      } else {
        this.$router.push({name: 'new-oral-category-index', params: {category: 'templatedPhrases'}});
      }
    },
  },
};

</script>

<style lang="scss" scoped>
  a {
    text-decoration: none;
  }
  /*
  .paragraph-long {
    max-width: 820px;
  }
  .paragraph-medium {
    max-width: 720px;
  }
  .paragraph-short {
    max-width: 620px;
  }
  */
  .oral-component {
    background: #fff;
    min-height: 100vh;
    min-height: -webkit-fill-available;
    width: 100vw;
    top: 0;
    position: fixed;
    display: flex;
    justify-content: center;
    &__content {
      min-height: -webkit-fill-available;
        display: flex;
        flex-direction: column;
      width: 100%;
      max-width: 860px;
      margin-bottom: 1rem;
      &--header {
        height: 5%;
        margin: .5rem 0;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        &-title {
          font-size: 1.25rem;
          font-weight: 600;
          flex: 1;
        }
        &-para-number {
          font-size: 0.9rem;
          font-weight: 600;
          padding: 0.25rem 0.5rem;
          border-radius: 20px;
          background: #ffcf6d;
        }
      }
      &--passage {
        display: flex;
        flex: 1;
        justify-content: center;
        align-items: center;
        border-top: 2px solid #ccc;
        border-bottom: 2px solid #ccc;
        padding: 0 2rem;
        position: relative;
        &-qns {
          display: flex;
          flex-wrap: wrap;
          :first-child {
            margin-left: 75px;
          }
          p {
            font-size: 1.35rem;
            width: 37.5px;
            display: flex;
            justify-content: center;
            align-items: flex-start;
            height: 60px;
          }
        }
        &-result {
          display: flex;
          flex-wrap: wrap;
          font-size: 1.35rem;
          :first-child {
            margin-left: 75px;
          }
          &--legend {
            position: absolute;
            bottom: .5rem;
            display: flex;
            font-size: .65rem;
            font-weight: 500;
            justify-content: space-between;
            width: 340px;
            text-align: center;
            flex-direction: row-reverse;
            &-1, &-2, &-3 {
              width: 33.33%;
              i {
                margin-right: .25rem;
              }
            }
            &-1 {
              color: #ff6666;
            }
            &-2 {
              color: #ff9f0d;
            }
            &-3 {
              color: #22d183;
            }
          }
        }
      }
      &--dashboard {
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        height: 40%;
        width: 100%;
        &-recorder {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          &-timer {
            position: absolute;
            top: 0;
            font-size: 0.9rem;
            font-weight: 600;
            text-align: center;
          }
          &-text {
            font-size: 0.75rem;
            font-weight: 600;
            font-style: italic;
            margin-top: 1rem;
          }
          &-btn {
            cursor: pointer;
            transition: all 0.3s;
            &:hover {
              transform: scale(1.05);
              &::after {
                transform: scaleX(1.1) scaleY(1.3);
                opacity: 0;
              }
            }
            &:active {
              transform: translateY(-1px);
            }
          }
        }
        &-loading {
          text-align: center;
          position: absolute;
          z-index: -1;
        }
        &-results {
          width: 100%;
          display: flex;
          justify-content: center;
          flex-direction: column;
          &-footer {
            font-size: .7rem;
            font-weight: 600;
            font-style: italic;
            text-align: center;
          }
          &-score {
            display: flex;
            justify-content: center;
            align-items: center;
            &-item {
              flex: 1;
              display: flex;
              align-items: center;
              justify-content: space-between;
              flex-direction: column;
              height: 60px;
              text-align: center;
              border-right: 1px solid #d2e8ff;
              margin: 1rem 0;
              &:last-child {
                border-right: none;
              }
            }
            &-name {
              font-size: .85rem;
              font-weight: 700;
              color: #333;
            }
            &-value {
              font-size: 1.5rem;
              font-weight: 700;
              color: #1377e1;
            }
          }
          &-audio {
            display: flex;
            justify-content: center;
            margin-top: 1rem;
            margin-bottom: 2rem;
            &-btn {
              display: flex;
              justify-content: center;
              width: 250px;
              text-align: center;
              border-radius: 0.5rem;
              font-size: 0.75rem;
              font-weight: 600;
              margin: 0 0.5rem;
              background: #7a58ed;
              box-shadow: 0px 5px #5f47b0;
              color: #fff;
              padding: .5rem 1rem;
              cursor: pointer;
              transition: all 0.3s;
              border: 2px solid #7a58ed;
              &:hover {
                transform: scale(1.03);
                background: #8f6fff;
                &::after {
                  transform: scaleX(1.1) scaleY(1.3);
                  opacity: 0;
                }
              }
              &-teacher {
                background: #1FAB87;
                border: 2px solid #1FAB87;
                box-shadow: 0px 5px #106852;
                &:hover {
                  background: #2dc49e;
                }
              }
              &-rerecord {
                background: #EA814E;
                border: 2px solid #EA814E;
                box-shadow: 0px 5px #A7603E;

                &:hover {
                  background: #fa915c;
                }
              }
              &:active {
                transform: translateY(-1px);
              }
              i {
                margin-right: 0.5rem;
                font-size: 1rem;
                display: flex;
                justify-content: center;
                align-items: center;
              }
              span {
                display: flex;
                justify-content: center;
                align-items: center;
              }
            }
          }
          &-btn-next {
            position: fixed;
            bottom: 0;
            left: 0;
            width: 100%;
            text-align: center;
            font-weight: 600;
            background: #22b492;
            color: #fff;
            padding: .75rem 0;
            cursor: pointer;
            transition: all 0.3s;
            &:hover {
              background: #27dfb4;
              &::after {
              }
            }
          }
        }
        &--final-scene {
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;
          &--header {
            height: 5%;
            font-size: 1.2rem;
            font-weight: 700;
            display: flex;
            justify-content: space-between;
            width: 100%;
            margin-bottom: 1rem;
          }
          &--score {
            width: 100%;
            flex-wrap: wrap;
            height: 25%;
            display: flex;
            justify-content: center;
            align-items: center;
            border-top: 2px solid #00000029;
            border-bottom: 2px solid #00000029;
            &-item {
              min-width: 8rem;
              flex: 1;
              display: flex;
              align-items: center;
              justify-content: space-between;
              flex-direction: column;
              height: 60px;
              text-align: center;
              border-right: 1px solid #d2e8ff;
              margin-bottom: 1rem;
              &:last-child {
                border-right: none;
              }
            }
            &-name {
              font-size: 1rem;
              font-weight: 700;
              color: #333;
            }
            &-value {
              font-size: 1.75rem;
              font-weight: 700;
              color: #1377e1;
            }
          }
          &--revision {
            height: 30%;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            &-header{
              text-align: center;
              margin-bottom: 1rem;
            }
            &-list{
              display: flex;
              width: 100%;
              flex-wrap: wrap;
              justify-content: center;
              align-items: center;
              li {
                padding: 0.5rem 1rem;
                border: 2px solid #265fcf;
                font-weight: 700;
                color: #265fcf;
                margin: .5rem;
                border-radius: 2rem;
                cursor: pointer;
                &:hover {
                  color: #fff;
                  background: #265fcf;
                }
              }
            }
          }
          &--reward-btn {
            width: 250px;
            text-align: center;
            border-radius: 0.5rem;
            font-size: 1.1rem;
            font-weight: 600;
            background: #22b492;
            box-shadow: 0px 5px #2e8771;
            color: #fff;
            height: 7.5%;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            transition: all 0.3s;
            &:hover {
              transform: scale(1.03);
              background: #3de7c0;
              box-shadow: 0px 5px #3bc6a4;
              &::after {
                transform: scaleX(1.1) scaleY(1.3);
                opacity: 0;
              }
            }
            &:active {
              transform: translateY(-1px);
            }
          }
        }
      }
    }
    &__popup-notification {
      position: absolute;
      min-height: 100vh;
      min-height: -webkit-fill-available;
      width: 100vw;
      z-index: 2;
      background: #000000e6;
      display: flex;
      justify-content: center;
      align-items: center;
      &--btn-close {
        position: absolute;
        z-index: 3;
        left: 1rem;
        top: 0rem;
        width: 32px;
        height: 32px;
        opacity: 0.3;
        cursor: pointer;
        transition: 0.3s all linear;
        &-container {
          z-index: 3;
          position: absolute;
          top: calc(50% - 400px);
          left: calc(50% - 360px);
        }
        &:hover {
          opacity: 1;
        }
        &:before, &:after {
          position: absolute;
          left: 15px;
          content: ' ';
          height: 33px;
          width: 2px;
          background-color: #333;
        }
        &:before {
          transform: rotate(45deg);
        }
        &:after {
          transform: rotate(-45deg);
        }
      }
      &--content {
        width: 95%;
        height: 95%;
        &-container {
          width: 820px;
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          position: relative;
        }
        background: #fff;
        position: relative;
        padding: 2rem;
        max-width: 720px;
        max-height: 800px;
        border-radius: 1rem;
      }
    }
  }

  @media only screen and (max-width: 1080px) {
    .oral-component__content {
      margin-bottom: 0;
      max-width: 100%;
      &--header-title {
        margin-left: 4rem;
      }
      &--header-para-number {
        margin-right: 1rem;
      }
    }
    .oral-component__content--dashboard-results-footer {
      margin-bottom: 2rem;
    }
    .oral-component__content--dashboard--final-scene--header {
      &-main {
      margin-left: 1rem;
      }
      &-sub {
        margin-right: 1rem;
      }
    }
  }
  @media only screen and (max-width: 760px) {
    .oral-component__popup-notification--content {
      width: 95%;
      height: 95%;
    }
    .oral-component__popup-notification--content-container {
      height: 100%;
      width: 100%;
    }
    .oral-component__popup-notification--btn-close-container {
      top: 2rem;
      left: 0.5rem
    }
  }
  @media only screen and (max-width: 710px) {
    .oral-component__content--passage {
    }
    .oral-component__content--dashboard {
      height: 35%;
    }
  }
  @media only screen and (max-width: 630px) {
    .oral-component__content--passage {
      padding: 0 1rem;
    }
    .oral-component__content--header-title {
      margin-left: 2.5rem;
    }
  }
  @media only screen and (max-width: 540px) {
    .oral-component__content--passage {
    }
    .oral-component__content--dashboard {
      height: 30%;
    }
    .oral-component__content--dashboard {
      &-results {
        &-audio-btn {
          font-size: 0.7rem;
          box-shadow: 0px 3px #5f47b0;
          padding: 0.3rem;
          width: 210px;
        }
        &-btn-next {
          font-size: 0.8rem;
          padding: 0.5rem 0;
        }
        &-score {
          &-name{
            font-size: 0.75rem;
          }
          &-value {
            font-size: 1.25rem;
          }
          &-item {
            height: 50px;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 440px) {
    .oral-component__content--passage{
      padding: 0 .75rem;
    }
  }
  @media only screen and (max-width: 375px) {
    .oral-component__content--dashboard {
      &-results {
        &-audio {
          margin-top: 0.5rem;
          margin-bottom: 1.5rem;
        }
        &-audio-btn {
          font-size: 0.6rem;
        }
        &-score {
          &-name{
            font-size: 0.65rem;
          }
          &-value {
            font-size: 1.15rem;
          }
          &-item {
            height: 45px;
          }
        }
      }
    }
    .oral-component__content--passage {
      &-qns {
        p {
          height: 50px;
        }
      }
    }
  }
  .popup-notification {
    &__component {
      position: fixed;
      background: rgba(0,0,0,.85);
      min-height: 100vh;
      min-height: -webkit-fill-available;
      width: 100vw;
      z-index: 50;
      top: 0;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    &__container {
      position: relative;
      max-width: 450px;
      text-align: center;
      padding: 2rem 3rem;
      background: #fff;
      color: #222;
      border-radius: 5px;
      p {
        margin-bottom: 1rem;
      }
    }
    &__close {
      &--icon {
        position: absolute;
        top: 1rem;
        right: 1rem;
        cursor: pointer;
        color: #ccc;
        font-size: 1.2rem;
      }
      &--btn {
        padding: .25rem .75rem;
        border: 4px solid #000;
        background: #8131ff;
        border-radius: 5px;
        margin: 1rem auto 0 auto;
        max-width: 150px;
        color: #fff;
        font-weight: 700;
        cursor: pointer;
      }
    }
  }
  </style>
