<template>
  <div class="diamond-shop__component row">
    <div class="diamond-shop__header">
      <h2>Diamond Exchange Shop</h2>
    </div>
    <!-- btn tabs -->
    <div class="diamond-shop__btns">
      <div class="diamond-shop__btn diamond-shop__btn--shop"
        :class="{ 'diamond-shop__btn-unselected': showPurchased }"
        @click="showPurchased = false">
        <i class="fas fa-shopping-cart"></i>
        <span class="diamond-shop__btn--text">Item Shop</span>
      </div>
      <div class="diamond-shop__btn diamond-shop__btn--orders"
        :class="{ 'diamond-shop__btn-unselected': !showPurchased }"
        @click="showPurchased = true">
        <i class="fas fa-truck">
        </i>
        <span class="diamond-shop__btn--text">Your Orders</span>
        <div
          v-if="numPurchasedItems > 0"
          class="diamond-shop__btn--orders--count">
          <!-- num of outstanding purchases -->
          {{ numPurchasedItems }}
        </div>
      </div>
    </div>
    <!-- To remove transaction history component? -->
     <transaction-history
      v-if="showPurchased"
      @close-popup="showPurchased = !showPurchased"
      class="diamond-shop__transaction-history"
      :items-map="itemsMap"
      :ordered-counts="orderedCounts"
      @cancel="cancel"
    >
    </transaction-history>
    <item-list
      v-if="!showPurchased"
      :items="items"
      :ordered-counts="orderedCounts"
      :popup-toggle="popupToggle"
    ></item-list>
    <purchase-popup
      v-if="isItemClicked"
      @close-popup="closePopup"
      :item-clicked="itemClicked"
      :can-user-redeem="canUserRedeem"
      :redeem-item="redeemItem"
      :reason-for-rejection="reasonForRejection"
    ></purchase-popup>
  </div>
</template>

<script>

import axios from 'axios';
import {mapState, mapMutations} from 'vuex';

import ShopTransactionHistoryComponent
  from './TransactionHistoryComponent.vue';
import ShopItemListComponent
  from './ItemListComponent.vue';
import PurchasePopupComponent
  from './PurchasePopupComponent.vue';

export default {
  name: 'ShopIndex',
  components: {
    TransactionHistory: ShopTransactionHistoryComponent,
    ItemList: ShopItemListComponent,
    PurchasePopup: PurchasePopupComponent,
  },
  props: {
  },
  data() {
    return {
      items: [],
      orderedCounts: {},
      itemsMap: {},
      isItemClicked: false,
      itemClicked: {},
      canUserRedeem: false,
      reasonForRejection: '',
      showPurchased: false,
    };
  },
  computed: {
    ...mapState(['diamonds', 'studentType']),
    ...mapState(['isOldNavShown']),
    numPurchasedItems() {
      if (this.orderedCounts === {}) return 0;
      let total = 0;
      for (const key in this.orderedCounts) {
        total += this.orderedCounts[key];
      }
      return total;
    },
  },
  mounted: function() {
    axios
        .get('/gamification/redeemableitem/load/')
        .then((response) => {
          this.items = response.data['redeemable_items'];
          // https://coderwall.com/p/ebqhca/javascript-sort-by-two-fields
          // sort first by price, then by index
          this.items.sort(function(a, b) {
            return a['price'] - b['price'] || a['index'] - b['index'];
          });
          this.orderedCounts = response.data['ordered_counts'];
          for (let i = 0; i < this.items.length; i++) {
            this.itemsMap[this.items[i].pk] = this.items[i];
          };
        });
  },
  methods: {
    ...mapMutations(['setDiamonds']),
    popupToggle(item) {
      this.$store.state.isOldNavShown = !this.$store.state.isOldNavShown;
      this.itemClicked = item;
      this.reasonForRejection = '';
      if (this.itemClicked.status === 'sold_out') {
        this.canUserRedeem = false;
        this.reasonForRejection = 'sold_out';
      } else if (this.studentType !== 'attending' &&
          this.studentType !== 'digital_subscription') {
        this.canUserRedeem = false;
        this.reasonForRejection = 'not_member';
        // return;
      } else if (item.price > this.diamonds) {
        this.reasonForRejection = 'insufficient_diamonds';
        this.canUserRedeem = false;
      } else {
        this.reasonForRejection = '';
        this.canUserRedeem = true;
      };
      this.isItemClicked = !this.isItemClicked;
    },
    closePopup() {
      this.isItemClicked = !this.isItemClicked;
      this.$store.state.isOldNavShown = !this.$store.state.isOldNavShown;
    },
    redeemItem(item) {
      axios
          .post('/gamification/redeemableitem/' + item.pk + '/purchase/')
          .then((response) => {
            if (response.data['status'] === 1) {
              this.setDiamonds(response.data['new_diamonds']);
              this.orderedCounts = response.data['ordered_counts'];
            } else {
              alert('Error buying item!');
            }
          })
          .catch((err) => {
            this.canUserRedeem = false;
            alert('Could not purchase item');
            console.log(err);
          });
    },
    cancel(itemId) {
      axios
          .post('/gamification/redeemableitem/' + itemId + '/cancel/', {})
          .then((response) => {
            this.orderedCounts = response.data['ordered_counts'];
            this.setDiamonds(response.data['new_diamonds']);
          });
    },
  },
};
</script>

<style lang="scss" scoped>
  .diamond-shop {
    &__component {
      margin: 20px auto 124px auto;
    }
    &__header {
      position: relative;
      text-align: center;
      background: #5a4179;
      color: #fff;
      padding: 10px;
      border-radius: 3px;
      margin-bottom: 15px;
    }
    &__btns {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 25px;
    }
    &__btn {
      display: flex;
      justify-content: center;
      margin: 0.5rem;
      width: 200px;
      padding: 0.75rem 1rem;
      border-radius: .3rem;
      border-top: 6px solid #ffbe43;
      background: #fff;
      color: #565656;
      i {
        font-size: 1.5rem;
      }
      &--text {
        font-size: 1rem;
        font-weight: 800;
        margin-left: 0.5rem;
      }
      &--shop{}
      &--orders{
        position: relative;
        i {
          transform: scaleX(-1);
        }
        &--count {
          font-family: 'Lato', 'Arial', sans-serif;
          padding-left: 0.3rem;
          background-color: #f44;
          width: 1rem;
          height: 1rem;
          border-radius: 50%;
          position: absolute;
          right: -.25rem;
          top: -.25rem;
          font-size: .75rem;
          color: #fff;
          font-weight: 800;
        }
      }
      &-unselected {
        background: #e2e2e2;
        color: #d0d0d0;
        border-top: 6px solid #d8d8d8;
        &:hover {
          border-top: 6px solid #ffbe43;
          color: #565656;
          background: #fff;
        }
      }
    }
  }
</style>
