// import Vue from 'vue';
// import axios from 'axios';


const getDefaultState = () => {
  // note: index resets for every subtrack
  const results = {
    activePet: null,
    pets: {},
    gameValues: {},
  };

  return results;
};

const state = getDefaultState();

export const pet = {
  namespaced: true,
  state,
  mutations: {
    resetStateMutation(state) {
      Object.assign(state, getDefaultState());
    },
    setGameValues(state, value) {
      state.gameValues = value;
    },
    setActivePet(state, value) {
      state.activePet = value;
    },
    setPets(state, value) {
      state.pets = value;
    },
  },
  actions: {
  },
  getters: {
    getPetById(state) {
      return (id) => {
        const filteredResults = state.pets.filter((pet) => {
          return pet.id === id;
        });
        return filteredResults[0];
      };
    },
  },
};
